import { StringUtils, TabBar } from "d-react-components";
import React, { useContext, useState } from "react";
import InputDataList from "../../../common/input/InputDataList";
import { CategoryDetailContext } from "../../../context/category";
import Messages from "../../../languages/Messages";

const PriorCheckingList = () => {
    const { categoryForm } = useContext(CategoryDetailContext);
    const { priorChecklist } = categoryForm?.values ?? {};

    return (
        <div className="p-4 bg-white">
            <InputDataList
                value={priorChecklist}
                onChange={(process) =>
                    categoryForm.setFieldValue("priorChecklist", process)
                }
            />
        </div>
    );
};

const PriorCriteria = () => {
    const { categoryForm, criteriaList } = useContext(CategoryDetailContext);
    const { priorCriteria } = categoryForm?.values ?? {};

    return (
        <div className="p-4 bg-white">
            <InputDataList
                value={priorCriteria}
                onChange={(process) =>
                    categoryForm.setFieldValue("priorCriteria", process)
                }
                defaultNewValue={{
                    id: StringUtils.getUniqueID(),
                    value: "",
                }}
                type="select"
                dataSource={criteriaList as any}
            />
        </div>
    );
};

const PRIOR_TABS = [
    {
        id: "checkListItem",
        label: Messages.checkListItem,
        component: <PriorCheckingList />,
    },

    {
        id: "criteria",
        label: Messages.criteria,
        component: <PriorCriteria />,
    },
];

const CategoryDetailPriorChecking = () => {
    const [tabSelected, setTabSelected] = useState<any>(PRIOR_TABS[0]);
    return (
        <div>
            <TabBar
                dataSource={PRIOR_TABS}
                onChange={setTabSelected}
                value={tabSelected}
            />
            {tabSelected.component}
        </div>
    );
};

export default CategoryDetailPriorChecking;
