import React, { useContext } from "react";
import { BookingDetailContext } from "../../../context/booking";
import BookingProductTable from "../common/BookingProductTable";

const BookingDetailProducts = () => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const { products = [] } = bookingDetail;

    return (
        <div className="card-container p-4">
            <BookingProductTable products={products} />
        </div>
    );
};

export default BookingDetailProducts;
