import { DialogManager, Notifications, Progress } from "d-react-components";
import { every, reduce, some } from "lodash";
import { useContext, useMemo } from "react";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import { WorksheetDetailContext } from "../../../context/worksheet";
import Messages from "../../../languages/Messages";
import WSDefectionConfirm from "../common/WSDefectionConfirm";
import WSCDefectionVisual from "../common/WSCDefectionVisual";
import { WORKSHEET_CRITERIA_CHECK_STATUS } from "../../../constant/worksheet";

const WorksheetDetailDefection = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const {
        defection,
        services,
        criteriasCheck,
        mileageStart,
        categoryProduct,
    } = worksheetDetail;

    const totalUseQuantity = reduce(
        services,
        (sum, item) => sum + item.quantity,
        0
    );

    const isFullCriteriasCheck = criteriasCheck.length === totalUseQuantity;

    const isSomePriorUnChecked = some(
        criteriasCheck,
        (criteriaCheck) =>
            // every(criteriaCheck?.priorChecklist, (item) => item.value)
            criteriaCheck?.status === WORKSHEET_CRITERIA_CHECK_STATUS.PENDING
    );

    const isStartedMileage =
        !categoryProduct?.mileageTrackingRequired ||
        ((mileageStart?.mile || mileageStart?.mile === 0) &&
            mileageStart?.proves?.length);

    const hiddenAdd = useMemo(() => {
        if (defection?.confirmation?.confirmed) {
            return true;
        }

        if (!isFullCriteriasCheck) {
            return true;
        }

        if (isSomePriorUnChecked) return true;

        if (!isStartedMileage) return true;

        return false;
    }, [worksheetDetail]);

    const hiddenUpdate = useMemo(() => {
        if (defection?.confirmation?.confirmed) {
            return true;
        }

        return false;
    }, [worksheetDetail]);

    const hiddenConfirmation = useMemo(() => {
        if (!isFullCriteriasCheck) {
            return true;
        }

        if (isSomePriorUnChecked) return true;

        if (!isStartedMileage) return true;
        return false;
    }, [worksheetDetail]);

    const hiddenRemove = useMemo(() => {
        if (defection?.confirmation?.confirmed) {
            return true;
        }

        return false;
    }, [worksheetDetail]);

    const onAddDefectionVisual = (visual: any) => {
        Progress.show(
            {
                method: WorksheetAPI.addDefectionVisual,
                params: [worksheetDetail?.id, visual],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.addDefectionVisualSuccess);
                setWorksheetDetail(res);
            }
        );
    };

    const onUpdateDefectionVisual = (visual: any) => {
        const input = {
            images: visual.images,
            note: visual.note,
        };
        Progress.show(
            {
                method: WorksheetAPI.updateDefectionVisual,
                params: [worksheetDetail?.id, visual?.id, input],
            },
            (res: any) => {
                Notifications.showSuccess(
                    Messages.updateDefectionVisualSuccess
                );
                setWorksheetDetail(res);
            }
        );
    };

    const onRemoveDefectionVisual = (visualId: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: WorksheetAPI.removeDefectionVisual,
                        params: [worksheetDetail?.id, visualId],
                    },
                    (res: any) => {
                        Notifications.showSuccess(
                            Messages.removeDefectionVisualSuccess
                        );
                        setWorksheetDetail(res);
                    }
                )
        );
    };

    const onConfirmDefection = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureConfirmThis,
            () =>
                Progress.show(
                    {
                        method: WorksheetAPI.confirmDefection,
                        params: [worksheetDetail?.id],
                    },
                    (res: any) => {
                        Notifications.showSuccess(
                            Messages.worksheetDefectionConfirmed
                        );
                        setWorksheetDetail(res);
                    }
                )
        );
    };

    const onUpdateRemark = ({ remark }: any) => {
        Progress.show(
            {
                method: WorksheetAPI.editRemarkDefectionConfirm,
                params: [worksheetDetail?.id, remark],
            },
            (res: any) => {
                setWorksheetDetail(res);
            }
        );
    };

    const onStaffSign = (signature: string) => {
        Progress.show(
            {
                method: WorksheetAPI.staffSignDefection,
                params: [worksheetDetail?.id, signature],
            },
            (res: any) => {
                setWorksheetDetail(res);
            }
        );
    };

    const onCustomerSign = (signature: string) => {
        Progress.show(
            {
                method: WorksheetAPI.customerSignDefection,
                params: [worksheetDetail?.id, signature],
            },
            (res: any) => {
                setWorksheetDetail(res);
            }
        );
    };

    return (
        <div className="flex-column mt-3">
            <WSCDefectionVisual
                visualizationList={defection.visualization as any}
                onAdd={onAddDefectionVisual}
                onUpdate={onUpdateDefectionVisual}
                onRemove={onRemoveDefectionVisual}
                hiddenAdd={hiddenAdd}
                hiddenRemove={hiddenRemove}
                hiddenUpdate={hiddenUpdate}
            />
            {!hiddenConfirmation && (
                <WSDefectionConfirm
                    defectionConfirm={defection?.confirmation}
                    onConfirm={onConfirmDefection}
                    onUpdateRemark={onUpdateRemark}
                    onStaffSign={onStaffSign}
                    onCustomerSign={onCustomerSign}
                />
            )}
        </div>
    );
};

export default WorksheetDetailDefection;
