import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { CATEGORY_STATUS } from "../../../constant/category";
import { mapBannerToSer } from "../../../interfaces/banner";
import BannerAPI from "../../../api/banner/BannerAPI";
import { BannerCrudContext } from "../../../context/banner";
import BannerCrudConfig from "./BannerCrudConfig";
import BannerCrudSliderContent from "./BannerCrudSliderContent";
import { BannerSchema } from "../../../formschema/banner";

const BannerCrud = () => {
    const history = useHistory();
    const { bannerId } = useParams<any>();
    const isEdit = useMemo(() => !!bannerId, [bannerId]);
    const [bannerLang, setBannerLang] = useState("en");

    const bannerForm = useFormik<any>({
        initialValues: { status: CATEGORY_STATUS.INACTIVE } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BannerSchema,
        onSubmit: (values: any) => {
            const input = mapBannerToSer(values);
            if (isEdit) {
                onUpdate(input);
            } else {
                onCreate(input);
            }
        },
    });

    useEffect(() => {
        isEdit && loadBannerDetail();
    }, []);

    const loadBannerDetail = () =>
        Progress.show(
            { method: BannerAPI.detail, params: [bannerId] },
            bannerForm.setValues
        );

    const onCreate = (input: any) => {
        Progress.show(
            { method: BannerAPI.create, params: [input] },
            (res: any) => {
                Notifications.showSuccess(Messages.createBannerSuccess);
                history.replace(
                    generatePath(Path.BANNER_DETAIL, {
                        bannerId: res?.id,
                    })
                );
            }
        );
    };

    const onUpdate = (input: any) => {
        Progress.show(
            { method: BannerAPI.update, params: [bannerId, input] },
            (bannerDetail: any) => {
                Notifications.showSuccess(Messages.updateBannerSuccess);
                bannerForm.setValues(bannerDetail);
            }
        );
    };

    return (
        <BannerCrudContext.Provider
            value={{
                bannerForm,
                bannerLang,
                setBannerLang,
            }}
        >
            <Header
                title={Messages.banner}
                onSave={() => bannerForm.handleSubmit()}
            />
            <div className="p-4 mt-3">
                <BannerCrudConfig />
                <BannerCrudSliderContent />
            </div>
        </BannerCrudContext.Provider>
    );
};

export default BannerCrud;
