import { DateInput } from "d-react-components";
import React, { useContext } from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { BANNER_POSITIONS, BANNER_STATUSES } from "../../../constant/banner";
import { BannerCrudContext } from "../../../context/banner";
import Messages from "../../../languages/Messages";

const BannerCrudConfig = () => {
    const { bannerForm } = useContext(BannerCrudContext);

    const classNameInput = "col-6 mt-3";
    return (
        <div className="card-container p-4 ">
            <div className="row">
                <InputTextForm
                    form={bannerForm}
                    keyData="title"
                    className="col-12"
                    required
                />
                <InputSelectForm
                    form={bannerForm}
                    keyData="position"
                    dataSource={BANNER_POSITIONS}
                    className={classNameInput}
                    required
                />
                <InputSelectForm
                    form={bannerForm}
                    keyData="status"
                    dataSource={BANNER_STATUSES}
                    className={classNameInput}
                    required
                />
                <DateInput
                    label={Messages.effectiveDate}
                    onChange={(value) =>
                        bannerForm.setFieldValue("effectiveDate", value)
                    }
                    value={bannerForm.values.effectiveDate}
                    error={bannerForm.errors.effectiveDate}
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    className={classNameInput}
                    required
                />
                <DateInput
                    label={Messages.expiryDate}
                    onChange={(value) =>
                        bannerForm.setFieldValue("expiryDate", value)
                    }
                    value={bannerForm.values.expiryDate}
                    error={bannerForm.errors.expiryDate}
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    className={classNameInput}
                />
                <InputTextForm
                    form={bannerForm}
                    keyData="remark"
                    className="col-12 mt-3"
                    multiple
                />
            </div>
        </div>
    );
};

export default BannerCrudConfig;
