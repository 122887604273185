export const BANNER_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const BANNER_STATUSES = [
    { id: BANNER_STATUS.ACTIVE, label: "active", color: "#33B950" },
    { id: BANNER_STATUS.INACTIVE, label: "inactive", color: "#C4C4C4" },
];

export const BANNER_POSITION = {
    HOME_TOP: "HOME_TOP",
    HOME_MIDDLE_1: "HOME_MIDDLE_1",
    HOME_MIDDLE_2: "HOME_MIDDLE_2",
    HOME_BOTTOM: "HOME_BOTTOM",
};

export const BANNER_POSITIONS = [
    { id: BANNER_POSITION.HOME_TOP, label: "homePageTop" },
    { id: BANNER_POSITION.HOME_MIDDLE_1, label: "homePageMiddle1" },
    { id: BANNER_POSITION.HOME_MIDDLE_2, label: "homePageMiddle2" },
    { id: BANNER_POSITION.HOME_BOTTOM, label: "homeBottom" },
];

export const BANNER_DESTINATION_TYPE = {
    EXTERNAL: "EXTERNAL",
    IN_APP_NEWS: "IN_APP_NEWS",
    NONE: "NONE",
};

export const BANNER_DESTINATION_TYPES = [
    {
        id: BANNER_DESTINATION_TYPE.EXTERNAL,
        label: "external",
    },
    {
        id: BANNER_DESTINATION_TYPE.IN_APP_NEWS,
        label: "inAppNews",
    },
    {
        id: BANNER_DESTINATION_TYPE.NONE,
        label: "none",
    },
];
