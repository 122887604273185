import {
    Button,
    DateInput,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const ServiceDateUpdateModal = ({ open, onClose, defaultValue }: any) => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);

    const updateForm = useFormik<any>({
        initialValues: {
            estServiceDate: defaultValue,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onUpdate(values);
        },
    });

    const formValues = updateForm.values;

    const onUpdate = (updateInfo: any) =>
        Progress.show(
            {
                method: BookingAPI.updateServiceDate,
                params: [bookingDetail?.id, updateInfo?.estServiceDate],
            },
            (bookingResult) => {
                setBookingDetail(bookingResult);
                onClose();
            }
        );

    return (
        <Modal
            size="small"
            title={Messages.update}
            open={open}
            onClose={onClose}
            onSave={() => updateForm.handleSubmit()}
        >
            <div className="row">
                <DateInput
                    label={Messages.serviceDate}
                    onChange={(val) => {
                        const valueTime = (val as any).valueOf();
                        if (valueTime < new Date().getTime()) {
                            Notifications.showError(
                                Messages.estDateNeedToGreaterThan
                            );
                            return;
                        }
                        updateForm.setFieldValue(
                            "estServiceDate",
                            moment(val).toISOString()
                        );
                    }}
                    value={moment(formValues.estServiceDate) as any}
                    className="col-12"
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    allowClear={false}
                />
            </div>
        </Modal>
    );
};

const BookingDetailServiceDate = () => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const { serviceDate, status } = bookingDetail;
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    return (
        <div className="card-container p-4 mt-3">
            <div className="flex-center justify-content-between">
                <label>{Messages.estServiceDate}</label>
                <Button
                    variant="trans"
                    onClick={() => setOpenEdit(true)}
                    hidden={status === BOOKING_STATUS.CANCELLED}
                >
                    {Messages.edit}
                </Button>
            </div>
            <text>{TimeUtils.toDateTime(serviceDate)}</text>
            {openEdit && (
                <ServiceDateUpdateModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    defaultValue={serviceDate}
                />
            )}
        </div>
    );
};

export default BookingDetailServiceDate;
