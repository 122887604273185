import { ObjectUtils, StringUtils } from "d-react-components";
import { filter, find, map } from "lodash";
import { useContext } from "react";
import { ORDER_FEES } from "../../../constant/order";
import { OrderCreateContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import AnonymousVoucherAppliedRow from "./vouchers/AnonymousVoucherAppliedRow";
import ProductVoucherAppliedRow from "./vouchers/ProductVoucherAppliedRow";

interface IPriceRow {
    title: string;
    value: any;
}

const classNamePriceRow =
    "py-3 border-bottom d-flex justify-content-between align-items-center";

export const PriceRow = ({ title, value }: IPriceRow) => {
    return (
        <div className={classNamePriceRow}>
            <text>{title}</text>
            <text>{StringUtils.moneyThaiFormatFixed2(value)}</text>
        </div>
    );
};

export const DiscountRow = ({
    title = Messages.discount,
    subTitle,
    value,
    index,
    onClickRemove,
}: any) => (
    <div className={classNamePriceRow}>
        <div className="flex-column">
            <text>
                {`${title} ${index}`}
                {subTitle && <text>{` (${subTitle})`}</text>}
            </text>
            <div className="text-error cursor-pointer" onClick={onClickRemove}>
                {Messages.remove}
            </div>
        </div>
        <text className="text-success">{`-${StringUtils.moneyThaiFormatFixed2(
            value
        )}`}</text>
    </div>
);

export const FeeRow = ({ fee, hidden, onRemove }: any) => {
    if (hidden) return <div />;
    const orderFee = find(ORDER_FEES, (item) => item.id === fee.type);
    return (
        <div className={classNamePriceRow}>
            <div className="flex-column">
                <text>{(Messages as any)[orderFee?.label ?? ""]}</text>
                <div
                    className="text-error cursor-pointer"
                    onClick={onRemove}
                    hidden={!onRemove}
                >
                    {Messages.remove}
                </div>
            </div>
            <text>{StringUtils.moneyThaiFormatFixed2(fee?.value)}</text>
        </div>
    );
};

function OrderCreatePrice() {
    const { orderForm, orderState } = useContext(OrderCreateContext);
    const { fees, manualDiscounts, productVouchers, anonymousVouchers } =
        orderForm?.values;
    const { subtotal, total, discountList } = orderState;

    const onRemoveFee = (type: string) => {
        const result = filter(fees, (item) => item.type !== type);
        orderForm.setFieldValue("fees", result);
    };

    const onRemoveDiscount = (discount: any) => {
        const voucherResult = ObjectUtils.removeArrayById(
            productVouchers,
            discount?.id
        );
        const discountResult = ObjectUtils.removeArrayById(
            manualDiscounts,
            discount?.id
        );
        const anonymousVouchersResult = ObjectUtils.removeArrayById(
            anonymousVouchers,
            discount?.id
        );
        orderForm.setValues({
            ...orderForm?.values,
            manualDiscounts: discountResult,
            productVouchers: voucherResult,
            anonymousVouchers: anonymousVouchersResult,
        });
    };

    return (
        <div className="flex-column w-50">
            <PriceRow title={Messages.subTotal} value={subtotal} />
            {map(productVouchers, (voucher, index) => {
                return (
                    <ProductVoucherAppliedRow
                        key={`${voucher?.id}_${index}`}
                        data={voucher}
                    />
                );
            })}
            {map(anonymousVouchers, (voucher, index) => {
                return (
                    <AnonymousVoucherAppliedRow
                        key={`${voucher?.id}_${index}`}
                        voucher={voucher}
                    />
                );
            })}
            {map(discountList, (discount, index) => (
                <DiscountRow
                    value={discount?.total}
                    index={index + 1}
                    subTitle={discount.subTitle}
                    title={discount.title}
                    onClickRemove={() => onRemoveDiscount(discount)}
                />
            ))}
            {map(fees, (fee) => (
                <FeeRow fee={fee} onRemove={() => onRemoveFee(fee.type)} />
            ))}
            <div className={classNamePriceRow}>
                <text className="text-bold">{Messages.total}</text>
                <text className="text-bold">
                    {StringUtils.moneyThaiFormatFixed2(total as any)}
                </text>
            </div>
        </div>
    );
}

export default OrderCreatePrice;
