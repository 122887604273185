import { DateInput, ViewCollapse } from "d-react-components";
import { useContext } from "react";
import Messages from "../../../languages/Messages";
import { NewsCrudContext } from "../../../context/news";

const NewsCrudStatus = () => {
    const { newsForm } = useContext(NewsCrudContext);
    return (
        <ViewCollapse className="mt-3" label={Messages.publishedDate} required>
            <DateInput
                onChange={(value) =>
                    newsForm.setFieldValue("publishedDate", value)
                }
                value={newsForm.values.publishedDate}
                error={newsForm.errors.publishedDate}
                showTime
                format="DD/MM/YYYY HH:mm"
            />
        </ViewCollapse>
    );
};

export default NewsCrudStatus;
