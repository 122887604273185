import { useContext } from "react";
import LanguageSelectButton from "../../../common/button/LanguageSelectButton";
import { NewsCrudContext } from "../../../context/news";

const NewsLanguageButton = () => {
    const { newsLang, setNewsLang } = useContext(NewsCrudContext);
    return <LanguageSelectButton lang={newsLang} onChange={setNewsLang} />;
};

export default NewsLanguageButton;
