import {
    Button,
    InputText,
    Select,
    ViewCollapse,
    ViewTextError,
} from "d-react-components";
import { debounce, find, uniqBy } from "lodash";
import { useContext, useState } from "react";
import NewsAPI from "../../../api/news/NewsAPI";
import Image from "../../../common/Image";
import {
    BANNER_DESTINATION_TYPE,
    BANNER_DESTINATION_TYPES,
} from "../../../constant/banner";
import { BannerCrudContext } from "../../../context/banner";
import { INews } from "../../../interfaces/news";
import Messages from "../../../languages/Messages";
import SelectMediaManagerButton from "../../media/selectmedia/SelectMediaManagerButton";
import BannerLanguageButton from "./BannerLanguageButton";

const BannerCrudSliderContent = () => {
    const { bannerForm, bannerLang } = useContext(BannerCrudContext);
    const formValues = bannerForm.values;
    const formErrors = bannerForm.errors;
    const formValuesLang = bannerForm.values[bannerLang];

    const [newsList, setNewsList] = useState<INews[]>([]);

    const classNameInput = "mt-3";

    const onSearchNews = debounce((search) => {
        NewsAPI.list(search, { pageIndex: 1, pageSize: 100 }).then((res) =>
            setNewsList(res?.data?.data?.news ?? [])
        );
    }, 300);

    const dataSourceNews = uniqBy(
        [...newsList, ...(formValuesLang?.news ? [formValuesLang?.news] : [])],
        (item) => item.id
    );

    console.log("dataSourceNews", dataSourceNews, formValues);

    return (
        <div className="mt-3">
            <BannerLanguageButton />
            <ViewCollapse label={Messages.sliderContent} className="mt-3">
                {formValues[bannerLang]?.image && (
                    <Image
                        src={formValues[bannerLang]?.image}
                        className="image-reg-x-large"
                    />
                )}
                <SelectMediaManagerButton
                    isSingleSelect
                    onSelect={([media]: any) =>
                        bannerForm.setFieldValue(
                            `${bannerLang}.image`,
                            media.url
                        )
                    }
                    renderButton={() => (
                        <Button className="mt-3">{Messages.image}</Button>
                    )}
                />
                <ViewTextError
                    error={formErrors?.th?.image || formErrors?.en?.image}
                />
                <Select
                    dataSource={BANNER_DESTINATION_TYPES}
                    getLabel={(item) => (Messages as any)[item.label]}
                    className={classNameInput}
                    label={Messages.destinationType}
                    value={formValues?.[bannerLang]?.destinationType}
                    onChange={(value) =>
                        bannerForm.setFieldValue(
                            `${bannerLang}.destinationType`,
                            value
                        )
                    }
                    error={
                        formErrors?.th?.destinationType ||
                        formErrors?.en?.destinationType
                    }
                />

                <InputText
                    key={bannerLang}
                    hidden={
                        formValues?.[bannerLang]?.destinationType !==
                        BANNER_DESTINATION_TYPE.EXTERNAL
                    }
                    className={classNameInput}
                    form={bannerForm}
                    value={formValues?.[bannerLang]?.destinationUrl}
                    onChange={(event) =>
                        bannerForm.setFieldValue(
                            `${bannerLang}.destinationUrl`,
                            event.target.value
                        )
                    }
                    label={Messages.destinationUrl}
                    error={
                        formErrors?.th?.destinationUrl ||
                        formErrors?.en?.destinationUrl
                    }
                />

                <Select
                    hidden={
                        formValues?.[bannerLang]?.destinationType !==
                        BANNER_DESTINATION_TYPE.IN_APP_NEWS
                    }
                    dataSource={dataSourceNews}
                    getLabel={(item) => item[bannerLang]?.title}
                    className={classNameInput}
                    label={Messages.news}
                    value={[formValues?.[bannerLang]?.news?.id]}
                    onChange={(value) => {
                        const result = find(
                            dataSourceNews,
                            (item) => item.id === value
                        );
                        bannerForm.setFieldValue(`${bannerLang}.news`, result);
                    }}
                    onSearch={onSearchNews}
                    showSearch
                />
            </ViewCollapse>
        </div>
    );
};

export default BannerCrudSliderContent;
