import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find, isEmpty } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import WarehouseAPI from "../../../api/warehouse/WarehouseAPI";
import VerticalTabView from "../../../common/view/VerticalTabView";
import { AppStateContext } from "../../../context/app";
import { WarehouseCreateContext } from "../../../context/warehouse";
import { WarehouseSchema } from "../../../formschema/warehouse";

import {
    IWarehouse,
    mapWarehouseToServer,
} from "../../../interfaces/warehouse";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import WarehouseLanguageSelect from "../common/WarehouseLangButton";
import WarehouseCrudAddress from "./WarehouseCrudAddress";
import WarehouseCrudInfo from "./WarehouseCrudInfo";

const TABS = [
    {
        id: "info",
        label: Messages.warehouseInformation,
        component: <WarehouseCrudInfo />,
    },

    {
        id: "address",
        label: Messages.address,
        component: <WarehouseCrudAddress />,
    },
];

const WarehouseCrud = () => {
    const { setSiteConfig } = useContext(AppStateContext);

    const history = useHistory();
    const { warehouseId } = useParams<any>();
    const isEdit = useMemo(() => !isEmpty(warehouseId), [warehouseId]);
    const [selectedTab, setSelectedTab] = useState<any>(TABS[0]);
    const [warehouseLang, setWarehouseLang] = useState<any>("en");
    const warehouseForm = useFormik<IWarehouse>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: WarehouseSchema,
        onSubmit: (values) => {
            const body = mapWarehouseToServer(values);
            if (isEdit) {
                onUpdateWarehouse(values?.id, body);
            } else {
                onCreateWarehouse(body);
            }
        },
    });

    useEffect(() => {
        onLoadWarehouseDetail();
    }, []);

    const onLoadWarehouseDetail = async () => {
        if (!isEdit) return;
        const warehouseRes = await WarehouseAPI.detail(warehouseId);
        warehouseForm.setValues(warehouseRes);

        setSiteConfig({
            breadcrumb: [
                {
                    title: Messages.allWarehouse,
                    url: Path.WAREHOUSE_TABLE,
                },
                {
                    title: warehouseRes?.[warehouseLang]?.name,
                },
            ],
        });
    };

    const onCreateWarehouse = (body: any) => {
        Progress.show(
            {
                method: WarehouseAPI.create,
                params: [body],
            },
            (warehouseId: any) => {
                Notifications.showSuccess(Messages.createWarehouseSuccessfully);
                history.replace(
                    generatePath(Path.WAREHOUSE_DETAIL, {
                        warehouseId,
                    })
                );
            }
        );
    };
    const onUpdateWarehouse = (id: string, body: any) => {
        Progress.show(
            {
                method: WarehouseAPI.update,
                params: [id, body],
            },
            (warehouse: any) => {
                Notifications.showSuccess(Messages.updateWarehouseSuccessfully);
                warehouseForm.setValues(warehouse);
            }
        );
    };

    return (
        <WarehouseCreateContext.Provider
            value={{ warehouseForm, warehouseLang, setWarehouseLang, isEdit }}
        >
            <Header
                title={
                    isEdit ? Messages.warehouseUpdate : Messages.warehouseCreate
                }
                onSave={() => {
                    warehouseForm.handleSubmit();
                }}
            />
            <div className="px-4 mt-3">
                <WarehouseLanguageSelect />
            </div>
            <div className="h-100 overflow-auto">
                <div className="row p-4">
                    <div className="col-12 col-sm-4">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-8 col-12">
                        {
                            find(TABS, (item) => selectedTab.id === item.id)
                                ?.component
                        }
                    </form>
                </div>
            </div>
        </WarehouseCreateContext.Provider>
    );
};

export default WarehouseCrud;
