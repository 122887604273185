import { InputText, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudWeight = () => {
    const { productForm } = useContext(ProductDetailContext);
    return (
        <div>
            <ViewCollapse
                className="product-create__category mt-3"
                label={Messages.weight}
            >
                <InputText
                    className="flex-grow-1"
                    value={`${productForm.values.weight}`}
                    onChange={(event) =>
                        productForm.setFieldValue(
                            "weight",
                            parseInt(event.target.value, 10)
                        )
                    }
                    prefix="G"
                />
            </ViewCollapse>
        </div>
    );
};

export default ProductCrudWeight;
