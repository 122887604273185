import { ObjectUtils } from "d-react-components";
import { find, map } from "lodash";
import React, { useContext } from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";
import ProductSearchButton from "../../product/common/ProductSearchButton";
import BookingProductTable from "../common/BookingProductTable";

const BookingCreateProducts = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const { products = [] } = bookingForm.values;

    const onChangeProducts = (productParam = []) => {
        const values = map(productParam, (paramItem: any) => {
            const currentProduct = find(
                products,
                (item) => item.id === paramItem?.id
            );
            if (currentProduct) {
                return currentProduct;
            }
            return {
                ...paramItem,
                quantity: 1,
                openingCost: paramItem?.salePrice,
            };
        });
        bookingForm.setFieldValue("products", values);
    };

    const onUpdateProduct = (productParam: any) => {
        const values = ObjectUtils.updateArrayById(products, productParam);
        bookingForm.setFieldValue("products", values);
    };

    const onRemoveProduct = (id: string) => {
        const values = ObjectUtils.removeArrayById(products, id);
        bookingForm.setFieldValue("products", values);
    };

    return (
        <div className="card-container p-4">
            <div className="d-flex flex-center justify-content-between">
                <h5>{Messages.items}</h5>
                <ProductSearchButton
                    onSave={onChangeProducts}
                    defaultValue={products}
                    filterSource={{
                        visibility: true,
                        productType: [
                            PRODUCT_TYPE.DEFAULT_PRODUCT,
                            PRODUCT_TYPE.SERVICE_PRODUCT,
                        ],
                    }}
                />
            </div>
            <BookingProductTable
                products={products}
                onUpdateProduct={onUpdateProduct}
                onRemove={onRemoveProduct}
            />
        </div>
    );
};

export default BookingCreateProducts;
