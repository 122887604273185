import { Dropdown, Menu } from "antd";
import {
    Checkbox,
    Icon,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import { useContext, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import Image from "../../../common/Image";
import InputTextForm from "../../../common/input/InputTextForm";
import { CONFIG } from "../../../configuration/AppConfig";
import { BOOKING_STATUS, BOOKING_STATUSES } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import {
    BookingCancelSchema,
    BookingMarkCompleteSchema,
} from "../../../formschema/booking";
import Messages from "../../../languages/Messages";

const BookingMoreAction = ({ onClickActions }: any) => {
    const ACTIONS_LIST = [
        {
            id: "markAsComplete",
            label: Messages.markAsComplete,
        },
        {
            id: "cancelBooking",
            label: Messages.cancelBooking,
        },
        {
            id: "sendBookingConfirm",
            label: Messages.sendBookingConfirm,
        },
    ];

    const menu = (
        <Menu>
            {map(ACTIONS_LIST, (action) => (
                <Menu.Item
                    key={action.id}
                    onClick={() => onClickActions(action.id)}
                >
                    {action.label}
                </Menu.Item>
            ))}
        </Menu>
    );
    return (
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="flex-center cursor-pointer ml-3"
        >
            <div>
                <text>{Messages.moreActions}</text>
                <Icon name="expand_more" size="small" className="ml-1" />
            </div>
        </Dropdown>
    );
};

const BookingSendEmailModal = ({
    open,
    onClose,
    onSend,
    title,
    description,
}: any) => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const sendConfirmForm = useFormik<any>({
        initialValues: {
            email: bookingDetail?.contact?.email,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const { email } = values;
            onSend(email);
            onClose();
        },
    });

    const formValues = sendConfirmForm?.values;
    const formErrors = sendConfirmForm?.errors;

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            onSave={() => sendConfirmForm.handleSubmit()}
        >
            <text>{description}</text>
            <Checkbox
                checked={formValues?.sendOtherEmail}
                onChange={() =>
                    sendConfirmForm.setFieldValue(
                        "sendOtherEmail",
                        !formValues?.sendOtherEmail
                    )
                }
                label={Messages.wantToSendToDifferentEmail}
                className="mt-3"
            />
            <InputTextForm
                hidden={!formValues?.sendOtherEmail}
                keyData="email"
                form={sendConfirmForm}
                className="mt-3"
            />
        </Modal>
    );
};

const BookingConfirmCancelModal = ({ open, onClose }: any) => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);
    const cancelForm = useFormik<any>({
        initialValues: {
            remark: "",
            id: bookingDetail?.id,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BookingCancelSchema,
        onSubmit: (values: any) => {
            onCancelBooking(values);
        },
    });

    const onCancelBooking = (params: any) =>
        Progress.show(
            {
                method: BookingAPI.cancel,
                params: [params],
            },
            (bookingDetailRes: any) => {
                setBookingDetail(bookingDetailRes);
                onClose();
                Notifications.showSuccess(Messages.cancelBookingSuccessfully);
            }
        );

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => cancelForm.handleSubmit()}
        >
            <text>{Messages.areYouSureToCancelBooking}</text>
            <InputTextForm
                keyData="remark"
                form={cancelForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};
const BookingMarkCompleteModal = ({ open, onClose }: any) => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);
    const markForm = useFormik<any>({
        initialValues: {
            remark: "",
            bookingId: bookingDetail?.id,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BookingMarkCompleteSchema,
        onSubmit: (values: any) => {
            onMarkCompleteBooking(values);
        },
    });

    const onMarkCompleteBooking = (params: any) =>
        Progress.show(
            {
                method: BookingAPI.markComplete,
                params: [params],
            },
            (bookingDetailRes: any) => {
                setBookingDetail(bookingDetailRes);
                onClose();
                Notifications.showSuccess(Messages.markCompleteSuccessfully);
            }
        );

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => markForm.handleSubmit()}
        >
            <text>{Messages.markCompleteBookingDesc}</text>
            <InputTextForm
                keyData="remark"
                form={markForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const BookingDetailHeader = () => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const { createdAt, status, createBy, bookingNo } = bookingDetail;
    const [openSendConfirm, setOpenSendConfirm] = useState(false);
    const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
    const [openMarkComplete, setOpenMarkComplete] = useState(false);

    const onClickActions = (actionId: string) => {
        switch (actionId) {
            case "sendBookingConfirm":
                setOpenSendConfirm(true);
                break;
            case "cancelBooking":
                onClickCancelBooking();
                break;
            case "markAsComplete":
                onClickMarkComplete();
                break;
            default:
                break;
        }
    };

    const onClickCancelBooking = () => {
        if (
            status === BOOKING_STATUS.PENDING ||
            status === BOOKING_STATUS.SCHEDULED ||
            status === BOOKING_STATUS.CANCELLED
        ) {
            Notifications.showError(Messages.bookingStatusNotValidToCancel);
            return;
        }
        setOpenCancelConfirm(true);
    };
    const onClickMarkComplete = () => {
        if (status !== BOOKING_STATUS.PROCESSING) {
            Notifications.showError(Messages.bookingStatusNotValid);
            return;
        }
        setOpenMarkComplete(true);
    };

    const onSendEmailConfirm = (email: string) =>
        Progress.show(
            {
                method: BookingAPI.sendMailBookingCreated,
                params: [bookingDetail?.id, email],
            },
            () => {
                Notifications.showSuccess(Messages.sendEmailConfirmSuccess);
            }
        );

    const onClickCopyFOUrl = () => {
        navigator.clipboard.writeText(
            `${CONFIG.FRONT_BOOKING_URL}?id=${bookingDetail?.id}&&locale=en`
        );
        Notifications.showSuccess(Messages.copiedToClipboard);
    };

    return (
        <div className="card-container d-flex p-4">
            <div className="flex-column flex-grow-1">
                <div className="d-flex align-items-center">
                    <h5>{`${Messages.booking} #${bookingNo}`}</h5>
                    <ViewLabelStatus
                        className="ml-3"
                        status={status}
                        listStatus={BOOKING_STATUSES}
                        getLabel={(item) => (item?.label ?? "")?.toUpperCase()}
                    />
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div className="d-flex align-items-center">
                        <Icon name="event_available" className="small" />
                        <small className="ml-1">
                            {`${Messages.createdOn} ${TimeUtils.toDateTime(
                                createdAt
                            )}`}
                        </small>
                    </div>
                    {/* <div className="d-flex align-items-center ml-3">
                        <Icon name="alarm" className="small" />
                        <small className="ml-1">
                            Expires on 19/02/2020 at 17:55
                        </small>
                    </div>
                    <div className="d-flex align-items-center ml-3">
                        <Icon name="alarm" className="small" />
                        <small className="ml-1">
                            Booking on 23/02/2020 at 10:00
                        </small>
                    </div> */}
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={onClickCopyFOUrl}
                    >
                        <Icon name="insert_link" />
                        <text className="ml-2">{Messages.bookingUrl}</text>
                    </div>
                    <div className="d-flex align-items-center ml-3 cursor-pointer">
                        <Icon name="print" />
                        <text className="ml-2">{Messages.printBooking}</text>
                    </div>
                    <BookingMoreAction onClickActions={onClickActions} />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <text>{`${Messages.by} ${createBy?.username}`}</text>
                <Image
                    className="image-square-xx-small ml-3"
                    src={createBy?.avatar}
                    style={{ borderRadius: "15px" }}
                />
            </div>
            {openSendConfirm && (
                <BookingSendEmailModal
                    title={Messages.sendConfirm}
                    description={`${Messages.sendBookingConfirmToThisEmail} ${bookingDetail?.contact?.email}`}
                    open={openSendConfirm}
                    onClose={() => setOpenSendConfirm(false)}
                    onSend={onSendEmailConfirm}
                />
            )}
            {openCancelConfirm && (
                <BookingConfirmCancelModal
                    open={openCancelConfirm}
                    onClose={() => setOpenCancelConfirm(false)}
                />
            )}
            {openMarkComplete && (
                <BookingMarkCompleteModal
                    open={openMarkComplete}
                    onClose={() => setOpenMarkComplete(false)}
                />
            )}
        </div>
    );
};

export default BookingDetailHeader;
