import { DialogManager, Notifications, Progress } from "d-react-components";
import { concat, find, forEach, reduce } from "lodash";
import React, { useContext, useMemo } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import { ORDER_STATUS } from "../../../constant/order";
import { OrderDetailContext } from "../../../context/order";
import { IServiceAddress, mapAddressToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import OrderServiceAddress from "../common/OrderServiceAddress";

const OrderDetailShippingAddress = () => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const { id, shippings, customer, status, deliveries, products } =
        orderDetail;

    const isDeliveryAllItems = () => {
        const totalItems = reduce(
            products,
            (sum, item: any) => sum + item?.quantity,
            0
        );
        let itemsDelivery: any[] = [];
        forEach(deliveries, (deliveryItem) => {
            itemsDelivery = concat(itemsDelivery, deliveryItem?.products ?? []);
        });

        const totalDeliveryItems = reduce(
            itemsDelivery ?? [],
            (sum, item) => {
                return sum + item.shippingQty;
            },
            0
        );

        return totalItems - totalDeliveryItems === 0;
    };

    const isHideAdd = useMemo(() => {
        if (
            status === ORDER_STATUS.PENDING_PAYMENT ||
            status === ORDER_STATUS.PENDING_CONFIRMATION
        ) {
            return false;
        }

        if (
            status === ORDER_STATUS.ORDER_PROCESSING ||
            status === ORDER_STATUS.DELIVERY_PROCESSING
        ) {
            return isDeliveryAllItems();
        }

        return true;
    }, [status]);

    const getHiddenCrud = (address: IServiceAddress) => {
        if (
            status === ORDER_STATUS.PENDING_PAYMENT ||
            status === ORDER_STATUS.PENDING_CONFIRMATION
        ) {
            return false;
        }

        if (
            status === ORDER_STATUS.ORDER_PROCESSING ||
            status === ORDER_STATUS.DELIVERY_PROCESSING
        ) {
            const isCreatedDelivery = find(
                deliveries,
                (deliveryItem) => deliveryItem?.shipping?.id === address?.id
            );

            if (isCreatedDelivery) {
                return true;
            }
            return isDeliveryAllItems();
        }

        return true;
    };

    const onUpdateAddress = (addressUpdate: any) => {
        const addressInput = mapAddressToSer(addressUpdate);
        Progress.show(
            {
                method: OrderAPI.updateServiceAddress,
                params: [id, addressUpdate?.id, addressInput],
            },
            setOrderDetail
        );
    };

    const onAddAddress = ([addressAdd]: any) => {
        const address = mapAddressToSer(addressAdd);
        Progress.show(
            {
                method: OrderAPI.addServiceAddress,
                params: [id, address],
            },
            (orderDetailRes: any) => {
                setOrderDetail(orderDetailRes);
                Notifications.showSuccess(Messages.addAddressSuccess);
            }
        );
    };

    const onDeleteAddress = (addressId: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureDeleteAddress,
            () =>
                Progress.show(
                    {
                        method: OrderAPI.deleteServiceAddress,
                        params: [orderDetail?.id, addressId],
                    },
                    setOrderDetail
                )
        );
    };

    return (
        <OrderServiceAddress
            onChangeAddress={onUpdateAddress}
            onAddAddress={onAddAddress}
            onDeleteAddress={onDeleteAddress}
            addresses={shippings}
            customerId={customer?.id as string}
            hiddenAdd={isHideAdd}
            getHiddenDelete={getHiddenCrud}
            getHiddenEdit={getHiddenCrud}
        />
    );
};

export default OrderDetailShippingAddress;
