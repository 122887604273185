import { Dropdown, Menu } from "antd";
import {
    Checkbox,
    DateInput,
    Icon,
    Modal,
    Notifications,
    Progress,
    StringUtils,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, includes, map, toUpper } from "lodash";
import { useContext, useState } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import Image from "../../../common/Image";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { CONFIG } from "../../../configuration/AppConfig";
import {
    ORDER_PAYMENT_STATUSES,
    ORDER_STATUS,
    ORDER_STATUSES,
} from "../../../constant/order";
import {
    C2P,
    PAYMENT_STATUS,
    PAYMENT_STATUSES,
} from "../../../constant/payment";
import { ORDER_ACCESS_TYPE, PERMISSION_MODULE } from "../../../constant/user";
import { OrderDetailContext } from "../../../context/order";
import {
    OrderCancelSchema,
    OrderExpiryDateSchema,
    OrderPaymentResetSchema,
} from "../../../formschema/order";
import { useUserPermission } from "../../../hoook/permission";
import { IOrder } from "../../../interfaces/order";
import { IPayment } from "../../../interfaces/payment";
import Messages from "../../../languages/Messages";
import { OrderDeliveryCrudModal } from "./OrderDetailDelivery";
import OrderRequestRefundModal from "./OrderRequestRefundModal";

const OrderChangeExpiryDateModal = ({ open, onClose }: any) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const expiryDateForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderExpiryDateSchema,
        onSubmit: (values: any) => {
            const { date, remark } = values;
            Progress.show(
                {
                    method: OrderAPI.changeExpiryDate,
                    params: [orderDetail?.id, date, remark],
                },
                (orderDetailRes: any) => {
                    setOrderDetail(orderDetailRes);
                    Notifications.showSuccess(
                        Messages.changeExpiringDateSuccess
                    );
                    onClose();
                }
            );
        },
    });

    const formValues = expiryDateForm?.values;
    const formErrors = expiryDateForm?.errors;

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.changeExpiringDate}
            onSave={() => expiryDateForm.handleSubmit()}
        >
            <DateInput
                label={Messages.date}
                onChange={(value) =>
                    expiryDateForm.setFieldValue("date", value)
                }
                value={formValues.date}
                error={formErrors.date as any}
                showTime
                format="DD/MM/YYYY HH:mm"
            />
            <InputTextForm
                keyData="remark"
                form={expiryDateForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const OrderCancelModal = ({ open, onClose }: any) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const cancelForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderCancelSchema,
        onSubmit: (values: any) => {
            const { remark } = values;
            Progress.show(
                {
                    method: OrderAPI.cancel,
                    params: [orderDetail?.id, remark],
                },
                (orderDetailRes: any) => {
                    setOrderDetail(orderDetailRes);
                    Notifications.showSuccess(Messages.cancelOrderSuccess);
                    onClose();
                }
            );
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.cancelOrder}
            onSave={() => cancelForm.handleSubmit()}
        >
            <text>{Messages.confirmCancelOrderBefore}</text>
            <InputTextForm
                keyData="remark"
                form={cancelForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const OrderSendEmailModal = ({
    open,
    onClose,
    onSend,
    title,
    description,
}: any) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const sendConfirmForm = useFormik<any>({
        initialValues: {
            email: orderDetail?.orderContact?.email,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const { email } = values;
            onSend(email);
            onClose();
        },
    });

    const formValues = sendConfirmForm?.values;
    const formErrors = sendConfirmForm?.errors;

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            onSave={() => sendConfirmForm.handleSubmit()}
        >
            <text>{description}</text>
            <Checkbox
                checked={formValues?.sendOtherEmail}
                onChange={() =>
                    sendConfirmForm.setFieldValue(
                        "sendOtherEmail",
                        !formValues?.sendOtherEmail
                    )
                }
                label={Messages.wantToSendToDifferentEmail}
                className="mt-3"
            />
            <InputTextForm
                hidden={!formValues?.sendOtherEmail}
                keyData="email"
                form={sendConfirmForm}
                className="mt-3"
            />
        </Modal>
    );
};

const OrderResetPaymentModal = ({ open, onClose }: any) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const resetForm = useFormik<any>({
        initialValues: { orderId: orderDetail?.id } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderPaymentResetSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: OrderAPI.resetPayment,
                    params: [values],
                },
                () => {
                    Notifications.showSuccess(
                        Messages.resetPaymentSuccessfully
                    );
                    onClose();
                    window.location.reload();
                }
            );
        },
    });

    const dataPayments = filter(
        orderDetail?.splitPayment,
        (item) =>
            (item.status === PAYMENT_STATUS.CONFIRMED ||
                item.status === PAYMENT_STATUS.REJECTED) &&
            item.paymentMethod?.type !== C2P
    );

    const formValues = resetForm?.values;
    const formErrors = resetForm?.errors;

    const renderPaymentItem = (payment: IPayment) => {
        const { paymentMethod, amount, status } = payment;

        const paymentStatus = find(
            PAYMENT_STATUSES,
            (item) => item.id === status
        );
        return `Payment - ${
            paymentMethod?.type
        } - ${StringUtils.moneyThaiFormat(amount)} - ${
            (Messages as any)[paymentStatus?.label as any]
        }`;
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.resetPayment}
            onSave={() => resetForm.handleSubmit()}
        >
            <InputSelectForm
                label={Messages.payment}
                keyData="splitPaymentId"
                form={resetForm}
                dataSource={dataPayments}
                getLabel={renderPaymentItem}
            />
            <InputTextForm
                keyData="remark"
                form={resetForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const OrderMoreAction = ({ onClickActions }: any) => {
    const ACTIONS_LIST = [
        { id: "changeExpiryDate", label: Messages.changeExpiringDate },
        { id: "createDelivery", label: Messages.createDelivery },
        { id: "cancelOrder", label: Messages.cancelOrder },
        // { id: "requestRefund", label: Messages.requestRefund },
        { id: "resetPayment", label: Messages.resetPayment },
        {
            id: "sendConfirmation",
            label: Messages.sendEmailConfirm,
        },
        {
            id: "sendInvoiceCustomer",
            label: Messages.sendInvoiceCustomer,
        },
    ];

    const menu = (
        <Menu>
            {map(ACTIONS_LIST, (action) => (
                <Menu.Item
                    key={action.id}
                    onClick={() => onClickActions(action.id)}
                >
                    {action.label}
                </Menu.Item>
            ))}
        </Menu>
    );
    return (
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="flex-center cursor-pointer ml-2"
        >
            <div>
                <text>{Messages.moreActions}</text>
                <Icon name="expand_more" size="small" className="ml-1" />
            </div>
        </Dropdown>
    );
};

const OrderActionButton = ({ iconName, content, onClick }: any) => (
    <div
        className="d-flex align-items-center cursor-pointer mr-3"
        onClick={onClick}
    >
        <Icon name={iconName} />
        <text className="ml-2">{content}</text>
    </div>
);

const OrderDetailHeader = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const [openExpiryDate, setOpenExpiryDate] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openSendConfirm, setOpenSendConfirm] = useState(false);
    const [openSendInvoice, setOpenSendInvoice] = useState(false);
    const [openCreateDelivery, setOpenCreateDelivery] = useState(false);
    const [openResetPayment, setOpenResetPayment] = useState(false);
    const [openRequestRefund, setOpenRequestRefund] = useState(false);
    const isGrantCancelOrder = useUserPermission(
        PERMISSION_MODULE.ORDER,
        ORDER_ACCESS_TYPE.CANCEL_ORDER
    );

    const {
        createdAt,
        status,
        createBy,
        orderNo,
        expiredAt,
        paymentStatus,
        deliveryProducts,
        creditNote,
    } = orderDetail as IOrder;

    const onClickActions = (actionId: string) => {
        switch (actionId) {
            case "changeExpiryDate":
                onClickChangeExpiryDate();
                break;
            case "createDelivery":
                onClickCreateDelivery();
                break;
            case "cancelOrder":
                onClickCancelOrder();
                break;
            case "sendConfirmation":
                setOpenSendConfirm(true);
                break;
            case "sendInvoiceCustomer":
                setOpenSendInvoice(true);
                break;
            case "resetPayment":
                setOpenResetPayment(true);
                break;
            case "requestRefund":
                onClickRequestRefund();
                break;
            default:
                break;
        }
    };

    /**
     * user only can change expiry date when order's status is in [
          OrderStatus.PENDING_PAYMENT,
          OrderStatus.PARTIALLY_PAID,
          OrderStatus.PENDING_CONFIRMATION,
          OrderStatus.EXPIRED,
        ]
     */
    const onClickChangeExpiryDate = () => {
        const { status } = orderDetail;
        const validStatus = [
            ORDER_STATUS.PENDING_PAYMENT,
            ORDER_STATUS.PARTIALLY_PAID,
            ORDER_STATUS.PENDING_CONFIRMATION,
            ORDER_STATUS.EXPIRED,
        ];
        const isValidStatus = includes(validStatus, status);
        if (!isValidStatus) {
            Notifications.showError(Messages.invalidStatusChangeExpiryDate);
            return;
        }
        setOpenExpiryDate(true);
    };

    /**
        * Rule cancel order như sau e nha:
        * Pending, Pending Confirmation: Được cancel thoải mái (ko cần check quyền permission API “Cancel Order”)
        * Order Processing: Khi cancel order e nhớ check quyền đứa nào có permission API “Cancel Order” thì mới được phép cancel nha e.
        * Delivery Processing: Khi cancel order e nhớ check quyền đứa nào có permission API “Cancel Order” thì mới được phép cancel nha e.
            * Sau khi đã check có permission rồi, khi cancel order e nhớ chặn và alert cho nó là
            * “You cannot cancel this order because there is an on-going delivery.
            * Please cancel it the delivery first before cancelling this order”
        * Completed:  … để a suy nghĩ thêm chỗ này
        * Cancelled:  ko cho nó cancel nữa.

     */
    const onClickCancelOrder = () => {
        if (
            (status === ORDER_STATUS.ORDER_PROCESSING ||
                status === ORDER_STATUS.DELIVERY_PROCESSING) &&
            !isGrantCancelOrder
        ) {
            Notifications.showError(Messages.notGrantPermission);
            return;
        }
        if (status === ORDER_STATUS.DELIVERY_PROCESSING) {
            Notifications.showError(Messages.cantCancelOnGoingDelivery);
            return;
        }

        const statusNptAllowCancel = [
            ORDER_STATUS.COMPLETED,
            ORDER_STATUS.EXPIRED,
            ORDER_STATUS.CANCELLED,
        ];
        if (includes(statusNptAllowCancel, status)) {
            Notifications.showError(Messages.cantCancelOrder);
            return;
        }
        setOpenCancel(true);
    };

    /**
     * user can create delivery when:
     * * * order's status is processing or delivery processing
     * * * order's products must contain simple product
     */
    const onClickCreateDelivery = () => {
        if (
            status !== ORDER_STATUS.ORDER_PROCESSING &&
            status !== ORDER_STATUS.DELIVERY_PROCESSING
        ) {
            Notifications.showError(Messages.onlyCreateDeliveryWhenProcessing);
            return;
        }

        if (deliveryProducts.length === 0) {
            Notifications.showError(Messages.onlyCreateDeliveryContainSimple);
            return;
        }
        setOpenCreateDelivery(true);
    };

    const onClickRequestRefund = () => {
        if (status !== ORDER_STATUS.CANCELLED) {
            Notifications.showError(Messages.onlyRequestRefundWhenCancel);
            return;
        }

        setOpenRequestRefund(true);
    };

    const onClickCopyFOUrl = () => {
        navigator.clipboard.writeText(
            `${CONFIG.FRONT_ORDER_URL}?id=${orderDetail?.id}&&locale=en`
        );
        Notifications.showSuccess(Messages.copiedToClipboard);
    };

    const onSendEmailConfirm = (email: string) =>
        Progress.show(
            {
                method: OrderAPI.sendMailOrderCreated,
                params: [orderDetail?.id, email],
            },
            () => {
                Notifications.showSuccess(Messages.sendEmailConfirmSuccess);
            }
        );

    const onSendInvoice = (email: string) =>
        Progress.show(
            {
                method: OrderAPI.sendMailInvoice,
                params: [orderDetail?.id, email],
            },
            () => {
                Notifications.showSuccess(Messages.sendEmailInvoiceSuccess);
            }
        );

    return (
        <div className="card-container d-flex p-4">
            <div className="flex-column flex-grow-1">
                <div className="d-flex align-items-center">
                    <h5>{`${Messages.order} #${orderNo}`}</h5>
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div className="d-flex align-items-center">
                        <Icon name="event_available" className="small" />
                        <small className="ml-1">
                            {`${Messages.createdOn} ${TimeUtils.toDateTime(
                                createdAt
                            )}`}
                        </small>
                    </div>
                    <div className="d-flex align-items-center ml-3">
                        <Icon name="alarm" className="small" />
                        <small className="ml-1" hidden={!expiredAt}>
                            {`${Messages.expireAt} ${TimeUtils.toDateTime(
                                expiredAt
                            )}`}
                        </small>
                        <small className="ml-1" hidden={!!expiredAt}>
                            {Messages.noExpireDate}
                        </small>
                    </div>
                    <ViewLabelStatus
                        className="ml-3"
                        status={status}
                        listStatus={ORDER_STATUSES}
                        getLabel={(item) =>
                            toUpper((Messages as any)[item.label])
                        }
                    />
                    {paymentStatus && (
                        <ViewLabelStatus
                            className="ml-3"
                            status={paymentStatus}
                            listStatus={ORDER_PAYMENT_STATUSES}
                            getLabel={(item) =>
                                toUpper((Messages as any)[item.label])
                            }
                        />
                    )}
                    {creditNote && (
                        <ViewLabelStatus
                            color="#29B3BC"
                            className="ml-3"
                            content={Messages.onCreditNote}
                        />
                    )}
                </div>
                <div className="d-flex align-items-center mt-3">
                    <OrderActionButton
                        iconName="link"
                        content={Messages.orderURL}
                        onClick={onClickCopyFOUrl}
                    />
                    <OrderActionButton
                        iconName="print"
                        content={Messages.printOrder}
                    />
                    <OrderMoreAction onClickActions={onClickActions} />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <text>{`${Messages.by} ${createBy?.username}`}</text>
                <Image
                    className="image-square-xx-small ml-3"
                    src={createBy?.avatar}
                    style={{ borderRadius: "15px" }}
                />
            </div>
            {openExpiryDate && (
                <OrderChangeExpiryDateModal
                    open={openExpiryDate}
                    onClose={() => setOpenExpiryDate(false)}
                />
            )}
            {openCancel && (
                <OrderCancelModal
                    open={openCancel}
                    onClose={() => setOpenCancel(false)}
                />
            )}
            {openSendConfirm && (
                <OrderSendEmailModal
                    title={Messages.sendConfirm}
                    description={`${Messages.sendConfirmToThisEmail} ${orderDetail?.orderContact?.email}`}
                    open={openSendConfirm}
                    onClose={() => setOpenSendConfirm(false)}
                    onSend={onSendEmailConfirm}
                />
            )}

            {openSendInvoice && (
                <OrderSendEmailModal
                    title={Messages.sendInvoice}
                    description={`${Messages.sendInvoiceToThisEmail} ${orderDetail?.orderContact?.email}`}
                    open={openSendInvoice}
                    onClose={() => setOpenSendInvoice(false)}
                    onSend={onSendInvoice}
                />
            )}
            {openCreateDelivery && (
                <OrderDeliveryCrudModal
                    open={openCreateDelivery}
                    onClose={() => setOpenCreateDelivery(false)}
                />
            )}
            {openResetPayment && (
                <OrderResetPaymentModal
                    open={openResetPayment}
                    onClose={() => setOpenResetPayment(false)}
                />
            )}
            {openRequestRefund && (
                <OrderRequestRefundModal
                    open={openRequestRefund}
                    onClose={() => setOpenRequestRefund(false)}
                />
            )}
        </div>
    );
};

export default OrderDetailHeader;
