import { DialogManager, Notifications, Progress } from "d-react-components";
import CustomerAPI from "../api/customer/CustomerAPI";
import Messages from "../languages/Messages";

export const useDeleteCustomerAdd = (
    customerId: string,
    addressId: string,
    onSuccessDelete: () => void,
    method = "deleteServiceAddress"
) => {
    const onClickDelete = () =>
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureDeleteAddress,
            onDeleteAddress
        );

    const onDeleteAddress = () => {
        Progress.show(
            {
                method: (CustomerAPI as any)[method],
                params: [customerId, addressId],
            },
            () => {
                Notifications.showSuccess(Messages.deleteAddressSuccess);
                onSuccessDelete && onSuccessDelete();
            }
        );
    };
    return { onClickDelete, onDeleteAddress };
};
