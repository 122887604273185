import { AwesomeTableComponent, Button, HeaderTable } from "d-react-components";
import { debounce, join, map } from "lodash";
import React, { useRef, useState } from "react";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import { PRODUCT_TYPE } from "../../../constant/product";
import { IInventory } from "../../../interfaces/inventory";
import Messages from "../../../languages/Messages";
import { isFilteredBody } from "../../../utils/Utils";
import InventoryAllocationModal from "../common/InventoryAllocationModal";
import OnHoldAllocationModal from "../common/OnHoldAllocationModal";
import InventoryImportModal from "../import/InventoryImportModal";
import InventoryExportModal from "./InventoryExportModal";
import InventoryFilterModal from "./InventoryFilterModal";
import InventoryHistoryModal from "./InventoryHistoryModal";

const InventoryOnHold = ({ onHoldQty, inventory }: any) => {
    const [open, setOpen] = useState(false);
    if (!onHoldQty) return <div>0</div>;
    return (
        <div>
            <div
                className="cursor-pointer text-primary"
                onClick={() => setOpen(true)}
            >
                {onHoldQty}
            </div>
            {open && (
                <OnHoldAllocationModal
                    productId={inventory?.productId}
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </div>
    );
};

const InventoryTotalQty = ({ totalQty, inventory }: any) => {
    const [open, setOpen] = useState(false);
    if (inventory.productType === PRODUCT_TYPE.GROUP_PRODUCT) {
        return totalQty;
    }
    return (
        <div>
            <div
                className="cursor-pointer text-primary"
                onClick={() => setOpen(true)}
            >
                {totalQty}
            </div>
            {open && (
                <InventoryAllocationModal
                    productId={inventory?.productId}
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </div>
    );
};

const InventoryTable = () => {
    const [openImport, setOpenImport] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openHistory, setOpenHistory] = useState<any>(null);
    const [filterState, setFilterState] = useState<any>(null);
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const columns: any = [
        {
            title: Messages.name,
            dataIndex: "name",
        },
        {
            title: Messages.sku,
            dataIndex: "SKU",
        },

        {
            title: Messages.category,
            dataIndex: "category",
            render: (category: any) =>
                join(
                    map(category, (item) => item.name),
                    ", "
                ),
        },
        {
            title: Messages.currentQty,
            dataIndex: "allAvailableCW",
            titleTooltip: Messages.currentQtyToolTip,
        },

        {
            title: Messages.onHold,
            dataIndex: "allOnHoldCW",
            titleTooltip: Messages.onHoldQtyToolTip,
            render: (onHoldQty: any, inventory: IInventory) => (
                <InventoryOnHold onHoldQty={onHoldQty} inventory={inventory} />
            ),
        },
        {
            title: Messages.totalQty,
            dataIndex: "quantity",
            titleTooltip: Messages.totalQtyToolTip,
            render: (qty: any, inventory: IInventory) => (
                <InventoryTotalQty totalQty={qty} inventory={inventory} />
            ),
        },

        {
            title: Messages.history,
            dataIndex: "",
            render: (item: any) => (
                <Button
                    iconName="history"
                    onClick={() => setOpenHistory({ id: item.id })}
                    variant="trans"
                />
            ),
        },
    ];

    const source = (pagingData: any) => {
        return InventoryAPI.list(searchRef.current, pagingData, filterState);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const onFilter = (values: {}) => {
        setFilterState(values);
        tableRef.current.refresh();
    };

    return (
        <div className="p-4">
            <HeaderTable
                label={Messages.inventory}
                onClickImport={() => setOpenImport(true)}
                onClickExport={() => setOpenExport(true)}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
                isFiltered={isFilteredBody(filterState)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => {
                    return res?.data?.data?.inventory ?? [];
                }}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
            />
            {openImport && (
                <InventoryImportModal
                    open={openImport}
                    onClose={() => setOpenImport(false)}
                    onImportSuccess={() => tableRef.current.refresh()}
                />
            )}
            {openExport && (
                <InventoryExportModal
                    open={openExport}
                    onClose={() => setOpenExport(false)}
                />
            )}

            {openFilter && (
                <InventoryFilterModal
                    defaultValue={filterState}
                    onFilter={onFilter}
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}

            {openHistory && (
                <InventoryHistoryModal
                    inventoryId={openHistory?.id}
                    open={openHistory}
                    onClose={() => setOpenHistory(null)}
                />
            )}
        </div>
    );
};

export default InventoryTable;
