export default {
    successBankText:
        "Thank you very much. Your payment via Bank Transfer has been successfully confirmed.  Once your order is ready for delivery, we will update the tracking code via your email. Should you have any question, please contact our customer support via channels below",
    success2C2PText:
        "Thank you very much. Your order has been successfully paid. via 2C2P. You shall receive the 2C2P receipt via your email shortly. Once your order is ready for delivery, we will update the tracking code via your email. Should you have any question, please contact our customer support via channels below",
    orderSummary: "Order summary",
    invoiceNo: "Invoice No.",
    createAt: "Created at ",
    expireAt: "Expire at ",
    skuName: "SKU: ",
    quantityName: "Quantity: ",
    costName: "Cost",
    priceName: "Price",
    subTotalName: "Sub total",
    shippingCost: "Shipping cost",
    free: "Free",
    discount: "Discount",
    totalName: "Total",
    bankTransfer: "Bank Transfer",
    "bank-transfer": "Bank Transfer",
    c2pName: "Debit/Credit Card by 2C2P",
    "2c2p": "Debit/Credit Card by 2C2P",
    paymentShould: "Payment should be made before ",
    onceYouMadeThePay:
        ". Once you made the payment, please attach the receipt of the payment below",
    receiptOfPayment: "Receipt of payment",
    dragAndDrop: "Drag and drop file here (Maximum 2 files per upload)",
    or: "-- or --",
    browse: "Browse",
    processName: "Process",
    shippingInformation: "Shipping Information",
    fullNameTitle: "Full name",
    nickName: "Nick name",
    emailConfirm: "Email Confirmation",
    typeOfPlace: "Type of place",
    addressName: "Address (House No., Road, Sub-district.)",
    provinceName: "Province",
    postcode: "Postcode",
    noteName: "Note",
    fullNameRequired: "Full name is required!",
    nickNameRequired: "Nick name is required!",
    phoneNumberRequired: "Phone number is required!",
    addressNameRequired: "Address is required!",
    provinceRequire: "Province is required!",
    placeIsRequired: "Place is required!",
    postCodeRequired: "Postcode is required!",
    notValidEmail: "Not valid email",
    needAllRequiredInfo: "You need fill all require information!",
    uploadFileFirst: "Let's upload the receipt of the payment first!",
    maximum5Mb: "Can not upload these files! Maximum upload file size: 5MB",
    maximum2Files: "You can only upload two files per time!",
    pay2c2p:
        "Pay securely by using 2C2P. Click proceed to be redirected to 2C2P system to complete the payment.",

    save: "Save",
    edit: "Edit",
    customerPayment: "Customer Payment",
    debitBy2C2P: "Debit/Credit card by 2C2P",
    successBy2C2P: "Successfully paid and approved by 2C2P",
    accountingConfirm: "Accounting Confirmation",
    confirmBy: "Confirmed by ",
    view2C2PLog: "View 2C2P logs",
    printOrder: "Print Order",
    orderURL: "Order URL",
    moreAction: "More Actions",
    expired: "Expired",
    cost: "Cost",
    total: "Total",
    tax: "Tax",
    taxName: "Tax",

    leaveAComment: "Leave a comment",
    attach: "Attach",
    send: "Send",
    customer: "Customer",
    shippingAddress: "Shipping address",
    cancel: "Cancel",
    name: "Name",
    contactInfo: "Contact Information",
    deliveryTracking: "Delivery Tracking",
    delete: "Delete",
    trackPackage: "Track package",
    copySuccess: "Copied!",
    info: "Info",
    success: "Success",
    updateFullnameSuccess: "Update full name successful!",
    updateContactInfoSuccess: "Create contact info success!",
    updateShippingAddressSuccess: "Update shipping address success!",
    updateTypeOfPlaceSuccess: " Update type of place success!",
    updateNoteSuccessful: "Update note success!",
    createDelivery: "Create Delivery",
    updateOrderStatus: "Update Order Status",
    pendingPaymentApproval: "Pending Payment Approval",
    updateOrderStatusSucess: " Update Order Status successfully! ",
    fullName: "Full Name",
    findYourCustomer: "Find Your Customer",
    pleaseSelectYourChannel: "Please Select Your Channel",
    searchYourExitingCustomer: "Search Your Exiting Customer",
    shoppingCart: "Shopping Cart",
    createOrder: "Create Order",
    browseProduct: "Browse Products...",
    changeExpiringDateSuccess: "Change Expiring Date successfully!",
    apply: "Apply",
    haveCoupon: "Have Coupon?",
    sendInvoiceToCustomerByEmail:
        "By default,you'll send the order invoice to this customer's contact email",
    wantToSendToDifferentEmail: "Want to send to different email address?",
    pleaseInputTheEmailAddress: "Please input the email address",
    addNewItems: "Add new items",
    close: "Close",
    remove: "Remove",
    createNewCustomer: "Create New Customer",
    pleaseSelectProductChannelFirst: "Please Select Product Channel First !",
    therearntanyselectedproductsyet:
        "There aren't any selected products yet...",
    youHaveToChooseProductsFirst: "You have to choose products first !",
    expire: "Expires",
    createShippingNote: "Create shipping note...",
    gender: "Gender",
    birthday: "Date of Birth",
    personalInfo: "Personal information",
    requirefieldwhencreatecustomer:
        "Please fill all required field when creating new customer: full name, last name, nick name, email, phone !",

    uploadFileSuccess: "Upload file success!",
    upload: "Upload",
    createDeliverySuccess: "Create delivery success!",
    updateDeliverySuccess: "Update delivery success!",
    error: "Error",
    extimatedOn: "Estimated to arrive on ",
    editShippingInformation: "Edit shipping information",
    deleteDeliverySuccess: "Delete delivery success!",
    confirmFullPay: "Confirmation of payment completion",
    confirm: "Confirm",
    areYouSureConfirmPayment:
        "Are you certain that this order has been fully paid? Please not that this action cannot be undone.",

    ok: "Ok",
    no: "No",
    deleteTrackingNumber: "Delete Tracking Number?",
    deleteTrackingNumberDes:
        "Are you sure want to delete this tracking number? Please note that this action cannot be undone.",

    loadMore: "Load more",
    firstName: "First name",
    lastName: "Last name",
    city: "City",
    billingInformation: "Billing information",
    editBillingInfo: "Edit billing information",
    paymentConfirmed: "Payment confirmed",
    at: "at",
    sameAsShipping: "Same as shipping information",
    useSameShipping: "Use same information as Shipping information",
    selectShippingPro: "Select shipping profile",
    billingType: "Billing type",
    citizenIdNo: "Citizen ID No.",
    company: "Company",
    companyTax: "Company Tax ID",
    updateBillingAddressSuccess: "Update billing address successful",
    addCommentSuccess: "Add comment success!",
    by: "By",
    orderNote: "Order Notes",
    endInvoice: "End Invoice",
    order: "Order",
    paymentMethod: "Payment method",
    website: "Website",
    address: "Address",
    tel: "Tel",
    hasNoTrackingInfo: "Has no tracking information!",
    taxId: "Tax ID",
    editOrderContact: "Edit order contact",
    customerContact: "Customer contact",
    orderContact: "Order contact",
    updateOrderContactSuccess: "Update order contact success",
    addNewShippingProfile: "Add new shipping profile",
    createNew: "Create new",
    createNewShippingInfoSuccess: "Create new shipping information success",
    selectChannel: "Select channel",
    shippingNote: "Shipping notes",
    enterOrderNote: "Enter order note",
    editNoteFail: "Edit note fail",
    editNoteSuccess: "Edit note success",
    inputOrderNote: "Please input the note for this order...",
    chooseCommentTypeFirst: "You must select comment type first",
    selectType: "Select types",

    sendEmailConfirm: "Send Order Confirmation to Customer Email",
    sendConfirm: "Send email confirmation ",
    sendEmailConfirmSuccess: "Send email confirmation success",
    sendEmailInvoiceSuccess: "Send email order invoice success",
    sendOrderConfirmationToCustomerByEmail:
        "By default, you'll send the order confirmation to this customer's contact email",
    sendOrderInvoiceToCustomerByEmail:
        "By default, you'll send the order invoice to this customer's contact email",
    orderContactIsNull:
        "The Order Contact is not fully inputted yet. Please make sure to update the Order Contact first.",

    createPromotion: "Create promotion",
    dunlopillo: "Dunlopillo",
    lotus: "Lotus",
    mattresscity: "Mattresscity",
    omazz: "Omazz",
    zinusOnline: "Zinus Online",
    bedgearOnline: "Bedgear Online",
    from: "From",
    to: "To",
    promotionPeriod: "Promotion Period",
    promotionTitle: "Promotion Title",
    description: "Description",
    selectyourads: "Select your ads(Optional)",
    createAds: "Create Ads",
    createNewShipping: "Create Shipping Information",
    firstNameRequired: "First name required",
    lastNameRequired: "Last name required",
    phoneRequired: "Phone number required",
    phoneNotValid: "Phone number is not valid",
    cityRequired: "City required",
    citizenRequired: "Citizen ID required",
    companyTaxRequired: "Company tax required",
    companyRequired: "Company required",
    emailRequired: "Email required",
    filter: "Filter",
    search: "Search...",
    searchYourExitingAds: "Search Your Exiting Ads",
    promotionItems: "Promotion items",
    commnetContentCanNotBeNull: "Sorry!The comment's content cannot be empty",
    newSalePrice: "New sale price",
    canOnlySendOrderConfirm:
        "Sorry! You can only send order confirmation email to the customer when the status is either Pending Payment or Pending Payment Confirmation",
    currentAds: "Current Ads",
    selectedAds: "Selected Ads",
    adsname: "Ads Name",
    adscode: "Ads Code",
    adsurl: "Ads Url",
    start: "Start",
    end: "End",
    createFreeGift: "Create Free Gift",
    selectSource: "Select source",
    pleaseFillAllRequireField: "Please fill all required field",
    endtimehastobegreater:
        "Ending time of promotion has to be greater than starting time!",
    starttimehastobe:
        "Starting time of promotion has to be greater than current time",
    createdAt: "Created at",
    promotiondetail: "Promotion detail",
    promotionads: "Promotion ads",
    selectyourpromotion: "Select your promotion",
    selectedPromotion: "Selected promotion",
    currentPromotion: "Current promotion",
    createAdsSuccess: "Create Ads success!",

    birthdayIsRequired: "Birthday is required",
    birthdayNotValid: "Birthday is not valid",
    areYouSureToRemoveChannel: "Are you sure to remove this channel?",
    createPromotionSuccess: "Create promotion success",
    editPromotion: "Edit promotion",
    editAds: "Edit ads",
    editAdsSuccess: "Edit ads success!",
    typeCustomer: "Type of customer",
    pleaseAddSomeNoteAboutThisCustomer:
        "Please Add Some Note About This Customer",
    individual: "Individual",
    createCustomerSuccess: "Create Customer Success !",
    areYouSureWanttocancelcreatingnewcustomer:
        "Are you sure want to cancel ? All the unsaved information will be lost !",
    createShippingInforSuccess: "Create Shipping Infor Success !",
    freeGiftPeriod: "Free Gift Period",
    freeGiftRuleTitle: "Free Gift Rule Title",
    conditions: "Conditions",
    ofthefollowingconditionsaremet: "of the following conditions are met ",
    addcondition: "Add condition",
    cartSubtotal: "Cart Subtotal",
    browseItems: "Browse Items",
    isoneof: "Is one of",
    isnotoneof: "is not one of",
    is: "Is",
    isnot: "Is not",
    equalorgreaterthan: "Equals or greater than",
    equalorlessthan: "Equals or less than",
    greaterthan: "Greater than",
    lessthan: "Less than",

    editPromotionSuccess: "Edit promotion success",
    defaultSalePrice: "Default sale price",
    print: "Print",
    createdOn: "Created on",
    channels: "Channels",
    memo: "Memo",
    groupCEO: "Group CEO",
    enterTitle: "Enter title",
    enterMemo: "Enter memo",
    marketingDirector: "Marketing Director",
    pleaseAddShippingFirst: "Please add shipping profile first!",
    promotionStatus: "Promotion status",
    selectStatus: "Select status",
    orderHistory: "Order History",
    itemQty: "Item Qty.",
    spending: "Spending",
    date: "Date",
    action: "Action",
    customerNote: "Customer note",
    customerWillNotSee: "(Customer will not see it)",
    customerWillSee: "(Customer will see it)",
    rejectPayment: "Reject payment",
    remark: "Remark",
    areYouSureToRejectThisPayment: "Are you sure to reject this payment?",
    pending: "Pending",
    pendingPaymentStatus: "Pending Payment",
    pendingPaymentConfirm: "Pending Payment Confirmation",
    orderProcessing: "Order Processing",
    deliveryProcessing: "Delivery Processing",
    delivered: "Delivered",
    completed: "Completed",
    refunded: "Refunded",
    cancelled: "Cancelled",

    rejectedPayment: "Rejected Payment",
    approvedPayment: "Approved Payment",

    expiredOn: "Expired on",

    changeExpiringDate: "Change Expiring Date",
    printDelivery: "Print Delivery",
    sendEmailCustomer: "Send Invoice to customer's email",
    sendSmsCustomer: "Send Order Confirmation to customer's email",

    activities: "Activities",
    chatLog: "Chat log",
    orderStatus: "Order status",
    refund: "Refund",
    delivery: "Delivery",
    paymentName: "Payment",

    included: "Included",

    itemNameTable: "Item Name",
    regularPrice: "Reg.Price",
    salePrice: "Sale Price",
    qnty: "Qty",
    price: "Price",

    phoneNumber: "Phone number",

    personal: "Personal",
    business: "Business",

    condominium: "Condominium/Apartment",
    privateHouse: "Private House",
    companyOffice: "Company/Office",

    email: "Email",
    promotionmemo: "Promotion memo",

    productName: "Product Name",
    promotionid: "Promotion ID",
    advertisementid: "Advertisement ID",
    sku: "SKU",

    insertCouponHere: "Insert Coupon Here...",
    pleaseSelectGender: "Please Select Gender",

    male: "Male",
    female: "Female",
    other: "Other",
    selectSellChannel: "Select Sell Channel",
    expiredAt: "Expired at",

    rejectBy: "Rejected by",
    fullNameOfTransferee: "Full Name Of Payor",
    bankOfTransfer: "Bank transferred to",
    dateOfTransfer: "Date Of Transfer",
    youNeedUploadAllBankTransfer:
        "You need to fill all bank transfer information",
    submit: "Submit",
    selectFile: "Select file",

    fillInfoBeforeSubmit: "You need to fill all information before submitting!",
    chooseShippingInformation: "Choose Shipping Information",
    setAsDefault: "Set as default",
    paymentRejected: "Payment Rejected",
    chooseBillingInformation: "Choose Billing Information",
    createNewBillingInfo: "Create New Billing Info",
    createBillingInformationSuccess: "Create Billing Information Success!",
    promotionMemo: "Promotion Memo",
    youNotHavePermission: "You are not granted permission",
    areYouSureWantToDeleteBilling:
        "Are you sure want to delete this billing information ?",
    default: "Default",
    youCannotChooseFuture: "You can not choose the future date",

    areYouSureWantToDeleteShipping:
        "Are you sure want to delete this shipping information ?",
    adsId: "Ads ID",
    salePriceCannotGreater: "New sale price can not greater than regular price",
    editCustomer: "Edit customer",
    updateCustomerSuccess: "Updated customer information successfully!",
    selectBillingProfile: "Select Billing Profile",
    changePaymentStatus: "Change payment status",
    paymentPendingConfirm: "Pending Payment Confirmation",
    paymentStatus: "Payment status",
    changePaymentStatusSuccess: "Change payment status successful!",
    selectCustomer: "Select customer",
    removeCustomerOrderSuccess:
        "Customer was successfully removed from the order!",

    dashboard: "Dashboard",
    latestSaleOrder: "Latest sale orders",
    viewDetail: "Detail",
    orderNo: "Order No.",
    amount: "Amount",
    bulkImport: "Bulk import",
    bulkImportItems: "Bulk import items",
    uploadItemsIntoPro:
        "Upload all product items into the promotion in one single file.",
    requireFieldsImport: "Required fields: SKU, New Sale Price",
    notSureHowToStart: "Not sure how to get started?",
    blankCSVTemplate: " blank CSV template",
    downloadAsvFile: "Download a CSV file, then edit and upload the file.",
    download: "Download",
    attachCSV: "Attach CSV",
    itemValidToImport: "items are valid to import.",
    itemHaveErrorImport: "items have errors and cannot be proceeded.",
    downloadLog: "Download log",
    import: "Import",
    notValidTime: "Not valid time",
    attachments: "Attachments",
    reason: "Reason",
    fileImportIsNotRightFormat: "File import is not right format!",
    remarkIsRequired: "Remark is required",
    discard: "Discard",
    publish: "Publish",
    addProducts: "Add products",
    item: "Item",
    itemName: "Item name",
    optional: "Optional",
    addFiles: "Add files",
    orDragDropHere: "or drag drop file here",
    media: "Media",
    pricing: "Pricing",
    productionCost: "Production cost",
    margin: "Margin",
    profit: "Profit",
    visibility: "Visibility",
    pleaseSelectCategory: "Please select category",
    category: "Category",
    pleaseSelectBrand: "Please select brand",
    brand: "Brand",
    shipping: "Shipping",
    weight: "Weight",
    dimension: "Dimension",
    inventory: "Inventory",
    totalStock: "Total stock",
    continueSelling: "Continue selling when out of stock",
    stockAvailable: "Stock available",
    warehouse: "Warehouse",
    pleaseSelect: "Please select",
    addMoreWareHouse: "Add more warehouse",
    allWarehouseIsAdded: "All warehouse is added!",
    adjust: "Adjust",
    adjustQuantity: "Adjust quantity",
    setQuantity: "Set quantity",
    adjustBy: "Adjust by",
    newQuantity: "New quantity",
    originQuantity: "Original quantity",
    createProductSuccess: "Create product successful",
    editProductSuccess: "Edit product successful",
    updateDataError: "Update data error",
    c2pApprovalCode: "2C2P Approval Code: ",
    deleteProductSuccess: "Delete product successful",
    sourceOfOrder: "Source Of Order",
    completeDelivery: "Complete Delivery",
    updateStatus: "Update status",
    duplicate: "Duplicate",
    arrivedOn: "Arrived on",
    allProducts: "All products",
    new: "New",
    export: "Export",
    deliveryStatus: "Delivery Status",
    deliveryPending: "Delivery Pending",
    deliveryBooked: "Delivery Booked",
    deliveryCompleted: "Delivery Completed",
    deliveryCancelled: "Delivery Cancelled",
    deliveryReturned: "Delivery Returned",
    updateTruckDelivery: "Update Truck Delivery",
    deliveryOrder: "Delivery Order",
    add: "Add",
    set: "Set",
    uploadAllItemsInventory: "Upload all product inventory in one single file",
    requiredField: "Required fields: ",
    selectWarehouse: "Select warehouse",
    selectWareHouseFirst: "Please select warehouse first!",
    importInventorySuccess: "Imported the inventory successfully!",
    stock: "Stock Qty.",
    updateStockSuccess: "Update stock successfully",
    haveNonSelectWareHouse: "You have not selected warehouse yet!",
    filterProduct: "Filter Product By",
    outOfStock: "Out Of Stock",
    lowOnStock: "Low Stock (< 10pcs)",
    highStock: "Hight Stock (> 1000pcs)",
    inStock: "In Stock",
    priceRange: "Price Range",
    activeFilter: "Active Filter",
    clearAll: "Clear All",
    stockUpdateMethod: "Stock update method",
    stockUpdateQuantity: "Stock update quantity",
    selected: "Selected",
    attachFileBeforeImport: "Please attach file CSV before importing.",
    fileIsNotRightFormat:
        "Attach file is not right format please re-select import file!",
    areYouSureDeleteInventory:
        "Are you sure that you want to delete this warehouse? All data relating to this warehouse might be affected. Please note that this action cannot be undone.",
    removeInventorySuccess: "Remove inventory success!",
    splitPayment: "Split Payment",
    splitMore: "Split More",
    splitLess: "Split Less",
    payment: "Payment",
    remainAmount: "Remaining Amount",
    disabled: "Disabled",
    deleteProduct: "Delete Product",
    quantityRange: "Quantity Range",
    pcs: "Pcs",
    lotusTruck: "Lotus Truck",
    shippop: "Shippop",
    deliveryNo: "Delivery Order No.",
    estimatedDateArrival: "Estimated Delivery",
    totalQty: "Total Qty.",
    shipQty: "Ship Qty.",
    canNotCreateDeliveryWithNoItems: "Can not create delivery with no items",
    listItem: "List item",
    noLeftItem: "No left item to be added",
    willBeLeft: " will be left",
    canNotCreateDelivery: "Unable to create delivery",
    resetStock: "Reset stock",
    brandManager: "Brand Manager",
    printPackingList: "Print packing list",
    totalpaidamountcannotgreaterthantotalcart:
        "The total paid amount cannot be greater than the total cart !",
    paymentIsNotEnough:
        "The total splitted amount must be same as the total amount!",
    partiallyPaid: "Partially Paid",
    thisItemOutOfStock: "This item is currently out-of-stock!",
    reject: "Reject",
    confirmRejectPayment: "Confirmation of payment rejection",
    areCertainPaymentPaid:
        "Are you certain that this payment has been fully paid? Please note that this action cannot be undone.",
    areCertainPaymentReject:
        "Are you certain that you want to reject this payment? Please note that this action cannot be undone.",
    pleaseInputTheNote: "Please input the remark for this update",
    reset: "Reset",
    inventoryStatus: "Inventory Status",
    enable: "Enable",
    wantToApplyToShipping:
        "Do you want to apply the default information of customer into the order information?",
    updateOrderInfoSuccess: "Updated the order information successfully!",
    method: "Method",
    sureToDeleteItem:
        "Are you certain that you want to remove all products out of this promotion? Please note that this action cannot be undone.",
    removeAll: "Remove all",

    fullPaymentConfirm: "Full Payment Confirmed",
    fullPaymentReject: "Payment Fully Rejected",
    partiallyPaymentReject: "Payment Partially Rejected",
    partiallyPaymentConfirm: "Payment Partially Confirmed",
    guest: "Guest",
    resetPayment: "Reset payment",
    salesQty: "Sales Qty.",
    topSKUPerformance: "Top SKU",
    salesAmount: "Sales Amount",
    viewReport: "Report",
    avgOrderValue: "Avg. Order Value",
    deletedSKU: "Deleted SKU",
    selectShippingAddressPlease: "Please select shipping address",
    addShippingToOrder: "Add Shipping To Order",
    remaining: "Remaining",
    resetPaymentStatus: "Reset payment status",
    selectBillingYouWant: "Select the billing that you want to print",
    orderCanNotBeReset:
        "Sorry! Payment Status can only be reset when the order status is either Pending Payment or Pending Payment Confirmation or Partially Paid or Order Processing",
    refundRequest: "Refund request",
    requestBy: "Request by",
    status: "Status",
    approve: "Approve",
    createRequestRefundSuccess: "Create request refund success!",
    bankForRefund: "Bank for refund",
    accountName: "Account name",
    bankName: "Bank name",
    accountNumber: "Account number",
    uploadAttachmentRefundSuccess: "Upload attachment refund success!",
    refundAmountCannotBeGreaterThanOrderTotal:
        "Refund amount can not be greater than order total !",
    dateTime: "Date/Time",
    refundStatus: "Refund status",
    refundAmount: "Refund amount",
    requestor: "Requestor",
    cancelOrder: "Cancel Order",
    addNewTransfer: "Add New Transfer",
    quantity: "Quantity",
    transferOrigin: "Transfer Origin",
    transferDestination: "Transfer Destination",
    expectTransferDate: "Expected Transfer Date",
    expectArrivalDate: "Expected Arrival Date",
    document: "Documents",
    transferOrigionAndTransferDestinationHasTobedifferent:
        "Transfer Origin and Destination has to be different !",
    expectedArrivalDateHasTobeEqualOrLaterThanExpectedTransferDate:
        "Expected arrival date has to be equal or later than expected transfer date !",
    createTransferStockSuccess: "Create Transfer Stock Success!",
    outofstock: "Out of stock!",

    searchInvoiceNo: "Search invoice no. ...",
    cancelRefund: "Cancel refund request",
    rejectedRefundSuccess: "Reject refund successful",
    confirmRefundSuccess: "Confirm refund successful",
    cancelRefundSuccess: "Cancel refund successful",
    completeRefundSuccess: "Complete refund successful",
    sureWantToCancelRefund:
        "Are you certain that you want to cancel this refund request? Please note that this action cannot be undone.",
    onlineSaleOrder: "Online Sale Order",
    newOrder: "New Order",
    loadOrder: "Load Order",
    approvedBy: "Approved by",
    directorApproval: "Director approval",
    completeRefund: "Complete refund",
    refundedBy: "Refunded by",
    cancelBy: "Cancelled by",
    actualDateArrival: "Actual date of arrival",
    updateDeliveryStatus: "Update delivery status",
    createOrderFromOld: "Create order from old order",
    pleaseInputTheOrderId:
        'Please input the order ID. PLease note that by clicking "Load order", you will load all information from the below order ID to new order.',
    editTransferStock: "Edit Transfer Stock",
    partial: "Partial",
    pendingName: "Pending",
    addBankRefundSuccess: "Add bank refund success!",
    newTransfer: "New transfer",
    transferStock: "Transfer stock",
    transferId: "Transfer ID",
    requestDate: "Request date",
    transferQuantity: "Transfer quantity",
    all: "All",
    change: "Change",
    editRequestRefundSuccess: "Edit request refund success!",
    editTransferStockSuccess: "Edit Transfer Stock Success!",
    editBankRefundSuccess: "Edit bank refund success!",
    rejected: "Rejected",
    approved: "Approved",
    sureWantToDeleteRefundBank:
        "Are you sure you want to delete the bank account information? Please note that this action cannot be undone.",
    deleteBankRefundSuccess: "Delete bank refund success!",
    receiveStocks: "Receive Stocks",
    receiveTransfer: "Receive Transfer",
    receivingQty: "Receving Qty.",
    orderIdIsRequired: "Order ID is required!",
    originOrder: "Origin order",
    toReceive: "To Receive",
    printReceivingList: "Print Receiving List",
    editReceiveStockSuccess: "Edit Receiving Stock Success!",
    remainingAmount: "Remaining amount",
    paid: "Paid",
    pleaseSelectWarehouseFirst: "Please select warehouse first !",
    clone: "Clone",

    theRefundRequestIsInvalid:
        "The refund request is invalid! The refunding amount cannot be greater than the total paid amount.",
    uploadAttachmentReceiveStockSuccess:
        "Upload attachment for receiving stock successfully !",
    oldOrderId: "Old Order ID",
    ref: "Ref",
    paidAmount: "Paid Amount",
    refundedAmount: "Refunded Amount",
    loaded: "Loaded",
    requestedRefundedAmount: "Requested refund amount",
    youCanRequestRefundCancel:
        "Sorry! You must cancel this order first before submitting any refund request.",
    amountMustBeGreaterThanZero: "The amount must be greater than zero!",
    phone: "Phone",
    channel: "Channel",
    view: "View",
    areYouSureDeleteThisOrder:
        "Are you sure want to delete this order ? Please note that this action cannot be undone!",
    deleteOrder: "Delete Order",
    createdBy: "Created by",

    pendingPayment: "Pending payment",
    fromDateAndToDateIsRequired: "Date range is required",
    companyReceiver: "Company receiver",
    salePerson: "Sale person",
    customerName: "Customer name",
    customerAddress: "Customer address",
    customerProvince: "Customer province",
    customerCity: "Customer city",
    customerPostCode: "Customer postal code",
    channelPayment: "Channel payment",
    statusPayment: "Status payment",
    skuBarCode: "SKU/Barcode",
    createdDate: "Created date",
    confirmedDate: "Confirmed date",
    deliveryType: "Delivery type",
    createdShipmentDate: "Created shipment date",
    expectedShipmentArrival: "Expected shipment arrival",
    actualShipmentArrival: "Actual shipment date",
    pleaseChooseCustomer: "Please choose a customer for this order !",
    areYouSureDeleteTheseOrders:
        "Are you sure want to delete these orders? Please note that this action cannot be undone!",
    ordersweredeleted: "Orders were deleted !",
    dateRangeIsNot90:
        "Date range is too long. Date range cannot exceed 90 days!",
    searchInvoiceNoPhone: "Search Invoice No. / Phone / Email / Name…",
    topCategoryPerformances: "Top Category",
    latestPaymentStatus: "Latest payment status",
    latestPromotions: "Latest promotions",
    title: "Title",
    dateStart: "Date start",
    dateEnd: "Date end",
    topSales: "Top sales",
    KPI: "KPI",
    actualSale: "Actual sales",
    topSalesStaff: "Top Salesman",
    topProvinces: "Top Provinces",
    topAreas: "Top Areas",
    saleChannelPerformance: "Sales channel performance",
    groupByYear: "By year",
    groupByMonth: "By month",
    groupByDay: "By day",
    groupByWeek: "By week",
    topDeliveryProvinces: "Top Provinces",
    topPaymentMethod: "Payment Method",
    allChannels: "All Channels",
    totalSales: "Total Sales",
    createCartRule: "Create Cart Rule",
    startingDate: "Starting Date",
    endDate: "End Date",
    ruleTitle: "Rule Title",
    noCoupon: "No Coupon",
    specificCoupon: "Specific Coupon",
    autoGeneratedCoupon: "Auto-generated Coupons",
    type: "Type",
    couponCode: "Coupon Code",
    cartRule: "Cart Rule",
    usageLimit: "Usage Limit Per Coupon",
    usageLimitPerUser: "Usage Limit Per User",
    conjunctionalUse: "Conjunctional Use",
    yes: "Yes",
    priority: "Priority",
    viewCoupons: "View Coupons",
    couponQuantity: "Coupon Quantity",
    chooseItemsTobeFreegift: "Choose items to be free gifts",
    applyDiscountByPercentage: "Apply product discount by percentage",
    applyDiscountByFixAmount: "Apply product discount by fixed amount",
    specificAction: "Specific Action",
    pleaseChooseTheFollowingItems:
        "Please select the following items to be Free Gift",
    channelPerformance: "Channel Performance",
    detail: "Detail",
    combination: "Combination",
    vsYesterday: "Yesterday",
    today: "Today",
    untilToday: "Until Today",
    totalOrder: "Total Order",
    totalRevenue: "Total Revenue",
    totalPurchasedItems: "Total Purchased Items",
    fullfilledOrders: "Fulfilled Orders",
    avgUnitPerOrder: "Avg. Unit Per Order",
    orderPendingAccount: "Order Pending Accounting",
    pendingFullfillment: "Pending Fulfillment",
    avgSalesPerMan: "Avg. Sales Per Man",
    navLog: "Nav logs",
    condition: "Condition",
    if: "If",
    successSyncOn: "Successfully synced on ",
    navSoNo: "NAV SO No.",
    cart: "Cart",
    couponCodeMustHaveSixCharacters: "Coupon Code must have six characters !",
    couponCodeIsValid: "Coupon Code is valid !",
    couponCodeIsInvalid: "Coupon Code is invalid !",
    youHaveToSetPeriodTimeForCoupon: "You have to set period time for coupon !",
    unit: "Unit",
    unitPrice: "Unit price",
    totalDiscountTHB: "Total discount (THB)",
    amountExcludeVAT: "Amount (excluding 7%VAT)",
    amountIncludeVAT: "Amount (including 7%VAT)",
    last7Days: "(Last 7 Days)",

    editCartRule: "Edit Cart Rule",
    subtotal: "Subtotal",
    subTotal: "Subtotal",
    createCartRuleSuccess: "Create Cart Rule Successfully!",
    editFreeGiftSuccess: "Edit freegift successfully!",
    startingDateCannotGreater:
        "Starting date cannot be greater than the ending date!",
    endDateCannotBeLess: "Ending date cannot be less than the starting date",
    ruleName: "Rule name",
    ruleType: "Rule type",
    endingDate: "Ending date",
    newRule: "New rule",
    freeGiftBundle: "Free Gift Bundle",
    cartRuleType: "Cart rule type",
    couponType: "Coupon type",
    existingCode: "Existing Code",
    newGenerateCode: "New Generate Code",
    cantChangeThisQuantityOfCouponCode:
        "You cannot change the quantity of this issued coupon code because some of the existing codes were already in use!",
    youCantChangeTypeOfCoupon:
        "You cannot change the quantity of this issued coupon code because some of the existing codes were already in use!",
    setAsDefaultShipping: "Set as default shipping",
    inactive: "Inactive",
    active: "Active",
    titleAndDescriptionFieldIsRequired:
        "Title and description field are required !",
    conditionsFieldIsRequired: "Condition fields are required !",
    selectFreeGift: "Select free gift",
    freeGift: "Free gift",
    channelFieldIsRequired: "Channel field is required !",
    refundRequestMemo: "Refund Request Memo",
    requestedDate: "Requested date",
    refundMethod: "Refund method",
    reasonOfRefund: "Reason of refund",
    shopCode: "Shop code",
    mobileAppSORefNo: "Mobile app SO Ref No.",
    mobileAppShipmentRefNo: "Mobile app shipment Ref No.",
    requestedDeliveryDate: "Requested delivery date",
    promisedDeliveryDate: "Promised delivery date",
    cusName2: "Customer name 2",
    cusContact: "Customer contact",
    cusPhoneNo: "Customer phone No.",
    yourReference: "Your reference",
    logisticType: "Logistic type",
    invoiceDiscountAmount: "Invoive discount amount",
    itemLineNo: "Item line No.",
    giveAway: "Give away",
    saleUnitPrice: "Sale unit price",
    orderDate: "Order date",
    itemNo: "Item No.",
    notValidStockRange: "Not valid stock range",
    youMustSelectAllFreeGift:
        "There are some gifts that you have not selected yet",
    couponCodeNotRightLength:
        "Coupon code length is incorrect (Should be 4 - 20 characters)",
    changeFreeGift: "Change free gift",
    reportNAV: "Report NAV",
    reportByItem: "Report By Items",
    reportByPayment: "Report By Payment Methods",
    reportByShipping: "Report By Shipping Address",
    doNo: "DO No.",
    accountantPerson: "Accountant Person",
    paymentDateTime: "Payment Date/Time",
    lineAmount: "Line Amount",
    paymentDate: "Payment Date",
    paymentTime: "Payment Time",
    noPaymentYet: "No Payment Yet",
    paymentAmount: "Payment Amount",
    coupon: "Coupon",
    createCoupon: "Create coupon",
    editCoupon: "Edit coupon",
    usageLimitTooltip:
        "The limited number of times you can use this coupon. Zero (0) means unlimited usage. Default is 0.  (Ex: if you input 2, you can use this coupon 2 times only.)",
    usageLimitPerUserTooltip:
        "The limited number of times you can use this coupon for one user. Zero (0) means unlimited usage. Default is 0. (Ex: if you input 1, you can use this coupon 1 time for each one user only)",
    conjunctionTooltip:
        "Choose YES if you want to use this coupon in conjunction with other coupons. Otherwise, choose NO. (Default is NO)",
    priorityTooltip:
        "If there is 2 cart rules with the similar conditions, the rule with smaller number will be applied first. (Scale from 0 to 999) - (Highest Priority: 0; Lowest Priority: 999). Default is 999.",
    exportType: "Export Type",
    invalidCouponCode: "Invalid coupon code",
    titleIsRequire: "Title is required!",
    descriptionIsRequire: "Description is required!",
    searchCouponList: "Search the rule name / coupon code...",
    history: "History",
    inventoryHistory: "Inventory History",
    event: "Event",
    adjustment: "Adjustment",
    adjustedBy: "Adjusted By",
    ruleValue: "Rule Value",
    citizenId: "Citizen ID",
    deleteCoupon: "Delete Coupon",

    areYouSureDeleteCoupon:
        "Are you sure you want to delete this coupon? Please note that this action cannot be undone.",
    deleteCouponSuccess: "Delete Coupon Successfully!",
    orderIsNotAvaiNavLog: "There is no any log for NAV yet",
    rePushNav: "Repush Nav",
    selectLocation: "Select location",
    pleaseSelectSourceOrder: "Please select the source of order",
    sourceOrder: "Source order",
    updateSourceOrderSuccess: "Update the source of order successfully!",
    searchGoogleLocationMap: "Search in Google maps…",
    code: "Code",
    promotionList: "Promotion list",
    searchPromotion: "Search promotion",
    newPromotion: "New promotion",
    loadPromotion: "Load promotion",
    load: "Load",
    promotionCodeIsRequire: "Promotion code is required!",
    read: "Read",
    unRead: "Unread",
    draft: "Draft",
    ended: "Ended",
    notification: "Notification",
    markAllAsRead: "Mark all as read",
    markAllAsReadSuccess: "Mark all as read successfully!",
    wareHouseAlreadyInTheList:
        "The warehouse you selected is already in the list!",
    actualDatePayment: "Actual date of receiving payment",
    actualBankReceivePayment: "Actual bank of receiving payment",
    actualBankPaymentIsRequired: "Actual bank payment field is required!",
    actualDatePaymentIsRequired: "Actual date receiving payment is required!",
    importProducts: "Import Products",
    uploadProduct: "Upload all product items in one single file.",
    requireFieldsImportProduct:
        "Required fields: SKU, Regular Price, Default Sale Price",
    totalSaleReport: "Total Sale Report",
    dataTable: "Data table",
    errorData: "Error Data",
    maximumTimeRangeInputByYear:
        "For group by year, Maximum time-range allowed is 7 years!",
    maximumTimeRangeInputByMonth:
        "For group by month, Maximum time-range allowed is 12 months!",
    maximumTimeRangeInputByWeek:
        "For group by week, Maximum time-range allowed is 12 weeks!",
    maximumTimeRangeInputByDay:
        "For group by day, Maximum time-range allowed is 30 days!",
    changeProductPriceSuccess: "Change product price successfully!",
    bulkPriceUpdate: "Bulk Price Update",
    listOrder: "List Order",
    orderDetail: "Order Detail",
    promotion: "Promotion",
    editProducts: "Edit products",
    products: "Products",
    avatar: "Avatar",
    totalShipping: "Total Shipping",
    confirmDeleteCustomer: "Confirmation Of Customer Deletion",
    allCustomers: "All Customers",
    searchProductTable: "Search SKU/Product name/Brand...",
    areYouSureDeleteMedia: "Are you want to delete this image?",
    productPictureWasDeleted: "Product image was deleted",
    confirmed: "Confirmed",
    pendingConfirmation: "Pending Confirmation",
    storePickup: "Store pick-up",
    partiallyPickUp: "Partial pick-up",
    fullPickUp: "Full pick-up",
    pickUpAtOneOrMore: "Pickup at one or more store",
    pickUpAndDeliveryLater: "Pickup at store and delivery later",
    pickup: "Pickup",
    pickUpAt: "Pick-up at",
    pickUpLocation: "Pick-up location",
    pickUpItem: "Pick-up items",
    pickUpTime: "Pick-up date & time",
    pickUpPerson: "Pick-up person",
    storeCode: "Store code",
    estimatePickupTime: "Estimated date of pick up",
    pickUpPersonInfoIsRequire: "Pickup person’s information is required!",
    pickUpItemQuantityCannotBeZero: "Pick-up items quantity can not be zero!",
    note: "Note",
    pickupQty: "Pickup Qty.",
    addPickupSuccess: "Add pick-up successfully!",
    editPickupSuccess: "Edit pick-up successfully!",
    deletePickupSuccess: "Order Pick-up was successfully removed!",
    areYouSureToDeletePickup:
        "Are you sure that you want to delete this order pick-up?",
    wasCreatedSuccess: "was created successfully",
    continue: "Continue",
    createPickup: "Create pick-up",
    confirmPickup: "Confirm pick-up",
    selectStore: "Select store",
    pleaseSelectStore: "Please select store!",
    allItemsAreNotPick: "All items are not picked up!",
    confirmPickupSuccess: "Confirmed order pick-up successfully!",
    staffDiscountManagement: "Staff Discount Management",
    searchEmployeeIDName: "Search EmployeeID/Name...",
    assignStaffDiscount: "Assign Staff Discount",
    updateStaffDiscount: "Update Staff Discount",
    store: "Store",
    stores: "Store",
    staff: "Staff",
    maxPercentage: "Maximum Percentage",
    maxFixAmount: "Maximum Fix Amount",
    create: "Create",
    update: "Update",
    searchName: "Search Name",
    discountRate: "Discount Rate",
    employeeID: "Employee ID",
    buyXGetBundleFree: "Buy X Get Bundle Free",
    buyXGetX: "Buy X get X Free",
    buyXGetY: "Buy X get Y Free",
    recursive: "Recursive",
    maxQuantityAllow: "Max. quantity allow",
    applyTheRuleOnlyToCartMatching:
        "Apply the rule only to CART matching the following conditions ",
    applyTheRuleOnlyToItemMatching:
        "Apply the rule only to ITEMS matching the following conditions ",
    buyQuantity: "Buy quantity",
    getFreeQuantity: "Get free quantity",
    addRange: "Add range",
    minQuantity: "Min. quantity",
    maxQuantity: "Max. quantity",
    freeItemsY: "Free items (Y)",
    buyXGetDiscountY: "Buy X get Discount Y item",
    bestSellerReport: "Best Seller Report",
    allProvince: "All Provinces",
    locationReport: "Location Report",
    deliveryCreated: "Delivery Created",
    saleStaffPerformanceReport: "Sale Staff Performance Report",
    staffName: "Staff Name",
    percentage: "Percentage",
    allStaff: "All Staff",
    actionsFieldIsRequired: "Action field is required!",
    youMustSelectItemsToBeFreeGift:
        "You must select items to be Free Gift in Action field!",
    discountAmountMustNotBeZero: "Discount amount must not be 0",
    discountPerMustNotBeZero: "Discount percentage must not be 0",
    channelSaleReport: "Channel Sale Report",
    addDiscount: "Add discount",
    addMoreDiscount: "Add more discount",
    wantMoreDiscount: "Want more discount ?",
    discountReason: "Discount reason",
    requestedTo: "Requested to",
    request: "Request",
    requestDiscount: "Request discount",
    haveDiscount: "Have discount ?",
    pleaseSelectPersonToRequest: "Please select a person to request to !",
    pleaseSelectCustomer: "Please select customer first !",
    pleaseSelectCustomerAndWarehouse:
        "Please select customer and warehouse first !",
    pleasePickProductFirst: "Please select products first !",
    pleaseAddReasonToRequest: "Please add reason to request !",
    sendRequestDiscountSuccess: "Send request discount success!",
    requested: "Requested",
    discountRequest: "Discount Request",
    discountAmount: "Discount amount",
    approvedAt: "Approved at",
    fixedAmount: "Fixed Amount",
    requestReason: "Request reason",
    confirmRemark: "Confirm remark",
    approveDiscountRequest: "Approve Discount Request",
    rejectDiscountRequest: "Reject Discount Request",
    pleaseTypeInARemarkToConfirm: "Please type in a remark to confirm!",
    confirmDiscountRequestSuccess: "Confirm Discount Request Success!",
    rejectedAt: "Rejected at",
    pleaseAddProductToCartFirst: "Please Add Product To Cart First",
    pleaseSelectStaff: "Please select the staff to assign !",
    pleaseInputTheMaximumPercentage: "Please input the maximum percentage !",
    pleaseInputTheMaximumFixAmount: "Please input the maximum fix amount !",
    assignStaffDiscountSuccess: "Assign Staff Discount Success !",
    allStore: "All Store",
    pleaseSelectSpecificAction: "Please select specific action",
    pleaseSelectStatus: "Please select status",
    statusIsRequired: "Status is required!",
    selectEndDate: "Select end date",
    endDateIsRequire: "End Date is reqruie!",
    enterDescription: "Enter description",
    productsSelected: "products selected",
    select: "Select",
    buyOrFreeCanNotBeZero: "Buy or free field can not be 0",
    rangeCanNotBeEmpty: "Range field can not be empty when recursive is No",
    discountTable: "Discount Table",
    totalOrderQty: "Total Order Qty.",
    totalItemQty: "Total Item Qty.",
    deliveryQty: "Delivery Qty.",
    categoryPerformanceReport: "Category Performance Report",
    paymentPerformanceReport: "Payment Performance Report",
    allCategories: "All Categories",
    allMethod: "All Method",
    yourBundleQuantityCanNotGreaterThan:
        "Sorry! You cannot select more than your granted free quantity",
    pleaseSelectEnoughBundleQuantity:
        "Please select enough quantity of bundle!",
    freeQuantity: "Free quantity",
    customerDetail: "Customer Detail",
    quantityLeft: "Quantity left",
    required: "Required",
    selectBank: "Select bank",
    actual2C2PMerchantOfReceivingPayment:
        "Actual 2C2P Merchant of receiving payment",
    actualDateOfReceiving2C2PPayment: "Actual date of receiving 2C2P payment",
    C2PTransactionId: "2C2P Transation ID",
    enterTransactionId: "Enter transaction id",
    pleaseInputNoteForTheUpdate: "Please input note for the update",
    paymentUpload: "Payment upload",
    fullNameOfPersonPayment: "Full name of person’s payment",
    merchantName: "Merchant name",
    dateOf2C2PTransaction: "Date of 2C2P transaction",
    attachmentIsRequire: "Attachment is required",
    printOrderCustomer: "Print Purchase Order (Customer)",
    printOrderInternal: "Print Purchase Order (Internal)",
    transactionId: "Transaction ID",
    totalConfirmed: "Total (Confirmed) ",
    totalIncludePending: "Total (Including Pending)",
    regular_price: "Regular price",
    total_stock: "Total stock",
    ruleid: "Rule ID",
    updatedDate: "Updated date",
    searchStore: "Search store",
    manualDiscount: "Manual discount",
    cashName: "Cash",
    creditCardPOS: "Credit card POS",
    "credit-card-pos": "Credit card POS",
    dateOfPayment: "Date of payment",
    nameOfPayee: "Name of payer",
    offlineStoreSaleReport: "Offline store sale report",
    wantSpecialStoreDiscount: "Want special store discount?",
    unlockCode: "Unlock code",
    enterManagerLockCode: "Enter the manager unlock code",
    unLockManagerCodeSuccessful:
        "Unlocked successfully! You can now use your manager’s privilleges to set the special discount above.",
    discountFixAmountCart: "Apply fixed amount discount for whole cart",
    discountPercentageCart: "Apply percentage discount for whole cart",
    sequentially: "Sequentially",
    thaiEMS: "Thai EMS",
    flashExpress: "Flash Express",
    shippopSevices: "Shippop Service",
    pleaseSelectShippopServices: "Please select the shippop service!",
    printReceiptOffline: "Print Purchase Order (Offline Shop)",
    receipt: "Receipt",
    staffCode: "Staff code",
    endReceipt: "End receipt",
    paidBy: "Paid by",
    confirmedBy: "Confirmed by",
    customerSignature: "Customer’s Signature",

    assginedBy: "Assigned by",
    storeWarehouse: "Warehouse/Store",
    managerCodeUsage: "Manager Code Usage",
    orderValue: "Order Value",
    discountValue: "Discount Value",
    usedBy: "Used by",
    useDate: "Used Date",
    searchCodeOrderId: "Search Code / Order ID",
    searchNameSKU: "Search Product SKU/Name",
    manualSaleReport: "Manual Sale Report",
    locationOfReport: "Location Of Report",
    dateOfReport: "Date Of Report",
    pleaseInputReasonToCancelThisOrder:
        "Please input the reason for cancelling this order.",
    internalBrandRevenue: "Internal Brand Revenue",
    competitorRevenue: "Competitor Revenue",
    pleaseSelectDateToReport: "Please select the date of report!",
    selectedDateAlreadyHasReport:
        "A record of report is already existed on the selected date",
    createManualSaleReportSuccess: "Create manual sale report successfully !",
    manualOfflineSaleReport: "Manual Offline Sale Report",
    searchManualOfflineReport: "Search Store Name/Store ID/Staff Name",
    location: "Location",
    locationCode: "Location Code",
    totalOfCompetitor: "Total Of Competitors",
    totalOfInternalBrand: "Total Of Internal Brands",
    createReport: "Create Report",
    thisReportIsNotAllowedToEdit:
        "This report exceeded the time range allowed for editting !",
    internal: "Internal",

    addNewCashDeposit: "Create Cash Deposit Request",
    noAnyPaymentAdded: "No any payment added yet!",
    selectDepositMethod: "Select deposit method",
    cashInBank: "Cash",
    transactionClosingDate: "Transaction closing date",
    expectedDepositDate: "Expected deposit date",
    selectCashTransaction: "Select transaction",
    browseOrder: "Browse order",
    warehouseStore: "Warehouse/Store",
    cashDepositRequests: "Cash Deposit Requests",
    newRequest: "New Request",
    transactionDate: "Transaction Date",
    cash: "Cash",
    allCashDepositRequest: "All Cash Deposit Request",
    cartRuleReport: "Cart rule report",
    withCoupon: "With coupon",
    withoutCoupon: "Without coupon",
    depositMethod: "Deposit Method",
    storeInformation: "Store information",
    depositInformation: "Deposit Information",
    staffId: "Staff ID",
    transactionClosing: "Transaction closing",
    totalPayment: "Total payment",
    paymentCreatedBy: "Payment created by",
    paymentCreatedAt: "Payment created at",
    refOrder: "Ref order",
    statusOrder: "Status order",
    orderCreatedBy: "Order created by",
    orderCreatedAt: "Order created at",
    paymentConfirmation: "Payment Confirmation",

    newLoadedOrder: "New loaded order",

    createDepositSuccess: "Create deposit successfully!",
    wareHouseIsRequired: "Warehouse is required!",
    paymentIsRequire: "Payment is required!",
    transactionClosingDateIsRequire: "Transaction closing date is required!",
    expectedDepositDateIsRequire: "Expected deposit date is required!",
    depositMethodIsRequired: "Deposit method is required!",
    documentIsRequire: "Documents is required!",
    "pending-deposit": "Pending Deposit",
    "pending-confirmation": "Pending confirmation",
    competitor: "Competitor",
    editWithStock: "Edit(With Inventory)",
    editDepositInfoSuccess: "Edit Deposit Information Successfully!",
    selectStoreFirst: "Select store first!",
    selectChannelFirst: "Select channel first!",
    "partially-paid": "Partially paid",
    "pending-payment-confirmation": "Pending payment confirmation",
    transaction: "Transaction",
    actualDateReceivingPayment: "Actual date of receiving payment",
    actualBankReceivingPayment: "Actual bank of receiving payment",
    depositBy: "Deposit by",
    confirmDepositSuccess: "Confirmed deposit successfully!",
    pleaseInputDataBeforeSubmit: "Plesase Input Data Before Submitting!",
    actualDateOfReceivingPayment: "Actual date of receiving payment",
    actualBankOfReceivingPayment: "Actual bank of receiving payment",
    areYouSureToCancelThisDeposit: "Are you sure to cancel this deposit?",
    cancelDepositSuccess: "Cancel deposit successfull!",
    thisIsGroupSKU: "This is group SKU",
    groupSkuItems: "Group SKU items",
    allCoupon: "All Coupons",
    transactionRef: "Transaction Ref.",
    orderRef: "Order Ref.",
    orderAmount: "Order amount",
    "cash-in-bank": "Cash",
    transClosingDateCanNotOverToday:
        "Transaction closing date cannot be over the current time!",
    expClosingDateNotBeforeTransDate:
        "Expected closing date cannot be before the transaction date!",
    pleaseselectTimeRangeFirst: "Please select time range to export first ! ",
    totalSaleInternalBrand: "Total Sale Internal Brands",
    totalSaleCompetitorsBrand: "Total Sale Competitor Brands",
    searchTrackingNo: "Search tracking No.",
    fromWarehouse: "From warehouse",
    customerPhone: "Customer phone",
    estArrival: "Est. Arrival",
    deliveryService: "Delivery service",
    youCannotSelectProduct: "You cannot select the group product",
    deliveredQty: "Delivered Qty.",
    youNeedSelectTransferOriginFirst:
        "You need to select the Transfer Origin first!",
    youCannotEditGroupProduct: "You cannot edit the group product",
    picked: "Picked",
    selectWarehousePlease: "Please select warehouse!",
    nav: "Nav",
    syncedOn: "Synced on",
    errorDetectedViewLog: "Error detected! View Log for more detail!",
    log: "Log",
    thereIsNoPickup: "There is no pick-up for this order yet",
    selectStoreWarehouse: "Select Store / Warehouse",
    storeBranchWarehouse: "Store Branches / Warehouse ",
    printOrderPickup: "Print Pick-up Confirmation (Offline Shop)",
    paymentRef: "Payment Ref",
    pickedUpBy: "Picked up by",
    noPickUp: "No Pick-up",
    pickUpStatus: "Pick-up Status",
    cartCondition: "Cart’s Conditions",
    pickUpRef: "Pick-up Ref",
    pickUpDate: "Pick-up Date",
    thereIsNoNav: "There is no NAV synced log for this order yet",
    pushNavSuccessful: "Re-pushed to NAV successfully",
    offline: "Offline",
    online: "Online",
    transactionClosingDateMustSelectFirst:
        "You must select the transaction closing date first!",
    selectPickUpToPrint: "Select pick up that you want to print",
    disable: "Disable",
    storeSaleReport: "Store Sale Report",
    totalSaleAmountMustGreater:
        "The total sales amount must be greater than zero",
    enabled: "Enabled",
    selectUsedBy: "Select used by",
    pleaseInputQuantityOfItemToPickUP:
        "Please input the quantity of items to pick-up!",
    managerCodeUsageReport: "Manager Code Usage Report",
    stockRequest: "Stock Request",
    newStockRequest: "New Stock Request",
    normalProduct: "Normal product",
    productForEvent: "Product for event",
    nameOfEvent: "Name of event",
    productIsRequire: "Product is required!",
    requestId: "Request ID",
    ifPurchasingOneOfTheFollowingBundles:
        "If purchasing one of the following bundles:",
    willGetOneOfTheFollowingBundles:
        "Will get one of the following bundles as free gift",
    priorityMustNotCoincide: "Priority must be unique!",
    youMustSelectBundle: "You must select the bundles",
    inUse: "In-use",
    in_use: "In use",
    bundle: "Bundle",
    citizenIsNotRightFormat:
        "Citizen ID must be a 13-digit number only and cannot contain any special character, spacing, etc.",

    buyBundleGetBundleFree: "Buy Bundle Get Bundle Free",
    buyNXGetDiscountPercentage: "Buy (n) X Get Discount (%)",
    buyNXGetDiscountFixAmount: "Buy (n) X Get Discount (Fixed Amount)",
    buyNXGetDiscountPercentageOnY: "Buy (n) X Get Discount (%) on Y",
    buyNXGetDiscountFixAmountOnY: "Buy (n) X Get Discount (Fixed amount) on Y",
    discountType: "Discount type",
    maxQuantityToGetDiscount: "Max. Quantity to get discount ",
    minQuantityToGetDiscount: "Min. Quantity to get discount ",
    maxQuantityMustGreaterThanMin:
        "Max quantity must be greater then min quantity",
    buyOrAmountCanNotBeZero: "Buy or amount can not be zero",
    amountCanNotGreaterThan100: "Amount can not be greater than 100%",
    createRequestStockSuccess: "Create request stock successfull!",
    rejectRequestSuccess: "Reject request successfull!",
    confirmRequestSuccess: "Confirm request successfull!",
    areYourSureCancelThisRequest: "Are you sure to cancel this request?",
    cancelStockRequestSuccess: "Cancel stock request successful",
    editStockRequest: "Edit stock request",
    thisIsGroupCustomSize: "This is group custom size SKU",
    thisIsCustomSizeProduct: "This is custom size product",
    groupCustomSizeSku: "Group custom size SKU",
    customSizeFromSku: "Custom size from SKU",
    customSizeFormula: "Custom size formula",
    widthMinCm: "Width Min. (cm)",
    widthMaxCm: "Width Max. (cm)",
    lengthMinCm: "Length Min. (cm)",
    lengthMaxCm: "Length Max. (cm)",
    priceCoefficient: "Price coefficient",
    groupProductIsEmpty: "Group product must not be empty!",
    groupCustomSizeProductIsEmpty:
        "Group custom size product must not be empty!",
    customSizeProductIsEmpty: "Custom size product must not be empty!",
    pleaseFillAllCustomSizeFormulaField:
        "Please fill all custom size fofmula field!",
    widthCM: "Width (cm)",
    lengthCM: "Length (cm)",
    youHaveSelectCustomSize: "You've selected the custom size",
    allowedRangeOfWidthIS: "- Allowed range of width is ",
    allowedRangeOfLengthIS: "- Allowed range of length is ",
    wantDifferentWidthAndLength:
        "Want different width and length? Please select other product.",
    lengthWidthIsNotInRange: "Length or width is not in range",
    youMustFillWidthAndLength:
        "You must fill width and length of custom size products!",
    requestNo: "Request No.",
    requestStatus: "Request status",
    requestedFor: "Requested for",
    requestInformation: "Request information",
    requestedBy: "Requested by",
    barCode: "Barcode",
    haveOtherProductDelivered:
        "Have other products delivered(off the list) and want to receive to your store?",
    clickHere: "Click here",
    receiveStockOnTheList: "Receive stock (On the list)",
    receiveStockOffTheList: "Receive stock (Off the list)",
    purchasedAt: "Purchased at",
    expectedDeliveryAt: "Expected delivery at",
    consultedBy: "Consulted by",
    branchCode: "Banch code",
    placeOfPurchase: "Place of purchase",
    confirmation: "Confirmation",
    customerDeclareTheSizeByThemselves:
        "Customer declares the size by themselves and fully responsible for that declaration.",
    customerWantsTheStaffToGoHome:
        "Customer wants the staff to go home to inspect the custom size",
    productDimensionInWidthLengthAndHeight:
        "Product dimension in width, length, and height are to be measured in Centimeter. The thickness are to be measured in Inch.",
    theCustomSizeOfTheProductHaveBeenPredetermined:
        "The custom size of the product have been pre-determined by you (client) in according and correct to your (client) wish",
    customBedMadeToOrderMayHaveATolerance:
        "Custom bed made to order may have a tolerance level of +/- 2 centimeters.",
    byCheckingThisBoxYouIndicate:
        "By checking this box, you indicate that you have read and agree to our terms and conditions",
    productConsultant: "Product Consultant",
    shopManager: "Shop Manager",
    authorizedPersonal: "Authorized Personal",
    unapproved: "Unapproved",
    productModel: "Product/Model",
    customSize: "Custom Size",
    color: "Color",
    authorizedPersonnel: "Authorized Personnel",
    fullReceived: "Fully Received",
    reMap: "Remap",
    mapped: "Mapped",
    map: "Map",
    sender: "Sender",
    proof: "Proof",
    receiver: "Receiver",
    clear: "Clear",
    signature: "Signature",

    areYouSureToReceiveTheseProduct:
        "Are you sure that you want to receive these products?",
    selectOrderStatus: "Select Order Status",
    noCustomSizeProductWasFound:
        "Invalid size range. If you want another size range, please select the custom size product again.",
    mustSelectAllCustomSizeInGift:
        "You must select the size of all gifts in your cart",
    customSizeOrderPrint: "Print Custom Size Declare Form",
    saveNew: "Save New",
    nameOfLayout: "Name Of Layout",
    selectLayout: "Select Layout",
    newLayout: "New Layout",
    selectAll: "Select All",
    saveAndApply: "Save & Apply",
    clearALlLayout: "Clear All Layout",
    areYouSureWantToDeleteAllSavedLayoutOfThisTable:
        "Are you sure that you want to delete all the saved layouts?",
    attributeValue: "Attribute value",
    addMore: "Add more",
    addCustomItem: "Add custom item",
    updateCustomItem: "Update custom item",
    sizeWL: "Size WxL (cm)",
    attributes: "Attributes",
    addAttribute: "Add attribute",
    itemNameIsRequire: "Item name is required!",
    salePriceIsRequire: "Sale price is required!",
    skuIsRequire: "SKU is required!",
    mapDummyProductSuccess: "Map custom product successfully!",
    areYouSureToMapThisProduct:
        "Are you sure that you want to map this product!",
    isCustomProduct: "Custom product",
    cartRuleName: "Cart rule name",
    amountDiscounted: "Amount discounted",
    cartRulePerformance: "Cart rule performance",
    cartRuleId: "Cart rule ID",
    complete: "Complete",
    completeStock: "Complete stock receiving",
    fullNameOfSender: "Fullname of sender",
    completeReceiveSuccess: "Complete stock receiving successfully!",
    proofImagesIsRequire: "Proof image is required!",
    signOfSenderAndReceiverIsRequire:
        "Signatures of sender and receiver are required!",
    senderFullNameIsRequire: "Fullname of sender is require",
    receiverSignature: "Receiver’s Signature",
    senderSignautre: "Sender’s Signature",
    notCreateDeliveryManualProHasNotMap:
        "Cannot create new delivery when the custom product hasn’t been mapped",
    declareOption: "Declare option",
    allCartRule: "All cart rule",
    companyTaxIsNotRightFormat:
        "Company tax must be a 13-digit number only and cannot contain any special character, spacing, etc.",
    allBrand: "All brand",
    uploadSku: "Upload SKU",
    checkViaCopyPaste: "Check Via Copy/Paste",
    checkViaUpload: "Check Via Upload",
    checkByCopyingAndPasting:
        "Check by copying and pasting the list of your SKUs in the box below ",
    only1SkuPerRow: "Only 1 SKU per row and maximum 1000 skus per time",
    check: "Check",
    checkByUploadingAllProduct:
        "Check by uploading all product SKUs in one single file",
    result: "Result",
    skuListIsNotMoreThan: "SKU list is not allowed more than 1000 items !",
    existed: "Existed",
    notExisted: "Not Existed",
    duplicated: "Duplicated",
    maximumFileSizeIs5MB: "Maximum file size is 5MB",
    dragAndDropYourCSVHere: "Drag and drop your CSV file here",
    orSelectFromYourComputer: "Or select it from your computer",
    skuFieldIsRequire: "SKU field is required.",
    maximum1000SkusPerTime: "Maximum 1000 skus per time.",
    createManualPickUp: "Create manual pick-up (For printing only)",
    selectThisOptionWhenOrderHasManual:
        "Select this option when your order has at least one custom product that hasn't been mapped yet.",
    existedItem: "Existed Items",
    notExistedItem: "Not Existed Items",
    duplicatedItem: "Duplicated Items",
    downloadAllItem: "Download All Items",
    downloadNotExisted: "Download  Existed And Duplicated Items",
    downloadOption: "Download Option",
    thisIsConfigProduct: "This is variable product",

    //***********************NEW WORDS ************************** */

    "topbar.aboutUs": "About Us",
    "topbar.contacts": "Contacts",
    "topbar.storeLocation": "Store Location",
    "topbar.trackOrder": "Track Order",
    "topbar.blog": "Blog",
    "topbar.myAccount": "My Account",
    "topbar.currency": "Currency",
    "topbar.language": "Language",

    "header.phoneLabel": "Customer Service",
    "header.phone": "(800) 060-0730",

    productTable: "Product table",
    orders: "Orders",
    orderTable: "Order table",
    orderCreate: "Order create",
    setting: "Setting",
    logout: "Logout",
    retry: "Retry",
    productCreate: "Product create",
    column: "Column",
    slug: "Slug",
    productFullDescription: "Product full description",
    shortDescription: "Short description",
    productShortDes: "Product short description",
    enableProduct: "Enable product",
    option: "Option",
    variants: "Variants",
    addVariant: "Add variant",
    retailPrice: "Retail price",
    wholeSalePrice: "Wholesale Price",
    weightG: "Weight(kg)",
    productType: "Product type",
    variations: "Variations",
    selectBrand: "Select brand",
    selectCategory: "Select category",
    gallery: "Gallery",
    browseOrDropHere: "Browse or drag and drop here",
    thumbnail: "Thumbnail",
    id: "ID",
    continueSellingWhenOutStock: "Continue selling when out of stock",
    thisIsGroupSku: "This is group SKU",
    thisIsCustomSizeSKU: "This is custom size SKU",
    warning: "Warning",
    thump: "Thump",
    createCustomer: "Create Customer",
    searchCustomerPlaceholder: "Search name,phone,email...",
    dob: "DOB",
    totalSpending: "Total Spending",
    lastUpdated: "Last Updated",
    customerSince: "Customer Since",
    filterCustomerBy: "Filter Customer By",
    seo: "SEO",
    general: "General",
    advanced: "Advanced",
    social: "Social",
    snippetPreview: "Snippet preview",
    newAddress: "New Address",
    tooShort: "Too Short",
    tooLong: "Too Long",
    altPhone: "Alt. Phone No",
    fullAddress: "Full Address",
    thisIsWhatAppearInThe:
        "This is what will appear in the first line when this post shows up in the search results.",
    thisIsUniqueURL:
        "This is the unique URL of this page, displayed below the post title in the search results.",
    thisIsWhatWillAppear:
        "This is what will appear as the description when this post shows up in the search results.",
    permaLink: "Permalink",
    focusKeyword: "Focus Keyword",
    socialPreview: "Social preview",
    ogImage: "OG Image",
    ogTitle: "OG Title",
    ogDescription: "OG Description",
    uploadAtLeast600315:
        "Upload at least 600x315px image. Recommended size is 1200x630px.",
    addImage: "Add image",
    noIndex: "No Index",
    noFlow: " No Follow",
    canonicalUrl: "Canonical URL",
    mediaManager: "Media manager",
    deleteFolder: "Delete folder",
    back: "Back",
    createFolder: "Create folder",
    defaultAddress: "Default Address",
    nameOfPlace: "Name of place",
    country: "Country",
    expandAll: "Expand all",
    collapseAll: "Collapse all",
    deleteCustomer: "Delete Customer",
    areYourSureToDeleteThisFolder: "Are you sure to delete this folder?",
    deleteFolderSuccess: "Delete folder successful!",
    folderName: "Folder name",
    createFolderSuccess: "Create folder success!",
    selectMedias: "Select medias",
    lastModified: "Last modified",
    size: "Size",
    createBrand: "Create Brand",
    logo: "Logo",
    featuredImage: "Featured Image",
    brandSlider: "Brand Slider",
    addVideo: "Add Video",
    brandBanner: "Brand Banner",
    SEO: "SEO",
    unSelectAll: "Unselect all",
    deleteAll: "Delete all",
    downloadAll: "Download all",
    addVideoURL: "Add Videp URL",
    mediaType: "Media type",
    sliderProduct: "Slider product",
    uploadMediaSuccess: "Media upload successfull!",
    mediaFiles: "Media files",
    mediaTypeIsRequire: "Media type is require!",
    mediaFilesIsRequire: "Media files is require!",
    deleteMedia: "Delete media",
    areYouSureToDeleteMedia: "Are you sure to delete these medias",
    deleteMediaSuccess: "Delete media successfull",
    saveAndInsert: "Save and insert",
    alternativeDescription: "Alternative description",
    fileName: "File name",
    modificationDate: "Modification date",
    fileUrl: "File URL",
    updateMediaDetailSuccess: "Update media successfull!",
    structure: "Structure",
    pleaseSelectBannerStructure:
        "Please select banner structure for the brand page below ",
    Banner1: "1 Banner",
    Banner2: "2 Banners",
    Banner3: "3 Banners",
    Banner4: "4 Banners",
    imageBanner: "Image Banner",
    videoBanner: "Video Banner",
    chooseImage: "Choose Image",
    openInNewWindow: "Open in new window",
    addNofollowTag: "Add nofollow tag",
    banner: "Banner",
    destinationUrl: "Destination URL",
    youtubeVimeoLink: "Youtube/Vimeo Link",
    recommendedSize: "Recommended size",
    brandBestSeller: "Brand Best Seller",
    resetFilter: "Reset Filter",
    position: "Position",
    selectAttr: "Select attribute",
    selectAttrValue: "Select attribute value",
    recommendedLogoFile: "Recommended size:W200xH200",
    recommendedLogoImgaeSize: "Recommended file:PNG",
    recommendedFeaturedImage: "Recommended size:W400xH400",
    noOfItem: "No. of Items",
    successfullyDeletedBrand: "Successfully Deleted Brand",
    addSelected: "Add selected",
    sizeGuide: "Size guide",
    tags: "Tags",
    productNameIsRequire: "Product name is require!",
    shortDescIsRequire: "Short description is require!",
    categoryIsRequire: "Category is require!",
    brandIsRequire: "Brand is require!",
    sizeGuideIsRequire: "Size guide is require!",
    thisRoleIsTaken: "This role is taken",
    videoThumbnail: "Video thumbnail",
    productGalleryIsRequire: "Product gallery is require!",
    productFeatureIsRequire: "Product feature is require!",
    productFeedIsRequire: "Product feed is require!",
    variantLostInformation: "Need to fill variation information!",
    successfullyCreateBrand: "Successfully Created Brand !",
    fieldNameIsRequired: "Field name is required!",
    successfullyUpdatedBrand: "Successfully Updated Brand !",
    productCategories: "Product Categories",
    upSells: "Up-sells",
    crossSell: "Cross-sells",
    addCategory: "Add Category",
    menu: "Menu",
    createAttribute: "Create attribute",
    allAttribute: "All attributes",
    attribute: "Attribute",
    allProduct: "All products",
    allOrder: "All orders",
    createProduct: "Create product",
    filterable: "Filterable",
    valueQty: "Value Qty.",
    valueQtyRange: "Value Qty. range",
    categoryName: "Category Name",
    categorySlug: "Category Slug",
    parentCategory: "Parent Category",
    in: "In",
    not_in: "Not in",
    createCategory: "Create Category",
    fullDescription: "Full Description",
    categoryShortDescription: "Category Short Description",
    categoryFullDescription: "Category Full Description",
    categoriesSlider: "Category Sliders",
    categoryBanner: "Category Banner",
    productInCategory: "Product in category",
    categoryBestSeller: "Category Best Seller",
    updateProductSuccess: "Successfully Updated Product!",
    seoGeneralTitleRequire: "SEO general title require!",
    seoGeneralDescRequire: "SEO general description require!",
    seoGeneralKeywordRequire: "SEO general keyword require!",
    seoSocialImageRequire: "SEO social image require!",
    seoSocialOgTitleRequire: "SEO social title require!",
    seoSocialOgDescRequire: "SEO social description require!",
    seoAdvanceCanonicalRequire: "SEO advance canonical require!",
    seoAdvanceCanonicalIsNotValid: "SEO advance canonical is not valid!",
    successfullyCreatedCategory: "Successfully Created Category !",
    successfullyUpdatedCategory: "Successfully Updated Category !",
    fieldSlugIsRequired: "Field slug is required !",
    allSizeGuide: "All size guide",
    createSizeGuide: "Create size guide",
    sizeGuideContent: "Size guide content",
    sizeGuideTitle: "Size guide title",
    createSizeGuideSuccess: "Create size guide successful!",
    updateSizeGuideSuccess: "Update size guide successful!",
    areYouSureDeleteBrand:
        "Are you sure want to delete this brand? Please keep in mind that this action can not be undone !",
    areYouSureToDeleteSizeGuide:
        "Are you sure want to delete this size guide? Please keep in mind that this action can not be undone !",
    areYouSureToDeleteProduct:
        "Are you sure want to delete this product? Please keep in mind that this action can not be undone !",
    areYouSureToDeleteCustomSize:
        "Are you sure want to delete this custom size? Please keep in mind that this action can not be undone !",
    deleteSizeGuideSuccess: "Delete size guide successful!",
    allCustomSize: "All custom size",
    notValidUrl: "Not valid URL !",
    pleaseReenterAValidURL: "Please Re-enter a valid URL !",
    customSizeLevel: "Custom size level",
    customSizeLevelManager: "Custom size level manager",
    levelValue: "Level value",
    province: "Province",
    total_spending: "Total Spending",
    customSizeCreate: "Custom size create",
    formula: "Formula",
    level: "Level",
    deleteCustomSizeSuccess: "Delete custom size successful!",
    useForVariation: "Use for creating variations",
    metaDescription: "Meta description",
    metaTitle: "Meta title",
    storeLabel: "Store Label",
    inputType: "Input Type",
    swatchColor: "Swatch Color",
    swatchImage: "Swatch Image",
    swatchText: "Swatch Text",
    dropdown: "Drop Down",
    thisIsSize: "This is size",
    attributeShortDescription: "Attribute Short Description",
    variantWrongPrice:
        "All regular price of variants must be greater than sale price!",
    regularPriceGreaterThan: "Regular price must be greater than sale price!",
    admin: "Admin",
    frontEnd: "Frontend",
    addOption: "Add option",
    uploadFile: "Upload file",
    noData: "No Data",
    customSizeNameRequire: "Custom size name is require!",
    formulaNeedToFillInfo: "Formular need to filled all information",
    maxWidthNeedGreaterThan: "Max width need to be greater than min width",
    maxLengthNeedGreaterThan: "Max length need to be greater than min length",
    createCustomSizeSuccess: "Create custom size successful!",
    updateCustomSizeSuccess: "Update custom size successful!",
    inList: "In list",
    notInList: "Not in list",
    bath: "฿",
    pickColor: "Pick a color",
    fieldLabelIsRequired: "Field label is required!",
    successfullyCreatedAttribute: "Successfully Created Attribute",
    successfullyUpdatedAttribute: "Successfully Updated Attribute",
    valueOfAttributeIsRequired: "Value of attribute is required !",
    fieldPositionIsRequired: "Field postion is required !",
    successfullyDeletedAttribute: "Successfully Deleted Attribute !",
    areYouSureDeleteAttribute:
        "Are you sure want to delete this attribute? Please keep in mind that this action can not be undone !",
    updateCustomSizeLevelSuccess: "Update custom size level successful!",
    blockTable: "Block Table",
    block: "Block",
    blockCreate: "Block Create",
    identifier: "Identifier",
    homePage: "Home Page",
    blockName: "Block name",
    shortCode: "Short Code",
    slider: "Slider",
    flashSale: "Flash Sale",
    bestSeller: "Best Seller",
    shopBy: "Shop By",
    popularCategory: "Popular Category",
    middleBanner: "Middle Banner",
    seoContent: "SEO Content",
    template: "Template",
    hook: "Hook",
    homepageSlider: "Homepage-Slider",
    homepageFlashSale: "Homepage-Flash Sale",
    homepageBestSeller: "Homepage-Best Seller",
    homepageShopBy: "Homepage-Shop By",
    homepagePopularCategory: "Homepage-Popular Category",
    homepageMiddleBanner: "Homepage-Middle Banner",
    homepageSeoContent: "Homepage-SEO Content",
    newSlider: "New Slider",
    pc: "PC",
    mobile: "Mobile",
    homepageFeaturedIcon: "Homepage Featured Icon",
    featuredIcon: "Featured Icon",
    icon: "Icon",
    subTitle: "Subtitle",
    image: "Image",
    newIcon: "New Icon",
    flashSaleItem: "Flash Sale's Item",
    flashSaleCountdown: "Flash Sale's Countdown",
    dueDate: "Due Date",
    messages: "Messages",
    hideCountdown: "Hide Countdown",
    showMessages: "Show Messages",
    actionAfterExpire: "Action After Expire",
    addNewSliderHere: "Add new slider here",
    addFeaturedIconHere: "Add featured icon here",
    successfullyCreateBlock: "Successfully Created Block !",
    successfullyUpdatedBlock: "Successfully Updated Block !",
    bestSellerBanner: "Best Seller's Banner",
    bestSellerItem: "Best Seller's Items",
    customTab: "Custom Tab",
    createBlock: "Create Block",
    successfullyDeletedBlock: "Successfully Deleted Block !",
    areYouSureDeleteBlock:
        "Are you sure want to delete this block? Please keep in mind that this action can not be undone !",
    selectName: "Select Name",
    featuredIconProduct: "Featured Icons-Product",
    promotionMessagesProduct: "Promotion Messages-Product",
    hookProductFeaturedIcon: "Product Detail-Featured Icons",
    hookProductPromotionMessages: "Product Detail-Promotion Messages",
    itemToApply: "Items To Apply",
    tabNameFieldIsRequired: "Tab's Name Field Is Required !",
    typeFieldIsRequired: "Type Field Is Required !",
    shortDescIsTooLong: "Short descreption is too long (> 250 characters)",
    areYouSureWantToPerformThisModification:
        "Are you sure want to perform this modification ? Keep in mind that this action can not be undone!",
    thickness: "Thickness",
    warranty: "Warranty",
    useForAdminFilter: "User for admin filter",
    notValidNameFile: "Not valid file name",
    defaultProduct: "Default Product",
    customProduct: "Custom Product",
    variableProduct: "Variable Product",
    visible: "Visible",
    invisible: "Invisible",
    topBanner: "Top Banner",
    headerTopBanner: "Header Top Banner",
    homepageAroundSliderBanner: "Homepage Around Slider Banner",
    aroundSliderBanner: "Around Slider Banner",
    referenceLayout: "Reference Layout",
    textCountDown: "Text + Countdown",
    displayFrom: "Display from",
    displayTo: "Display to",
    backgroundColor: "Background Color",
    noColor: "No Color",
    textColor: "Text Color",
    countdownColor: "Countdown Color",
    countdown: "Countdown",
    text: "Text",
    socialMedia: "Social Media",
    areYouSureDeleteOrder:
        "Are you sure want to delete this order? Please keep in mind that this action can not be undone !",
    orderNoteForInternal: "Order Notes (For Internal Use Only)",
    saleInfinityStatus: "Sale infinity status",
    totalItem: "Total item",
    source: "Source",
    tax7InClude: "Tax (7% Included)",
    topBannerImage: "Top Banner Image",
    topBannerTextCountdown: "Top Banner Text Countdown",
    displayTime: "Display Time",
    keepCountdown: "Keep Countdown",
    menus: "Menu",
    menuNavigation: "Menu Navigation",
    productCategory: "Product Category",
    addToMenu: "Add to menu",
    navigationLabel: "Navigation Label",
    original: "Original",
    navigationIcon: "Navigation Icon",
    navigationIconPosition: "Navigation Icon Position",
    beforeLabel: "Before Label",
    afterLabel: "After Label",
    none: "None",
    thisIsMegaMenu: "This is mega menu",
    backgroundImage: "Background Image",
    backgroundPosition: "Background Position",
    backgroundCustomPosition: "Background Custom Position",
    moveFolder: "Move folder",
    selectFolder: "Select folder",
    moveMediaSuccess: "Move media successfull!",
    leftCenter: "Left Center",
    leftTop: "Left Top",
    leftBottom: "Left Bottom",
    centerTop: "Center Top",
    centerBottom: "Center Bottom",
    centerCenter: "Center Center",
    rightTop: "Right Top",
    rightCenter: " Right Center",
    rightBottom: "Right Bottom",
    paddingLeft: "Padding Left",
    paddingRight: "Padding Right",
    paddingTop: "Padding Top",
    paddingBottom: "Padding Bottom",
    moveToFolder: "Move to folder",
    move: "Move",
    editBrand: "Edit brand",
    editAttribute: "Edit attribute",
    editSizeGuide: "Edit size guide",
    editCustomSize: "Edit custom size",
    editBlock: "Edit block",
    editProduct: "Edit product",
    createMenu: "Create Menu",
    menuLocation: "Menu Location",
    swatchImageIsRequire: "Swatch image is require!",
    swatchColorIsRequire: "Swatch color is require!",
    assignedMenuLocation: "Assigned Menu Location",
    primaryShopCategory: "Primary Shop Category",
    topHeaderLeft: "Top Header (Left)",
    topHeaderRight: "Top Header (Right)",
    centralHeader: "Central Header",
    footerWidget1: "Footer (Widget 1)",
    footerWidget2: "Footer (Widget 2)",
    footerWidget3: "Footer (Widget 3)",
    footerWidget4: "Footer (Widget 4)",
    footerWidget5: "Footer (Widget 5)",
    footerWidget6: "Footer (Widget 6)",
    footer: "Footer",
    assignWidget: "Assign Widget",
    widget: "Widget",
    selectMenu: "Select Menu",
    widgetTitle: "Widget Title",
    customLink: "Custom Link",
    moveAll: "Move all",
    simpleProduct: "Simple product",
    variable: "Variable",
    browseImages: "Browse image",
    year: "Year",
    columnPosition: "Column Position",
    columnWidth: "Column Width (px)",
    cannotAddMoreMegaMenu: "Can not add more item to Megamenu",
    parent: "Parent",
    excessNumberOfItemToDropIntoMegaMenu:
        "The dropping  item is excessing level allowd to add in to a Megamenu",
    canNotDropMegaMenuIntoAnotherMenu:
        " Can not drop Megamenu into another menu",
    attributeData: "Attribute data",
    assignProductManually: "Assign product manually",
    selectProduct: "Select product",
    successfullyAssignedMenuLocation: "Successfully assigned menu location!",
    pleaseSelectTypeFirst: "Please Select Type First!",
    megaMenu: "Mega Menu",
    allStores: "All stores",
    createStore: "Create store",
    fax: "Fax",

    phoneNo: "Phone No.",

    latitude: "Latutude",
    longitude: "Longitude",
    storeDescription: "Store description",
    storeAddress: "Store address",
    storeTiming: "Store timing",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    areYouSureDeleteStore:
        "Are you sure want to delete this store? Please keep in mind that this action can not be undone !",
    successfullyDeletedStore: "Successfully deleted store",

    errorUploadingFile:
        "Sorry, this file type is not permitted for security reasons.",
    updateStoreSuccess: "Update store successful!",
    createStoreSuccess: "Create store successful!",
    open: "Open",
    blockEditor: "Block Editor",
    footerBlock: "Footer Block",
    mobileMenu: "Mobile Menu",
    content: "Content",
    tabTitle: "Tab's Title",
    tabTitleFieldIsRequired: "Tab's Title Field is required!",
    allBlogs: "All blogs",
    blogs: "Blogs",
    createBlog: "Create blog",
    editBlog: "Edit blog",
    areYouSureDeleteBlog:
        "Are you sure want to delete this blog? Please keep in mind that this action can not be undone !",
    successfullyDeletedBlog: "Successfully deleted blog",
    publishedDate: "Published date",
    headerImage: "Header image",
    productRelated: "Product related",
    breadCrump: "Breadcrumbs",
    feature: "Feature",
    blogNameIsRequire: "Blog title is require!",
    featureImageIsRequire: "Feature image is reuiqre!",
    catalog: "Blog category",
    addCatalog: "Add category blog",
    createCatalog: "Create blog category",
    parentCatalog: "Parent blog category",
    successfullyUpdatedCatalog: "Update blog category successfully!",
    successfullyCreatedCatalog: "Create blog category successfully!",
    nameIsRequire: "Name is reuiqre",
    privacyPolicy: "Privacy Policy",
    termOfUse: "Term Of Use",
    deliveryPolicy: "Delivery Policy",
    aboutUs: "About Us",
    breadCrumpIsRequire: "Breadcrumb is require!",
    groupProductItemRequire: "Group product items require!",
    bundleProductItemRequire: "Bundle product items require!",
    bundleProduct: "Bundle product",
    thisIsBundleProduct: "This is bundle product",
    bundleSKUItems: "Bundle SKU Items",
    bundleItemPriceNotValid:
        "Bundle items's sale price can not be over regular price",
    language: "Language",
    bundleTitle: "Bundle title",
    paymentHolding: "Payment holding",
    paymentCompleted: "Payment completed",
    billingAddress: "Billing Address",
    customerId: "Customer ID",
    allPopup: "All Popup",
    shipToName: "Ship-to Name",
    billToName: "Bill-to Name",
    purchasedDate: "Purchased Date",
    deleteCustomerSuccess: "Successfully Deleted Customer !",
    setDefaultShippingSuccess: "Successfully Set Default Shipping !",
    setDefaultBillingSuccess: "Successfully Set Default Billing !",
    popup: "Popup",
    createPopup: "Create Popup",
    ruleSet: "Rule Set",
    generalInformation: "General Information",
    target: "Target",
    cookie: "Cookie",
    allLanguage: "All Languages",
    Thai: "Thai",
    English: "English",
    loginUserPopup:
        "By default it will be shown to all visitors. Enabling this will let logged-in users see this too!",
    loginUser: "Logged-in User",
    hideOnDevice: "Hide on devices",
    tablet: "Tablet",
    computer: "Computer",
    displayRules: "Display Rules",
    exclusionRules: "Exclusion Rules",
    noBanner: "No Banner",
    addRule: "Add Rule",
    specificTarget: "Specific Target",
    allPages: "All Pages",
    entireSite: "Entire Site",
    pages: "Pages",
    days: "Days",
    hidePopupAfterClosing: "Hide Popup After Closing",
    doUWishToHide: "Do you wish to hide this popup after a visitor closes it?",
    thisWillNotBeShow:
        "This will not be shown to repeated visitors for the set number of days.",
    addSpecificCookie: "Add specific Cookie name after closing",
    ifUWantToSetSpecific:
        "If you want to set a specific cookie after closing of the popup, you can do that here :",
    enterCookieName: "Enter cookie name / names",
    setForXNumberOfDays: "Set for 'X' number of days after closing",
    displayWhenUserOnPageAtLeast:
        "Display when a user is on the page for at least:",
    displayWhenUserLeave: "Display when a user is about to leave the site",
    displayWhenUserScroll: "Display at - Display when a user scrolls the page:",
    displayWhenUserInActivity: "Display when a user is inactive for 60 seconds",
    displayWhenUserFinishBlog:
        "Display when a user finishes reading the full blog post",
    cookiesBaseTriggerText:
        "Enable this if you want to show this call-to-action when a particular cookie is set.",
    byDefaultShowAllVisitor: "By default it will be shown to all visitors",
    ruleReferrerText: "By default it will be shown to all visitors",
    ruleScheduleText:
        "Select the time period between which you wish to display this popup",
    ruleOnClickText:
        "Display when the user clicks a link, button or any element",
    afterSecondOnPage: "After Few Seconds on Page",
    exitIntents: "Exit intents",
    afterScroll: "After scroll",
    userInactivity: "User Inactivity",
    afterBlogRead: "After blog read",
    cookiesBaseTrigger: "Cookie Based Trigger",
    visitor: "Visitor",
    referrer: "Referrer",
    schedule: "Schedule",
    onClick: "On Click",
    seconds: "Seconds",
    displayType: "Display Type",
    visitorType: "Visistor Type",
    enterCookieNameTriggerCTA:
        "Enter cookie name / names that will trigger the CTA",
    youCanAddMultipleCookie:
        "You can add multiple Cookie names separated by a comma. Ex: firstcookie_name, secondcookie_name, thirdcookie_name. Do you want to hide this CTA when a particular cookie is set? You can do that here",
    firstTimeVisitor: "First Time Visistors",
    returningVisitor: "Returning Visistors",
    hideFrom: "Hide from",
    visitorWhoComeFrom: "Visistors who come from",
    enterYourCssClassOrId: "Enter your CSS Class or ID",
    pasteThisCopiedCodeInYourPage:
        "Paste this copied code in your page. Call-to-action will appear when a user clicks on it.",
    enterUniqueCssClassIDName:
        "Enter unique CSS Class / ID name of the element. Call-to-action will appear when a user clicks on it. You can add multiple classes or IDs separated by comma.",
    exampleOnClickPopUp: "Example - #button, .widget-title, .site-description",
    groupProduct: "Group product",
    customSizeProduct: "Custom size product",
    groupCustomSizeProduct: "Group custom size product",
    smartLayer: "Smart Layer",
    allSmartLayer: "All smart layer",
    createSmartLayer: "Create Smart Layer",
    areYouSureDeleteSmartLayer:
        "Are you sure want to delete this smart layer? Please keep in mind that this action can not be undone !",
    layerConfiguration: "Layer configuration",
    newLayer: "New layer",
    preview: "Preview",
    toggleIcon: "Toggle icon",
    layerImage: "Layer image",
    recommendW1000Auto: "Recommended size: W1000 x H-auto",
    recommend300: "Recommended size: W300x300",
    collapse: "Collapse",
    toggleIconExpand: "Toggle icon (Expand)",
    toggleIconCollapse: "Toggle icon (Collapse)",
    pulseEffectColor: "Pulse Effect Color",
    iconPositionHorizontal: "Icon Position (Horizontal)",
    iconPositionVertical: "Icon Position (Vertical)",
    iconSize: "Icon Size",
    appliedItems: "Applied Items",
    deleteSmartLayerSuccessfully: "Delete smart layer successfully!",
    updateSmartLayerSuccessfully: "Update smart layer successfully!",
    createSmartLayerSuccessfully: "Create smart layer successfully!",
    layerConfigurationsIsRequire: "Layer configurations is require!",
    layerConfigurationsFillAllData:
        "Layer configuration need to fill all data!",
    configTitleIsRequire: "Configuration's title is require!",
    configDescIsRequire: "Configuration's description is require!",
    iconSizePC: "Icon Size (PC)",
    iconSizeMobile: "Icon Size (Mobile)",
    layerImageIsRequired: "Layer image is required!",
    lastUpdate: "Last update",
    insight: "Insight",
    deletePopupSuccessfully: "Delete popup successfully!",
    areYouSureDeletePopup:
        "Are you sure want to delete this popup? Please keep in mind that this action can not be undone !",
    updatePopupSuccessfully: "Update popup successfully!",
    createPopupSuccessfully: "Create popup successfully!",
    allProductsCat: "All products category",
    allNews: "All news",
    allNewsCat: "All news category",
    searchPage: "Search page",
    errorPage: "404 page",
    checkout: "Checkout",
    productCat: "Product category",
    newsCat: "News category",
    product: "Product",
    news: "News",
    homepage: "Homepage",
    generalSetting: "General Setting",
    smartSeo: "Smart SEO",
    globalMeta: "Global Meta",
    localSEO: "Local SEO",
    socialMeta: "Social Meta",
    siteTitle: "Site Title",
    siteTitleNoted: "Your name or company name",
    openGraphThumb: "OpenGraph Thumb ",
    openGraphThumbNoted:
        "When a featured image is not set, this image will be used as a thumbnail when your post is shared on Facebook. Recommended image size 1200 x 630 pixels. ",
    googleSearchConsole: "Google Search Console",
    googleSearchConsoleNoted:
        "Enter your Google Search Console verification HTML code or ID. Get it from here: Search Console Verification Page <meta name='google-site-verification' content='your-id' />",
    bingWebmasterTools: "Bing Webmaster Tools",
    bingWebmasterToolsNoted:
        "Enter your Bing Webmaster Tools verification HTML code or ID. Get it here: Bing Webmaster Verification Page <meta name='msvalidate.01' content='your-id' />",
    facebookPageUrl: "Facebook Page URL",
    instagramURL: "Instagram URL",
    twitterURL: "Twitter URL",
    youtubeURL: "Youtube URL",
    pinterestURL: "Pinterest URL",
    lineOfficialURL: "LINE Official URL",
    facebookPageUrlNoted: "The URL to your Facebook page",
    instagramURLNoted: "The URL to your Instagram page",
    twitterURLNoted: "The URL to your Twitter page",
    youtubeURLNoted: "The URL to your Youtube channel",
    pinterestURLNoted: "The URL to your Pinterest page",
    lineOfficialURLNoted: "The URL to your LINE offical account",
    websiteURL: "Website URL",
    emailOfBusiness: "Email contact of business",
    contactPoint: "Contact Point",
    addContact: "Add Contact",
    addContactNoted:
        "Search engines may prominently display your contact phone number for mobile users.",
    packageTracking: "Package Tracking",
    roadsideAssistance: "Roadside Assistance",
    creditCardSupport: "Credit Card Support",
    reservations: "Reservations",
    sales: "Sales",
    billingPayment: "Billing Payment",
    billingSupport: "Billing Support",
    technicalSupport: "Technical Support",
    customerService: "Customer Service",
    cartRuleSyncId: "Cart Rule Sync ID",
    updateStatusSuccessful: "Update status successfully",
    updateSomethingSuccessfully: "Successfully Updated @something !",
    homepageSetting: "Homepage Setting",
    employeeDiscount: "Employee discount",
    areYouSureDeletePaymentMethod:
        "Are you sure want to delete this payment method? Please keep in mind that this action can not be undone !",

    successfullyDeletedPaymentMethod: "Successfully deleted payment method!",
    successfullyUpdatePaymentMethod: "Successfully update payment method!",
    allPaymentMethod: "All payment methods",
    installPaymentMethods:
        "Installed payment methods are listed below and can be sorted to control their display order on the frontend.",
    minimumOrder: "Minimum order",
    maximumOrder: "Maximum order",
    bankAccount: "Bank account",
    addNew: "Add new",
    syncId: "SyncId",
    accountNo: "Account No.",
    subDistrict: "Subdistrict",
    mustBe13Character: "Must be 13 characters!",
    mustBeOnlyDigit: "Must be only digits!",
    tokenIsExpire: "Access token is expired!",
    bookings: "Bookings",
    bookingNo: "Booking No.",
    openingCost: "Opening cost",
    serviceDate: "Service date",
    couponUsed: "Coupon used",
    categoryTable: "Category table",
    processQty: "Process Qty",
    updateAt: "Update at",
    deliveryProgress: "Delivery progress",
    workProgress: "Work progress",
    items: "Items",
    updateCategorySuccessfully: "Update category successfully!",
    createCategorySuccessfully: "Create category successfully!",
    deleteCategorySuccessfully: "Delete category successfully!",
    createCustomerSuccessfully: "Create customer successfully!",
    booked: "Booked",
    processing: "Processing",
    bookingTable: "Booking table",
    customerTable: "Customer table",
    categoryCreate: "Category create",
    customerCreate: "Customer create",
    serviceAddress: "Service address",
    updatePersonalInfo: "Update personal information",
    dateOfBirth: "Date of Birth",
    nationality: "Nationality",
    customerType: "Customer type",
    companyName: "Company name",
    companyTaxId: "Company tax id",
    areYouSureDeleteCustomer:
        "Are you sure want to delete this customer? Please keep in mind that this action can not be undone !",

    deleteCustomerSuccessfully: "Create customer successfully!",
    resetPassword: "Reset password",
    newBooking: "New booking",
    newCoupon: "New coupon",
    couponNo: "Coupon No.",
    serviceQty: "Service Qty.",
    lastLogin: "Last login",
    couponLeft: "Coupon left",
    updateAddress: "Update address",
    bookingDate: "Booking date",
    updateCustomerSuccessfully: "Update customer successfully!",
    createCustomerAddressSuccess: "Create customer's address successfully!",
    updateCustomerAddressSuccess: "Update customer's address successfully!",
    district: "District",
    userTable: "User table",
    userCreate: "User create",
    user: "User",
    companyId: "Company ID",
    role: "Role",
    allUsers: "All users",
    deleteUserSuccessfully: "Create user successfully!",
    areYouSureDeleteUser:
        "Are you sure want to delete this user? Please keep in mind that this action can not be undone !",

    userDetail: "User detail",
    password: "Password",
    sendResetPassword: "Send reset password",
    deleteUser: "Delete user",
    newPassword: "New password",
    newPasswordConfirm: "New password confirm",
    updateRole: "Update role",
    roles: "Roles",
    allRoles: "All roles",
    areYouSureDeleteRole:
        "Are you sure want to delete this role? Please keep in mind that this action can not be undone !",

    deleteRoleSuccessfully: "Create role successfully!",
    updateRoleResource: "Update role resource",
    typeOfCustomer: "Type of Customer",
    areYouSureDeleteAddress:
        "Are you sure want to delete this address? Please keep in mind that this action can not be undone !",
    deleteAddressSuccess: "Delete address successfully!",
    companyPhoneNo: "Company phone No.",
    addNewCategory: "Add new category",
    updateCategory: "Update category",
    serviceCategory: "Service category",
    priorChecking: "Prior checking",
    criteria: "Criteria",
    checkListItem: "Checklist item",
    criteriaTable: "Criteria table",
    criteriaCreate: "Criteria create",
    criteriaValue: "Criteria value",
    areYouSureDeleteCategory:
        "Are you sure want to delete this category? Please note that this action cannot be undone.",
    deleteCriteriaSuccess: "Delete criteria successfully!",
    textInput: "Text input",
    pleaseInput: "Please input",
    createCriteriaSuccess: "Create criteria successfully!",
    updateCriteriaSuccess: "Update criteria successfully!",
    createBookingSuccess: "Create booking successfully!",
    bookingCreate: "Booking create",
    finalOpeningCost: "Final opening cost",
    bookingNotesInternalUseOnly: "Booking notes (For internal use only)",
    enterNote: "Enter note",
    sourceOfBooking: "Source of booking",
    addServiceAddress: "Add service address",
    saveAsNewAddress: "Save as new address",
    booking: "Booking",
    areYouSureDeleteBooking:
        "Are you sure want to delete this booking? Please note that this action cannot be undone.",

    deleteBookingSuccessfully: "Delete booking successfully!",
    updateBookingSuccess: "Update booking successfully!",
    bookingUrl: "Booking URL",
    printBooking: "Print Booking",
    pic: "PIC",
    assignPic: "Assign PIC",
    worksheet: "Work Sheet",
    createWorksheet: "Create work sheet",
    areYouSureDeleteWorksheet:
        "Are you sure want to delete this worksheet? Please note that this action cannot be undone.",
    deleteWorksheetSuccessfully: "Delete worksheet successfully!",
    worksheetTable: "Worksheet table",
    worksheetCreate: "Worksheet create",
    worksheetNo: "Worksheet No.",
    createWorksheetSuccess: "Create worksheet successfully!",
    defection: "Defection",
    progress: "Progress",
    finalConfirmation: "Final confirmation",
    previous: "Previous",
    next: "Next",
    bookingNumber: "Booking number",
    useItemQty: "Use item Qty.",
    workingProtection: "Working protection",
    protected: "Protected",
    addItem: "Add item",
    defectionVisualization: "Defection visualization",
    defectionNote: "Defection note",
    defectionConfirmation: "Defection confirmation",
    iAcknowledgeAndConfirm:
        "I acknowledge and confirm all the defections which the specialist described above is competely accurate.",
    confirmServiceCompleted:
        "I acknowledge and confirm that the service has been completed.",

    customerConfirmation: "Customer confirmation",
    canNotProWithDiffService: "Can not select product with different services!",
    allBooking: "All bookings",
    allWorksheet: "All worksheet",
    allProductCategory: "All product categories",
    allCriteria: "All criteria",
    userRoles: "User roles",
    selectServiceAddress: "Select service address",
    printWorksheet: "Print worksheet",
    deleteCouponSuccessfully: "Delete coupon successfully!",
    coupons: "Coupons",
    couponTable: "Coupon table",
    expiring: "Expiring",
    used: "Used",
    effectiveDate: "Effective date",
    expiryDate: "Expiry date",
    addNewCoupon: "Add new coupon",
    leaveEmptyNoExpiry: "Leave empty for no expiration",
    createCouponSuccessfully: "Create coupon successfully!",
    available: "Available",
    voucher: "Voucher",
    voucherNo: "Voucher No.",
    areYouSureDeleteVoucher:
        "Are you sure you want to delete this voucher? Please note that this action cannot be undone.",
    areYouSureCancelVoucher:
        "Are you sure you want to cancel this voucher? Please note that this action cannot be undone.",
    deleteVoucherSuccess: "Delete voucher successfully!",
    cancelVoucherSuccess: "Cancel voucher successfully!",
    voucherTable: "Voucher table",
    vouchers: "Vouchers",
    areYouSureDeleteWarehouse:
        "Are you sure you want to delete this warehouse? Please note that this action cannot be undone.",

    deleteWarehouseSuccess: "Delete Warehouse Successfully!",
    warehouseCreate: "Warehouse create",
    warehouseTable: "Warehouse table",
    allWarehouse: "All warehouse",
    createWarehouseSuccessfully: "Create warehouse Successfully!",
    updateWarehouseSuccessfully: "Update warehouse Successfully!",
    warehouseInformation: "Warehouse information",
    warehouseUpdate: "Warehouse update",
    paymentMethodTable: "Payment Method Table",

    updatePaymentMethodSuccess: "Update warehouse Successfully!",
    stockQty: "Stock Qty.",
    importStock: "Import stock",
    validate: "Validate",
    done: "Done",

    areYouSureToDeleteOrder:
        "Are you sure you want to delete this order? Please note that this action cannot be undone.",

    deleteOrderSuccess: "Delete order Successfully!",
    createOrderSuccess: "Create order Successfully!",
    searchCustomer: "Search customer",
    contact: "Contact",
    updateContact: "Update contact",
    show: "Show",
    discountAndFee: "Discount & Fess",
    addTransportationFee: "Add transportation fee",
    commercialTaxInvoice: "Commercial Tax Invoice",
    iWantCommercialTax: "I want commercial tax invoice",
    stockStatus: "Stock status",
    transportationFee: "Transportation fee",
    deliveryAssignment: "Delivery assignment",
    worksheetReference: "Worksheet reference",
    addDiscountItem: "Add discount item",
    allVoucher: "All voucher",
    voucherCreate: "Voucher create",
    newVoucher: "New voucher",
    createVoucherSuccess: "Create voucher successfully!",
    voucherDetail: "Voucher detail",
    information: "Information",
    ownerShipStatus: "Ownership Status",
    usageStatus: "Usage Status",
    usedAt: "Used at",
    thisWorksheetNoProductLeft:
        "This worksheet %worksheetNo have no products left",
    theWorksheetNoProductLeft:
        "The worksheet number: @worksheet  have no products left",
    youNeedAddAtLeastOneProduct: "You need to add at least one product!",
    transportation: "Transportation",
    feeType: "Fee type",
    addFee: "Add fee",
    doYouWantApplyCustomerOrder:
        "Do you want to apply the customer information of this worksheet for this order?",
    estServiceDate: "Est. Service Date",
    scheduled: "Scheduled",
    serviceProduct: "Service product",
    scheduleServiceDate: "Schedule service date",
    areYouSureToDeleteSchedule:
        "Are you sure you want to delete this schedule? Please note that this action cannot be undone.",
    deleteScheduleSuccess: "Delete Schedule Successfully!",
    bookingIsNotInScheduling: "Booking is not in scheduling!",
    thereIsNoAnyProgress: "There is no any progress yet",
    thereIsNoFinalConfirmation: "There is not final confirmation yet",
    voucherIsInvalid: "Voucher is invalid",
    addAddressSuccess: "Add address successfully!",
    moreActions: "More actions",
    cancelOrderSuccess: "Cancel order successfully",
    confirmCancelOrderBefore:
        "You are going to cancel this order. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    sendConfirmToThisEmail:
        "By default, you'll send the order confirmation to this customer's contact email: ",
    otherEmail: "Other email",
    pleaseAdvisedThisOrder:
        "Please be advised that this Order Note section will only be used for internal purpose and will not be shown to customers.",
    nameOfPayer: "Name of payer",
    c2pTransactionId: "2C2P Transaction ID",
    areYouCertainConfirmPayment:
        "Are you certain that this payment has been fully paid? Please note that this action cannot be undone.",
    areYouCertainRejectPayment:
        "Are you certain that you want to reject this payment? Please note that this action cannot be undone.",
    proofOfPaymentReceive: "Proof of payment received",
    totalCart: "Total cart",
    splitAmount: "Split amount",
    paidAmountCannotGreater:
        "The total paid amount cannot be greater than the total cart !",
    splitPaymentSuccess: "Split payment successfully!",
    fillAllPaymentAmount: "Please fill all the payment amount fields!",
    orderQty: "Order Qty",
    availableQtyWH: "Available Qty. (Current WH)",
    allDelivery: "All deliveries",
    inDelivery: "In-Delivery",
    uploadProofPaymentSuccess: "Upload proof payment successfully!",
    updateDeliveryStatusSuccess: "Update delivery status successfully!",
    paymentPending: "Payment pending",
    notAllowDefaultPaymentSplit:
        "Not allow default payment when splitting payment",
    confirmPaymentSuccess: "Confirm payment successfully!",
    rejectPaymentSuccess: "Reject payment successfully!",
    noBilling: "No Billing",
    noShipping: "No shipping address",
    noServiceAddress: "No service address",
    cantChooseFutureDate: "Can not choose future date",
    copiedToClipboard: "Copied to clipboard",
    deliveryFailed: "Delivery failed",
    paymentFullyConfirmed: "Payment fully confirmed",
    paymentFullyRejected: "Payment fully rejected",
    paymentPartiallyRejected: "Payment partially rejected",
    paymentPartiallyConfirm: "Payment partially confirmed",
    infinity: "Infinity",
    noExpireDate: "No expire date",
    onlyCreateDeliveryWhenProcessing:
        "You only can create delivery when order's status is processing or delivery processing",
    onlyCreateDeliveryContainSimple:
        "You only can create delivery when order's product contain at least one simple product",
    availableQtyCurrentStore: "Available Qty. (Current WH)",
    onHoldQtyCurrentStore: "On-hold Qty. (Current WH)",
    availableQtyDelivery: "Available Qty. (Delivery)",
    onHoldQtyDelivery: "On-hold Qty. (Delivery)",
    availableQtyCurrentStoreTooltip:
        "The available quantity for creating sales orders at the selected store/warehouse (excluding on-hold quantity)",
    onHoldQtyCurrentStoreTooltip:
        "The quantity that customer already reserved at the selected store/warehouse (Not available for creating sales orders)",
    availableQtyDeliveryTooltip:
        "The available quantity which is ready for delivery (excluding on-hold quantity for delivery)",
    onHoldQtyDeliveryTooltip:
        "The quantity that customer already reserved for delivery purpose (Not available for creating sales orders)",
    onHold: "On hold",
    inventoryAllocation: "Inventory allocation",
    onHoldAllocation: "On-hold allocation",
    onHoldQty: "On-hold Qty.",
    currentQty: "Current Qty.",
    currentQtyToolTip:
        "The current quantity of inventory at the selected store/warehouse.",
    onHoldQtyToolTip:
        "The quantity that customer already reserved at the selected store/warehouse..",
    totalQtyToolTip:
        "The total quantity of inventory at all stores/warehouses that currently have this stock.",
    premiumServiceProduct: "Premium service product",
    tooltipGroupProduct:
        "Group Product is a product that consists of more than 1 simple product. Price of group product will be a sum of all simple products consisted",
    tooltipPremiumService:
        "Premium service is a special product type that applicable to any service of the selected services",
    premiumService: "Premium service",
    thisBookingNotHaveAnyService: "This booking does not have any services",
    applyVoucherConfirmation: "Apply voucher confirmation",
    serviceShippingProfile: "Service shipping profile",
    sameAsServiceAddress: "Same as service address",
    resetUserPasswordSuccessfully: "Reset user password successfully!",
    createUserSuccessfully: "Create user successfully!",
    actualDateReturn: "Actual date of return",
    returnedOn: "Returned on",
    access: "Access",
    list: "List",
    createRoleSuccess: "Create role successfully!",
    updateRoleSuccess: "Update role successfully!",
    resetPaymentSuccessfully: "Reset payment successfully!",
    bookingContact: "Booking contact",
    sendInvoiceCustomer: "Send invoice to customer email",
    sendInvoice: "Send invoice",
    sendInvoiceToThisEmail:
        "By default, you'll send the order invoice to this customer's contact email: ",
    sendBookingConfirm: " Send Booking Confirmation to Customer Email",
    sendBookingConfirmToThisEmail:
        "By default, you'll send the booking confirmation to this customer's contact email: ",
    cantCancelApprovedPayment:
        "You cannot cancel this order because there is an approved payment. Please reset the payment first before cancelling this order",
    cantCancelOnGoingDelivery:
        "You cannot cancel this order because there is an on-going delivery. Please cancel it first before cancelling this order",
    cantCancelOrder: "You can not cancel this order",
    activity: "Activity",
    cancelBooking: "Cancel booking",
    areYouSureToCancelBooking:
        "You are going to cancel this booking. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone",
    cancelBookingSuccessfully: "Cancel booking successfully!",
    cancelWorksheetSuccessfully: "Cancel worksheet successfully!",
    markCompleteSuccessfully: "Marking complete booking successfully!",
    bookingStatusNotValidToCancel: "Booking status is not valid to cancel",
    markAsComplete: "Mark as completed",
    bookingStatusNotValid: "Booking status is invalid",
    markCompleteBookingDesc:
        "You are going to mark this booking as completed. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    criteriaLengthCantGreaterThan:
        "Criteria length can't greater than total of using quantity",
    needAddFullCriteria: "Need to add full criteria items",
    authentication: "Authentication",
    userGoogleToScanThisCode:
        "Use Google Authentication to scan this QR Code for the 2-step verification. Please make sure to keep the secret code confidentially. In case you sent this QR Code  for user to scan, please make sure to unsend/delete it upon the completion.",
    generate: "Generate",
    secretCode: "Secret code",
    regenerate: "Regenerate",
    qrCode: "QR code",
    username: "Username",
    areYouSureToCancelWorksheet:
        "You are going to cancel this worksheet. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone",
    worksheetStatusNotValidToCancel: "Worksheet status is not valid to cancel",
    loginWithWorkInfinity: "Login with Work Infinity X",
    mapCustomProductSuccess: "Map custom product successfully!",
    newsCreate: "News create",
    newsTable: "News table",
    newsCategory: "News category",
    visibilityPriority: "Visibility priority",
    bookService: "Book service",
    featuredBanner: "Featured banner",
    serviceToBook: "Serivce to book",
    createNewsSuccess: "Create news successfully",
    updateNewsSuccess: "Update news successfully",
    blockFeature: "Block feature",
    blockBestSelling: "Block best selling",
    blocks: "Blocks",
    removeProductSuccessfully: "Remove product successfully",
    addProductSuccessfully: "Add product successfully",
    banners: "Banners",
    bannerTable: "Banner table",
    bannerCreate: "Banner create",
    homePageTop: "Home page top",
    homePageMiddle1: "Home page middle 1",
    homePageMiddle2: "Home page middle 2",
    homePageBottom: "Home page bottom",
    allBanner: "All banner",
    createBannerSuccess: "Create banner successfully",
    updateBannerSuccess: "Update banner successfully",
    homeBottom: "Home bottom",
    sliderContent: "Slider content",
    inAppNews: "In-app News",
    destinationType: "Destination type",
    external: "External",
    voucherUsed: "Voucher used",
    updatePassword: "Update password",
    passwordNotMatch: "Password not match",
    updatePasswordSuccess: "Update password successfully",
    updateSmartSupportSuccess: "Update Smart Support successfully",
    confirmPassword: "Confirm password",
    selfCreated: "Self-created",
    customerCreated: "Customer created",
    voucherOnlyApllyWorksheet:
        "Sorry! This voucher can only be applied for the product that loaded from the worksheet!",
    invalidStatusChangeExpiryDate:
        "You can not change expiry datw with current order status",
    smartSupport: "Smart Support",
    url: "URL",
    themeColor: "Theme Color",
    rotatingSpeed: "Rotating Speed",
    mobileHeader: "Mobile Header",
    slow: "Slow",
    medium: "Medium",
    fast: "Fast",
    addPriorCheckingSuccess: "Add prior checking successfully!",
    areYouSureWantRemove:
        "Are you sure you want to remove this? Please note that your action cannot be undone.",
    removePriorCheckingSuccess: "Remove prior checking successfully!",
    areYouSureCompleteChecklist:
        "Are you sure you want to complete this checklist? Please note that your action cannot be undone.",
    areYouSureCancelChecklist:
        "Are you sure you want to cancel this checklist? Please note that your action cannot be undone.",
    addDefectionVisualSuccess: "Add defection visualization successfully!",
    updateDefectionVisualSuccess:
        "Update defection visualization successfully!",
    removeDefectionVisualSuccess:
        "Remove defection visualization successfully!",
    areYouSureConfirmThis:
        "Are you sure you want to confirm this? Please note that your action cannot be undone.",
    worksheetDefectionConfirmed: "Worksheet defection confirmed",
    defectionNeedToBeConfirmed: "Defection need to be confirmed first",
    bySigningThisBothCustomer:
        "By signing this, both customer and De Hygienique staff acknowledge and confirm all the works which the specialist completed above is completely accurate.",
    reportByPrice: "Report by price",
    estDateNeedToGreaterThan: "Estimate service date need to grater than now",
    FAQ: "FAQ",
    newCategory: "New Category",
    newArticle: "New Article",
    updateStSuccess: "updateStSuccess",
    createStSuccess: "createStSuccess",
    manualArticle: "manualArticle",
    serverError: "serverError",
    confirmDeleteSetting: "confirmDeleteSetting",
    deleteStSuccess: "deleteStSuccess",
    manualArticleCreate: "manualArticleCreate",
    categories: "categories",
    manualCategory: "Manual Category",
    inActive: "In-active",
    newFAQ: "New FAQ",
    c2pAPICredential: "2C2P API Credential",
    merchantId: "Merchant ID",
    secretKey: "Secret key",
    mode: "Mode",
    bookingAssigned: "Bookings Assigned",
    faq: "FAQ",
    preferNotToSay: "Prefer not to say",
    loadWorksheet: "Load worksheet",
    manual: "Manual",
    autoGenerated: "Auto-generated",
    prefix: "Prefix",
    suffix: "Suffix",
    voucherCode: "Voucher Code",
    customerOptional: "Customer (Optional)",
    voucherSelectCustomerNote:
        "Leave it blank if you would like any customer can use it",

    //***********************NEW WORDS 4/11/2022 ************************** */

    authorizationCode: "Authorization Code",
    confirmAll: "Confirm all",
    selectVoucher: "Select Voucher",
    selectService: "Select Service",
    usingQty: "Using Quantity",
    expiringOn: "Expiring on",
    until: "until",
    applyAuthorizationCodeForVoucherDescription:
        "Please input the 6-digit authentication code generated from the customer De Hygienique application in order to authorize this action.",
    noVoucherAvailable: "No Voucher Available",
    youDontHavePermissionToAccessThisData:
        "Sorry! You don't have permissions to access this data !",
    confirmBeforeRemoveVouchers: "Are you sure want to remove all vouchers ?",
    codeIsAlreadyApplied: "Code is already applied !",
    voucherSelectServiceDescription:
        "Please select the service you would like to apply the voucher in the list service below.",
    availableVoucherQty: "Available Vouchers Qty.",
    orText: "Or",
    appliedVoucher: "Applied Voucher",
    appliedVoucherQty: "Applied Voucher Qty.",
    ownership: "Ownership",
    anonymous: "Anonymous",
    wantToRemoveVouchers: "Want to remove any anonymous voucher?",
    scanToRemove: "Scan to remove",
    confirmToRemove: "Confirm to remove",
    codeIsInvalid: "Code is invalid !",
    noQtyLeft: "No quantity left to apply vouchers !",
    requestRefund: "Request refund",
    totalPaidAmount: "Total paid amount",
    requestedRefundAmount: "Requested refund amount",
    services: "Services",
    thisOrderHasNoVoucher: "This order has no voucher",
    requestRefundSuccessfully: "Request refund successfully",
    notGrantPermission:
        "You are not granted to access this data or this action. Please contact administrator ",
    dateOfRequest: "Date of request",
    viewList: "View list",
    itemsToRefund: "Items to refund",
    addAttachmentSuccess: "Add attachment successfully!",
    addBank: "Add bank",
    addBankSuccessfully: "Add bank successfully!",
    refundToBankAccount: "Refund to bank account",
    goingApproveRefund:
        "You are going to approve the refund request for this order. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    goingRejectRefund:
        "You are going to reject the refund request for this order. Check carefully and confirm one more time to make sure of your action. Please note that this action cannot be undone.",
    refundConfirmed: "Refund confirmed",
    refundRejected: "Refund rejected",
    userNamePassword: "Username & Password",
    orLoginWith: "or login with",
    resetRefund: "Reset refund",
    onlyResetRefundStatus:
        "Only reset refund's status when Confirmed or Rejected",
    resetRefundSuccess: "Reset refund successfully!",
    onlyRequestRefundWhenCancel:
        "You only can create request refund when order is cancelled",
    cantRefundVoucherByNormalProduct:
        "Can not refund voucher by normal product",
    staticContent: "Static content",
    updateStaticContentSuccess: "Update static content successfully!",
    statusNotValidToCancel: "Status is not valid to cancel",
    allRefundRequest: "All refund request",
    refundRequestCreate: "Refund request create",
    refundRequestTable: "Refund request table",
    newRefundRequest: "New refund request",
    aServiceNotUsed: "A service that has not been used yet",
    aServiceUsed: "A service that has already been used",
    refundType: "Type of refund",
    refundFormat: "Format of refund",
    onlyRefundCancelledOrder: "Only make request refund of cancelled order",
    onlyRefundCompletedWorksheet:
        "Only make request refund of completed worksheet",
    orderHasNoPaymentConfirmed: "This order has no payment confirmed",
    hasNoProductToRefund: "This worksheet has no product to refund",
    createRefundSuccess: "Create refund successfully!",
    ownershipVoucher: "Ownership Vouchers",
    anonymousVoucher: "Anonymous Vouchers",
    voucherType: "Voucher Type",
    ownershipType: "Ownership Type",
    premiumVoucher: "Premium Voucher",
    normalVoucher: "Normal Voucher",
    canNotResetVoucher: "This worksheet is not allowed to reset voucher !",
    addVoucher: "Add Voucher",
    removeVoucher: "Remove Voucher",
    enterVoucherCode: "Enter voucher code",
    selectAnonymousVoucher: "Select Anonymous Voucher",
    voucherInputDescription:
        "Please input the voucher code or simply press the button Scan to scan the voucher Barcode/QR Code",
    refundNo: "Refund No.",
    confirmedAt: "Confirmed at",
    sureToResetRefund: "Are you sure to reset this refund?",
    transferOwnership: "Transfer ownership",
    changeExpiryDateSuccess: "Change expiry date successfully!",
    transferOwnershipSuccess: "Tranfer ownership successfully!",
    worksheetRef: "Worksheet Ref.",
    serviceYetUsedCanNotRefundVoucher:
        "With service that have not been used yet. Can not refund by voucher!",
    pleaseSearchAndSelect: "Please search and select",
    productSku: "Product SKU",
    createdDateFrom: "Created date from",
    createdDateTo: "Created date to",
    owned: "Owned",
    openingPrice: "Opening price",
    priceFromOrder: "Price from order",
    startingMileage: "Starting mileage",
    endingMileage: "Ending mileage",
    areYouSureToDoThisAction:
        "Are you sure to make this action? Please not that this action cannot be undone.",
    startingMileageSuccess: "Starting mileage successfully!",
    endingMileageSuccess: "Ending mileage successfully!",
    mile: "Mile",
    otpAuthorizeCode: "OTP authorize code",
    approveAddingVoucherOwnership: "Approve adding voucher ownership",
    authOTP: "Authorize OTP",
    yourDiscountAuthOTPToken: "Your Discount Authorization OTP Token is",
    warningTokenOTP:
        "Warning: For your own safety, please only use this authorization code appropriately. Please note that all the OTP Token will be saved as the proof of authorization in the system.",
    verificationMethod: "Verification method",
    authorization: "Authorization",
    smsOtpDesc:
        "There is a 6-digit verification code that will be sent to the customer phone number: %phoneNumber. Please make sure the phone number is correct before continuing the next step. In case the phone number is incorrect, please contact the Administrator for further assistance.",
    enterDigitAdminToken:
        "Please input the 6-digit Admin Token code generated from the authorized admin in order to authorize this action.",
    inputDigitPhoneNumber:
        "Please input the 6-digit verification code sent to your phone number %phoneNumber",
    didNotReceiveTheCode: "Did not receive the code?",
    resendNowSecond: "Resend now (%second)",
    creditTerm: "Credit term",
    month: "month",
    day: "Day",
    creditNote: "Credit note",
    iWantApplyCreditNote: "I want to apply credit note",
    years: "Years",
    paymentScheduledOn: "Payment scheduled on",
    onCreditNote: "On credit note",
    numberCriteriaCreateNewNotOver:
        "Number of creating new critera items can not over than %number items",
    ownershipVoucherSummary: "Ownership voucher summary",
    customerInfo: "Customer info",
    verified: "Verified",
    unVerified: "Unverified",
    verify: "Verify",
    phoneNumberVerification: "Phone number verification",
    inOrderToVerifyPhone:
        "In order to verify your phone number, a 6-digit verification code will be sent to this phone number ",
    sendOtp: "Send OTP",
    verifySuccess: "Verify successfully!",
    creditCardEdc: "Credit card EDC",
    mileageTracking: "Milleage tracking",
    checkConfirmWholeService:
        "Please check carefully and confirm to complete the whole service. Please note that your action cannot be undone after this step.",
    yourBookingBulkWorksheet:
        "Your booking #%bookingNo contains some worksheets that can be confirmed at the same time. Select to confirm all at once",
    bulkConfirmation: "Bulk confirmation",
    current: "Current",
    summary: "Summary",
    systemVisibility: "System visibility",
    customerVisibility: "Customer visibility",
    worksheetAssigned: "Worksheet assigned",
    coSales: "Co-sales",
    giftVoucherConversion: "Gift voucher conversion",
    allowCombineVoucherConversion: "Allow to combine voucher conversion",
    canNotSelectQuantityVoucher:
        "Can not select quantity greater than quantity of voucher",
    canNotSelectQuantityOverService:
        "Can not select quantity greater than quantity of service",
    numberOfSeat: "Number of seat",
    totalSeat: "Total seat",
    canNotSelectQuantityOverTotalSeat:
        "Can not select quantity greater than quantity of total of seat",
    voucherAppliedSeat: "Voucher-applied seat",
    remainingSeatBilled: "Remaining seat to be billed",
    youNeedAddAtLeastOneDefaultProduct:
        "You need to add at least one default product!",
    onlyAcceptPremiumVoucher: "Only accept premium voucher",
    premiumVoucherNoIncludeProduct:
        "This premium voucher not include any added order's product",
};
