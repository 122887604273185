import { Modal, Progress, TimeUtils } from "d-react-components";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import React from "react";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import {
    CUSTOMER_EXPORT_FIELDS,
    CUSTOMER_EXPORT_TYPES,
} from "../../../constant/customer";
import { CustomerExportSchema } from "../../../formschema/customer";
import { mapCustomerFilterBodyToServer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import FileUtils from "../../../utils/FileUtils";
import CustomerFilterForm from "./CustomerFilterForm";

interface ICustomerExportModal {
    open: boolean;
    onClose: () => void;
}

const CustomerExportModal = ({ open, onClose }: ICustomerExportModal) => {
    const exportForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerExportSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerFilterBodyToServer(values);
            onExport(input);
        },
    });

    const onExport = (filterBody: any) => {
        Progress.show(
            {
                method: CustomerAPI.getExportList,
                params: [1000, filterBody],
            },
            (res: any) => {
                const customerList = res?.data?.customerExport ?? [];
                const fileData = FileUtils.convertRawToFileData(
                    customerList,
                    CUSTOMER_EXPORT_FIELDS
                );

                const fileName = `CustomerExport_${TimeUtils.toDateTime(
                    new Date().getTime()
                )}`;
                FileUtils.exportToCSV(fileData, fileName);
                onClose();
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.export}
            saveText={Messages.export}
            onSave={() => exportForm.handleSubmit()}
        >
            <div>
                <div className="row">
                    <InputSelectForm
                        keyData="exportType"
                        form={exportForm}
                        dataSource={CUSTOMER_EXPORT_TYPES}
                        className="col-12"
                    />
                </div>
                <CustomerFilterForm formData={exportForm} />
            </div>
        </Modal>
    );
};

export default CustomerExportModal;
