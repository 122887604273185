export default {
    ROOT: "/",

    /* ----------------------------------------
    // AUTH
    // ---------------------------------------- */
    AUTH: "/auth",
    AUTH_LOGIN: "/auth/login",
    AUTH_FORGET_PASS: "/auth/forgetpassword",
    AUTH_OTP_AUTH: "/auth/otp-auth",

    /* ----------------------------------------
    // OAUTH
    // ---------------------------------------- */
    OAUTH: "/oauth",

    /* ----------------------------------------
    // BOOKING
    // ---------------------------------------- */
    BOOKING_TABLE: "/bookings",
    BOOKING_ASSIGNED_TABLE: "/bookings/assigned",
    BOOKING_CREATE: "/bookings/create",
    BOOKING_DETAIL: "/bookings/detail/:bookingId",

    /* ----------------------------------------
    // WORKSHEET
    // ---------------------------------------- */
    WORKSHEET_TABLE: "/worksheet",
    WORKSHEET_ASSIGNED_TABLE: "/worksheet/assigned",
    WORKSHEET_CREATE: "/worksheet/create",
    WORKSHEET_DETAIL: "/worksheet/detail/:worksheetId",

    /* ----------------------------------------
    // VOUCHER
    // ---------------------------------------- */
    VOUCHER_TABLE: "/voucher",
    VOUCHER_CREATE: "/voucher/create",
    VOUCHER_DETAIL: "/voucher/detail/:voucherId",

    /* ----------------------------------------
    // CUSTOMER
    // ---------------------------------------- */
    CUSTOMER_TABLE: "/customers",
    CUSTOMER_DETAIL: "/customers/detail/:customerId",
    CUSTOMER_CREATE: "/customers/create",

    /* ----------------------------------------
    // CATEGORY
    // ---------------------------------------- */
    CATEGORY_TABLE: "/categories",
    CATEGORY_DETAIL: "/categories/detail/:catId",
    CATEGORY_CREATE: "/categories/create/:duplicateId",

    /* ----------------------------------------
    // CRITERIA
    // ---------------------------------------- */
    CRITERIA_TABLE: "/criteria",
    CRITERIA_DETAIL: "/criteria/detail/:criteriaId",
    CRITERIA_CREATE: "/criteria/create",

    /* ----------------------------------------
    // USERS
    // ---------------------------------------- */
    USER_TABLE: "/users",
    USER_DETAIL: "/users/detail/:userId",
    USER_CREATE: "/users/create",
    USER_ROLES: "/users/roles",
    USER_ROLE_DETAIL: "/users/roles/detail/:roleId",
    USER_ROLE_CREATE: "/users/roles/create",

    /* ----------------------------------------
    // MEDIA
    // ---------------------------------------- */
    MEDIA: "/medias/manager",
    MEDIA_FOLDER: "/medias/manager/:folderId",
    MEDIA_DETAIL: "/medias/manager/:folderId/:mediaId",

    /* ----------------------------------------
    // SMART SUPPORT
    // ---------------------------------------- */
    SMART_SUPPORT: "/smart_support",
    FAQ_MANAGER: "/faq",

    /* ----------------------------------------
    // PRODUCT
    // ---------------------------------------- */
    PRODUCT_TABLE: "/product",
    PRODUCT_DETAIL: "/product/detail/:productId",
    PRODUCT_CREATE: "/product/create/:duplicateId",
    PRODUCT_CAT_TABLE: "/product/category",
    /* ----------------------------------------
    // PRODUCT
    // ---------------------------------------- */
    INVENTORY_TABLE: "/inventory",

    /* ----------------------------------------
    // WAREHOUSE
    // ---------------------------------------- */
    WAREHOUSE_TABLE: "/warehouse",
    WAREHOUSE_CREATE: "/warehouse/create",
    WAREHOUSE_DETAIL: "/warehouse/detail/:warehouseId",

    /* ----------------------------------------
    // PAYMENT METHOD
    // ---------------------------------------- */
    PAYMENT_METHOD_TABLE: "/payment-method",
    PAYMENT_METHOD_CREATE: "/payment-method/create",
    PAYMENT_METHOD_DETAIL: "/payment-method/detail/:paymentMethodId",

    /* ----------------------------------------
    // ORDER
    // ---------------------------------------- */
    ORDER_TABLE: "/order",
    ORDER_CREATE: "/order/create",
    ORDER_DETAIL: "/order/detail/:orderId",

    /* ----------------------------------------
    // NEWS
    // ---------------------------------------- */
    NEWS_TABLE: "/news",
    NEWS_DETAIL: "/news/detail/:newsId",
    NEWS_CREATE: "/news/create",
    NEWS_CAT_TABLE: "/news/category",

    /* ----------------------------------------
    // BLOCK
    // ---------------------------------------- */
    BLOCK_FEATURE: "/block/feature",
    BLOCK_BEST_SELLING: "/block/best-selling",

    /* ----------------------------------------
    // BLOCK
    // ---------------------------------------- */
    BANNER_TABLE: "/banner",
    BANNER_DETAIL: "/banner/detail/:bannerId",
    BANNER_CREATE: "/banner/create",

    /* ----------------------------------------
    // STATIC CONTENT
    // ---------------------------------------- */
    STATIC_CONTENT: "/static-content",
    TERM_OF_USE: "/static-content?type=TERM_OF_USE",
    PRIVACY_POLICY: "/static-content?type=PRIVACY_POLICY",

    /* ----------------------------------------
    // REFUND
    // ---------------------------------------- */
    REFUND_REQUEST_TABLE: "/refund-request",
    REFUND_REQUEST_DETAIL: "/refund-request/detail/:refundRequestId",
    REFUND_REQUEST_CREATE: "/refund-request/create",
};
