// react
import React from "react";
import { Link } from "react-router-dom";

function AppLink(props: any) {
    const { external, children, disabled, ...otherProps } = props;
    let link;

    if (disabled) return <div {...otherProps}>{children}</div>;

    if (external) {
        const { to, replace, innerRef, ...linkProps } = otherProps;

        link = (
            <a href={to} {...linkProps}>
                {children}
            </a>
        );
    } else {
        link = <Link {...otherProps}>{children}</Link>;
    }

    return link;
}

export default AppLink;
