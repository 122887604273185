import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Notifications } from "d-react-components";
import { includes, map } from "lodash";
import Messages from "../languages/Messages";
import Path from "../screen/Path";
import { signOut } from "../store/auth/authActions";
import store from "../store/store";

const UNAUTHORIZE = 401;
const DATA_ERROR = 400;

export const AuthMiddleware = new ApolloLink((operation, forward) => {
    try {
        const accessToken = store.getState().auth?.accessToken;
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            },
        }));
    } catch (error: any) {
        console.log({ error });
    }

    return forward(operation);
});

export const LangMiddleware = new ApolloLink((operation, forward) => {
    try {
        const language = Messages.getLanguage();
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                language,
            },
        }));
    } catch (error: any) {
        console.log({ error });
    }

    return forward(operation);
});

// export const PermissionInterceptor = {
//     checkPermission: (config) => {
//         if (PermissionUtils.isGrantURLPermission(config.url, config.method)) {
//             return config;
//         }
//         const urlSlash = config.url.split("/");
//         const urlTail = urlSlash[urlSlash.length - 1];
//         Notifications.showError(Messages.youNotHavePermission + " " + urlTail);
//         return Promise.reject({
//             message: Messages.youNotHavePermission + " " + urlTail,
//         });
//     },
// };

const UNAUTHORIZE_MESSAGE = "Authentication token is wrong or expired";

export const UnauthorizeMiddleware = onError((error: any) => {
    const messageErrors = map(
        error?.graphQLErrors,
        (item: any) => item.message
    );
    const isUnauthorized = includes(messageErrors, UNAUTHORIZE_MESSAGE);
    if (isUnauthorized && store.getState()?.auth.accessToken) {
        Notifications.showError(Messages.tokenIsExpire);
        store.dispatch(signOut());
        window.open(Path.AUTH, "_self");
    }
});
