import {
    Button,
    Checkbox,
    Modal,
    Notifications,
    Progress,
} from "d-react-components";
import { map } from "lodash";
import { useEffect, useState } from "react";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { useDeleteCustomerAdd } from "../../../hoook/customer";
import { IBillingAddress, mapAddressToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import CustomerCrudBillingModal from "./CustomerCrudBillingModal";

interface ISelectBillingItem {
    billing: IBillingAddress;
    isChecked: boolean;
    customerId: string;
    onChangeCheck: () => void;
    onUpdateSuccess: () => void;
}

const SelectBillingItem = ({
    billing,
    isChecked,
    onChangeCheck,
    onUpdateSuccess,
    customerId,
}: ISelectBillingItem) => {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const { onClickDelete } = useDeleteCustomerAdd(
        customerId,
        billing?.id,
        onUpdateSuccess,
        "deleteBillingAddress"
    );

    const onUpdateAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.updateBillingAddress,
                params: [customerId, body?.id, mapAddressToSer(body)],
            },
            () => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                onUpdateSuccess();
            }
        );
    };

    return (
        <div className="d-flex justify-content-between border-bottom py-3">
            <div className="flex-column ">
                <div className="d-flex align-items-center">
                    <text className="text-bold">{billing.fullName}</text>
                    <Button
                        iconName="edit"
                        variant="trans"
                        onClick={() => setOpenEdit(true)}
                    />
                    <Button
                        iconName="delete"
                        variant="trans"
                        onClick={onClickDelete}
                    />
                </div>
                <text className="mt-1">{billing.phone}</text>
                <text className="mt-1">{billing.fullAddress}</text>
            </div>
            <Checkbox
                variant="radio"
                checked={isChecked}
                onChange={onChangeCheck}
            />
            {openEdit && (
                <CustomerCrudBillingModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    billingDefault={billing}
                    onChange={onUpdateAddress}
                />
            )}
        </div>
    );
};

const CustomerSelectBillingModal = ({
    open,
    onClose,
    onSave,
    customerId,
}: any) => {
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [billingSelect, setBillingSelect] = useState<IBillingAddress>();
    const [billingList, setBillingList] = useState<IBillingAddress[]>([]);

    useEffect(() => {
        loadBillingList();
    }, [customerId]);

    const loadBillingList = () => {
        if (!customerId) return;
        CustomerAPI.billingAddresses(customerId).then(setBillingList);
    };

    const onAddBillingAddress = (billing: any) => {
        Progress.show(
            {
                method: CustomerAPI.createBillingAddress,
                params: [customerId, mapAddressToSer(billing)],
            },
            () => {
                Notifications.showSuccess(
                    Messages.createCustomerAddressSuccess
                );
                loadBillingList();
            }
        );
    };

    const renderAddressItem = (billing: IBillingAddress) => (
        <SelectBillingItem
            billing={billing}
            isChecked={billing.id === billingSelect?.id}
            onChangeCheck={() => setBillingSelect(billing)}
            onUpdateSuccess={loadBillingList}
            customerId={customerId}
        />
    );

    const renderHeaderSide = () => (
        <Button variant="trans" onClick={() => setOpenAdd(true)}>
            {Messages.addNew}
        </Button>
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.billingAddress}
            size="medium"
            onSave={() => {
                onSave(billingSelect);
                onClose();
            }}
            headerSide={renderHeaderSide}
        >
            {map(billingList, renderAddressItem)}
            {openAdd && (
                <CustomerCrudBillingModal
                    hideSaveAsNew
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    onChange={onAddBillingAddress}
                />
            )}
        </Modal>
    );
};

export default CustomerSelectBillingModal;
