import { Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { CustomerInfoSchema } from "../../../formschema/customer";
import { ICustomer, mapCustomerToServer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import CustomerPersonalInfoForm from "../common/CustomerPersonalInfoForm";

interface ICustomerCreateModal {
    open: boolean;
    onClose: () => void;
    onSuccess: (value: any) => void;
}

const CustomerCreateModal = ({
    open,
    onClose,
    onSuccess,
}: ICustomerCreateModal) => {
    const customerForm = useFormik<ICustomer>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerInfoSchema,
        onSubmit: (values) => {
            onCreateCustomer(values);
        },
    });

    const onCreateCustomer = (values: any) => {
        const { customer } = mapCustomerToServer(values);
        Progress.show(
            {
                method: CustomerAPI.create as any,
                params: [customer, [], [], {}],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.createCustomerSuccessfully);
                onClose();
                const customerDetail = res?.data?.createCustomer;
                onSuccess(customerDetail);
            }
        );
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            size="medium"
            onSave={() => customerForm.handleSubmit()}
            title={Messages.createCustomer}
        >
            <CustomerPersonalInfoForm customerForm={customerForm} />
        </Modal>
    );
};

export default CustomerCreateModal;
