import { isEmpty, map, some } from "lodash";

export const isFilteredBody = (filterBody: any) => {
    if (!filterBody) return false;
    const isFiltered = some(
        Object.keys(filterBody),
        (key) => !isEmpty(filterBody[key])
    );

    return isFiltered;
};

export const getArrayIntegerFromLength = (length: number) => {
    const array = [];
    for (let index = 0; index < length + 1; index += 1) {
        array.push(index);
    }
    return array;
};

export const getSourceSelectIntegerFromLength = (length: number) => {
    const array = getArrayIntegerFromLength(length);
    return map(array, (item) => ({ id: item, label: item }));
};
