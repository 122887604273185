/* eslint-disable react/no-unused-state */
import { Loading } from "d-react-components";
import React, { Component } from "react";
import MediaAPI from "../../api/media/MediaAPI";
import { MediaContext } from "../../context/media";
import MediaKeysManager from "../../localstorage/media";
import MediaManagerHeader from "./MediaManagerHeader";
import MediaManagerMediaList from "./MediaManagerMediaList";
import MediaManagerTreeFolder from "./MediaManagerTreeFolder";

class MediaManager extends Component<any, any> {
    constructor(props: any) {
        super(props);
        const folderIdParams = props?.match?.params?.folderId;
        this.state = {
            //params state
            folderId: folderIdParams
                ? decodeURIComponent(folderIdParams)
                : MediaKeysManager.getSelectedFolder(),

            mediaId: props?.match?.params?.mediaId,

            isSelectMode: props.isSelect,
            onClickAddSelected: props.onSelect,
            isSingleSelect: props.isSingleSelect,
            onClickBack: props.onClickBack,

            loadingMedia: true,
            folders: [],
            loadMediaFolder: this.loadMediaFolder,

            onChangeState: this.onChangeState,
            onReloadMediaList: this.onReloadMediaList,
        };
    }

    componentDidMount() {
        this.loadMediaFolder();
    }

    onChangeState = (newState: any) => {
        this.setState((state: any) => ({ ...state, ...newState }));
    };

    loadMediaFolder = async () => {
        const folders = await MediaAPI.getDirAll();

        this.setState({
            loadingMedia: false,
            folders,
        });
    };

    /**
     * to force to reload media list => set folderId to null then back to old value
     */
    onReloadMediaList = () => {
        this.setState({ folderId: null }, () => {
            const folderIdParams = this.props?.match?.params?.folderId;

            folderIdParams &&
                this.setState({
                    folderId: decodeURIComponent(folderIdParams),
                });
        });
    };

    renderMediaContent = () => {
        const { loadingMedia, folderId } = this.state;

        if (loadingMedia) {
            return (
                <div className="flex-center p-3">
                    <Loading />
                </div>
            );
        }
        return (
            <div className="card-container mt-3 d-flex">
                <div className="col-3 p-3 border-right">
                    <MediaManagerTreeFolder />
                </div>
                {folderId && (
                    <div className="col-9">
                        <MediaManagerMediaList />
                    </div>
                )}
            </div>
        );
    };

    render() {
        return (
            <MediaContext.Provider value={this.state}>
                <MediaManagerHeader />
                {this.renderMediaContent()}
            </MediaContext.Provider>
        );
    }
}

export default MediaManager;
