export interface IPaymentMethodInfo {
    title: string;
    description: string;
}

export interface IPaymentMethodBankInfo {
    bankName: string;
    accountName: string;
}

export interface IPaymentMethodBank {
    id: string;
    image: string;
    en: IPaymentMethodBankInfo;
    th: IPaymentMethodBankInfo;
    accountNo: string;
}

export interface IPaymentMethod {
    id: string;
    type: string;
    priority: number;
    status: boolean;
    minimum: number;
    maximum: number;
    createdAt: string;
    th: IPaymentMethodInfo;
    en: IPaymentMethodInfo;
    bankAccounts: IPaymentMethodBank[];
    updatedAt: string;
}

export const mapPaymentMethodToServer = (paymentMethod: any) => {
    const {
        status,
        maximum,
        minimum,
        priority,
        en,
        th,
        type,
        bankAccounts,
        merchantID,
        secretKey,
        credentialMode,
    } = paymentMethod;

    return {
        status: !!status,
        maximum,
        minimum,
        priority,
        en,
        th,
        type,
        bankAccounts,
        merchantID,
        secretKey,
        credentialMode,
    };
};
