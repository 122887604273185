import { InputText } from "d-react-components";
import React, { useContext } from "react";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const BookingCreateNote = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const { notes } = bookingForm.values;
    return (
        <div className="card-container p-4 mt-3">
            <InputText
                multiple
                label={Messages.bookingNotesInternalUseOnly}
                rows={7}
                placeholder={Messages.enterNote}
                value={notes}
                name="notes"
                onChange={bookingForm.handleChange}
            />
        </div>
    );
};

export default BookingCreateNote;
