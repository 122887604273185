import {
    Button,
    InputText,
    Modal,
    StringUtils,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Messages from "../../languages/Messages";
import SelectMediaManagerButton from "../../screen/media/selectmedia/SelectMediaManagerButton";

const AddVideoSchema = Yup.object().shape({
    videoUrl: Yup.string().url().required("Required"),
    thumbnail: Yup.string().required("Required"),
});

const INIT_VIDEO_DATA = {
    videoUrl: "",
    thumbnail: "",
};

export const ModalAddVideoLink = ({
    open,
    onClose,
    onSaveVideoLink,
    videoData = INIT_VIDEO_DATA,
}: any) => {
    const formik = useFormik({
        initialValues: videoData,
        validationSchema: AddVideoSchema,
        onSubmit: onSaveVideoLink,
    });

    const renderThumbnail = () => {
        if (!formik.values.thumbnail) return <div />;

        return (
            <div>
                <img
                    src={formik.values.thumbnail}
                    className="image-square-x-large"
                />
            </div>
        );
    };
    return (
        <Modal
            open={open}
            title={Messages.addVideo}
            onClose={onClose}
            size="small"
            onSave={formik.submitForm}
            destroyOnClose
        >
            <form onSubmit={formik.handleSubmit}>
                <InputText
                    required
                    label={Messages.addVideoURL}
                    name="videoUrl"
                    onChange={(event) => {
                        const embedUrl = StringUtils.mapWatchToEmbedYouTube(
                            event.target.value
                        );
                        formik.setFieldValue("videoUrl", embedUrl);
                    }}
                    value={formik.values.videoUrl}
                    error={formik.errors.videoUrl as any}
                />

                {formik.values.videoUrl && !formik.errors.videoUrl && (
                    <div className="py-3 flex-center">
                        <iframe
                            className="w-100"
                            src={formik.values.videoUrl}
                            frameBorder="0"
                            allowFullScreen
                            title="item"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            scrolling="no"
                            marginHeight={0}
                            marginWidth={0}
                            width="420"
                            height="315"
                        />
                    </div>
                )}

                <div className="mt-3 d-flex flex-column">
                    <label>{Messages.videoThumbnail}</label>
                    {renderThumbnail()}
                    <SelectMediaManagerButton
                        isSingleSelect
                        onSelect={([media]: any) =>
                            formik.setFieldValue("thumbnail", media.url)
                        }
                        renderButton={() => (
                            <Button className="mt-3">
                                {Messages.thumbnail}
                            </Button>
                        )}
                    />
                    <ViewTextError error={formik.errors.thumbnail} />
                </div>
            </form>
        </Modal>
    );
};
const AddVideoLinkButton = ({ onSaveVideoLink }: any) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Button onClick={() => setOpen(true)} content={Messages.addVideo} />
            {open && (
                <ModalAddVideoLink
                    open={open}
                    onClose={() => setOpen(false)}
                    onSaveVideoLink={(videoData: any) => {
                        setOpen(false);
                        onSaveVideoLink(videoData);
                    }}
                />
            )}
        </div>
    );
};

export default AddVideoLinkButton;
