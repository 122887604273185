import classNames from "classnames";
import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { forEach } from "lodash";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import { WORKSHEET_CREATE_STEPS } from "../../../constant/worksheet";
import { WorksheetCreateContext } from "../../../context/worksheet";
import { WorksheetSchema } from "../../../formschema/worksheet";
import { IBooking } from "../../../interfaces/booking";
import { mapWorksheetToServer } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import WSCustomerInformation from "../common/WSCustomerInformation";
import WorksheetCreateBooking from "./booking/WorksheetCreateBooking";
import WorksheetCreateDefection from "./defection/WorksheetCreateDefection";
import WorksheetCreatePriorChecking from "./priorchecking/WorksheetCreatePriorChecking";
import WorksheetCreateStepControl from "./WorksheetCreateStepControl";
import WorksheetCreateSteps from "./WorksheetCreateSteps";

const WorksheetCreate = () => {
    const history = useHistory();
    const [step, setStep] = useState<number>(0);
    const [booking, setBooking] = useState<IBooking>();

    const worksheetForm = useFormik<any>({
        initialValues: {
            products: [],
            criteria: [],
            defectionVisualList: [],
            defectionConfirm: {},
            confirmation: {},
            PIC: [],
            totalUseQuantity: 0,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: WorksheetSchema,
        onSubmit: (values) => {
            const input = mapWorksheetToServer(values);
            onCreate(input);
        },
    });

    useEffect(() => {
        forEach(Object.keys(worksheetForm.errors), (key) =>
            Notifications.showError(JSON.stringify(worksheetForm.errors[key]))
        );
    }, [worksheetForm.errors]);

    const onClickCreate = async () => {
        try {
            await WorksheetSchema.validate(worksheetForm.values);
            worksheetForm.handleSubmit();
        } catch (err: any) {
            Notifications.showError(err.message);
        }
    };

    const onCreate = (input: any) =>
        Progress.show(
            { method: WorksheetAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createWorksheetSuccess);
                history.replace(
                    generatePath(Path.WORKSHEET_DETAIL, { worksheetId: id })
                );
            }
        );

    const renderContent = () => {
        const pageId = WORKSHEET_CREATE_STEPS[step]?.id;
        switch (pageId) {
            case 0:
                return <WorksheetCreateBooking />;
            case 1:
                return <WorksheetCreatePriorChecking />;
            case 2:
                return <WorksheetCreateDefection />;
            // case 3:
            //     return <WorksheetCreateProgress />;
            // case 4:
            //     return <WorksheetCreateVouchers />;
            // case 5:
            //     return <WorksheetCreateCustomerConfirm />;
            default:
                return <WorksheetCreateBooking />;
        }
    };

    const classNameLeft = classNames("pb-5 mb-5", {
        "col-8": !!booking,
        "col-12": !booking,
    });

    return (
        <WorksheetCreateContext.Provider
            value={{
                worksheetForm,
                step,
                booking,
                setStep,
                setBooking,
            }}
        >
            <Header title={Messages.worksheetCreate} onSave={onClickCreate} />
            <div className="h-100 overflow-auto pb-5">
                <div className="row p-4">
                    <div className={classNameLeft}>
                        {/* <WorksheetCreateSteps /> */}
                        {renderContent()}
                        <WorksheetCreateStepControl />
                    </div>
                    {booking && (
                        <div className="col-4  ">
                            <WSCustomerInformation
                                {...booking}
                                address={worksheetForm.values.address}
                            />
                        </div>
                    )}
                </div>
            </div>
        </WorksheetCreateContext.Provider>
    );
};

export default WorksheetCreate;
