import { useContext } from "react";
import LanguageSelectButton from "../../../common/button/LanguageSelectButton";
import { StaticContentCrudContext } from "../../../context/staticContent";

const StaticContentLanguageSelect = () => {
    const { contentLang, setContentLang } = useContext(
        StaticContentCrudContext
    );
    return (
        <LanguageSelectButton lang={contentLang} onChange={setContentLang} />
    );
};

export default StaticContentLanguageSelect;
