import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import React, { useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import VerticalTabView from "../../../common/view/VerticalTabView";
import { CustomerCreateContext } from "../../../context/customer";
import { CustomerSchema } from "../../../formschema/customer";
import { ICustomer, mapCustomerToServer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerCreateAddress from "./CustomerCreateAddress";
import CustomerCreateBilling from "./CustomerCreateBilling";
import CustomerCreatePassword from "./CustomerCreatePassword";
import CustomerCreatePersonInfo from "./CustomerCreatePersonInfo";

const TABS = [
    {
        id: "general",
        label: Messages.personalInfo,
        component: <CustomerCreatePersonInfo />,
    },

    {
        id: "serviceAddress",
        label: Messages.serviceAddress,
        component: <CustomerCreateAddress />,
    },
    {
        id: "billingInformation",
        label: Messages.billingInformation,
        component: <CustomerCreateBilling />,
    },
    {
        id: "password",
        label: Messages.password,
        component: <CustomerCreatePassword />,
    },
];

const CustomerCreate = () => {
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<any>(TABS[0]);
    const customerForm = useFormik<ICustomer>({
        initialValues: {
            address: [],
            billingAddress: [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values: any) => {
            const error: any = {};

            if (values.password !== values.confirmPassword) {
                error.confirmPassword = Messages.passwordNotMatch;
            }

            return error;
        },
        validationSchema: CustomerSchema,
        onSubmit: (values) => {
            onCreateCustomer(values);
        },
    });

    const onCreateCustomer = (values: any) => {
        const { customer, address, billingAddress, password } =
            mapCustomerToServer(values);
        Progress.show(
            {
                method: CustomerAPI.create as any,
                params: [customer, address, billingAddress, password],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.createCustomerSuccessfully);
                history.replace(
                    generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: res?.data?.createCustomer.id,
                    })
                );
            }
        );
    };

    const contextValue = useMemo(() => ({ customerForm }), [customerForm]);

    return (
        <CustomerCreateContext.Provider value={contextValue}>
            <Header
                title={Messages.customerCreate}
                onSave={() => {
                    customerForm.handleSubmit();
                }}
            />
            <div className="h-100 overflow-auto">
                <div className="row p-4">
                    <div className="col">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-9  ml-2">
                        {
                            find(TABS, (item) => selectedTab.id === item.id)
                                ?.component
                        }
                    </form>
                </div>
            </div>
        </CustomerCreateContext.Provider>
    );
};

export default CustomerCreate;
