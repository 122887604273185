import { Avatar, AvatarProps } from "d-react-components";
import React from "react";
import classNames from "classnames";
import { IUser } from "../../../interfaces/user";

interface IUserAvatarName {
    user: IUser;
    placeholder?: any;
    size?: AvatarProps["size"];
    className?: any;
}

const UserAvatarName = ({
    user,
    placeholder = "N/A",
    size = "small",
    className,
}: IUserAvatarName) => {
    if (!user || !user?.id) return placeholder;
    return (
        <div className={classNames("flex-center-y", className)}>
            <Avatar src={user?.avatar} size={size} />
            <div className="ml-2">{`${user?.nickName}_${user.companyId}`}</div>
        </div>
    );
};

export default UserAvatarName;
