import React, { useContext } from "react";
import { OrderDetailContext } from "../../../context/order";
import { getWarehouseInfoText } from "../../../interfaces/warehouse";
import Messages from "../../../languages/Messages";

const OrderDetailWarehouse = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { warehouse } = orderDetail;
    return (
        <div className="card-container p-4">
            <label>{Messages.warehouse}</label>
            <div>{getWarehouseInfoText(warehouse)}</div>
        </div>
    );
};

export default OrderDetailWarehouse;
