import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { IBookingSource } from "../../../interfaces/booking";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";

export interface IBookingSourceSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const BookingSourceSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IBookingSourceSelect<IBookingSource>) => {
    const [sourceList, setSourceList] = useState<IBookingSource[]>(
        isArray(value) ? value : [value]
    );

    useEffect(() => {
        BookingAPI.sourceBookings().then(setSourceList);
    }, []);

    const onChangeValue = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    ObjectUtils.findItemFromId(sourceList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(sourceList, values));
    };

    const sourceBookingValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id ?? null;
    }, [value]);

    return (
        <Select
            label={Messages.sourceOfBooking}
            dataSource={sourceList}
            getLabel={(item) => item.label}
            onChange={onChangeValue}
            showSearch
            className={className}
            multiple={multiple}
            value={sourceBookingValue}
            // filterOption={false}
            {...selectProps}
        />
    );
};

export default BookingSourceSelect;
