import * as Yup from "yup";
import { REFUND_FORMAT } from "../constant/refundRequest";

export const RefundRequestCreateSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        attachments: Yup.array()
            .required("Attachments required!")
            .min(1, "Attachments required!"),
        refundType: Yup.string().required("Type required!"),
        refundFormat: Yup.string().required("Type required!"),
        remark: Yup.string().required("Remark required!"),
        products: Yup.array().of(
            Yup.object().shape({
                id: Yup.string().required("Required field!"),
                quantity: Yup.number().required("Required field!"),
            }) as any
        ),
    });

    if (value.refundFormat === REFUND_FORMAT.CASH) {
        return schema.concat(
            Yup.object().shape({
                paymentMethodId: Yup.string().required(
                    "Payment method required!"
                ),
                amount: Yup.number().required("Amount required!"),
            }) as any
        );
    }

    return schema;
});

export const RefundBankSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name required!"),
    accountNo: Yup.string().required("Account no required!"),
    accountName: Yup.string().required("Account name required!"),
});

export const RefundApprovalSchema = Yup.object().shape({
    remark: Yup.string().required("Bank name required!"),
});

export const RefundCompleteSchema = Yup.object().shape({
    remark: Yup.string().required("Bank name required!"),
    attachments: Yup.array()
        .required("Attachments required!")
        .min(1, "Attachments required!"),
});
