import React from "react";

interface IInventoryImportState {
    importForm: any;
    onStepNext: () => any;
    onStepPre: () => any;
}

const inventoryImportState: IInventoryImportState = {
    importForm: null,
    onStepNext: () => {},
    onStepPre: () => {},
};

export const InventoryImportContext = React.createContext(inventoryImportState);
