/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Button, StringUtils } from "d-react-components";
import { filter } from "lodash";
import React, { useContext } from "react";
import { OrderCreateContext } from "../../../../context/order";
import { IVoucher } from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";

export interface IVoucherAppliedRowProps {
    voucher: IVoucher & { amount: number };
}

const AnonymousVoucherAppliedRow: React.FC<IVoucherAppliedRowProps> = ({
    voucher,
}) => {
    const { orderForm } = useContext(OrderCreateContext);

    const { anonymousVouchers } = orderForm?.values ?? {};
    const { id, amount, code } = voucher || {};

    return (
        <React.Fragment>
            <div className="d-flex flex-row justify-content-between border-bottom py-2">
                <div>
                    <div className="d-flex flex-row align-items-center hover-pointer">
                        <div>{Messages.voucher}</div>
                        {/* <div className="bg-primary rounded-1 px-2 ml-2 text-white text-x-small">
                            {quantity}
                        </div> */}
                    </div>
                    <div className="text-primary">{`(${code})`}</div>

                    <Button
                        content={Messages.remove}
                        color="secondary"
                        variant="trans"
                        size="x-small"
                        className="pl-0"
                        onClick={() => {
                            const removedVoucher = filter(
                                anonymousVouchers,
                                (i) => i?.id !== id
                            );
                            orderForm.setFieldValue(
                                "anonymousVouchers",
                                removedVoucher
                            );
                        }}
                    />
                </div>
                <div className="text-success">
                    {`-${StringUtils.moneyThaiFormatFixed2(`${amount}`)}`}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AnonymousVoucherAppliedRow;
