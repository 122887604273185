import {
    AwesomeTableComponent,
    Button,
    InputText,
    ObjectUtils,
    ViewCollapse,
} from "d-react-components";
import React, { useContext } from "react";
import Image from "../../../common/Image";
import UploadField from "../../../common/upload/UploadField";
import { BANK_TRANSFER } from "../../../constant/payment";
import { PaymentMethodCrudContext } from "../../../context/paymentmethod";
import Messages from "../../../languages/Messages";
import PaymentMethodLangButton from "./PaymentMethodLangButton";

const PaymentMethodCrudBankAccount = () => {
    const { paymentMethodForm, methodLang } = useContext(
        PaymentMethodCrudContext
    );
    const { bankAccounts, type } = paymentMethodForm?.values;

    if (type !== BANK_TRANSFER) {
        return <div />;
    }

    const onMove = (index: any, newIndex: number) => {
        const bankAccountsResult = ObjectUtils.arrayMove(
            bankAccounts,
            index,
            newIndex
        );
        paymentMethodForm.setFieldValue("bankAccounts", [
            ...bankAccountsResult,
        ]);
    };

    const onDelete = (bank: any) => {
        const bankAccountsResult = ObjectUtils.removeArrayById(
            paymentMethodForm.values.bankAccounts ?? [],
            bank?.id
        );
        paymentMethodForm.setFieldValue("bankAccounts", bankAccountsResult);
    };

    const onUpdateBankInfo = (bank: any, key: string, value: string) => {
        onUpdate({
            ...bank,
            [methodLang]: {
                ...bank[methodLang],
                [key]: value,
            },
        });
    };

    const onUpdate = (newBank: any) => {
        const bankAccountsResult = ObjectUtils.updateArrayById(
            bankAccounts,
            newBank
        );
        paymentMethodForm.setFieldValue("bankAccounts", [
            ...bankAccountsResult,
        ]);
    };

    const columns = [
        {
            title: "",
            dataIndex: "",
            render: (item: any, itemVal: any, index: number) => (
                <div className="flex-column col-2">
                    <Button
                        iconName="keyboard_arrow_up"
                        variant="trans"
                        onClick={() => onMove(index, index - 1)}
                    />
                    <Button
                        iconName="keyboard_arrow_down"
                        variant="trans"
                        onClick={() => onMove(index, index + 1)}
                    />
                </div>
            ),
            width: 200,
        },
        {
            title: Messages.image,
            dataIndex: "image",
            render: (image: any, bank: any) => (
                <UploadField
                    onChange={(value: any) =>
                        onUpdate({ ...bank, image: value?.url })
                    }
                >
                    <Image className="image-square-small" src={image} />
                </UploadField>
            ),
        },
        {
            title: Messages.bankName,
            dataIndex: methodLang,
            render: (info: any, bank: any) => (
                <InputText
                    value={info?.bankName ?? ""}
                    onChange={(event) =>
                        onUpdateBankInfo(bank, "bankName", event.target.value)
                    }
                />
            ),
        },
        {
            title: Messages.accountName,
            dataIndex: methodLang,
            render: (info: any, bank: any) => (
                <InputText
                    value={info?.accountName ?? ""}
                    onChange={(event) =>
                        onUpdateBankInfo(
                            bank,
                            "accountName",
                            event.target.value
                        )
                    }
                />
            ),
        },
        {
            title: Messages.accountNo,
            dataIndex: "accountNo",
            render: (accountNo: string, bank: any) => (
                <InputText
                    value={accountNo ?? ""}
                    onChange={(event) =>
                        onUpdate({ ...bank, accountNo: event?.target.value })
                    }
                />
            ),
        },
        {
            title: "",
            dataIndex: "",
            render: (item: any) => (
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={() => onDelete(item)}
                />
            ),
        },
    ];

    return (
        <div>
            <PaymentMethodLangButton />
            <ViewCollapse
                label={Messages.bankAccount}
                className="card-container mt-3"
                required
            >
                <AwesomeTableComponent
                    columns={columns}
                    className="mt-3"
                    dataSource={bankAccounts ?? []}
                    isPagination={false}
                    bordered={false}
                    baseColumnProps={{ width: 200 }}
                    rowKey={(item) => item.id}
                />
            </ViewCollapse>
        </div>
    );
};

export default PaymentMethodCrudBankAccount;
