import { Icon, Modal } from "d-react-components";
import { useFormik } from "formik";
import { every } from "lodash";
import { useState } from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    CUSTOMER_CHECKS,
    WORKSHEET_CRITERIA_CHECK_STATUS,
} from "../../../constant/worksheet";
import Messages from "../../../languages/Messages";
import CriteriaCheckTable from "./CriteriaCheckTable";
import SignatureField from "./SignatureField";

interface IWSCustomerConfirm {
    criteriasCheck: any;
    criteriaDataList: any[];
    confirmation: any;
    // eslint-disable-next-line no-unused-vars
    onUpdateRemark?: (values: any) => any;
    onCheckCustomer?: (values: any) => any;
    onCustomerSign?: (values: any) => any;
    onStaffSign?: (values: any) => any;
    onCheckAllCustomerConfirm?: (values: any) => any;
}

interface ConfirmationEditRemark {
    open: boolean;
    onClose: () => void;
    onSave: any;
}

const ConfirmationEditRemarkModal = ({
    open,
    onClose,
    onSave,
}: ConfirmationEditRemark) => {
    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onSave(values);
            onClose();
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => confirmForm.handleSubmit()}
        >
            <div>
                <InputTextForm
                    form={confirmForm}
                    keyData="remark"
                    className="mt-3"
                    multiple
                />
            </div>
        </Modal>
    );
};

const WSCustomerConfirm = ({
    criteriasCheck,
    criteriaDataList = [],
    confirmation = {},
    onUpdateRemark,
    onCheckCustomer,
    onCheckAllCustomerConfirm,
    onStaffSign,
    onCustomerSign,
}: IWSCustomerConfirm) => {
    const [openEditRemark, setOpenEditRemark] = useState(false);

    const isCompletedCustomerCheck = every(
        criteriasCheck,
        (criteriaItem) =>
            criteriaItem?.status ===
                WORKSHEET_CRITERIA_CHECK_STATUS.CUSTOMER_CONFIRMED ||
            criteriaItem?.status === WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED
    );

    return (
        <div className="">
            <label>{Messages.customerConfirmation}</label>
            <CriteriaCheckTable
                criteriasCheck={criteriasCheck}
                criteriaDataList={criteriaDataList}
                checkDataList={CUSTOMER_CHECKS}
                checkDataKey="customerCheck"
                // onChangeCheck={onCheckCustomer}
                onCheckAll={onCheckAllCustomerConfirm}
                hiddenCancel
            />
            <div className="mt-3">{Messages.bySigningThisBothCustomer}</div>
            <div className="bg-muted p-3 mt-3 flex-column">
                <div className="flex-row-between-center">
                    <label>{Messages.remark}</label>
                    <Icon
                        name="edit"
                        size="small"
                        onClick={() => setOpenEditRemark(true)}
                        className="cursor-pointer"
                    />
                </div>

                <text>{confirmation?.remark}</text>
            </div>

            <div className="mt-3 d-flex justify-content-between">
                <SignatureField
                    name={Messages.staff}
                    date={confirmation?.staffSignDate}
                    signature={confirmation?.staffSignature}
                    onChangeSign={(staffSignature) =>
                        onStaffSign && onStaffSign(staffSignature)
                    }
                    disabled={!isCompletedCustomerCheck}
                />
                <SignatureField
                    name={Messages.customer}
                    date={confirmation?.customerSignDate}
                    signature={confirmation?.customerSignature}
                    onChangeSign={(customerSignature) =>
                        onCustomerSign && onCustomerSign(customerSignature)
                    }
                    disabled={
                        !isCompletedCustomerCheck ||
                        !confirmation?.staffSignature
                    }
                />
            </div>
            <ConfirmationEditRemarkModal
                onClose={() => setOpenEditRemark(false)}
                open={openEditRemark}
                onSave={onUpdateRemark}
            />
        </div>
    );
};

export default WSCustomerConfirm;
