import { gql } from "@apollo/client";
import API from "../API";

export default {
    provinceList: () => {
        return API.query({
            query: gql`
                query provinces {
                    provinces {
                        id
                        name
                    }
                }
            `,
        });
    },

    districtList: (provinceId: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.districts ?? [],
        }).query({
            query: gql`
                query districts($provinceId: ID!) {
                    districts(provinceId: $provinceId) {
                        id
                        name
                    }
                }
            `,
            variables: {
                provinceId,
            },
        }),
    subDistrictList: (districtId: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.subDistricts ?? [],
        }).query({
            query: gql`
                query subDistricts($districtId: ID!) {
                    subDistricts(districtId: $districtId) {
                        id
                        name
                        postcode
                    }
                }
            `,
            variables: {
                districtId,
            },
        }),
};
