import React from "react";
import { IUserRole } from "../interfaces/user";

interface IUserCrudState {
    userForm: any;
    userUpdatePassForm: any;
    roleList: IUserRole[];
}

const userCrudState: IUserCrudState = {
    userForm: null,
    userUpdatePassForm: null,
    roleList: [],
};

export const UserCrudContext = React.createContext(userCrudState);

interface IUserRoleCrudState {
    roleForm: any;
}

const userRoleCrudState: IUserRoleCrudState = {
    roleForm: null,
};

export const UserRoleCrudContext = React.createContext(userRoleCrudState);
