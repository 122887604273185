import { TimeUtils, ViewLabelStatus } from "d-react-components";
import { useContext, useState } from "react";
import Carousel, { ModalGateway, Modal as ModalImage } from "react-images";
import Image from "../../../common/Image";
import ViewRowInterchange, {
    IRowsKey,
} from "../../../common/view/ViewRowInterchange";
import { WORKSHEET_STATUSES } from "../../../constant/worksheet";
import { WorksheetDetailContext } from "../../../context/worksheet";
import { IWorksheet, IWorksheetMileage } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";
import CustomerAvatarName from "../../customer/common/CustomerAvatarName";
import UserAvatarName from "../../user/common/UserAvatarName";

const MilageInfo = ({ mileage }: { mileage: IWorksheetMileage }) => {
    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });
    return (
        <div className="flex-column">
            <text>{mileage?.mile}</text>
            <Image
                src={mileage?.proves?.[0]}
                className="image-square-small"
                onClick={() => setImageToView({ isVisible: true })}
            />
            <ModalGateway>
                {imageToView.isVisible ? (
                    <ModalImage
                        onClose={() => setImageToView({ isVisible: false })}
                    >
                        <Carousel
                            views={[
                                { source: mileage?.proves?.[0], caption: "" },
                            ]}
                            currentIndex={imageToView.index}
                        />
                    </ModalImage>
                ) : null}
            </ModalGateway>
        </div>
    );
};

const GENERAL_KEYS: IRowsKey<IWorksheet>[] = [
    {
        id: "worksheetNo",
        label: Messages.worksheetNo,
    },
    {
        id: "categoryProduct",
        label: Messages.serviceCategory,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "serviceDate",
        label: Messages.serviceDate,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
    {
        id: "customer",
        label: Messages.customer,
        renderContent: ({ data }) => <CustomerAvatarName customer={data} />,
    },
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => (
            <ViewLabelStatus status={data} listStatus={WORKSHEET_STATUSES} />
        ),
    },
    {
        id: "mileageStart",
        label: Messages.startingMileage,
        renderContent: ({ data }) => <MilageInfo mileage={data} />,
        hidden: ({ data }) => !data,
    },
    {
        id: "mileageEnd",
        label: Messages.endingMileage,
        renderContent: ({ data }) => <MilageInfo mileage={data} />,
        hidden: ({ data }) => !data,
    },
    {
        id: "createBy",
        label: Messages.createdBy,
        renderContent: ({ data }) => <UserAvatarName user={data} />,
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
];

const WorksheetDetailGeneral = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );

    return (
        <div className="flex-column mt-3 card-container p-3">
            <ViewRowInterchange
                keyList={GENERAL_KEYS}
                dataSource={worksheetDetail}
            />
        </div>
    );
};

export default WorksheetDetailGeneral;
