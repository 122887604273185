import { Switch } from "antd";
import React, { useContext } from "react";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudVisibility = () => {
    const { productForm } = useContext(ProductDetailContext);
    const { visibility, customerVisibility } = productForm.values;
    return (
        <>
            <div className="card-container d-flex - justify-content-between p-3">
                <h5>{Messages.systemVisibility}</h5>
                <Switch
                    checked={visibility}
                    onChange={() => {
                        if (visibility) {
                            productForm.setFieldValue(
                                "customerVisibility",
                                false
                            );
                        }
                        productForm.setFieldValue("visibility", !visibility);
                    }}
                />
            </div>
            <div className="card-container d-flex - justify-content-between p-3 mt-3">
                <h5>{Messages.customerVisibility}</h5>
                <Switch
                    checked={customerVisibility}
                    onChange={() =>
                        productForm.setFieldValue(
                            "customerVisibility",
                            !customerVisibility
                        )
                    }
                />
            </div>
        </>
    );
};

export default ProductCrudVisibility;
