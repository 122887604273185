import { CheckboxGroup, ObjectUtils } from "d-react-components";
import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import CategoryAPI from "../../../api/category/CategoryAPI";

import Messages from "../../../languages/Messages";

interface ICategoryGroupCheckbox {
    className?: string;
    value: any[];
    multiple?: boolean;
    isObjectValue?: boolean;
    onChange: (value: any) => void;
}

const CategoryCheckboxGroup = ({
    value,
    multiple,
    className,
    isObjectValue,
    onChange,
}: ICategoryGroupCheckbox) => {
    const [serviceCategories, setServiceCategories] = useState([]);

    useEffect(() => {
        loadServiceCategory();
    }, []);

    const loadServiceCategory = () => {
        CategoryAPI.activeCategoryList().then((res) =>
            setServiceCategories(res?.data?.data?.category ?? [])
        );
    };

    const onChangeValue = (id: any) => {
        if (!isObjectValue) {
            onChange(id);
            return;
        }
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(serviceCategories, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(serviceCategories, id));
    };

    const fieldValue = useMemo(() => {
        if (!isObjectValue) {
            return value;
        }
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <CheckboxGroup
            numberOfColumns="2"
            dataSource={serviceCategories}
            label={Messages.serviceCategory}
            className={className}
            value={fieldValue}
            onChange={onChangeValue}
            getLabel={(item) => item?.name}
        />
    );
};

export default CategoryCheckboxGroup;
