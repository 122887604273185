import { gql } from "@apollo/client";
import { F_CUSTOMER } from "../customer/CustomerFragment";
import { F_PAGINATION } from "../Fragment";
import { F_PAYMENT_METHOD } from "../paymentmethod/PaymentMethodFragment";
import { F_PRODUCT } from "../product/ProductFragment";
import { F_USER } from "../user/UserFragment";

export const F_REFUND_REQUEST_ITEM = gql`
    ${F_USER}
    ${F_CUSTOMER}
    fragment F_REFUND_REQUEST_ITEM on RefundRequest {
        id
        requestNo
        status
        createdAt
        createBy {
            ...F_USER
        }
        amount
        customer {
            ...F_CUSTOMER
        }
    }
`;

export const F_REFUND_REQUESTS = gql`
    ${F_REFUND_REQUEST_ITEM}
    ${F_PAGINATION}
    fragment F_REFUND_REQUESTS on RefundRequests {
        refundRequest {
            ...F_REFUND_REQUEST_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_REFUND_REQUEST_PROOF = gql`
    ${F_USER}
    fragment F_REFUND_REQUEST_PROOF on RefundRequestProof {
        remark
        attachments
        createdAt
        createBy {
            ...F_USER
        }
    }
`;

export const F_REFUND_REQUEST = gql`
    ${F_REFUND_REQUEST_PROOF}
    ${F_PAYMENT_METHOD}
    ${F_CUSTOMER}
    ${F_USER}
    ${F_PRODUCT}
    fragment F_REFUND_REQUEST on RefundRequest {
        id
        requestNo
        products {
            id
            quantity
            product {
                ...F_PRODUCT
            }
        }
        refundType
        refundFormat
        paymentMethod {
            ...F_PAYMENT_METHOD
        }
        amount
        remark
        attachments
        status
        banks {
            bankName
            accountName
            accountNo
        }
        confirmRefund {
            ...F_REFUND_REQUEST_PROOF
        }
        rejectRefund {
            ...F_REFUND_REQUEST_PROOF
        }
        completeRefund {
            ...F_REFUND_REQUEST_PROOF
        }
        createBy {
            ...F_USER
        }
        createdAt
        customer {
            ...F_CUSTOMER
        }
        order {
            id
            orderNo
        }
        worksheet {
            id
            worksheetNo
        }
    }
`;

export const F_REFUND_ORDER = gql`
    fragment F_REFUND_ORDER on Order {
        id
        orderNo
        subTotal
        total
        status
        requestedRefundAmount
        totalPaidAmount
        products {
            id
            productId
            name
            SKU
            thumbnail
            regularPrice
            salePrice
            quantity
            productType
            isCustom
        }
    }
`;

export const F_REFUND_WORKSHEET = gql`
    fragment F_REFUND_WORKSHEET on Worksheet {
        id
        worksheetNo

        status
        openingCost
        quantity

        requestedRefundAmount

        vouchers {
            id
            code
            product {
                id
            }
        }

        services {
            product {
                id
                th {
                    name
                    description
                    shortDescription
                }
                en {
                    name
                    description
                    shortDescription
                }
                productId
                SKU
                service {
                    id
                    name
                }
                thumbnail {
                    thumbnail
                }
                productType
            }
            openingCost
            quantity
            availableOrderQty
        }
        serviceApply {
            voucherCode
            productServiceId
        }
    }
`;
