import { map } from "lodash";

export interface ICriteriaValue {
    id: string;
    value: string;
}
export interface ICriteria {
    id: string;
    name: string;
    type: string;
    values: ICriteriaValue[];
    valuesQty: number;
    createdAt: string;
    updatedAt: string;
}

export const mapCriteriaToServer = (criteria: any) => {
    const { values, name, type, status } = criteria;
    return {
        name,
        type,
        status,
        values: map(values, (item) => {
            if (item.isAddNew) {
                return {
                    value: item.value,
                };
            }
            return {
                value: item.value,
                id: item.id,
            };
        }),
    };
};
