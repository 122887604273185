import { find, forEach, includes, split } from "lodash";
import {
    PERMISSION_MODULES,
    ROLE_ACCESS_TYPES,
    PERMISSION_TYPE,
} from "../constant/user";

export enum IUserStatus {
    ACTIVE,
    INACTIVE,
}

export interface IUserRole {
    id: string;
    name: string;
    permissions: any[];
    permissionType: string;
}

export interface IUserAuthOtp {
    code: string;
    expiryAt: string;
}

export interface IUser {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    username: string;
    nickName: string;
    companyId: string;
    avatar: string;
    createdAt: string;
    lastLogin: string;
    updatedAt: string;
    email: string;
    isOtpAuth?: boolean;
    otpAuthSecretCode?: string;
    otpAuthUrl?: string;
    status: IUserStatus;
}

export const getDefaultRolePermissions = () => {
    const values: string[] = [];
    forEach(PERMISSION_MODULES, (access, index) => {
        values.push(`0-${index}`);
        const accessTypeList = access.accessTypes ?? ROLE_ACCESS_TYPES;
        forEach(accessTypeList, (spec, indexSpec) => {
            values.push(`0-${index}-${indexSpec}`);
        });
    });
    return values;
};

export const mapUserRolePermissionToSever = (permissions: any[]) => {
    const values: any[] = [];
    forEach(PERMISSION_MODULES, (access, index) => {
        const accessValue = {};
        const accessTypeList = access.accessTypes ?? ROLE_ACCESS_TYPES;
        forEach(accessTypeList, (spec, indexSpec) => {
            const value = `0-${index}-${indexSpec}`;
            const isGrantPermission = includes(permissions, value);
            (accessValue as any)[spec?.id] = isGrantPermission;
        });
        values.push({ key: access.key, access: accessValue });
    });
    return values;
};

export const mapUserRolePermissionFromSever = (permissions: any[]) => {
    const values: string[] = [];

    forEach(PERMISSION_MODULES, (access, index) => {
        const accessTypeList = access.accessTypes ?? ROLE_ACCESS_TYPES;
        forEach(accessTypeList, (spec, indexSpec) => {
            const permission = find(
                permissions,
                (item) => item.key === access?.key
            );
            if (permission && permission?.access?.[spec?.id] === true) {
                values.push(`0-${index}-${indexSpec}`);
            }
        });
    });

    return values;
};

export const mapUserRoleToSever = (data: any) => {
    const { name, permissions, permissionType } = data;
    const result: any = {
        name,
        permissionType,
    };
    if (permissionType === PERMISSION_TYPE.CUSTOM) {
        result.permissions = mapUserRolePermissionToSever(permissions);
    }
    return result;
};

export const mapUserFromSer = (dataServer: any) => {
    return {
        ...dataServer,
        roleId: dataServer?.role?.id,
        fullName: `${dataServer?.firstName} ${dataServer?.lastName}`,
    };
};

export const mapUserGeneralInfoToSer = (input: any) => {
    const {
        firstName,
        lastName,
        username,
        nickName,
        companyId,
        avatar,
        email,
        status,
        roleId,
        isOtpAuth,
        otpAuthSecretCode,
    } = input;
    return {
        avatar,
        firstName,
        lastName,
        username,
        nickName,
        companyId,
        email,
        status,
        roleId,
        isOtpAuth,
        otpAuthSecretCode,
    };
};

export const mapUserPasswordToSer = (input: any) => {
    const { password, confirmPassword } = input;
    return {
        password,
        confirmPassword,
    };
};

export const mapUserResetPasswordToSer = (input: any) => {
    const { password, confirmPassword } = input;
    return {
        newPassword: password,
        confirmPassword,
    };
};
