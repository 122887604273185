import { gql } from "@apollo/client";
import API from "../API";
import { F_PAGINATION } from "../Fragment";
import { F_FAQ, F_FAQ_CATEGORY } from "./FaqFragment";

const detailConvertor = {
    fromServer: (res: any) => res?.data?.data,
};

export default {
    faqCategoryList: (q?: string) => {
        return API.instance.query({
            query: gql`
                ${F_FAQ_CATEGORY}
                query faqCategoryList {
                    data: categoryFaqList {
                        ...F_FAQ_CATEGORY
                    }
                }
            `,
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        });
    },

    faqCategoryCreate: ({ payload }: any) => {
        return API.instance.mutate({
            mutation: gql`
                mutation faqCategoryCreate($data: CategoryFaqInput!) {
                    data: createCategoryFaq(input: $data) {
                        id
                    }
                }
            `,
            variables: {
                data: { ...payload },
            },
        });
    },

    faqCategoryUpdate: ({ payload, id }: any) => {
        return API.instance.mutate({
            mutation: gql`
                mutation faqCategoryUpdate(
                    $id: String!
                    $data: CategoryFaqUpdateInput!
                ) {
                    data: updateCategoryFaq(id: $id, input: $data) {
                        id
                    }
                }
            `,
            variables: {
                data: { ...payload },
                id,
            },
        });
    },

    moveFaqCategory: (id: string, parentId: string) => {
        return API.instance.mutate({
            mutation: gql`
                mutation moveFaqCategory($id: String!, $parentId: String!) {
                    data: moveFaqCategory(id: $id, parentId: $parentId) {
                        id
                    }
                }
            `,
            variables: {
                parentId,
                id,
            },
        });
    },

    manualCategoryDelete: ({ id }: any) => {
        return API.instance.mutate({
            mutation: gql`
                mutation deleteManualCategory($id: String!) {
                    data: deleteManualCategory(id: $id) {
                        status
                    }
                }
            `,
            variables: {
                id,
            },
        });
    },

    faqCategoriesUpdate: ({ payload }: any) => {
        return API.instance.mutate({
            mutation: gql`
                mutation faqCategoriesUpdate(
                    $data: [CategoriesFaqUpdateInput]
                ) {
                    data: updateCategoriesFaq(input: $data)
                }
            `,
            variables: {
                data: payload,
            },
        });
    },

    faqList: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filter: any = {}
    ) => {
        return API.instance.query({
            query: gql`
                ${F_FAQ}
                ${F_PAGINATION}
                query faqList(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: OrderFilter
                ) {
                    data: faqList(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        faq {
                            ...F_FAQ
                        }
                        pagination {
                            ...F_PAGINATION
                        }
                    }
                }
            `,
            variables: { q, page: pageIndex, limit: pageSize, filter },
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        });
    },

    faqListAll: (query: any) => {
        return API.instance.query({
            query: gql`
                ${F_FAQ}
                query faqListAll($query: FaqFilter) {
                    data: faqListAll(query: $query) {
                        ...F_FAQ
                    }
                }
            `,
            variables: {
                query,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        });
    },

    faqDetail: ({ id }: { id: string }) => {
        return API.instance.query({
            query: gql`
                ${F_FAQ}
                query faqDetail($id: ID!) {
                    faq(id: $id) {
                        ...F_FAQ
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        });
    },

    createFaq: ({ payload }: any) => {
        return API.instance.mutate({
            mutation: gql`
                ${F_FAQ}
                mutation createFaq($data: FaqInput!) {
                    data: createFaq(input: $data) {
                        ...F_FAQ
                    }
                }
            `,
            variables: {
                data: { ...payload },
            },
        });
    },

    updateFaq: ({ payload, id }: any) => {
        return API.instance.mutate({
            mutation: gql`
                ${F_FAQ}
                mutation updateFaq($id: ID!, $data: FaqInput!) {
                    data: updateFaq(id: $id, input: $data) {
                        ...F_FAQ
                    }
                }
            `,
            variables: {
                data: { ...payload },
                id,
            },
        });
    },

    updateMultipleFaq: ({ payload }: any) => {
        return API.instance.mutate({
            mutation: gql`
                mutation updateMultipleFaq($data: [FaqInput!]!) {
                    data: updateMultipleFaq(input: $data)
                }
            `,
            variables: {
                data: payload,
            },
        });
    },

    faqDelete: ({ id }: any) => {
        return API.instance.mutate({
            mutation: gql`
                mutation faqDelete($id: String!) {
                    data: deleteManualArticle(id: $id) {
                        status
                    }
                }
            `,
            variables: {
                id,
            },
        });
    },
};
