import * as Yup from "yup";

export const UserPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Required field!"),
    confirmPassword: Yup.string().required("Required field!"),
});

export const UserUpdateInfoSchema = Yup.object().shape({
    // email: Yup.string().required("Required field!"),
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    nickName: Yup.string().required("Required field!"),
    companyId: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
});

export const UserCreateSchema = UserUpdateInfoSchema.concat(
    UserPasswordSchema as any
);

export const UserRoleSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
});
