import { Progress } from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderAPI from "../../../api/order/OrderAPI";
import { AppStateContext } from "../../../context/app";
import { IOrderDetailMeta, OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import OrderDetailActivity from "./OrderDetailActivity";
import OrderDetailBillingAddress from "./OrderDetailBillingAddress";
import OrderDetailContact from "./OrderDetailContact";
import OrderDetailCreditNote from "./OrderDetailCreditNote";
import OrderDetailCustomer from "./OrderDetailCustomer";
import OrderDetailDelivery from "./OrderDetailDelivery";
import OrderDetailHeader from "./OrderDetailHeader";
import OrderDetailNote from "./OrderDetailNote";
import OrderDetailPayment from "./OrderDetailPayment";
import OrderDetailProducts from "./OrderDetailProducts";
import OrderDetailRefundRequest from "./OrderDetailRefundRequest";
import OrderDetailShippingAddress from "./OrderDetailShippingAddress";
import OrderDetailWarehouse from "./OrderDetailWarehouse";
import OrderDetailWorksheet from "./OrderDetailWorksheet";
import OrderDetailCoSales from "./OrderDetailCoSales";

const OrderDetail = () => {
    const { setSiteConfig } = useContext(AppStateContext);
    const [orderDetail, setOrderDetail] = useState<IOrder>({} as any);
    const [metaData, setMetaData] = useState<IOrderDetailMeta>({} as any);
    const { orderId } = useParams<any>();

    useEffect(() => {
        loadOrderDetail();
        loadMetaData();
    }, []);

    const loadMetaData = () => {
        OrderAPI.detailMetaData().then(setMetaData);
    };

    const loadOrderDetail = () => {
        Progress.show(
            {
                method: OrderAPI.detail,
                params: orderId,
            },
            (orderDetail: any) => {
                setOrderDetail(orderDetail);
                setSiteConfig({
                    breadcrumb: [
                        {
                            title: Messages.allOrder,
                            url: Path.ORDER_TABLE,
                        },
                        {
                            title: orderDetail?.orderNo,
                        },
                    ],
                });
            }
        );
    };

    if (!orderDetail) return <div />;
    return (
        <OrderDetailContext.Provider
            value={{
                metaData,
                orderDetail,
                setOrderDetail,
            }}
        >
            <div className="h-100 overflow-auto pb-5 p-3">
                <OrderDetailHeader />
                <div className="d-flex flex-column flex-lg-row mt-3 p-0">
                    <div className="col col-lg-8 p-0">
                        <OrderDetailProducts />
                        <OrderDetailNote />
                        <OrderDetailPayment />
                        <OrderDetailRefundRequest />
                        <OrderDetailActivity />
                    </div>
                    <div className="col col-lg-4 p-0 pl-lg-3 py-0">
                        <OrderDetailCreditNote />
                        <OrderDetailWarehouse />
                        <OrderDetailCoSales />
                        <OrderDetailDelivery />
                        <OrderDetailCustomer />
                        <OrderDetailContact />
                        <OrderDetailShippingAddress />
                        <OrderDetailBillingAddress />
                        <OrderDetailWorksheet />
                    </div>
                </div>
            </div>
        </OrderDetailContext.Provider>
    );
};

export default OrderDetail;
