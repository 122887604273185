import { AwesomeTableComponent, Button, TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { useHistory, generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const BookingDetailWorksheet = () => {
    const history = useHistory();
    const { bookingDetail } = useContext(BookingDetailContext);
    const { status } = bookingDetail;
    const columns = [
        {
            title: Messages.id,
            dataIndex: "worksheetNo",
            render: (worksheetNo: string, item: any) => (
                <AppLink
                    to={generatePath(Path.WORKSHEET_DETAIL, {
                        worksheetId: item.id,
                    })}
                >
                    {worksheetNo}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "categoryProduct",
            render: (categoryProduct: any) => categoryProduct?.name,
        },
        {
            title: Messages.status,
            dataIndex: "status",
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) => createBy?.username,
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: (createdAt: string) => TimeUtils.toDateTime(createdAt),
        },
    ];
    return (
        <div className="card-container p-4 mt-3">
            <div className="d-flex flex-center justify-content-between">
                <label>{Messages.worksheet}</label>
                <Button
                    onClick={() =>
                        history.push(
                            `${Path.WORKSHEET_CREATE}?booking=${bookingDetail.bookingNo}`
                        )
                    }
                    hidden={status === BOOKING_STATUS.CANCELLED}
                >
                    {Messages.createWorksheet}
                </Button>
            </div>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={bookingDetail?.worksheet ?? []}
                isPagination={false}
                bordered={false}
            />
        </div>
    );
};

export default BookingDetailWorksheet;
