import { useContext } from "react";
import LanguageSelectButton from "../../../common/button/LanguageSelectButton";
import { BannerCrudContext } from "../../../context/banner";

const BannerLanguageButton = () => {
    const { bannerLang, setBannerLang } = useContext(BannerCrudContext);
    return <LanguageSelectButton lang={bannerLang} onChange={setBannerLang} />;
};

export default BannerLanguageButton;
