import { Select, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudCategory = () => {
    const { productForm, categoryProducts } = useContext(ProductDetailContext);
    const { categoryId, productType } = productForm.values;

    if (productType === PRODUCT_TYPE.SERVICE_PRODUCT) {
        return <div />;
    }
    return (
        <ViewCollapse className="mt-3" label={Messages.category} required>
            <Select
                value={categoryId}
                onChange={(value) =>
                    productForm.setFieldValue("categoryId", value)
                }
                multiple
                dataSource={categoryProducts}
                error={productForm.errors.categoryId}
            />
        </ViewCollapse>
    );
};

export default ProductCrudCategory;
