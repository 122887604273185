import { Modal } from "antd";
import {
    Button,
    DialogManager,
    Icon,
    InputText,
    Loading,
    Notifications,
    TimeUtils,
} from "d-react-components";
import React, { useEffect, useState } from "react";
import MediaAPI from "../../api/media/MediaAPI";
import { IMedia, mapMediaToSer } from "../../interfaces/media";
import Messages from "../../languages/Messages";

const MediaManagerMediaDetail = ({
    open,
    onClose,
    onUpdateMedia,
    onSelect,
    onClickBack,
    mediaId,
}: any) => {
    const [mediaDetail, setMediaDetail] = useState<IMedia>();

    useEffect(() => {
        getMediaDetail();
    }, []);

    const getMediaDetail = () => {
        MediaAPI.getMedia(mediaId).then(setMediaDetail);
    };

    const onClickSave = async () => {
        const mediaInput = mapMediaToSer(mediaDetail);
        return MediaAPI.updateMedia(mediaId, mediaInput).then(() => {
            Notifications.showSuccess(Messages.updateMediaDetailSuccess);
            onUpdateMedia && onUpdateMedia();
            // onClose();
        });
    };

    const onClickSaveAndInsert = async () => {
        await onClickSave();
        onSelect && onSelect(mediaDetail);
        onClose();
    };

    const onClickDelete = () => {
        DialogManager.showConfirm(
            Messages.deleteMedia,
            Messages.areYouSureDeleteMedia,
            onDeleteMedia
        );
    };

    const onDeleteMedia = () => {
        MediaAPI.deleteMedia([mediaId]).then(() => {
            onUpdateMedia && onUpdateMedia();
            onClickBack();
            Notifications.showSuccess(Messages.deleteMediaSuccess);
        });
    };

    const renderHeader = () => {
        return (
            <div className="container-fluid p-3 d-flex justify-content-between bg-muted align-items-center">
                <div className="d-flex w-50 align-items-center">
                    <Button variant="trans" onClick={onClickBack}>
                        <Icon name="arrow_back" />
                    </Button>
                    <h5 className="font-weight-bold">{mediaDetail?.title}</h5>
                </div>
                <div className="d-flex">
                    <Button variant="outline" onClick={onClickDelete}>
                        {Messages.delete}
                    </Button>
                    {/* <button type="button" className="btn btn-primary ml-3" onClick={onClickSaveAndInsert}>
                        {Messages.saveAndInsert}
                    </button> */}
                    <Button className=" ml-3" onClick={onClickSave}>
                        {Messages.save}
                    </Button>
                </div>
            </div>
        );
    };
    const renderContent = () => {
        if (!mediaDetail) {
            return (
                <div className="d-flex justify-content-center p-3">
                    <Loading />
                </div>
            );
        }
        const {
            description,
            url,
            title,
            type,
            dimension,
            createBy,
            modificationDate,
        } = mediaDetail;
        return (
            <div className="row p-3">
                <div className="col-7 d-flex justify-content-center">
                    <img
                        src={url}
                        className="media-manager__media-detail-image"
                    />
                </div>
                <div className="col-5">
                    <InputText
                        label={Messages.title}
                        onChange={(event) =>
                            setMediaDetail({
                                ...mediaDetail,
                                title: event.target.value,
                            })
                        }
                        placeholder={Messages.title}
                        value={title}
                    />
                    <InputText
                        label={Messages.alternativeDescription}
                        onChange={(event) =>
                            setMediaDetail({
                                ...mediaDetail,
                                description: event.target.value,
                            })
                        }
                        multiple
                        placeholder={Messages.description}
                        value={description}
                        className="mt-3"
                    />
                    <div className="flex-column">
                        <label className="mt-3">{Messages.fileName}</label>
                        <text>{title}</text>
                        <label className="mt-3">{Messages.type}</label>
                        <text className="text-uppercase">{type}</text>
                        <label className="mt-3">{Messages.dimension}</label>
                        <text>{dimension}</text>
                        <label className="mt-3">
                            {Messages.modificationDate}
                        </label>
                        <text>
                            {TimeUtils.toDateTime(modificationDate)}
                        </text>
                        <label className="mt-3">{Messages.createdBy}</label>
                        <text>{createBy?.username}</text>
                        <label className="mt-3">{Messages.fileUrl}</label>
                        <text>{url}</text>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            visible={open}
            onCancel={onClose}
            destroyOnClose
            className="media-manager__modal-select-media"
            closable={false}
            footer={null}
        >
            {renderHeader()}
            {renderContent()}
        </Modal>
    );
};

export default MediaManagerMediaDetail;
