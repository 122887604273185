import * as Yup from "yup";
import { ProDistrictSchema } from "./customer";

export const WarehouseInfoSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
});
export const WarehouseSchema = Yup.object().shape({
    th: WarehouseInfoSchema,
    en: WarehouseInfoSchema,
    code: Yup.string().required("Required"),
    province: ProDistrictSchema,
    district: ProDistrictSchema,
    subDistrict: ProDistrictSchema,
    postcode: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
});
