import { map } from "lodash";
import { useContext } from "react";
import { OrderDetailContext } from "../../../context/order";
import UserAvatarName from "../../user/common/UserAvatarName";
import Messages from "../../../languages/Messages";

const OrderDetailCoSales = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { coSales } = orderDetail ?? {};

    return (
        <div className="mt-3">
            <div className="card-container p-4 ">
                <div className="label">{Messages.coSales}</div>
                <div className="flex-wrap flex-row w-100">
                    {map(coSales, (user) => (
                        <UserAvatarName
                            user={user}
                            className="mr-2 mt-2"
                            size="xx-small"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrderDetailCoSales;
