import { map } from "lodash";
import { useParams } from "react-router-dom";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import ActivityView from "../../../common/activity/ActivityView";

const VoucherDetailActivity = () => {
    const { voucherId } = useParams<any>();

    const onSendComment = (commentText: string, attachments: any = []) => {
        const attachmentList = map(attachments, (item) => item.url);
        return VoucherAPI.addComment(voucherId, commentText, attachmentList);
    };

    const source = (paging: any) => {
        return VoucherAPI.activities(voucherId, paging);
    };

    const transformer = (res: any) => res?.data?.data?.activities ?? [];

    return (
        <ActivityView
            className="mt-3"
            onClickSendComment={onSendComment}
            source={source}
            transformer={transformer}
        />
    );
};

export default VoucherDetailActivity;
