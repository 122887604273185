/* eslint-disable no-continue */
import { Button, Notifications, StringUtils } from "d-react-components";
import { every, forEach, includes, isEmpty, map, replace, split } from "lodash";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import { InventoryImportContext } from "../../../context/inventory";
import Messages from "../../../languages/Messages";
import WarehouseSelect from "../../warehouse/common/WarehouseSelect";

const splitRemoveSpaceString = (stringData = "") => {
    const breakArray = includes(stringData, ";")
        ? split(stringData, ";")
        : split(stringData, ",");
    return map(breakArray, (item) => replace(item, "\r", ""));
};

function convertCsvJSON(csv: string) {
    /**
     * get all string that has format "address1, address2, address3, ..."
     */
    const stringContainCommaList = csv.match(/".*,.*"/g);

    forEach(stringContainCommaList, (stringComma) => {
        /**
         *  "Address, Bangkok" => "Address[space]Bangkok"
         */
        const convertCommaString = stringComma.replaceAll(", ", "[space]");
        // eslint-disable-next-line no-param-reassign
        csv = replace(csv, stringComma, convertCommaString);
    });

    // /**
    //  * ,[space][space], => ''
    //  */
    // const clearedSpaceCommaString = csv.replace(/,  +,/g, ",,");
    // /**
    //  *  "Address, Bangkok" => "Address[space]Bangkok"
    //  */
    // const convertCommaString = clearedSpaceCommaString.replaceAll(", ", "[space]");
    const lines = split(csv, "\n");
    const result = [];
    const headers = splitRemoveSpaceString(lines[0]);

    for (let i = 1; i < lines.length; i += 1) {
        if (!lines[i]) continue;
        const obj: any = {};
        const currentLine = splitRemoveSpaceString(lines[i]);

        for (let j = 0; j < headers.length; j += 1) {
            const currentHeader = headers[j];
            let value: any = currentLine[j];
            if (value === "TRUE") {
                value = true;
            }
            if (value === "FALSE") {
                value = false;
            }
            if (!isEmpty(currentHeader)) {
                const valueRemoveSpace = value.replaceAll("[space]", ", ");
                // eslint-disable-next-line no-useless-escape
                const valueRemoveMark = valueRemoveSpace.replaceAll(`\"`, "");
                obj[currentHeader] = valueRemoveMark
                    ? valueRemoveMark.trim()
                    : "";
            }
        }
        result.push(obj);
    }
    return result;
}

const InventoryImportInput = () => {
    const { importForm, onStepNext } = useContext(InventoryImportContext);

    const { warehouseId } = importForm.values;

    const validateSkuList = (skuListConverted: any[]) => {
        return every(skuListConverted, (sku) => {
            const { stockUpdateMethod, stockUpdateQuantity } = sku;
            if (isEmpty(stockUpdateQuantity)) {
                return false;
            }
            if (isEmpty(sku.sku)) {
                return false;
            }
            if (
                isEmpty(stockUpdateMethod) ||
                (stockUpdateMethod.toLowerCase() !== "add" &&
                    stockUpdateMethod.toLowerCase() !== "set")
            ) {
                return false;
            }

            const validStockNumber = stockUpdateQuantity
                .replace("+", "")
                .replace("-", "");
            if (!StringUtils.isAllDigit(validStockNumber)) {
                return false;
            }
            return true;
        });
    };

    const onDrop = ([fileUpload]: any) => {
        const reader: any = new FileReader();
        reader.readAsText(fileUpload);
        reader.onload = () => {
            const skuList = convertCsvJSON(reader.result);
            if (!validateSkuList(skuList)) {
                Notifications.showError(Messages.fileImportIsNotRightFormat);
                return;
            }
            InventoryAPI.validateImport(skuList)
                .then(() => {
                    importForm.setValues({
                        ...importForm.values,
                        itemImportList: skuList,
                        itemInvalid: [],
                    });
                    onStepNext();
                })
                .catch((reason) => {
                    const inValidItems = reason?.errors?.[0]?.state ?? [];
                    importForm.setValues({
                        ...importForm.values,
                        itemImportList: skuList,
                        itemInvalid: inValidItems,
                    });
                });
        };
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <div className="flex-column">
            <text>{Messages.uploadAllItemsInventory}</text>
            <text>
                <text className="text-bold">{Messages.requiredField} :</text>
                sku, stock_update_method, stock_update_quantity
            </text>
            <WarehouseSelect
                className="mt-3"
                value={warehouseId}
                onChange={(value) =>
                    importForm.setFieldValue("warehouseId", value)
                }
            />
            <div {...getRootProps()}>
                <input
                    {...getInputProps()}
                    multiple={false}
                    accept=".csv, text/csv"
                    disabled={!warehouseId}
                />

                <Button
                    className="mt-3"
                    iconName="attachment"
                    disabled={!warehouseId}
                >
                    {Messages.attachCSV}
                </Button>
            </div>

            <text className="text-bold mt-3">{Messages.notSureHowToStart}</text>
            <text>{`- ${Messages.downloadAsvFile}`}</text>
            <text>
                - {Messages.download}
                <a
                    href="/template_import/import-stock-template.csv"
                    className="text-primary"
                    target="_blank"
                >
                    {Messages.blankCSVTemplate}
                </a>
            </text>
        </div>
    );
};

export default InventoryImportInput;
