import classNames from "classnames";
import { Button } from "d-react-components";
import { map } from "lodash";
import React from "react";
import { LANGUAGES } from "../../constant/app";

const LanguageSelectButton = ({ onChange, lang, className }: any) => {
    const wrapperClass = classNames("d-flex", className);
    return (
        <div className={wrapperClass}>
            {map(LANGUAGES, (langItem: any) => (
                <Button
                    className={classNames("mr-3", {
                        "btn-primary": langItem.locale === lang,
                        "btn-secondary": langItem.locale !== lang,
                    })}
                    variant={langItem.locale === lang ? "standard" : "outline"}
                    onClick={() => onChange(langItem.locale)}
                    key={langItem?.label}
                >
                    <div>
                        <img src={langItem.icon} alt="" className="mr-2" />
                        {langItem.label}
                    </div>
                </Button>
            ))}
        </div>
    );
};
export default LanguageSelectButton;
