import React from "react";

interface IBannerCrudState {
    bannerForm: any;
    bannerLang: string;
    setBannerLang: any;
}

const bannerCrudState: IBannerCrudState = {
    bannerForm: null,
    bannerLang: "en",
    setBannerLang: () => {},
};

export const BannerCrudContext = React.createContext(bannerCrudState);
