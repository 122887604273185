/* eslint-disable import/no-unresolved */
import { Progress } from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingAPI from "../../../api/booking/BookingAPI";
import { AppStateContext } from "../../../context/app";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import BookingDetailActivity from "./BookingDetailActivity";
import BookingDetailBilling from "./BookingDetailBilling";
import BookingDetailContact from "./BookingDetailContact";
import BookingDetailCustomer from "./BookingDetailCustomer";
import BookingDetailHeader from "./BookingDetailHeader";
import BookingDetailNote from "./BookingDetailNote";
import BookingDetailPic from "./BookingDetailPic";
import BookingDetailProducts from "./BookingDetailProducts";
import BookingDetailScheduleService from "./BookingDetailScheduleService";
import BookingDetailServiceDate from "./BookingDetailSeriveDate";
import BookingDetailServiceAdd from "./BookingDetailServiceAdd";
import BookingDetailSource from "./BookingDetailSource";
import BookingDetailWorksheet from "./BookingDetailWorksheet";

const BookingDetail = () => {
    const { bookingId } = useParams<any>();
    const [metaData, setMetaData] = useState<any[]>();
    const [bookingDetail, setBookingDetail] = useState<any>({});
    const { setSiteConfig } = useContext(AppStateContext);
    useEffect(() => {
        loadMetaData();
    }, []);

    const loadMetaData = () => {
        Progress.show(
            [
                { method: BookingAPI.metaData, params: [] },
                { method: BookingAPI.detail, params: [bookingId] },
            ],
            (res: any) => {
                const bookingDetail = res[1];
                setMetaData(res[0]);
                setBookingDetail(bookingDetail);
                setSiteConfig({
                    breadcrumb: [
                        { title: Messages.allBooking, url: Path.BOOKING_TABLE },
                        {
                            title: `${Messages.booking} #${bookingDetail?.bookingNo}`,
                        },
                    ],
                });
            }
        );
    };

    return (
        <BookingDetailContext.Provider
            value={{ bookingDetail, setBookingDetail, ...metaData } as any}
        >
            <div className="h-100 overflow-auto pb-5 p-4">
                <BookingDetailHeader />
                <div className="row mt-3">
                    <div className="col-8 pb-5 mb-5">
                        <BookingDetailProducts />
                        <BookingDetailNote />
                        <BookingDetailScheduleService />
                        <BookingDetailPic />
                        <BookingDetailWorksheet />
                        <BookingDetailActivity />
                    </div>
                    <div className="col-4  ">
                        <BookingDetailSource />
                        <BookingDetailServiceDate />
                        <BookingDetailCustomer />
                        <BookingDetailContact />
                        <BookingDetailServiceAdd />
                        <BookingDetailBilling />
                    </div>
                </div>
            </div>
        </BookingDetailContext.Provider>
    );
};

export default BookingDetail;
