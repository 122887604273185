import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";
import { F_PRODUCT } from "../product/ProductFragment";
import { F_USER } from "../user/UserFragment";

export const F_NEWS_ITEM = gql`
    ${F_USER}
    fragment F_NEWS_ITEM on News {
        id
        th {
            title
        }
        en {
            title
        }
        status
        publishedDate
        featuredUrl
        createBy {
            ...F_USER
        }
        createdAt
        newsId
    }
`;

export const F_NEWS_LIST = gql`
    ${F_NEWS_ITEM}
    ${F_PAGINATION}
    fragment F_NEWS_LIST on NewsList {
        news {
            ...F_NEWS_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;
export const F_NEWS_CATEGORY = gql`
    ${F_USER}
    fragment F_NEWS_CATEGORY on CategoryNews {
        id
        name
        order
        createBy {
            ...F_USER
        }
        createdAt
        updatedAt
        status
    }
`;

export const F_NEWS = gql`
    ${F_USER}
    ${F_PRODUCT}
    ${F_NEWS_CATEGORY}

    fragment F_NEWS on News {
        id
        newsId
        th {
            title
            description
            shortDescription
        }
        en {
            title
            description
            shortDescription
        }
        status
        publishedDate
        category {
            ...F_NEWS_CATEGORY
        }
        featuredUrl
        isBookService
        products {
            ...F_PRODUCT
        }
        createdAt
        createBy {
            ...F_USER
        }
    }
`;

export const F_CATEGORY_NEWS_ITEM = gql`
    ${F_USER}
    fragment F_CATEGORY_NEWS_ITEM on CategoryNews {
        id
        name
        order
        createBy {
            ...F_USER
        }
        createdAt
        updatedAt
        status
    }
`;

export const F_CATEGORY_NEWS_LIST = gql`
    ${F_CATEGORY_NEWS_ITEM}
    ${F_PAGINATION}
    fragment F_CATEGORY_NEWS_LIST on CategoryNewsList {
        categoryNews {
            ...F_CATEGORY_NEWS_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;
