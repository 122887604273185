import * as Yup from "yup";

export const WSProductSchema = Yup.array()
    .of(
        Yup.object().shape({
            useQuantity: Yup.string().required("Need to fill item quantity!"),
        }) as any
    )
    .required("Products required!")
    .min(1, "Products required!");

export const WSBookingSchema = Yup.object().shape({
    products: WSProductSchema,
    address: Yup.object().required("Address is required!"),
}) as any;

export const WSCriteriaCheckSchema = Yup.array().of(
    Yup.object().shape({
        value: Yup.boolean()
            .oneOf([true], "All progress must be checked!")
            .required("All progress must be checked!"),
    })
) as any;

export const WSCriteriaPriorCheckingSchema = Yup.array()
    .of(
        Yup.object().shape({
            checkList: WSCriteriaCheckSchema,
        }) as any
    )
    .required("Prior checking items required!")
    .min(1, "Prior checking items required!");

export const WSCriteriaProgressSchema = Yup.array()
    .of(
        Yup.object().shape({
            workProgress: WSCriteriaCheckSchema,
            // deliveryProgress: WSCriteriaCheckSchema,
        }) as any
    )
    .required("Prior checking items required!")
    .min(1, "Prior checking items required!");

export const WSSignatureConfirmSchema = Yup.object().shape({
    customerSignDate: Yup.string().required(
        "Customer signature date required!"
    ),
    customerSignature: Yup.string().required("Customer signature required!"),
    // remark: Yup.string().required("Defection confirm remark required!"),
    staffSignDate: Yup.string().required("Staff signature date required!"),
    staffSignature: Yup.string().required("Staff signature required!"),
    confirmed: Yup.boolean()
        .oneOf([true], "You have not confirmed yet!")
        .required("You have not confirmed yet!"),
});

export const WSCustomerConfirmSchema = Yup.object().shape({
    confirmation: WSSignatureConfirmSchema,
    criteria: Yup.array().of(
        Yup.object().shape({
            customerCheck: WSCriteriaCheckSchema,
        }) as any
    ),
});

export const WSDefectionVisualSchema = Yup.array().of(
    Yup.object().shape({
        note: Yup.string().required("Defection visualization note required!"),
        images: Yup.array()
            .required("Defection visualization media required!")
            .min(1, "Defection visualization media required!"),
    }) as any
);
// .required("Defection visualization required!")
// .min(1, "Defection visualization required!");

export const WSDefectionSchema = Yup.object().shape({
    defectionConfirm: WSSignatureConfirmSchema,
    defectionVisualList: WSDefectionVisualSchema,
});

export const WorksheetSchema = Yup.object().shape({
    // defectionConfirm: WSSignatureConfirmSchema,
    // defectionVisualList: WSDefectionVisualSchema,
    // criteria: WSCriteriaPriorCheckingSchema,
    products: WSProductSchema,
});

export const WorksheetCancelSchema = Yup.object().shape({
    remark: Yup.string().required("Remark required!"),
});

export const WorksheetMileageSchema = Yup.object().shape({
    mile: Yup.number().required("Mile required!"),
    proves: Yup.array().min(1).required("File required!"),
});
