import { FormikProps } from "formik";
import React from "react";
import { IOrder, IOrderCreatePriceState } from "../interfaces/order";
import { IPayment } from "../interfaces/payment";
import { IPaymentMethod } from "../interfaces/paymentmethod";
import { IWarehouse } from "../interfaces/warehouse";
import { OrderCreateValueType } from "../screen/order/create/OrderCreate";

export interface IOrderDetailMeta {
    paymentMethods: IPaymentMethod[];
    warehouses: IWarehouse[];
}
export interface IOrderDetailState {
    metaData: IOrderDetailMeta;
    orderDetail: IOrder;
    setOrderDetail: (value: any) => void;
}

const orderDetailState: IOrderDetailState = {
    metaData: {} as any,
    orderDetail: {} as any,
    setOrderDetail: () => {},
};

export const OrderDetailContext = React.createContext(orderDetailState);

interface IOrderCreateState {
    orderForm: FormikProps<OrderCreateValueType>;
    orderState: IOrderCreatePriceState;
    onChangeCustomer: (customer: any) => void;
}

const orderCreateState: IOrderCreateState = {
    orderForm: null as any,
    orderState: null as any,
    onChangeCustomer: () => {},
};

export const OrderCreateContext = React.createContext(orderCreateState);

interface IOrderPaymentDetailState {
    payment: IPayment;
}

const orderPaymentDetailState: IOrderPaymentDetailState = {
    payment: {} as any,
};

export const OrderPaymentDetailContext = React.createContext(
    orderPaymentDetailState
);
