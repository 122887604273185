import { Modal } from "d-react-components";
import { useFormik } from "formik";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";

interface IBookingCrudContactModal {
    open: boolean;
    defaultValue: any;
    onClose: () => void;
    onChange: (value: any) => void;
}

const BookingCrudContactModal = ({
    open,
    defaultValue,
    onClose,
    onChange,
}: IBookingCrudContactModal) => {
    const contactForm = useFormik<any>({
        initialValues: defaultValue,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            onChange(values);
            onClose();
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateContact}
            onSave={() => contactForm.handleSubmit()}
        >
            <div className="row">
                <InputTextForm
                    keyData="firstName"
                    form={contactForm}
                    className="col-6 mt-3"
                />
                <InputTextForm
                    keyData="lastName"
                    form={contactForm}
                    className="col-6 mt-3"
                />
                <InputTextForm
                    keyData="email"
                    form={contactForm}
                    className="col-6 mt-3"
                />
                <InputTextForm
                    keyData="phone"
                    form={contactForm}
                    className="col-6 mt-3"
                />
            </div>
        </Modal>
    );
};

export default BookingCrudContactModal;
