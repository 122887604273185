import { Select, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import { NewsCrudContext } from "../../../context/news";
import Messages from "../../../languages/Messages";

const NewsCrudCategory = () => {
    const { newsForm, categoryList } = useContext(NewsCrudContext);

    const { values, errors } = newsForm;

    return (
        <ViewCollapse className="mt-3" label={Messages.category} required>
            <Select
                value={values.categoryIds}
                error={errors.categoryIds}
                dataSource={categoryList}
                onChange={(valueId) =>
                    newsForm.setFieldValue("categoryIds", valueId)
                }
                getLabel={(item) => item.name}
                multiple
            />
        </ViewCollapse>
    );
};

export default NewsCrudCategory;
