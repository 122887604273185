import {
    AwesomeTableComponent,
    Badge,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    TabBar,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find, map } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { PERMISSION_MODULE, ROLE_ACCESS_TYPE } from "../../../constant/user";
import {
    VOUCHER_OWNERSHIP_STATUSES,
    VOUCHER_STATUSES,
} from "../../../constant/voucher";
import { useUserPermission } from "../../../hoook/permission";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import { isFilteredBody } from "../../../utils/Utils";
import Path from "../../Path";
import VoucherExportModal from "./VoucherExportModal";
import VoucherFilterModal from "./VoucherFilterModal";

const VOUCHER_TABS = [
    { id: null, label: "all", color: "#33B950" },
    ...VOUCHER_STATUSES,
];

const VoucherTable = () => {
    const [filterStatus, setFilterStatus] = useState(VOUCHER_TABS[0]);
    const [voucherSummary, setVoucherSummary] = useState([]);
    const history = useHistory();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const isFirstTime = useFirstTime();
    const [openFilter, setOpenFilter] = useState(false);
    const [openExport, setOpenExport] = useState(false);

    const [filterState, setFilterState] = useQueryParam(
        "filterState",
        JsonParam
    );

    const isGrantDetail = useUserPermission(
        PERMISSION_MODULE.VOUCHER,
        ROLE_ACCESS_TYPE.DETAIL
    );

    const isGrantCreate = useUserPermission(
        PERMISSION_MODULE.VOUCHER,
        ROLE_ACCESS_TYPE.CREATE
    );

    const isGrantExport = useUserPermission(
        PERMISSION_MODULE.VOUCHER,
        ROLE_ACCESS_TYPE.EXPORT
    );

    const columns: IColumnsProps = [
        {
            title: Messages.voucherNo,
            dataIndex: "code",
            render: (code: string, voucher: any) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })}
                >
                    {code}
                </AppLink>
            ),
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status: string) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={VOUCHER_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },

        {
            title: Messages.ownerShipStatus,
            dataIndex: "ownership",
            render: (ownership: any) => (
                <ViewLabelStatus
                    status={ownership?.status}
                    listStatus={VOUCHER_OWNERSHIP_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },
        {
            title: Messages.effectiveDate,
            dataIndex: "effectiveDate",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.expiryDate,
            dataIndex: "expiryDate",
            render: (date) =>
                date ? TimeUtils.toDateTime(date) : Messages.noExpireDate,
        },

        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) => createBy?.firstName,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (voucher) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, voucher)
                    }
                    dataSource={[{ id: "detail", label: "detail" }]}
                />
            ),
            align: "center",
        },
    ];

    useEffect(() => {
        loadVoucherSummary();
    }, []);

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterStatus]);

    const loadVoucherSummary = () => {
        VoucherAPI.summary().then(setVoucherSummary);
    };

    const source = (pagingData: any) => {
        const filterParams = {
            status: filterStatus?.id,
            serviceCategories: filterState?.serviceCategories,
            effectiveDate: filterState?.effectiveDate
                ? moment(filterState?.effectiveDate).toISOString()
                : null,
            expiryDate: filterState?.expiryDate
                ? moment(filterState?.expiryDate).toISOString()
                : null,
            createdBy: map(filterState?.createdBy, (item) => item.id),
            customer: map(filterState?.customer, (item) => item.id),
            productIds: map(filterState?.products, (item) => item.id),
            ownershipStatus: filterState?.ownershipStatus,
        };
        return VoucherAPI.list(searchRef.current, pagingData, filterParams);
    };

    const onClickTableAction = (actionId: string, voucher: any) => {
        switch (actionId) {
            case "cancel":
                onCancelVoucher(voucher.id as string);
                break;
            default:
                history.push(
                    generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })
                );
        }
    };

    const onCancelVoucher = (id: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCancelVoucher,
            () => {
                Progress.show(
                    { method: VoucherAPI.delete, params: [id] },
                    () => {
                        tableRef.current.refresh();
                        Notifications.showSuccess(
                            Messages.deleteVoucherSuccess
                        );
                    }
                );
            }
        );
    };

    const onFilter = (values: any) => {
        setFilterState(values);
        tableRef.current.refresh();
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const renderLabelTab = (tab: any) => {
        const summary = find(
            voucherSummary,
            (item: any) => item.status === tab.id
        );
        return (
            <div className="flex-center">
                {(Messages as any)[tab.label]}
                <Badge
                    className="ml-3"
                    variant="index"
                    size="xx-large"
                    index={summary?.total ?? 0}
                    color={summary?.total === 0 ? "green" : "secondary"}
                />
            </div>
        );
    };

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={() => history.push(Path.VOUCHER_CREATE)}
                label={Messages.voucher}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
                isFiltered={isFilteredBody(filterState)}
                showExport={isGrantExport}
                onClickExport={() => setOpenExport(true)}
            />
            <TabBar
                dataSource={VOUCHER_TABS as any}
                onChange={setFilterStatus as any}
                value={filterStatus as any}
                getLabel={renderLabelTab}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => res?.data?.data?.voucher ?? []}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
            />
            {openFilter && (
                <VoucherFilterModal
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    onFilter={onFilter}
                    defaultValue={filterState}
                />
            )}

            {openExport && (
                <VoucherExportModal
                    open={openExport}
                    onClose={() => setOpenExport(false)}
                />
            )}
        </div>
    );
};

export default VoucherTable;
