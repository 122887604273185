import { Switch } from "antd";
import React, { useContext } from "react";
import { NewsCrudContext } from "../../../context/news";
import Messages from "../../../languages/Messages";

const NewsCrudBookService = () => {
    const { newsForm } = useContext(NewsCrudContext);

    return (
        <div className="card-container p-4 flex-row-between-center mt-3">
            <label>{Messages.bookService}</label>
            <Switch
                onChange={() =>
                    newsForm.setFieldValue(
                        "isBookService",
                        !newsForm.values?.isBookService
                    )
                }
                checked={newsForm.values?.isBookService}
            />
        </div>
    );
};

export default NewsCrudBookService;
