import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import { join, map } from "lodash";
import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../../../common/AppLink";
import { isPremiumService } from "../../../../../interfaces/voucher";
import Messages from "../../../../../languages/Messages";
import Path from "../../../../Path";
import { WorksheetDetailVoucherContext } from "../context/WorksheetDetailVoucherContext";

export interface IWorksheetVoucherTableProps {
    [key: string]: any;
}

const WorksheetVoucherTable: React.FC<IWorksheetVoucherTableProps> = () => {
    const {
        worksheetProducts,
        voucherWithServiceApply,
        ownershipVouchers,
        anonymousVouchers,
    } = useContext(WorksheetDetailVoucherContext);

    const columns: IColumnsProps = [
        {
            title: Messages.voucherNo,
            dataIndex: "appliedList",
            render: (appliedList, item, index) => {
                const voucherItems = map(
                    appliedList,
                    (voucher, index: number) => (
                        <AppLink
                            to={generatePath(Path.VOUCHER_DETAIL, {
                                voucherId: voucher.id,
                            })}
                        >
                            {voucher?.code}
                            {index !== appliedList?.length - 1 && (
                                <text>, </text>
                            )}
                        </AppLink>
                    )
                );
                const voucherNos = join(
                    map(appliedList, (i) => i?.voucherNo),
                    ","
                );
                return <div> {voucherItems}</div>;
            },
        },
        {
            title: Messages.voucherType,
            dataIndex: "voucher",
            width: 200,
            render: (voucher, item) => {
                const isPremium = isPremiumService(voucher);
                return (
                    <div className="p-3">
                        {isPremium
                            ? Messages.premiumVoucher
                            : Messages.normalVoucher}
                    </div>
                );
            },
        },
        {
            title: Messages.ownershipType,
            dataIndex: "isAnonymous",
            width: 150,
            render: (isAnonymous) => (
                <div className="d-flex align-items-center">
                    {isAnonymous ? Messages.anonymous : Messages.ownership}
                </div>
            ),
        },

        {
            title: Messages.quantity,
            align: "center",
            dataIndex: "appliedQty",
            render: (quantity) => quantity,
        },
    ];

    return (
        <AwesomeTableComponent
            className="mt-4"
            columns={columns}
            dataSource={[...ownershipVouchers, ...anonymousVouchers]}
        />
    );
};

export default WorksheetVoucherTable;
