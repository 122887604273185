import * as Yup from "yup";
import { BANNER_DESTINATION_TYPE } from "../constant/banner";

export const BannerContentSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        image: Yup.string().required("Required"),
        destinationType: Yup.string().required("Required"),
    });

    if (value?.destinationType === BANNER_DESTINATION_TYPE.EXTERNAL) {
        return schema.concat(
            Yup.object().shape({
                destinationUrl: Yup.string().required("Required field!"),
            }) as any
        );
    }

    if (value?.destinationType === BANNER_DESTINATION_TYPE.IN_APP_NEWS) {
        return schema.concat(
            Yup.object().shape({
                news: Yup.object().required("Required field!"),
            }) as any
        );
    }
    return schema;
});

export const BannerSchema = Yup.object().shape({
    th: BannerContentSchema,
    en: BannerContentSchema,
    title: Yup.string().required("Required"),
    position: Yup.string().required("Required"),
    effectiveDate: Yup.string().required("Required"),
});
