import React from "react";

interface IWarehouseCreateState {
    isEdit: boolean;
    warehouseForm: any;
    warehouseLang: "th" | "en";
    setWarehouseLang: (value: any) => any;
}
const warehouseCreateState: IWarehouseCreateState = {
    warehouseForm: null,
    isEdit: false,
    warehouseLang: "en",
    setWarehouseLang: () => {},
};
export const WarehouseCreateContext = React.createContext(warehouseCreateState);

interface IWarehouseDetailState {}
const warehouseDetailState: IWarehouseDetailState = {};
export const WarehouseDetailContext = React.createContext(warehouseDetailState);
