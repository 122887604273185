import { RadioGroup } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { UserCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";

const UserCrudRoles = () => {
    const { roleList, userForm } = useContext(UserCrudContext);
    return (
        <div className="bg-white p-4">
            <h4>{Messages.updateRole}</h4>
            <RadioGroup
                numberOfColumns="1"
                dataSource={map(roleList, (item) => ({
                    id: item.id,
                    label: item.name,
                }))}
                onChange={(value) => userForm?.setFieldValue("roleId", value)}
                value={userForm.values?.roleId}
            />
        </div>
    );
};

export default UserCrudRoles;
