import { FormikProps } from "formik";
import React from "react";
import { IVoucher } from "../interfaces/voucher";

interface IVoucherCreateState {
    voucherForm: FormikProps<any>;
}

const voucherCreateState: IVoucherCreateState = {
    voucherForm: {} as any,
};

export const VoucherCreateContext = React.createContext(voucherCreateState);

interface IVoucherDetailState {
    voucher: IVoucher;
    setVoucher: (voucher: any) => void;
}

const voucherDetailState: IVoucherDetailState = {
    voucher: null as any,
    setVoucher: (val: any) => {},
};

export const VoucherDetailContext = React.createContext(voucherDetailState);
