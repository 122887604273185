import {
    AwesomeTableComponent,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import UserAPI from "../../../api/user/UserAPI";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

export const TABLE_ACTIONS = [{ id: "remove", label: "remove" }];

const UserRoleTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const history = useHistory();
    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "roleId",
            render: (roleId, item) => (
                <AppLink
                    to={generatePath(Path.USER_ROLE_DETAIL, {
                        roleId: item.id,
                    })}
                >
                    {roleId}
                </AppLink>
            ),
        },

        {
            title: Messages.role,
            dataIndex: "name",
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (role: any) => {
                return (
                    <TableAction
                        dataSource={TABLE_ACTIONS}
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, role)
                        }
                    />
                );
            },
        },
    ];

    const source = () => {
        return UserAPI.roleList(searchRef.current);
    };

    const onClickTableAction = (actionId: string, role: any) => {
        switch (actionId) {
            default:
                onRemoveRole(role?.id);
        }
    };

    const onRemoveRole = (roleId: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: UserAPI.removeRole,
                        params: [roleId],
                    },
                    () => {
                        tableRef.current.refresh();
                    }
                )
        );
    };

    const onClickAddNew = () => {
        history.push(Path.USER_ROLE_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allRoles}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.role ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                isPagination={false}
            />
        </div>
    );
};

export default UserRoleTable;
