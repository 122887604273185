import {
    AwesomeTableComponent,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useRef, useState } from "react";
import ProductAPI from "../../../api/product/ProductAPI";
import Image from "../../../common/Image";
import TableAction from "../../../common/TableActions";
import { CATEGORY_STATUSES } from "../../../constant/category";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import ProductCategoryEditModal from "./ProductCategoryEditModal";

const INIT_CRUD_MODAL = {
    open: false,
    category: null,
};

const ProductCategoryTable = () => {
    const [openCRUD, setOpenCRUD] = useState<any>(INIT_CRUD_MODAL);
    const tableRef = useRef<any>();
    const searchRef = useRef<string>("");
    const columns: IColumnsProps = [
        {
            title: Messages.feature,
            dataIndex: "featured",
            render: (featured: string) => (
                <Image src={featured} className="image-square-small" />
            ),
        },
        {
            title: Messages.name,
            dataIndex: "name",
        },

        {
            title: Messages.order,
            dataIndex: "order",
        },
        {
            title: Messages.itemQty,
            dataIndex: "quantity",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={CATEGORY_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) =>
                `${createBy?.firstName} ${createBy.lastName}`,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.updateAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "source",
            render: (source: any, category) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, category)
                    }
                />
            ),
            align: "center",
        },
    ];

    const onClickTableAction = (actionId: string, category: any) => {
        switch (actionId) {
            default:
                setOpenCRUD({ open: true, category });
        }
    };

    const source = (pagingData: any) => {
        return ProductAPI.categoryList(searchRef.current, {}, pagingData);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={() => setOpenCRUD({ open: true })}
                label={Messages.productCategory}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => res?.data?.data?.categoryProduct ?? []}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
                ref={(ref) => {
                    tableRef.current = ref;
                }}
            />
            {openCRUD.open && (
                <ProductCategoryEditModal
                    open={openCRUD.open}
                    category={openCRUD.category}
                    onClose={() => setOpenCRUD(INIT_CRUD_MODAL)}
                    onSuccess={() => tableRef.current.refresh()}
                />
            )}
        </div>
    );
};

export default ProductCategoryTable;
