import { TimeUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import { OrderDetailContext } from "../../../context/order";
import { IOrderWorksheet } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const OrderDetailWorksheet = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { worksheets } = orderDetail;

    const renderWorksheetItem = (worksheet: IOrderWorksheet) => (
        <div className="flex-column border-bottom py-2">
            <AppLink
                to={generatePath(Path.WORKSHEET_DETAIL, {
                    worksheetId: worksheet.id,
                })}
                target="_blank"
            >
                {`#${worksheet.worksheetNo}`}
            </AppLink>
            <text>{`${Messages.createdOn} ${TimeUtils.toDateTime(
                worksheet.createdAt
            )}`}</text>
        </div>
    );

    return (
        <div className="card-container p-4 mt-3">
            <label>{Messages.worksheetReference}</label>
            {map(worksheets, renderWorksheetItem)}
        </div>
    );
};

export default OrderDetailWorksheet;
