import Messages from "../languages/Messages";

export const CRITERIA_TYPES = [
    { id: "dropdown", label: Messages.dropdown },
    { id: "textInput", label: Messages.textInput },
];

export const CRITERIA_TYPE = {
    dropdown: "dropdown",
    textInput: "textInput",
};

export const CRITERIA_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const CRITERIA_STATUSES = [
    { id: CRITERIA_STATUS.ACTIVE, label: "active", color: "#33B950" },
    { id: CRITERIA_STATUS.INACTIVE, label: "inactive", color: "#C4C4C4" },
];
