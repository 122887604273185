import { DateInput, Notifications } from "d-react-components";
import React, { useContext } from "react";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const BookingCreateServiceDate = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    return (
        <div className="card-container p-4 mt-3">
            <DateInput
                label={Messages.estServiceDate}
                onChange={(value) => {
                    const valueTime = (value as any).valueOf();
                    if (valueTime < new Date().getTime()) {
                        Notifications.showError(
                            Messages.estDateNeedToGreaterThan
                        );
                        return;
                    }
                    bookingForm.setFieldValue("serviceDate", value);
                }}
                value={bookingForm.values.serviceDate ?? null}
                error={bookingForm.errors.serviceDate}
                showTime
                format="DD/MM/YYYY HH:mm"
                allowClear={false}
            />
        </div>
    );
};

export default BookingCreateServiceDate;
