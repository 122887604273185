import {
    Header,
    Notifications,
    Progress,
    ViewCollapse,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import StaticContentAPI from "../../../api/staticContent/StaticContentAPI";
import InputTextForm from "../../../common/input/InputTextForm";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { StaticContentCrudContext } from "../../../context/staticContent";
import { mapStaticContentToServer } from "../../../interfaces/staticContent";
import Messages from "../../../languages/Messages";
import StaticContentLanguageSelect from "./StaticContentLanguageSelect";

const StaticContentCrud = () => {
    const [type] = useQueryParam("type", StringParam);

    const [contentLang, setContentLang] = useState<string>("en");
    const contentForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapStaticContentToServer(values);
            onUpdateStaticContent(input);
        },
    });

    useEffect(() => {
        loadStaticContentDetail();
    }, [type]);

    const formValues = contentForm?.values;
    const formErrors = contentForm?.errors;

    const loadStaticContentDetail = () => {
        if (!type) return;
        Progress.show(
            { method: StaticContentAPI.staticContent, params: [type] },
            (res: any) => {
                contentForm.setValues(res?.data?.data);
            }
        );
    };

    const onUpdateStaticContent = (input: any) => {
        Progress.show(
            { method: StaticContentAPI.updateByType, params: [input] },
            (res: any) => {
                contentForm.setValues(res?.data?.data);
                Notifications.showSuccess(Messages.updateStaticContentSuccess);
            }
        );
    };

    return (
        <StaticContentCrudContext.Provider
            value={{ contentForm, contentLang, setContentLang }}
        >
            <Header
                title={Messages.staticContent}
                onSave={() => contentForm.handleSubmit()}
            />
            <div className="h-100 overflow-auto p-3 pb-5">
                <StaticContentLanguageSelect />
                <InputTextForm
                    form={contentForm}
                    keyData="name"
                    className="mt-3"
                />

                <ViewCollapse
                    label={Messages.content}
                    className="card-container mt-3"
                    required
                >
                    <RichTextTiny
                        value={formValues?.[contentLang]?.content ?? ""}
                        onChange={(value: any) =>
                            contentForm.setFieldValue(
                                `${contentLang}.content`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.productShortDes}
                        size="large"
                        error={
                            (formErrors?.th as any)?.content ||
                            (formErrors?.en as any)?.content
                        }
                    />
                </ViewCollapse>
            </div>
        </StaticContentCrudContext.Provider>
    );
};

export default StaticContentCrud;
