import { CheckboxGroup, ObjectUtils } from "d-react-components";
import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import ProductAPI from "../../../api/product/ProductAPI";

import Messages from "../../../languages/Messages";

interface IProductCategoryCheckboxGroup {
    className?: string;
    value: any[];
    multiple?: boolean;
    isObjectValue?: boolean;
    onChange: (value: any) => void;
}

const ProductCategoryCheckboxGroup = ({
    value,
    multiple,
    className,
    isObjectValue,
    onChange,
}: IProductCategoryCheckboxGroup) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        loadCategory();
    }, []);

    const loadCategory = () => {
        ProductAPI.activeCategoryList().then((res) =>
            setCategories(res?.data?.data?.categoryProduct ?? [])
        );
    };

    const onChangeValue = (id: any) => {
        if (!isObjectValue) {
            onChange(id);
            return;
        }
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(categories, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(categories, id));
    };

    const fieldValue = useMemo(() => {
        if (!isObjectValue) {
            return value;
        }
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <CheckboxGroup
            numberOfColumns="2"
            dataSource={categories}
            label={Messages.category}
            className={className}
            value={fieldValue}
            onChange={onChangeValue}
            getLabel={(item) => item?.name}
        />
    );
};

export default ProductCategoryCheckboxGroup;
