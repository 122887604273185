import { gql } from "@apollo/client";
import API from "../API";
import { F_NOTIFICATIONS } from "./NotificationFragment";

export default {
    updateMessagingToken: (token: string) =>
        API.mutate({
            mutation: gql`
                mutation updateMessagingToken($token: String!) {
                    data: updateMessagingToken(token: $token) {
                        id
                    }
                }
            `,
            fetchPolicy: "no-cache",
            variables: { token },
        }),

    notifications: ({ pageIndex = 1, pageSize = 20 }: any, isRead?: boolean) =>
        API.query({
            query: gql`
                ${F_NOTIFICATIONS}
                query notifications($isRead: Boolean, $page: Int, $limit: Int) {
                    data: notifications(
                        isRead: $isRead
                        page: $page
                        limit: $limit
                    ) {
                        ...F_NOTIFICATIONS
                    }
                }
            `,
            variables: {
                isRead,
                page: pageIndex,
                limit: pageSize,
            },
            fetchPolicy: "no-cache",
        }),

    notificationUnread: () =>
        API.query({
            query: gql`
                query notificationUnread {
                    notificationUnread {
                        unread
                    }
                }
            `,
        }),

    markAllNotificationAsRead: () =>
        API.mutate({
            mutation: gql`
                mutation markAllNotificationAsRead {
                    markAllNotificationAsRead {
                        status
                    }
                }
            `,
        }),

    markNotificationAsRead: (id: string) =>
        API.mutate({
            mutation: gql`
                mutation markNotificationAsRead($id: ID!) {
                    markNotificationAsRead(id: $id) {
                        status
                    }
                }
            `,
            variables: {
                id,
            },
        }),
};
