import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import TableAction from "../../../common/TableActions";
import { CUSTOMER_STATUSES } from "../../../constant/customer";
import {
    ICustomer,
    mapCustomerFilterBodyToServer,
} from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerExportModal from "./CustomerExportModal";
import CustomerFilterModal from "./CustomerFilterModal";

const CustomerTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const history = useHistory();
    const [openFilter, setOpenFilter] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [filterBody, setFilterBody] = useState();

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "customerId",
            render: (customerId, item) => (
                <AppLink
                    to={generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: item.id,
                    })}
                    className="p-3"
                >
                    {customerId}
                </AppLink>
            ),
        },
        {
            title: Messages.avatar,
            dataIndex: "avatar",
            render: (avatar) => (
                <Image src={avatar} className="image-square-small" />
            ),
        },

        {
            title: Messages.name,
            dataIndex: "fullName",
        },
        {
            title: Messages.email,
            dataIndex: "email",
        },

        {
            title: Messages.phone,
            dataIndex: "phone",
        },
        {
            title: Messages.gender,
            dataIndex: "gender",
            render: (gender) => gender ?? "N/A",
        },
        {
            title: Messages.dob,
            dataIndex: "dateOfBirth",
            render: (date) => (date ? TimeUtils.toDate(date) : "N/A"),
        },
        {
            title: Messages.totalSpending,
            dataIndex: "totalSpending",
            render: (totalSpending) => (
                <div className="px-5">{totalSpending ?? 0}</div>
            ),
        },
        {
            title: Messages.province,
            dataIndex: "province",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={CUSTOMER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.customerSince,
            dataIndex: "createdAt",
        },
        {
            title: Messages.updatedDate,
            dataIndex: "updatedAt",
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (customer: ICustomer) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, customer)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterInput = mapCustomerFilterBodyToServer(filterBody);
        return CustomerAPI.list(searchRef.current, pagingData, filterInput);
    };

    const onClickTableAction = (actionId: string, customer: ICustomer) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: customer.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        history.push(Path.CUSTOMER_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const onFilter = (values: any) => {
        setFilterBody(values);
        tableRef.current.refresh();
    };

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allCustomers}
                onClickFilter={() => setOpenFilter(true)}
                onClickExport={() => setOpenExport(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
                isFiltered={!!filterBody}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.customer ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 200 }}
            />
            {openFilter && (
                <CustomerFilterModal
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    onFilter={onFilter}
                />
            )}
            {openExport && (
                <CustomerExportModal
                    open={openExport}
                    onClose={() => setOpenExport(false)}
                />
            )}
        </div>
    );
};

export default CustomerTable;
