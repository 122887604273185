import { InputText } from "d-react-components";
import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { map } from "lodash";
import Image from "../../../common/Image";
import { IWorksheetMileage } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";

interface IWorksheetMileageView {
    mileage: IWorksheetMileage;
}

const WorksheetMileageView = ({ mileage }: IWorksheetMileageView) => {
    const [imageToView, setImageToView] = useState<any[]>([]);

    return (
        <div className="flex-column">
            <Image
                className="mt-3 image-square-medium"
                src={mileage?.proves?.[0] ?? ""}
                onClick={() => setImageToView(mileage?.proves)}
            />
            <InputText
                value={mileage?.mile}
                label={Messages.mile}
                className="mt-3 "
                disabled
            />
            <ModalGateway>
                {imageToView && imageToView.length > 0 ? (
                    <Modal onClose={() => setImageToView([])}>
                        <Carousel
                            views={map(imageToView, (file) => ({
                                source: file,
                                caption: "",
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
};

export default WorksheetMileageView;
