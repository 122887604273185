import { Modal } from "d-react-components";
import React, { useContext, useState } from "react";
import { MediaContext } from "../../context/media";
import Messages from "../../languages/Messages";
import MediaManagerTreeFolder from "./MediaManagerTreeFolder";

const INIT_TREE_FOLDER = {
    folders: [],
    folderId: null,
    isSelectMode: true,
};

const MediaManagerTreeFolderModal = ({ open, onClose, onSave }: any) => {
    const { folders } = useContext(MediaContext);
    const [state, setState] = useState(INIT_TREE_FOLDER);

    const onChangeState = (newState: any) => {
        setState({ ...state, ...newState });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.moveToFolder}
            className="md-modal media-manager__modal-select-folder"
            onSave={() => onSave(state.folderId)}
        >
            <MediaContext.Provider value={{ ...state, folders, onChangeState }}>
                <MediaManagerTreeFolder />
            </MediaContext.Provider>
        </Modal>
    );
};

export default MediaManagerTreeFolderModal;
