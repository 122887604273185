import {
    InputText,
    Modal,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import ProductAPI from "../../../api/product/ProductAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { CATEGORY_STATUSES } from "../../../constant/category";
import Messages from "../../../languages/Messages";
import SelectMediaManagerButton from "../../media/selectmedia/SelectMediaManagerButton";

interface IProps {
    open: boolean;
    category: any;
    onClose: () => any;
    onSuccess: () => any;
}

const CategorySchema = Yup.object().shape({
    featured: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    order: Yup.number().required("Required"),
});

const ProductCategoryEditModal = ({
    open,
    onClose,
    onSuccess,
    category,
}: IProps) => {
    const isEdit = !!category?.id;
    const categoryForm = useFormik({
        initialValues: {
            name: category?.name ?? "",
            status: category?.status,
            order: category?.order ?? "",
            featured: category?.featured ?? "",
        },
        validationSchema: CategorySchema,
        onSubmit: (values) => {
            if (isEdit) {
                onEdit(values);
            } else {
                onCreateNew(values);
            }
        },
    });

    const onCreateNew = (body: any) =>
        Progress.show(
            { method: ProductAPI.categoryCreate, params: [body] },
            () => {
                onClose();
                onSuccess();
                Notifications.showSuccess(Messages.createCategorySuccessfully);
            }
        );

    const onEdit = (body: any) =>
        Progress.show(
            { method: ProductAPI.categoryEdit, params: [category?.id, body] },
            () => {
                onClose();
                onSuccess();
                Notifications.showSuccess(Messages.updateCategorySuccessfully);
            }
        );
    const inputClassName = "mt-3";
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={isEdit ? Messages.updateCategory : Messages.addNewCategory}
            onSave={() => categoryForm.handleSubmit()}
        >
            <InputSelectForm
                keyData="status"
                form={categoryForm}
                dataSource={CATEGORY_STATUSES}
                required
                className={inputClassName}
            />
            <InputTextForm
                keyData="name"
                form={categoryForm}
                className={inputClassName}
            />
            <InputText
                label={Messages.order}
                placeholder={Messages.order}
                value={categoryForm.values.order}
                error={categoryForm.errors.order as string}
                onChange={(event) =>
                    categoryForm.setFieldValue(
                        "order",
                        parseInt(event.target.value, 10)
                    )
                }
                name="order"
                className="mt-3"
            />
            <div className="flex-column">
                <label className="mt-3">{Messages.featuredImage}</label>
                {categoryForm.values.featured && (
                    <img
                        src={categoryForm.values.featured}
                        className="image-square-large"
                    />
                )}
                <SelectMediaManagerButton
                    isSingleSelect
                    onSelect={([value]: any) => {
                        categoryForm.setFieldValue("featured", value?.url);
                    }}
                    className="mt-3"
                />
                <ViewTextError error={categoryForm.errors.featured} />
            </div>
        </Modal>
    );
};

export default ProductCategoryEditModal;
