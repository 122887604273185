/* eslint-disable react/jsx-no-duplicate-props */
import { Icon, InputTextSearch } from "d-react-components";
import GoogleMapReact from "google-map-react";
import { isEmpty, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import API from "../../api/API";
import { API_GOOGLE_KEY } from "../../constant/app";
import Messages from "../../languages/Messages";

const LONGTITUDE_DEFAULT = 13.7248936;
const LATITUDE_DEFAULT = 100.4930264;

const DEFAULT_ZOOM = 17;

interface IAddressMapSelect {
    location: any;
    onChange: (location: any) => void;
}
const AddressMapSelect = ({ location, onChange }: IAddressMapSelect) => {
    const [selectedLocation, setSelectedLocation] = useState({
        ...location,
        zoom: DEFAULT_ZOOM,
    });
    const [addressSearch, setAddressSearch] = useState("");
    const mapsRef = useRef<any>(null);
    const mapRef = useRef<any>(null);
    const markerCenter = useRef<any>(null);
    const isChangeLocationOutside = useRef(true);
    useEffect(() => {
        isChangeLocationOutside.current = true;
        setCenterMap(location);
    }, [location]);

    const onChangeLocation = (locationParam: any) => {
        if (isChangeLocationOutside.current) {
            isChangeLocationOutside.current = false;
            return;
        }
        if (
            selectedLocation?.zoom &&
            selectedLocation.zoom !== locationParam.zoom
        ) {
            setSelectedLocation(locationParam);
            return;
        }
        const result = {
            latitude: (locationParam?.center?.lat ?? 0) % 90,
            longitude: (locationParam?.center?.lng ?? 0) % 180,
            zoom: locationParam.zoom,
        };

        setSelectedLocation(locationParam);
        onChange(result);
    };

    const handleApiLoaded = (map: any, maps: any) => {
        mapRef.current = map;
        mapsRef.current = maps;
        setCenterMap(location);
    };

    const setCenterMap = (location: any) => {
        if (!mapRef.current) {
            return;
        }
        mapRef.current.setOptions({
            disableDoubleClickZoom: true,
            fullscreenControlOptions: false,
            fullscreenControl: false,
            zoom: selectedLocation?.zoom ?? DEFAULT_ZOOM,
            center: {
                lat: location?.latitude ? location.latitude : LATITUDE_DEFAULT,
                lng: location?.longitude
                    ? location.longitude
                    : LONGTITUDE_DEFAULT,
            },
            gestureHandling: "cooperative",
            scrollwheel: false,
        });
    };

    const renderCenterMarker = () => {
        const heightMap = selectedLocation?.size?.height;
        const widthMap = selectedLocation?.size?.width;
        const marginTop = heightMap ? heightMap / 2 - 40 : "41%";
        const marginRight = widthMap ? widthMap / 2 - 20 : "48%";
        return (
            <Icon
                name="location_on"
                className="text-primary address__pin-location"
                size="xx-large"
            />
        );
    };

    const addMarkerCenter = (location: any) => {
        if (!mapsRef.current || !mapRef.current) {
            return;
        }
        if (markerCenter.current) {
            markerCenter.current.setMap(null);
        }
        markerCenter.current = new mapsRef.current.Marker({
            position: {
                lat: location?.latitude ? location.latitude : LATITUDE_DEFAULT,
                lng: location?.longitude
                    ? location.longitude
                    : LONGTITUDE_DEFAULT,
            },
            map: mapRef.current,
        });
    };

    const onLoadLocation = (address: any) => {
        if (isEmpty(address)) {
            return;
        }
        API.getLocationFromAddress(address).then((res) => {
            const locationResult = res?.data?.results?.[0]?.geometry?.location;
            if (locationResult) {
                onChange({
                    latitude: locationResult.lat,
                    longitude: locationResult.lng,
                    zoom: selectedLocation?.zoom ?? DEFAULT_ZOOM,
                });
            }
        });
    };

    return (
        <div style={{ height: 350, width: "100%", position: "relative" }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: API_GOOGLE_KEY,
                }}
                defaultCenter={{
                    lat: location?.latitude
                        ? location.latitude
                        : LATITUDE_DEFAULT,
                    lng: location?.longitude
                        ? location.longitude
                        : LONGTITUDE_DEFAULT,
                }}
                defaultZoom={DEFAULT_ZOOM}
                onChange={onChangeLocation}
                onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                }
                yesIWantToUseGoogleMapApiInternals
            />

            {renderCenterMarker()}

            <PlacesAutocomplete
                value={addressSearch}
                onChange={(address) => setAddressSearch(address)}
                onSelect={(address) => {
                    setAddressSearch(address);
                    onLoadLocation(address);
                }}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <div className="address__search-container">
                        <InputTextSearch
                            {...getInputProps()}
                            placeholder={Messages.searchGoogleLocationMap}
                        />
                        <div>
                            {loading && <div>Loading...</div>}
                            {map(suggestions, (suggestion) => {
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className: "text p-1 hover-pointer",
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
};

export default AddressMapSelect;
