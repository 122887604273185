/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import {
    Button,
    Dropdown,
    Notifications,
    StringUtils,
} from "d-react-components";
import { filter, isEmpty, map } from "lodash";
import React, { useContext, useState } from "react";
import { PRODUCT_TYPE } from "../../../../constant/product";
import { OrderCreateContext } from "../../../../context/order";
import { IVoucher, IVoucherOrderCreate } from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";
import OrderVoucherSelectDrawer from "./OrderVoucherSelectDrawer";
import {
    VOUCHER_OWNERSHIP_TYPES,
    VoucherOwnershipType,
} from "../../../../constant/voucher";

export interface IOrderCreateSelectVoucherButtonProps {
    [key: string]: any;
}

export const addIdAndAmountToListVoucher = (
    listToAdd: Array<IVoucherOrderCreate>,
    isInvoice?: boolean
): Array<IVoucherOrderCreate> => {
    const transList = map(listToAdd, (voucher) => {
        const { product, quantity } = voucher;

        let amount = product?.salePrice * quantity;

        if (isInvoice) {
            amount *= 1.07;
        }

        const newVoucher = {
            id: StringUtils.getUniqueID(),
            isVoucher: true,
            amount,
            ...voucher,
        };
        return newVoucher;
    });
    return transList;
};

const OrderCreateSelectVoucherButton: React.FC<
    IOrderCreateSelectVoucherButtonProps
> = () => {
    const { orderForm } = useContext(OrderCreateContext);
    const { values, setFieldValue } = orderForm || {};
    const { customer, productVouchers = [], products } = values ?? {};
    const [voucherInputType, setVoucherInputType] =
        useState<VoucherOwnershipType | null>(null);

    const defaultProducts = filter(
        products,
        (item) => item.productType === PRODUCT_TYPE.DEFAULT_PRODUCT
    );

    const handleUpdateVoucher = (
        applyVouchers: IVoucherOrderCreate[],
        anonymousVouchers: IVoucher[]
    ) => {
        if (applyVouchers.length) {
            const transList = addIdAndAmountToListVoucher(
                applyVouchers,
                orderForm?.values?.isInvoice
            );
            setFieldValue("productVouchers", [
                ...transList,
                ...productVouchers,
            ]);
        }

        if (anonymousVouchers.length) {
            const transList = addIdAndAmountToListVoucher(
                map(anonymousVouchers, (item) => ({ ...item, quantity: 1 })),
                orderForm?.values?.isInvoice
            );
            setFieldValue("anonymousVouchers", transList);
        }
    };

    const onSelectVoucherType = (action: any) => {
        if (isEmpty(defaultProducts)) {
            return Notifications.showError(
                Messages.youNeedAddAtLeastOneDefaultProduct
            );
        }

        setVoucherInputType(action.id);
    };

    return (
        <React.Fragment>
            <Dropdown
                dataSource={VOUCHER_OWNERSHIP_TYPES}
                Messages={Messages}
                onClick={onSelectVoucherType}
            >
                <Button
                    variant="outline"
                    className="mt-3"
                    content={Messages.selectVoucher}
                />
            </Dropdown>
            {!!voucherInputType && (
                <OrderVoucherSelectDrawer
                    inputType={voucherInputType}
                    open={!!voucherInputType}
                    onClose={() => setVoucherInputType(null)}
                    customer={customer}
                    products={defaultProducts}
                    onChange={(productVouchers = [], anonymousVouchers = []) =>
                        handleUpdateVoucher(productVouchers, anonymousVouchers)
                    }
                    voucherSelected={productVouchers}
                />
            )}
        </React.Fragment>
    );
};

export default OrderCreateSelectVoucherButton;
