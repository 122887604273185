import { Select } from "d-react-components";
import React, { useContext } from "react";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { USER_STATUSES } from "../../../constant/user";
import { UserCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";
import UserInputForm from "./UserInputForm";

const UserCrudInfo = () => {
    const { userForm } = useContext(UserCrudContext);

    return (
        <div className="bg-white p-4">
            <UploadAvatar
                onChange={(value: any) =>
                    userForm.setFieldValue("avatar", value?.url)
                }
                value={userForm?.values?.avatar}
            />
            <div className="row ">
                <UserInputForm keyData="username" />
                <UserInputForm keyData="firstName" />
                <UserInputForm keyData="lastName" />
                <UserInputForm keyData="nickName" />
                <UserInputForm keyData="companyId" />
                <UserInputForm keyData="email" />
                <Select
                    label={Messages.status}
                    value={userForm.values?.status}
                    error={userForm.errors?.status}
                    className="col-6 mt-3"
                    dataSource={USER_STATUSES}
                    getLabel={(item) => (Messages as any)[item?.label]}
                    onChange={(value) =>
                        userForm.setFieldValue("status", value)
                    }
                />
            </div>
        </div>
    );
};

export default UserCrudInfo;
