import { Icon, ObjectUtils, Select } from "d-react-components";
import { debounce, find, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import ProductAPI from "../../../api/product/ProductAPI";
import Image from "../../../common/Image";
import { IProduct } from "../../../interfaces/product";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";

export interface IProductSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const ProductSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    filterSource = {},
    ...selectProps
}: IProductSelect<IProduct>) => {
    const [productList, setProductList] = useState<IProduct[]>(
        isArray(value) ? value : [value]
    );

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setProductList([]);
        } else {
            ProductAPI.list(
                textSearch,
                { pageIndex: 1, pageSize: 50 },
                filterSource
            ).then((res: any) => {
                const productRes = res?.data?.data?.product ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterProduct = map(
                    [...selectedValue, ...productRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqProduct = uniqBy(filterProduct, (item) => item.id);
                setProductList(uniqProduct);
            });
        }
    }, 500);

    const onChangeValue = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    ObjectUtils.findItemFromId(productList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(productList, values));
    };

    const productValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id ?? null;
    }, [value]);

    const renderSelectLabel = (item: IProduct) => (
        <div className="p-2 d-flex">
            <Image
                src={item?.thumbnail?.thumbnail ?? "/images/placeholder.png"}
                className="image-square-small"
            />
            <div className="ml-2 flex-column">
                <h5>{(item as any)?.[Messages.getLanguage()]?.name}</h5>
                <small>
                    {Messages.sku}: {item?.SKU}
                </small>
            </div>
        </div>
    );

    const renderSelectTag = (tagProps: any) => {
        let product: any = value;
        if (multiple) {
            product = find(value, (item: any) => item.id === tagProps.value);
        }

        return (
            <div className="text-white bg-primary px-3 py-1 mr-2 small d-flex">
                {product?.[Messages.getLanguage()]?.name}
                <Icon
                    name="clear"
                    onClick={tagProps.onClose}
                    className="text-white small ml-2 cursor-pointer"
                />
            </div>
        );
    };

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.product}
            dataSource={productList}
            getKey={(item) => item?.id + item?.name}
            value={productValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            filterOption={false}
            getLabel={renderSelectLabel}
            tagRender={renderSelectTag}
            optionLabelProp="label"
            getOptionProps={(item) =>
                ({
                    label: item?.[Messages.getLanguage()]?.name,
                } as any)
            }
            {...selectProps}
        />
    );
};

export default ProductSelect;
