import React from "react";
import { IBillingAddress, IServiceAddress } from "../interfaces/address";
import { ICustomer } from "../interfaces/customer";

interface ICustomerCreateState {
    customerForm: any;
}
const customerCreateState: ICustomerCreateState = { customerForm: null };
export const CustomerCreateContext = React.createContext(customerCreateState);

interface ICustomerDetailState {
    customerInfo: ICustomer;
    customerServiceAdds: IServiceAddress[];
    setCustomerInfo: (param: any) => any;
    loadCustomerServiceAdds: () => any;

    customerBillingAdds: IBillingAddress[];
    loadCustomerBillingAdds: () => any;
}
const customerDetailState: ICustomerDetailState = {
    customerInfo: {} as any,
    customerServiceAdds: [],
    setCustomerInfo: () => {},
    loadCustomerServiceAdds: () => {},
    customerBillingAdds: [],
    loadCustomerBillingAdds: () => {},
};
export const CustomerDetailContext = React.createContext(customerDetailState);
