import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import VoucherAPI from "../../../api/voucher/VoucherAPI";

import { IFieldSelect } from "../../../interfaces/select";
import { IVoucher } from "../../../interfaces/voucher";

export interface IVoucherSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const VoucherSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    filterSource = {},
    ...selectProps
}: IVoucherSelect<IVoucher>) => {
    const [voucherList, setVoucherList] = useState<IVoucher[]>(
        isArray(value) ? value : [value]
    );

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setVoucherList([]);
        } else {
            VoucherAPI.list(
                textSearch,
                {
                    pageIndex: 1,
                    pageSize: 50,
                },
                filterSource
            ).then((res: any) => {
                const voucherRes = res?.data?.data?.voucher ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const uniqVoucher = uniqBy(
                    [...selectedValue, ...voucherRes],
                    (item) => item.id
                );
                setVoucherList(uniqVoucher);
            });
        }
    }, 500);

    const onChangeValue = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    ObjectUtils.findItemFromId(voucherList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(voucherList, values));
    };

    const voucherValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id ?? null;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            dataSource={voucherList}
            getLabel={(item) => item.code}
            onChange={onChangeValue}
            showSearch
            className={className}
            multiple={multiple}
            value={voucherValue}
            filterOption={false}
            {...selectProps}
        />
    );
};

export default VoucherSelect;
