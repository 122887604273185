import { Button, ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { ICustomer } from "../../../interfaces/customer";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";
import CustomerCreateModal from "../create/CustomerCreateModal";

export interface ICustomerSearchSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
    showAddNew?: boolean;
}

const CustomerSelect = ({
    value = [],
    className,
    onChange,
    multiple,
    showAddNew = true,
    hidden,
    ...selectProps
}: ICustomerSearchSelect<ICustomer>) => {
    const [openAdd, setOpenAdd] = useState(false);

    const arrayValueFormat = useMemo(() => {
        if (!value) return [];
        if (isArray(value)) return value;
        return [value];
    }, [value]);

    const [customerList, setCustomerList] =
        useState<ICustomer[]>(arrayValueFormat);

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setCustomerList([]);
        } else {
            CustomerAPI.searchCustomerByEmailPhone(textSearch).then((res) => {
                const customerRes = res?.data?.data ?? [];

                const filterCustomer = map(
                    [...arrayValueFormat, ...customerRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqCustomer = uniqBy(filterCustomer, (item) => item.id);

                setCustomerList(uniqCustomer);
            });
        }
    }, 500);

    const onSelectCustomer = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    ObjectUtils.findItemFromId(customerList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(customerList, values));
    };

    const customerValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id ?? null;
    }, [value]);

    return (
        <div className={className} hidden={hidden}>
            <div className="d-flex flex-center justify-content-between">
                <label>{Messages.searchCustomer}</label>
                {showAddNew && (
                    <Button variant="trans" onClick={() => setOpenAdd(true)}>
                        {Messages.addNew}
                    </Button>
                )}
            </div>

            <Select
                placeholder={Messages.pleaseSelect}
                onSearch={onChangeTextSearch}
                dataSource={customerList}
                getLabel={(item) => `${item?.firstName} ${item?.lastName}`}
                onChange={onSelectCustomer}
                value={customerValue}
                showSearch
                filterOption={false}
                multiple={multiple}
                {...selectProps}
            />
            {openAdd && (
                <CustomerCreateModal
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    onSuccess={onChange}
                />
            )}
        </div>
    );
};

export default CustomerSelect;
