import { every, find, toLower } from "lodash";
import { useContext, useEffect, useState } from "react";
import { PERMISSION_TYPE } from "../constant/user";
import { AppStateContext } from "../context/app";

export const useUserPermission = (moduleKey: string, accessType?: string) => {
    const { me } = useContext(AppStateContext);
    const { role } = me ?? {};
    const [isGrantPermission, setIsGrantPermission] = useState(false);

    const getGrantPermission = () => {
        if (!role) return false;
        if (role.permissionType === PERMISSION_TYPE.ALL) {
            return true;
        }
        const modulePermission = find(
            role?.permissions,
            (item) => toLower(item.key) === toLower(moduleKey)
        );

        if (!modulePermission) return false;

        if (!accessType) {
            const isNotGrantPermission = every(
                Object.keys(modulePermission?.access),
                (key) => !modulePermission?.access[key]
            );

            return !isNotGrantPermission;
        }

        return modulePermission?.access?.[accessType];
    };

    useEffect(() => {
        setIsGrantPermission(getGrantPermission());
    }, [role]);

    return isGrantPermission;
};
