import { gql } from "@apollo/client";
import { map } from "lodash";
import { CATEGORY_STATUS } from "../../constant/category";
import { mapCateFromSer } from "../../interfaces/category";
import API from "../API";
import { F_CATEGORIES, F_CATEGORY } from "./CategoryFragment";

const listConvertor = {
    fromServer: (res: any) => ({
        data: {
            data: {
                ...res.data.data,
                category: map(res?.data?.data?.category ?? [], mapCateFromSer),
            },
        },
    }),
};

const detailConvertor = {
    fromServer: (res: any) => mapCateFromSer(res?.data?.category),
};

const detailMetaDataConvertor = {
    fromServer: (res: any) => ({
        criteriaList: res?.data?.data?.criteria ?? [],
    }),
};

const CategoryAPI = {
    list: (q: string, filter: any, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_CATEGORIES}
                query categories(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: CategoryFilter
                ) {
                    data: categories(
                        search: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_CATEGORIES
                    }
                }
            `,
            variables: {
                q,
                page: paging?.pageIndex,
                limit: paging?.pageSize,
                filter,
            },
            fetchPolicy: "no-cache",
        }),

    activeCategoryList: () =>
        CategoryAPI.list(
            "",
            { status: CATEGORY_STATUS.ACTIVE },
            { pageIndex: 1, pageSize: 1000 }
        ),

    create: (body: any) =>
        API.mutate({
            mutation: gql`
                mutation createCategory($body: CategoryInput) {
                    createCategory(input: $body) {
                        id
                    }
                }
            `,
            variables: { body },
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_CATEGORY}
                query detail($id: ID!) {
                    category(id: $id) {
                        ...F_CATEGORY
                    }
                }
            `,
            variables: { id },
        }),

    update: (id: string, body: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_CATEGORY}
                mutation updateCategory($id: ID!, $body: CategoryInput) {
                    category: updateCategory(id: $id, input: $body) {
                        ...F_CATEGORY
                    }
                }
            `,
            variables: { body, id },
        }),

    delete: (id: string) => API.delete("destroyCategory", id),

    detailMetaData: () =>
        API.withConverter(detailMetaDataConvertor).query({
            query: gql`
                query detailMetaData {
                    data: criterias(q: "", page: 1, limit: 100) {
                        criteria {
                            id
                            label: name
                        }
                    }
                }
            `,
        }),
};
export default CategoryAPI;
