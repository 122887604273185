import { ObjectUtils, StringUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { OrderCreateContext } from "../../../context/order";
import OrderServiceAddress from "../common/OrderServiceAddress";

const OrderCreateServiceAdd = () => {
    const { orderForm } = useContext(OrderCreateContext);
    const { addresses = [], customer } = orderForm.values;
    const customerId = customer?.id;

    const onUpdateAddress = (addressParam: any) => {
        const value = ObjectUtils.updateArrayById(
            addresses as any,
            addressParam
        );
        orderForm.setFieldValue("addresses", value);
    };

    const onAddAddress = (addressParam: any) => {
        const value = [
            ...addresses,
            ...map(addressParam, (item) => ({
                ...item,
                id: StringUtils.getUniqueID(),
            })),
        ];
        orderForm.setFieldValue("addresses", value);
    };

    const onDeleteAddress = (addressId: string) => {
        const value = ObjectUtils.removeArrayById(addresses as any, addressId);
        orderForm.setFieldValue("addresses", value);
    };

    return (
        <OrderServiceAddress
            onChangeAddress={onUpdateAddress}
            onAddAddress={onAddAddress}
            onDeleteAddress={onDeleteAddress}
            addresses={addresses}
            customerId={customerId}
        />
    );
};

export default OrderCreateServiceAdd;
