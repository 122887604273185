import { DateInput } from "d-react-components";
import React, { useContext } from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { CUSTOMER_STATUSES, CUSTOMER_TYPES, GENDERS } from "../../../constant/customer";
import { AppStateContext } from "../../../context/app";
import Messages from "../../../languages/Messages";

const CustomerPersonalInfoForm = ({ customerForm }: any) => {
    const { countryList } = useContext(AppStateContext);
    const inputClassName = "col-6 mt-3";
    return (
        <div className="grid-container ">
            <div className="row mt-3">
                <div className={inputClassName}>
                    <UploadAvatar
                        onChange={(value: any) =>
                            customerForm.setFieldValue("avatar", value?.url)
                        }
                        value={customerForm?.values?.avatar}
                    />
                </div>
                <InputSelectForm
                    keyData="typeOfCustomer"
                    form={customerForm}
                    dataSource={CUSTOMER_TYPES}
                    required
                    className={inputClassName}
                />
            </div>
            <div className="row mt-3">
                <InputSelectForm
                    keyData="status"
                    form={customerForm}
                    dataSource={CUSTOMER_STATUSES}
                    required
                    className={inputClassName}
                />
                <InputTextForm
                    className={inputClassName}
                    keyData="firstName"
                    form={customerForm}
                    required
                />
                <InputTextForm
                    className={inputClassName}
                    keyData="lastName"
                    form={customerForm}
                    required
                />
                <InputTextForm
                    keyData="nickName"
                    form={customerForm}
                    className={inputClassName}
                />
                <InputTextForm
                    keyData="company"
                    form={customerForm}
                    className={inputClassName}
                />
                <InputSelectForm
                    keyData="gender"
                    form={customerForm}
                    dataSource={GENDERS}
                    className={inputClassName}
                />
                <DateInput
                    className={inputClassName}
                    label={Messages.dateOfBirth}
                    name="dateOfBirth"
                    error={customerForm.errors.dateOfBirth}
                    value={customerForm.values.dateOfBirth}
                    onChange={(value) =>
                        customerForm.setFieldValue("dateOfBirth", value)
                    }
                    placeholder={Messages.dateOfBirth}
                    format="DD/MM/YYYY"
                />
                <InputSelectForm
                    keyData="nationality"
                    form={customerForm}
                    dataSource={countryList}
                    getLabel={(item: any) => item.name}
                    className={inputClassName}
                />
                <InputTextForm
                    keyData="email"
                    form={customerForm}
                    className={inputClassName}
                />
                <InputTextForm
                    keyData="phone"
                    form={customerForm}
                    required
                    className={inputClassName}
                />
            </div>
        </div>
    );
};

export default CustomerPersonalInfoForm;
