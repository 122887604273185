import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import BannerAPI from "../../../api/banner/BannerAPI";

import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import TableAction from "../../../common/TableActions";
import { BANNER_STATUSES } from "../../../constant/banner";
import { CATEGORY_STATUSES } from "../../../constant/category";
import { IBanner } from "../../../interfaces/banner";

import Messages from "../../../languages/Messages";
import Path from "../../Path";

const BannerTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const history = useHistory();
    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "bannerId",
            render: (bannerId, item) => (
                <AppLink
                    to={generatePath(Path.BANNER_DETAIL, {
                        bannerId: item.id,
                    })}
                    className="p-3"
                >
                    {bannerId}
                </AppLink>
            ),
        },
        {
            title: Messages.title,
            dataIndex: "title",
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus status={status} listStatus={BANNER_STATUSES} />
            ),
        },

        {
            title: Messages.position,
            dataIndex: "position",
        },
        {
            title: Messages.effectiveDate,
            dataIndex: "effectiveDate",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.expireAt,
            dataIndex: "expiryDate",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (user) => user?.firstName,
        },

        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (banner: IBanner) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, banner)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return BannerAPI.list(searchRef.current, pagingData);
    };

    const onClickTableAction = (actionId: string, banner: IBanner) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.BANNER_DETAIL, {
                        bannerId: banner.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        history.push(Path.BANNER_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allBanner}
                // onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.banner ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 200 }}
            />
        </div>
    );
};

export default BannerTable;
