import { Select, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudBrand = () => {
    const { productForm, brands } = useContext(ProductDetailContext);
    const { brand } = productForm.values;

    return (
        <ViewCollapse className=" mt-3" label={Messages.brand} required>
            <div>
                <Select
                    dataSource={brands}
                    onChange={(value) =>
                        productForm.setFieldValue("brand", value)
                    }
                    placeholder={Messages.selectBrand}
                    value={brand}
                    error={productForm.errors.brand}
                />
            </div>
        </ViewCollapse>
    );
};

export default ProductCrudBrand;
