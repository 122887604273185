import { useContext } from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import { OrderCreateContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const OrderCreateNotes = () => {
    const { orderForm } = useContext(OrderCreateContext);
    return (
        <div className="card-container mt-3 p-4">
            <InputTextForm
                multiple
                label={Messages.orderNoteForInternal}
                keyData="note"
                form={orderForm}
                className="col-12"
            />
        </div>
    );
};

export default OrderCreateNotes;
