import { isEmpty } from "lodash";
import React from "react";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const Image = ({ src, ...rest }: ImageProps) => (
    <img src={!isEmpty(src) ? src : "/images/placeholder.png"} {...rest} />
);

export default Image;
