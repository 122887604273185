import { gql } from "@apollo/client";
import { F_ADDRESS, F_BILLING } from "../address/AddressFragment";
import { F_CRITERIA } from "../criteria/CriteriaFragment";
import { F_CUSTOMER } from "../customer/CustomerFragment";
import { F_PAGINATION } from "../Fragment";
import { F_USER, F_USER_ITEM } from "../user/UserFragment";
import { F_WORKSHEET_ITEM } from "../worksheet/WorksheetFragment";

export const F_BOOKING_ITEM = gql`
    ${F_USER}
    ${F_BILLING}
    fragment F_BOOKING_ITEM on Booking {
        id
        bookingNo
        status
        cost
        customer {
            id
            firstName
            lastName
            avatar
        }
        quantity
        serviceDate
        voucher
        createBy {
            ...F_USER
        }
        createdAt
        source {
            id
            name
        }
        billing {
            ...F_BILLING
        }
    }
`;

export const F_BOOKINGS = gql`
    ${F_BOOKING_ITEM}
    ${F_PAGINATION}
    fragment F_BOOKINGS on Bookings {
        booking {
            ...F_BOOKING_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_BOOKING_PRODUCT = gql`
    fragment F_BOOKING_PRODUCT on Product {
        id
        th {
            name
        }
        en {
            name
        }
        productId
        thumbnail {
            thumbnail
        }
        regularPrice
        salePrice
        SKU
    }
`;

export const F_BOOKING_SCHEDULE_SERVICE = gql`
    fragment F_BOOKING_SCHEDULE_SERVICE on ScheduleService {
        id
        date
        remark
    }
`;

export const F_BOOKING = gql`
    ${F_ADDRESS}
    ${F_BILLING}
    ${F_USER_ITEM}
    ${F_CUSTOMER}
    ${F_BOOKING_PRODUCT}
    ${F_USER}
    ${F_WORKSHEET_ITEM}
    ${F_BOOKING_SCHEDULE_SERVICE}
    fragment F_BOOKING on Booking {
        id
        bookingNo
        customer {
            ...F_CUSTOMER
        }
        addresses {
            ...F_ADDRESS
            remark
        }
        billing {
            ...F_BILLING
        }
        cost
        quantity
        bookingService {
            product {
                ...F_BOOKING_PRODUCT
            }
            openingCost
            quantity
        }
        serviceDate
        voucher
        notes
        source {
            id
            name
        }
        status
        PIC {
            ...F_USER_ITEM
        }

        activity {
            createBy {
                ...F_USER
            }
            message
            createdAt
        }
        createBy {
            ...F_USER
        }
        createdAt
        worksheet {
            ...F_WORKSHEET_ITEM
        }

        scheduleServices {
            ...F_BOOKING_SCHEDULE_SERVICE
        }
        contact {
            firstName
            lastName
            email
            phone
        }
    }
`;

export const F_BOOKING_PRODUCT_CATEGORY = gql`
    ${F_CRITERIA}
    fragment F_BOOKING_PRODUCT_CATEGORY on Category {
        id
        name
        priority

        workProgress {
            id
            value
        }
        deliveryProgress {
            id
            value
        }
        priorChecking {
            checkList {
                id
                value
            }
            criterias {
                ...F_CRITERIA
            }
        }
        processQty
    }
`;

export const F_BOOKING_PRODUCT_BY_NO = gql`
    ${F_BOOKING_PRODUCT_CATEGORY}
    fragment F_BOOKING_PRODUCT_BY_NO on Product {
        id
        th {
            name
        }
        en {
            name
        }
        productId
        thumbnail {
            thumbnail
        }
        regularPrice
        salePrice
        SKU
        service {
            ...F_BOOKING_PRODUCT_CATEGORY
        }
        productType
    }
`;

export const F_BOOKING_BY_NO = gql`
    ${F_ADDRESS}
    ${F_BILLING}
    ${F_USER_ITEM}
    ${F_CUSTOMER}
    ${F_BOOKING_PRODUCT_BY_NO}

    fragment F_BOOKING_BY_NO on Booking {
        id
        bookingNo
        customer {
            ...F_CUSTOMER
        }
        addresses {
            ...F_ADDRESS
            remark
        }
        billing {
            ...F_BILLING
        }
        cost
        quantity
        bookingService {
            product {
                ...F_BOOKING_PRODUCT_BY_NO
            }
            openingCost
            quantity
        }
        serviceDate
        voucher
        notes
        source {
            name
        }
        status
        PIC {
            ...F_USER_ITEM
        }
    }
`;
