import { Button } from "d-react-components";
import { useContext, useState } from "react";
import { OrderCreateContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import OrderCrudContactModal from "../common/OrderCrudContactModal";

const OrderCreateContact = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const { orderForm } = useContext(OrderCreateContext);
    const { contact } = orderForm.values;
    const { firstName, lastName, phone, email } = contact ?? {};
    return (
        <div className="card-container p-4 mt-3" hidden={!contact}>
            <div className="flex-center justify-content-between">
                <label>{Messages.contact}</label>
                <Button variant="trans" onClick={() => setOpenEdit(true)}>
                    {Messages.edit}
                </Button>
            </div>
            <div className="flex-column">
                <text>{`${firstName} ${lastName}`}</text>
                <text>{email}</text>
                <text>{phone}</text>
            </div>
            {openEdit && (
                <OrderCrudContactModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    defaultValue={contact}
                    onChange={(value) =>
                        orderForm.setFieldValue("contact", value)
                    }
                />
            )}
        </div>
    );
};

export default OrderCreateContact;
