import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { BLOCK_TYPE } from "../constant/block";
import { AppStateContext } from "../context/app";
import BannerCrud from "./banner/crud/BannerCrud";
import BannerTable from "./banner/table/BannerTable";
import BlockDetail from "./block/detail/BlockDetail";
import BookingCreate from "./bookings/create/BookingCreate";
import BookingDetail from "./bookings/detail/BookingDetail";
import BookingTable from "./bookings/table/BookingTable";
import CategoryDetail from "./category/detail/CategoryDetail";
import CategoryTable from "./category/table/CategoryTable";
import CriteriaCrud from "./criteria/crud/CriteriaCrud";
import CriteriaTable from "./criteria/table/CriteriaTable";
import CustomerCreate from "./customer/create/CustomerCreate";
import CustomerDetail from "./customer/detail/CustomerDetail";
import CustomerTable from "./customer/table/CustomerTable";
import InventoryTable from "./inventory/table/InventoryTable";
import FaqManager from "./faq/FaqManager";
import MediaManager from "./media/MediaManager";
import NewsCategoryTable from "./news/category/NewsCategoryTable";
import NewsCrud from "./news/crud/NewsCrud";
import NewsTable from "./news/table/NewsTable";
import OrderCreate from "./order/create/OrderCreate";
import OrderDetail from "./order/detail/OrderDetail";
import OrderTable from "./order/table/OrderTable";
import Path from "./Path";
import PaymentMethodCrud from "./paymentmethod/crud/PaymentMethodCrud";
import PaymentMethodTable from "./paymentmethod/table/PaymentMethodTable";
import ProductCategoryTable from "./product/category/ProductCategoryTable";
import ProductCrud from "./product/crud/ProductCrud";
import ProductTable from "./product/table/ProductTable";
import SmartSupport from "./smartSupport/SmartSupport";
import UserCrud from "./user/crud/UserCrud";
import UserRoleCrud from "./user/role/UserRoleCrud";
import UserRoleTable from "./user/role/UserRoleTable";
import UserTable from "./user/table/UserTable";
import VoucherCreate from "./voucher/create/VoucherCreate";
import VoucherDetail from "./voucher/detail/VoucherDetail";
import VoucherTable from "./voucher/table/VoucherTable";
import WarehouseCrud from "./warehouse/crud/WarehouseCrud";
import WarehouseTable from "./warehouse/table/WarehouseTable";
import WorksheetCreate from "./worksheet/create/WorksheetCreate";
import WorksheetDetail from "./worksheet/detail/WorksheetDetail";
import WorksheetTable from "./worksheet/table/WorksheetTable";
import StaticContentCrud from "./staticContent/crud/StaticContentCrud";
import RefundRequestTable from "./refundrequest/table/RefundRequestTable";
import RefundRequestCreate from "./refundrequest/create/RefundRequestCreate";
import RefundRequestDetail from "./refundrequest/detail/RefundRequestDetail";
import WorksheetAPI from "../api/worksheet/WorksheetAPI";
import BookingAPI from "../api/booking/BookingAPI";

const RootLayout = () => {
    const { me } = useContext(AppStateContext);
    return (
        <div
            className="w-100 flex-center flex-column"
            style={{ height: "100vh" }}
        >
            <img src="/images/welcome.png" className="image-reg-x-large mb-5" />
            <div className="d-flex">
                <h2>Have a good day, </h2>
                <h2 className="text-primary ml-3">{`${me?.firstName} ${me?.lastName}`}</h2>
            </div>

            <div className="w-50 mt-4 text-center">
                Welcome to De Hygienqiue system, a powerful sales system
                developed and powered by Lotus Bedding Group. We wish you can
                maximize the sales ability and have a pleasant working day!
            </div>
        </div>
    );
};

const Routes = () => {
    return (
        <div className="overflow-auto">
            <Switch>
                <Route exact path={Path.ROOT} component={RootLayout} />
                <Route
                    exact
                    path={Path.BOOKING_TABLE}
                    component={() => (
                        <BookingTable dataSource={BookingAPI.list} />
                    )}
                />
                <Route
                    exact
                    path={Path.BOOKING_ASSIGNED_TABLE}
                    component={() => (
                        <BookingTable
                            dataSource={BookingAPI.listAssigned}
                            hideSummary
                        />
                    )}
                />
                <Route
                    exact
                    path={Path.BOOKING_CREATE}
                    component={BookingCreate}
                />
                <Route
                    exact
                    path={Path.BOOKING_DETAIL}
                    component={BookingDetail}
                />
                {/* ****************************************************************
                 ************************PRODUCT *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.PRODUCT_TABLE}
                    component={ProductTable}
                />
                <Route
                    exact
                    path={Path.PRODUCT_DETAIL}
                    component={(props: any) => <ProductCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.PRODUCT_CREATE}
                    component={(props: any) => <ProductCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.PRODUCT_CAT_TABLE}
                    component={ProductCategoryTable}
                />
                {/* ****************************************************************
                 ************************WORKSHEET *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.WORKSHEET_TABLE}
                    component={() => (
                        <WorksheetTable dataSource={WorksheetAPI.list} />
                    )}
                />
                <Route
                    exact
                    path={Path.WORKSHEET_ASSIGNED_TABLE}
                    component={() => (
                        <WorksheetTable
                            dataSource={WorksheetAPI.listAssignedMe}
                            hideSummary
                        />
                    )}
                />
                <Route
                    exact
                    path={Path.WORKSHEET_CREATE}
                    component={(props: any) => <WorksheetCreate {...props} />}
                />
                <Route
                    exact
                    path={Path.WORKSHEET_DETAIL}
                    component={(props: any) => <WorksheetDetail {...props} />}
                />
                {/* ****************************************************************
                 ************************VOUCHER *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.VOUCHER_TABLE}
                    component={VoucherTable}
                />
                <Route
                    exact
                    path={Path.VOUCHER_CREATE}
                    component={VoucherCreate}
                />
                <Route
                    exact
                    path={Path.VOUCHER_DETAIL}
                    component={VoucherDetail}
                />
                {/* ****************************************************************
                 ************************CUSTOMER *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.CUSTOMER_TABLE}
                    component={CustomerTable}
                />
                <Route
                    exact
                    path={Path.CUSTOMER_CREATE}
                    component={CustomerCreate}
                />
                <Route
                    exact
                    path={Path.CUSTOMER_DETAIL}
                    component={CustomerDetail}
                />
                {/* ****************************************************************
                 ************************CATEGORY *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.CATEGORY_TABLE}
                    component={CategoryTable}
                />
                <Route
                    exact
                    path={Path.CATEGORY_DETAIL}
                    component={(props: any) => <CategoryDetail {...props} />}
                />
                <Route
                    exact
                    path={Path.CATEGORY_CREATE}
                    component={(props: any) => <CategoryDetail {...props} />}
                />
                {/* ****************************************************************
                 ************************CRITERIA *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.CRITERIA_TABLE}
                    component={CriteriaTable}
                />
                <Route
                    exact
                    path={Path.CRITERIA_DETAIL}
                    component={(props: any) => <CriteriaCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.CRITERIA_CREATE}
                    component={(props: any) => <CriteriaCrud {...props} />}
                />
                {/* ****************************************************************
                 ************************USERS *********************************
                 ****************************************************************/}
                <Route exact path={Path.USER_TABLE} component={UserTable} />
                <Route
                    exact
                    path={Path.USER_DETAIL}
                    component={(props: any) => <UserCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.USER_CREATE}
                    component={(props: any) => <UserCrud {...props} />}
                />
                <Route exact path={Path.USER_ROLES} component={UserRoleTable} />
                <Route
                    exact
                    path={Path.USER_ROLE_CREATE}
                    component={(props: any) => <UserRoleCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.USER_ROLE_DETAIL}
                    component={(props: any) => <UserRoleCrud {...props} />}
                />
                {/* ****************************************************************
                 ************************NEWS *********************************
                 ****************************************************************/}
                <Route exact path={Path.NEWS_TABLE} component={NewsTable} />
                <Route
                    exact
                    path={Path.NEWS_CREATE}
                    component={(props: any) => <NewsCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.NEWS_DETAIL}
                    component={(props: any) => <NewsCrud {...props} />}
                />
                <Route
                    exact
                    path={Path.NEWS_CAT_TABLE}
                    component={(props: any) => <NewsCategoryTable {...props} />}
                />
                {/* ****************************************************************
                 ************************BLOCKS *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.BLOCK_FEATURE}
                    component={(props: any) => (
                        <BlockDetail {...props} type={BLOCK_TYPE.FEATURE} />
                    )}
                />
                <Route
                    exact
                    path={Path.BLOCK_BEST_SELLING}
                    component={(props: any) => (
                        <BlockDetail
                            {...props}
                            type={BLOCK_TYPE.BEST_SELLING}
                        />
                    )}
                />
                {/* ****************************************************************
                 ************************BANNERS *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.BANNER_TABLE}
                    component={(props: any) => <BannerTable {...props} />}
                />
                <Route
                    exact
                    path={Path.BANNER_CREATE}
                    component={(props: any) => <BannerCrud {...props} />}
                />

                <Route
                    exact
                    path={Path.BANNER_DETAIL}
                    component={(props: any) => <BannerCrud {...props} />}
                />

                {/* ****************************************************************
                 ************************MEDIA *********************************
                 ****************************************************************/}
                <Route exact path={Path.MEDIA} component={MediaManager} />
                <Route
                    exact
                    path={Path.MEDIA_FOLDER}
                    component={MediaManager}
                />
                <Route
                    exact
                    path={Path.MEDIA_DETAIL}
                    component={MediaManager}
                />
                {/* ****************************************************************
                 ************************WAREHOUSE *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.WAREHOUSE_TABLE}
                    component={WarehouseTable}
                />
                <Route
                    exact
                    path={Path.WAREHOUSE_CREATE}
                    component={WarehouseCrud}
                />
                <Route
                    exact
                    path={Path.WAREHOUSE_DETAIL}
                    component={WarehouseCrud}
                />
                {/* ****************************************************************
                 ************************PAYMENT METHOD *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.PAYMENT_METHOD_TABLE}
                    component={PaymentMethodTable}
                />
                <Route
                    exact
                    path={Path.PAYMENT_METHOD_DETAIL}
                    component={PaymentMethodCrud}
                />
                {/* ****************************************************************
                 ************************INVENTORY *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.INVENTORY_TABLE}
                    component={InventoryTable}
                />
                {/* ****************************************************************
                 ************************SETTING *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.SMART_SUPPORT}
                    component={SmartSupport}
                />
                <Route exact path={Path.FAQ_MANAGER} component={FaqManager} />

                {/* ****************************************************************
                 ************************STATIC CONTENT *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.STATIC_CONTENT}
                    component={StaticContentCrud}
                />
                {/* ****************************************************************
                 ************************ORDER *********************************
                 ****************************************************************/}
                <Route exact path={Path.ORDER_TABLE} component={OrderTable} />
                <Route exact path={Path.ORDER_CREATE} component={OrderCreate} />
                <Route exact path={Path.ORDER_DETAIL} component={OrderDetail} />

                {/* ****************************************************************
                 ************************REFUND *********************************
                 ****************************************************************/}
                <Route
                    exact
                    path={Path.REFUND_REQUEST_TABLE}
                    component={RefundRequestTable}
                />
                <Route
                    exact
                    path={Path.REFUND_REQUEST_CREATE}
                    component={RefundRequestCreate}
                />
                <Route
                    exact
                    path={Path.REFUND_REQUEST_DETAIL}
                    component={RefundRequestDetail}
                />
                {/* <Route component={SitePageNotFound} /> */}
            </Switch>
        </div>
    );
};

export default Routes;
