import React, { useContext } from "react";
import { OrderCreateContext } from "../../../context/order";
import OrderBilling from "../common/OrderBilling";

const OrderCreateBilling = () => {
    const { orderForm } = useContext(OrderCreateContext);
    const { billing, customer } = orderForm.values;
    const customerId = customer?.id;

    const onChangeBilling = (value: any) => {
        orderForm.setFieldValue("billing", value);
    };

    return (
        <OrderBilling
            customerId={customerId}
            billing={billing}
            onChangeBilling={onChangeBilling}
        />
    );
};

export default OrderCreateBilling;
