import {
    Icon,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import Image from "../../../common/Image";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    WorksheetStatus,
    WORKSHEET_STATUSES,
} from "../../../constant/worksheet";
import { WorksheetDetailContext } from "../../../context/worksheet";
import { WorksheetCancelSchema } from "../../../formschema/worksheet";
import Messages from "../../../languages/Messages";

const ConfirmCancelModal = ({ open, onClose }: any) => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const cancelForm = useFormik<any>({
        initialValues: {
            remark: "",
            id: worksheetDetail?.id,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: WorksheetCancelSchema,
        onSubmit: (values: any) => {
            onCancel(values);
        },
    });

    const onCancel = (params: any) =>
        Progress.show(
            {
                method: WorksheetAPI.cancel,
                params: [params?.id, params?.remark],
            },
            (res: any) => {
                setWorksheetDetail(res);
                onClose();
                Notifications.showSuccess(Messages.cancelWorksheetSuccessfully);
            }
        );

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => cancelForm.handleSubmit()}
        >
            <text>{Messages.areYouSureToCancelWorksheet}</text>
            <InputTextForm
                keyData="remark"
                form={cancelForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const WorksheetDetailHeader = () => {
    const { worksheetDetail } = useContext(WorksheetDetailContext);
    const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
    const { createdAt, status, createBy, worksheetNo } = worksheetDetail;

    const onClickCancel = () => {
        if (status === WorksheetStatus.CANCELLED) {
            Notifications.showError(Messages.statusNotValidToCancel);
            return;
        }
        setOpenConfirmCancel(true);
    };
    return (
        <div className="card-container d-flex p-4">
            <div className="flex-column flex-grow-1">
                <div className="d-flex align-items-center">
                    <h5>{`${Messages.worksheet} #${worksheetNo}`}</h5>
                    <ViewLabelStatus
                        className="ml-3"
                        status={status}
                        listStatus={WORKSHEET_STATUSES}
                        getLabel={(item) => item.label.toUpperCase()}
                    />
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div className="d-flex align-items-center">
                        <Icon name="event_available" className="small" />
                        <small className="ml-1">
                            {`${Messages.createdOn} ${TimeUtils.toDateTime(
                                createdAt
                            )}`}
                        </small>
                    </div>
                    {/* <div className="d-flex align-items-center ml-3">
                        <Icon name="alarm" className="small" />
                        <small className="ml-1">
                            Expires on 19/02/2020 at 17:55
                        </small>
                    </div>
                    <div className="d-flex align-items-center ml-3">
                        <Icon name="alarm" className="small" />
                        <small className="ml-1">
                            Booking on 23/02/2020 at 10:00
                        </small>
                    </div> */}
                </div>
                <div className="d-flex align-items-center mt-3">
                    {/* <div className="d-flex align-items-center cursor-pointer">
                        <Icon name="insert_link" />
                        <text className="ml-2">{Messages.worksheetUrl}</text>
                    </div> */}
                    <div className="d-flex align-items-center cursor-pointer">
                        <Icon name="print" />
                        <text className="ml-2">{Messages.printWorksheet}</text>
                    </div>
                    <div
                        className="d-flex align-items-center ml-3 cursor-pointer"
                        onClick={onClickCancel}
                    >
                        <Icon name="cancel" />
                        <text className="ml-2">{Messages.cancel}</text>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <text>{`${Messages.by} ${createBy?.username}`}</text>
                <Image
                    className="image-square-xx-small ml-3"
                    src={createBy?.avatar}
                    style={{ borderRadius: "15px" }}
                />
            </div>
            {openConfirmCancel && (
                <ConfirmCancelModal
                    open={openConfirmCancel}
                    onClose={() => setOpenConfirmCancel(false)}
                />
            )}
        </div>
    );
};

export default WorksheetDetailHeader;
