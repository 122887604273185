import Messages from "../languages/Messages";
import { IDistrict, IProvince, ISubdistrict } from "./address";

export enum IWarehouseStatus {
    ACTIVE,
    INACTIVE,
}

export interface IWarehouseInfo {
    name: String;
    code: String;
    phone: String;
    address: String;
}
export interface IWarehouse {
    id: string;
    warehouseNo: string;
    th: IWarehouseInfo;
    en: IWarehouseInfo;
    province: IProvince;
    district: IDistrict;
    subDistrict: ISubdistrict;
    postcode: number;
    createdAt: string;
    updatedAt: string;
    status: IWarehouseStatus;
}

export const getWarehouseInfoText = (warehouse: IWarehouse) =>
    `${(warehouse as any)?.[Messages.getLanguage()]?.name ?? "N/A"} (${
        warehouse?.warehouseNo ?? "N/A"
    })`;

export const mapWarehouseToServer = (warehouse: any) => {
    const {
        province,
        district,
        subDistrict,
        th,
        en,
        code,
        phone,
        postcode,
        status,
    } = warehouse;
    return {
        provinceId: province.id,
        districtId: district.id,
        subDistrictId: subDistrict.id,
        th,
        en,
        code,
        phone,
        postcode,
        status,
    };
};
