import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";
import { F_USER } from "../user/UserFragment";

export const F_ACTIVITY = gql`
    ${F_USER}
    fragment F_ACTIVITY on Activity {
        createBy {
            ...F_USER
        }
        message
        attachments
        createdAt
        location {
            longitude
            latitude
        }
    }
`;

export const F_ACTIVITIES = gql`
    ${F_ACTIVITY}
    ${F_PAGINATION}
    fragment F_ACTIVITIES on Activities {
        activities {
            ...F_ACTIVITY
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;
