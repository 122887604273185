import {
    DialogManager,
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
    TreeSelect,
    ViewCollapse,
} from "d-react-components";
import { useFormik } from "formik";
import { omit, uniq } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FaqAPI from "../../../api/faq/FaqAPI";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { FAQ_STATUS, Faq } from "../../../interfaces/faq";
import Messages from "../../../languages/Messages";
import { IFaqManagerContext, FaqManagerContext } from "../FaqManager";

export interface IFaqArticleDetailProps {
    [key: string]: any;
}

interface CreateFaqInput {
    title?: string;
    description?: string;
    categoryId?: string[];
    status?: string;
    id?: string;
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required("isRequired"),
    description: Yup.string().required("isRequired"),
    status: Yup.string().required("isRequired"),
    categoryId: Yup.string().required("isRequired"),
});

const FaqArticleDetail: React.FC<IFaqArticleDetailProps> = ({
    article,
    onUpdatedArticle,
}) => {
    const history = useHistory();
    const {
        categoryList,
        selectedCategory,
        setSelectedArticle,
        treeData,
        selectedArticle,
    } = React.useContext<IFaqManagerContext>(FaqManagerContext);
    const [originalArticle, setOriginalArticle] = useState<Faq>();

    const loadArticle = (id: string) => {
        Progress.show(
            {
                method: FaqAPI.faqDetail,
                params: { id },
            },
            (res: any) => {
                const { faq } = res?.data ?? {};
                if (faq) {
                    setOriginalArticle(faq);
                    setValues({
                        title: faq.title,
                        description: faq.description,
                        status: faq.status,
                        categoryId: faq?.category.id,
                        id: faq.id,
                    });
                }
            }
        );
    };

    React.useEffect(() => {
        if (selectedArticle?.value) {
            loadArticle(selectedArticle?.value);
        } else {
            setValues({
                title: "",
            });
        }
    }, [selectedArticle]);

    React.useEffect(() => {
        setFieldValue(
            "categoryId",
            selectedCategory ? selectedCategory.value : undefined
        );
    }, [selectedCategory]);

    const form = useFormik<CreateFaqInput>({
        initialValues: {},
        onSubmit: () => {
            const payload = {
                ...omit(values, ["id"]),
            };
            Progress.show(
                {
                    method: values.id ? FaqAPI.updateFaq : FaqAPI.createFaq,
                    params: {
                        payload,
                        ...(values.id ? { id: values.id } : {}),
                    },
                },
                (res: any) => {
                    Notifications.showSuccess(
                        (values.id
                            ? Messages.updateStSuccess
                            : Messages.createStSuccess
                        ).replace("@something", "FAQ")
                    );
                    onUpdatedArticle(
                        uniq([
                            values.categoryId,
                            ...(originalArticle
                                ? [originalArticle?.category?.id]
                                : []),
                        ])
                    );
                    setFieldValue("id", res?.data?.data?.id);
                },
                (res: any) => {
                    Notifications.showError(Messages.serverError);
                }
            );
        },
        validationSchema,
        validateOnChange: false,
    });
    const { handleSubmit, values, errors, setFieldValue, setValues } = form;

    const deleteArticle = () => {
        DialogManager.showConfirm(
            Messages.warning,
            Messages.confirmDeleteSetting.replace(
                "$setting",
                Messages.manualArticle
            ),
            () => {
                Progress.show(
                    {
                        method: FaqAPI.faqDelete,
                        params: {
                            id: selectedArticle?.value,
                        },
                    },
                    (res: any) => {
                        Notifications.showSuccess(
                            Messages.deleteStSuccess.replace(
                                "@something",
                                Messages.manualArticle
                            )
                        );
                        onUpdatedArticle([
                            ...(originalArticle
                                ? [originalArticle?.category?.id]
                                : []),
                        ]);
                        setSelectedArticle(undefined);
                    },
                    (res: any) => {
                        Notifications.showError(Messages.serverError);
                    }
                );
            }
        );
    };

    return (
        <>
            <Header
                title={values.title || "New FAQ"}
                onSave={() => handleSubmit()}
                cancelText={Messages.delete}
                onCancel={deleteArticle}
            />
            <div className="row">
                <div className="col-md-8">
                    <InputText
                        placeholder={Messages.title}
                        value={values.title}
                        onChange={(e) =>
                            setFieldValue("title", e?.target?.value)
                        }
                        error={errors.title}
                        className="mt-4"
                    />
                    <ViewCollapse
                        label={Messages.fullDescription}
                        className="mt-4"
                    >
                        <RichTextTiny
                            onChange={(value: any) =>
                                setFieldValue("description", value)
                            }
                            value={values.description}
                            error={errors.description}
                            size="large"
                        />
                    </ViewCollapse>
                </div>
                <div className="col-md-4">
                    <ViewCollapse label={Messages.status} className="mt-4">
                        <Select
                            onChange={(value) => setFieldValue("status", value)}
                            value={values.status}
                            error={errors.status}
                            dataSource={FAQ_STATUS}
                            label={Messages.status}
                            getLabel={(item) =>
                                Messages[item.label as keyof typeof Messages]
                            }
                        />
                    </ViewCollapse>
                    <ViewCollapse label={Messages.category} className="mt-4">
                        <TreeSelect
                            label={Messages.categories}
                            error={errors.categoryId}
                            value={values?.categoryId as any}
                            dataSource={treeData}
                            allowClear={false}
                            onChange={(v: any) => {
                                // getTree(v);
                                setFieldValue("categoryId", v);
                            }}
                        />
                    </ViewCollapse>
                </div>
            </div>
        </>
    );
};

export default FaqArticleDetail;
