import { Button } from "d-react-components";
import { map } from "lodash";
import React, { useState } from "react";
import { IServiceAddress } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressServiceInfoView from "../../address/AddressServiceInfoView";
import CustomerCrudAddressModal from "../../customer/common/CustomerCrudAddressModal";
import CustomerSelectAddressModal from "../../customer/common/CustomerSelectAddressModal";

interface IOrderAddressItem {
    address: IServiceAddress;
    customerId: string;
    hiddenDelete?: boolean;
    hiddenEdit?: boolean;
    onChange: (address: IServiceAddress) => void;
    onDelete: (addressId: string) => void;
}

const OrderAddressItem = ({
    address,
    customerId,
    hiddenEdit,
    hiddenDelete,
    onChange,
    onDelete,
}: IOrderAddressItem) => {
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    return (
        <div className="flex-column mt-3">
            <AddressServiceInfoView address={address} />

            <div className="d-flex mt-1">
                <div
                    className="cursor-pointer text-primary"
                    onClick={() => setOpenEdit(true)}
                    hidden={hiddenEdit}
                >
                    {Messages.edit}
                </div>
                <div
                    className="cursor-pointer text-error ml-3"
                    onClick={() => onDelete(address?.id)}
                    hidden={hiddenDelete}
                >
                    {Messages.delete}
                </div>
            </div>

            {openEdit && (
                <CustomerCrudAddressModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    isEdit
                    addressDefault={address}
                    isShowRemark
                    onCrudSuccess={onChange}
                    isShowSaveAsNew
                    customerId={customerId}
                />
            )}
        </div>
    );
};

interface IOrderServiceAddress {
    customerId: string;
    hiddenAdd?: boolean;
    addresses: IServiceAddress[];
    getHiddenDelete?: any;
    getHiddenEdit?: any;
    onChangeAddress: (values: any) => void;
    onDeleteAddress: (id: any) => void;
    onAddAddress: (values: any) => void;
}

const OrderServiceAddress = ({
    customerId,
    addresses,
    hiddenAdd,
    getHiddenDelete = () => false,
    getHiddenEdit = () => false,
    onChangeAddress,
    onDeleteAddress,
    onAddAddress,
}: IOrderServiceAddress) => {
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    return (
        <div className="card-container p-4 mt-3">
            <div className="flex-center justify-content-between align-items-center">
                <label>{Messages.shippingAddress}</label>
                <Button
                    variant="trans"
                    onClick={() => setOpenAdd(true)}
                    hidden={!customerId || hiddenAdd}
                >
                    {Messages.add}
                </Button>
            </div>
            <div>
                {map(addresses, (address) => (
                    <OrderAddressItem
                        customerId={customerId}
                        address={address}
                        onChange={onChangeAddress}
                        onDelete={onDeleteAddress}
                        hiddenEdit={getHiddenEdit(address)}
                        hiddenDelete={getHiddenDelete(address)}
                    />
                ))}

                {openAdd && (
                    <CustomerSelectAddressModal
                        open={openAdd}
                        onClose={() => setOpenAdd(false)}
                        customerId={customerId}
                        onSave={onAddAddress}
                    />
                )}
            </div>
        </div>
    );
};

export default OrderServiceAddress;
