import { filter } from "lodash";

export const PRODUCT_GALLERY_TAG = {
    FEATURE: "feature",
    FEED: "feed",
};

export const PRODUCT_GALLERY_TAGS = [
    {
        id: "feature",
        label: "Featured",
    },
    {
        id: "feed",
        label: "Feed",
    },
];

export const PRODUCT_TYPE = {
    DEFAULT_PRODUCT: "DEFAULT_PRODUCT",
    SERVICE_PRODUCT: "SERVICE_PRODUCT",
    PREMIUM_SERVICE: "PREMIUM_SERVICE",
    GROUP_PRODUCT: "GROUP_PRODUCT",
};

export const PRODUCT_TYPES = [
    {
        id: PRODUCT_TYPE.DEFAULT_PRODUCT,
        label: "simpleProduct",
    },
    {
        id: PRODUCT_TYPE.SERVICE_PRODUCT,
        label: "serviceProduct",
    },
    {
        id: PRODUCT_TYPE.PREMIUM_SERVICE,
        label: "premiumServiceProduct",
        tooltip: "tooltipPremiumService",
    },
    {
        id: PRODUCT_TYPE.GROUP_PRODUCT,
        label: "groupProduct",
        tooltip: "tooltipGroupProduct",
    },
];

export const PRODUCT_STOCK_TYPES = filter(
    PRODUCT_TYPES,
    (item) =>
        item.id === PRODUCT_TYPE.DEFAULT_PRODUCT ||
        item.id === PRODUCT_TYPE.GROUP_PRODUCT
);

export const PRODUCT_VISIBILITY = {
    ENABLE: true,
    DISABLED: false,
};

export const PRODUCT_VISIBILITIES = [
    {
        id: PRODUCT_VISIBILITY.ENABLE,
        label: "enable",
    },
    {
        id: PRODUCT_VISIBILITY.DISABLED,
        label: "disabled",
    },
];
