import { DialogManager, Notifications, Progress } from "d-react-components";
import { every, filter } from "lodash";
import { useContext } from "react";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import { WorksheetDetailContext } from "../../../context/worksheet";
import Messages from "../../../languages/Messages";
import CriteriaCheckTable from "../common/CriteriaCheckTable";
import WorksheetMileageForm from "../common/WorksheetMileageForm";
import WorksheetMileageView from "../common/WorksheetMileageView";
import { WORKSHEET_CRITERIA_CHECK_STATUS } from "../../../constant/worksheet";

const WSEndingMileage = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const { criteriasCheck } = worksheetDetail;

    const isEveryProgressChecked = every(
        criteriasCheck,
        (item) =>
            item.status ===
                WORKSHEET_CRITERIA_CHECK_STATUS.WORK_PROGRESS_CHECKED ||
            item.status === WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED
    );

    const onEndMileage = (values: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureToDoThisAction,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.endMileage,
                        params: [worksheetDetail?.id, values],
                    },
                    (worksheet) => {
                        setWorksheetDetail(worksheet);
                        Notifications.showSuccess(
                            Messages.endingMileageSuccess
                        );
                    }
                );
            }
        );
    };

    return (
        <div className="mt-3 card-container p-4">
            <label>{Messages.endingMileage}</label>
            {worksheetDetail?.mileageEnd && (
                <WorksheetMileageView mileage={worksheetDetail?.mileageEnd} />
            )}
            {!worksheetDetail?.mileageEnd?.proves?.length && (
                <WorksheetMileageForm
                    onSubmit={onEndMileage}
                    disabled={!isEveryProgressChecked}
                />
            )}
        </div>
    );
};

const WorksheetDetailProgress = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const { categoryProduct, criteriasCheck, defection } = worksheetDetail;

    const { confirmed, staffSignDate, customerSignature } =
        defection?.confirmation ?? {};

    const disableProgress = !confirmed || !staffSignDate;

    const onCheckWorkProgress = (criteriaCheck: any, progress: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkWorkProgress,
                        params: [
                            worksheetDetail?.id,
                            criteriaCheck?.id,
                            progress?.id,
                        ],
                    },
                    (res: any) => {
                        setWorksheetDetail(res);
                    }
                );
            }
        );
    };

    const onCheckDeliveryProgress = (criteriaCheck: any, progress: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkDeliveryProgress,
                        params: [
                            worksheetDetail?.id,
                            criteriaCheck?.id,
                            progress?.id,
                        ],
                    },
                    (res: any) => {
                        setWorksheetDetail(res);
                    }
                );
            }
        );
    };

    const onCheckAllWorkProgress = (criteria: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkAllWorkProgress,
                        params: [worksheetDetail?.id, criteria?.id],
                    },
                    setWorksheetDetail
                );
            }
        );
    };

    const onCancelWorkProgress = (criteria: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCancelChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.cancelWorkProgress,
                        params: [worksheetDetail?.id, criteria?.id],
                    },
                    setWorksheetDetail
                );
            }
        );
    };

    const onCheckAllDeliveryProgress = (criteria: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkAllDeliveryProgress,
                        params: [worksheetDetail?.id, criteria?.id],
                    },
                    setWorksheetDetail
                );
            }
        );
    };

    if (disableProgress) {
        return (
            <div className="flex-center mt-3 card-container p-4">
                {Messages.defectionNeedToBeConfirmed}
            </div>
        );
    }
    return (
        <div className="flex-column mt-3">
            <div className="card-container p-4">
                <label>{Messages.workProgress}</label>

                <CriteriaCheckTable
                    criteriasCheck={criteriasCheck}
                    criteriaDataList={
                        categoryProduct?.priorChecking?.criterias ?? []
                    }
                    checkDataList={categoryProduct?.workProgress ?? []}
                    checkDataKey="workProgress"
                    // onChangeCheck={onCheckWorkProgress}
                    onCheckAll={onCheckAllWorkProgress}
                    onCancel={onCancelWorkProgress}
                />
            </div>
            {/* <div className="card-container p-4 mt-3">
                <label>{Messages.deliveryProgress}</label>

                <CriteriaCheckTable
                    criteriasCheck={criteriasCheck}
                    criteriaDataList={
                        categoryProduct?.priorChecking?.criterias ?? []
                    }
                    checkDataList={categoryProduct?.deliveryProgress ?? []}
                    checkDataKey="deliveryProgress"
                    // onChangeCheck={onCheckDeliveryProgress}
                    onCheckAll={onCheckAllDeliveryProgress}
                />
            </div> */}
            {categoryProduct?.mileageTrackingRequired && <WSEndingMileage />}
        </div>
    );
};

export default WorksheetDetailProgress;
