/* eslint-disable jsx-a11y/label-has-associated-control */

import { Button } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import AuthCode from "react-auth-code-input";
import { AuthOtpSchema } from "../../../formschema/auth";

export interface IOtpFormViewProps {
    onSubmitHandler?: (code: string) => any;
}

const OtpFormView: React.FC<IOtpFormViewProps> = ({ onSubmitHandler }) => {
    const formik = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: AuthOtpSchema,
        onSubmit: (values) => {
            onSubmitHandler && onSubmitHandler(values?.code);
        },
    });

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="mt-3 flex-column flex-center"
        >
            <div className="">
                <label>2FA Authentication</label>
                <AuthCode
                    onChange={(code) => formik.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3"
                    inputClassName="auth-code__input"
                />
                <Button
                    className="mt-5 w-100"
                    onClick={() => formik.handleSubmit()}
                >
                    Login
                </Button>
            </div>
        </form>
    );
};

export default OtpFormView;
