import { DateInput } from "d-react-components";
import moment from "moment";
import Messages from "../../../languages/Messages";
import CategoryCheckboxGroup from "../../category/common/CategoryCheckboxGroup";
import CustomerSelect from "../../customer/common/CustomerSelect";
import ProductSelect from "../../product/common/ProductSelect";
import UserSelect from "../../user/common/UserSelect";
import VoucherOwnershipStatusCheckboxGroup from "../common/VoucherOwnershipStatusCheckboxGroup";
import VoucherStatusCheckboxGroup from "../common/VoucherStatusCheckboxGroup";

interface IVoucherFilterForm {
    filterForm: any;
    hideCustomer?: boolean;
}

const VoucherFilterForm = ({
    filterForm,
    hideCustomer,
}: IVoucherFilterForm) => {
    const inputClassName = "col-6 mt-3";
    const inputFullClassName = "col-12 mt-3";
    const formValues = filterForm.values ?? {};
    const formErrors = filterForm.errors ?? {};

    return (
        <div className="row">
            <VoucherStatusCheckboxGroup
                className={inputFullClassName}
                value={formValues?.status}
                onChange={(value) => filterForm.setFieldValue("status", value)}
            />
            <VoucherOwnershipStatusCheckboxGroup
                className={inputFullClassName}
                value={formValues?.ownershipStatus}
                onChange={(value) =>
                    filterForm.setFieldValue("ownershipStatus", value)
                }
            />
            <CategoryCheckboxGroup
                className={inputFullClassName}
                value={formValues?.serviceCategories}
                onChange={(value) =>
                    filterForm.setFieldValue("serviceCategories", value)
                }
            />
            <ProductSelect
                value={formValues?.products}
                className={inputFullClassName}
                onChange={(value) =>
                    filterForm.setFieldValue("products", value)
                }
                label={Messages.productSku}
                multiple
            />
            <UserSelect
                className={inputFullClassName}
                onChange={(value) =>
                    filterForm.setFieldValue("createdBy", value)
                }
                label={Messages.createdBy}
                value={formValues.createdBy}
                multiple
            />
            <CustomerSelect
                hidden={hideCustomer}
                className={inputFullClassName}
                onChange={(value) =>
                    filterForm.setFieldValue("customer", value)
                }
                value={formValues?.customer ?? []}
                error={formErrors.customer}
                multiple
                showAddNew={false}
            />
            <DateInput
                className={inputClassName}
                label={Messages.effectiveDate}
                onChange={(value) =>
                    filterForm.setFieldValue("effectiveDate", value)
                }
                value={
                    formValues.effectiveDate
                        ? (moment(formValues.effectiveDate) as any)
                        : null
                }
                error={formErrors.effectiveDate as any}
            />
            <DateInput
                className={inputClassName}
                label={Messages.expiryDate}
                onChange={(value) =>
                    filterForm.setFieldValue("expiryDate", value)
                }
                value={
                    formValues.expiryDate
                        ? (moment(formValues.expiryDate) as any)
                        : null
                }
                error={formErrors.expiryDate as any}
            />
        </div>
    );
};

export default VoucherFilterForm;
