/* eslint-disable no-unused-vars */
import React, { CSSProperties } from "react";
import classNames from "classnames";
import ObjectUtils from "../../utils/ObjectUtils";

export interface IRowsKey<T> {
    id: keyof T;
    label: string | number;
    hidden?: (props: {
        id: IRowsKey<T>["id"];
        data: any;
        item?: any;
        row?: IRowsKey<T>;
    }) => boolean;

    renderLabel?: (props: {
        id: IRowsKey<T>["id"];
        data: any;
        item?: any;
        row?: IRowsKey<T>;
    }) => any;

    renderContent?: (props: {
        id: IRowsKey<T>["id"];
        data: any;
        item?: any;
        row?: IRowsKey<T>;
    }) => any;
}

export interface RowInterchangeViewProps {
    className?: string;
    classNameRow?: string;
    classNameLabel?: string;
    classNameContent?: string;
    dataSource: any;
    keyList: Array<IRowsKey<any>>;
    variant?: "border" | "dashed" | "none";
    Messages?: any;
    style?: CSSProperties;
    styleContent?: CSSProperties;
    styleLabel?: CSSProperties;
}

const ViewRowInterchange: React.FC<RowInterchangeViewProps> = ({
    dataSource = {},
    keyList = [],
    variant = "border",
    className,
    classNameRow,
    classNameLabel,
    classNameContent,
    Messages,
    style,
    styleLabel,
    styleContent,
}) => {
    const wrapperClass = classNames(className);
    return (
        <div className={wrapperClass}>
            {keyList.map((row, index) => {
                const rowClass = classNames(
                    "d-flex align-items-start w-100 justify-content-between py-3",
                    {
                        "border-top": index !== 0 && variant === "border",
                        "border-top-dashed":
                            index !== 0 && variant === "dashed",
                    },
                    classNameRow
                );
                const labelClass = classNames(
                    "text-disabled w-50",
                    classNameLabel
                );
                const contentClass = classNames(
                    "text-grey w-50",
                    classNameContent
                );
                const { id, label, renderLabel, renderContent, hidden } = row;
                let labelView;
                let content;
                labelView = label;
                if (Messages) {
                    labelView = Messages[label];
                }
                if (typeof renderLabel === "function") {
                    labelView = renderLabel({
                        id,
                        item: dataSource,
                        data: dataSource?.[id],
                        row,
                    });
                }
                content = dataSource?.[id] ?? "N/A";
                if (typeof id === "string" && id.includes(".")) {
                    content = ObjectUtils.getValueFromStringKey(dataSource, id);
                }
                if (typeof renderContent === "function") {
                    content = renderContent({
                        id,
                        data: dataSource?.[id],
                        row,
                        item: dataSource,
                    });
                }

                if (typeof hidden === "function") {
                    const isHidden = hidden({
                        id,
                        data: dataSource?.[id],
                        row,
                        item: dataSource,
                    });

                    if (isHidden) return <div />;
                }
                const contentView = (
                    <div className={contentClass} style={styleContent}>
                        {content}
                    </div>
                );
                return (
                    <div
                        className={rowClass}
                        key={`${id as string} + ${index}`}
                        style={style}
                    >
                        <div className={labelClass} style={styleLabel}>
                            {labelView}
                        </div>
                        {contentView}
                    </div>
                );
            })}
        </div>
    );
};

export default ViewRowInterchange;
