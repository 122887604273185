import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import API from "./api/API";
import Root from "./Root";
import store from "./store/store";
import "./style/index.scss";

ReactDOM.render(
    <ApolloProvider client={API.instance}>
        <Provider store={store}>
            <Root />
        </Provider>
    </ApolloProvider>,
    document.getElementById("root")
);
