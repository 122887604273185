import classNames from "classnames";
import {
    Avatar,
    AwesomeListComponent,
    Button,
    Icon,
    InputText,
    Notifications,
    TimeUtils,
} from "d-react-components";
import _, { isEmpty, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { IActivity } from "../../interfaces/activity";
import Messages from "../../languages/Messages";
import ButtonFileUpload from "../button/ButtonFileUpload";
import Image from "../Image";

interface IActivityItem {
    activity: IActivity;
    onClickImage: any;
}

export const ActivityItem = ({ activity, onClickImage }: IActivityItem) => {
    const locationText = `${activity?.location?.latitude},${activity?.location?.longitude}`;
    return (
        <div className="d-flex mt-3 border-bottom pb-3">
            <Avatar
                src={activity?.createBy?.avatar ?? "/images/placeholder.png"}
                size="small"
            />

            <div className="ml-3 w-100">
                <div className="d-flex align-center-center w-100">
                    <div className="flex-grow-1">
                        <div
                            className="text "
                            dangerouslySetInnerHTML={{
                                __html: activity.message,
                            }}
                        />
                        <div
                            className="small text-primary d-flex mt-1 cursor-pointer"
                            hidden={!activity?.location}
                            onClick={() =>
                                window.open(
                                    `https://maps.google.com/?q=${locationText}`
                                )
                            }
                        >
                            <Icon name="my_location" className="mr-1" />
                            {locationText}
                        </div>
                    </div>

                    <div
                        className="small d-flex justify-content-end"
                        style={{ minWidth: 120 }}
                    >
                        {TimeUtils.toDateTime(activity.createdAt)}
                    </div>
                </div>
                <div className="d-flex mt-2">
                    {map(activity?.attachments ?? [], (item) => (
                        <Image
                            className="image-reg-large mr-2"
                            src={item}
                            onClick={() => {
                                onClickImage && onClickImage();
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

interface IActivityView {
    onClickSendComment: (comment: string, attachments: any[]) => any;
    setRef?: (ref: any) => void;
    renderSubTitle?: () => any;
    source?: (paging: any) => any;
    transformer?: (res: any) => any;
    className?: string;
}

const ActivityView = ({
    onClickSendComment,
    setRef,
    renderSubTitle,
    source = () => Promise.resolve([]),
    transformer = (res: any) => res,
    className,
}: IActivityView) => {
    const [imageToView, setImageToView] = useState<any[]>([]);
    const [attachments, setAttachments] = useState<any[]>([]);

    const [commentText, setCommentText] = useState("");
    const [loadingSend, setLoadingSend] = useState(false);
    const activityList = useRef<any>(null);
    const buttonUploadRef = useRef<any>(null);

    useEffect(() => {
        setRef && setRef(activityList.current);
    }, []);

    const renderItem = (activity: IActivity) => {
        return (
            <ActivityItem
                activity={activity}
                onClickImage={() => {
                    setImageToView(activity.attachments);
                }}
            />
        );
    };

    const onClickSend = _.throttle(() => {
        if (loadingSend) {
            return;
        }
        setLoadingSend(true);
        onClickSendComment &&
            onClickSendComment(commentText, attachments)
                .then(() => {
                    setLoadingSend(false);
                    setAttachments([]);
                    setCommentText("");
                    activityList.current.refresh();
                    buttonUploadRef.current &&
                        buttonUploadRef.current.refresh();
                    Notifications.showSuccess(Messages.addCommentSuccess);
                })
                .catch(() => setLoadingSend(false));
    }, 1000);

    const classNameContainer = classNames(
        className,
        "card-container p-4 w-100"
    );

    return (
        <div className={classNameContainer}>
            <label>
                {Messages.activities}
                {renderSubTitle && renderSubTitle()}
            </label>
            <div className="mt-3">
                <div className="d-flex">
                    {/* <Avatar
                        className="avaImage"
                        size="small"
                        src="/images/placeholder.png"
                    /> */}
                    <InputText
                        className="w-100 ml-3"
                        placeholder={Messages.leaveAComment}
                        multiple
                        value={commentText}
                        onChange={(event) => setCommentText(event.target.value)}
                    />
                </div>
                <div className="d-flex mt-3 justify-content-end">
                    <ButtonFileUpload
                        onChange={setAttachments}
                        maxFiles={1}
                        className="d-flex"
                        ref={buttonUploadRef}
                    />
                    <Button
                        className="ml-3 "
                        onClick={onClickSend}
                        iconName="send"
                        disabled={isEmpty(commentText)}
                    >
                        {Messages.send}
                    </Button>
                </div>
            </div>
            <ModalGateway>
                {imageToView && imageToView.length > 0 ? (
                    <Modal onClose={() => setImageToView([])}>
                        <Carousel
                            views={map(imageToView, (file) => ({
                                source: file,
                                caption: "",
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <AwesomeListComponent
                ref={(ref) => {
                    activityList.current = ref;
                }}
                source={source}
                transformer={transformer}
                renderItem={renderItem}
                styleContainer={{ overflowY: "auto" }}
                variant="load-more"
            />
        </div>
    );
};

export default ActivityView;
