import { Empty } from "antd";
import { Button, ObjectUtils, StringUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import CustomerAddressForm from "../common/CustomerAddressForm";

const CustomerCreateBilling = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    const billingList = customerForm?.values?.billingAddress;

    const onClickAddNewBilling = () => {
        customerForm.setFieldValue("billingAddress", [
            ...billingList,
            { id: StringUtils.getUniqueID(), typeOfCustomer: "INDIVIDUAL" },
        ]);
    };

    const onToggleDefault = (id: string) => {
        const result = map(billingList, (item) => {
            if (item.id !== id) {
                return { ...item, pin: false };
            }
            return { ...item, pin: true };
        });
        customerForm.setFieldValue("billingAddress", result);
    };

    const onDelete = (id: string) => {
        const result = ObjectUtils.removeArrayById(billingList, id);
        customerForm.setFieldValue("billingAddress", result);
    };

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button iconName="add" onClick={onClickAddNewBilling}>
                    {Messages.newAddress}
                </Button>
            </div>

            {billingList?.length === 0 && (
                <Empty className="mt-3" description={Messages.noBilling} />
            )}

            {map(billingList ?? [], (addressItem, index) => {
                const isPersonal = addressItem?.typeOfCustomer === "INDIVIDUAL";
                return (
                    <div className="bg-white p-4 my-3">
                        <CustomerAddressForm
                            formData={customerForm}
                            prefix={`billingAddress.${index}`}
                            show={{
                                citizenId: isPersonal,
                                company: !isPersonal,
                                email: true,
                                nickname: false,
                                gender: false,
                                typeOfCustomer: true,
                                typeOfPlace: false,
                                map: false,
                            }}
                            onToggleDefault={() =>
                                onToggleDefault(addressItem?.id)
                            }
                            onClickDelete={() => onDelete(addressItem?.id)}
                            title={Messages.billingAddress}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default CustomerCreateBilling;
