import {
    Button,
    DialogManager,
    InputText,
    Modal,
    Notifications,
    Progress,
} from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import MediaAPI from "../../api/media/MediaAPI";
import { MediaContext } from "../../context/media";
import { useDirectory } from "../../hoook/media";
import Messages from "../../languages/Messages";
import Path from "../Path";

const MediaManagerHeader = () => {
    const history = useHistory();
    const { onClickBack, folderId, isSelectMode, onChangeState } =
        useContext(MediaContext);

    const [folderName, setFolderName] = useState("");
    const [modalCreateFolder, setModalCreateFolder] = useState(false);
    const { folders } = useContext(MediaContext);

    const { parentPath, folder } = useDirectory(folderId, folders);

    const onClickDeleteFolder = () => {
        DialogManager.showConfirm(
            Messages.deleteFolder,
            Messages.areYourSureToDeleteThisFolder,
            onDeleteFolder
        );
    };

    const onDeleteFolder = () => {
        Progress.show(
            { method: MediaAPI.deleteDir, params: [folder?.id] },
            (folders) => {
                Notifications.showSuccess(Messages.deleteFolderSuccess);
                !isSelectMode && history.replace(Path.MEDIA);
                onChangeState({ folders });
            }
        );
    };

    const onCreateFolder = () => {
        if (isEmpty(folderName)) {
            return;
        }

        const body = {
            name: folderName,
            parentId: folderId,
        };
        Progress.show(
            { method: MediaAPI.createDir, params: [body] },
            (folders) => {
                Notifications.showSuccess(Messages.createFolderSuccess);
                !isSelectMode && history.replace(Path.MEDIA);
                onChangeState({ folders });
                setModalCreateFolder(false);
                setFolderName("");
            }
        );
    };

    return (
        <div className="container-fluid d-flex justify-content-between align-items-center card-container p-3">
            <h4 className="font-weight-bold">{Messages.mediaManager}</h4>
            <div className="d-flex">
                {isSelectMode && (
                    <Button
                        className="mr-3"
                        variant="outline"
                        color="gray"
                        onClick={onClickBack}
                    >
                        {Messages.back}
                    </Button>
                )}
                {!isEmpty(folderId) && (
                    <Button
                        color="error"
                        className="mr-3"
                        type="button"
                        onClick={onClickDeleteFolder}
                    >
                        {Messages.deleteFolder}
                    </Button>
                )}
                <Button onClick={() => setModalCreateFolder(true)}>
                    {Messages.createFolder}
                </Button>

                {modalCreateFolder && (
                    <Modal
                        open={modalCreateFolder}
                        onClose={() => setModalCreateFolder(false)}
                        onSave={onCreateFolder}
                        title={Messages.createFolder}
                        size="small"
                    >
                        <InputText
                            label={Messages.folderName}
                            onChange={(event) =>
                                setFolderName(event.target.value)
                            }
                            value={folderName}
                            autoFocus
                        />
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default MediaManagerHeader;
