import {
    AwesomeTableComponent,
    IColumnsProps,
    Modal,
} from "d-react-components";
import React, { useState } from "react";
import { IProduct } from "../../../../../interfaces/product";
import Messages from "../../../../../languages/Messages";
import ProductNameView from "../../../../product/common/ProductNameView";

const WorksheetVoucherConfirmModal = ({
    open,
    onClose,
    products = [],
    onSave,
}: any) => {
    const [productSelect, setProductSelect] = useState<IProduct>();

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.applyVoucherConfirmation}
            onSave={() => onSave(productSelect)}
            maskClosable={false}
        >
            <AwesomeTableComponent
                columns={columns}
                dataSource={products}
                rowSelection={{
                    type: "radio",
                    onChange: (selectedRowKeys, [selectRow]) => {
                        setProductSelect(selectRow as any);
                    },
                }}
                isPagination={false}
            />
        </Modal>
    );
};

export default WorksheetVoucherConfirmModal;
