import React from "react";
import { ICriteria } from "../interfaces/criteria";

interface ICatDetailState {
    categoryForm: any;
    criteriaList: ICriteria[];
}

const categoryDetailState: ICatDetailState = {
    categoryForm: null,
    criteriaList: [],
};

export const CategoryDetailContext = React.createContext(categoryDetailState);
