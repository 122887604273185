import { Switch } from "antd";
import React, { useContext } from "react";
import { CATEGORY_STATUS } from "../../../constant/category";
import { NewsCrudContext } from "../../../context/news";
import Messages from "../../../languages/Messages";

const NewsCrudStatus = () => {
    const { newsForm } = useContext(NewsCrudContext);
    const isActive = newsForm.values?.status === CATEGORY_STATUS.ACTIVE;
    return (
        <div className="card-container p-4 flex-row-between-center">
            <label>{Messages.status}</label>
            <Switch
                onChange={() =>
                    newsForm.setFieldValue(
                        "status",
                        isActive
                            ? CATEGORY_STATUS.INACTIVE
                            : CATEGORY_STATUS.ACTIVE
                    )
                }
                checked={isActive}
            />
        </div>
    );
};

export default NewsCrudStatus;
