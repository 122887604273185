import classNames from "classnames";
import {
    AwesomeTableComponent,
    IColumnsProps,
    InputTextSearch,
    Modal,
    Notifications,
    Progress,
    StringUtils,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { every, filter, find, isNaN, map, parseInt, reduce } from "lodash";
import { useContext, useMemo } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { DEFAULT, PAYMENT_STATUS } from "../../../constant/payment";
import { PRODUCT_TYPE } from "../../../constant/product";
import { REFUND_FORMAT, REFUND_FORMATS } from "../../../constant/refundRequest";
import { OrderDetailContext } from "../../../context/order";
import { OrderRefundRequestSchema } from "../../../formschema/order";
import {
    IOrderProduct,
    mapOrderRefundToServer,
} from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import ObjectUtils from "../../../utils/ObjectUtils";
import { OrderDetailProductView } from "./OrderDetailProducts";

interface IOrderRequestRefundModal {
    open: boolean;
    onClose: () => void;
}

const OrderRequestRefundModal = ({
    open,
    onClose,
}: IOrderRequestRefundModal) => {
    const { metaData, orderDetail, setOrderDetail } =
        useContext(OrderDetailContext);
    const { paymentMethods } = metaData;
    const { splitPayment = [], refunds = [] } = orderDetail;

    const cashRefunds = filter(
        refunds,
        (item) => item.typeOfRefund === REFUND_FORMAT.CASH
    );

    const voucherRefunds = filter(
        refunds,
        (item) => item.typeOfRefund === REFUND_FORMAT.VOUCHER
    );

    const totalPaid = useMemo(() => {
        const paymentConfirmed = filter(
            splitPayment,
            (item) => item.status === PAYMENT_STATUS.CONFIRMED
        );
        return reduce(paymentConfirmed, (sum, item) => sum + item.amount, 0);
    }, [splitPayment]);

    const totalRequested = useMemo(
        () => reduce(cashRefunds, (sum, item) => sum + item.amount, 0),
        [refunds]
    );

    const refundForm = useFormik<any>({
        initialValues: {
            products: map(orderDetail?.products, (item) => ({
                id: item.id,
                quantity: 0,
                product: item,
            })),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderRefundRequestSchema,
        validate: (values: any) => onValidateRefund(values),
        onSubmit: (values: any) => {
            const input = mapOrderRefundToServer(values);
            onRequestRefund(input);
        },
    });

    const formValues = refundForm?.values;
    const formErrors = refundForm?.errors;

    const onRequestRefund = (input: any) => {
        Progress.show(
            {
                method: OrderAPI.requestRefund,
                params: [orderDetail?.id, input],
            },
            (order: any) => {
                Notifications.showSuccess(Messages.requestRefundSuccessfully);
                setOrderDetail(order);
                onClose();
            }
        );
    };

    const getTotalVoucherRefunded = (productId: string) =>
        reduce(
            voucherRefunds,
            (sum, voucher) => {
                const product = find(
                    voucher.products,
                    (item) => item.id === productId
                );
                if (product) {
                    return sum + product.quantity;
                }
                return sum;
            },
            0
        );

    const onValidateRefund = (values: any) => {
        const error: any = {};

        if (values.typeOfRefund === REFUND_FORMAT.VOUCHER) {
            const isEmptyQuantity = every(
                values?.products,
                (item) => item.quantity === 0
            );
            if (isEmptyQuantity) {
                error.products = Messages.youNeedAddAtLeastOneProduct;
            }

            // if (vouchers?.length === 0) {
            //     error.typeOfRefund = Messages.thisOrderHasNoVoucher;
            // }
        }

        for (let index = 0; index < values?.products.length; index += 1) {
            const inputPro = values?.products[index];
            const product = find(
                orderDetail?.products,
                (item) => item.id === inputPro?.id
            ) as IOrderProduct;

            if (
                product.productType !== PRODUCT_TYPE.SERVICE_PRODUCT &&
                product.productType !== PRODUCT_TYPE.PREMIUM_SERVICE
            ) {
                error.products = Messages.cantRefundVoucherByNormalProduct;
            }

            if (values.typeOfRefund === REFUND_FORMAT.VOUCHER) {
                /**
                 * with refund by voucher => total items voucher can not greater than order's items
                 */
                const orderRefundVoucher = filter(
                    orderDetail?.refunds,
                    (item) => item.typeOfRefund === REFUND_FORMAT.VOUCHER
                );

                const totalRefunded = reduce(
                    orderRefundVoucher,
                    (sum, refund) => {
                        const proRefunded = find(
                            refund.products,
                            (item) => item.id === inputPro.id
                        );
                        if (proRefunded) return sum + proRefunded.quantity;
                        return sum;
                    },
                    0
                );

                if (totalRefunded + inputPro.quantity > product.quantity) {
                    error.products = `Product ${product?.SKU} quantity of refunding can not greater than order's product quantity`;
                }
            }
        }

        return error;
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product) => <OrderDetailProductView product={product} />,
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => (
                <InputTextQuantity
                    value={quantity}
                    onChange={(quantity) => onChangeQuantity(product, quantity)}
                    min={0}
                />
            ),
        },
    ];

    const onChangeQuantity = (product: any, quantity: number) => {
        const values = ObjectUtils.updateArrayById(formValues.products, {
            ...product,
            quantity,
        });
        refundForm.setFieldValue("products", values);
    };

    const renderSummaryItem = (label: any, content: any) => {
        return (
            <div className="border-bottom-dashed flex-row-between-center py-2 mt-1">
                <label className="text-primary">{label}</label>
                <div>{content}</div>
            </div>
        );
    };
    const renderAmountSummary = () => {
        return (
            <div className="p-3 bg-gray">
                {renderSummaryItem(
                    Messages.totalPaidAmount,
                    StringUtils.moneyThaiFormat(totalPaid)
                )}
                {renderSummaryItem(
                    Messages.requestedRefundAmount,
                    StringUtils.moneyThaiFormat(totalRequested)
                )}
                {renderSummaryItem(
                    Messages.remainingAmount,
                    StringUtils.moneyThaiFormat(totalPaid - totalRequested)
                )}
            </div>
        );
    };

    const classNameInput = classNames("mt-3");

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.requestRefund}
            onSave={() => {
                refundForm.handleSubmit();
            }}
            size="medium"
        >
            <div>
                {renderAmountSummary()}
                <label className="mt-3">{Messages.services}</label>
                <InputTextSearch className="mt-3" />
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={formValues?.products ?? []}
                    className="mt-3"
                    pagination={false}
                />
                <ViewTextError error={formErrors?.products} />
                <InputSelectForm
                    label={Messages.type}
                    form={refundForm}
                    keyData="typeOfRefund"
                    dataSource={REFUND_FORMATS}
                    className={classNameInput}
                />
                <InputSelectForm
                    hidden={formValues?.typeOfRefund === REFUND_FORMAT.VOUCHER}
                    label={Messages.paymentMethod}
                    form={refundForm}
                    keyData="paymentMethodId"
                    dataSource={filter(
                        paymentMethods,
                        (item) => item.type !== DEFAULT
                    )}
                    getLabel={(item: any) =>
                        item?.[Messages.getLanguage()]?.title
                    }
                    className={classNameInput}
                />
                <InputTextForm
                    hidden={formValues?.typeOfRefund === REFUND_FORMAT.VOUCHER}
                    form={refundForm}
                    keyData="amount"
                    className={classNameInput}
                    value={`${formValues.amount ?? 0}`}
                    onChange={(event: any) => {
                        const text = parseInt(event.target.value ?? "0");
                        const value = isNaN(text) ? 0 : text;
                        refundForm.setFieldValue("amount", value);
                    }}
                />

                <div className={classNameInput}>
                    <label>{Messages.attachments}</label>
                    <ButtonFileUpload
                        onChange={(value: any) =>
                            refundForm.setFieldValue("attachments", value)
                        }
                        maxFiles={5}
                        className="mt-3"
                    />
                </div>

                <InputTextForm
                    form={refundForm}
                    keyData="remark"
                    className={classNameInput}
                    multiple
                />
            </div>
        </Modal>
    );
};

export default OrderRequestRefundModal;
