import {
    AwesomeTableComponent,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import CategoryAPI from "../../../api/category/CategoryAPI";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import TableAction from "../../../common/TableActions";
import { CATEGORY_STATUSES } from "../../../constant/category";
import { ICategory } from "../../../interfaces/category";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CategoryTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const history = useHistory();
    const columns: IColumnsProps = [
        {
            title: Messages.feature,
            dataIndex: "thumbnail",
            render: (thumbnail: any) => (
                <Image className="image-square-small" src={thumbnail} />
            ),
            width: 150,
        },
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name: string, category: any) => (
                <AppLink
                    to={generatePath(Path.CATEGORY_DETAIL, {
                        catId: category.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },

        {
            title: Messages.processQty,
            dataIndex: "processQty",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={CATEGORY_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: any) => `${createBy?.fullName}`,
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAtDis",
        },
        {
            title: Messages.updateAt,
            dataIndex: "updatedAtDis",
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (category: ICategory) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, category)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return CategoryAPI.list(searchRef.current, {}, pagingData);
    };

    const onClickTableAction = (actionId: string, category: ICategory) => {
        switch (actionId) {
            case "delete":
                onDeleteCategory(category.id as string);
                break;
            default:
                history.push(
                    generatePath(Path.CATEGORY_DETAIL, { catId: category.id })
                );
        }
    };

    const onClickAddNew = () => {
        history.push(Path.CATEGORY_CREATE);
    };

    const onDeleteCategory = (id: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureDeletePaymentMethod,
            () => {
                Progress.show(
                    { method: CategoryAPI.delete, params: [id] },
                    () => {
                        tableRef.current.refresh();
                        Notifications.showSuccess(
                            Messages.deleteCategorySuccessfully
                        );
                    }
                );
            }
        );
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.category}
                // onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.category ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default CategoryTable;
