import { DialogManager, Progress } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import { mapAddressBookingToSer } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import BookingServiceAddress from "../common/BookingServiceAddress";

const BookingDetailServiceAdd = () => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);

    const { addresses, customer, status } = bookingDetail;
    const customerId = customer?.id ?? "";

    const onUpdateAddress = (addressParam: any) => {
        const input = mapAddressBookingToSer(addressParam);
        Progress.show(
            {
                method: BookingAPI.updateAddress,
                params: [bookingDetail?.id, addressParam?.id, input],
            },
            setBookingDetail
        );
    };

    const onAddAddress = (addresses: any) => {
        const addressesInput = map(addresses, mapAddressBookingToSer);
        Progress.show(
            {
                method: BookingAPI.createAddress,
                params: [bookingDetail?.id, addressesInput],
            },
            (res: any) => setBookingDetail(res as any)
        );
    };

    const onDeleteAddress = (addressId: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureDeleteAddress,
            () =>
                Progress.show(
                    {
                        method: BookingAPI.deleteAddress,
                        params: [bookingDetail?.id, addressId],
                    },
                    setBookingDetail
                )
        );
    };

    return (
        <BookingServiceAddress
            addresses={addresses}
            customerId={customerId}
            onChangeAddress={onUpdateAddress}
            onAddAddress={onAddAddress}
            onDeleteAddress={onDeleteAddress}
            hiddenCrud={status === BOOKING_STATUS.CANCELLED}
        />
    );
};

export default BookingDetailServiceAdd;
