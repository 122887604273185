// react
import React from "react";

// third-party
import { Route, Switch } from "react-router-dom";
import AuthLogin from "./auth/AuthLogin";
import AuthOTP from "./auth/AuthOTP";
import Path from "./Path";

// application

// pages

export default function AuthLayout() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path={Path.AUTH} component={AuthLogin} />
                <Route exact path={Path.AUTH_LOGIN} component={AuthLogin} />
                <Route exact path={Path.AUTH_OTP_AUTH} component={AuthOTP} />
                <Route path="/" component={AuthLogin} />
            </Switch>
        </React.Fragment>
    );
}
