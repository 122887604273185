import { Progress } from "d-react-components";
import React, { useContext } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import { mapAddressBookingToSer } from "../../../interfaces/booking";
import BookingBilling from "../common/BookingBilling";

const BookingDetailBilling = () => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);

    const { billing, customer, status } = bookingDetail;
    const customerId = customer?.id;

    const onChangeBilling = (value: any) => {
        const input = mapAddressBookingToSer(value);
        Progress.show(
            {
                method: BookingAPI.updateBilling,
                params: [bookingDetail?.id, input],
            },
            setBookingDetail
        );
    };

    return (
        <BookingBilling
            customerId={customerId ?? ""}
            billing={billing}
            onChangeBilling={onChangeBilling}
            hiddenCrud={status === BOOKING_STATUS.CANCELLED}
        />
    );
};

export default BookingDetailBilling;
