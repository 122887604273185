import { gql } from "@apollo/client";
import { map, reduce } from "lodash";
import { mapVoucherFromServer } from "../../interfaces/voucher";
import { F_ACTIVITIES } from "../activity/ActivityFragment";
import API from "../API";
import {
    F_VOUCHER,
    F_VOUCHERS,
    F_VOUCHERS_EXPORT,
    F_VOUCHER_CHECK,
    F_VOUCHER_PRODUCT,
} from "./VoucherFragment";

const listConvertor = {
    fromServer: (res: any) => ({
        data: {
            data: {
                ...res.data.data,
                voucher: res?.data?.data?.voucher ?? [],
            },
        },
    }),
};

export default {
    list: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filterState: any
    ) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_VOUCHERS}
                query vouchers(
                    $q: String
                    $page: Int
                    $limit: Int
                    $status: VoucherStatus
                    $serviceCategories: [String]
                    $effectiveDate: Date
                    $expiryDate: Date
                    $createdBy: [String]
                    $customer: [String]
                    $ownershipStatus: [String]
                    $productIds: [ID]
                ) {
                    data: vouchers(
                        q: $q
                        page: $page
                        limit: $limit
                        status: $status
                        serviceCategories: $serviceCategories
                        effectiveDate: $effectiveDate
                        expiryDate: $expiryDate
                        createdBy: $createdBy
                        customer: $customer
                        ownershipStatus: $ownershipStatus
                        productIds: $productIds
                    ) {
                        ...F_VOUCHERS
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                ...filterState,
            },
            fetchPolicy: "no-cache",
        }),

    export: (
        q: string,
        { pageIndex = 1, pageSize = 20 }: any,
        filterState: any
    ) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_VOUCHERS_EXPORT}
                query vouchers(
                    $q: String
                    $page: Int
                    $limit: Int
                    $status: VoucherStatus
                    $serviceCategories: [String]
                    $effectiveDate: Date
                    $expiryDate: Date
                ) {
                    data: vouchers(
                        q: $q
                        page: $page
                        limit: $limit
                        status: $status
                        serviceCategories: $serviceCategories
                        effectiveDate: $effectiveDate
                        expiryDate: $expiryDate
                    ) {
                        ...F_VOUCHERS_EXPORT
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                ...filterState,
            },
            fetchPolicy: "no-cache",
        }),

    summary: (input?: any) =>
        API.withConverter({
            fromServer: (res) => {
                const summary = res?.data?.summarizeVouchers ?? [];
                const allSummary = {
                    status: null,
                    total: reduce(summary, (sum, item) => sum + item.total, 0),
                };
                return [allSummary, ...summary];
            },
        }).query({
            query: gql`
                query summarizeVouchers($input: SummarizeVoucherInput) {
                    summarizeVouchers(input: $input) {
                        status
                        total
                    }
                }
            `,
            fetchPolicy: "no-cache",
            variables: {
                input,
            },
        }),

    delete: () => API.delete("", ""),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createVoucher?.id,
        }).mutate({
            mutation: gql`
                mutation createVoucher($input: VoucherInput!) {
                    createVoucher(input: $input) {
                        id
                        status
                    }
                }
            `,
            variables: { input },
        }),

    generateBulkVoucher: (input: any) =>
        API.mutate({
            mutation: gql`
                mutation generateBulkVoucher($input: BulkVoucherInput!) {
                    generateBulkVoucher(input: $input)
                }
            `,
            variables: { input },
        }),

    detail: (id: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.voucher,
        }).query({
            query: gql`
                ${F_VOUCHER}
                query voucher($id: ID!) {
                    voucher(id: $id) {
                        ...F_VOUCHER
                    }
                }
            `,
            variables: { id },
        }),

    cancel: (variables: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.voucher,
        }).mutate({
            mutation: gql`
                ${F_VOUCHER}
                mutation cancelVoucher($id: ID!, $remark: String) {
                    voucher: cancelVoucher(id: $id, remark: $remark) {
                        ...F_VOUCHER
                    }
                }
            `,
            variables,
        }),

    checkVouchers: (codes: string[]) =>
        API.withConverter({
            fromServer: (res) =>
                map(res?.data?.checkVouchers, mapVoucherFromServer),
        }).mutate({
            mutation: gql`
                ${F_VOUCHER_CHECK}
                mutation checkVouchers($codes: [String]!) {
                    checkVouchers(codes: $codes) {
                        ...F_VOUCHER_CHECK
                    }
                }
            `,
            variables: { codes },
        }),

    listProductVoucher: (
        { pageIndex = 1, pageSize = 1000 }: any,
        filterState: any
    ) =>
        API.query({
            query: gql`
                ${F_VOUCHER_PRODUCT}
                query listProductVoucher(
                    $page: Int
                    $limit: Int
                    $filter: ProductVoucherFilter
                ) {
                    data: productListVouchers(
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        products {
                            ...F_VOUCHER_PRODUCT
                        }
                    }
                }
            `,
            variables: {
                page: pageIndex,
                limit: pageSize,
                filter: filterState,
            },
            fetchPolicy: "no-cache",
        }),

    changeExpiryDate: (id: string, input: any) =>
        API.withConverter({
            fromServer: (res) =>
                mapVoucherFromServer(res?.data?.changeExpiryDateVoucher),
        }).mutate({
            mutation: gql`
                ${F_VOUCHER}
                mutation changeExpiryDateVoucher(
                    $id: ID!
                    $input: VoucherChangeExpiryDateInput
                ) {
                    changeExpiryDateVoucher(id: $id, input: $input) {
                        ...F_VOUCHER
                    }
                }
            `,
            variables: { id, input },
        }),

    transferOwnership: (id: string, input: any) =>
        API.withConverter({
            fromServer: (res) =>
                mapVoucherFromServer(res?.data?.transferOwnership),
        }).mutate({
            mutation: gql`
                ${F_VOUCHER}
                mutation transferOwnership(
                    $id: ID!
                    $input: VoucherTransferOwnershipInput
                ) {
                    transferOwnership(id: $id, input: $input) {
                        ...F_VOUCHER
                    }
                }
            `,
            variables: { id, input },
        }),

    activities: (id: string, { pageIndex = 1, pageSize = 10 }: any) =>
        API.query({
            query: gql`
                ${F_ACTIVITIES}
                query voucherActivities($id: ID!, $page: Int, $limit: Int) {
                    data: voucherActivities(
                        id: $id
                        page: $page
                        limit: $limit
                    ) {
                        ...F_ACTIVITIES
                    }
                }
            `,
            variables: { id, page: pageIndex, limit: pageSize },
            fetchPolicy: "no-cache",
        }),

    addComment: (id: string, message: string, attachments?: string[]) =>
        API.mutate({
            mutation: gql`
                mutation commentVoucher(
                    $id: ID!
                    $message: String
                    $attachments: [String]
                ) {
                    commentVoucher(
                        id: $id
                        message: $message
                        attachments: $attachments
                    )
                }
            `,
            variables: { id, message, attachments },
        }),
};
