import { Button } from "d-react-components";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { BookingAddressesContext } from "../../../context/booking";
import { IServiceAddress } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressServiceInfoView from "../../address/AddressServiceInfoView";
import CustomerCrudAddressModal from "../../customer/common/CustomerCrudAddressModal";
import CustomerSelectAddressModal from "../../customer/common/CustomerSelectAddressModal";

interface IBookingAddressItem {
    address: IServiceAddress;
    onChange: (address: IServiceAddress) => void;
    onDelete: (addressId: string) => void;
    hiddenEdit?: boolean;
    hiddenDelete?: boolean;
}

const BookingAddressItem = ({
    address,
    onChange,
    onDelete,
    hiddenEdit,
    hiddenDelete,
}: IBookingAddressItem) => {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const { customerId } = useContext(BookingAddressesContext);

    return (
        <div className="flex-column mt-3">
            <AddressServiceInfoView address={address} />

            <div className="d-flex mt-1">
                <div
                    className="cursor-pointer text-primary"
                    onClick={() => setOpenEdit(true)}
                    hidden={hiddenEdit}
                >
                    {Messages.edit}
                </div>
                <div
                    className="cursor-pointer text-error ml-3"
                    onClick={() => onDelete(address?.id)}
                    hidden={hiddenDelete}
                >
                    {Messages.delete}
                </div>
            </div>

            {openEdit && (
                <CustomerCrudAddressModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    isEdit
                    addressDefault={address}
                    isShowRemark
                    onCrudSuccess={onChange}
                    isShowSaveAsNew
                    customerId={customerId}
                />
            )}
        </div>
    );
};

interface IBookingServiceAddress {
    customerId: string;
    addresses: IServiceAddress[];
    onChangeAddress: (values: any) => void;
    onDeleteAddress: (id: any) => void;
    onAddAddress: (values: any) => void;
    hiddenCrud?: boolean;
}

const BookingServiceAddress = ({
    customerId,
    addresses,
    onChangeAddress,
    onDeleteAddress,
    onAddAddress,
    hiddenCrud,
}: IBookingServiceAddress) => {
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    return (
        <BookingAddressesContext.Provider
            value={{
                customerId,
            }}
        >
            <div className="card-container p-4 mt-3">
                <div className="flex-center justify-content-between align-items-center">
                    <label>{Messages.serviceAddress}</label>
                    <Button
                        variant="trans"
                        onClick={() => setOpenAdd(true)}
                        hidden={hiddenCrud}
                    >
                        {Messages.add}
                    </Button>
                </div>
                <div>
                    {map(addresses, (address) => (
                        <BookingAddressItem
                            address={address}
                            onChange={onChangeAddress}
                            onDelete={onDeleteAddress}
                            hiddenEdit={hiddenCrud}
                            hiddenDelete={hiddenCrud}
                        />
                    ))}

                    {openAdd && (
                        <CustomerSelectAddressModal
                            open={openAdd}
                            onClose={() => setOpenAdd(false)}
                            customerId={customerId}
                            onSave={onAddAddress}
                        />
                    )}
                </div>
            </div>
        </BookingAddressesContext.Provider>
    );
};

export default BookingServiceAddress;
