import classNames from "classnames";
import React from "react";
import { IBillingAddress, IServiceAddress } from "../../../interfaces/address";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import AddressBillingInfoView from "../../address/AddressBillingInfoView";
import AddressServiceInfoView from "../../address/AddressServiceInfoView";
import CustomerInfoView from "../../customer/common/CustomerInfoView";

export interface IProps {
    customer: ICustomer;
    billing: IBillingAddress;
    address: IServiceAddress;
    serviceDate: string;
    source: any;
    className?: string;
}

const WSCustomerInformation = ({
    customer,
    billing,
    serviceDate,
    source,
    address,
    className,
}: IProps) => {
    return (
        <div className={classNames("flex-column", className)}>
            <div className="card-container p-4">
                <label>{Messages.customer}</label>
                <CustomerInfoView customer={customer as any} />
            </div>
            {/* <div className="card-container p-4 mt-3 flex-column">
                <label>{Messages.serviceDate}</label>
                <text>{TimeUtils.toDate(serviceDate)}</text>
            </div> */}
            <div className="card-container p-4 mt-3">
                <label>{Messages.serviceAddress}</label>
                <AddressServiceInfoView address={address} />
            </div>
            <div className="card-container p-4 mt-3">
                <label>{Messages.billingInformation}</label>
                <AddressBillingInfoView billing={billing} />
            </div>
            <div className="card-container p-4 mt-3 flex-column">
                <label>{Messages.sourceOfBooking}</label>
                <text>{source?.name ?? ""}</text>
            </div>
        </div>
    );
};

export default WSCustomerInformation;
