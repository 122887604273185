import React from "react";
import { IPaymentMethod } from "../interfaces/paymentmethod";
import { IRefundRequest } from "../interfaces/refundRequest";

interface IRefundCreateState {
    refundForm: any;
    paymentMethods: IPaymentMethod[];
}

const refundCreateState: IRefundCreateState = {
    refundForm: null as any,
    paymentMethods: [],
};

export const RefundCreateContext = React.createContext(refundCreateState);

interface IRefundDetailState {
    refundRequest: IRefundRequest;
    setRefundRequest: any;
}

const refundDetailState: IRefundDetailState = {
    refundRequest: null as any,
    setRefundRequest: () => {},
};

export const RefundDetailContext = React.createContext(refundDetailState);
