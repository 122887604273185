import { CheckboxGroup, ObjectUtils } from "d-react-components";
import { map } from "lodash";
import { useMemo } from "react";
import { VOUCHER_STATUSES } from "../../../constant/voucher";

import Messages from "../../../languages/Messages";

interface IVoucherStatusCheckboxGroup {
    className?: string;
    value: any[];
    multiple?: boolean;
    isObjectValue?: boolean;
    onChange: (value: any) => void;
}

const VoucherStatusCheckboxGroup = ({
    value,
    multiple,
    className,
    isObjectValue,
    onChange,
}: IVoucherStatusCheckboxGroup) => {
    const onChangeValue = (id: any) => {
        if (!isObjectValue) {
            onChange(id);
            return;
        }
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(VOUCHER_STATUSES, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(VOUCHER_STATUSES, id));
    };

    const fieldValue = useMemo(() => {
        if (!isObjectValue) {
            return value;
        }
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <CheckboxGroup
            numberOfColumns="2"
            dataSource={VOUCHER_STATUSES}
            label={Messages.status}
            className={className}
            value={fieldValue}
            onChange={onChangeValue}
            getLabel={(item) => (Messages as any)[item?.label]}
        />
    );
};

export default VoucherStatusCheckboxGroup;
