import { InputText, ViewCollapse } from "d-react-components";
import { useContext } from "react";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { NewsCrudContext } from "../../../context/news";
import Messages from "../../../languages/Messages";
import NewsLanguageButton from "./NewsLanguageButton";

const NewsCrudContent = () => {
    const { newsForm, newsLang } = useContext(NewsCrudContext);
    const { errors } = newsForm;
    const { description, shortDescription, title } =
        newsForm.values?.[newsLang] ?? {};
    return (
        <div>
            <NewsLanguageButton />
            <InputText
                value={title ?? ""}
                onChange={(event: any) =>
                    newsForm.setFieldValue(
                        `${newsLang}.title`,
                        event.target.value
                    )
                }
                className="mt-3"
                error={errors?.th?.title || errors?.en?.title}
            />
            <div className="mt-3">
                <NewsLanguageButton />
                <ViewCollapse
                    label={Messages.description}
                    className="card-container mt-3"
                    required
                >
                    <RichTextTiny
                        value={description ?? ""}
                        onChange={(value: any) =>
                            newsForm.setFieldValue(
                                `${newsLang}.description`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.description}
                        size="large"
                        error={
                            errors?.th?.description || errors?.en?.description
                        }
                    />
                </ViewCollapse>
            </div>
            <div className="mt-3">
                <NewsLanguageButton />
                <ViewCollapse
                    label={Messages.shortDescription}
                    className="card-container mt-3"
                    required
                >
                    <RichTextTiny
                        value={shortDescription ?? ""}
                        onChange={(value: any) =>
                            newsForm.setFieldValue(
                                `${newsLang}.shortDescription`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.shortDescription}
                        size="large"
                        error={
                            errors?.th?.shortDescription ||
                            errors?.en?.shortDescription
                        }
                    />
                </ViewCollapse>
            </div>
        </div>
    );
};

export default NewsCrudContent;
