import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import { useContext } from "react";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { RefundCreateContext } from "../../../context/refundRequest";
import Messages from "../../../languages/Messages";
import ObjectUtils from "../../../utils/ObjectUtils";
import { OrderDetailProductView } from "../../order/detail/OrderDetailProducts";

const RefundRequestCreateItems = () => {
    const { refundForm } = useContext(RefundCreateContext);

    const formValues = refundForm?.values;

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product) => {
                return <OrderDetailProductView product={product as any} />;
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => (
                <InputTextQuantity
                    value={quantity}
                    onChange={(quantity) => onChangeQuantity(product, quantity)}
                    min={0}
                    max={product?.maxQuantity}
                />
            ),
        },
    ];

    const onChangeQuantity = (product: any, quantity: number) => {
        const values = ObjectUtils.updateArrayById(formValues.products, {
            ...product,
            quantity,
        });
        refundForm.setFieldValue("products", values);
    };

    if (!formValues.orderDetail && !formValues.worksheet) {
        return <div />;
    }

    return (
        <div className="d-flex flex-column p-3 mt-3 card-container">
            <label>{Messages.itemsToRefund}</label>
            <AwesomeTableComponent
                columns={columns}
                dataSource={formValues?.products ?? []}
                pagination={false}
            />
        </div>
    );
};

export default RefundRequestCreateItems;
