import { map } from "lodash";
import moment from "moment";
import { IBillingAddress, IServiceAddress } from "./address";
import { ICustomer } from "./customer";
import { IProduct } from "./product";
import { IUser } from "./user";
import { IWorksheet } from "./worksheet";

export interface IBookingScheduleService {
    id: string;
    date: string;
    remark: string;
}
export interface IBookingContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface IBookingSource {
    id: string;
    name: string;
}

export interface IBooking {
    id: string;
    bookingNo: string;
    customer: ICustomer;
    cost: number;
    quantity: number;
    products: IProduct[];
    serviceDate: string;
    voucher: any;
    notes: string;
    source: IBookingSource;
    status: string;
    createBy: IUser;
    createdAt: string;
    billing: IBillingAddress;
    addresses: IServiceAddress[];
    PIC: ICustomer[];
    worksheet: IWorksheet[];
    scheduleServices: IBookingScheduleService[];
    contact: IBookingContact;
}

export const mapBookingFromSer = (booking: any) => {
    const { bookingService, serviceDate, ...rest } = booking ?? {};
    return {
        products: map(bookingService, ({ product, ...restItem }) => ({
            ...product,
            ...restItem,
        })),
        serviceDate: moment(serviceDate),
        ...rest,
    };
};

export const mapAddressBookingToSer = (addressDefault: any) => {
    if (!addressDefault) {
        return {
            country: "Thai",
        };
    }

    const {
        fullName,
        fullAddress,
        pin,
        id,
        zoom,
        province,
        district,
        subDistrict,
        ...rest
    } = addressDefault;
    return {
        country: "Thai",
        province: province?.id,
        district: district?.id,
        subDistrict: subDistrict?.id,
        ...rest,
    };
};

export const mapBookingToSer = (booking: any) => {
    const {
        customer,
        products,
        serviceDate,
        notes,
        sourceBookingId,
        addresses,
        billing,
        contact,
    } = booking;
    return {
        customerId: customer.id,
        services: map(products, (item) => ({
            productId: item.id,
            quantity: parseInt(item.quantity, 10),
            openingCost: parseInt(item.openingCost, 10),
        })),
        serviceDate: moment(serviceDate).toISOString(),
        notes,
        sourceBookingId,
        address: map(addresses, mapAddressBookingToSer),
        billing: mapAddressBookingToSer(billing),
        contact,
    };
};

export const mapBookingToSerUpdate = (booking: any) => {
    const {
        customer,

        serviceDate,
        notes,
        sourceBookingId,
        addresses,
        billing,
    } = booking;
    return {
        customerId: customer.id,
        serviceDate: moment(serviceDate).toISOString(),
        notes,
        sourceBookingId,
        address: map(addresses, mapAddressBookingToSer),
        billing: mapAddressBookingToSer(billing),
    };
};
