import moment from "moment";
import { INews } from "./news";
import { IUser } from "./user";

export interface IBannerContent {
    image: string;
    destinationType: string;
    destinationUrl: string;
    news: INews;
}

export interface IBanner {
    id: string;
    bannerId: number;
    title: string;
    position: string;
    status: string;
    effectiveDate: string;
    expiryDate: string;
    remark: String;
    th: IBannerContent;
    en: IBannerContent;
    createBy: IUser;
    isExpired: boolean;
}

export interface IBannerContentInput {
    image: string;
    destinationType: string;
    destinationUrl: string;
    newsId: string;
}

export interface IBannerInput {
    title: string;
    position: string;
    status: string;
    effectiveDate: string;
    expiryDate: string;
    remark: String;
    th: IBannerContentInput;
    en: IBannerContentInput;
}

export const mapBannerFromSer = (banner: any) => {
    const { expiryDate, effectiveDate, ...rest } = banner;
    return {
        effectiveDate: moment(effectiveDate),
        expiryDate: expiryDate ? moment(expiryDate) : null,
        ...rest,
    };
};

const mapBannerContentToSer = (content: any) => {
    const { image, destinationType, destinationUrl, news } = content;
    return { image, destinationType, destinationUrl, newsId: news?.id };
};

export const mapBannerToSer = (banner: any): IBannerInput => {
    const {
        title,
        position,
        status,
        effectiveDate,
        expiryDate,
        remark,
        th,
        en,
    } = banner;

    return {
        title,
        position,
        status,
        effectiveDate,
        expiryDate,
        remark,
        th: mapBannerContentToSer(th),
        en: mapBannerContentToSer(en),
    };
};
