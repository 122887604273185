import { Switch } from "antd";
import {
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
    ViewCollapse,
    ViewRow,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import PaymentMethodAPI from "../../../api/paymentmethod/PaymentMethodAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import {
    C2P,
    PAYMENTS_CREDENTIAL_MODES,
    PAYMENTS_METHODS,
} from "../../../constant/payment";
import { AppStateContext } from "../../../context/app";
import { PaymentMethodCrudContext } from "../../../context/paymentmethod";
import { mapPaymentMethodToServer } from "../../../interfaces/paymentmethod";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import PaymentMethodCrudBankAccount from "./PaymentMethodCrudBankAccount";
import PaymentMethodLangButton from "./PaymentMethodLangButton";

const PaymentMethodSchema = Yup.object().shape({
    type: Yup.string().required("Required"),
});

const PaymentMethodCrud = () => {
    const { paymentMethodId } = useParams<any>();
    const { setSiteConfig } = useContext(AppStateContext);
    const [methodLang, setMethodLang] = useState<any>("th");

    const paymentMethodForm = useFormik<any>({
        initialValues: {
            name: null,
            type: null,
            values: [],
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PaymentMethodSchema,
        onSubmit: (values) => {
            const input = mapPaymentMethodToServer(values);
            onUpdatePaymentMethod(input);
        },
    });
    useEffect(() => {
        onLoadPaymentMethodDetail();
    }, []);

    const onLoadPaymentMethodDetail = async () => {
        const paymentMethodRes = await PaymentMethodAPI.detail(paymentMethodId);
        paymentMethodForm.setValues(paymentMethodRes);

        setSiteConfig({
            breadcrumb: [
                {
                    title: Messages.allPaymentMethod,
                    url: Path.PAYMENT_METHOD_TABLE,
                },
                {
                    title: paymentMethodRes?.[Messages.getLanguage()].title,
                },
            ],
        });
    };

    const onUpdatePaymentMethod = (input: any) => {
        Progress.show(
            {
                method: PaymentMethodAPI.update,
                params: [paymentMethodId, input],
            },
            (updatedPaymentMethod: any) => {
                Notifications.showSuccess(Messages.updatePaymentMethodSuccess);
                paymentMethodForm.setValues(updatedPaymentMethod);
            }
        );
    };

    const formValues = paymentMethodForm.values;
    const formLangValues = formValues[methodLang];
    const formErrors = paymentMethodForm.errors as any;

    return (
        <PaymentMethodCrudContext.Provider
            value={{ methodLang, setMethodLang, paymentMethodForm }}
        >
            <div className="p-4">
                <Header
                    title={Messages.paymentMethod}
                    onSave={() => paymentMethodForm.handleSubmit()}
                />
                <PaymentMethodLangButton />
                <ViewCollapse label={Messages.general} className="mt-3">
                    <ViewRow label={Messages.status}>
                        <Switch
                            checked={formValues.status}
                            onChange={() =>
                                paymentMethodForm.setFieldValue(
                                    "status",
                                    !formValues.status
                                )
                            }
                        />
                    </ViewRow>
                    <ViewRow label={Messages.name} className="mt-3">
                        <InputText
                            value={formLangValues?.title ?? ""}
                            error={
                                formErrors?.th?.title || formErrors?.en?.title
                            }
                            onChange={(event: any) =>
                                paymentMethodForm.setFieldValue(
                                    `${methodLang}.title`,
                                    event.target.value
                                )
                            }
                            placeholder={Messages.pleaseInput}
                        />
                    </ViewRow>
                    <ViewRow label={Messages.type} className="mt-3">
                        <Select
                            value={formValues.type}
                            error={formErrors?.type}
                            onChange={(value) =>
                                paymentMethodForm.setFieldValue("type", value)
                            }
                            placeholder={Messages.pleaseInput}
                            dataSource={PAYMENTS_METHODS}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </ViewRow>
                    <ViewRow label={Messages.priority} className="mt-3">
                        <InputText
                            name="priority"
                            value={formValues.priority}
                            error={formErrors?.priority}
                            onChange={(event) =>
                                paymentMethodForm.setFieldValue(
                                    "priority",
                                    parseInt(event.target.value, 10)
                                )
                            }
                            placeholder={Messages.pleaseInput}
                        />
                    </ViewRow>
                    <ViewRow label={Messages.minimumOrder} className="mt-3">
                        <InputText
                            name="minimum"
                            value={formValues.minimum}
                            error={formErrors?.minimum}
                            onChange={(event) =>
                                paymentMethodForm.setFieldValue(
                                    "minimum",
                                    parseInt(event.target.value, 10)
                                )
                            }
                            placeholder={Messages.pleaseInput}
                        />
                    </ViewRow>
                    <ViewRow label={Messages.maximumOrder} className="mt-3">
                        <InputText
                            name="maximum"
                            value={formValues.maximum}
                            error={formErrors?.maximum}
                            onChange={(event) =>
                                paymentMethodForm.setFieldValue(
                                    "maximum",
                                    parseInt(event.target.value, 10)
                                )
                            }
                            placeholder={Messages.pleaseInput}
                        />
                    </ViewRow>
                </ViewCollapse>
                {paymentMethodForm?.values?.type === C2P && (
                    <ViewCollapse
                        label={Messages.c2pAPICredential}
                        className="card-container mt-3"
                        required
                    >
                        <ViewRow label={Messages.merchantId} className="mt-3">
                            <InputTextForm
                                form={paymentMethodForm}
                                keyData="merchantID"
                                label=""
                            />
                        </ViewRow>
                        <ViewRow label={Messages.secretKey} className="mt-3">
                            <InputText
                                onChange={(event) =>
                                    paymentMethodForm.setFieldValue(
                                        "secretKey",
                                        event.target.value
                                    )
                                }
                                error={formErrors?.secretKey}
                                type="password"
                                defaultValue="123456789101111111"
                            />
                        </ViewRow>
                        <ViewRow label={Messages.mode} className="mt-3">
                            <InputSelectForm
                                dataSource={PAYMENTS_CREDENTIAL_MODES}
                                form={paymentMethodForm}
                                keyData="credentialMode"
                            />
                        </ViewRow>
                    </ViewCollapse>
                )}
                <PaymentMethodLangButton />
                <ViewCollapse
                    label={Messages.description}
                    className="card-container mt-3"
                    required
                >
                    <RichTextTiny
                        value={formLangValues?.description ?? ""}
                        onChange={(value: any) =>
                            paymentMethodForm.setFieldValue(
                                `${methodLang}.description`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.productFullDescription}
                        size="large"
                        error={
                            formErrors?.th?.description ||
                            formErrors?.en?.description
                        }
                    />
                </ViewCollapse>
                <PaymentMethodCrudBankAccount />
            </div>
        </PaymentMethodCrudContext.Provider>
    );
};

export default PaymentMethodCrud;
