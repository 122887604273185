import { useContext } from "react";
import { OrderCreateContext } from "../../../context/order";
import UserSelect from "../../user/common/UserSelect";
import Messages from "../../../languages/Messages";

const OrderCreateCoSales = () => {
    const { orderForm } = useContext(OrderCreateContext);
    const { coSales } = orderForm.values;

    return (
        <div className="mt-3">
            <div className="card-container p-4">
                <UserSelect
                    multiple
                    label={Messages.coSales}
                    onChange={(values) =>
                        orderForm.setFieldValue("coSales", values)
                    }
                    value={coSales}
                    error={orderForm.errors.coSales as any}
                />
            </div>
        </div>
    );
};

export default OrderCreateCoSales;
