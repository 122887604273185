import { Tooltip } from "antd";
import {
    Button,
    DateInput,
    Icon,
    InputText,
    Modal,
    Notifications,
    ObjectUtils,
    Progress,
    Select,
    StringUtils,
    TimeUtils,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { find, findIndex, includes, map, reduce, replace } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import Carousel, { Modal as ModalImage, ModalGateway } from "react-images";
import OrderAPI from "../../../api/order/OrderAPI";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import Image from "../../../common/Image";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { ORDER_STATUS } from "../../../constant/order";
import {
    BANK_TRANSFER,
    C2P,
    DEFAULT,
    PAYMENT_STATUS,
    PAYMENT_STATUSES,
} from "../../../constant/payment";
import {
    OrderDetailContext,
    OrderPaymentDetailContext,
} from "../../../context/order";
import {
    OrderPaymentConfirmSchema,
    OrderPaymentProofSchema,
    OrderPaymentRejectSchema,
} from "../../../formschema/order";
import {
    mapOrderPaymentConfirmToServer,
    mapOrderPaymentProofToServer,
    mapOrderPaymentRejectToServer,
} from "../../../interfaces/order";
import {
    IPayment,
    IPaymentProof,
    mapSplitPaymentToSer,
} from "../../../interfaces/payment";
import Messages from "../../../languages/Messages";

export interface IPaymentDetail {
    index: number;
    payment: IPayment;
}
export interface IPaymentDetailUpload {}
export interface IPaymentDetailLog {
    payment: IPayment;
}
export interface IPaymentApproveStatus {
    payment: IPayment;
}

interface IPaymentUploadModal {
    payment: IPayment;
    open: boolean;
    onClose: () => void;
}

interface IPaymentConfirmModal {
    open: boolean;
    onClose: () => void;
}

interface IPaymentRejectModal {
    open: boolean;
    onClose: () => void;
}

interface IPaymentSplitModal {
    open: boolean;
    onClose: () => void;
}
interface IPaymentSlipDetail {
    slip: string;
    proof: IPaymentProof;
    onClick: any;
}

function PaymentUploadProofModal({
    payment,
    open,
    onClose,
}: IPaymentUploadModal) {
    const { metaData, orderDetail, setOrderDetail } =
        useContext(OrderDetailContext);
    const uploadForm = useFormik<any>({
        initialValues: {
            method: payment?.paymentMethod,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderPaymentProofSchema,
        onSubmit: (values: any) => {
            const input = mapOrderPaymentProofToServer(values);
            onUploadProofPayment(input);
        },
    });

    const formValues = uploadForm?.values;
    const formErrors = uploadForm?.errors;

    const isDisabledMethod = payment?.paymentMethod.type !== DEFAULT;
    const isBankTransferType = formValues?.method?.type === BANK_TRANSFER;
    const is2C2PType = formValues?.method?.type === C2P;

    const classNameInput = "col-12 mt-3";

    const onUploadProofPayment = (input: any) => {
        Progress.show(
            {
                method: OrderAPI.createProofPayment,
                params: [orderDetail.id, payment?.id, input],
            },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.uploadProofPaymentSuccess);
                setOrderDetail(orderDetailRes);
                onClose();
            }
        );
    };

    const renderPaymentUploadContent = () => {
        if (formValues?.method?.type === DEFAULT) return <div />;
        return (
            <div className="row">
                <InputSelectForm
                    required
                    keyData="bankAccountId"
                    label={Messages.bankOfTransfer}
                    form={uploadForm}
                    className={classNameInput}
                    dataSource={formValues?.method?.bankAccounts ?? []}
                    hidden={!isBankTransferType}
                    getLabel={(item: any) =>
                        `${item[Messages.getLanguage()].bankName} // ${
                            item?.accountNo
                        }`
                    }
                />
                <InputTextForm
                    required
                    keyData="nameOfPayer"
                    form={uploadForm}
                    className={classNameInput}
                />

                {/* <InputSelectForm
                    keyData="merchantName"
                    form={uploadForm}
                    className={classNameInput}
                    dataSource={[]}
                    hidden={!is2C2PType}
                /> */}
                <InputTextForm
                    required
                    keyData="transactionId"
                    label={Messages.c2pTransactionId}
                    form={uploadForm}
                    className={classNameInput}
                    hidden={!is2C2PType}
                />
                <DateInput
                    required
                    label={Messages.dateOfPayment}
                    onChange={(val) =>
                        uploadForm.setFieldValue(
                            "dateOfPayment",
                            moment(val).toISOString()
                        )
                    }
                    value={
                        formValues.dateOfPayment
                            ? (moment(formValues.dateOfPayment) as any)
                            : null
                    }
                    className={classNameInput}
                    error={formErrors.dateOfPayment as any}
                />

                <div className={classNameInput}>
                    <label>{Messages.proof}</label>
                    <ButtonFileUpload
                        onChange={(value: any) =>
                            uploadForm.setFieldValue("attachment", value)
                        }
                        maxFiles={5}
                        className="mt-3"
                    />
                </div>

                <InputTextForm
                    required
                    keyData="remark"
                    form={uploadForm}
                    className={classNameInput}
                    multiple
                />
            </div>
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.upload}
            onSave={() => uploadForm.handleSubmit()}
        >
            <div className="row">
                <Select
                    className={classNameInput}
                    dataSource={metaData.paymentMethods}
                    getLabel={(item: any) => item[Messages.getLanguage()].title}
                    disabled={isDisabledMethod}
                    getDisableOption={(item: any) => item.type === DEFAULT}
                    onChange={(id) => {
                        const method = find(
                            metaData.paymentMethods,
                            (item) => item.id === id
                        );
                        method && uploadForm.setFieldValue("method", method);
                    }}
                    value={formValues?.method?.id}
                    label={Messages.method}
                />
            </div>
            {renderPaymentUploadContent()}
        </Modal>
    );
}

const PaymentSlipDetail = ({ slip, proof, onClick }: IPaymentSlipDetail) => {
    const {
        nameOfPayer,
        bankTransferred,
        dateOfPayment,
        remark,
        transactionId,
    } = proof;

    const renderRowItem = (title: any, content: any, hidden = false) => (
        <div hidden={hidden}>{`${title}: ${content}`}</div>
    );
    const proofInfo = (
        <div className="flex-column">
            {renderRowItem(
                Messages.bankOfTransfer,
                (bankTransferred as any)?.[Messages.getLanguage()]?.bankName,
                !bankTransferred
            )}
            {renderRowItem(Messages.nameOfPayer, nameOfPayer)}
            {renderRowItem(Messages.transaction, transactionId)}
            {renderRowItem(
                Messages.dateOfPayment,
                TimeUtils.toDateTime(dateOfPayment)
            )}
            {renderRowItem(Messages.remark, remark)}
        </div>
    );
    return (
        <div className="mr-3 position-relative" onClick={onClick}>
            <Image src={slip} className="image-reg-large" />
            <Tooltip title={proofInfo}>
                <Icon name="info" className="absolute-right" />
            </Tooltip>
        </div>
    );
};

const PaymentUploadProof = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { payment } = useContext(OrderPaymentDetailContext);
    const [openUpload, setOpenUpload] = useState(false);
    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });

    const { status, confirmation, rejection } = payment;

    const showUploadProof = useMemo(
        () =>
            status !== PAYMENT_STATUS.CONFIRMED &&
            status !== PAYMENT_STATUS.REJECTED &&
            orderDetail?.status !== ORDER_STATUS.CANCELLED,
        [payment.status, orderDetail?.status]
    );

    const mapProofImage = (url: string) => ({
        source: url,
        caption: "",
    });

    const allProofConfirmReject = [
        ...(confirmation?.proofOfPaymentReceived ?? []),
        ...(rejection?.proofOfCancellation ?? []),
    ];

    const allPaymentSlip = reduce(
        payment.proofPayment,
        (slips: any, item: any) => {
            const result = map(item.slip, mapProofImage);
            return [...slips, ...result];
        },
        []
    ).concat(map(allProofConfirmReject, mapProofImage));

    const onClickSlip = (slip: string) =>
        setImageToView({
            isVisible: true,
            index: findIndex(allPaymentSlip, (item) => item.source === slip),
        });

    return (
        <div className="flex-column">
            <div className="d-flex overflow-scroll pt-3 scroll-hide-indicator">
                {map(
                    (payment.proofPayment ?? []) as any,
                    (proof: IPaymentProof) =>
                        map(proof?.slip ?? [], (slip) => (
                            <PaymentSlipDetail
                                slip={slip}
                                proof={proof}
                                onClick={() => onClickSlip(slip)}
                            />
                        ))
                )}
                {map(allProofConfirmReject, (url) => (
                    <Image
                        src={url}
                        onClick={() => onClickSlip(url)}
                        className="image-reg-large"
                    />
                ))}
                <ModalGateway>
                    {imageToView.isVisible ? (
                        <ModalImage
                            onClose={() => setImageToView({ isVisible: false })}
                        >
                            <Carousel
                                views={allPaymentSlip}
                                currentIndex={imageToView.index}
                            />
                        </ModalImage>
                    ) : null}
                </ModalGateway>
            </div>
            <Button
                onClick={() => setOpenUpload(true)}
                className="mt-3"
                iconName="cloud_upload"
                hidden={!showUploadProof}
            >
                {Messages.upload}
            </Button>
            {openUpload && (
                <PaymentUploadProofModal
                    open={openUpload}
                    onClose={() => setOpenUpload(false)}
                    payment={payment}
                />
            )}
        </div>
    );
};

function PaymentConfirmModal({ open, onClose }: IPaymentConfirmModal) {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const { payment } = useContext(OrderPaymentDetailContext);
    const { paymentMethod } = payment;

    const confirmForm = useFormik<any>({
        initialValues: { method: paymentMethod } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderPaymentConfirmSchema,
        onSubmit: (values: any) => {
            const input = mapOrderPaymentConfirmToServer(values);
            onConfirmPayment(input);
        },
    });
    const formValues = confirmForm?.values;
    const formErrors = confirmForm?.errors;

    const isBankTransferType = paymentMethod?.type === BANK_TRANSFER;
    const is2C2PType = paymentMethod?.type === C2P;

    const classNameInput = "col-12 mt-3";

    const onConfirmPayment = (input: any) => {
        Progress.show(
            {
                method: OrderAPI.confirmProofPayment,
                params: [orderDetail?.id, payment?.id, input],
            },
            (res: any) => {
                onClose();
                setOrderDetail(res);
                Notifications.showSuccess(Messages.confirmPaymentSuccess);
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.paymentConfirmation}
            onSave={() => confirmForm.handleSubmit()}
        >
            <div className="row">
                <div className="col-12">
                    {Messages.areYouCertainConfirmPayment}
                </div>

                <div className={classNameInput}>
                    <label>{Messages.proofOfPaymentReceive}</label>
                    <ButtonFileUpload
                        onChange={(value: any) =>
                            confirmForm.setFieldValue("attachment", value)
                        }
                        maxFiles={1}
                        className="mt-3"
                    />
                    <ViewTextError error={formErrors.attachment} />
                </div>

                <InputSelectForm
                    keyData="bankAccountId"
                    label={Messages.actualBankOfReceivingPayment}
                    form={confirmForm}
                    className={classNameInput}
                    dataSource={paymentMethod?.bankAccounts ?? []}
                    hidden={!isBankTransferType}
                    getLabel={(item: any) =>
                        `${item[Messages.getLanguage()].bankName} // ${
                            item?.accountNo
                        }`
                    }
                    required
                />
                {/* <InputSelectForm
                    keyData="merchant"
                    label={Messages.actual2C2PMerchantOfReceivingPayment}
                    form={confirmForm}
                    className={classNameInput}
                    dataSource={paymentMethod?.bankAccounts ?? []}
                    hidden={!is2C2PType}
                    getLabel={(item: any) =>
                        item[Messages.getLanguage()].bankName
                    }
                /> */}
                <DateInput
                    label={Messages.actualDateOfReceivingPayment}
                    onChange={(val) =>
                        confirmForm.setFieldValue(
                            "dateOfReceiving",
                            moment(val).toISOString()
                        )
                    }
                    value={
                        formValues.dateOfReceiving
                            ? moment(formValues.dateOfReceiving) as any
                            : null
                    }
                    className={classNameInput}
                    error={formErrors.dateOfReceiving as any}
                    required
                />
                <InputTextForm
                    keyData="transactionId"
                    form={confirmForm}
                    className={classNameInput}
                    hidden={!is2C2PType}
                    required
                />
                <InputTextForm
                    keyData="remark"
                    multiple
                    form={confirmForm}
                    className={classNameInput}
                    required
                />
            </div>
        </Modal>
    );
}

const PaymentConfirm = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    return (
        <div className="flex-column">
            <Button onClick={() => setOpenConfirm(true)}>
                {Messages.confirm}
            </Button>
            {openConfirm && (
                <PaymentConfirmModal
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                />
            )}
        </div>
    );
};

const PaymentRejectModal = ({ open, onClose }: IPaymentRejectModal) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const { payment } = useContext(OrderPaymentDetailContext);
    const rejectForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderPaymentRejectSchema,
        onSubmit: (values: any) => {
            const input = mapOrderPaymentRejectToServer(values);
            onRejectPayment(input);
        },
    });

    const formValues = rejectForm?.values;
    const formErrors = rejectForm?.errors;

    const onRejectPayment = (input: any) => {
        Progress.show(
            {
                method: OrderAPI.rejectProofPayment,
                params: [orderDetail?.id, payment?.id, input],
            },
            (res: any) => {
                onClose();
                Notifications.showSuccess(Messages.rejectPaymentSuccess);
                setOrderDetail(res);
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirmRejectPayment}
            onSave={() => rejectForm.handleSubmit()}
        >
            <text>{Messages.areYouCertainRejectPayment}</text>
            <ButtonFileUpload
                onChange={(value: any) =>
                    rejectForm.setFieldValue("attachment", value)
                }
                maxFiles={1}
                className="mt-3"
            />
            <ViewTextError error={formErrors.attachment} />
            <InputTextForm
                required
                keyData="remark"
                form={rejectForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const PaymentReject = () => {
    const [openReject, setOpenReject] = useState(false);
    return (
        <div className="flex-column">
            <Button
                onClick={() => setOpenReject(true)}
                className="ml-3"
                color="dark"
            >
                {Messages.reject}
            </Button>
            {openReject && (
                <PaymentRejectModal
                    open={openReject}
                    onClose={() => setOpenReject(false)}
                />
            )}
        </div>
    );
};

const PaymentLog = () => {
    const { payment } = useContext(OrderPaymentDetailContext);
    const [openLog, setOpenLog] = useState(false);
    const buttonContent = Messages.view2C2PLog;
    let hidden = true;

    if (
        payment.status === PAYMENT_STATUS.CONFIRMED &&
        payment.paymentGatewayLogs &&
        payment.paymentGatewayLogs.length > 0
    ) {
        hidden = false;
    }

    if (hidden) {
        return <div />;
    }

    const renderLogContent = (logItem: any) => {
        const {
            respDesc,
            merchantID,
            tranRef,
            respCode,
            amount,
            eci,
            transactionDateTime,
            approvalCode,
        } = logItem ?? {};
        const formatDateTime = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/g;
        return (
            <div className="flex-column border-bottom pb-3">
                <div>{`2C2P Payment Status ${respDesc}`}</div>
                <div>{`Order ID ${merchantID}`}</div>
                <div>{`Transaction Ref ${tranRef}`}</div>
                <div>{`Payment Status ${respCode}`}</div>
                <div>{`Amount ${amount}`}</div>
                <div>{`ECI ${eci}`}</div>
                <div>{`Transaction Datetime ${replace(
                    transactionDateTime,
                    formatDateTime,
                    "$1-$2-$3 $4:$5:$6"
                )}`}</div>
                <div>{`Approve code ${approvalCode}`}</div>
            </div>
        );
    };

    return (
        <div className="flex-column mt-3">
            <Button onClick={() => setOpenLog(true)} className="">
                {buttonContent}
            </Button>
            <Modal
                open={openLog}
                onClose={() => setOpenLog(false)}
                title={Messages.view2C2PLog}
            >
                {map(payment.paymentGatewayLogs, renderLogContent)}
            </Modal>
        </div>
    );
};

const PaymentApproveStatus = () => {
    const { payment } = useContext(OrderPaymentDetailContext);
    const { status, confirmation, paymentMethod, proofPayment } = payment;
    const { bankAccount, approvalCode } = confirmation ?? {};
    const bankAccountInfo =
        (bankAccount as any)?.[Messages.getLanguage()] ?? {};
    return (
        <div
            hidden={status !== PAYMENT_STATUS.CONFIRMED || !confirmation}
            className="mt-3"
        >
            <div
                className="text-success"
                hidden={paymentMethod?.type !== C2P || proofPayment?.length > 0}
            >
                {Messages.successBy2C2P}
            </div>
            <div className="text-success" hidden={!approvalCode}>
                {`2C2P Approval Code: ${approvalCode}`}
            </div>
            <div className="text-success">
                {`Actual date of receiving payment: ${TimeUtils.toDateTime(
                    confirmation?.dateOfReceiving
                )}`}
            </div>
            <div className="text-success" hidden={!bankAccount}>
                {`Actual bank of receiving payment: ${bankAccount?.accountNo} (${bankAccountInfo?.bankName} - ${bankAccountInfo?.accountName} )`}
            </div>
        </div>
    );
};

const PaymentDetail = (props: IPaymentDetail) => {
    const { index, payment } = props;
    const {
        amount,
        paymentMethod,
        status,
        confirmation,
        rejection,
        paymentRef,
    } = payment;

    const showConfirmReject = useMemo(
        () => status === PAYMENT_STATUS.PENDING_CONFIRM,
        [status]
    );

    const paymentStatus = find(PAYMENT_STATUSES, (item) => item.id === status);

    const renderPaymentInfoRow = (title: any, content: any) => {
        return (
            <div className="d-flex">
                <text className="font-weight-bold">{`${title}: `}</text>
                <div className="ml-1">{content}</div>
            </div>
        );
    };

    const renderConfirmResult = () => {
        if (!confirmation || status !== PAYMENT_STATUS.CONFIRMED) {
            return <div hidden />;
        }

        const { confirmedBy, createdAt, remark } = confirmation;
        return (
            <div className="text-success">{`${
                Messages.confirmed
            } by ${confirmedBy} at ${TimeUtils.toDate(
                createdAt
            )} "${remark}"`}</div>
        );
    };
    const renderRejectResult = () => {
        if (!rejection || status !== PAYMENT_STATUS.REJECTED) {
            return <div hidden />;
        }

        const { rejectedBy, createdAt, remark } = rejection;
        return (
            <div className="text-error ml-3">{`${
                Messages.rejected
            } by ${rejectedBy} at ${TimeUtils.toDate(
                createdAt
            )} "${remark}"`}</div>
        );
    };

    return (
        <OrderPaymentDetailContext.Provider value={{ payment }}>
            <div className="border-dashed p-4 mt-3">
                <div className="flex-center-y justify-content-between">
                    <h5 className="text-uppercase w-50">{`${Messages.payment} ${index}`}</h5>
                    <div className="d-flex" hidden={!showConfirmReject}>
                        <PaymentConfirm />
                        <PaymentReject />
                    </div>
                    {renderConfirmResult()}
                    {renderRejectResult()}
                </div>
                <div className="flex-column mt-3">
                    {renderPaymentInfoRow(
                        Messages.amount,
                        StringUtils.moneyThaiFormat(amount)
                    )}
                    {renderPaymentInfoRow(
                        Messages.status,
                        <div
                            style={{
                                color: paymentStatus?.color ?? "#fff",
                            }}
                        >
                            {(Messages as any)[paymentStatus?.label ?? ""]}
                        </div>
                    )}
                    {renderPaymentInfoRow(Messages.paymentRef, paymentRef)}
                    {renderPaymentInfoRow(
                        Messages.method,
                        (paymentMethod as any)?.[Messages.getLanguage()].title
                    )}
                    {confirmation?.transactionId &&
                        renderPaymentInfoRow(
                            Messages.c2pTransactionId,
                            confirmation?.transactionId
                        )}
                    <PaymentUploadProof />
                    <PaymentApproveStatus />
                    <PaymentLog />
                </div>
            </div>
        </OrderPaymentDetailContext.Provider>
    );
};

const PaymentSplitInput = ({
    index,
    onClickRemove,
    payment,
    onChangeValue,
}: any) => {
    const { status, amount, paymentMethod } = payment;
    const { metaData } = useContext(OrderDetailContext);
    const disabled = status === PAYMENT_STATUS.CONFIRMED;

    const renderAdditionalInput = () => {
        switch (paymentMethod?.type) {
            default:
                return <div />;
        }
    };

    return (
        <div className="flex-column mt-3">
            <div className="d-flex flex-center-y border-bottom border-primary">
                <label className="text-primary text-uppercase w-100">{`${
                    Messages.payment
                } ${index + 1}`}</label>
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={onClickRemove}
                    disabled={disabled}
                />
            </div>
            <Select
                className="mt-3"
                label={Messages.method}
                dataSource={metaData?.paymentMethods}
                value={paymentMethod.id}
                getLabel={(item: any) =>
                    item[Messages.getLanguage()].title ?? "Default"
                }
                onChange={(id) => {
                    const selected = ObjectUtils.findItemFromId(
                        metaData?.paymentMethods,
                        id
                    );
                    onChangeValue("paymentMethod", selected);
                }}
                disabled={disabled}
                allowClear={false}
                getDisableOption={(item) => item.type === DEFAULT}
            />

            <InputText
                className="mt-3"
                label={Messages.amount}
                value={amount.toLocaleString()}
                onChange={(event) => {
                    const result = event.target.value.replace(/\D/g, "");
                    let value = parseInt(result, 10);
                    if (!value) {
                        value = 0;
                    }
                    onChangeValue("amount", value);
                }}
                suffix="฿"
                disabled={disabled}
            />
            {renderAdditionalInput()}
        </div>
    );
};

const PaymentSplitModal = ({ open, onClose }: IPaymentSplitModal) => {
    const { orderDetail, metaData, setOrderDetail } =
        useContext(OrderDetailContext);
    const [listPayment, setListPayment] = useState<IPayment[]>(
        orderDetail?.splitPayment ?? []
    );

    const oldPayment: any[] = [];
    const getPaidFromPayments = (payments: any[]) => {
        if (!payments || payments.length === 0) return 0;
        let paid = 0;
        payments.forEach((item) => {
            if (item.status === PAYMENT_STATUS.CONFIRMED) {
                paid += item.total;
            }
        });
        return paid;
    };

    const orderTotal = useMemo(() => {
        const totalAmount = orderDetail?.total ?? 0;
        const paid = getPaidFromPayments(oldPayment);
        return totalAmount - paid;
    }, []);

    const totalAmountPayment = useMemo(
        () => reduce(listPayment, (sum, item) => sum + item.amount, 0),
        [listPayment]
    );

    const remainingAmount = useMemo(
        () => orderTotal - totalAmountPayment,
        [totalAmountPayment]
    );

    const onRemovePayment = (paymentId: string) => {
        const payments = ObjectUtils.removeArrayById(listPayment, paymentId);
        setListPayment(payments);
    };

    const onUpdatePayment = (key: string, value: string, paymentId: string) => {
        const oldValue =
            find(listPayment, (item) => item.id === paymentId)?.amount ?? 0;

        const differOldValue =
            totalAmountPayment - oldValue + parseInt(value, 10);

        if (key === "amount" && differOldValue > Math.round(orderTotal)) {
            Notifications.showError(Messages.paidAmountCannotGreater);
            return;
        }
        const cloneList = map(listPayment, (item: any) => {
            if (item.id === paymentId) {
                return { ...item, [key]: value };
            }
            return item;
        });
        setListPayment(cloneList);
    };

    const getPaymentDefault = () => ({
        id: StringUtils.getUniqueID(),
        amount: 0,
        paymentMethod: metaData?.paymentMethods?.[0],
    });

    const onAddPayment = () => {
        const newPayment = getPaymentDefault() as IPayment;
        setListPayment([...listPayment, newPayment]);
        setTimeout(() => {
            scrollSplitListBottom();
        }, 200);
    };

    const scrollSplitListBottom = () => {
        const element = document.getElementById("scrollPaymentList");
        if (element) {
            element.scrollTop = element.scrollHeight - element.clientHeight;
        }
    };

    const isValidateSplitPayment = () => {
        const arrayTotal = map(listPayment, (item) => item?.amount);
        const arrayMethod = map(
            listPayment,
            (item) => item?.paymentMethod?.type
        );
        if (includes(arrayTotal, 0)) {
            Notifications.showError(Messages.fillAllPaymentAmount);
            return false;
        }
        if (remainingAmount > 0) {
            Notifications.showError(Messages.paymentIsNotEnough);
            return false;
        }

        const isContainDefault = includes(arrayMethod, DEFAULT);
        if (isContainDefault) {
            Notifications.showError(Messages.notAllowDefaultPaymentSplit);
            return false;
        }
        return true;
    };

    const onClickSplitPayment = () => {
        const isValid = isValidateSplitPayment();
        if (!isValid) return;

        onSplitPayment();
    };

    const onSplitPayment = () => {
        const payments = map(listPayment, mapSplitPaymentToSer);
        Progress.show(
            {
                method: OrderAPI.splitPayment,
                params: [orderDetail?.id, payments],
            },
            (orderDetailRes: any) => {
                setOrderDetail(orderDetailRes);
                onClose();
                Notifications.showSuccess(Messages.splitPaymentSuccess);
            }
        );
    };

    const renderSummaryAmount = (title: string, content: any) => (
        <div className="d-flex justify-content-between py-3 border-top-dashed">
            <label className="text-primary">{title}</label>
            <text>{content}</text>
        </div>
    );
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.splitPayment}
            onSave={onClickSplitPayment}
            classNameContent="overflow-initial flex-column"
        >
            <div className="px-3 py-1 bg-muted w-100">
                <div className="d-flex justify-content-between py-3">
                    <label className="text-primary">{Messages.totalCart}</label>
                    <text>{StringUtils.moneyThaiFormat(orderTotal)}</text>
                </div>
                {renderSummaryAmount(
                    Messages.splitAmount,
                    StringUtils.moneyThaiFormat(totalAmountPayment)
                )}
                {renderSummaryAmount(
                    Messages.remainingAmount,
                    StringUtils.moneyThaiFormat(remainingAmount)
                )}
            </div>
            <div
                className="mt-3 overflow-scroll scroll-hide-indicator border-top border-bottom"
                id="scrollPaymentList"
            >
                <div className="flex-column border-bottom pb-3">
                    {map(listPayment, (payment: any, index) => (
                        <PaymentSplitInput
                            index={index}
                            onClickRemove={() => onRemovePayment(payment.id)}
                            payment={payment}
                            paymentMethods={[]}
                            onChangeValue={(key: string, value: any) =>
                                onUpdatePayment(key, value, payment?.id)
                            }
                        />
                    ))}
                </div>
            </div>
            <div
                onClick={onAddPayment}
                className="cursor-pointer text-primary mt-3"
            >
                {Messages.splitMore}
            </div>
        </Modal>
    );
};

const OrderPaymentSplit = () => {
    const { orderDetail } = useContext(OrderDetailContext);

    const [openSplit, setOpenSplit] = useState(false);

    if (
        orderDetail?.status !== ORDER_STATUS.PENDING_PAYMENT &&
        orderDetail?.status !== ORDER_STATUS.PENDING_CONFIRMATION &&
        orderDetail?.status !== ORDER_STATUS.PARTIALLY_PAID
    ) {
        return <div />;
    }

    return (
        <div>
            <Button iconName="call_split" onClick={() => setOpenSplit(true)}>
                {Messages.splitPayment}
            </Button>
            {openSplit && (
                <PaymentSplitModal
                    open={openSplit}
                    onClose={() => setOpenSplit(false)}
                />
            )}
        </div>
    );
};

const OrderDetailPayment = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { splitPayment } = orderDetail;
    return (
        <div className="card-container mt-3 p-4">
            <div className="d-flex justify-content-between align-items-center ">
                <label>{Messages.customerPayment}</label>
                <OrderPaymentSplit />
            </div>

            {map(splitPayment, (payment, index) => (
                <PaymentDetail index={index + 1} payment={payment} />
            ))}
        </div>
    );
};

export default OrderDetailPayment;
