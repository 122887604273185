import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import { VOUCHER_CREATE_TYPE } from "../../../constant/voucher";
import { VoucherCreateContext } from "../../../context/voucher";
import { VoucherCreateSchema } from "../../../formschema/voucher";
import {
    mapBulkVoucherToServer,
    mapVoucherToServer,
} from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import VoucherCreateCustomer from "./VoucherCreateCustomer";
import VoucherCreateGeneral from "./VoucherCreateGeneral";
import VoucherCreateProducts from "./VoucherCreateProducts";

const VoucherCreate = () => {
    const history = useHistory();
    const voucherForm = useFormik<any>({
        initialValues: {
            products: [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VoucherCreateSchema,
        onSubmit: (values: any) => {
            if (values?.createType === VOUCHER_CREATE_TYPE.MANUAL) {
                const body = mapVoucherToServer(values);
                onCreateVoucher(body);
            }

            if (values?.createType === VOUCHER_CREATE_TYPE.GENERATE_BY_BULK) {
                const body = mapBulkVoucherToServer(values);
                onGenerateBulkVoucher(body);
            }
        },
    });

    const onCreateVoucher = (input: any) => {
        Progress.show(
            { method: VoucherAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createVoucherSuccess);
                history.replace(
                    generatePath(Path.VOUCHER_DETAIL, { voucherId: id })
                );
            }
        );
    };

    const onGenerateBulkVoucher = (input: any) => {
        Progress.show(
            { method: VoucherAPI.generateBulkVoucher, params: [input] },
            () => {
                Notifications.showSuccess(Messages.createVoucherSuccess);
                history.replace(generatePath(Path.VOUCHER_TABLE));
            }
        );
    };

    return (
        <VoucherCreateContext.Provider value={{ voucherForm }}>
            <Header
                title={Messages.newVoucher}
                onSave={() => voucherForm.handleSubmit()}
            />
            <div className="h-100 overflow-auto pb-5">
                <div className="p-4">
                    <VoucherCreateGeneral />
                    <VoucherCreateProducts />
                    <VoucherCreateCustomer />
                </div>
            </div>
        </VoucherCreateContext.Provider>
    );
};

export default VoucherCreate;
