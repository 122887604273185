import { Button, InputText, ViewTextError } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import { WorksheetMileageSchema } from "../../../formschema/worksheet";
import { IWorksheetMileage } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";

interface IWorksheetMileForm {
    disabled: boolean;
    onSubmit: (values: IWorksheetMileage) => void;
}

const WorksheetMileageForm = ({ disabled, onSubmit }: IWorksheetMileForm) => {
    const mileageForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: WorksheetMileageSchema,
        onSubmit: (values: any) => {
            const input = {
                mile: values?.mile,
                proves: map(values?.proves, (item) => item.url),
            };
            onSubmit(input);
        },
    });
    const formValues = mileageForm?.values;
    const formErrors = mileageForm?.errors;
    return (
        <div>
            <InputText
                disabled={disabled}
                label={Messages.mile}
                value={formValues?.mile}
                className="w-100"
                onChange={(event) => {
                    const { value } = event.target;

                    mileageForm.setFieldValue(
                        "mile",
                        parseInt(`0${value}`, 10)
                    );
                }}
                error={formErrors?.mile as any}
                placeholder={Messages.pleaseInput}
            />
            <ButtonFileUpload
                disabled={disabled}
                onChange={(value: any) =>
                    mileageForm.setFieldValue("proves", value)
                }
                maxFiles={1}
                className="mt-3"
            />
            <ViewTextError error={formErrors?.proves as any} />
            <div className="d-flex justify-content-end mt-3">
                <Button
                    onClick={() => mileageForm.handleSubmit()}
                    disabled={disabled}
                >
                    {Messages.submit}
                </Button>
            </div>
        </div>
    );
};

export default WorksheetMileageForm;
