import React from "react";
import { getFullAddress } from "../../interfaces/address";
import Messages from "../../languages/Messages";

const AddressServiceInfoView = ({ address }: any) => {
    if (!address) return <div />;
    const { firstName, lastName, phone, nameOfPlace, remark } = address;
    return (
        <div className="flex-column">
            <text>{`${firstName} ${lastName}`}</text>
            <text>{phone}</text>
            <text>{getFullAddress(address)}</text>
            <text>{nameOfPlace}</text>
            <div
                hidden={!remark}
                className="p-2 text bg-note"
            >{`${Messages.remark}: ${remark}`}</div>
        </div>
    );
};

export default AddressServiceInfoView;
