import { Checkbox, Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { AddressServiceSchema } from "../../../formschema/customer";
import { mapAddressToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import CustomerAddressForm from "./CustomerAddressForm";

const CustomerCrudAddressModal = ({
    open,
    customerId,
    onClose,
    isEdit,
    isShowRemark,
    isShowSaveAsNew,
    addressDefault = {},
    onCrudSuccess,
}: any) => {
    // const [addressList, setAddressList] = useState<IServiceAddress[]>([]);
    // const [addressSelect, setAddressSelect] = useState<string>();
    const addressForm = useFormik<any>({
        initialValues: addressDefault,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddressServiceSchema,
        onSubmit: (values) => {
            if (isEdit) {
                onClose();
                onCrudSuccess(values);
            } else {
                onCreateAddress(values);
            }
        },
    });

    // useEffect(() => {
    //     onLoadAddressList();
    // }, []);

    // const onLoadAddressList = () => {
    //     if (!customerId) return;

    //     CustomerAPI.serviceAddresses(customerId).then(setAddressList);
    // };

    const onCreateAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.createServiceAddress,
                params: [customerId, mapAddressToSer(body)],
            },
            () => {
                Notifications.showSuccess(
                    Messages.createCustomerAddressSuccess
                );
                onClose();
                onCrudSuccess && onCrudSuccess();
            }
        );
    };

    // const onChangeAddressSelect = (id: string) => {
    //     const address = ObjectUtils.findItemFromId(addressList, id);
    //     addressForm.setValues(address);
    //     setAddressSelect(id);
    // };

    const renderSideModal = () => {
        if (!isShowSaveAsNew) return <div />;
        return (
            <Checkbox
                label={Messages.saveAsNewAddress}
                checked={addressForm.values.saveNewAddress}
                onChange={() =>
                    addressForm.setFieldValue(
                        "saveNewAddress",
                        !addressForm.values.saveNewAddress
                    )
                }
            />
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateAddress}
            size="medium"
            onSave={() => addressForm.handleSubmit()}
            customSideButton={renderSideModal}
        >
            {/* <Select
                dataSource={addressList}
                value={addressSelect}
                getLabel={getFullAddress}
                label={Messages.serviceShippingProfile}
                onChange={onChangeAddressSelect}
            /> */}
            <CustomerAddressForm
                formData={addressForm}
                show={{ remark: isShowRemark }}
            />
        </Modal>
    );
};

export default CustomerCrudAddressModal;
