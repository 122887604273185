import { useContext } from "react";
import LanguageSelectButton from "../../common/button/LanguageSelectButton";
import { SmartSupportContext } from "./SmartSupport";

const SmartSupportLanguageButton = () => {
    const { setSmartSupportLang, smartSupportLang } = useContext(
        SmartSupportContext
    );
    return (
        <LanguageSelectButton
            lang={smartSupportLang}
            onChange={setSmartSupportLang}
        />
    );
};

export default SmartSupportLanguageButton;
