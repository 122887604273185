import { gql } from "@apollo/client";
import { mapBannerFromSer } from "../../interfaces/banner";
import API from "../API";
import { F_BANNER, F_BANNERS } from "./BannerFragment";

const detailConvertor = {
    fromServer: (res: any) => mapBannerFromSer(res?.data?.banner),
};

export default {
    list: (q: string, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.query({
            query: gql`
                ${F_BANNERS}
                query banners($q: String, $page: Int, $limit: Int) {
                    data: banners(q: $q, page: $page, limit: $limit) {
                        ...F_BANNERS
                    }
                }
            `,
            variables: { q, page: paging.pageIndex, limit: paging.pageSize },
            fetchPolicy: "no-cache",
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_BANNER}
                query banner($id: ID!) {
                    banner(id: $id) {
                        ...F_BANNER
                    }
                }
            `,
            variables: { id },
        }),

    create: (input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BANNER}
                mutation createBanner($input: BannerInput) {
                    banner: createBanner(input: $input) {
                        ...F_BANNER
                    }
                }
            `,
            variables: { input },
        }),

    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BANNER}
                mutation updateBanner($id: ID!, $input: BannerInput) {
                    banner: updateBanner(id: $id, input: $input) {
                        ...F_BANNER
                    }
                }
            `,
            variables: { input, id },
        }),
};
