import {
    AwesomeTableComponent,
    IColumnsProps,
    InputTextSearch,
} from "d-react-components";
import { filter, includes, isEmpty } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import { VoucherCreateContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/common/ProductNameView";
import ProductSearchButton from "../../product/common/ProductSearchButton";
import { PRODUCT_TYPE } from "../../../constant/product";

const VoucherCreateProducts = () => {
    const { voucherForm } = useContext(VoucherCreateContext);
    const { products } = voucherForm?.values;
    const tableRef = useRef<any>();
    const [textSearch, setTextSearch] = useState();

    const productsSource = useMemo(() => {
        if (isEmpty(textSearch)) return products;
        return filter(products, (item) => includes(item?.SKU, textSearch));
    }, [textSearch, products]);

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
    ];

    const onChangeProduct = (productsParam: any) => {
        voucherForm.setFieldValue("products", productsParam);
    };

    return (
        <div className="card-container p-4 mt-3">
            <label>{Messages.products}</label>
            <div className="mt-3 d-flex">
                <InputTextSearch
                    onChange={(event: any) => {
                        setTextSearch(event.target.value);
                    }}
                />
                <ProductSearchButton
                    onSave={onChangeProduct}
                    defaultValue={products}
                    multiple={false}
                    filterSource={{
                        productType: [
                            PRODUCT_TYPE.SERVICE_PRODUCT,
                            PRODUCT_TYPE.PREMIUM_SERVICE,
                        ],
                    }}
                />
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={productsSource}
                className="mt-3"
                pagination={false}
            />
        </div>
    );
};

export default VoucherCreateProducts;
