import { CheckboxGroup, Modal } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { PRODUCT_TYPES, PRODUCT_VISIBILITIES } from "../../../constant/product";
import Messages from "../../../languages/Messages";
import CategoryCheckboxGroup from "../../category/common/CategoryCheckboxGroup";
import ProductCategoryCheckboxGroup from "../category/ProductCategoryCheckboxGroup";

interface IProductFilterModal {
    open: boolean;
    defaultValue: any;
    onClose: () => void;
    onFilter: (values: any) => void;
}

function ProductFilterModal({
    open,
    defaultValue,
    onClose,
    onFilter,
}: IProductFilterModal) {
    const filterForm = useFormik<any>({
        initialValues: {
            serviceCategory: [],
            category: [],
            status: null,
            ...(defaultValue ?? {}),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            onFilter(values);
            onClose();
        },
    });
    const formValue = filterForm?.values;

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm.handleSubmit()}
            size="medium"
            sideText={Messages.clearAll}
            onSideClick={() => filterForm.setValues({})}
        >
            <div className="flex-column">
                <CheckboxGroup
                    numberOfColumns="2"
                    dataSource={PRODUCT_TYPES}
                    label={Messages.productType}
                    className="mt-3"
                    value={formValue?.productType}
                    onChange={(value) =>
                        filterForm.setFieldValue("productType", value)
                    }
                    getLabel={(item) => (Messages as any)[item.label]}
                />
                <div className="divider mt-3" />
                <CategoryCheckboxGroup
                    className="mt-3"
                    value={formValue?.serviceIds}
                    onChange={(value) =>
                        filterForm.setFieldValue("serviceIds", value)
                    }
                />
                <div className="divider mt-3" />
                <ProductCategoryCheckboxGroup
                    className="mt-3"
                    value={formValue?.categoryIds}
                    onChange={(value) =>
                        filterForm.setFieldValue("categoryIds", value)
                    }
                />
                <div className="divider mt-3" />
                <CheckboxGroup
                    numberOfColumns="2"
                    dataSource={PRODUCT_VISIBILITIES}
                    label={Messages.visibility}
                    className="mt-3"
                    value={formValue?.visibility}
                    getLabel={(item) => (Messages as any)[item.label]}
                    onChange={(value) =>
                        filterForm.setFieldValue("visibility", value)
                    }
                />
                <div className="divider mt-3" />
            </div>
        </Modal>
    );
}

export default ProductFilterModal;
