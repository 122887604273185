import { gql } from "@apollo/client";
import { F_ADDRESS, F_BILLING } from "../address/AddressFragment";
import { F_CATEGORY } from "../category/CategoryFragment";
import { F_CUSTOMER } from "../customer/CustomerFragment";
import { F_PAGINATION } from "../Fragment";
import { F_PRODUCT } from "../product/ProductFragment";
import { F_USER, F_USER_ITEM } from "../user/UserFragment";

export const F_WORKSHEET_ITEM = gql`
    ${F_CATEGORY}
    fragment F_WORKSHEET_ITEM on Worksheet {
        id
        worksheetNo
        booking {
            id
            bookingNo
            customer {
                id
                firstName
                lastName
            }
        }
        address {
            id
            firstName
            lastName
        }
        billing {
            id
            postcode
            address
            typeOfCustomer
            pin
        }

        status
        openingCost
        quantity
        serviceDate
        createBy {
            id
            firstName
            lastName
            username
            avatar
            companyId
            nickName
        }
        createdAt
        categoryProduct {
            ...F_CATEGORY
        }

        services {
            product {
                id
                th {
                    name
                    description
                    shortDescription
                }
                en {
                    name
                    description
                    shortDescription
                }
                productId
                SKU
                service {
                    id
                    name
                }
                thumbnail {
                    thumbnail
                }
                productType
            }
            openingCost
            quantity
            availableOrderQty
        }
    }
`;

export const F_WORKSHEETS = gql`
    ${F_WORKSHEET_ITEM}
    ${F_PAGINATION}
    fragment F_WORKSHEETS on Worksheets {
        worksheet {
            ...F_WORKSHEET_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_WORKSHEET = gql`
    ${F_ADDRESS}
    ${F_BILLING}
    ${F_CUSTOMER}
    ${F_USER}
    ${F_USER_ITEM}
    ${F_PRODUCT}
    ${F_CATEGORY}
    fragment F_WORKSHEET on Worksheet {
        id
        worksheetNo
        PIC {
            ...F_USER_ITEM
        }
        booking {
            id
            bookingNo
            source {
                name
            }
            notes
            createdAt
        }
        address {
            ...F_ADDRESS
        }
        billing {
            ...F_BILLING
        }

        status
        openingCost
        quantity
        serviceDate
        createBy {
            ...F_USER
        }
        createdAt
        services {
            product {
                id
                th {
                    name
                    description
                    shortDescription
                }
                en {
                    name
                    description
                    shortDescription
                }
                productId
                SKU
                service {
                    id
                    name
                    seatConfiguration
                }
                thumbnail {
                    thumbnail
                }
                numberOfSeat
            }
            openingCost
            quantity
            availableOrderQty
        }
        categoryProduct {
            ...F_CATEGORY
        }
        criteriasCheck {
            id
            criteria {
                id
                value
                valueId
            }
            priorChecklist {
                id
                value
            }
            workProgress {
                id
                value
            }
            # deliveryProgress {
            #     id
            #     value
            # }
            customerCheck {
                id
                value
            }
            status
        }

        defection {
            visualization {
                id
                images
                note
            }
            confirmation {
                customerSignature
                customerSignDate
                staffSignature
                staffSignDate
                remark
                confirmed
            }
        }

        confirmation {
            customerSignature
            customerSignDate
            staffSignature
            staffSignDate
            remark
            confirmed
        }

        vouchers {
            id
            voucherNo
            code
            type
            product {
                ...F_PRODUCT
            }
            status
            effectiveDate
            expiryDate
            ownership {
                status
                customer {
                    id
                    firstName
                    lastName
                    nickName
                    customerId
                }
                remark
            }
        }
        isVoucherRemovable
        serviceApply {
            voucherCode
            productServiceId
        }
        mileageStart {
            mile
            proves
        }
        mileageEnd {
            mile
            proves
        }

        customer {
            ...F_CUSTOMER
        }
        totalRemainingSeat
        completedAt
    }
`;

export const F_WORKSHEET_IN_BOOKING = gql`
    ${F_CATEGORY}
    fragment F_WORKSHEET_IN_BOOKING on Worksheet {
        id
        worksheetNo: worksheetNo

        booking {
            id
            bookingNo
        }

        status
        openingCost
        quantity
        serviceDate

        categoryProduct {
            ...F_CATEGORY
        }
        criteriasCheck {
            id
            criteria {
                id
                value
                valueId
            }
            priorChecklist {
                id
                value
            }
            workProgress {
                id
                value
            }

            customerCheck {
                id
                value
            }
            status
        }

        defection {
            visualization {
                id
                images
                note
            }
            confirmation {
                customerSignature
                customerSignDate
                staffSignature
                staffSignDate
                remark
                confirmed
            }
        }

        confirmation {
            customerSignature
            customerSignDate
            staffSignature
            staffSignDate
            remark
            confirmed
        }

        isVoucherRemovable
        serviceApply {
            voucherCode
            productServiceId
        }

        mileageStart {
            mile
            proves
        }

        mileageEnd {
            mile
            proves
        }
    }
`;
