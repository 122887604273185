import {
    Avatar,
    Checkbox,
    InputTextSearch,
    Modal,
    ObjectUtils,
} from "d-react-components";
import { includes, isEmpty, map } from "lodash";
import { useEffect, useState } from "react";
import UserAPI from "../../../api/user/UserAPI";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";

const UserSelectModal = ({ open, onClose, defaultValue = [], onSave }: any) => {
    const [textSearch, setTextSearch] = useState<string>("");
    const [userListDis, setUserListDis] = useState<IUser[]>([]);
    const [userSelected, setUserSelected] = useState<IUser[]>(defaultValue);

    useEffect(() => {
        onLoadUserList();
    }, [textSearch]);

    const onLoadUserList = () => {
        if (isEmpty(textSearch)) {
            setUserListDis(userSelected);
            return;
        }
        UserAPI.activeUserList(textSearch).then((res) =>
            setUserListDis(res?.data?.data?.user ?? [])
        );
    };

    const isSelectUser = (userId: string) => {
        const userIds = map(userSelected, (item) => item.id);
        return includes(userIds, userId);
    };

    const onSelect = (user: IUser) => {
        setUserSelected([...userSelected, user]);
    };

    const onDeSelect = (userId: string) => {
        const result = ObjectUtils.removeItemFromId(userSelected, userId);
        setUserSelected(result);
    };

    const renderUserItem = (user: IUser) => {
        const isSelected = isSelectUser(user.id as string);
        return (
            <div className="d-flex border-bottom p-3">
                <Avatar src={user?.avatar} size="xx-small" />
                <div className="flex-column ml-3 flex-grow-1">
                    {`${user?.firstName} ${user?.lastName}`}
                </div>
                <Checkbox
                    checked={isSelected}
                    onChange={() =>
                        isSelected
                            ? onDeSelect(user?.id as any)
                            : onSelect(user)
                    }
                />
            </div>
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.assignPic}
            onSave={() => {
                onClose();
                onSave(userSelected);
            }}
        >
            <InputTextSearch
                onChange={(event: any) => setTextSearch(event.target.value)}
                placeholder={Messages.search}
                value={textSearch}
            />
            {map(userListDis, renderUserItem)}
        </Modal>
    );
};

export default UserSelectModal;
