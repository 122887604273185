import { Modal, ViewTextError } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import InputTextForm from "../../../common/input/InputTextForm";
import { WSDefectionVisualSchema } from "../../../formschema/worksheet";
import Messages from "../../../languages/Messages";

interface IWSDefectionVisualAddModal {
    open: boolean;
    defaultValue: any;
    onClose: () => void;
    onSave: any;
}

const WSDefectionVisualCrudModal = ({
    open,
    onClose,
    onSave,
    defaultValue = {},
}: IWSDefectionVisualAddModal) => {
    const visualForm = useFormik<any>({
        initialValues: {
            id: defaultValue?.id,
            images: defaultValue?.images ?? [],
            note: defaultValue?.note,
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: WSDefectionVisualSchema,
        onSubmit: (values: any) => {
            onSave && onSave(values);
        },
    });
    const formValues = visualForm?.values ?? {};
    const formErrors = visualForm?.errors;
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.defectionVisualization}
            onSave={() => visualForm.handleSubmit()}
        >
            <label>{Messages.image}</label>
            <ButtonFileUpload
                className="mt-2"
                onChange={(files: any) => {
                    const images = map(files, (item) => item.url);
                    visualForm.setFieldValue("images", images);
                }}
                defaultFiles={map(formValues?.images, (item, index) => ({
                    id: item,
                    imageData: item,
                    url: item,
                }))}
            />
            <ViewTextError error={formErrors?.images} />

            <InputTextForm
                keyData="note"
                form={visualForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

export default WSDefectionVisualCrudModal;
