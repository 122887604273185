import {
    Button,
    DialogManager,
    Notifications,
    Progress,
} from "d-react-components";
import { every, map, reduce, some } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import Image from "../../../common/Image";
import { WorksheetDetailContext } from "../../../context/worksheet";
import Messages from "../../../languages/Messages";
import CriteriaCheckTable from "../common/CriteriaCheckTable";
import WorksheetMileageForm from "../common/WorksheetMileageForm";
import WorksheetMileageView from "../common/WorksheetMileageView";
import WSCPriorCheckingAddModal from "../create/priorchecking/WSCPriorCheckingAddModal";
import { WORKSHEET_CRITERIA_CHECK_STATUS } from "../../../constant/worksheet";

const WSStartingMileage = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const { criteriasCheck, services } = worksheetDetail;

    const isSomePriorUnChecked = some(
        criteriasCheck,
        (criteriaCheck) =>
            criteriaCheck?.status === WORKSHEET_CRITERIA_CHECK_STATUS.PENDING
    );

    const totalUseQuantity = reduce(
        services,
        (sum, item) => sum + item.quantity,
        0
    );

    const onStartMileage = (values: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureToDoThisAction,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.startMileage,
                        params: [worksheetDetail?.id, values],
                    },
                    (worksheet) => {
                        setWorksheetDetail(worksheet);
                        Notifications.showSuccess(
                            Messages.startingMileageSuccess
                        );
                    }
                );
            }
        );
    };

    return (
        <div className="mt-3 card-container p-4">
            <label>{Messages.startingMileage}</label>
            {worksheetDetail?.mileageStart && (
                <WorksheetMileageView mileage={worksheetDetail?.mileageStart} />
            )}
            {!worksheetDetail?.mileageStart?.proves?.length && (
                <WorksheetMileageForm
                    onSubmit={onStartMileage}
                    disabled={
                        isSomePriorUnChecked ||
                        criteriasCheck.length !== totalUseQuantity
                    }
                />
            )}
        </div>
    );
};

const WorksheetDetailPriorChecking = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const { categoryProduct, criteriasCheck, services } = worksheetDetail;
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    const totalUseQuantity = reduce(
        services,
        (sum, item) => sum + item.quantity,
        0
    );

    const isHiddenAdd = useMemo(() => {
        const { defection, criteriasCheck, PIC } = worksheetDetail;
        if (!PIC?.length) return true;

        if (defection?.visualization?.length) {
            return true;
        }

        if (criteriasCheck.length === totalUseQuantity) {
            return true;
        }
        return false;
    }, [worksheetDetail]);

    const isHiddenRemove = useMemo(() => {
        const { defection } = worksheetDetail;
        if (defection?.visualization?.length) {
            return true;
        }
        if (defection?.confirmation?.confirmed) {
            return true;
        }
        return false;
    }, [worksheetDetail]);

    const onAddPriorCheck = ({ criteriaList, quantity }: any) => {
        if (criteriasCheck?.length === totalUseQuantity) {
            Notifications.showError(Messages.criteriaLengthCantGreaterThan);
            return;
        }
        const input = map(criteriaList, (item) => ({
            id: item.id,
            value: item.value,
            valueId: item.valueId,
        }));
        Progress.show(
            {
                method: WorksheetAPI.addCriteriaCheckByQuantity,
                params: [worksheetDetail?.id, input, quantity],
            },
            (res: any) => {
                setWorksheetDetail(res);
                Notifications.showSuccess(Messages.addPriorCheckingSuccess);
                setOpenAdd(false);
            }
        );
    };

    const onClickRemove = (criteriaCheckId: string) =>
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () => onRemove(criteriaCheckId)
        );

    const onRemove = (id: string) =>
        Progress.show(
            {
                method: WorksheetAPI.removeCriteriaCheck,
                params: [worksheetDetail?.id, id],
            },
            (res: any) => {
                setWorksheetDetail(res);
                Notifications.showSuccess(Messages.removePriorCheckingSuccess);
            }
        );

    const onCheckPriorChecklist = (criteriaCheck: any, checklistItem: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkPriorChecklist,
                        params: [
                            worksheetDetail?.id,
                            criteriaCheck?.id,
                            checklistItem?.id,
                        ],
                    },
                    (res: any) => {
                        setWorksheetDetail(res);
                    }
                );
            }
        );
    };

    const onCheckAll = (criteria: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkAllPriorChecklist,
                        params: [worksheetDetail?.id, criteria?.id],
                    },
                    setWorksheetDetail
                );
            }
        );
    };

    const onCancel = (criteria: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCancelChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.cancelPriorChecklist,
                        params: [worksheetDetail?.id, criteria?.id],
                    },
                    setWorksheetDetail
                );
            }
        );
    };

    return (
        <div className="mt-3">
            <div className="card-container p-4 flex-column">
                <label>{Messages.workingProtection}</label>
                <text>{Messages.protected}</text>
            </div>
            <div className="card-container p-4 mt-3">
                <div className="flex-row-between-center">
                    <label>{`${Messages.priorChecking} `}</label>
                    <Button
                        onClick={() => setOpenAdd(true)}
                        hidden={isHiddenAdd}
                    >
                        {Messages.addMore}
                    </Button>
                </div>

                <CriteriaCheckTable
                    criteriasCheck={criteriasCheck}
                    criteriaDataList={
                        categoryProduct?.priorChecking?.criterias ?? []
                    }
                    checkDataList={
                        categoryProduct?.priorChecking?.checkList ?? []
                    }
                    checkDataKey="priorChecklist"
                    onRemove={onClickRemove}
                    // onChangeCheck={onCheckPriorChecklist}
                    isRemove={!isHiddenRemove}
                    onCheckAll={onCheckAll}
                    onCancel={onCancel}
                />
                {openAdd && (
                    <WSCPriorCheckingAddModal
                        open={openAdd}
                        onClose={() => setOpenAdd(false)}
                        defaultCriteriaList={
                            categoryProduct?.priorChecking?.criterias ?? []
                        }
                        onSave={onAddPriorCheck}
                        maxQuantity={totalUseQuantity - criteriasCheck?.length}
                    />
                )}
            </div>
            {categoryProduct?.mileageTrackingRequired && <WSStartingMileage />}
        </div>
    );
};

export default WorksheetDetailPriorChecking;
