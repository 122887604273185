import React, { useContext } from "react";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import CustomerInfoView from "../../customer/common/CustomerInfoView";

const OrderDetailCustomer = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { customer } = orderDetail;
    return (
        <div className="mt-3 card-container p-4">
            <label>{Messages.customer}</label>
            <CustomerInfoView customer={customer as any} />
        </div>
    );
};

export default OrderDetailCustomer;
