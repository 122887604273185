import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    StringUtils,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";

import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../user/common/UserAvatarName";
import TableAction from "../../../common/TableActions";
import { REFUND_STATUSES } from "../../../constant/refundRequest";
import { ICustomer } from "../../../interfaces/customer";
import { IRefundRequest } from "../../../interfaces/refundRequest";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const RefundRequestTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const history = useHistory();
    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "requestNo",
            render: (requestNo, item) => (
                <AppLink
                    to={generatePath(Path.REFUND_REQUEST_DETAIL, {
                        refundRequestId: item.id,
                    })}
                    className="p-3"
                >
                    {requestNo}
                </AppLink>
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    className="ml-3"
                    status={status}
                    listStatus={REFUND_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer: ICustomer) =>
                `${customer.firstName} ${customer.lastName}`,
        },
        {
            title: Messages.refundAmount,
            dataIndex: "amount",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createdBy) => <UserAvatarName user={createdBy} />,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (refundRequest: IRefundRequest) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, refundRequest)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return RefundRequestAPI.list(searchRef.current, pagingData);
    };

    const onClickTableAction = (
        actionId: string,
        refundRequest: IRefundRequest
    ) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.REFUND_REQUEST_DETAIL, {
                        refundRequestId: refundRequest.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        history.push(Path.REFUND_REQUEST_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allRefundRequest}
                // onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.refundRequest ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 200 }}
            />
        </div>
    );
};

export default RefundRequestTable;
