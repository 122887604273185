import { gql } from "@apollo/client";
import { F_CRITERIA } from "../criteria/CriteriaFragment";
import { F_PAGINATION } from "../Fragment";
import { F_USER } from "../user/UserFragment";

export const F_CATEGORIES_ITEM = gql`
    fragment F_CATEGORIES_ITEM on Category {
        id
        name
        thumbnail
        processQty
        createBy {
            id
            firstName
            lastName
        }
        createdAt
        updatedAt
        status
    }
`;

export const F_CATEGORIES = gql`
    ${F_CATEGORIES_ITEM}
    ${F_PAGINATION}
    fragment F_CATEGORIES on Categories {
        category {
            ...F_CATEGORIES_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_CATEGORY = gql`
    ${F_USER}
    ${F_CRITERIA}
    fragment F_CATEGORY on Category {
        id
        name
        priority
        description
        thumbnail
        workProgress {
            id
            value
        }
        # deliveryProgress {
        #     id
        #     value
        # }
        priorChecking {
            checkList {
                id
                value
            }
            criterias {
                ...F_CRITERIA
            }
        }
        processQty
        createBy {
            ...F_USER
        }
        createdAt
        updatedAt
        status
        mileageTrackingRequired
        seatConfiguration
    }
`;
