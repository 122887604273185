import * as Yup from "yup";
import Messages from "../languages/Messages";

export const ProDistrictSchema = Yup.object().shape({
    id: Yup.string().required("Required field!"),
});

export const AddressSchema = Yup.object().shape({
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    phone: Yup.string()
        .min(6, "Phone number is too short!")
        .max(10, "Phone number is too long!")
        .required("Required field!"),

    // country: Yup.string().required("Required field!"),
    province: ProDistrictSchema,
    district: ProDistrictSchema,
    subDistrict: ProDistrictSchema,
    postcode: Yup.string().required("Required field!"),
    address: Yup.string().required("Required field!"),
});

export const AddressServiceSchema = Yup.lazy((value) => {
    return AddressSchema.concat(
        Yup.object().shape({
            typeOfPlace: Yup.string().required("Required field!"),
        }) as any
    );
});

export const BillingSchema = Yup.lazy((value: any) => {
    const schema = AddressSchema.concat(
        Yup.object().shape({
            typeOfCustomer: Yup.string().required("Required field!"),
        }) as any
    );
    if (value.sameServiceAddress) {
        return Yup.object();
    }

    if (value.typeOfCustomer === "INDIVIDUAL") {
        return schema.concat(
            Yup.object().shape({
                citizenId: Yup.string()
                    .matches(/^[0-9]+$/, Messages.mustBeOnlyDigit)
                    .min(13, Messages.mustBe13Character)
                    .max(13, Messages.mustBe13Character)
                    .required("Required field!"),
            }) as any
        );
    }

    if (value.typeOfCustomer === "BUSINESS") {
        return schema.concat(
            Yup.object().shape({
                taxId: Yup.string()
                    .matches(/^[0-9]+$/, Messages.mustBeOnlyDigit)
                    .min(13, Messages.mustBe13Character)
                    .max(13, Messages.mustBe13Character)
                    .required("Required field!"),
                companyName: Yup.string().required("Required field!"),
                companyPhone: Yup.string()
                    .min(6, "Phone number is too short!")
                    .max(10, "Phone number is too long!")
                    .required("Required field!"),
            }) as any
        );
    }
    return schema;
});

export const CustomerInfoSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.string()
        .min(10, "Phone number is too short!")
        .max(14, "Phone number is too long!")
        .required("Required field!"),
    email: Yup.string().email(),
});

export const CustomerPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string().required("Required"),
});

export const CustomerSchema = CustomerInfoSchema.concat(
    CustomerPasswordSchema as any
).concat(
    Yup.object().shape({
        address: Yup.array().of(AddressServiceSchema),
        billingAddress: Yup.array().of(BillingSchema),
    }) as any
);

export const CustomerExportSchema = Yup.object().shape({
    exportType: Yup.string().required("Required"),
});
