import { InputText, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudPricing = () => {
    const { productForm } = useContext(ProductDetailContext);
    const { regularPrice, salePrice, productType } = productForm.values;

    const onChangePrice = (key: string, value: string) => {
        const result = value ? value.replace(/\D/g, "") : "0";
        // eslint-disable-next-line radix
        productForm.setFieldValue(key, parseInt(result));
    };

    if (productType === PRODUCT_TYPE.GROUP_PRODUCT) return <div />;
    return (
        <ViewCollapse className="mt-3" label={Messages.pricing}>
            <div className="row">
                <InputText
                    className="col-6"
                    placeholder="0.00"
                    onChange={(event) =>
                        onChangePrice("regularPrice", event.target.value)
                    }
                    value={regularPrice?.toLocaleString()}
                    prefix={Messages.bath}
                    label={Messages.regularPrice}
                    required
                    error={productForm.errors.regularPrice}
                />
                <InputText
                    className="col-6"
                    placeholder="0.00"
                    onChange={(event) =>
                        onChangePrice("salePrice", event.target.value)
                    }
                    value={salePrice?.toLocaleString()}
                    prefix={Messages.bath}
                    label={Messages.salePrice}
                />
            </div>
        </ViewCollapse>
    );
};

export default ProductCrudPricing;
