import { gql } from "@apollo/client";
import { mapBookingFromSer } from "../../interfaces/booking";
import { F_ACTIVITIES } from "../activity/ActivityFragment";
import API from "../API";
import { F_BOOKING, F_BOOKINGS, F_BOOKING_BY_NO } from "./BookingFragment";
import { F_WORKSHEET_IN_BOOKING } from "../worksheet/WorksheetFragment";

const detailConvertor = {
    fromServer: (res: any) => mapBookingFromSer(res?.data?.booking),
};

const metaDataConvertor = {
    fromServer: (res: any) => ({
        sourceBookings: res?.data?.sourceBookings ?? [],
    }),
};

const sourceBookingConvertor = {
    fromServer: (res: any) => res?.data?.sourceBookings ?? [],
};

const detailWorksheetConvertor = {
    fromServer: (res: any) => res?.data?.booking?.worksheet ?? [],
};

export default {
    list: (q: string, { pageIndex = 1, pageSize = 10 }: any, filter: any) =>
        API.query({
            query: gql`
                ${F_BOOKINGS}
                query bookings(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: BookingFilter
                ) {
                    data: bookings(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_BOOKINGS
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                filter,
            },
            fetchPolicy: "no-cache",
        }),

    listAssigned: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filterState?: any
    ) =>
        API.query({
            query: gql`
                ${F_BOOKINGS}
                query listAssigned(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: BookingFilter
                ) {
                    data: bookingsAssignedMe(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_BOOKINGS
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                filter: filterState,
            },
            fetchPolicy: "no-cache",
        }),

    summary: () =>
        API.withConverter({
            fromServer: (res) => res?.data?.bookingSummarize ?? [],
        }).query({
            query: gql`
                query bookingSummarize {
                    bookingSummarize {
                        type
                        total
                    }
                }
            `,
            fetchPolicy: "no-cache",
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_BOOKING}
                query booking($id: ID!) {
                    booking(id: $id) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    getAllWorksheets: (id: string) =>
        API.withConverter(detailWorksheetConvertor).query({
            query: gql`
                ${F_WORKSHEET_IN_BOOKING}
                query booking($id: ID!) {
                    booking(id: $id) {
                        worksheet {
                            ...F_WORKSHEET_IN_BOOKING
                        }
                    }
                }
            `,
            variables: { id },
        }),

    cancel: (params: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation cancelBooking($id: ID!, $remark: String) {
                    booking: cancelBooking(id: $id, remark: $remark) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: params,
        }),

    detailByBookingNo: (bookingNo: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_BOOKING_BY_NO}
                query bookingNo($bookingNo: String!) {
                    booking: bookingNo(bookingNo: $bookingNo) {
                        ...F_BOOKING_BY_NO
                    }
                }
            `,
            variables: { bookingNo },
            fetchPolicy: "no-cache",
        }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createBooking?.id,
        }).mutate({
            mutation: gql`
                mutation create($input: BookingInput) {
                    createBooking(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateBooking($id: ID!, $input: UpdateBookingInput) {
                    booking: updateBooking(id: $id, input: $input) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { id, input },
        }),

    updateSource: (bookingId: string, sourceBookingId: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateSource($bookingId: ID!, $sourceBookingId: ID!) {
                    booking: updateBookingSource(
                        bookingId: $bookingId
                        sourceBookingId: $sourceBookingId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, sourceBookingId },
        }),

    updateContact: (bookingId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateBookingContact(
                    $bookingId: ID!
                    $input: BookingContactInput
                ) {
                    booking: updateBookingContact(
                        bookingId: $bookingId
                        input: $input
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, input },
        }),

    updateServiceDate: (bookingId: string, serviceDate: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateServiceDate(
                    $bookingId: ID!
                    $serviceDate: Date
                ) {
                    booking: updateBookingServiceDate(
                        bookingId: $bookingId
                        serviceDate: $serviceDate
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, serviceDate },
        }),

    updateNote: (bookingId: string, note: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateNote($bookingId: ID!, $note: String) {
                    booking: updateBookingNote(
                        bookingId: $bookingId
                        note: $note
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, note },
        }),

    updateBilling: (bookingId: string, billing: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateBilling(
                    $bookingId: ID!
                    $billing: BillingInput
                ) {
                    booking: updateBookingBilling(
                        bookingId: $bookingId
                        input: $billing
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, billing },
        }),

    createAddress: (bookingId: string, address: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation createAddress(
                    $bookingId: ID!
                    $address: [AddressInput]
                ) {
                    booking: addBookingServiceAddress(
                        bookingId: $bookingId
                        input: $address
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, address },
        }),

    updateAddress: (bookingId: string, addressId: string, address: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateAddress(
                    $bookingId: ID!
                    $addressId: ID!
                    $address: AddressInput
                ) {
                    booking: updateBookingServiceAddress(
                        bookingId: $bookingId
                        addressId: $addressId
                        input: $address
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, addressId, address },
        }),

    deleteAddress: (bookingId: string, addressId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation deleteAddress($bookingId: ID!, $addressId: ID!) {
                    booking: deleteBookingServiceAddress(
                        bookingId: $bookingId
                        addressId: $addressId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, addressId },
        }),

    createScheduleService: (bookingId: string, scheduleServices: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation createScheduleServices(
                    $bookingId: ID!
                    $scheduleServices: [ScheduleServiceInput]
                ) {
                    booking: createScheduleServices(
                        bookingId: $bookingId
                        scheduleServices: $scheduleServices
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, scheduleServices: [scheduleServices] },
        }),

    updateScheduleService: (
        bookingId: string,
        scheduleServiceId: string,
        scheduleService: any
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation updateScheduleService(
                    $bookingId: ID!
                    $scheduleServiceId: ID!
                    $scheduleService: ScheduleServiceInput
                ) {
                    booking: updateScheduleService(
                        bookingId: $bookingId
                        scheduleServiceId: $scheduleServiceId
                        scheduleService: $scheduleService
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, scheduleServiceId, scheduleService },
        }),

    deleteScheduleService: (bookingId: string, scheduleServiceId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation destroyScheduleService(
                    $bookingId: ID!
                    $scheduleServiceId: ID!
                ) {
                    booking: destroyScheduleService(
                        bookingId: $bookingId
                        scheduleServiceId: $scheduleServiceId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, scheduleServiceId },
        }),

    assignPIC: (bookingId: string, userId: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation assignPICBooking($bookingId: ID!, $userId: [ID]!) {
                    booking: assignPICBooking(
                        bookingId: $bookingId
                        userId: $userId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, userId },
        }),

    removePIC: (bookingId: string, userId: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation removePICBooking($bookingId: ID!, $userId: [ID]!) {
                    booking: removePICBooking(
                        bookingId: $bookingId
                        userId: $userId
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: { bookingId, userId },
        }),

    addComment: (bookingId: string, message: string, attachments?: string[]) =>
        API.mutate({
            mutation: gql`
                mutation commentBooking(
                    $bookingId: ID!
                    $message: String
                    $attachments: [String]
                ) {
                    commentBooking(
                        bookingId: $bookingId
                        message: $message
                        attachments: $attachments
                    )
                }
            `,
            variables: { bookingId, message, attachments },
        }),

    activities: (bookingId: string, { pageIndex = 1, pageSize = 10 }: any) =>
        API.query({
            query: gql`
                ${F_ACTIVITIES}
                query bookingActivities(
                    $bookingId: ID!
                    $page: Int
                    $limit: Int
                ) {
                    data: bookingActivities(
                        bookingId: $bookingId
                        page: $page
                        limit: $limit
                    ) {
                        ...F_ACTIVITIES
                    }
                }
            `,
            variables: { bookingId, page: pageIndex, limit: pageSize },
            fetchPolicy: "no-cache",
        }),

    metaData: () =>
        API.withConverter(metaDataConvertor).query({
            query: gql`
                query {
                    sourceBookings {
                        id
                        label: name
                    }
                }
            `,
        }),

    sourceBookings: () =>
        API.withConverter(sourceBookingConvertor).query({
            query: gql`
                query {
                    sourceBookings {
                        id
                        label: name
                    }
                }
            `,
        }),

    sendMailBookingCreated: (id: string, email: string) =>
        API.mutate({
            mutation: gql`
                mutation sendMailBookingCreated($id: ID!, $email: String!) {
                    sendMailBookingCreated(id: $id, email: $email) {
                        status
                    }
                }
            `,
            variables: { id, email },
        }),
    markComplete: (body: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BOOKING}
                mutation markBookingCompleted(
                    $bookingId: ID!
                    $remark: String!
                ) {
                    booking: markBookingCompleted(
                        bookingId: $bookingId
                        remark: $remark
                    ) {
                        ...F_BOOKING
                    }
                }
            `,
            variables: body,
        }),
};
