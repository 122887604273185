import {
    Avatar,
    AwesomeTableComponent,
    Button,
    IColumnsProps,
} from "d-react-components";
import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserSelectModal from "../../user/common/UserSelectModal";

const WSCBookingPIC = ({
    picList,
    onAddUser,
    onRemoveUser,
    hiddenAssignPIC,
}: any) => {
    const [openAssign, setOpenAssign] = useState<boolean>(false);
    const columns: IColumnsProps = [
        {
            title: Messages.no,
            dataIndex: "userId",
            render: (userId, item, index) => index + 1,
        },
        {
            title: Messages.companyId,
            dataIndex: "companyId",
            render: (userId, item) => (
                <AppLink
                    to={generatePath(Path.USER_DETAIL, {
                        userId: item.id,
                    })}
                    className="p-3"
                >
                    {userId}
                </AppLink>
            ),
        },
        {
            title: Messages.name,
            dataIndex: "",
            render: (user) => (
                <div className="d-flex align-items-center">
                    <Avatar
                        src={user?.avatar ?? "/images/placeholder.png"}
                        size="xx-small"
                    />
                    <small className="ml-3">
                        {`${user?.firstName} ${user?.lastName}`}
                    </small>
                </div>
            ),
        },

        {
            title: Messages.role,
            dataIndex: "role",
            render: (role) => role?.name,
        },
    ];

    if (onRemoveUser) {
        columns.push({
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (user: IUser) => {
                return (
                    <TableAction
                        onClick={() => onRemoveUser(user)}
                        dataSource={[{ id: "delete", label: "delete" }]}
                    />
                );
            },
        });
    }

    return (
        <div className="card-container p-4 mt-3">
            <div className="d-flex flex-center justify-content-between">
                <label>{Messages.pic}</label>
                {!hiddenAssignPIC && (
                    <Button onClick={() => setOpenAssign(true)}>
                        {Messages.assignPic}
                    </Button>
                )}
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={picList}
                className="mt-3"
                pagination={false}
            />
            {openAssign && (
                <UserSelectModal
                    open={openAssign}
                    onClose={() => setOpenAssign(false)}
                    onSave={onAddUser}
                    defaultValue={picList ?? []}
                />
            )}
        </div>
    );
};

export default WSCBookingPIC;
