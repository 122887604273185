import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useParams, generatePath } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import AppLink from "../../../common/AppLink";
import { BOOKING_STATUSES } from "../../../constant/booking";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CustomerDetailBooking = () => {
    const { customerId } = useParams<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.bookingNo,
            dataIndex: "bookingNo",
            render: (bookingNo: string, booking: any) => (
                <AppLink
                    to={generatePath(Path.BOOKING_DETAIL, {
                        bookingId: booking.id,
                    })}
                >
                    {bookingNo}
                </AppLink>
            ),
        },
        {
            title: Messages.openingCost,
            dataIndex: "cost",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.billToName,
            dataIndex: "billing",
            render: (billing: any) =>
                `${billing?.firstName} ${billing.lastName}`,
        },
        {
            title: Messages.estServiceDate,
            dataIndex: "serviceDate",
            render: TimeUtils.toDate,
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status: string) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={BOOKING_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },

        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) => createBy?.firstName,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (paging: any) => {
        return CustomerAPI.bookingList(customerId, paging);
    };
    return (
        <div className="p-4 bg-white">
            <h4>{Messages.booking}</h4>
            <AwesomeTableComponent
                source={source}
                columns={columns}
                className="mt-3"
                transformer={(res) => res?.data?.data?.booking ?? []}
            />
        </div>
    );
};

export default CustomerDetailBooking;
