import {
    AwesomeTableComponent,
    Badge,
    HeaderTable,
    IColumnsProps,
    StringUtils,
    TabBar,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find, map } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import BookingAPI from "../../../api/booking/BookingAPI";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../user/common/UserAvatarName";
import TableAction from "../../../common/TableActions";
import { BOOKING_STATUSES } from "../../../constant/booking";
import { PERMISSION_MODULE, ROLE_ACCESS_TYPE } from "../../../constant/user";
import { useUserPermission } from "../../../hoook/permission";
import { ICustomer } from "../../../interfaces/customer";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import { isFilteredBody } from "../../../utils/Utils";
import Path from "../../Path";
import BookingFilterModal from "./BookingFilterModal";

const ALL_TAB: any = { id: "ALL", label: "all" };

interface Props {
    dataSource: any;
    hideSummary?: boolean;
}

const BookingTable = ({ dataSource, hideSummary }: Props) => {
    const [bookingSummary, setBookingSummary] = useState([]);

    const history = useHistory();

    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const isFirstTime = useFirstTime();
    const [openFilter, setOpenFilter] = useState(false);

    const [filterState, setFilterState] = useQueryParam(
        "filterState",
        JsonParam
    );

    const isGrantCreate = useUserPermission(
        PERMISSION_MODULE.BOOKING,
        ROLE_ACCESS_TYPE.CREATE
    );

    const isGrantDetail = useUserPermission(
        PERMISSION_MODULE.BOOKING,
        ROLE_ACCESS_TYPE.DETAIL
    );

    const columns: IColumnsProps = [
        {
            title: Messages.bookingNo,
            dataIndex: "bookingNo",
            render: (bookingNo: string, booking: any) => (
                <AppLink
                    disabled={!isGrantDetail}
                    to={generatePath(Path.BOOKING_DETAIL, {
                        bookingId: booking.id,
                    })}
                >
                    {bookingNo}
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status: string) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={BOOKING_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer: ICustomer) => customer?.firstName,
        },

        {
            title: Messages.openingCost,
            dataIndex: "cost",
            render: (cost: any) => StringUtils.moneyThaiFormat(cost ?? 0),
        },
        {
            title: Messages.itemQty,
            dataIndex: "quantity",
        },
        {
            title: Messages.serviceDate,
            dataIndex: "serviceDate",
            render: (serviceDate: any) => TimeUtils.toDate(serviceDate),
        },

        {
            title: Messages.source,
            dataIndex: "source",
            render: (source: any) => source?.name,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) => (
                <UserAvatarName
                    user={createBy}
                    placeholder={Messages.customerCreated}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (booking) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, booking)
                    }
                />
            ),
            align: "center",
        },
    ];
    useEffect(() => {
        loadSummary();
    }, []);

    const loadSummary = () => {
        BookingAPI.summary().then(setBookingSummary);
    };

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterState, dataSource]);

    const source = (pagingData: any) => {
        const isAll =
            !filterState?.status || filterState?.status?.id === ALL_TAB.id;
        const filterParams = {
            status: isAll ? [] : filterState?.status.id,

            createdAtFrom: filterState?.createdAtFrom
                ? moment(filterState?.createdAtFrom).toISOString()
                : null,
            createdAtTo: filterState?.createdAtTo
                ? moment(filterState?.createdAtTo).toISOString()
                : null,
            createBy: map(filterState?.createBy, (item) => item.id),
            PIC: map(filterState?.PIC, (item) => item.id),
            source: map(filterState?.source, (item) => item.id),
            customer: map(filterState?.customer, (item) => item.id),
        };
        if (!dataSource) return Promise.resolve([]);

        return dataSource(searchRef.current, pagingData, filterParams);
        // return BookingAPI.list(searchRef.current, pagingData, filterParams);
    };

    const onClickTableAction = (actionId: string, booking: any) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.BOOKING_DETAIL, { bookingId: booking.id })
                );
        }
    };

    const onClickAddNew = () => {
        history.push(Path.BOOKING_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const onFilter = (values: any) => {
        setFilterState(values);
        tableRef.current.refresh();
    };

    const renderLabelTab = (tab: any) => {
        const summary = find(
            bookingSummary,
            (item: any) => item.type === tab.id
        );
        return (
            <div className="flex-center">
                {(Messages as any)[tab.label]}
                {!hideSummary && (
                    <Badge
                        className="ml-3"
                        variant="index"
                        size="xx-large"
                        index={summary?.total ?? 0}
                        color={summary?.total === 0 ? "green" : "secondary"}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="p-4">
            <HeaderTable
                //@ts-ignore
                showAddNew={isGrantCreate}
                onClickNew={onClickAddNew}
                label={Messages.bookings}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                isFiltered={isFilteredBody(filterState)}
                className="mb-3"
            />
            <TabBar
                className="bg-transparent"
                classNameItem="border-0"
                dataSource={[ALL_TAB as any, ...BOOKING_STATUSES]}
                onChange={(tab) => setFilterState({ status: tab as any })}
                value={filterState?.status ?? ALL_TAB}
                getLabel={renderLabelTab}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => res?.data?.data?.booking ?? []}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
            />

            {openFilter && (
                <BookingFilterModal
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    onFilter={onFilter}
                    defaultValue={filterState}
                />
            )}
        </div>
    );
};

export default BookingTable;
