/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
    Icon,
    Notifications,
    ObjectUtils,
    Select,
    StringUtils,
    ViewCollapse,
} from "d-react-components";
import { filter, includes, map, some, uniqBy } from "lodash";
import React, { useContext, useState } from "react";
import AddMediaView from "../../../common/view/AddMediaView";
import AddVideoLinkButton, {
    ModalAddVideoLink,
} from "../../../common/button/AddVideoLinkButton";
import DragContainer from "../../../common/drag/DragContainer";
import DragItem from "../../../common/drag/DragItem";
import { PRODUCT_GALLERY_TAGS } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";
import MediaManagerMediaDetail from "../../media/MediaManagerMediaDetail";
import SelectMediaManagerButton from "../../media/selectmedia/SelectMediaManagerButton";

const ImageItemView = ({
    media,
    onClick,
    onChangeRoleMedia,
    onClickDelete,
}: any) => {
    const { roles = [], url } = media;

    return (
        <div className="d-flex flex-column mr-3 position-relative">
            <img src={url} className="image-square-x-large" onClick={onClick} />
            <div
                className=" product-create__gallery-image-remove"
                onClick={onClickDelete}
            >
                <Icon name="cancel" className="color-primary" />
            </div>

            <Select
                className="mt-3"
                dataSource={PRODUCT_GALLERY_TAGS}
                multiple
                onChange={onChangeRoleMedia}
                value={roles}
            />
        </div>
    );
};

const VideoItemView = ({ videoLinkData, onClickDelete, onSaveVideo }: any) => {
    const [open, setOpen] = useState(false);
    const { thumbnail } = videoLinkData;
    return (
        <div className="mr-3">
            <div className="position-relative" onClick={() => setOpen(true)}>
                <img src={thumbnail} className="image-square-x-large" />
                <div
                    className="product-create__gallery-image-remove"
                    onClick={onClickDelete}
                >
                    <Icon name="cancel" className="color-primary" />
                </div>
                <div className="product-create__gallery-video-icon">
                    <Icon name="video_library" />
                </div>
            </div>

            <ModalAddVideoLink
                open={open}
                onClose={() => setOpen(false)}
                onSaveVideoLink={(videoData: any) => {
                    setOpen(false);
                    onSaveVideo(videoData);
                }}
                videoData={videoLinkData}
            />
        </div>
    );
};

const ProductCrudGallery = () => {
    const { productForm } = useContext(ProductDetailContext);
    const { gallery } = productForm.values;
    const [mediaDetailModal, setMediaDetailModal] = useState<any>({
        open: false,
        mediaDetail: {},
    });

    const onSelectMedia = (mediaList: any) => {
        const allGallery = [...gallery, ...mediaList];
        const uniqueGallery = uniqBy(allGallery, (item) => item.url);
        productForm.setFieldValue("gallery", uniqueGallery);
    };

    const onChangeMediaDetail = (mediaDetail: any) => {
        const resultList = map(gallery, (item) => {
            if (item.id === mediaDetail.id) {
                return mediaDetail;
            }
            return item;
        });
        productForm.setFieldValue("gallery", resultList);
    };

    const onChangeRoleMedia = (mediaItem: any, values: any) => {
        const remainingMedia = gallery.filter(
            (item: any) => item.id !== mediaItem.id
        );
        const isExistingRole = some(remainingMedia, (item) =>
            includes(item.roles ?? [], values[values?.length - 1])
        );
        if (isExistingRole) {
            Notifications.showError(Messages.thisRoleIsTaken);
            return;
        }
        onChangeMediaDetail({ ...mediaItem, roles: values });
    };

    const onSaveVideoLink = (videoData: any) => {
        if (videoData.id) {
            onEditVideoLink(videoData);
        } else {
            onAddVideoLink(videoData);
        }
    };

    const onAddVideoLink = (videoData: any) => {
        const videoResult = {
            ...videoData,
            id: StringUtils.getUniqueID(),
            isVideo: true,
        };

        productForm.setFieldValue("gallery", [...gallery, videoResult]);
    };

    const onEditVideoLink = (videoData: any) => {
        const result = map(gallery, (item: any) => {
            if (item.id === videoData.id) {
                return videoData;
            }
            return item;
        });
        productForm.setFieldValue("gallery", result);
    };

    const onClickDeleteMedia = (mediaItem: any) => {
        const result = filter(gallery, (item: any) => item.id !== mediaItem.id);

        productForm.setFieldValue("gallery", result);
    };

    const onMoveMedia = (dragIndex: number, hoverIndex: number) => {
        const results = ObjectUtils.arrayMove(gallery, dragIndex, hoverIndex);
        productForm.setFieldValue("gallery", results);
    };

    const renderGalleryList = () => {
        return (
            <div className="product-create__gallery-images">
                <DragContainer>
                    {map(gallery, (imageItem: any, index: number) => {
                        if (imageItem.isVideo) {
                            return (
                                <DragItem
                                    id={imageItem.id}
                                    onMoveItem={onMoveMedia}
                                    index={index}
                                >
                                    <VideoItemView
                                        videoLinkData={imageItem}
                                        onClickDelete={() =>
                                            onClickDeleteMedia(imageItem)
                                        }
                                        onSaveVideo={onSaveVideoLink}
                                    />
                                </DragItem>
                            );
                        }
                        return (
                            <DragItem
                                id={imageItem.id}
                                onMoveItem={onMoveMedia}
                                index={index}
                            >
                                <ImageItemView
                                    media={imageItem}
                                    key={index}
                                    onClick={() =>
                                        setMediaDetailModal({
                                            open: true,
                                            mediaDetail: imageItem,
                                        })
                                    }
                                    onChangeRoleMedia={(value: any) =>
                                        onChangeRoleMedia(imageItem, value)
                                    }
                                    onClickDelete={() =>
                                        onClickDeleteMedia(imageItem)
                                    }
                                />
                            </DragItem>
                        );
                    })}
                </DragContainer>

                <SelectMediaManagerButton
                    renderButton={() => <AddMediaView />}
                    onSelect={onSelectMedia}
                />
            </div>
        );
    };

    return (
        <ViewCollapse
            label={Messages.gallery}
            className="product-create__gallery mt-3"
        >
            <div className="d-flex justify-content-end">
                <AddVideoLinkButton onSaveVideoLink={onSaveVideoLink} />
            </div>
            {renderGalleryList()}
            {mediaDetailModal.open && (
                <MediaManagerMediaDetail
                    open={mediaDetailModal.open}
                    onSelect={onChangeMediaDetail}
                    onClose={() =>
                        setMediaDetailModal({ open: false, mediaDetail: {} })
                    }
                    onClickBack={() =>
                        setMediaDetailModal({ open: false, mediaDetail: {} })
                    }
                    mediaId={mediaDetailModal?.mediaDetail?.id}
                />
            )}
        </ViewCollapse>
    );
};

export default ProductCrudGallery;
