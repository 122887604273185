import { gql } from "@apollo/client";
import API from "../API";
import { F_BLOCK } from "./BlockFragment";

const detailConvertor = {
    fromServer: (res: any) => res?.data?.block,
};

export default {
    detail: (type: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_BLOCK}
                query block($type: BlockType) {
                    block(type: $type) {
                        ...F_BLOCK
                    }
                }
            `,
            variables: { type },
        }),

    update: (type: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BLOCK}
                mutation updateBlock($type: BlockType, $input: BlockInput) {
                    block: updateBlock(type: $type, input: $input) {
                        ...F_BLOCK
                    }
                }
            `,
            variables: { input, type },
        }),

    addProduct: (type: string, ids: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BLOCK}
                mutation addProductsBlock($type: BlockType!, $ids: [ID]) {
                    block: addProductsBlock(type: $type, ids: $ids) {
                        ...F_BLOCK
                    }
                }
            `,
            variables: { type, ids },
        }),

    removeProduct: (type: string, ids: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_BLOCK}
                mutation removeProductsBlock($type: BlockType!, $ids: [ID]) {
                    block: removeProductsBlock(type: $type, ids: $ids) {
                        ...F_BLOCK
                    }
                }
            `,
            variables: { type, ids },
        }),
};
