import { ViewLabelStatus } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import { VOUCHER_OWNERSHIP_STATUSES } from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const VoucherDetailOwnerShip = () => {
    const { voucher } = useContext(VoucherDetailContext);
    const { ownership } = voucher;
    const renderInfoItem = (label: string, content: any) => {
        return (
            <div className="flex-center-y border-bottom py-3">
                <div className="small w-50">{label}</div>
                <div className="text w-50 ">{content}</div>
            </div>
        );
    };
    return (
        <div className="card-container mt-3 p-4">
            <label>{Messages.ownerShipStatus}</label>
            {renderInfoItem(
                Messages.status,
                <ViewLabelStatus
                    listStatus={VOUCHER_OWNERSHIP_STATUSES}
                    status={ownership?.status}
                    getLabel={(item) => (item?.label ?? "")?.toUpperCase()}
                />
            )}
            {ownership?.customer &&
                renderInfoItem(
                    Messages.customer,
                    <a
                        href={generatePath(Path.CUSTOMER_DETAIL, {
                            customerId: ownership?.customer?.id,
                        })}
                    >{`${ownership?.customer?.firstName} ${ownership?.customer?.lastName}`}</a>
                )}
            {ownership?.remark &&
                renderInfoItem(Messages.remark, ownership?.remark)}
        </div>
    );
};

export default VoucherDetailOwnerShip;
