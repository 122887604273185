import { TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { OrderDetailContext } from "../../../context/order";
import { getCreditTermString } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

const OrderDetailCreditNote = () => {
    const { orderDetail } = useContext(OrderDetailContext);

    return (
        <div
            className="card-container p-4 mb-3"
            hidden={!orderDetail?.creditNote}
        >
            <label>{Messages.creditNote}</label>
            <div>{`${Messages.creditTerm}: ${getCreditTermString(
                orderDetail?.creditNote
            )}`}</div>
            <div className="divider my-3" />
            <div>
                {`${Messages.paymentScheduledOn} ${TimeUtils.toDateTime(
                    orderDetail?.creditNote?.paymentScheduleOn
                )}`}
            </div>
        </div>
    );
};

export default OrderDetailCreditNote;
