import React, { useContext } from "react";
import LanguageSelectButton from "../../../common/button/LanguageSelectButton";
import { PaymentMethodCrudContext } from "../../../context/paymentmethod";

const PaymentMethodLangButton = () => {
    const { methodLang, setMethodLang } = useContext(PaymentMethodCrudContext);
    return (
        <LanguageSelectButton
            lang={methodLang}
            onChange={setMethodLang}
            className="mt-3"
        />
    );
};

export default PaymentMethodLangButton;
