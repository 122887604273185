// react
import { Component } from "react";
import { connect } from "react-redux";
// third-party
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import AppLayout from "./screen/AppLayout";
import AuthLayout from "./screen/AuthLayout";
import InitComponent from "./screen/InitComponent";
import Path from "./screen/Path";

class Root extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { accessToken } = this.props;
        if (!accessToken && window.location.pathname !== Path.AUTH) {
            window.open(Path.AUTH, "_self");
        }
    }

    shouldComponentUpdate(nextProps: any) {
        if (nextProps.accessToken !== this.props.accessToken) {
            return true;
        }
        return false;
    }

    renderMainAppContent = () => {
        const { accessToken } = this.props;
        if (!accessToken) {
            return <AuthLayout />;
        }

        return <AppLayout />;
    };

    render() {
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <InitComponent />
                    {this.renderMainAppContent()}
                </QueryParamProvider>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state: any) => ({
    locale: state.locale,
    accessToken: state?.auth?.accessToken,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
