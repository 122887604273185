import React, { useContext } from "react";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";
import CustomerInfoView from "../../customer/common/CustomerInfoView";

const BookingDetailCustomer = () => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const { customer } = bookingDetail;

    return (
        <div className="card-container p-4 mt-3">
            <label>{Messages.customer}</label>
            <CustomerInfoView customer={customer as any} />
        </div>
    );
};

export default BookingDetailCustomer;
