import React from "react";
import { getFullAddress } from "../../interfaces/address";
import Messages from "../../languages/Messages";

const AddressBillingInfoView = ({ billing }: any) => {
    if (!billing) return <div />;
    const {
        firstName,
        lastName,
        phone,
        typeOfCustomer,
        citizenId,
        typeOfPlace,
        taxId,
        sameServiceAddress,
    } = billing;
    if (sameServiceAddress) {
        return <div>{Messages.sameAsServiceAddress}</div>;
    }
    return (
        <div className="flex-column">
            <text>{`${firstName} ${lastName}`}</text>
            {typeOfCustomer === "INDIVIDUAL" && (
                <text>{`${Messages.citizenId}: ${citizenId}`}</text>
            )}
            {typeOfCustomer === "BUSINESS" && (
                <text>{`${Messages.taxId}: ${taxId}`}</text>
            )}
            <text>{phone}</text>
            <text>{getFullAddress(billing)}</text>
            <text>{typeOfPlace}</text>
        </div>
    );
};

export default AddressBillingInfoView;
