import { StringUtils, TimeUtils } from "d-react-components";
import { map } from "lodash";
import { ICriteria } from "./criteria";
import { IMedia } from "./media";
import { IUser, mapUserFromSer } from "./user";

export interface ICategoryProgress {
    id: string;
    value: string;
    dataSource?: any[];
}

export interface IPriorChecking {
    checkList: ICategoryProgress[];
    criterias: ICriteria[];
}

export interface ICategory {
    id?: string;
    thumbnail?: IMedia;
    name?: string;
    createBy?: IUser;
    createdAt?: string;
    createdAtDis?: string;
    updatedAt?: string;
    updatedAtDis?: string;
    processQty?: string;
    priority?: string;
    workProgress?: ICategoryProgress[];
    deliveryProgress?: ICategoryProgress[];
    priorChecklist?: ICategoryProgress[];
    priorCriteria?: ICategoryProgress[];
    description?: string;
    priorChecking?: IPriorChecking;
    status?: string;
    mileageTrackingRequired?: boolean;
    seatConfiguration?: boolean;
}

export const mapCateFromSer = (dataSer: any) => {
    const createBy = mapUserFromSer(dataSer?.createBy);
    const { priorChecking, ...resDataSer } = dataSer;
    return {
        ...resDataSer,
        createBy,
        createdAtDis: TimeUtils.toDateTime(dataSer?.createdAt),
        updatedAtDis: TimeUtils.toDateTime(dataSer?.updatedAt),
        priorChecklist: priorChecking?.checkList ?? [],
        priorCriteria: map(priorChecking?.criterias ?? [], (item) => ({
            id: StringUtils.getUniqueID(),
            value: item.id,
        })),
    };
};

const getDataListValue = (values: any = []) =>
    map(values, (item) => {
        if (item.isAddNew) {
            return {
                value: item.value,
            };
        }
        return {
            value: item.value,
            id: item.id,
        };
    });

export const mapCateToServer = (data: ICategory) => {
    const {
        name,
        description,
        priority,
        workProgress,
        deliveryProgress,
        thumbnail,
        priorChecklist,
        priorCriteria,
        status,
        mileageTrackingRequired,
    } = data;
    return {
        description,
        name,
        priority,
        thumbnail,
        workProgress: getDataListValue(workProgress),
        deliveryProgress: getDataListValue(deliveryProgress),
        status,
        mileageTrackingRequired,
        priorChecking: {
            checkList: getDataListValue(priorChecklist),
            criteriaId: map(priorCriteria, (item) => item.value),
        },
    };
};
