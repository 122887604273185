import { InputText } from "d-react-components";
import { useContext } from "react";
import { UserCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";

const UserInputForm = ({ keyData }: any) => {
    const { userForm } = useContext(UserCrudContext);

    return (
        <div className="col-6 mt-3">
            <InputText
                label={(Messages as any)[keyData]}
                placeholder={(Messages as any)[keyData]}
                onChange={(event) =>
                    userForm.setFieldValue(keyData, event.target.value)
                }
                value={userForm?.values?.[keyData]}
                error={userForm?.errors?.[keyData]}
            />
        </div>
    );
};

export default UserInputForm;
