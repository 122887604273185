import {
    Button,
    Modal,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { map, toUpper } from "lodash";
import React, { useContext, useState } from "react";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";
import UserAvatarName from "../../user/common/UserAvatarName";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import Image from "../../../common/Image";
import InputTextForm from "../../../common/input/InputTextForm";
import { REFUND_STATUSES } from "../../../constant/refundRequest";
import { RefundDetailContext } from "../../../context/refundRequest";
import {
    RefundApprovalSchema,
    RefundCompleteSchema,
} from "../../../formschema/refundRequest";
import Messages from "../../../languages/Messages";

interface IRefundConfirmModal {
    open: boolean;
    onClose: () => void;
}

const RefundConfirmModal = ({ open, onClose }: IRefundConfirmModal) => {
    const { refundRequest, setRefundRequest } = useContext(RefundDetailContext);

    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RefundApprovalSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: RefundRequestAPI.confirmRefund,
                    params: [refundRequest?.id, values],
                },
                (refundRequest: any) => {
                    setRefundRequest(refundRequest);
                    onClose();
                    Notifications.showSuccess(Messages.refundConfirmed);
                }
            );
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => confirmForm.handleSubmit()}
        >
            <div>
                <div>{Messages.goingApproveRefund}</div>
                <InputTextForm form={confirmForm} keyData="remark" multiple />
            </div>
        </Modal>
    );
};

const RefundRejectModal = ({ open, onClose }: IRefundConfirmModal) => {
    const { refundRequest, setRefundRequest } = useContext(RefundDetailContext);

    const rejectForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RefundApprovalSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: RefundRequestAPI.rejectRefund,
                    params: [refundRequest?.id, values],
                },
                (refundRequest: any) => {
                    refundRequest;
                    onClose();
                    Notifications.showSuccess(Messages.refundRejected);
                }
            );
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.reject}
            onSave={() => rejectForm.handleSubmit()}
        >
            <div>
                <div>{Messages.goingRejectRefund}</div>
                <InputTextForm form={rejectForm} keyData="remark" multiple />
            </div>
        </Modal>
    );
};

const RefundRequestDetailDirector = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const { refundRequest } = useContext(RefundDetailContext);
    const { status, confirmRefund, rejectRefund } =
        refundRequest;

    const renderInfoItem = (label: string, content: any) => {
        return (
            <div className="d-flex border-bottom py-2">
                <div className="text-disabled w-50">{label}</div>
                <div className="text-grey w-50">{content}</div>
            </div>
        );
    };

    const renderActionButton = () => (
        <div className="d-flex my-2">
            <Button onClick={() => setOpenConfirm(true)} className="mr-3">
                {Messages.confirm}
            </Button>
            <Button
                onClick={() => setOpenReject(true)}
                className="mr-3"
                color="error"
            >
                {Messages.reject}
            </Button>
        </div>
    );

    return (
        <div className="mt-3 card-container p-3">
            <label>{Messages.directorApproval}</label>
            {renderInfoItem(
                Messages.status,
                <ViewLabelStatus
                    className="ml-3"
                    status={status}
                    listStatus={REFUND_STATUSES}
                    getLabel={(item) => toUpper((Messages as any)[item.label])}
                />
            )}

            {confirmRefund &&
                renderInfoItem(
                    Messages.approvedBy,
                    <UserAvatarName user={confirmRefund?.createBy} />
                )}

            {rejectRefund &&
                renderInfoItem(
                    Messages.rejectBy,
                    <UserAvatarName user={rejectRefund?.createBy} />
                )}

            {confirmRefund &&
                renderInfoItem(Messages.remark, confirmRefund?.remark)}

            {rejectRefund &&
                renderInfoItem(Messages.remark, rejectRefund?.remark)}

            {!rejectRefund &&
                !confirmRefund &&
                renderInfoItem(Messages.action, renderActionButton())}

            {openConfirm && (
                <RefundConfirmModal
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                />
            )}

            {openReject && (
                <RefundRejectModal
                    open={openReject}
                    onClose={() => setOpenReject(false)}
                />
            )}
        </div>
    );
};

export default RefundRequestDetailDirector;
