/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from "d-react-components";
import { map } from "lodash";
import { useState } from "react";
import Carousel, { Modal as ModalImage, ModalGateway } from "react-images";
import Messages from "../../../languages/Messages";
import WSDefectionVisualCrudModal from "./WSDefectionVisualCrudModal";

// eslint-disable-next-line no-unused-vars
type functionType = (value: any) => void;

interface IDefectionVisualItem {
    index: number;
    visualization: any;
    onRemove?: functionType;
    onEdit?: functionType;
    hiddenRemove?: boolean;
    hiddenUpdate?: boolean;
}
interface IWSDefectionVisual {
    visualizationList: any[];
    hiddenAdd?: boolean;
    hiddenRemove?: boolean;
    hiddenUpdate?: boolean;
    onAdd?: functionType;
    onRemove?: functionType;
    onUpdate?: functionType;
}

const DefectionVisualItem = ({
    index,
    visualization,
    onRemove,
    onEdit,
    hiddenUpdate = false,
    hiddenRemove = false,
}: IDefectionVisualItem) => {
    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });
    return (
        <div className="mt-3">
            <div className="flex-center">
                <label className="w-25">{`${Messages.defection} ${index}`}</label>
                <div className="divider flex-grow-1" />
                <Button
                    iconName="edit"
                    variant="trans"
                    onClick={() => onEdit && onEdit(visualization?.id)}
                    hidden={hiddenUpdate}
                />

                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={() => onRemove && onRemove(visualization?.id)}
                    hidden={hiddenRemove}
                />
            </div>
            <div className="d-flex flex-wrap mt-3">
                {map(visualization?.images, (media, index) => (
                    <div className="position-relative mr-3">
                        <img
                            className="image-square-x-large "
                            src={media}
                            onClick={() =>
                                setImageToView({
                                    isVisible: true,
                                    index,
                                })
                            }
                        />
                    </div>
                ))}
            </div>

            <div className="bg-muted p-3 mt-3 flex-column">
                <label>{Messages.note}</label>
                <text>{visualization?.note}</text>
            </div>

            <ModalGateway>
                {imageToView.isVisible ? (
                    <ModalImage
                        onClose={() => setImageToView({ isVisible: false })}
                    >
                        <Carousel
                            views={map(visualization?.images, (url) => ({
                                source: url,
                            }))}
                            currentIndex={imageToView.index}
                        />
                    </ModalImage>
                ) : null}
            </ModalGateway>
        </div>
    );
};

const WSCDefectionVisual = ({
    visualizationList = [],
    hiddenAdd = false,
    hiddenUpdate = false,
    hiddenRemove = false,
    onAdd,
    onUpdate,
    onRemove,
}: IWSDefectionVisual) => {
    const [crudVisualization, setCrudVisualization] = useState({
        open: false,
        visualization: null,
    });

    const onClickAdd = () =>
        setCrudVisualization({
            open: true,
            visualization: null,
        });

    const onClickSave = (data: any) => {
        if (data?.id) {
            onUpdate && onUpdate(data);
        } else {
            onAdd && onAdd(data);
        }
        onCloseCrud();
    };

    const onCloseCrud = () =>
        setCrudVisualization({
            open: false,
            visualization: null,
        });

    return (
        <div className="flex-column card-container p-4">
            <div className="flex-center justify-content-between">
                <label>{Messages.defectionVisualization}</label>
                <Button onClick={onClickAdd} disabled={hiddenAdd}>
                    {Messages.addItem}
                </Button>
            </div>
            {map(visualizationList, (visualization, index) => (
                <DefectionVisualItem
                    index={index + 1}
                    visualization={visualization}
                    onRemove={onRemove}
                    onEdit={() =>
                        setCrudVisualization({ open: true, visualization })
                    }
                    hiddenUpdate={hiddenUpdate}
                    hiddenRemove={hiddenRemove}
                />
            ))}

            {crudVisualization.open && (
                <WSDefectionVisualCrudModal
                    open={crudVisualization.open}
                    onClose={() =>
                        setCrudVisualization({
                            open: false,
                            visualization: null,
                        })
                    }
                    defaultValue={crudVisualization.visualization}
                    onSave={onClickSave}
                />
            )}
        </div>
    );
};

export default WSCDefectionVisual;
