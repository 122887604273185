import { gql } from "@apollo/client";
import { F_CUSTOMER } from "../customer/CustomerFragment";
import { F_PAGINATION } from "../Fragment";
import { F_PREMIUM_SERVICE, F_PRODUCT } from "../product/ProductFragment";
import { F_USER } from "../user/UserFragment";

export const F_VOUCHER_ITEM = gql`
    ${F_USER}
    fragment F_VOUCHER_ITEM on Voucher {
        id
        voucherNo
        code
        type
        remark
        status
        effectiveDate
        expiryDate
        createBy {
            ...F_USER
        }
        createdAt

        ownership {
            status
        }
        usageState {
            status
        }
    }
`;

export const F_VOUCHER_ITEM_EXPORT = gql`
    ${F_USER}
    ${F_CUSTOMER}
    fragment F_VOUCHER_ITEM_EXPORT on Voucher {
        id
        code
        product {
            SKU
            id
            productId
            th {
                name
            }
            en {
                name
            }
        }
        effectiveDate
        expiryDate
        createBy {
            ...F_USER
        }
        createdAt

        ownership {
            status
            customer {
                ...F_CUSTOMER
            }
            remark
        }
        usageState {
            status
            usedAt
            worksheet {
                id
                worksheetNo
            }
        }
    }
`;

export const F_VOUCHER_ITEM_CUSTOMER = gql`
    ${F_USER}
    fragment F_VOUCHER_ITEM_CUSTOMER on Voucher {
        id
        voucherNo
        code
        type
        remark
        status
        effectiveDate
        expiryDate
        createBy {
            ...F_USER
        }
        product {
            SKU
            id
            productId
            regularPrice
            salePrice
            th {
                name
            }
            en {
                name
            }
        }
        createdAt
    }
`;

export const F_VOUCHERS = gql`
    ${F_VOUCHER_ITEM}
    ${F_PAGINATION}
    fragment F_VOUCHERS on Vouchers {
        voucher {
            ...F_VOUCHER_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_VOUCHERS_EXPORT = gql`
    ${F_VOUCHER_ITEM_EXPORT}
    ${F_PAGINATION}
    fragment F_VOUCHERS_EXPORT on Vouchers {
        voucher {
            ...F_VOUCHER_ITEM_EXPORT
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_VOUCHERS_CUSTOMER = gql`
    ${F_VOUCHER_ITEM_CUSTOMER}
    ${F_PAGINATION}
    fragment F_VOUCHERS_CUSTOMER on Vouchers {
        voucher {
            ...F_VOUCHER_ITEM_CUSTOMER
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_VOUCHER = gql`
    ${F_PRODUCT}
    ${F_USER}
    ${F_CUSTOMER}
    fragment F_VOUCHER on Voucher {
        id
        voucherNo
        code
        type
        remark
        product {
            ...F_PRODUCT
        }
        activity {
            createBy {
                ...F_USER
            }
            message
            createdAt
        }
        status
        effectiveDate
        expiryDate
        createBy {
            ...F_USER
        }
        createdAt
        ownership {
            status
            customer {
                ...F_CUSTOMER
            }
            remark
        }
        usageState {
            status
            usedAt
            worksheet {
                id
                worksheetNo
            }
        }
        priceFromOrder
    }
`;

export const F_VOUCHER_CHECK = gql`
    ${F_PREMIUM_SERVICE}
    ${F_CUSTOMER}
    fragment F_VOUCHER_CHECK on Voucher {
        id
        voucherNo
        code
        product {
            SKU
            id
            productId
            regularPrice
            salePrice
            th {
                name
            }
            en {
                name
            }
            thumbnail {
                thumbnail
            }
            productType
            premiumService {
                ...F_PREMIUM_SERVICE
            }
        }
        ownership {
            status
            customer {
                ...F_CUSTOMER
            }
            remark
        }
        status
        effectiveDate
        expiryDate
    }
`;

export const F_VOUCHER_PRODUCT = gql`
    ${F_PRODUCT}
    fragment F_VOUCHER_PRODUCT on ProductVoucher {
        expiryDate
        voucherQuantity
        voucherCodes
        product {
            ...F_PRODUCT
        }
    }
`;
