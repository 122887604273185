import { Button, InputText, Modal } from "d-react-components";
import { useState } from "react";
import Messages from "../../../languages/Messages";

const OrderCreateLoadOrder = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <div className="ml-3">
            <Button onClick={() => setOpenModal(true)}>
                {Messages.loadOrder}
            </Button>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title={Messages.loadOrder}
                >
                    <InputText label={Messages.loadOrder} />
                </Modal>
            )}
        </div>
    );
};

export default OrderCreateLoadOrder;
