import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { generatePath, useParams } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import AppLink from "../../../common/AppLink";
import { ORDER_STATUSES } from "../../../constant/order";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CustomerDetailOrder = () => {
    const { customerId } = useParams<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.orderNo,
            dataIndex: "orderNo",
            render: (orderNo: string, order: any) => (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: order.id,
                    })}
                >
                    {orderNo}
                </AppLink>
            ),
        },
        {
            title: Messages.billToName,
            dataIndex: "billing",
            render: (billing: any, item) =>
                billing?.sameServiceAddress
                    ? `${item?.shippings?.[0]?.firstName} ${item?.shippings?.[0]?.lastName}`
                    : `${billing?.firstName} ${billing?.lastName}`,
        },
        {
            title: Messages.total,
            dataIndex: "total",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status: string) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={ORDER_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },

        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) => createBy?.firstName,
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (paging: any) => {
        return CustomerAPI.orderByCustomer(customerId, "", paging);
    };

    return (
        <div className="p-4 bg-white">
            <h4>{Messages.order}</h4>
            <AwesomeTableComponent
                source={source}
                columns={columns}
                className="mt-3"
                transformer={(res) => res?.data?.data?.order ?? []}
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default CustomerDetailOrder;
