import { Modal } from "antd";
import { Button } from "d-react-components";
import { useState } from "react";
import Messages from "../../../languages/Messages";
import MediaManager from "../MediaManager";

export const SelectMediaManagerModal = ({
    open,
    onClose,
    isSingleSelect,
    onSelect,
}: any) => {
    const onSelectMedia = (mediaList: any[]) => {
        onSelect && onSelect(mediaList);
        onClose();
    };
    return (
        <Modal
            visible={open}
            onCancel={onClose}
            destroyOnClose
            className="media-manager__modal-select-media"
            closable={false}
            footer={null}
        >
            <MediaManager
                isSelect
                onSelect={onSelectMedia}
                isSingleSelect={isSingleSelect}
                onClickBack={onClose}
            />
        </Modal>
    );
};

interface ISelectMediaButton {
    isSingleSelect?: boolean;
    buttonText?: string;
    disabled?: boolean;
    className?: string;
    renderButton?: () => any;
    onSelect: (media: any) => any;
}

const SelectMediaManagerButton = ({
    renderButton,
    onSelect,
    isSingleSelect,
    buttonText = Messages.upload,
    disabled,
    className,
}: ISelectMediaButton) => {
    const [openModal, setOpenModal] = useState(false);
    const onClickUploadButton = () => {
        if (disabled) {
            return;
        }
        setOpenModal(true);
    };
    const renderButtonSelect = () => {
        return (
            <div onClick={onClickUploadButton}>
                {renderButton ? (
                    renderButton()
                ) : (
                    <Button content={buttonText} disabled={disabled} />
                )}
            </div>
        );
    };

    return (
        <div className={className}>
            {renderButtonSelect()}
            <SelectMediaManagerModal
                onClose={() => setOpenModal(false)}
                open={openModal}
                onSelect={onSelect}
                isSingleSelect={isSingleSelect}
            />
        </div>
    );
};

export default SelectMediaManagerButton;
