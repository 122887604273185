import * as Yup from "yup";

export const NewsCategorySchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    order: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
});

export const NewsInfoSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    shortDescription: Yup.string().required("Required"),
});

export const NewsSchema = Yup.object().shape({
    th: NewsInfoSchema,
    en: NewsInfoSchema,
    publishedDate: Yup.string().required("Required"),
    categoryIds: Yup.array().required("Required"),
    featuredUrl: Yup.string().required("Required"),
});
