export interface IStaticContent {
    id: string;
    name: string;
    en: {
        content: string;
    };
    th: {
        content: string;
    };
    type: string;
}

export const mapStaticContentToServer = (body: any) => {
    if (!body) return {};
    const { name, en, th, type } = body;
    return {
        name,
        en,
        th,
        type,
    };
};
