import { gql } from "@apollo/client";

export const F_FAQ_CATEGORY = gql`
    fragment F_FAQ_CATEGORY on CategoryFaq {
        value: id
        title: name
        tree
        parentId
        order
    }
`;
export const F_FAQ = gql`
    fragment F_FAQ on Faq {
        id
        title
        description
        category {
            id
            name
        }
        status
        createBy {
            id
        }
    }
`;
