import { CheckboxGroup, DateInput, Icon } from "d-react-components";
import { filter, map } from "lodash";
import { useEffect, useState } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { YES_NO_OPTIONS } from "../../../constant/app";
import {
    ORDER_PAYMENT_STATUSES,
    ORDER_STATUSES,
} from "../../../constant/order";
import Messages from "../../../languages/Messages";
import PaymentMethodsCheckboxGroup from "../../paymentmethod/common/PaymentMethodsCheckboxGroup";
import UserSelect from "../../user/common/UserSelect";
import WarehouseSelect from "../../warehouse/common/WarehouseSelect";

interface IOrderFilterForm {
    formData: any;
}

const OrderFilterForm = ({ formData }: IOrderFilterForm) => {
    const [sourceOrders, setSourceOrders] = useState([]);

    useEffect(() => {
        loadOrderSource();
    }, []);

    const loadOrderSource = () => {
        OrderAPI.orderSource().then(setSourceOrders);
    };

    const inputClassName = "col-6 mt-3";
    const formValues = formData.values ?? {};
    const formErrors = formData.errors ?? {};

    const onRemoveCreatedBy = (userId: string) => {
        const values = filter(
            formValues.createdBy,
            (item) => item.id !== userId
        );
        formData.setFieldValue("createdBy", values);
    };

    const renderCreatedBySelected = () => {
        return (
            <div className="d-flex mt-3 col-12">
                {map(formValues.createdBy, (user) => (
                    <div className="border border-primary p-2 mr-3 flex-center-y text-primary">
                        {`${user?.firstName} ${user?.lastName}`}
                        <div
                            onClick={() => onRemoveCreatedBy(user.id)}
                            className="ml-2 cursor-pointer"
                        >
                            <Icon name="cancel" />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="row">
            <DateInput
                className={inputClassName}
                label={Messages.from}
                onChange={(value) => formData.setFieldValue("from", value)}
                value={formValues.from}
                error={formErrors.from as any}
            />
            <DateInput
                className={inputClassName}
                label={Messages.to}
                onChange={(value) => formData.setFieldValue("to", value)}
                value={formValues.to}
                error={formErrors.to as any}
            />
            <CheckboxGroup
                numberOfColumns="2"
                dataSource={ORDER_STATUSES}
                label={Messages.status}
                className="mt-3 col-12"
                value={formValues?.status}
                getLabel={(item) => (Messages as any)[item?.label]}
                onChange={(value) => formData.setFieldValue("status", value)}
            />

            <PaymentMethodsCheckboxGroup
                value={map(formValues?.paymentMethod, (item) => item.id)}
                onChange={(value) =>
                    formData.setFieldValue("paymentMethod", value)
                }
            />

            <CheckboxGroup
                numberOfColumns="2"
                dataSource={ORDER_PAYMENT_STATUSES}
                label={Messages.paymentStatus}
                className="mt-3 col-12"
                value={formValues?.paymentStatus}
                getLabel={(item) => (Messages as any)[item?.label]}
                onChange={(value) =>
                    formData.setFieldValue("paymentStatus", value)
                }
            />
            <CheckboxGroup
                dataSource={sourceOrders}
                label={Messages.source}
                className="mt-3 col-12"
                value={formValues?.source}
                onChange={(value) => formData.setFieldValue("source", value)}
            />

            <UserSelect
                className="mt-3 col-12"
                onChange={(value) => formData.setFieldValue("createdBy", value)}
                label={Messages.createdBy}
                value={formValues.createdBy}
            />
            {renderCreatedBySelected()}

            <WarehouseSelect
                className="mt-3 col-12"
                value={formValues?.warehouse}
                onChange={(value) => formData.setFieldValue("warehouse", value)}
                multiple
                error={formErrors?.warehouse}
            />

            <InputSelectForm
                className="mt-3 col-12"
                dataSource={YES_NO_OPTIONS}
                label={Messages.creditNote}
                keyData="isCreditNote"
                form={formData}
            />
        </div>
    );
};

export default OrderFilterForm;
