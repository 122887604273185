import React, { useContext, useEffect, useState } from "react";
import WarehouseAPI from "../../../api/warehouse/WarehouseAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { OrderCreateContext } from "../../../context/order";
import { IWarehouse } from "../../../interfaces/warehouse";
import Messages from "../../../languages/Messages";

const OrderCreateWarehouse = () => {
    const { orderForm } = useContext(OrderCreateContext);
    const [warehouseList, setWarehouseList] = useState<IWarehouse[]>([]);

    useEffect(() => {
        loadWarehouseList();
    }, []);

    const loadWarehouseList = () => {
        WarehouseAPI.warehouseActiveList().then((res) =>
            setWarehouseList(res?.data?.data?.warehouse ?? [])
        );
    };

    return (
        <div className="card-container p-4">
            <InputSelectForm
                required
                form={orderForm}
                keyData="warehouseId"
                label={Messages.warehouse}
                dataSource={warehouseList}
                getLabel={(item: any) =>
                    `${item?.[Messages.getLanguage()]?.name} (${
                        item?.warehouseNo
                    })`
                }
            />
        </div>
    );
};

export default OrderCreateWarehouse;
