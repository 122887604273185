import { Progress as ProgressAntd } from "antd";
import {
    Icon,
    InputText,
    Modal,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import { InventoryImportContext } from "../../../context/inventory";
import { mapImportInventToServer } from "../../../interfaces/inventory";
import Messages from "../../../languages/Messages";
import InventoryImportInput from "./InventoryImportInput";

const STEPS = [
    {
        id: "input",
        saveText: Messages.validate,
    },
    {
        id: "validate",
        saveText: Messages.import,
    },
    {
        id: "import",
        saveText: Messages.done,
    },
];

interface IInventoryImportModal {
    open: boolean;
    onClose: () => any;
    onImportSuccess?: () => void;
}

const InventoryImportValidate = () => {
    const { importForm } = useContext(InventoryImportContext);
    const { itemImportList, itemInvalid } = importForm.values;
    return (
        <div className="flex-column">
            <div className="d-flex">
                <Icon name="done" className="text-success mr-3" />
                <text className="mr-3">
                    {itemImportList?.length - itemInvalid?.length}
                </text>

                <text>{Messages.itemValidToImport}</text>
            </div>
            <div className="d-flex mt-3">
                <Icon name="error" className="text-error mr-3" />
                <text className="mr-3">{itemInvalid?.length}</text>
                <text>{Messages.itemHaveErrorImport}</text>
                <div
                    className="text-primary ml-1 cursor-pointer"
                    hidden={itemInvalid?.length === 0}
                >
                    {Messages.downloadLog}
                </div>
            </div>
            <InputText
                label={Messages.memo}
                multiple
                className="mt-3"
                value={importForm.values.remark}
                error={importForm.errors.remark}
                name="remark"
                onChange={importForm.handleChange}
            />
            <ButtonFileUpload
                onChange={(value: any) =>
                    importForm.setFieldValue("attachment", value)
                }
                maxFiles={1}
                className="mt-3"
            />
        </div>
    );
};

const InventoryImportProcessing = () => {
    return <ProgressAntd percent={100} />;
};

const InventoryImportModal = ({
    open,
    onClose,
    onImportSuccess,
}: IInventoryImportModal) => {
    const [step, setStep] = useState(0);

    const importForm = useFormik<any>({
        initialValues: {
            warehouseId: null,
            itemImportList: [],
            itemInvalid: [],
            importEnable: false,
            remark: "",
            attachment: [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            const input = mapImportInventToServer(values);
            Progress.show(
                { method: InventoryAPI.import, params: [input] },
                () => {
                    Notifications.showSuccess(Messages.importInventorySuccess);
                    onClose();
                    onImportSuccess && onImportSuccess();
                }
            );
        },
    });

    const onStepNext = () => {
        if (step === STEPS.length - 1) return;
        setStep(step + 1);
    };

    const onStepPre = () => {
        if (step === 0) return;
        setStep(step - 1);
    };

    const renderContent = () => {
        switch (step) {
            case 0:
                return <InventoryImportInput />;
            case 1:
                return <InventoryImportValidate />;
            case 2:
                return <InventoryImportProcessing />;
            default:
                return <div />;
        }
    };

    const { itemImportList, itemInvalid } = importForm.values;

    return (
        <Modal
            title={Messages.importStock}
            open={open}
            onClose={onClose}
            size="medium"
            saveText={Messages.import}
            onSave={() => importForm.handleSubmit()}
            saveButtonProps={{
                disabled:
                    itemImportList?.length === 0 || itemInvalid?.length > 0,
            }}
        >
            <InventoryImportContext.Provider
                value={{ importForm, onStepNext, onStepPre }}
            >
                {renderContent()}
            </InventoryImportContext.Provider>
        </Modal>
    );
};

export default InventoryImportModal;
