import classNames from "classnames";
import { InputText, Icon, Notifications } from "d-react-components";
import { isEmpty } from "lodash";
import React from "react";

export interface IInputTextQuantity {
    value: number;
    min?: number;
    max?: number;
    className?: string;
    onChange: (value: number) => void;
}

const InputTextQuantity = ({
    value = 0,
    className,
    min,
    max,
    onChange,
}: IInputTextQuantity) => {
    const valueParseNumber = parseInt(`0${value}`, 10);
    const classNameInput = classNames(
        "input-text-quantity__container",
        className
    );

    const onChangeValue = (newValue: number) => {
        if ((min || min === 0) && newValue < min) {
            Notifications.showError(`Can't less than ${min}`);
            return;
        }

        if ((max || max === 0) && newValue > max) {
            Notifications.showError(`Can't greater than ${max}`);
            return;
        }
        onChange(newValue);
    };

    return (
        <InputText
            className={classNameInput}
            prefix={
                <div
                    className="cursor-pointer"
                    onClick={() => onChangeValue(valueParseNumber - 1)}
                >
                    <Icon name="remove" size="small" />
                </div>
            }
            suffix={
                <div
                    className="cursor-pointer"
                    onClick={() => onChangeValue(valueParseNumber + 1)}
                >
                    <Icon name="add" size="small" />
                </div>
            }
            value={`${value ?? "0"}`}
            onChange={(event) => {
                const { value } = event.target;
                if (isEmpty(value)) {
                    onChange(0);
                    return;
                }
                onChangeValue(parseInt(`0${value}`, 10));
            }}
        />
    );
};

export default InputTextQuantity;
