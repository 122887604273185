import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";
import { F_USER } from "../user/UserFragment";

export const F_NOTIFICATION = gql`
    ${F_USER}
    fragment F_NOTIFICATION on Notification {
        id
        sender {
            ...F_USER
        }
        receiver {
            ...F_USER
        }
        type
        isRead
        content
        createdAt
        data
    }
`;

export const F_NOTIFICATIONS = gql`
    ${F_NOTIFICATION}
    ${F_PAGINATION}
    fragment F_NOTIFICATIONS on Notifications {
        notification {
            ...F_NOTIFICATION
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;
