import {
    AwesomeTableComponent,
    Button,
    HeaderTable,
    IColumnsProps,
    Modal,
    StringUtils,
} from "d-react-components";
import { debounce, filter, find, join, map } from "lodash";
import React, { useRef, useState } from "react";
import ProductAPI from "../../../api/product/ProductAPI";
import { PRODUCT_TYPE } from "../../../constant/product";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductNameView from "./ProductNameView";

interface IModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (productSelect: any) => void;
    defaultValue?: any[];
    multiple?: boolean;
    filterSource?: any;
    isHideAvaQtyCurrentWH?: boolean;
    isHideOnHoldQtyCurrentWH?: boolean;

    isHideAvaQtyDelivery?: boolean;
    isHideOnHoldQtyDelivery?: boolean;

    isHideRegularPrice?: boolean;
    isHideSalePrice?: boolean;
}

interface IProps extends Omit<IModalProps, "open" | "onClose"> {
    isInvalidateOpenSearch?: () => boolean;
    children?: any;
}

export const ProductSearchModal = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    multiple = true,
    filterSource,
    isHideAvaQtyCurrentWH,
    isHideOnHoldQtyCurrentWH,
    isHideAvaQtyDelivery,
    isHideOnHoldQtyDelivery,
    isHideRegularPrice,
    isHideSalePrice,
}: IModalProps) => {
    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",

            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
        {
            hidden: isHideAvaQtyCurrentWH,
            title: Messages.availableQtyCurrentStore,
            titleTooltip: Messages.availableQtyCurrentStoreTooltip,
            dataIndex: "allAvailableCW",
        },

        {
            hidden: isHideOnHoldQtyCurrentWH,
            title: Messages.onHoldQtyCurrentStore,
            titleTooltip: Messages.onHoldQtyCurrentStoreTooltip,
            dataIndex: "allOnHoldCW",
        },
        {
            hidden: isHideAvaQtyDelivery,
            title: Messages.availableQtyDelivery,
            titleTooltip: Messages.availableQtyDeliveryTooltip,
            dataIndex: "allAvailableDW",
        },
        {
            hidden: isHideOnHoldQtyDelivery,
            title: Messages.onHoldQtyDelivery,
            titleTooltip: Messages.onHoldQtyDeliveryTooltip,
            dataIndex: "allOnHoldDW",
        },

        {
            hidden: isHideRegularPrice,
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: StringUtils.moneyThaiFormat,
            width: 150,
        },

        {
            hidden: isHideSalePrice,
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
            width: 150,
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (category: any, product) => {
                if (product?.productType === PRODUCT_TYPE.SERVICE_PRODUCT) {
                    return product?.service?.name;
                }
                return (
                    <text className="text-wrap">
                        {join(
                            map(category, (item) => item?.name),
                            ", "
                        )}
                    </text>
                );
            },
            width: 150,
        },
    ];

    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [productSelect, setProductSelect] =
        useState<IProduct[]>(defaultValue);

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const source = (pagingData: any) => {
        return ProductAPI.list(searchRef.current, pagingData, filterSource);
    };

    const onClickSave = () => {
        onSave(productSelect);
        onClose();
    };

    const getSelectProps = (product: any) => {
        let disabled = false;
        if (
            product?.productType === PRODUCT_TYPE.DEFAULT_PRODUCT &&
            product.allAvailableCW === 0 &&
            !product.sellingOutOfStock
        ) {
            disabled = true;
        }
        return {
            disabled,
        };
    };
    const onChangeSelectProduct = (
        selectedRowKeys: string[],
        selectRows: any[]
    ) => {
        const existedSelectRow = filter(selectRows, (item) => !!item);
        const products = map(selectedRowKeys, (productId) => {
            let selectRow = find(
                existedSelectRow,
                (item) => item?.id === productId
            );
            if (!selectRow) {
                selectRow = find(
                    productSelect,
                    (item) => item.id === productId
                );
            }
            return selectRow;
        });
        setProductSelect(products);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.searchProductTable}
            onSave={onClickSave}
            size="large"
        >
            <HeaderTable
                // onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                transformer={(res) => {
                    return res?.data?.data?.product ?? [];
                }}
                baseColumnProps={{ width: 600 }}
                bordered={false}
                rowSelection={{
                    type: multiple ? "checkbox" : "radio",
                    selectedRowKeys: map(productSelect, (item) => item.id),
                    preserveSelectedRowKeys: true,
                    onChange: onChangeSelectProduct as any,
                    getCheckboxProps: getSelectProps,
                }}
            />
        </Modal>
    );
};

const ProductSearchButton = ({
    isInvalidateOpenSearch = () => false,
    children = Messages.search,
    ...modalProps
}: IProps) => {
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const onClickSearch = () => {
        if (isInvalidateOpenSearch()) {
            return;
        }
        setOpenSearch(true);
    };

    return (
        <div>
            <Button iconName="search" onClick={onClickSearch}>
                {children}
            </Button>
            {openSearch && (
                <ProductSearchModal
                    open={openSearch}
                    onClose={() => setOpenSearch(false)}
                    {...modalProps}
                />
            )}
        </div>
    );
};

export default ProductSearchButton;
