import {
    AwesomeTableComponent,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    StringUtils,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, join, map } from "lodash";
import React, { useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import ProductAPI from "../../../api/product/ProductAPI";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import TableAction from "../../../common/TableActions";
import { PRODUCT_TYPE } from "../../../constant/product";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import { isFilteredBody } from "../../../utils/Utils";
import Path from "../../Path";
import ProductFilterModal from "./ProductFilterModal";

const ProductTable = () => {
    const history = useHistory();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [openFilter, setOpenFilter] = useState(false);

    const [filterState, setFilterState] = useQueryParam(
        "filterState",
        JsonParam
    );

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "productId",
            render: (productId: string, product: any) => (
                <AppLink
                    to={generatePath(Path.PRODUCT_DETAIL, {
                        productId: product.id,
                    })}
                >
                    {productId}
                </AppLink>
            ),
        },
        {
            title: Messages.thumbnail,
            dataIndex: "thumbnail",
            render: (thumbnail: any) => (
                <Image
                    src={thumbnail?.thumbnail}
                    className="image-square-x-small"
                />
            ),
        },
        {
            title: Messages.name,
            dataIndex: Messages.getLanguage(),
            render: (info: any, product: any) => {
                return (
                    <AppLink
                        to={generatePath(Path.PRODUCT_DETAIL, {
                            productId: product.id,
                        })}
                    >
                        {info?.name}
                    </AppLink>
                );
            },
        },
        {
            title: Messages.sku,
            dataIndex: "SKU",
        },

        {
            title: Messages.visibility,
            dataIndex: "visibility",
            render: (show) => (
                <ViewLabelStatus
                    content={show ? Messages.enabled : Messages.disabled}
                    color={show ? "#33B950" : "#C4C4C4"}
                />
            ),
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (category: any, product: any) => {
                if (product?.productType === PRODUCT_TYPE.SERVICE_PRODUCT) {
                    return product?.service?.name;
                }
                return join(
                    map(category, (item) => item?.name),
                    ", "
                );
            },
        },
        {
            title: Messages.lastUpdated,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "source",
            render: (source: any, product) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, product)
                    }
                />
            ),
            align: "center",
        },
    ];

    const onClickTableAction = (actionId: string, product: IProduct) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.PRODUCT_DETAIL, {
                        productId: product.id,
                    })
                );
        }
    };

    const source = (pagingData: any) => {
        return ProductAPI.list(searchRef.current, pagingData, filterState);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const onFilter = (values: {}) => {
        setFilterState(values);
        tableRef.current.refresh();
    };

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={() => history.push(Path.PRODUCT_CREATE)}
                label={Messages.products}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
                isFiltered={isFilteredBody(filterState)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => {
                    return res?.data?.data?.product ?? [];
                }}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
            />
            {openFilter && (
                <ProductFilterModal
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    onFilter={onFilter}
                    defaultValue={filterState}
                />
            )}
        </div>
    );
};

export default ProductTable;
