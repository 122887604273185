import { Button, Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { CustomerDetailContext } from "../../../context/customer";
import { AddressServiceSchema } from "../../../formschema/customer";
import { useDeleteCustomerAdd } from "../../../hoook/customer";
import {
    IServiceAddress,
    mapAddressToForm,
    mapAddressToSer,
} from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressItemHeader from "../common/AddressItemHeader";
import CustomerAddressForm from "../common/CustomerAddressForm";

interface IAddressItem {
    address: IServiceAddress;
    index: number;
}

const CreateEditAddressModal = ({
    open,
    onClose,
    isEdit,
    addressDefault = {},
}: any) => {
    const { customerId } = useParams<any>();
    const { loadCustomerServiceAdds } = useContext(CustomerDetailContext);

    const addressForm = useFormik<any>({
        initialValues: mapAddressToForm(addressDefault),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddressServiceSchema,
        onSubmit: (values) => {
            const input = mapAddressToSer(values);
            if (isEdit) {
                onEditAddress(input);
            } else {
                onCreateAddress(input);
            }
        },
    });

    const onEditAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.updateServiceAddress,
                params: [customerId, addressDefault?.id, body],
            },
            () => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                loadCustomerServiceAdds();
                onClose();
            }
        );
    };

    const onCreateAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.createServiceAddress,
                params: [customerId, body],
            },
            () => {
                Notifications.showSuccess(
                    Messages.createCustomerAddressSuccess
                );
                loadCustomerServiceAdds();
                onClose();
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateAddress}
            size="medium"
            onSave={() => addressForm.handleSubmit()}
        >
            <CustomerAddressForm
                formData={addressForm}
                title={Messages.serviceAddress}
            />
        </Modal>
    );
};

const AddressItem = ({ address, index }: IAddressItem) => {
    const { customerId } = useParams<any>();
    const { loadCustomerServiceAdds } = useContext(CustomerDetailContext);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const { id, phone, nameOfPlace, pin, fullAddress, fullName } = address;
    const { onClickDelete } = useDeleteCustomerAdd(
        customerId,
        id,
        loadCustomerServiceAdds
    );

    const onSetDefaultAddress = () => {
        const body = { ...mapAddressToSer(address), pin: true };
        Progress.show(
            {
                method: CustomerAPI.updateServiceAddress,
                params: [customerId, id, body],
            },
            () => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                loadCustomerServiceAdds();
            }
        );
    };

    return (
        <div className="mt-3">
            <AddressItemHeader
                isDefault={pin}
                label={`${Messages.address} ${index}`}
                onClickEdit={() => setOpenEdit(true)}
                onClickDelete={onClickDelete}
                onClickSetDefault={onSetDefaultAddress}
            />
            <div className="flex-column">
                <text>{fullName}</text>
                <text>{phone}</text>
                <text>{fullAddress}</text>
                <text>{nameOfPlace}</text>
            </div>
            {openEdit && (
                <CreateEditAddressModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    isEdit
                    addressDefault={address}
                />
            )}
        </div>
    );
};

const CustomerDetailAddress = () => {
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const { customerServiceAdds } = useContext(CustomerDetailContext);
    return (
        <div>
            <div className="d-flex justify-content-between">
                <h4>{Messages.serviceAddress}</h4>
                <Button iconName="add" onClick={() => setOpenAdd(true)}>
                    {Messages.newAddress}
                </Button>
            </div>
            <div className="bg-white p-4 mt-3">
                {map(customerServiceAdds, (address, index) => (
                    <AddressItem address={address} index={index + 1} />
                ))}
            </div>
            {openAdd && (
                <CreateEditAddressModal
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}
        </div>
    );
};

export default CustomerDetailAddress;
