/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { TimeUtils } from "d-react-components";
import { map } from "lodash";
import moment from "moment";
import Messages from "../languages/Messages";
import { IBillingAddress, IServiceAddress, mapAddressToSer } from "./address";
import { IUser } from "./user";

export interface ICustomerCreditTerm {
    year: number;
    month: number;
    day: number;
}

export interface ICustomer {
    id?: string;
    customerId?: string;
    avatar?: string;
    firstName?: string;
    lastName?: string;
    typeOfCustomer?: string;
    fullName?: string;
    nickName?: string;
    company?: string;
    gender?: "MALE" | "FEMALE";
    dateOfBirth?: string;
    nationality?: string;
    email?: string;
    phone?: number;
    address?: IServiceAddress[];
    billingAddress?: IBillingAddress[];
    createBy?: IUser;
    addressServiceDefault?: IServiceAddress;
    addressBillingDefault?: IBillingAddress;
    status: string;
    creditTerm: ICustomerCreditTerm;
    isVerified: boolean;
}

export const mapCustomerFromServer = (customerData: any): ICustomer => {
    const { dateOfBirth, firstName, lastName, createdAt, updatedAt } =
        customerData;
    return {
        ...customerData,
        fullName: `${firstName} ${lastName}`,
        dateOfBirth: moment(dateOfBirth),
        createdAt: TimeUtils.toDateTime(createdAt),
        updatedAt: TimeUtils.toDateTime(updatedAt),
    };
};

export const mapCustomerToServer = (customer: ICustomer) => {
    const {
        address,
        billingAddress,
        fullName,
        id,
        dateOfBirth,
        customerId,
        createdAt,
        updatedAt,
        addressServiceDefault,
        addressBillingDefault,
        password,
        confirmPassword,
        creditTerm,
        isVerified,
        ...resCustomer
    } = customer as any;
    return {
        customer: {
            ...resCustomer,
            dateOfBirth: moment(dateOfBirth).toISOString(),
        },
        address: map(address, mapAddressToSer),
        billingAddress: map(billingAddress, mapAddressToSer),
        password: {
            password,
            confirmPassword,
        },
    };
};

export const mapCustomerFilterBodyToServer = (filterBody: any) => {
    if (!filterBody) return {};
    const { gender, province, status, typeOfCustomer } = filterBody;
    return {
        gender,
        province,
        status,
        typeOfCustomer,
    };
};

export const getCreditTermString = (creditTerm: ICustomerCreditTerm) => {
    return `${creditTerm?.year} ${Messages.years} ${creditTerm?.month} ${Messages.month} ${creditTerm?.day} ${Messages.days}`;
};
