import { InputText } from "d-react-components";
import React, { useContext } from "react";
import { UserCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";

const UserCrudPassword = () => {
    const { userForm, userUpdatePassForm } = useContext(UserCrudContext);
    const formError = {
        ...(userForm.errors ?? {}),
        ...(userUpdatePassForm.errors ?? {}),
    };
    return (
        <div className="bg-white p-4">
            <div className="row ">
                <div className="col-6 mt-3">
                    <InputText
                        label={Messages.newPassword}
                        onChange={(event) => {
                            const { value } = event.target;
                            userForm.setFieldValue("password", value);
                            userUpdatePassForm.setFieldValue("password", value);
                        }}
                        value={userForm?.values?.password}
                        error={formError?.password}
                        type="password"
                    />
                </div>
                <div className="col-6 mt-3">
                    <InputText
                        label={Messages.newPasswordConfirm}
                        onChange={(event) => {
                            const { value } = event.target;
                            userForm.setFieldValue("confirmPassword", value);
                            userUpdatePassForm.setFieldValue(
                                "confirmPassword",
                                value
                            );
                        }}
                        value={userForm?.values?.confirmPassword}
                        error={formError?.confirmPassword}
                        type="password"
                    />
                </div>
            </div>
        </div>
    );
};

export default UserCrudPassword;
