import { Button, Progress } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";
import BookingCrudContactModal from "../common/BookingCrudContactModal";

const BookingDetailContact = () => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);
    const { contact, id, status } = bookingDetail;
    const { firstName, lastName, phone, email } = contact ?? {};
    const [openEdit, setOpenEdit] = useState(false);

    const isHideEdit = useMemo(
        () => status !== BOOKING_STATUS.PENDING,
        [status]
    );

    const onUpdateContact = (contact: any) => {
        Progress.show(
            { method: BookingAPI.updateContact, params: [id, contact] },
            setBookingDetail
        );
    };

    return (
        <div className="card-container p-4 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.bookingContact}</label>
                <Button
                    variant="trans"
                    onClick={() => setOpenEdit(true)}
                    hidden={isHideEdit}
                >
                    {Messages.edit}
                </Button>
            </div>
            <div className="flex-column">
                <text>{`${firstName} ${lastName}`}</text>
                <text>{email}</text>
                <text>{phone}</text>
            </div>
            {openEdit && (
                <BookingCrudContactModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    defaultValue={contact}
                    onChange={onUpdateContact}
                />
            )}
        </div>
    );
};

export default BookingDetailContact;
