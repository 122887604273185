// ----------------------------------------
// -- PAGINATION
// ----------------------------------------

import { gql } from "@apollo/client";

export const F_PAGINATION = gql`
    fragment F_PAGINATION on Pagination {
        totalDocs
        limit
        currentPage: page
        items: totalDocs
        pagingCounter
    }
`;
