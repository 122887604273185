import {
    AwesomeTableComponent,
    Button,
    InputText,
    StringUtils,
    Summary,
} from "d-react-components";
import { reduce } from "lodash";
import React, { useMemo } from "react";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { PRODUCT_TYPE } from "../../../constant/product";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/common/ProductNameView";

interface IBookingProductTable {
    products: IProduct[];
    onUpdateProduct?: (product: IProduct) => void;
    onRemove?: (id: string) => void;
}

const ProductInput = ({
    valueKey,
    value,
    onChange,
    product,
    ...props
}: any) => {
    if (!onChange) return value;
    return (
        <InputText
            value={value?.toLocaleString()}
            onChange={(event) => {
                const result = event.target.value.replace(/\D/g, "");
                let value = parseInt(result, 10);
                if (!value) {
                    value = 0;
                }
                onChange({
                    ...product,
                    [valueKey]: value,
                });
            }}
            className="input-text-quantity__container"
            {...props}
        />
    );
};

const BookingProductTable = ({
    products = [],
    onUpdateProduct,
    onRemove,
}: IBookingProductTable) => {
    const columns = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (item: any) => <ProductNameView product={item} />,
            width: 200,
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.openingCost,
            dataIndex: "openingCost",
            width: 200,
            render: (openingCost: number, product: any) => {
                if (product.productType === PRODUCT_TYPE.DEFAULT_PRODUCT) {
                    return openingCost;
                }
                return (
                    <ProductInput
                        product={product}
                        value={openingCost}
                        onChange={onUpdateProduct}
                        prefix="฿"
                        valueKey="openingCost"
                    />
                );
            },
        },
        {
            title: Messages.qnty,
            dataIndex: "quantity",
            width: 80,
            render: (quantity: number, item: any) =>
                onUpdateProduct ? (
                    <InputTextQuantity
                        value={quantity}
                        onChange={(quantity) =>
                        onUpdateProduct({ ...item, quantity })
                        }
                    />
                ) : (
                    quantity
                ),
        },
        {
            title: Messages.finalOpeningCost,
            dataIndex: "",
            render: (item: any) =>
                StringUtils.moneyThaiFormat(item?.quantity * item.openingCost),
        },
    ];

    if (onRemove) {
        columns.push({
            title: "",
            dataIndex: "id",
            render: (id: string) => (
                <Button
                    iconName="delete"
                    onClick={() => onRemove(id)}
                    variant="trans"
                />
            ),
        } as any);
    }
    const totalQuantity = useMemo(
        () =>
            reduce(
                products,
                (sum, item: any) => sum + parseInt(item.quantity, 10),
                0
            ),
        [products]
    );

    const totalOpeningCost = useMemo(
        () =>
            reduce(
                products,
                (sum, item: any) =>
                    sum +
                    parseInt(item.openingCost, 10) *
                        parseInt(item.quantity, 10),
                0
            ),
        [products]
    );

    return (
        <AwesomeTableComponent
            columns={columns}
            className="mt-3"
            dataSource={products}
            isPagination={false}
            bordered={false}
            baseColumnProps={{ width: 100 }}
            summary={() => (
                <Summary fixed>
                    <Summary.Row>
                        <Summary.Cell index={0} />
                        <Summary.Cell index={1} />
                        <Summary.Cell index={1} />
                        <Summary.Cell index={2}>{Messages.total}</Summary.Cell>
                        <Summary.Cell index={3} className="text-bold">
                            {totalQuantity}
                        </Summary.Cell>
                        <Summary.Cell index={4} className="text-bold">
                            {StringUtils.moneyThaiFormat(totalOpeningCost)}
                        </Summary.Cell>
                        <Summary.Cell index={1} />
                    </Summary.Row>
                </Summary>
            )}
        />
    );
};

export default BookingProductTable;
