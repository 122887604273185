import { gql } from "@apollo/client";
import { map } from "lodash";
import { mapAddressFromSer } from "../../interfaces/address";
import { mapCustomerFromServer } from "../../interfaces/customer";
import { F_ADDRESS, F_BILLING } from "../address/AddressFragment";
import API from "../API";
import { F_BOOKINGS } from "../booking/BookingFragment";
import { F_ORDERS } from "../order/OrderFragment";
import { F_VOUCHERS, F_VOUCHERS_CUSTOMER } from "../voucher/VoucherFragment";
import { F_CUSTOMER, F_CUSTOMERS, F_CUSTOMER_EXPORT } from "./CustomerFragment";

const listConvertor = {
    fromServer: (res: any) => ({
        data: {
            data: {
                ...res.data.data,
                customer: map(
                    res?.data?.data?.customer ?? [],
                    mapCustomerFromServer
                ),
            },
        },
    }),
};

const detailConvertor = {
    fromServer: (res: any) => mapCustomerFromServer(res?.data?.customer),
};

const addressesConvertor = {
    fromServer: (res: any) => map(res?.data?.addressAll, mapAddressFromSer),
};

export default {
    list: (q: string, paging = { pageIndex: 1, pageSize: 10 }, filter: any) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_CUSTOMERS}
                query customers(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: CustomerFilter
                ) {
                    data: customers(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_CUSTOMERS
                    }
                }
            `,
            variables: {
                q,
                page: paging.pageIndex,
                limit: paging.pageSize,
                filter,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        }),

    getExportList: (limit: number, filter: any) =>
        API.query({
            query: gql`
                ${F_CUSTOMER_EXPORT}
                query customerExport($limit: Int, $filter: CustomerFilter) {
                    customerExport(limit: $limit, filter: $filter) {
                        ...F_CUSTOMER_EXPORT
                    }
                }
            `,
            variables: {
                limit,
                filter,
            },
            fetchPolicy: "no-cache",
        }),

    searchCustomerByEmailPhone: (q: string) =>
        API.query({
            query: gql`
                ${F_CUSTOMER}
                query searchCustomerByEmailPhone($q: String) {
                    data: searchCustomerByEmailPhone(q: $q) {
                        ...F_CUSTOMER
                    }
                }
            `,
            variables: { q },
            fetchPolicy: "no-cache",
        }),

    create: (
        customerInput: any,
        addressInput: any,
        billingInput: any,
        passwordInput: any
    ) =>
        API.mutate({
            mutation: gql`
                ${F_CUSTOMER}
                mutation createCustomer(
                    $customerInput: CustomerInput!
                    $addressInput: [AddressInput]!
                    $billingInput: [BillingInput]!
                    $passwordInput: PasswordInput
                ) {
                    createCustomer(
                        customerInput: $customerInput
                        addressInput: $addressInput
                        billingInput: $billingInput
                        passwordInput: $passwordInput
                    ) {
                        ...F_CUSTOMER
                    }
                }
            `,
            variables: {
                customerInput,
                addressInput,
                billingInput,
                passwordInput,
            },
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_CUSTOMER}
                query detail($id: ID!) {
                    customer(id: $id) {
                        ...F_CUSTOMER
                    }
                }
            `,
            variables: { id },
        }),

    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_CUSTOMER}
                mutation update($id: ID!, $input: CustomerInput) {
                    customer: updateCustomer(id: $id, input: $input) {
                        ...F_CUSTOMER
                    }
                }
            `,
            variables: { id, input },
        }),

    updateCreditTerm: (customerId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_CUSTOMER}
                mutation updateCreditTerm(
                    $customerId: ID!
                    $input: CustomerCreditTermInput
                ) {
                    customer: updateCustomerCreditTerm(
                        customerId: $customerId
                        input: $input
                    ) {
                        ...F_CUSTOMER
                    }
                }
            `,
            variables: { customerId, input },
        }),

    serviceAddresses: (id: string) =>
        API.withConverter(addressesConvertor).query({
            query: gql`
                ${F_ADDRESS}
                query customerAddress($id: ID!) {
                    addressAll(customerId: $id) {
                        ...F_ADDRESS
                    }
                }
            `,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    createServiceAddress: (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation createAddress($id: ID!, $input: AddressInput) {
                    createAddress(customerId: $id, input: $input) {
                        id
                    }
                }
            `,
            variables: { id, input },
        }),

    updateServiceAddress: (customerId: string, addressId: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation updateAddress(
                    $customerId: ID!
                    $addressId: ID!
                    $input: AddressInput
                ) {
                    updateAddress(
                        customerId: $customerId
                        addressId: $addressId
                        input: $input
                    ) {
                        id
                    }
                }
            `,
            variables: { customerId, addressId, input },
        }),
    deleteServiceAddress: (id: string, addressId: string) =>
        API.mutate({
            mutation: gql`
                mutation delete($id: ID!, $addressId: ID!) {
                    destroyAddress(customerId: $id, addressId: $addressId) {
                        status
                        messages
                    }
                }
            `,
            variables: { id, addressId },
        }),

    billingAddresses: (id: string) =>
        API.withConverter(addressesConvertor).query({
            query: gql`
                ${F_BILLING}
                query customerBillings($id: ID!) {
                    addressAll: billingAll(customerId: $id) {
                        ...F_BILLING
                    }
                }
            `,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    createBillingAddress: (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation createBilling($id: ID!, $input: BillingInput) {
                    createBilling(customerId: $id, input: $input) {
                        id
                    }
                }
            `,
            variables: { id, input },
        }),

    updateBillingAddress: (customerId: string, billingId: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation updateBilling(
                    $customerId: ID!
                    $billingId: ID!
                    $input: BillingInput
                ) {
                    updateBilling(
                        customerId: $customerId
                        billingId: $billingId
                        input: $input
                    ) {
                        id
                    }
                }
            `,
            variables: { customerId, billingId, input },
        }),

    deleteBillingAddress: (id: string, billingId: string) =>
        API.mutate({
            mutation: gql`
                mutation delete($id: ID!, $billingId: ID!) {
                    destroyBilling(customerId: $id, billingId: $billingId) {
                        status
                        messages
                    }
                }
            `,
            variables: { id, billingId },
        }),

    bookingList: (customerId: string, paging: any, filter: any = {}) =>
        API.query({
            query: gql`
                ${F_BOOKINGS}
                query bookingByCustomer(
                    $customerId: ID!
                    $q: String
                    $status: Status
                    $page: Int
                    $limit: Int
                ) {
                    data: bookingByCustomer(
                        customerId: $customerId
                        q: $q
                        status: $status
                        page: $page
                        limit: $limit
                    ) {
                        ...F_BOOKINGS
                    }
                }
            `,
            variables: {
                customerId,
                page: paging?.pageIndex,
                limit: paging?.pageSize,
                ...filter,
            },
            fetchPolicy: "no-cache",
        }),
    orderByCustomer: (
        customerId: string,
        q: string,
        { pageIndex = 1, pageSize = 10 }: any
    ) =>
        API.query({
            query: gql`
                ${F_ORDERS}
                query orderByCustomer(
                    $customerId: ID!
                    $q: String
                    $page: Int
                    $limit: Int
                ) {
                    data: ordersByUser(
                        customerId: $customerId
                        q: $q
                        page: $page
                        limit: $limit
                    ) {
                        ...F_ORDERS
                    }
                }
            `,
            variables: { customerId, page: pageIndex, limit: pageSize, q },
        }),

    voucherList: (customerId: string, paging: any, filter: any = {}) =>
        API.query({
            query: gql`
                ${F_VOUCHERS_CUSTOMER}
                query vouchersByUser(
                    $customerId: ID!
                    $q: String
                    $page: Int
                    $limit: Int
                    $serviceCategories: [String]
                    $effectiveDate: Date
                    $expiryDate: Date
                    $createdBy: [String]
                    $ownershipStatus: [String]
                    $status: [String]
                    $productIds: [ID]
                ) {
                    data: vouchersByUser(
                        customerId: $customerId
                        q: $q
                        page: $page
                        limit: $limit
                        serviceCategories: $serviceCategories
                        effectiveDate: $effectiveDate
                        expiryDate: $expiryDate
                        createdBy: $createdBy
                        ownershipStatus: $ownershipStatus
                        status: $status
                        productIds: $productIds
                    ) {
                        ...F_VOUCHERS_CUSTOMER
                    }
                }
            `,
            variables: {
                customerId,
                page: paging?.pageIndex,
                limit: paging?.pageSize,
                ...filter,
            },
            fetchPolicy: "no-cache",
        }),

    updatePassword: (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation updateCustomerPassword(
                    $id: ID!
                    $input: PasswordInput!
                ) {
                    updateCustomerPassword(id: $id, input: $input) {
                        status
                    }
                }
            `,
            variables: { id, input },
        }),

    verifyPhone: (input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_CUSTOMER}
                mutation verifyCustomerPhone(
                    $input: CustomerVerifyPhoneInput!
                ) {
                    customer: verifyCustomerPhone(input: $input) {
                        ...F_CUSTOMER
                    }
                }
            `,
            variables: { input },
        }),

    // verifyCustomerOAuth: (id: string, code: string) =>
    //     API.mutate({
    //         mutation: gql`
    //             mutation verifyCustomerOAuth($customerId: ID!, $code: String) {
    //                 data: verifyCustomerOAuth(
    //                     customerId: $customerId
    //                     code: $code
    //                 )
    //             }
    //         `,
    //         variables: { customerId: id, code },
    //     }),
};
