import { Select } from "d-react-components";
import React, { useContext } from "react";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const BookingCreateSource = () => {
    const { sourceBookings, bookingForm } = useContext(BookingCreateContext);
    return (
        <div className="card-container p-4 ">
            <Select
                label={Messages.sourceOfBooking}
                dataSource={sourceBookings}
                onChange={(value) =>
                    bookingForm.setFieldValue("sourceBookingId", value)
                }
                value={bookingForm.values.sourceBookingId}
                error={bookingForm.errors.sourceBookingId}
            />
        </div>
    );
};

export default BookingCreateSource;
