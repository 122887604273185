import Messages from "../languages/Messages";

export const DELIVERY_STATUS = {
    PENDING: "DELIVERY_PENDING",
    BOOKED: "DELIVERY_BOOKED",
    IN_DELIVERY: "IN_DELIVERY",
    COMPLETED: "DELIVERY_COMPLETED",
    // FAILED: "DELIVERY_FAILED",
    CANCEL: "DELIVERY_CANCELLED",
    RETURNED: "DELIVERY_RETURNED",
};

export const DELIVERY_STATUSES = [
    {
        id: DELIVERY_STATUS.PENDING,
        label: Messages.deliveryPending,
        color: "#F2C94C ",
    },
    {
        id: DELIVERY_STATUS.BOOKED,
        label: Messages.deliveryBooked,
        color: "#2196F3 ",
    },
    {
        id: DELIVERY_STATUS.IN_DELIVERY,
        label: Messages.inDelivery,
        color: "#F2994A ",
    },
    {
        id: DELIVERY_STATUS.COMPLETED,
        label: Messages.deliveryCompleted,
        color: "#27AE60",
    },
    {
        id: DELIVERY_STATUS.CANCEL,
        label: Messages.deliveryCancelled,
        color: "#F44336",
    },
    {
        id: DELIVERY_STATUS.RETURNED,
        label: Messages.deliveryReturned,
        color: "#F44336",
    },
    // {
    //     id: DELIVERY_STATUS.FAILED,
    //     label: Messages.deliveryFailed,
    //     color: "#F44336",
    // },
];
