import { Button, Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import InputTextForm from "../../../common/input/InputTextForm";
import { OrderDetailContext } from "../../../context/order";
import { OrderNoteSchema } from "../../../formschema/order";
import Messages from "../../../languages/Messages";

interface IOrderDetailNote {
    open: boolean;
    onClose: () => void;
}

function OrderEditNoteModal({ open, onClose }: IOrderDetailNote) {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const noteForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderNoteSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: OrderAPI.updateNote,
                    params: [orderDetail?.id, values?.note],
                },
                (orderDetailRes: any) => {
                    setOrderDetail(orderDetailRes);
                    Notifications.showSuccess(Messages.updateNoteSuccessful);
                    onClose();
                }
            );
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.orderNote}
            onSave={() => noteForm.handleSubmit()}
        >
            <div>{Messages.pleaseAdvisedThisOrder}</div>
            <InputTextForm
                keyData="note"
                form={noteForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
}

const OrderDetailNote = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const [openEdit, setOpenEdit] = useState(false);

    return (
        <div className="card-container p-4 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.orderNote}</label>
                <Button onClick={() => setOpenEdit(true)} variant="trans">
                    {Messages.edit}
                </Button>
            </div>
            <text className="mt-3">{orderDetail?.note}</text>
            {openEdit && (
                <OrderEditNoteModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

export default OrderDetailNote;
