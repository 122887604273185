import React from "react";

interface IProductDetailState {
    productForm: any;
    categoryProducts: any[];
    categoryServices: any[];
    brands: any[];
    productLang: string;
    setProductLang: (lang: string) => any;
}

const productDetailState: IProductDetailState = {
    productForm: null,
    categoryProducts: [],
    categoryServices: [],
    brands: [],
    productLang: "en",
    setProductLang: () => {},
};

export const ProductDetailContext = React.createContext(productDetailState);
