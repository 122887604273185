import React from "react";
import { IBooking } from "../interfaces/booking";
import { IWorksheet } from "../interfaces/worksheet";

interface IWorksheetCreateState {
    worksheetForm: any;
    step: number;
    setStep: (value: number) => any;
    booking: IBooking | undefined;
    setBooking: (value: IBooking) => void;
}

const worksheetCreateState: IWorksheetCreateState = {
    worksheetForm: null,
    step: 0,
    setStep: () => {},
    booking: {} as any,
    setBooking: (value: IBooking) => {},
};

export const WorksheetCreateContext = React.createContext(worksheetCreateState);

interface IWSCBookingState {
    booking: IBooking;
    onChangeBooking: (value: any) => void;
}

const wscBookingState: IWSCBookingState = {
    booking: {} as any,
    onChangeBooking: () => {},
};

export const WSCBookingContext = React.createContext(wscBookingState);

interface IWorksheetDetailState {
    worksheetDetail: IWorksheet;
    setWorksheetDetail: (value: any) => void;
}

const worksheetDetail: IWorksheetDetailState = {
    worksheetDetail: {} as any,
    setWorksheetDetail: () => {},
};

export const WorksheetDetailContext = React.createContext(worksheetDetail);
