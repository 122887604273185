import { map } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import BookingAPI from "../../../api/booking/BookingAPI";
import ActivityView from "../../../common/activity/ActivityView";

const BookingDetailActivity = () => {
    const { bookingId } = useParams<any>();

    const onSendComment = (commentText: string, attachments: any = []) => {
        const attachmentList = map(attachments, (item) => item.url);
        return BookingAPI.addComment(bookingId, commentText, attachmentList);
    };

    const source = (paging: any) => {
        return BookingAPI.activities(bookingId, paging);
    };

    const transformer = (res: any) => res?.data?.data?.activities ?? [];

    return (
        <ActivityView
            className="mt-3"
            onClickSendComment={onSendComment}
            source={source}
            transformer={transformer}
        />
    );
};

export default BookingDetailActivity;
