import { Notifications, Progress } from "d-react-components";
import React, { useContext, useMemo } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import { ORDER_STATUS } from "../../../constant/order";
import { OrderDetailContext } from "../../../context/order";
import { mapAddressToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import OrderBilling from "../common/OrderBilling";

const OrderDetailBillingAddress = () => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const { billing, status } = orderDetail;

    const isHideEdit = useMemo(() => {
        if (
            status === ORDER_STATUS.PENDING_PAYMENT ||
            status === ORDER_STATUS.PENDING_CONFIRMATION
        ) {
            return false;
        }

        return true;
    }, [status]);

    const onChangeBilling = (billingParams: any) => {
        const input = mapAddressToSer(billingParams);
        Progress.show(
            {
                method: OrderAPI.updateBilling,
                params: [orderDetail?.id, input],
            },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.updateBillingAddressSuccess);
                setOrderDetail(orderDetailRes);
            }
        );
    };

    return (
        <OrderBilling
            billing={billing}
            onChangeBilling={onChangeBilling}
            isHideEdit={isHideEdit}
        />
    );
};

export default OrderDetailBillingAddress;
