import {
    DialogManager,
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
    ViewCollapse,
    ViewRow,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import UserAPI from "../../../api/user/UserAPI";
import {
    PERMISSION_TYPE,
    PERMISSION_TYPES,
} from "../../../constant/user";
import { UserRoleCrudContext } from "../../../context/user";
import { UserRoleSchema } from "../../../formschema/user";
import {
    getDefaultRolePermissions,
    mapUserRoleToSever,
} from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserRoleCrudSetting from "./UserRoleCrudSetting";

const UserRoleCrud = () => {
    const history = useHistory();
    const { roleId } = useParams<any>();
    const isEdit = useMemo(() => !!roleId, [roleId]);

    const roleForm = useFormik<any>({
        initialValues: {
            permissionType: PERMISSION_TYPE.CUSTOM,
            permissions: getDefaultRolePermissions(),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UserRoleSchema,
        onSubmit: (values: any) => {
            const input = mapUserRoleToSever(values);
            if (isEdit) {
                onUpdate(input);
            } else {
                onCreate(input);
            }
        },
    });

    useEffect(() => {
        isEdit && loadRoleDetail();
    }, []);

    const loadRoleDetail = () =>
        Progress.show(
            { method: UserAPI.roleDetail, params: [roleId] },
            roleForm.setValues
        );

    const onCreate = (input: any) => {
        Progress.show(
            { method: UserAPI.roleCreate, params: [input] },
            (res: any) => {
                Notifications.showSuccess(Messages.createRoleSuccess);
                history.replace(
                    generatePath(Path.USER_ROLE_DETAIL, {
                        roleId: res?.data?.createRole.id,
                    })
                );
            }
        );
    };

    const onUpdate = (input: any) => {
        Progress.show(
            { method: UserAPI.roleUpdate, params: [roleId, input] },
            (roleDetail: any) => {
                Notifications.showSuccess(Messages.updateRoleSuccess);
                roleForm.setValues(roleDetail);
            }
        );
    };

    const onChangePermissionType = (value: any) => {
        roleForm.setFieldValue("permissionType", value);
    };

    const formValues = roleForm?.values;
    const formErrors = roleForm?.errors;

    return (
        <UserRoleCrudContext.Provider
            value={{
                roleForm,
            }}
        >
            <Header
                title={Messages.role}
                onSave={() => roleForm.handleSubmit()}
            />
            <div className="p-4">
                <ViewCollapse label={Messages.general}>
                    <ViewRow label={Messages.name}>
                        <InputText
                            placeholder={Messages.name}
                            onChange={(event) =>
                                roleForm.setFieldValue(
                                    "name",
                                    event?.target?.value
                                )
                            }
                            value={formValues?.name}
                            error={formErrors?.name as any}
                        />
                    </ViewRow>
                </ViewCollapse>
                <ViewCollapse
                    label={Messages.updateRoleResource}
                    className="mt-4"
                >
                    <ViewRow label={Messages.access}>
                        <Select
                            className="mt-3"
                            value={formValues?.permissionType}
                            dataSource={PERMISSION_TYPES}
                            onChange={onChangePermissionType}
                        />
                    </ViewRow>
                    {formValues.permissionType ===
                        PERMISSION_TYPE.CUSTOM && (
                        <ViewRow label=" ">
                            <UserRoleCrudSetting />
                        </ViewRow>
                    )}
                </ViewCollapse>
            </div>
        </UserRoleCrudContext.Provider>
    );
};

export default UserRoleCrud;
