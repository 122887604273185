import { map } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import OrderAPI from "../../../api/order/OrderAPI";
import ActivityView from "../../../common/activity/ActivityView";

const OrderDetailActivity = () => {
    const { orderId } = useParams<any>();

    const source = (paging: any) => {
        return OrderAPI.activityList(orderId, paging);
    };

    const transformer = (res: any) => res?.data?.data?.activities ?? [];

    const onSendComment = (content: string, attachments: any[]) => {
         const attachmentList = map(attachments, (item) => item.url);
        return OrderAPI.activityAdd(orderId, content, attachmentList);
    };

    return (
        <ActivityView
            className="mt-3"
            onClickSendComment={onSendComment}
            source={source}
            transformer={transformer}
        />
    );
};

export default OrderDetailActivity;
