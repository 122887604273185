import _ from "lodash";

const MediaKeysManager: any = {};

const MEDIA_EXPANDED_KEY = "deMediaExpandedKey";
const MEDIA_SELECTED_FOLDER = "deMediaSelectedFolderId";

MediaKeysManager.saveExpandedKeys = (keys: any) => {
    return localStorage.setItem(MEDIA_EXPANDED_KEY, JSON.stringify(keys));
};

MediaKeysManager.clearExpendedKeys = () => {
    localStorage.setItem(MEDIA_EXPANDED_KEY, "");
};

MediaKeysManager.getExpandedKeys = () => {
    return JSON.parse(localStorage.getItem(MEDIA_EXPANDED_KEY) as any) ?? [];
};

MediaKeysManager.saveSelectedFolder = (keys: any) => {
    return localStorage.setItem(MEDIA_SELECTED_FOLDER, JSON.stringify(keys));
};

MediaKeysManager.clearSelectedFolder = () => {
    localStorage.setItem(MEDIA_SELECTED_FOLDER, "");
};

MediaKeysManager.getSelectedFolder = () => {
    return JSON.parse(localStorage.getItem(MEDIA_SELECTED_FOLDER) as any);
};

export default MediaKeysManager;
