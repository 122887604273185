import * as Yup from "yup";
import { VOUCHER_CREATE_TYPE } from "../constant/voucher";

export const VoucherCreateSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        products: Yup.array()
            .required("Items required!")
            .min(1, "Items required!"),

        effectiveDate: Yup.string().required("Effective date required!"),
    });
    if (value.createType === VOUCHER_CREATE_TYPE.MANUAL) {
        return schema.concat(
            Yup.object().shape({
                code: Yup.string().required("Voucher code required!"),
            }) as any
        );
    }

    if (value.createType === VOUCHER_CREATE_TYPE.GENERATE_BY_BULK) {
        return schema.concat(
            Yup.object().shape({
                quantity: Yup.number()
                    .required("Required field!")
                    .max(5000, "Quantity can not greater than 5000")
                    .min(1, "Min quantity must be 1"),
            }) as any
        );
    }
    return schema;
});

export const VoucherExportSchema = Yup.object().shape({
    // serviceCategories: Yup.array()
    //     .required("At least one service required!")
    //     .min(1, "Items required!"),
});

export const VoucherChangeExpiryDateSchema = Yup.object().shape({
    attachments: Yup.array()
        .required("At least one service required!")
        .min(1, "Items required!"),
    date: Yup.string().required("Expiry date required!"),
    remark: Yup.string().required("Remark required!"),
});

export const VoucherTransferOwnershipSchema = Yup.object().shape({
    attachments: Yup.array()
        .required("At least one service required!")
        .min(1, "Items required!"),
    ownership: Yup.object().required("Expiry date required!"),
    remark: Yup.string().required("Remark required!"),
});
