import { find, map, reduce, uniqBy } from "lodash";
import { useMemo } from "react";
import { IProductOrderCreate } from "../interfaces/product";
import { IVoucherOrderCreate } from "../interfaces/voucher";
import { PRODUCT_TYPE } from "../constant/product";

export type AppliedVoucherProductsResultType = {
    [proId: string]: { quantity?: number; usedQty?: number };
};

interface IUsedAppliedVoucherProps {
    products: IProductOrderCreate[];
    vouchers?: IVoucherOrderCreate[];
}

export const useAppliedVouchersProductList = (
    props: IUsedAppliedVoucherProps,
    deps?: Array<any>
): AppliedVoucherProductsResultType => {
    const appliedList = useMemo<AppliedVoucherProductsResultType>(() => {
        const { products, vouchers } = props;
        const productIds: string[] = map(products, (pro) => pro?.id);
        const uniqueList = uniqBy(productIds, (i) => i);
        const applyList: any = {};

        uniqueList.forEach((proId) => {
            const findAll = products?.filter((item) => item?.id === proId);
            const qtyTotal = reduce(
                findAll,
                (res = 0, i) => {
                    const qty = i?.quantity ?? 0;
                    return res + qty;
                },
                0
            );
            // const usedQty =
            //     vouchers?.filter((i) => i?.appliedFor === proId)?.length ?? 0;

            /**
             * with premium voucher => used quantity of service is calculated by 1 / conversion rate
             */
            const usedQty = reduce(
                vouchers,
                (sum, voucher) => {
                    if (voucher?.appliedFor !== proId) {
                        return sum;
                    }

                    const isPremiumVoucher =
                        voucher?.product?.productType ===
                        PRODUCT_TYPE.PREMIUM_SERVICE;
                    if (isPremiumVoucher) {
                        // const conversionRate =
                        //     find(
                        //         voucher?.product?.premiumService,
                        //         (service) => service.id === proId
                        //     )?.voucherConversion ?? 1;

                        // return sum + 1 / conversionRate;
                        return sum + 1;
                    }
                    return sum + 1;
                },
                0
            );
            Object.assign(applyList, {
                [proId]: { quantity: qtyTotal, usedQty },
            });
        });

        return applyList;
    }, deps);
    return appliedList || {};
};
