import { Button, ViewCollapse, ViewTextError } from "d-react-components";
import { useContext } from "react";
import Image from "../../../common/Image";
import { NewsCrudContext } from "../../../context/news";
import Messages from "../../../languages/Messages";
import SelectMediaManagerButton from "../../media/selectmedia/SelectMediaManagerButton";

const NewsCrudFeaturedBanner = () => {
    const { newsForm } = useContext(NewsCrudContext);

    return (
        <ViewCollapse className="mt-3" label={Messages.featuredBanner} required>
            {newsForm.values?.featuredUrl && (
                <Image
                    src={newsForm.values?.featuredUrl}
                    className="image-reg-x-large"
                />
            )}
            <SelectMediaManagerButton
                isSingleSelect
                onSelect={([media]: any) =>
                    newsForm.setFieldValue("featuredUrl", media.url)
                }
                renderButton={() => (
                    <Button className="mt-3">{Messages.chooseImage}</Button>
                )}
            />
            <small>Recommended size W1920 x H600</small>
            <ViewTextError error={newsForm.errors?.featuredUrl} />
        </ViewCollapse>
    );
};

export default NewsCrudFeaturedBanner;
