import { Progress as ProgressAntd } from "antd";
import { Drawer, Icon, Notifications, Progress } from "d-react-components";
import { useContext, useEffect, useState } from "react";
import UserAPI from "../../../api/user/UserAPI";
import { AppStateContext } from "../../../context/app";
import { IUserAuthOtp } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";

const UserAuthOtp = () => {
    const { openUserAuthOtp, setOpenUserAuthOtp } = useContext(AppStateContext);
    const [otpCode, setOtpCode] = useState<IUserAuthOtp | undefined>();
    const [currentTime, setCurrentTime] = useState<number>(0);

    const remainingExpiryTime =
        new Date(otpCode?.expiryAt ?? "").getTime() - currentTime;

    useEffect(() => {
        if (!openUserAuthOtp) return;
        onLoadOtpCode();
    }, [openUserAuthOtp]);

    useEffect(() => {
        if (!remainingExpiryTime || remainingExpiryTime > 0) return;
        onLoadOtpCode();
    }, [remainingExpiryTime]);

    useEffect(() => {
        if (!otpCode?.expiryAt) return () => {};

        const interval = setInterval(() => {
            const now = new Date().getTime();
            setCurrentTime(now);
        }, 1000);

        if (!openUserAuthOtp) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [otpCode?.expiryAt]);

    const onLoadOtpCode = () => {
        Progress.show(
            { method: UserAPI.refreshOtpCode, params: [] },
            (res: any) => {
                const otpCodeRes = res?.data?.data;
                setOtpCode(otpCodeRes);
            }
        );
    };

    return (
        <Drawer
            title={Messages.authOTP}
            open={openUserAuthOtp}
            onClose={() => setOpenUserAuthOtp(false)}
            // hideFooter={true}
            size="auto"
            width={400}
        >
            <div className="text-center p-4">
                <img
                    alt=""
                    src="/images/icons/password.svg"
                    className="w-8 h-8 mb-4 inline-block"
                />
                <h5 className="mb-4">{Messages.yourDiscountAuthOTPToken}</h5>
                <div className="relative p-4 border border-primary d-flex flex-row ">
                    <div
                        className="w-100 h-100 text-center text-bold"
                        style={{ fontSize: "18px" }}
                    >
                        {otpCode?.code}
                    </div>
                    <Icon
                        name="content_copy"
                        className=" cursor-pointer mr-1"
                        onClick={() => {
                            navigator.clipboard.writeText(otpCode?.code ?? "");
                            Notifications.showSuccess(Messages.copySuccess);
                        }}
                    />
                    <Icon
                        name="refresh"
                        className=" cursor-pointer right-2 top-[15px] z-10"
                        onClick={() => {
                            onLoadOtpCode();
                        }}
                    />
                    <ProgressAntd
                        percent={remainingExpiryTime / 600}
                        showInfo={false}
                        className="auth-otp-progress"
                    />
                </div>
                <div className="bg-error-20 text-error p-3 small mt-4">
                    {Messages.warningTokenOTP}
                </div>
            </div>
        </Drawer>
    );
};

export default UserAuthOtp;
