import { Button, InputText, Modal, Progress } from "d-react-components";
import React, { useContext, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const ServiceNoteModal = ({ open, onClose, defaultValue }: any) => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);

    const [value, setValue] = useState<string>(defaultValue);

    const onClickSave = () =>
        Progress.show(
            {
                method: BookingAPI.updateNote,
                params: [bookingDetail?.id, value],
            },
            (bookingResult) => {
                setBookingDetail(bookingResult);
                onClose();
            }
        );

    return (
        <Modal
            size="small"
            title={Messages.update}
            open={open}
            onClose={onClose}
            onSave={onClickSave}
        >
            <InputText
                multiple
                label={Messages.bookingNotesInternalUseOnly}
                rows={7}
                placeholder={Messages.enterNote}
                value={value}
                name="notes"
                onChange={(event) => setValue(event?.target.value)}
            />
        </Modal>
    );
};

const BookingDetailNote = () => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const { notes = "" } = bookingDetail;

    return (
        <div className="card-container p-4 mt-3 flex-column">
            <div className="d-flex align-items-center justify-content-between">
                <label>{Messages.bookingNotesInternalUseOnly}</label>
                <Button variant="trans" onClick={() => setOpenEdit(true)}>
                    {Messages.edit}
                </Button>
            </div>
            <text className="mt-3">{notes}</text>
            <ServiceNoteModal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                defaultValue={bookingDetail?.notes}
            />
        </div>
    );
};

export default BookingDetailNote;
