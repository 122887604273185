import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";
import { F_USER } from "../user/UserFragment";

export const F_PRODUCT_ITEM = gql`
    fragment F_PRODUCT_ITEM on Product {
        id
        th {
            name
        }
        en {
            name
        }
        productId
        regularPrice
        salePrice
        SKU
        visibility
        customerVisibility
        updatedAt
        service {
            name
        }
        category {
            name
        }
        thumbnail {
            thumbnail
        }
        productType
        allAvailableCW
        allOnHoldCW
        allOnHoldDW
        allAvailableDW
        sellingOutOfStock
    }
`;

export const F_PRODUCTS = gql`
    ${F_PRODUCT_ITEM}
    ${F_PAGINATION}
    fragment F_PRODUCTS on Products {
        product {
            ...F_PRODUCT_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_PREMIUM_SERVICE = gql`
    fragment F_PREMIUM_SERVICE on Product {
        id
        th {
            name
        }
        en {
            name
        }
        productId
        regularPrice
        salePrice
        SKU
        thumbnail {
            thumbnail
        }
    }
`;
export const F_PRODUCT_GROUP = gql`
    fragment F_PRODUCT_GROUP on Product {
        id
        th {
            name
        }
        en {
            name
        }
        productId
        regularPrice
        salePrice
        SKU
        thumbnail {
            thumbnail
        }
    }
`;

export const F_PRODUCT = gql`
    ${F_USER}
    ${F_PREMIUM_SERVICE}
    ${F_PRODUCT_GROUP}
    fragment F_PRODUCT on Product {
        id
        th {
            name
            description
            shortDescription
        }
        en {
            name
            description
            shortDescription
        }
        productId
        gallery {
            video
            thumbnail
            type
        }
        thumbnail {
            thumbnail
        }
        regularPrice
        salePrice
        SKU
        stock
        activity {
            createBy {
                ...F_USER
            }
            message
            createdAt
        }
        visibility
        customerVisibility
        category {
            id
            name
        }
        brand
        weight
        tags
        sellingOutOfStock
        service {
            id
            seatConfiguration
        }
        productType
        premiumService {
            ...F_PREMIUM_SERVICE
        }
        groupProducts: productGroup {
            ...F_PRODUCT_GROUP
        }

        allowCombineVoucher
        numberOfSeat
    }
`;

export const F_PRODUCT_CAT_ITEM = gql`
    fragment F_PRODUCT_CAT_ITEM on CategoryProduct {
        id
        featured
        name
        order
        quantity
        createBy {
            id
            firstName
            lastName
        }
        createdAt
        updatedAt
        status
    }
`;

export const F_PRODUCT_CATS = gql`
    ${F_PRODUCT_CAT_ITEM}
    ${F_PAGINATION}
    fragment F_PRODUCT_CATS on CategoryProducts {
        categoryProduct {
            ...F_PRODUCT_CAT_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;
