import { gql } from "@apollo/client";
import { F_ADDRESS, F_BILLING } from "../address/AddressFragment";
import { F_CUSTOMER } from "../customer/CustomerFragment";
import { F_PAGINATION } from "../Fragment";
import { F_PAYMENT_METHOD } from "../paymentmethod/PaymentMethodFragment";
import { F_PRODUCT, F_PRODUCT_CAT_ITEM } from "../product/ProductFragment";
import { F_USER } from "../user/UserFragment";
import { F_WAREHOUSE } from "../warehouse/WarehouseFragment";
import { F_VOUCHER } from "../voucher/VoucherFragment";

export const F_ORDER_ITEM = gql`
    ${F_USER}
    ${F_CUSTOMER}
    ${F_BILLING}
    fragment F_ORDER_ITEM on Order {
        id
        orderNo
        subTotal
        total
        customer {
            ...F_CUSTOMER
        }
        status
        createdAt
        expiredAt
        createBy {
            ...F_USER
        }
        billing {
            firstName
            lastName
            sameServiceAddress
        }
        shippings {
            firstName
            lastName
        }
    }
`;

export const F_ORDERS = gql`
    ${F_ORDER_ITEM}
    ${F_PAGINATION}
    fragment F_ORDERS on Orders {
        order {
            ...F_ORDER_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_ORDER_PAYMENT = gql`
    ${F_PAYMENT_METHOD}
    fragment F_ORDER_PAYMENT on SplitPayment {
        id
        paymentMethod {
            ...F_PAYMENT_METHOD
        }
        amount
        status
        proofPayment {
            id
            paymentMethod {
                ...F_PAYMENT_METHOD
            }
            nameOfPayer
            bankTransferred {
                id
                image
                th {
                    bankName
                    accountName
                }
                en {
                    bankName
                    accountName
                }
                accountNo
            }
            dateOfPayment
            slip
            remark
            status
            transactionId
        }
        confirmation: confirmationPayment
        rejection: rejectPayment {
            proofOfCancellation
            remark
        }
        paymentRef
        paymentGatewayLogs
    }
`;

export const F_ORDER_TO_EXPORT_ITEM = gql`
    ${F_USER}
    ${F_CUSTOMER}
    ${F_ORDER_PAYMENT}

    fragment F_ORDER_TO_EXPORT_ITEM on Order {
        id
        orderNo
        subTotal
        total
        customer {
            ...F_CUSTOMER
        }
        status
        createdAt

        createBy {
            ...F_USER
        }

        discountManual
        # discountVoucher
        products {
            id
            name
            SKU
            productId
            regularPrice
            salePrice
            quantity
        }
        splitPayment {
            ...F_ORDER_PAYMENT
        }
    }
`;

export const F_ORDERS_TO_EXPORT = gql`
    ${F_ORDER_TO_EXPORT_ITEM}
    ${F_PAGINATION}
    fragment F_ORDERS_TO_EXPORT on Orders {
        order {
            ...F_ORDER_TO_EXPORT_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_ORDER_WORKSHEET = gql`
    ${F_PRODUCT}
    ${F_CUSTOMER}
    fragment F_ORDER_WORKSHEET on Worksheet {
        id
        worksheetNo
        quantity
        services {
            product {
                ...F_PRODUCT
            }
            openingCost
            quantity
            availableOrderQty
        }
        booking {
            id
            bookingNo
            customer {
                ...F_CUSTOMER
            }
        }
    }
`;

export const F_ORDER_DISCOUNT = gql`
    fragment F_ORDER_DISCOUNT on Discount {
        code
        discount
    }
`;

// export const F_REFUND_PROOF = gql`
//     ${F_USER}
//     fragment F_REFUND_PROOF on OrderRefundProof {
//         remark
//         attachments
//         createBy {
//             ...F_USER
//         }
//         createdAt
//     }
// `;

export const F_ORDER_REFUND = gql`
    ${F_PAYMENT_METHOD}
    ${F_USER}
    fragment F_ORDER_REFUND on OrderRefund {
        id
        products {
            id
            quantity
        }
        typeOfRefund
        paymentMethod {
            ...F_PAYMENT_METHOD
        }
        amount
        remark
        attachments
        status
        banks {
            bankName
            accountName
            accountNo
        }
        # confirmRefund {
        #     ...F_REFUND_PROOF
        # }
        # rejectRefund {
        #     ...F_REFUND_PROOF
        # }
        # completeRefund {
        #     ...F_REFUND_PROOF
        # }
        createBy {
            ...F_USER
        }
        createdAt
    }
`;

export const F_ORDER_VOUCHER = gql`
    fragment F_ORDER_VOUCHER on Voucher {
        id
        voucherNo
        code
        type
        product {
            id
            th {
                name
                description
                shortDescription
            }
            en {
                name
                description
                shortDescription
            }
            productId
            salePrice
        }
        priceFromOrder
    }
`;

export const F_ORDER = gql`
    ${F_PRODUCT_CAT_ITEM}
    ${F_ORDER_DISCOUNT}

    ${F_USER}
    ${F_WAREHOUSE}
    ${F_ADDRESS}
    ${F_BILLING}
    ${F_CUSTOMER}
    ${F_PAYMENT_METHOD}

    ${F_ORDER_PAYMENT}
    ${F_ORDER_VOUCHER}
    fragment F_ORDER on Order {
        id
        orderNo
        products {
            id
            productId
            name
            SKU
            categories {
                ...F_PRODUCT_CAT_ITEM
            }
            thumbnail
            regularPrice
            salePrice
            salePriceAtCreated
            quantity
            onHold
            productType
            worksheet {
                id
                worksheetNo
            }
            isCustom
            shippedQty
        }
        subTotal

        manualCode {
            ...F_ORDER_DISCOUNT
        }
        # vouchers {
        #     ...F_ORDER_DISCOUNT
        # }

        # discountVoucher
        discountManual

        deliveries {
            id
            warehouse {
                ...F_WAREHOUSE
            }
            deliveryNo
            dateOfArrival
            deliveryType {
                id
                label
            }
            shipping {
                ...F_ADDRESS
            }
            products {
                id
                productId
                name
                thumbnail
                SKU
                quantity
                shippingQty
                productType
            }
            status
            remark
            createdAt
            createBy {
                ...F_USER
            }
            ref
            arrived
        }
        tax
        total
        note
        customer {
            ...F_CUSTOMER
        }
        orderContact {
            firstName
            lastName
            email
            phone
        }
        shippings {
            ...F_ADDRESS
        }
        billing {
            ...F_BILLING
        }
        status
        isInvoice: invoice
        splitPayment {
            ...F_ORDER_PAYMENT
        }
        createdAt
        expiredAt
        createBy {
            ...F_USER
        }

        fees: transportationFee {
            type
            value
        }
        worksheets {
            worksheetNo
            createdAt
            id
        }
        warehouse {
            ...F_WAREHOUSE
        }
        paymentStatus
        # refunds {
        #     ...F_ORDER_REFUND
        # }

        creditNote {
            year
            month
            day
            paymentScheduleOn
        }

        coSales {
            ...F_USER
        }
        productVouchers {
            voucher {
                ...F_ORDER_VOUCHER
            }
        }
    }
`;
