import { gql } from "@apollo/client";
import API from "../API";
import { F_CRITERIA, F_CRITERIA_LIST } from "./CriteriaFragment";

const CriteriaAPI = {
    list: (q: string, { pageIndex = 1, pageSize = 10 }: any) =>
        API.query({
            query: gql`
                ${F_CRITERIA_LIST}
                query criteriaList($q: String, $page: Int, $limit: Int) {
                    data: criterias(q: $q, page: $page, limit: $limit) {
                        ...F_CRITERIA_LIST
                    }
                }
            `,
            variables: { q, page: pageIndex, limit: pageSize },
            fetchPolicy: "no-cache",
        }),

    create: (body: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createCriterias?.id,
        }).mutate({
            mutation: gql`
                mutation createCriteria($body: CriteriaInput) {
                    createCriterias(input: $body) {
                        id
                    }
                }
            `,
            variables: { body },
        }),

    detail: (id: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.criteria,
        }).query({
            query: gql`
                ${F_CRITERIA}
                query detail($id: ID!) {
                    criteria(id: $id) {
                        ...F_CRITERIA
                    }
                }
            `,
            variables: { id },
        }),

    update: (id: string, input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.updateCriterias,
        }).mutate({
            mutation: gql`
                ${F_CRITERIA}
                mutation update($id: ID!, $input: CriteriaInput) {
                    updateCriterias(id: $id, input: $input) {
                        ...F_CRITERIA
                    }
                }
            `,
            variables: { id, input },
        }),
};
export default CriteriaAPI;
