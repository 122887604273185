import {
    AwesomeTableComponent,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import WarehouseAPI from "../../../api/warehouse/WarehouseAPI";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { WAREHOUSE_STATUSES } from "../../../constant/warehouse";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const WarehouseTable = () => {
    const history = useHistory();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "warehouseNo",
            render: (warehouseNo: string, warehouse: any) => (
                <AppLink
                    to={generatePath(Path.WAREHOUSE_DETAIL, {
                        warehouseId: warehouse.id,
                    })}
                >
                    {warehouseNo}
                </AppLink>
            ),
        },

        {
            title: Messages.warehouse,
            dataIndex: Messages.getLanguage(),
            render: (info: any) => info?.name,
        },
        {
            title: Messages.code,
            dataIndex: "code",
        },

        {
            title: Messages.district,
            dataIndex: "district",
            render: (district: any) => district?.name,
        },

        {
            title: Messages.phone,
            dataIndex: "phone",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={WAREHOUSE_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.updateAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "",
            render: (warehouse) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, warehouse)
                    }
                />
            ),
            align: "center",
        },
    ];

    const source = (pagingData: any) => {
        return WarehouseAPI.list(searchRef.current, {}, pagingData);
    };

    const onClickTableAction = (actionId: string, warehouse: any) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.WAREHOUSE_DETAIL, {
                        warehouseId: warehouse.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                label={Messages.warehouse}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => res?.data?.data?.warehouse ?? []}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
            />
        </div>
    );
};

export default WarehouseTable;
