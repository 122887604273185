import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";

export const F_WAREHOUSE_ITEM = gql`
    fragment F_WAREHOUSE_ITEM on Warehouse {
        id
        warehouseNo
        code
        phone
        th {
            name

            address
        }
        en {
            name
            address
        }
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        createdAt
        updatedAt
        status
    }
`;

export const F_WAREHOUSES = gql`
    ${F_WAREHOUSE_ITEM}
    ${F_PAGINATION}
    fragment F_WAREHOUSES on Warehouses {
        warehouse {
            ...F_WAREHOUSE_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_WAREHOUSE = gql`
    fragment F_WAREHOUSE on Warehouse {
        id
        warehouseNo
        code
        phone
        th {
            name

            address
        }
        en {
            name
            address
        }
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        createdAt
        updatedAt
        status
    }
`;
