/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { filter, forEach, map } from "lodash";
import moment from "moment";
import { DISCOUNT_AMOUNT_TYPE } from "../constant/discount";
import { PRODUCT_TYPE } from "../constant/product";
import { IOrderPrice } from "../hoook/order";
import { IBillingAddress, IServiceAddress, mapAddressToSer } from "./address";
import { ICategory } from "./category";
import { ICustomer } from "./customer";
import { IDelivery } from "./delivery";
import { IPayment } from "./payment";
import { IPaymentMethod } from "./paymentmethod";
import { IUser } from "./user";
import { IVoucher, IVoucherOrderCreate } from "./voucher";
import { IWarehouse } from "./warehouse";
import { IWorksheet } from "./worksheet";
import { IProduct } from "./product";

export interface IOrderDiscount {
    discount: number;
    code: string;
}

export interface IOrderFee {
    value: number;
    type: string;
}

export interface IOrderDiscountDisplay {
    total: number;
    subTitle: string;
    title: string;
    id: string;
    [key: string]: any;
}

export interface IOrderVoucherDisplay {
    total: number;
    subTitle?: string;
    title: string;
    id: string;
    numberOfVouchers?: number;
    appliedVouchers?: IVoucherOrderCreate[];
    appliedFor?: string;
    amount: number;
    quantity: number;
    product: IProduct;
}

export interface IOrderCreateState {
    discountList: IOrderDiscountDisplay[];
    subtotal: number;
    total: number;
    tax: number;
}

export interface IOrderCreatePriceState extends IOrderPrice {
    tax: number;
}

export interface IOrderProduct {
    id: string;
    productId: string;
    name: string;
    SKU: string;
    categories: ICategory[];
    thumbnail: string;
    regularPrice: number;
    salePrice: number;
    quantity: number;
    onHold: boolean;
    isCustom: boolean;
    productType: string;
    worksheet: IWorksheet;
    shippedQty: number;
}

export interface IOrderContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface IOrderWorksheet {
    id: string;
    worksheetNo: string;
    createdAt: string;
}

export interface IOrderRefundProduct {
    id: string;
    quantity: number;
}

export interface IOrderRefundBank {
    bankName: string;
    accountName: string;
    accountNo: string;
}

export interface IOrderRefundProof {
    remark: string;
    attachments: [string];
    createBy: IUser;
    createdAt: string;
}

export interface IOrderRefund {
    id: string;
    products: [IOrderRefundProduct];
    typeOfRefund: string;
    paymentMethod: IPaymentMethod;
    amount: number;
    remark: string;
    attachments: [string];
    status: string;
    banks: IOrderRefundBank[];
    confirmRefund: IOrderRefundProof;
    rejectRefund: IOrderRefundProof;
    completeRefund: IOrderRefundProof;
    createBy: IUser;
    createdAt: string;
}

export interface IOrderCreditNote {
    year: number;
    month: number;
    day: number;
    paymentScheduleOn: number;
}

export interface IOrderProductVoucher {
    voucher: IVoucher;
}

export interface IOrder {
    id: string;
    orderNo: string;
    products: IOrderProduct[];
    deliveryProducts: IOrderProduct[];

    subTotal: number;
    // discountVoucher: number;
    discountManual: number;

    deliveries: IDelivery[];
    tax: number;
    total: number;
    note: string;
    customer: ICustomer;
    orderContact: IOrderContact;
    shippings: IServiceAddress[];
    billing: IBillingAddress;
    status: string;
    splitPayment: IPayment[];
    createdAt: string;
    expiredAt: string;
    createBy: IUser;
    // vouchers: IVoucher[];
    manualCode: IOrderDiscount;
    fees: IOrderFee;
    isInvoice: boolean;
    worksheets: IOrderWorksheet[];
    warehouse: IWarehouse;
    paymentStatus: string;
    refunds: IOrderRefund[];

    creditNote: IOrderCreditNote;
    coSales: IUser[];
    productVouchers: IOrderProductVoucher[];
}

export const mapOrderProductToServer = (product: any) => {
    const { id, salePrice, quantity, worksheetId, salePriceAtCreated } =
        product;
    return {
        productId: id,
        salePrice,
        salePriceAtCreated,
        quantity,
        worksheetId,
    };
};

export const mapOrderCustomProductToServer = (product: any) => {
    const {
        name,
        salePrice,
        quantity,
        category,
        SKU,
        remark,
        salePriceAtCreated,
    } = product;
    return {
        name,
        salePrice: parseInt(salePrice, 10),
        salePriceAtCreated: parseInt(salePriceAtCreated, 10),
        quantity: parseInt(quantity, 10),
        category: category?.id,
        SKU,
        remark,
    };
};

const mapManualDiscountToSer = (discount: any) => {
    if (discount.amountType === DISCOUNT_AMOUNT_TYPE.PERCENTAGE) {
        return `${discount.amount}%`;
    }
    return discount.amount;
};

// const getVoucherCodeForCreateOrder = (
//     vouchers: Array<IVoucherOrderCreate>
// ): {
//     applyCodes: string[] | null;
//     voucherWithCode: Array<IVoucherOrderCreate> | null;
// } => {
//     if (isEmpty(vouchers)) {
//         return { applyCodes: null, voucherWithCode: null };
//     }
//     const cloneVoucher = [...vouchers];
//     const inputCodes = compact(map(vouchers, (i) => i?.code));
//     const noneCodeList = filter(vouchers, (i) => !i?.code);
//     const groupedVouchers = groupBy(noneCodeList, (i) => i?.product?.id);
//     const applyCodes: string[] = [...inputCodes];
//     forEach(groupedVouchers, (items, key) => {
//         if (items?.length > 0) {
//             const voucherList = items?.[0]?.voucherCodes ?? [];
//             const allowCodes = filter(
//                 voucherList,
//                 (i) => !includes(inputCodes, i)
//             );
//             forEach(items, (item, index) => {
//                 const applyCode = allowCodes?.[index];
//                 if (applyCode) {
//                     const itemIndex = findIndex(
//                         vouchers,
//                         (i) => i?.id === item?.id
//                     );
//                     cloneVoucher[itemIndex].code = applyCode;
//                     applyCodes.push(applyCode);
//                 }
//             });
//         }
//     });
//     return { applyCodes, voucherWithCode: cloneVoucher };
// };

const mapProductVouchersToService = (
    productVouchers: any[],
    anonymousVouchers: IVoucher[]
) => {
    const vouchers: any[] = [];
    forEach(productVouchers, (productVoucher) => {
        Array.from(Array(productVoucher.quantity).keys()).forEach((n, i) => {
            vouchers.push({
                code: productVoucher?.voucherCodes[i],
            });
        });
    });

    forEach(anonymousVouchers, (voucher) => {
        vouchers.push({
            code: voucher?.code,
        });
    });

    return vouchers;
};

export const mapOrderToServer = (order: any) => {
    const {
        products,
        productsWorksheet,
        customProducts,
        customer,
        contact,
        addresses,
        billing,
        note,
        fees,
        isInvoice,
        worksheetIds,
        manualDiscounts = [],
        // vouchers = [],
        warehouseId,
        isCreditNote,
        coSales,
        productVouchers,
        anonymousVouchers,
    } = order;

    // const { voucherWithCode = [], applyCodes } =
    //     getVoucherCodeForCreateOrder(vouchers);

    // const premiumVouchers = filter(voucherWithCode, (item) =>
    //     isPremiumService(item)
    // );

    return {
        products: map(
            [...productsWorksheet, ...products],
            mapOrderProductToServer
        ),
        customProducts: map(customProducts, mapOrderCustomProductToServer),
        customerId: customer?.id,
        orderContact: contact,
        shippings: map(addresses, mapAddressToSer),
        billing: mapAddressToSer(billing),
        // vouchers: applyCodes,
        // serviceApply: map(premiumVouchers, (item) => ({
        //     voucherCode: item.code,
        //     productServiceId: item?.appliedFor,
        // })),
        fee: fees,
        invoice: isInvoice,
        worksheetIds,
        note,
        manualCode: map(manualDiscounts, mapManualDiscountToSer),
        warehouseId,
        isCreditNote,
        coSaleIds: map(coSales, (item) => item.id),
        productVouchers: mapProductVouchersToService(
            productVouchers,
            anonymousVouchers
        ),
    };
};

export const mapOrderFromServer = (order: any) => {
    const { products } = order;
    return {
        ...order,
        products: map(products, (item) => ({
            ...item,
            isCustomProduct: item.isCustom,
        })),
        deliveryProducts: filter(
            products,
            (item) =>
                item.productType === PRODUCT_TYPE.DEFAULT_PRODUCT ||
                item.productType === PRODUCT_TYPE.GROUP_PRODUCT
        ),
    };
};

export const mapOrderFilterBodyToServer = (filterBody: any) => {
    if (!filterBody) return {};
    const {
        status,
        source,
        fromDate,
        toDate,
        paymentMethod,
        createdBy,
        paymentStatus,
        warehouse,
        isCreditNote,
    } = filterBody;
    return {
        status,
        source,
        from: fromDate ? moment(fromDate).toISOString() : null,
        to: toDate ? moment(toDate).toISOString() : null,
        paymentMethod:
            paymentMethod && paymentMethod?.length > 0
                ? map(paymentMethod, (item) => item.id)
                : null,
        createBy:
            createdBy && createdBy?.length > 0
                ? map(createdBy, (item) => item.id)
                : null,
        paymentStatus,
        warehouse,
        isCreditNote,
    };
};

export const mapOrderDeliveryToServer = (body: any) => {
    if (!body) return {};
    const {
        products,
        dateOfArrival,
        orderId,
        warehouseId,
        deliveryType,
        deliveryNo,
        shippingId,
        remark,
    } = body;
    const shipProducts = filter(products, (item) => item.shippingQty !== 0);
    return {
        orderId,
        warehouseId,
        deliveryType,
        deliveryNo,
        dateOfArrival: dateOfArrival
            ? moment(dateOfArrival).toISOString()
            : null,
        products: map(shipProducts, (pro) => ({
            id: pro.id,
            productId: pro.productId,
            quantity: pro.quantity,
            shippingQty: pro.shippingQty,
            productType: pro.productType,
        })),
        shippingId,
        remark,
    };
};

export const mapOrderPaymentProofToServer = (body: any) => {
    if (!body) return {};
    const { attachment, dateOfPayment, method, ...rest } = body;
    return {
        ...rest,
        paymentMethodId: method?.id,
        dateOfPayment: moment(dateOfPayment).toISOString(),
        slip: map(attachment, (item) => item.url),
    };
};

export const mapOrderPaymentConfirmToServer = (body: any) => {
    if (!body) return {};
    const { attachment, dateOfReceiving, method, ...rest } = body;
    return {
        ...rest,
        dateOfReceiving: moment(dateOfReceiving).toISOString(),
        proofOfPaymentReceived: map(attachment, (item) => item.url),
    };
};

export const mapOrderPaymentRejectToServer = (body: any) => {
    if (!body) return {};
    const { attachment, ...rest } = body;
    return {
        ...rest,
        proofOfCancellation: map(attachment, (item) => item.url),
    };
};

export const mapOrderRefundToServer = (body: any) => {
    if (!body) return {};
    const {
        attachments,
        typeOfRefund,
        products,
        remark,
        amount,
        paymentMethodId,
    } = body;
    return {
        typeOfRefund,
        products: map(products, (item) => ({
            id: item.id,
            quantity: item.quantity,
        })),
        remark,
        amount,
        attachments: map(attachments, (item) => item.url),
        paymentMethodId,
    };
};
