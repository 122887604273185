import { TimeUtils } from "d-react-components";
import * as Yup from "yup";
import { DELIVERY_STATUS } from "../constant/delivery";
import { BANK_TRANSFER, C2P } from "../constant/payment";
import { REFUND_FORMAT } from "../constant/refundRequest";
import Messages from "../languages/Messages";

export const OrderContactSchema = Yup.object().shape({
    email: Yup.string().required("Required field!"),
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    phone: Yup.string().required("Required field!"),
});

export const OrderCreateSchema = Yup.object().shape({
    customer: Yup.object().nullable().required("Customer required!"),
    contact: Yup.object().nullable().required("Order contact required!"),
    addresses: Yup.array()
        .required("Shipping addresses required!")
        .min(1, "Shipping addresses required!"),
    billing: Yup.object().nullable().required("Billing addresses required!"),
    warehouseId: Yup.string()
        .nullable()
        .required(Messages.pleaseSelectWarehouseFirst),
});

export const OrderCustomItemSchema = Yup.object().shape({
    category: Yup.object().required("Category required!!"),
    name: Yup.string().required("Name required!"),
    SKU: Yup.string().required("SKU required!"),
    salePrice: Yup.string().required("Sale price required!"),
});

export const OrderFeeSchema = Yup.object().shape({
    value: Yup.number().required("Amount required!"),
});

export const OrderExpiryDateSchema = Yup.object().shape({
    date: Yup.date()
        .min(TimeUtils.yesterday(), "Please choose future date")
        .required("Date required!"),
    remark: Yup.string().required("Remark required!"),
});

export const OrderCancelSchema = Yup.object().shape({
    remark: Yup.string().required("Remark required!"),
});

export const OrderNoteSchema = Yup.object().shape({
    note: Yup.string().required("Note required!"),
});

export const OrderDeliverySchema = Yup.object().shape({
    warehouseId: Yup.string().required("Warehouse required!"),
    deliveryType: Yup.string().required("Delivery type required!"),
    shippingId: Yup.string().required("Shipping address required!"),
    dateOfArrival: Yup.date()
        .min(TimeUtils.yesterday(), "Please choose future date")
        .required("Estimated date required!"),
});

export const OrderDeliveryStatusSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        status: Yup.string().required("Delivery type required!"),
        remark: Yup.string().required("Remark required!"),
    });

    if (
        value.status === DELIVERY_STATUS.COMPLETED ||
        value.status === DELIVERY_STATUS.RETURNED
    ) {
        return schema.concat(
            Yup.object().shape({
                dateOfArrival: Yup.date()
                    .min(TimeUtils.yesterday(), "Please choose future date")
                    .required("Estimated date required!"),
            }) as any
        );
    }

    return schema;
});

export const PaymentProofBankSchema = Yup.object().shape({
    bankAccountId: Yup.string().required("Bank required!"),
});

export const PaymentProof2C2PSchema = Yup.object().shape({
    transactionId: Yup.string().required("Transaction ID required!"),
});

export const OrderPaymentProofSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        nameOfPayer: Yup.string().required("Name of payer required!"),
        dateOfPayment: Yup.date()
            .max(TimeUtils.tomorrow(), Messages.cantChooseFutureDate)
            .required("Date of payment date required!"),
        attachment: Yup.array()
            .required("Attachment required!")
            .min(1, "Attachment required!"),
        remark: Yup.string().required("Remark required!"),
    });

    if (value.method?.type === BANK_TRANSFER) {
        return schema.concat(PaymentProofBankSchema as any);
    }

    if (value.method?.type === C2P) {
        return schema.concat(PaymentProof2C2PSchema as any);
    }

    return schema;
});

export const OrderPaymentConfirmSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        dateOfReceiving: Yup.date().required("Date of payment date required!"),
        attachment: Yup.array()
            .required("Proof of payment received required!")
            .min(1, "Proof of payment received required!"),
        remark: Yup.string().required("Remark required!"),
    });

    if (value.method?.type === BANK_TRANSFER) {
        return schema.concat(
            Yup.object().shape({
                bankAccountId: Yup.string().required("Bank required!"),
            }) as any
        );
    }

    if (value.method?.type === C2P) {
        return schema.concat(
            Yup.object().shape({
                transactionId: Yup.string().required(
                    "Transaction ID required!"
                ),
            }) as any
        );
    }

    return schema;
});

export const OrderPaymentRejectSchema = Yup.object().shape({
    attachment: Yup.array()
        .required("Proof of payment received required!")
        .min(1, "Proof of payment received required!"),
    remark: Yup.string().required("Remark required!"),
});

export const OrderPaymentResetSchema = Yup.object().shape({
    splitPaymentId: Yup.string().required("Payment required!"),
    remark: Yup.string().required("Remark required!"),
});

export const OrderExportSchema = Yup.object().shape({
    from: Yup.string().required("Payment required!"),
    to: Yup.string().required("Payment required!"),
});

export const OrderRefundRequestSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        attachments: Yup.array()
            .required("Attachments required!")
            .min(1, "Attachments required!"),
        typeOfRefund: Yup.string().required("Type required!"),
        remark: Yup.string().required("Remark required!"),
        products: Yup.array().of(
            Yup.object().shape({
                id: Yup.string().required("Required field!"),
                quantity: Yup.number().required("Required field!"),
            }) as any
        ),
    });

    if (value.typeOfRefund === REFUND_FORMAT.CASH) {
        return schema.concat(
            Yup.object().shape({
                paymentMethodId: Yup.string().required(
                    "Payment method required!"
                ),
                amount: Yup.number().required("Amount required!"),
            }) as any
        );
    }

    return schema;
});

export const OrderRefundBankSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name required!"),
    accountNo: Yup.string().required("Account no required!"),
    accountName: Yup.string().required("Account name required!"),
});

export const OrderRefundApprovalSchema = Yup.object().shape({
    remark: Yup.string().required("Bank name required!"),
});

export const OrderRefundCompleteSchema = Yup.object().shape({
    remark: Yup.string().required("Bank name required!"),
    attachments: Yup.array()
        .required("Attachments required!")
        .min(1, "Attachments required!"),
});
