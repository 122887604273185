import { map } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import ActivityView from "../../../common/activity/ActivityView";

const WorksheetDetailActivity = () => {
    const { worksheetId } = useParams<any>();

    const onSendComment = (commentText: string, attachments: any = []) => {
        const attachmentList = map(attachments, (item) => item.url);

        return WorksheetAPI.addComment(
            worksheetId,
            commentText,
            attachmentList
        );
    };

    const source = (paging: any) => {
        return WorksheetAPI.activities(worksheetId, paging);
    };

    const transformer = (res: any) => res?.data?.data?.activities ?? [];

    return (
        <ActivityView
            className="mt-3"
            onClickSendComment={onSendComment}
            source={source}
            transformer={transformer}
        />
    );
};

export default WorksheetDetailActivity;
