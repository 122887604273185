import classNames from "classnames";
import {
    Button,
    Icon,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useContext, useState } from "react";
import AuthCode from "react-auth-code-input";
import AuthAPI from "../../../api/auth/AuthAPI";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import Image from "../../../common/Image";
import InputSelectForm from "../../../common/input/InputSelectForm";
import ViewRowInterchange, {
    IRowsKey,
} from "../../../common/view/ViewRowInterchange";
import { CUSTOMER_STATUSES } from "../../../constant/customer";
import { CustomerDetailContext } from "../../../context/customer";
import { AuthOtpSchema } from "../../../formschema/auth";
import { CustomerInfoSchema } from "../../../formschema/customer";
import { useCountDown } from "../../../hoook/app";
import {
    ICustomer,
    ICustomerCreditTerm,
    mapCustomerToServer,
} from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import { getSourceSelectIntegerFromLength } from "../../../utils/Utils";
import CustomerPersonalInfoForm from "../common/CustomerPersonalInfoForm";

const GENERAL_KEYS: IRowsKey<ICustomer>[] = [
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => (
            <ViewLabelStatus status={data} listStatus={CUSTOMER_STATUSES} />
        ),
    },
    {
        id: "customerId",
        label: Messages.customerId,
    },
    {
        id: "typeOfCustomer",
        label: Messages.typeOfCustomer,
    },
    {
        id: "firstName",
        label: Messages.firstName,
    },
    {
        id: "lastName",
        label: Messages.lastName,
    },
    {
        id: "nickName",
        label: Messages.nickName,
    },
    {
        id: "gender",
        label: Messages.gender,
    },
    {
        id: "dateOfBirth",
        label: Messages.dateOfBirth,
        renderContent: ({ data }) => TimeUtils.toDate(data),
    },

    {
        id: "phone",
        label: Messages.phone,
        renderContent: ({ data, item: customer }) => {
            const classNameTag =
                "border px-2 py-1 ml-2 d-flex align-items-center";
            return (
                <div className="d-flex align-items-center">
                    <text>{data}</text>
                    {customer?.isVerified && (
                        <div
                            className={classNames(
                                "border-success text-success",
                                classNameTag
                            )}
                        >
                            <Icon name="done" className="mr-1" />
                            {Messages.verified}
                        </div>
                    )}

                    {!customer?.isVerified && (
                        <div
                            className={classNames(
                                "border-warning text-warning",
                                classNameTag
                            )}
                        >
                            <Icon name="info" className="mr-1" />
                            {Messages.unVerified}
                        </div>
                    )}
                    {!customer?.isVerified && (
                        <ButtonVerifyCustomerPhone customer={customer} />
                    )}
                </div>
            );
        },
    },
    {
        id: "email",
        label: Messages.email,
    },
    {
        id: "company",
        label: Messages.company,
    },
    {
        id: "nationality",
        label: Messages.nationality,
    },
];

interface IButtonVerifyCustomerPhone {
    customer: ICustomer;
}

const ButtonVerifyCustomerPhone = ({
    customer,
}: IButtonVerifyCustomerPhone) => {
    const [openVerify, setOpenVerify] = useState(false);
    return (
        <div>
            <Button
                onClick={() => setOpenVerify(true)}
                className="ml-2"
                size="small"
            >
                {Messages.verify}
            </Button>
            {openVerify && (
                <VerifyCustomerPhoneModal
                    customer={customer}
                    open={openVerify}
                    onClose={() => setOpenVerify(false)}
                />
            )}
        </div>
    );
};

interface IVerifyCustomerPhoneModal {
    customer: ICustomer;
    open: boolean;
    onClose: () => void;
}

const VerifyCustomerPhoneModal = ({
    customer,
    open,
    onClose,
}: IVerifyCustomerPhoneModal) => {
    const { setCustomerInfo } = useContext(CustomerDetailContext);
    const [timeLeft, { start }] = useCountDown(60 * 1000, 1000);

    const verifyForm = useFormik<any>({
        initialValues: {
            code: undefined,
            otpToken: null,
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AuthOtpSchema,
        onSubmit: (values: any) => {
            const input = {
                code: values?.code,
                otpToken: values?.otpToken,
                phone: customer?.phone,
            };
            Progress.show(
                {
                    method: CustomerAPI.verifyPhone,
                    params: [input],
                },
                (customer) => {
                    setCustomerInfo(customer);
                    Notifications.showSuccess(Messages.verifySuccess);
                    onClose();
                },
                () => {
                    verifyForm.setFieldValue("code", "");
                }
            );
        },
    });

    const requestSmsOtp = () => {
        Progress.show(
            {
                method: AuthAPI.requestSendOtp,
                params: [{ phone: customer.phone }],
            },
            (res: any) => {
                const tokenRes = res?.data?.data;
                if (!isEmpty(tokenRes?.token)) {
                    verifyForm.setFieldValue("otpToken", tokenRes?.token);
                    start();
                }
            }
        );
    };

    const renderCustomerSmsOtp = () => {
        return (
            <div className="p-3">
                <div
                    className="my-3"
                    dangerouslySetInnerHTML={{
                        __html: `<div>${Messages.inputDigitPhoneNumber?.replace(
                            "%phoneNumber",
                            `<text style='color:rgba(244,67,54,1)' >${customer?.phone}</text>`
                        )}</div>`,
                    }}
                />
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
                <div className="flex-column flex-center mt-3">
                    <div>{Messages.didNotReceiveTheCode}</div>
                    <div
                        className={classNames(
                            "text mt-1 text-primary cursor-pointer",
                            {
                                "text-disabled": timeLeft > 0,
                            }
                        )}
                        onClick={requestSmsOtp}
                    >
                        {Messages.resendNowSecond.replace(
                            "%second",
                            `${timeLeft / 1000}`
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderRequestOtpView = () => {
        return (
            <div className="flex-column flex-center p-3">
                <text className="text-center">
                    {Messages.inOrderToVerifyPhone}
                    <text className="text-error">{customer?.phone}</text>
                </text>
                <Button onClick={requestSmsOtp} className="mt-4">
                    {Messages.sendOtp}
                </Button>
            </div>
        );
    };

    const renderContent = () => {
        if (!verifyForm?.values?.otpToken) {
            return renderRequestOtpView();
        }

        return renderCustomerSmsOtp();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.phoneNumberVerification}
            onSave={() => verifyForm.handleSubmit()}
            saveText={Messages.verify}
        >
            {renderContent()}
        </Modal>
    );
};

const CustomerUpdateInfoModal = ({ open, onClose }: any) => {
    const { customerInfo, setCustomerInfo } = useContext(CustomerDetailContext);

    const customerForm = useFormik<ICustomer>({
        initialValues: { ...customerInfo },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerInfoSchema,
        onSubmit: (values) => {
            onUpdateCustomer(values);
        },
    });

    const onUpdateCustomer = (body: any) => {
        const customerBody = mapCustomerToServer(body);
        Progress.show(
            {
                method: CustomerAPI.update,
                params: [customerInfo.id, customerBody.customer],
            },
            (detailInfo) => {
                Notifications.showSuccess(Messages.updateCustomerSuccessfully);
                setCustomerInfo(detailInfo);
                onClose();
            }
        );
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updatePersonalInfo}
            onCancel={onClose}
            size="medium"
            onSave={() => customerForm.handleSubmit()}
        >
            <CustomerPersonalInfoForm customerForm={customerForm} />
        </Modal>
    );
};

const CustomerUpdateCreditTermModal = ({ open, onClose }: any) => {
    const { customerInfo, setCustomerInfo } = useContext(CustomerDetailContext);

    const termForm = useFormik<ICustomerCreditTerm>({
        initialValues: customerInfo?.creditTerm ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: CustomerInfoSchema,
        onSubmit: (values) => {
            onUpdateCreditTerm(values);
        },
    });

    const formValues = termForm?.values;

    const onUpdateCreditTerm = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.updateCreditTerm,
                params: [customerInfo.id, body],
            },
            (detailInfo) => {
                Notifications.showSuccess(Messages.updateCustomerSuccessfully);
                setCustomerInfo(detailInfo);
                onClose();
            }
        );
    };

    const inputClassName = "mt-3";

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.update}
            onCancel={onClose}
            size="medium"
            onSave={() => termForm.handleSubmit()}
        >
            <InputSelectForm
                dataSource={getSourceSelectIntegerFromLength(5)}
                keyData="year"
                form={termForm}
                className={inputClassName}
            />
            <InputSelectForm
                dataSource={getSourceSelectIntegerFromLength(12)}
                keyData="month"
                form={termForm}
                className={inputClassName}
            />
            <InputSelectForm
                dataSource={getSourceSelectIntegerFromLength(31)}
                keyData="day"
                form={termForm}
                className={inputClassName}
            />
        </Modal>
    );
};

const CustomerDetailInfo = () => {
    const { customerInfo } = useContext(CustomerDetailContext);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openEditCreditTerm, setOpenEditCreditTerm] = useState(false);

    const renderGeneralInfo = () => {
        return (
            <div className="p-4 bg-white">
                <div className="d-flex justify-justify-content-between">
                    <div className="d-flex flex-grow-1">
                        <div className="customer-detail__info-avatar-container">
                            <Image src={customerInfo?.avatar} />
                        </div>
                        <div className="flex-column ml-3">
                            <text className="text-x-small-bold">
                                {customerInfo?.fullName}
                            </text>
                            <text className="text-xx-small">
                                {Messages.createAt}
                            </text>
                            <text className="text-xx-small">
                                {Messages.lastLogin}
                            </text>
                        </div>
                    </div>
                    <Button variant="trans" onClick={() => setOpenEdit(true)}>
                        {Messages.edit}
                    </Button>
                </div>
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={customerInfo}
                />
                {openEdit && (
                    <CustomerUpdateInfoModal
                        open={openEdit}
                        onClose={() => setOpenEdit(false)}
                    />
                )}
            </div>
        );
    };

    const renderCreditTerm = () => {
        const { creditTerm } = customerInfo;
        return (
            <div className="mt-3 card-container p-4">
                <div className="flex-row-between-center">
                    <div className="label">{Messages.creditTerm}</div>
                    <Button
                        variant="trans"
                        onClick={() => setOpenEditCreditTerm(true)}
                    >
                        {Messages.edit}
                    </Button>
                </div>
                <div className="d-flex flex-row">
                    {creditTerm?.year && (
                        <div>{`${creditTerm?.year} ${Messages.year}`}</div>
                    )}
                    {creditTerm?.month && (
                        <div className="ml-1">{` ${creditTerm?.month} ${Messages.month} `}</div>
                    )}
                    {creditTerm?.day && (
                        <div className="ml-1">{` ${creditTerm?.day} ${Messages.day} `}</div>
                    )}
                </div>
                {openEditCreditTerm && (
                    <CustomerUpdateCreditTermModal
                        open={openEditCreditTerm}
                        onClose={() => setOpenEditCreditTerm(false)}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            {renderGeneralInfo()}
            {renderCreditTerm()}
        </div>
    );
};

export default CustomerDetailInfo;
