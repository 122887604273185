import { Checkbox, Icon, Modal } from "d-react-components";
import { useFormik } from "formik";
import { useState } from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";
import SignatureField from "./SignatureField";

interface IWSDefectionConfirm {
    defectionConfirm: any;
    onConfirm?: (value: any) => any;
    onUpdateRemark?: (value: any) => any;
    onCustomerSign?: (value: any) => any;
    onStaffSign?: (value: any) => any;
}

interface IWSDefectionEditRemark {
    open: boolean;
    onClose: () => void;
    onSave: any;
}

const WSDefectionEditRemarkModal = ({
    open,
    onClose,
    onSave,
}: IWSDefectionEditRemark) => {
    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onSave(values);
            onClose();
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => confirmForm.handleSubmit()}
        >
            <div>
                <InputTextForm
                    form={confirmForm}
                    keyData="remark"
                    className="mt-3"
                    multiple
                />
            </div>
        </Modal>
    );
};

const WSDefectionConfirm = ({
    defectionConfirm,
    onConfirm,
    onUpdateRemark,
    onCustomerSign,
    onStaffSign,
}: IWSDefectionConfirm) => {
    const [openEditRemark, setOpenEditRemark] = useState(false);
    return (
        <div className="flex-column card-container p-4 mt-3">
            <label>{Messages.defectionConfirmation}</label>
            <div className="d-flex mt-3">
                <Checkbox
                    onChange={onConfirm}
                    checked={!!defectionConfirm.confirmed}
                    disabled={defectionConfirm.confirmed}
                />
                <text className="ml-3">{Messages.iAcknowledgeAndConfirm}</text>
            </div>

            <div className="bg-muted p-3 mt-3 flex-column">
                <div className="flex-row-between-center">
                    <label>{Messages.remark}</label>
                    <Icon
                        name="edit"
                        size="small"
                        onClick={() => setOpenEditRemark(true)}
                        className="cursor-pointer"
                    />
                </div>

                <text>{defectionConfirm.remark}</text>
            </div>

            <div className="mt-3 d-flex justify-content-between">
                <SignatureField
                    name={Messages.customer}
                    date={defectionConfirm?.customerSignDate}
                    signature={defectionConfirm?.customerSignature}
                    onChangeSign={(customerSignature) =>
                        onCustomerSign && onCustomerSign(customerSignature)
                    }
                    disabled={!defectionConfirm.confirmed}
                />
                <SignatureField
                    name={Messages.staff}
                    date={defectionConfirm?.staffSignDate}
                    signature={defectionConfirm?.staffSignature}
                    onChangeSign={(staffSignature) => {
                        onStaffSign && onStaffSign(staffSignature);
                    }}
                    disabled={!defectionConfirm.confirmed}
                />
            </div>
            {openEditRemark && (
                <WSDefectionEditRemarkModal
                    open={openEditRemark}
                    onClose={() => setOpenEditRemark(false)}
                    onSave={onUpdateRemark}
                />
            )}
        </div>
    );
};

export default WSDefectionConfirm;
