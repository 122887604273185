import { Spin } from "antd";
import classNames from "classnames";
import { Icon } from "d-react-components";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import MediaAPI from "../../api/media/MediaAPI";
import Messages from "../../languages/Messages";
import "./UploadAvatar.scss";

export default function UploadAvatar({
    onChange,
    disabled = false,
    value = null,
}: any) {
    const [loading, setLoading] = useState(false);

    const onDrop = ([fileUpload]: any) => {
        setLoading(true);
        const reader: any = new FileReader();
        reader.readAsDataURL(fileUpload);
        reader.onloadend = () => {
            MediaAPI.uploadMedia({ file: fileUpload }).then((res) => {
                onChange(res?.data?.upload);
                setLoading(false);
            });
        };
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const iconClass = classNames("d-none", {
        "uploadImageModule_avatar-container-icon": !disabled,
    });

    return (
        <div className="uploadImagesModule" {...getRootProps()}>
            <input {...getInputProps()} multiple={false} disabled={disabled} />
            {value ? (
                <div className="uploadImageModule_avatar-container rounded w-100 h-100">
                    <img className="avatarPhoto" src={value} alt="" />
                    <div className={iconClass}>
                        <Icon name="photo_camera" size="large" />
                    </div>
                </div>
            ) : (
                <div className="uploadImageModule_upload-view">
                    <Icon name="photo_camera" />
                    {loading ? (
                        <Spin size="large" />
                    ) : (
                        <text className="mt-2">{Messages.upload}</text>
                    )}
                </div>
            )}
        </div>
    );
}
