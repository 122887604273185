import { Tree } from "antd";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { PERMISSION_MODULES, ROLE_ACCESS_TYPES } from "../../../constant/user";
import { UserRoleCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";

const UserRoleCrudSetting = () => {
    const { roleForm } = useContext(UserRoleCrudContext);

    const [expandedKeys, setExpandedKeys] = useState([]);

    const permissionTreeData: any[] = map(PERMISSION_MODULES, (item) => {
        const accessTypeList = item.accessTypes ?? ROLE_ACCESS_TYPES;
        return {
            ...item,
            children: accessTypeList,
        };
    });

    const onCheck = (checkedKeys: any[], info: any) => {
        roleForm.setFieldValue("permissions", checkedKeys);
    };

    return (
        <div className="mt-3">
            <Tree
                showLine={{ showLeafIcon: false }}
                showIcon={false}
                treeData={permissionTreeData}
                expandedKeys={expandedKeys}
                onExpand={(keys: any) => {
                    setExpandedKeys(keys);
                }}
                defaultExpandedKeys={expandedKeys}
                titleRender={(node: any) => (Messages as any)[node?.label]}
                checkable
                onCheck={onCheck as any}
                checkedKeys={roleForm?.values?.permissions}
            />
        </div>
    );
};

export default UserRoleCrudSetting;
