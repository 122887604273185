import { TimeUtils } from "d-react-components";
import React from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

interface ICustomerInfoView {
    customer: ICustomer;
}

const CustomerInfoView = ({ customer }: ICustomerInfoView) => {
    if (!customer) return <div />;
    const {
        avatar,
        customerId,
        firstName,
        lastName,
        nickName,
        dateOfBirth,
        email,
        phone,
    } = customer;
    return (
        <div className="flex-column">
            <Image
                className="image-square-small"
                style={{ borderRadius: "24px" }}
                src={avatar}
            />
            <AppLink
                to={generatePath(Path.CUSTOMER_DETAIL, {
                    customerId: customer.id,
                })}
            >
                <text className="mt-1">{`${Messages.id}: ${customerId}`}</text>
            </AppLink>
            <text className="mt-1">{`${firstName} ${lastName} (${nickName})`}</text>
            <text className="mt-1">{`${Messages.dob}: ${TimeUtils.toDate(
                dateOfBirth
            )}`}</text>
            <text className="mt-1">{email}</text>
            <text className="mt-1">{phone}</text>
        </div>
    );
};

export default CustomerInfoView;
