import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    Icon,
    Modal,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce, filter, isEmpty, map, toLower } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import classNames from "classnames";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import AppLink from "../../../common/AppLink";
import { VOUCHER_STATUSES } from "../../../constant/voucher";
import { IProduct } from "../../../interfaces/product";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import { isFilteredBody } from "../../../utils/Utils";
import Path from "../../Path";
import VoucherFilterForm from "../../voucher/table/VoucherFilterForm";
import ProductSelect from "../../product/common/ProductSelect";
import VoucherAPI from "../../../api/voucher/VoucherAPI";

interface IVoucherFilterModal {
    open: boolean;
    defaultValue: any;
    onClose: () => void;
    onFilter: (values: any) => void;
}

const VoucherFilterModal = ({
    open,
    defaultValue = {},
    onClose,
    onFilter,
}: IVoucherFilterModal) => {
    const filterForm = useFormik<any>({
        initialValues: defaultValue as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onFilter(values);
            onClose();
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm.handleSubmit()}
        >
            <VoucherFilterForm filterForm={filterForm} hideCustomer />
        </Modal>
    );
};

const CustomerDetailVoucher = () => {
    const { customerId } = useParams<any>();
    const [openFilter, setOpenFilter] = useState(false);

    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [filterBody, setFilterBody] = useState<any>();
    const [summaryFilter, setSummaryFilter] = useState<any>({});
    const [summaryVoucher, setSummaryVoucher] = useState<any[]>([]);

    useEffect(() => {
        loadVoucherSummary();
    }, [summaryFilter]);

    const loadVoucherSummary = () => {
        VoucherAPI.summary({
            customer: [customerId],
            productIds: summaryFilter?.product
                ? [summaryFilter?.product?.id]
                : null,
        }).then((summaryVoucher) => {
            const result = filter(
                summaryVoucher,
                (item) => item.status && item.total
            );
            setSummaryVoucher(result);
        });
    };

    const columns: IColumnsProps = [
        {
            dataIndex: "code",
            title: Messages.no,
            render: (code: string, voucher: any) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })}
                >
                    {code}
                </AppLink>
            ),
        },
        {
            dataIndex: "product",
            title: "SKU",
            render: (data: IProduct) => {
                if (isEmpty(data)) {
                    return null;
                }
                return (
                    <div className="flex-center-y">
                        <div>{data?.SKU}</div>
                        <AppLink
                            to={generatePath(Path.PRODUCT_DETAIL, {
                                productId: data.id,
                            })}
                            target="_blank"
                        >
                            <Icon
                                name="link"
                                className="ml-2"
                                style={{ fontSize: 40 }}
                            />
                        </AppLink>
                    </div>
                );
            },
        },
        {
            dataIndex: "status",
            title: Messages.status,
            render: (status: string) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={VOUCHER_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },
        { dataIndex: "", title: Messages.type },
        {
            title: Messages.effectiveDate,
            dataIndex: "effectiveDate",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.expiryDate,
            dataIndex: "expiryDate",
            render: (date) =>
                date ? TimeUtils.toDateTime(date) : Messages.noExpireDate,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy: IUser) => createBy?.firstName,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (paging: any) => {
        const filter = {
            q: searchRef.current,

            serviceCategories: filterBody?.serviceCategories,
            effectiveDate: filterBody?.effectiveDate
                ? moment(filterBody?.effectiveDate).toISOString()
                : null,
            expiryDate: filterBody?.expiryDate
                ? moment(filterBody?.expiryDate).toISOString()
                : null,
            createdBy: map(filterBody?.createdBy, (item) => item.id),
            productIds: map(filterBody?.products, (item) => item.id),
            ownershipStatus: filterBody?.ownershipStatus,
            status: filterBody?.status,
        };
        return CustomerAPI.voucherList(customerId, paging, filter);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const onFilter = (values: any) => {
        setFilterBody(values);
        tableRef.current.refresh();
    };

    return (
        <div>
            <div className="p-4 bg-white mt-3">
                <div className="h4">{Messages.summary}</div>
                <ProductSelect
                    label=""
                    className="mt-3"
                    value={summaryFilter?.product}
                    onChange={(value) =>
                        setSummaryFilter({ ...summaryFilter, product: value })
                    }
                    multiple={false}
                />
                <div
                    className="px-3 py-2 mt-3"
                    style={{ backgroundColor: "#F2F5F3" }}
                    hidden={!summaryVoucher?.length}
                >
                    {map(summaryVoucher, (summary, index) => (
                        <div
                            className={classNames(
                                "flex-row-between-center  py-2",
                                {
                                    "border-bottom":
                                        index !== summaryVoucher?.length - 1,
                                }
                            )}
                        >
                            <div className="text-primary">
                                {(Messages as any)[toLower(summary?.status)]}
                            </div>
                            <div>{summary?.total}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="p-4 bg-white mt-3">
                <HeaderTable
                    label={Messages.voucher}
                    onClickFilter={() => setOpenFilter(true)}
                    onChangeText={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                    className="mb-3"
                    isFiltered={isFilteredBody(filterBody)}
                />
                <AwesomeTableComponent
                    columns={columns}
                    className="mt-3"
                    transformer={(res) => res?.data?.data?.voucher ?? []}
                    source={source}
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                />

                {openFilter && (
                    <VoucherFilterModal
                        open={openFilter}
                        onClose={() => setOpenFilter(false)}
                        onFilter={onFilter}
                        defaultValue={filterBody}
                    />
                )}
            </div>
        </div>
    );
};

export default CustomerDetailVoucher;
