import {
    Avatar,
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Progress,
} from "d-react-components";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { generatePath } from "react-router-dom";
import BookingAPI from "../../../api/booking/BookingAPI";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserSelectModal from "../../user/common/UserSelectModal";

const BookingDetailPic = () => {
    const columns: IColumnsProps = [
        {
            title: Messages.no,
            dataIndex: "companyId",
            render: (companyId, item, index) => index + 1,
        },
        {
            title: Messages.companyId,
            dataIndex: "companyId",
            render: (companyId, item) => (
                <AppLink
                    to={generatePath(Path.USER_DETAIL, {
                        userId: item.id,
                    })}
                    className="p-3"
                >
                    {companyId}
                </AppLink>
            ),
        },
        {
            title: Messages.name,
            dataIndex: "",
            render: (user) => (
                <div className="d-flex align-items-center">
                    <Avatar
                        src={user?.avatar ?? "/images/placeholder.png"}
                        size="xx-small"
                    />
                    <small className="ml-3">{`${user?.firstName} ${user?.lastName}`}</small>
                </div>
            ),
        },

        {
            title: Messages.role,
            dataIndex: "role",
            render: (role) => role?.name,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (user: IUser) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, user)
                        }
                        dataSource={[{ id: "delete", label: "delete" }]}
                    />
                );
            },
        },
    ];
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);

    const [openAssign, setOpenAssign] = useState<boolean>(false);
    const { PIC = [], status } = bookingDetail;

    const onClickTableAction = (actionId: string, user: any) => {
        Progress.show(
            {
                method: BookingAPI.removePIC,
                params: [bookingDetail?.id, [user?.id]],
            },
            setBookingDetail
        );
    };

    const onChangeAssignPic = (users: any[]) => {
        const userIds = map(users, (item) => item.id);
        Progress.show(
            {
                method: BookingAPI.assignPIC,
                params: [bookingDetail?.id, userIds],
            },
            setBookingDetail
        );
    };

    return (
        <div className="card-container p-4 mt-3">
            <div className="d-flex flex-center justify-content-between">
                <label>{Messages.pic}</label>
                <Button
                    onClick={() => setOpenAssign(true)}
                    hidden={status === BOOKING_STATUS.CANCELLED}
                >
                    {Messages.assignPic}
                </Button>
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={PIC}
                className="mt-3"
            />
            {openAssign && (
                <UserSelectModal
                    open={openAssign}
                    onClose={() => setOpenAssign(false)}
                    onSave={onChangeAssignPic}
                    defaultValue={PIC ?? []}
                />
            )}
        </div>
    );
};

export default BookingDetailPic;
