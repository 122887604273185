import { Button, Header, Progress } from "d-react-components";
import { find } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import VerticalTabView from "../../../common/view/VerticalTabView";
import { AppStateContext } from "../../../context/app";
import { CustomerDetailContext } from "../../../context/customer";
import { IBillingAddress, IServiceAddress } from "../../../interfaces/address";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerDetailAddress from "./CustomerDetailAddress";
import CustomerDetailBilling from "./CustomerDetailBilling";
import CustomerDetailBooking from "./CustomerDetailBooking";
import CustomerDetailInfo from "./CustomerDetailInfo";
import CustomerDetailOrder from "./CustomerDetailOrder";
import CustomerDetailPassword from "./CustomerDetailPassword";
import CustomerDetailVoucher from "./CustomerDetailVoucher";

const TABS = [
    {
        id: "voucher",
        label: Messages.voucher,
        component: <CustomerDetailVoucher />,
    },
    {
        id: "general",
        label: Messages.personalInfo,
        component: <CustomerDetailInfo />,
    },

    {
        id: "serviceAddress",
        label: Messages.serviceAddress,
        component: <CustomerDetailAddress />,
    },
    {
        id: "billingInformation",
        label: Messages.billingInformation,
        component: <CustomerDetailBilling />,
    },
    {
        id: "booking",
        label: Messages.bookings,
        component: <CustomerDetailBooking />,
    },
    {
        id: "order",
        label: Messages.order,
        component: <CustomerDetailOrder />,
    },
    {
        id: "password",
        label: Messages.password,
        component: <CustomerDetailPassword />,
    },
];

const CustomerDetail = () => {
    const { customerId } = useParams<any>();

    const [customerInfo, setCustomerInfo] = useState<ICustomer>({} as any);
    const [customerServiceAdds, setCustomerServiceAdds] = useState<
        IServiceAddress[]
    >([]);
    const [customerBillingAdds, setCustomerBillingAdds] = useState<
        IBillingAddress[]
    >([]);
    const [selectedTab, setSelectedTab] = useState<any>(TABS[0]);
    const { setSiteConfig } = useContext(AppStateContext);

    useEffect(() => {
        loadCustomerDetail();
    }, []);

    const loadCustomerDetail = () => {
        Progress.show(
            [
                { method: CustomerAPI.detail, params: [customerId] },
                { method: CustomerAPI.serviceAddresses, params: [customerId] },
                { method: CustomerAPI.billingAddresses, params: [customerId] },
            ],
            (res) => {
                const [info, addresses, billingAddresses] = res as any;
                setCustomerInfo(info);
                setCustomerServiceAdds(addresses);
                setCustomerBillingAdds(billingAddresses);
                setSiteConfig({
                    breadcrumb: [
                        {
                            title: Messages.allCustomers,
                            url: Path.CUSTOMER_TABLE,
                        },
                        {
                            title: `${Messages.customer} #${info?.customerId}`,
                        },
                    ],
                });
            }
        );
    };

    const loadCustomerServiceAdds = () => {
        CustomerAPI.serviceAddresses(customerId).then(setCustomerServiceAdds);
    };

    const loadCustomerBillingAdds = () => {
        CustomerAPI.billingAddresses(customerId).then(setCustomerBillingAdds);
    };

    const renderHeaderButtons = () => {
        return (
            <div className="flex-center">
                {/* <Button variant="outline">{Messages.back}</Button> */}
                {/* <Button className="ml-3">{Messages.resetPassword}</Button> */}
                {/* <Button className="ml-3">{Messages.newBooking}</Button> */}
                {/* <Button className="ml-3">{Messages.newVoucher}</Button> */}
            </div>
        );
    };
    return (
        <CustomerDetailContext.Provider
            value={{
                customerInfo,
                setCustomerInfo,
                customerServiceAdds,
                loadCustomerServiceAdds,
                customerBillingAdds,
                loadCustomerBillingAdds,
            }}
        >
            <Header
                showCancel={false}
                title={Messages.customerDetail}
                customRight={renderHeaderButtons}
            />
            <div className="h-100 overflow-auto">
                <div className="row p-4">
                    <div className="col">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-9  ml-2">
                        {
                            find(TABS, (item) => selectedTab.id === item.id)
                                ?.component
                        }
                    </form>
                </div>
            </div>
        </CustomerDetailContext.Provider>
    );
};

export default CustomerDetail;
