import { gql } from "@apollo/client";
import API from "../API";
import { F_SMART_SUPPORT } from "./SettingFragment";

const detailConvertor = {
    fromServer: (res: any) => res?.data?.data,
};

export default {
    smartSupport: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_SMART_SUPPORT}
                query smartSupport {
                    data: smartSupport {
                        ...F_SMART_SUPPORT
                    }
                }
            `,
            variables: { id },
        }),

    updateSmartSupport: (input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_SMART_SUPPORT}
                mutation updateSmartSupport($input: SmartSupportInput!) {
                    data: updateSmartSupport(input: $input) {
                        ...F_SMART_SUPPORT
                    }
                }
            `,
            variables: { input },
        }),
};
