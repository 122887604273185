import classNames from "classnames";
import {
    Checkbox,
    DialogManager,
    Progress,
    TimeUtils,
} from "d-react-components";
import { every, filter, find, map, replace, some, sortBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import Drawer from "../../../common/drawer/Drawer";
import {
    WORKSHEET_CRITERIA_CHECK_STATUS,
    WorksheetStatus,
} from "../../../constant/worksheet";
import { WorksheetDetailContext } from "../../../context/worksheet";
import { IWorksheet } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";
import WSCustomerConfirm from "../common/WSCustomerConfirm";

interface IWSDetailCustomerBulkConfirm {
    open: boolean;
    onClose: () => void;
    customerSignature: string;
}

interface IWSDetailStaffBulkConfirm {
    open: boolean;
    onClose: () => void;
    staffSignature: string;
}

interface IWorksheetItem {
    worksheet: IWorksheet;
    isCurrent: boolean;
    checked: boolean;
    disabled: boolean;
    onChange: any;
}

const WorksheetItem = ({
    worksheet,
    isCurrent,
    checked,
    onChange,
    disabled,
}: IWorksheetItem) => {
    return (
        <div className={classNames("border-bottom py-2 d-flex flex-row w-100")}>
            <div className={classNames("flex-grow-1")}>
                <div className={classNames("flex-row d-flex")}>
                    {isCurrent && (
                        <div
                            className={classNames(
                                "bg-primary px-3 mr-2 flex-center"
                            )}
                        >
                            <div
                                className={classNames("text-small text-white")}
                            >
                                {Messages.current}
                            </div>
                        </div>
                    )}
                    <div
                        className={classNames("text-primary font-weight-bold")}
                    >
                        {`#${worksheet?.worksheetNo}`}
                    </div>
                </div>

                <div className={classNames("text-placeholder")}>
                    {`${Messages.serviceDate}: ${TimeUtils.toDateTime(
                        worksheet.serviceDate
                    )}`}
                </div>
                <div className={classNames("text-placeholder")}>
                    {`${Messages.serviceCategory}: ${worksheet?.categoryProduct?.name}`}
                </div>
            </div>
            <Checkbox
                checked={checked}
                // disabled={disabled}
                onChange={() => onChange(worksheet, !checked)}
            />
        </div>
    );
};

const WSDetailCustomerBulkConfirm = ({
    open,
    onClose,
    customerSignature,
}: IWSDetailCustomerBulkConfirm) => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const [worksheets, setWorksheets] = useState<IWorksheet[]>([]);
    const [worksheetSelected, setWorksheetSelected] = useState([
        worksheetDetail,
    ]);

    useEffect(() => {
        loadAllWorksheetInBooking();
    }, []);

    const loadAllWorksheetInBooking = () => {
        Progress.show(
            {
                method: BookingAPI.getAllWorksheets,
                params: [worksheetDetail?.booking?.id],
            },
            setWorksheets as any
        );
    };

    const onChangeChecked = (worksheet: any, value: boolean) => {
        if (value) {
            setWorksheetSelected([...worksheetSelected, worksheet]);
        } else {
            setWorksheetSelected(
                filter(worksheetSelected, (item) => item.id !== worksheet?.id)
            );
        }
    };

    const getDisableWs = (ws: IWorksheet) => {
        if (ws?.id === worksheetDetail?.id) {
            return true;
        }

        if (ws?.status !== WorksheetStatus.PROCESSING) {
            return true;
        }
        return !ws?.confirmation?.staffSignature;
    };

    const onFinalConfirm = () => {
        Progress.show(
            map(worksheetSelected, (ws) => ({
                method: WorksheetAPI.customerSignFinalConfirm,
                params: [ws?.id, customerSignature],
            })),
            ([res]: any) => {
                setWorksheetDetail(res);
                onClose();
            }
        );
    };

    return (
        <Drawer
            title={Messages.bulkConfirmation}
            size="x-large"
            open={open}
            onClose={onClose}
            onSave={onFinalConfirm}
        >
            <div className="p-3 bg-white">
                <div>{Messages.checkConfirmWholeService}</div>
                <div className={classNames("font-weight-bold mt-2")}>
                    {Messages.bulkConfirmation}
                </div>
                <div className={classNames("mt-2")}>
                    {replace(
                        Messages.yourBookingBulkWorksheet,
                        "%bookingNo",
                        worksheetDetail?.booking?.bookingNo
                    )}
                </div>
                <div
                    className="p-3 mt-2"
                    style={{ backgroundColor: "#F2F5F3" }}
                >
                    {map(
                        sortBy(
                            worksheets,
                            (item) => worksheetDetail?.id !== item?.id
                        ),
                        (item) => (
                            <WorksheetItem
                                worksheet={item}
                                isCurrent={worksheetDetail?.id === item?.id}
                                onChange={onChangeChecked}
                                checked={
                                    !!find(
                                        worksheetSelected,
                                        (ws) => ws.id === item.id
                                    )
                                }
                                disabled={getDisableWs(item)}
                            />
                        )
                    )}
                </div>
            </div>
        </Drawer>
    );
};

const WSDetailStaffBulkConfirm = ({
    open,
    onClose,
    staffSignature,
}: IWSDetailStaffBulkConfirm) => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const [worksheets, setWorksheets] = useState<IWorksheet[]>([]);
    const [worksheetSelected, setWorksheetSelected] = useState([
        worksheetDetail,
    ]);

    useEffect(() => {
        loadAllWorksheetInBooking();
    }, []);

    const loadAllWorksheetInBooking = () => {
        Progress.show(
            {
                method: BookingAPI.getAllWorksheets,
                params: [worksheetDetail?.booking?.id],
            },
            setWorksheets as any
        );
    };

    const onChangeChecked = (worksheet: any, value: boolean) => {
        if (value) {
            setWorksheetSelected([...worksheetSelected, worksheet]);
        } else {
            setWorksheetSelected(
                filter(worksheetSelected, (item) => item.id !== worksheet?.id)
            );
        }
    };

    const getDisableWs = (ws: IWorksheet) => {
        if (ws?.id === worksheetDetail?.id) {
            return true;
        }

        if (ws?.status !== WorksheetStatus.PROCESSING) {
            return true;
        }

        const isCustomerConfirmChecked = every(
            ws?.criteriasCheck,
            (criteriaCheck) =>
                criteriaCheck?.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CUSTOMER_CONFIRMED ||
                criteriaCheck?.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED
        );
        return !isCustomerConfirmChecked;
    };

    const onFinalConfirm = () => {
        Progress.show(
            map(worksheetSelected, (ws) => ({
                method: WorksheetAPI.staffSignFinalConfirm,
                params: [ws?.id, staffSignature],
            })),
            ([res]: any) => {
                setWorksheetDetail(res);
                onClose();
            }
        );
    };

    return (
        <Drawer
            title={Messages.bulkConfirmation}
            size="x-large"
            open={open}
            onClose={onClose}
            onSave={onFinalConfirm}
        >
            <div className="p-3 bg-white">
                <div>{Messages.checkConfirmWholeService}</div>
                <div className={classNames("font-weight-bold mt-2")}>
                    {Messages.bulkConfirmation}
                </div>
                <div className={classNames("mt-2")}>
                    {replace(
                        Messages.yourBookingBulkWorksheet,
                        "%bookingNo",
                        worksheetDetail?.booking?.bookingNo
                    )}
                </div>
                <div
                    className="p-3 mt-2"
                    style={{ backgroundColor: "#F2F5F3" }}
                >
                    {map(
                        sortBy(
                            worksheets,
                            (item) => worksheetDetail?.id !== item?.id
                        ),
                        (item) => (
                            <WorksheetItem
                                worksheet={item}
                                isCurrent={worksheetDetail?.id === item?.id}
                                onChange={onChangeChecked}
                                checked={
                                    !!find(
                                        worksheetSelected,
                                        (ws) => ws.id === item.id
                                    )
                                }
                                disabled={getDisableWs(item)}
                            />
                        )
                    )}
                </div>
            </div>
        </Drawer>
    );
};

const WorksheetDetailCustomerConfirm = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const { confirmation, categoryProduct, criteriasCheck, mileageEnd } =
        worksheetDetail;

    const [openBulkCustomerConfirm, setOpenBulkCustomerConfirm] = useState({
        open: false,
        customerSignature: "",
    });
    const [openBulkStaffConfirm, setOpenBulkStaffConfirm] = useState({
        open: false,
        staffSignature: "",
    });

    const isEndedMileage =
        !categoryProduct?.mileageTrackingRequired ||
        ((mileageEnd?.mile || mileageEnd?.mile === 0) &&
            mileageEnd?.proves?.length);

    const isCompletedProgress =
        criteriasCheck?.length > 0 &&
        some(
            criteriasCheck,
            (criteriaItem) =>
                // every(criteriaItem.workProgress, (item) => item.value)
                // && every(criteriaItem.deliveryProgress, (item) => item.value)
                criteriaItem.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.WORK_PROGRESS_CHECKED ||
                criteriaItem.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CUSTOMER_CONFIRMED ||
                criteriaItem.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED
        );

    const onUpdateRemark = ({ remark }: any) => {
        Progress.show(
            {
                method: WorksheetAPI.editRemarkFinalConfirm,
                params: [worksheetDetail?.id, remark],
            },
            (res: any) => {
                setWorksheetDetail(res);
            }
        );
    };

    const onCheckCustomer = (criteriaCheck: any, checklistItem: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkCustomerConfirm,
                        params: [
                            worksheetDetail?.id,
                            criteriaCheck?.id,
                            checklistItem?.id,
                        ],
                    },
                    (res: any) => {
                        setWorksheetDetail(res);
                    }
                );
            }
        );
    };

    const isDisableCustomerConfirmWs = (ws: IWorksheet) => {
        if (ws?.id === worksheetDetail?.id) {
            return true;
        }

        if (ws?.status !== WorksheetStatus.PROCESSING) {
            return true;
        }
        return !ws?.confirmation?.staffSignature;
    };

    const isDisableStaffConfirmWs = (ws: IWorksheet) => {
        if (ws?.id === worksheetDetail?.id) {
            return true;
        }

        if (ws?.status !== WorksheetStatus.PROCESSING) {
            return true;
        }

        const isCustomerConfirmChecked = every(
            ws?.criteriasCheck,
            (criteriaCheck) =>
                criteriaCheck?.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CUSTOMER_CONFIRMED ||
                criteriaCheck?.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED
        );
        return !isCustomerConfirmChecked;
    };

    const onStaffSign = (signature: string) => {
        Progress.show(
            {
                method: BookingAPI.getAllWorksheets,
                params: [worksheetDetail?.booking?.id],
            },
            (allWs) => {
                const isAllWsDisabled = every(allWs, isDisableStaffConfirmWs);

                if (isAllWsDisabled) {
                    Progress.show(
                        {
                            method: WorksheetAPI.staffSignFinalConfirm,
                            params: [worksheetDetail?.id, signature],
                        },
                        (res: any) => {
                            setWorksheetDetail(res);
                        }
                    );
                } else {
                    setOpenBulkStaffConfirm({
                        open: true,
                        staffSignature: signature,
                    });
                }
            }
        );
    };

    const onCustomerSign = (signature: string) => {
        Progress.show(
            {
                method: BookingAPI.getAllWorksheets,
                params: [worksheetDetail?.booking?.id],
            },
            (allWs) => {
                const isAllWsDisabled = every(
                    allWs,
                    isDisableCustomerConfirmWs
                );

                if (isAllWsDisabled) {
                    Progress.show(
                        {
                            method: WorksheetAPI.customerSignFinalConfirm,
                            params: [worksheetDetail?.id, signature],
                        },
                        (res: any) => {
                            setWorksheetDetail(res);
                        }
                    );
                } else {
                    setOpenBulkCustomerConfirm({
                        open: true,
                        customerSignature: signature,
                    });
                }
            }
        );
    };

    const onCheckAllCustomerConfirm = (criteria: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureCompleteChecklist,
            () => {
                Progress.show(
                    {
                        method: WorksheetAPI.checkAllCustomerConfirm,
                        params: [worksheetDetail?.id, criteria?.id],
                    },
                    setWorksheetDetail
                );
            }
        );
    };

    if (!isCompletedProgress || !isEndedMileage) {
        return (
            <div className="flex-column card-container p-4 mt-3 flex-center">
                <text>{Messages.thereIsNoFinalConfirmation}</text>
            </div>
        );
    }
    return (
        <div className="flex-column card-container p-4 mt-3">
            <WSCustomerConfirm
                criteriasCheck={criteriasCheck}
                criteriaDataList={
                    categoryProduct?.priorChecking?.criterias ?? []
                }
                confirmation={confirmation}
                onUpdateRemark={onUpdateRemark}
                // onCheckCustomer={onCheckCustomer as any}
                onStaffSign={onStaffSign}
                onCustomerSign={onCustomerSign}
                onCheckAllCustomerConfirm={onCheckAllCustomerConfirm}
            />
            {openBulkCustomerConfirm?.open && (
                <WSDetailCustomerBulkConfirm
                    open={openBulkCustomerConfirm?.open}
                    customerSignature={
                        openBulkCustomerConfirm?.customerSignature
                    }
                    onClose={() =>
                        setOpenBulkCustomerConfirm({
                            open: false,
                            customerSignature: "",
                        })
                    }
                />
            )}
            {openBulkStaffConfirm?.open && (
                <WSDetailStaffBulkConfirm
                    open={openBulkStaffConfirm?.open}
                    staffSignature={openBulkStaffConfirm?.staffSignature}
                    onClose={() =>
                        setOpenBulkStaffConfirm({
                            open: false,
                            staffSignature: "",
                        })
                    }
                />
            )}
        </div>
    );
};

export default WorksheetDetailCustomerConfirm;
