import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import { useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import { RefundDetailContext } from "../../../context/refundRequest";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const RefundProduct = ({ product }: any) => {
    return (
        <div className="d-flex">
            <Image
                src={product?.thumbnail?.thumbnail}
                className="image-square-small"
            />

            <div className="flex-column ml-2">
                <AppLink
                    to={generatePath(Path.PRODUCT_DETAIL, {
                        productId: product.productId ?? "products",
                    })}
                >
                    {product?.[Messages.getLanguage()]?.name}
                </AppLink>
                <small>
                    {Messages.sku}: {product?.SKU}
                </small>
            </div>
        </div>
    );
};

const RefundRequestDetailItems = () => {
    const { refundRequest } = useContext(RefundDetailContext);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product) => {
                return <RefundProduct product={product as any} />;
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
        },
    ];

    return (
        <div className="mt-3 card-container p-3">
            <label>{Messages.itemsToRefund}</label>
            <AwesomeTableComponent
                className="mt-3"
                columns={columns}
                dataSource={refundRequest?.products ?? []}
                pagination={false}
            />
        </div>
    );
};

export default RefundRequestDetailItems;
