export enum NOTIFICATION_TYPE {
    NEW_COMMENT_BOOKING = "NEW_COMMENT_BOOKING",
    NEW_COMMENT_ORDER = "NEW_COMMENT_ORDER",
    NEW_COMMENT_WORKSHEET = "NEW_COMMENT_WORKSHEET",
    NEW_BOOKING = "NEW_BOOKING",
    NEW_WORKSHEET = "NEW_WORKSHEET",
    NEW_ORDER = "NEW_ORDER",
    BOOKING_SCHEDULE_SERVICE = "BOOKING_SCHEDULE_SERVICE",
    ORDER_CONFIRMED = "ORDER_CONFIRMED",
}
