import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { filter, forEach } from "lodash";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import BookingAPI from "../../../api/booking/BookingAPI";
import { PRODUCT_TYPE } from "../../../constant/product";
import { BookingCreateContext } from "../../../context/booking";
import { BookingCreateSchema } from "../../../formschema/booking";
import { mapBookingToSer } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import BookingCreateBilling from "./BookingCreateBilling";
import BookingCreateContact from "./BookingCreateContact";
import BookingCreateCustomer from "./BookingCreateCustomer";
import BookingCreateNote from "./BookingCreateNote";
import BookingCreateProducts from "./BookingCreateProducts";
import BookingCreateServiceAdd from "./BookingCreateServiceAdd";
import BookingCreateServiceDate from "./BookingCreateServiceDate";
import BookingCreateSource from "./BookingCreateSource";

const BookingCreate = () => {
    const history = useHistory();
    const [metaData, setMetaData] = useState<any[]>();

    const bookingForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BookingCreateSchema,
        validate: (values) => {
            const error: any = {};
            const serviceProducts = filter(
                values?.products,
                (item) => item.productType === PRODUCT_TYPE.SERVICE_PRODUCT
            );
            if (serviceProducts.length === 0) {
                error.values = "At least one service product";
            }
            return error;
        },
        onSubmit: (values) => {
            const input = mapBookingToSer(values);
            onCreate(input);
        },
    });

    useEffect(() => {
        loadMetaData();
    }, []);

    useEffect(() => {
        forEach(Object.keys(bookingForm.errors), (key) =>
            Notifications.showError(`${bookingForm.errors[key]}`)
        );
    }, [bookingForm.errors]);

    const onCreate = (input: any) =>
        Progress.show(
            { method: BookingAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createBookingSuccess);
                history.replace(
                    generatePath(Path.BOOKING_DETAIL, { bookingId: id })
                );
            }
        );

    const loadMetaData = () => {
        Progress.show(
            { method: BookingAPI.metaData, params: [] },
            setMetaData as any
        );
    };

    return (
        <BookingCreateContext.Provider
            value={{ bookingForm, ...metaData } as any}
        >
            <Header
                title={Messages.bookingCreate}
                onSave={() => bookingForm.handleSubmit()}
            />
            <div className="h-100 overflow-auto pb-5">
                <div className="row p-4">
                    <div className="col-8 pb-5 mb-5">
                        <BookingCreateProducts />
                        <BookingCreateNote />
                    </div>
                    <div className="col-4  ">
                        <BookingCreateSource />
                        <BookingCreateServiceDate />
                        <BookingCreateCustomer />
                        <BookingCreateContact />
                        <BookingCreateServiceAdd />
                        <BookingCreateBilling />
                    </div>
                </div>
            </div>
        </BookingCreateContext.Provider>
    );
};

export default BookingCreate;
