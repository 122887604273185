import { TimeUtils } from "d-react-components";

export const GENDERS = [
    { id: "MALE", label: "male" },
    { id: "FEMALE", label: "female" },
    { id: "NOT_SAY", label: "preferNotToSay" },
];

export const CUSTOMER_TYPES = [
    { id: "INDIVIDUAL", label: "individual" },
    { id: "BUSINESS", label: "business" },
];

export const TYPE_OF_PLACES = [
    { id: "private-house", label: "privateHouse" },
    { id: "condo-apartment", label: "condominium" },
    { id: "company-office", label: "companyOffice" },
];

export const CUSTOMER_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const CUSTOMER_STATUSES = [
    { id: CUSTOMER_STATUS.ACTIVE, label: "active", color: "#33B950" },
    { id: CUSTOMER_STATUS.INACTIVE, label: "inactive", color: "#C4C4C4" },
];

export const CUSTOMER_EXPORT_FIELDS = [
    { header: "ID", field: "customerId" },
    { header: "First name", field: "firstName" },
    { header: "Last name", field: "lastName" },
    { header: "Nickname", field: "nickName" },
    { header: "Email", field: "email" },
    { header: "Phone", field: "phone" },
    { header: "Gender", field: "gender" },
    { header: "DOB", field: "dateOfBirth" },
    { header: "Nationality", field: "nationality" },
    { header: "Customer Type", field: "typeOfCustomer" },
    { header: "Company", field: "company" },
    //lost
    { header: "Number Of Voucher", field: "numberOfVoucher" },
    { header: "Number Of Booking", field: "numberOfBooking" },
    { header: "Number Of Worksheet", field: "numberOfWorksheet" },
    { header: "Number Of Order", field: "numberOfOrder" },
    { header: "Total Spending", field: "totalSpending" },

    //ady
    { header: "Province", getValue: (item: any) => item?.province?.name },
    { header: "Status", field: "status" },
    {
        header: "Customer Since",
        getValue: (item: any) => TimeUtils.toDateTime(item.createdAt),
    },

    //lost
    { header: "Last Booking", field: "lastBooking" },
    { header: "Last Order", field: "lastOrder" },
    { header: "Last Worksheet", field: "lastWorksheet" },
];

export const CUSTOMER_EXPORT_TYPES = [
    { id: "customerInfo", label: "customerInfo" },
    { id: "ownershipVoucher", label: "ownershipVoucherSummary" },
];
