import { Switch } from "antd";
import {
    AwesomeTableComponent,
    IColumnsProps,
    TimeUtils,
} from "d-react-components";
import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import PaymentMethodAPI from "../../../api/paymentmethod/PaymentMethodAPI";
import TableAction from "../../../common/TableActions";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const PaymentMethodTable = () => {
    const history = useHistory();
    const tableRef = useRef<any>(null);

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",
            render: (item) => item[Messages.getLanguage()]?.title,
        },
        {
            title: Messages.priority,
            dataIndex: "priority",
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status, item) => (
                <Switch
                    checked={status}
                    onChange={() => onUpdateStatus(item, !status)}
                />
            ),
        },

        {
            title: Messages.updateAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (paymentMethod) => (
                <TableAction
                    dataSource={[{ id: "detail", label: "detail" }]}
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, paymentMethod)
                    }
                />
            ),
            align: "center",
        },
    ];

    const onUpdateStatus = (method: any, value: boolean) => {
        PaymentMethodAPI.quickUpdate(method?.id, { status: value }).then(() => {
            tableRef.current.refresh();
        });
    };

    const source = () => {
        return PaymentMethodAPI.list();
    };

    const onClickTableAction = (actionId: string, paymentMethod: any) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.PAYMENT_METHOD_DETAIL, {
                        paymentMethodId: paymentMethod.id,
                    })
                );
        }
    };

    return (
        <div className="p-4">
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => res?.data?.data ?? []}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
                isPagination={false}
            />
        </div>
    );
};

export default PaymentMethodTable;
