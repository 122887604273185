import { gql } from "@apollo/client";
import API from "../API";
import { F_STATIC_CONTENT } from "./StaticContentFragment";

export default {
    staticContent: (type: string) =>
        API.query({
            query: gql`
                ${F_STATIC_CONTENT}
                query staticContentByType($type: String!) {
                    data: staticContentByType(type: $type) {
                        ...F_STATIC_CONTENT
                    }
                }
            `,
            variables: { type },
            fetchPolicy: "no-cache",
        }),

    updateByType: (input: string) =>
        API.mutate({
            mutation: gql`
                ${F_STATIC_CONTENT}
                mutation updateStaticContentByType($input: StaticContentInput) {
                    data: updateStaticContentByType(input: $input) {
                        ...F_STATIC_CONTENT
                    }
                }
            `,
            variables: { input },
            fetchPolicy: "no-cache",
        }),
};
