import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";

export const F_PERMISSION = gql`
    fragment F_PERMISSION on Permission {
        key
        label
        access
    }
`;

export const F_USER_ROLE = gql`
    ${F_PERMISSION}
    fragment F_USER_ROLE on Role {
        id
        roleId
        name
        permissions {
            ...F_PERMISSION
        }
        permissionType
        createdAt
    }
`;

export const F_USER_ROLES = gql`
    ${F_USER_ROLE}
    ${F_PAGINATION}
    fragment F_USER_ROLES on Roles {
        role {
            ...F_USER_ROLE
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_USER_ITEM = gql`
    ${F_USER_ROLE}
    fragment F_USER_ITEM on User {
        id
        firstName
        lastName
        username
        avatar
        companyId
        role {
            ...F_USER_ROLE
        }
        createdAt
        lastLogin
        updatedAt
        status
        email
    }
`;

export const F_USERS = gql`
    ${F_USER_ITEM}
    ${F_PAGINATION}
    fragment F_USERS on Users {
        user {
            ...F_USER_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_USER = gql`
    ${F_USER_ROLE}
    fragment F_USER on User {
        id
        firstName
        lastName
        nickName
        username
        avatar
        role {
            ...F_USER_ROLE
        }
        companyId
        status
        email
        isOtpAuth
        otpAuthSecretCode
        otpAuthUrl
    }
`;
