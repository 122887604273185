import { DateInput } from "d-react-components";
import moment from "moment";
import Messages from "../../../languages/Messages";
import CustomerSelect from "../../customer/common/CustomerSelect";
import UserSelect from "../../user/common/UserSelect";
import BookingSourceSelect from "./BookingSourceSelect";

interface IBookingFilterForm {
    filterForm: any;
    hideCustomer?: boolean;
}

const BookingFilterForm = ({
    filterForm,
    hideCustomer,
}: IBookingFilterForm) => {
    const inputClassName = "col-6 mt-3";
    const inputFullClassName = "col-12 mt-3";
    const formValues = filterForm.values ?? {};
    const formErrors = filterForm.errors ?? {};

    return (
        <div className="row">
            <CustomerSelect
                hidden={hideCustomer}
                className={inputFullClassName}
                onChange={(value) =>
                    filterForm.setFieldValue("customer", value)
                }
                value={formValues?.customer ?? []}
                error={formErrors.customer}
                multiple
                showAddNew={false}
            />

            <BookingSourceSelect
                className={inputFullClassName}
                onChange={(value) => filterForm.setFieldValue("source", value)}
                value={formValues?.source ?? []}
                error={formErrors.source}
                multiple
            />

            <UserSelect
                className={inputFullClassName}
                onChange={(value) => filterForm.setFieldValue("PIC", value)}
                label={Messages.pic}
                value={formValues.PIC}
                multiple
            />

            <UserSelect
                className={inputFullClassName}
                onChange={(value) =>
                    filterForm.setFieldValue("createBy", value)
                }
                label={Messages.createdBy}
                value={formValues.createBy}
                multiple
            />

            <DateInput
                className={inputClassName}
                label={Messages.createdDateFrom}
                onChange={(value) =>
                    filterForm.setFieldValue("createdAtFrom", value)
                }
                value={
                    formValues.createdAtFrom
                        ? (moment(formValues.createdAtFrom) as any)
                        : null
                }
                error={formErrors.createdAtFrom as any}
            />
            <DateInput
                className={inputClassName}
                label={Messages.createdDateTo}
                onChange={(value) =>
                    filterForm.setFieldValue("createdAtTo", value)
                }
                value={
                    formValues.createdAtTo
                        ? (moment(formValues.createdAtTo) as any)
                        : null
                }
                error={formErrors.createdAtTo as any}
            />
        </div>
    );
};

export default BookingFilterForm;
