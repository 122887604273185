import { gql } from "@apollo/client";
import { F_PRODUCT } from "../product/ProductFragment";

export const F_BLOCK = gql`
    ${F_PRODUCT}
    fragment F_BLOCK on Block {
        id
        name
        type
        products {
            ...F_PRODUCT
        }
    }
`;
