import React, { useContext } from "react";
import { VoucherCreateContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import CustomerSelect from "../../customer/common/CustomerSelect";

const VoucherCreateCustomer = () => {
    const { voucherForm } = useContext(VoucherCreateContext);
    const { ownerShip } = voucherForm?.values;

    const onChangeCustomer = (customerParam: any) => {
        voucherForm.setFieldValue("ownerShip", customerParam);
    };

    return (
        <div className="card-container p-4 mt-3">
            <label className="">{Messages.customerOptional}</label>
            <div className="text-gray mb-3">
                {Messages.voucherSelectCustomerNote}
            </div>
            <CustomerSelect
                value={ownerShip}
                onChange={onChangeCustomer}
            />
        </div>
    );
};

export default VoucherCreateCustomer;
