// react
import {
    Button,
    InputText,
    InputTextPassword,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import AuthAPI from "../../api/auth/AuthAPI";
import { CONFIG } from "../../configuration/AppConfig";
import { LoginSchema } from "../../formschema/auth";
import Messages from "../../languages/Messages";
import { useSignIn } from "../../store/auth/authHook";
import Path from "../Path";
import OtpFormView from "./shared/OtpFormView";

enum LoginViewType {
    INIT = "INIT",
    LOGIN_PASSWORD = "LOGIN_PASSWORD",
    LOGIN_OTP = "LOGIN_OTP",
}

export default function AuthLogin() {
    const signIn = useSignIn();
    const [activeView, setActiveView] = useState(LoginViewType.INIT);
    const [thirdPartyAccessToken] = useQueryParam("accessToken", StringParam);

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
            accessToken: null as any,
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            Progress.show(
                { method: AuthAPI.signIn, params: [values] },
                onProcessLogin
            );
        },
    });

    useEffect(() => {
        if (!isEmpty(thirdPartyAccessToken)) {
            signIn(thirdPartyAccessToken as string);
            window.open(Path.ROOT, "_self");
        }
    }, [thirdPartyAccessToken]);

    const onProcessLogin = (res: any) => {
        const { accessToken, isOtpAuth } = res?.data.login;
        if (isOtpAuth) {
            setActiveView(LoginViewType.LOGIN_OTP);
            formik.setFieldValue("accessToken", accessToken);
        } else {
            onProcessAuthenticated(null, accessToken);
        }
    };

    const onSubmitOtp = (code: string) => {
        Progress.show(
            {
                method: AuthAPI.otpAuthenticate,
                params: [{ code, accessToken: formik?.values?.accessToken }],
            },
            onProcessAuthenticated
        );
    };

    const onProcessAuthenticated = (res?: any, token?: string) => {
        const accessToken = res?.data?.authenticate?.accessToken ?? token;
        signIn(accessToken);
        window.open(Path.ROOT, "_self");
    };

    const renderBackButton = () => {
        return (
            <Button
                size="x-small"
                className="align-self-start mb-5 px-0"
                variant="trans"
                iconName="chevron_left"
                content={Messages.back}
                onClick={() => {
                    if (activeView === LoginViewType.LOGIN_OTP) {
                        formik.setFieldValue("accessToken", null);
                        return setActiveView(LoginViewType.LOGIN_PASSWORD);
                    }
                    return setActiveView(LoginViewType.INIT);
                }}
            />
        );
    };

    const renderInitView = () => {
        return (
            <div className="">
                <a
                    href={CONFIG.WORK_INFINITY_OAUTH}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white d-flex flex-center-y px-3 py-2 w-100"
                    style={{ backgroundColor: "#041B47" }}
                >
                    <div className="bg-white p-1 rounded-1 mr-3">
                        <img
                            src="/images/login/workInfinityLogo.png"
                            alt=""
                            className="image-square-xx-small"
                        />
                    </div>

                    <div className="text font-weight-bold">
                        Log in with Work Infinity X
                    </div>
                </a>
                <div className="flex-center-y w-100 text-black my-3">
                    <div
                        className="bg-dark flex-fill mr-2"
                        style={{ height: 1 }}
                    />
                    <div className="text-small text-nowrap w-fitContent">
                        {Messages.orLoginWith}
                    </div>
                    <div
                        className="bg-dark flex-fill ml-2"
                        style={{ height: 1 }}
                    />
                </div>
                <Button
                    className="w-100"
                    variant="outline"
                    color="dark"
                    onClick={() => setActiveView(LoginViewType.LOGIN_PASSWORD)}
                >
                    {Messages.userNamePassword}
                </Button>
            </div>
        );
    };

    const renderAuthorizationView = () => {
        return (
            <div className=" w-100 ">
                <form onSubmit={formik.handleSubmit}>
                    <InputText
                        id="login-username"
                        name="username"
                        placeholder="Enter username"
                        label="User name"
                        onChange={formik.handleChange}
                        error={formik.errors.username}
                        className="mt-3"
                    />
                    <InputTextPassword
                        id="login-password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        label="Password"
                        onChange={formik.handleChange}
                        error={formik.errors.password}
                        className="mt-3"
                    />

                    <Button
                        className="mt-5 w-100"
                        onClick={() => formik.handleSubmit()}
                    >
                        Login
                    </Button>
                </form>
            </div>
        );
    };

    const renderOtpView = () => {
        return <OtpFormView onSubmitHandler={onSubmitOtp} />;
    };

    return (
        <div
            className="container-fluid d-flex justify-content-center align-items-center bg-muted"
            style={{ height: "100vh" }}
        >
            <div className="d-flex bg-white shadow">
                <img
                    alt=""
                    className="d-none d-md-block"
                    src="/images/login/login-welcome.png"
                    style={{ width: "500px", height: "500px" }}
                />
                <div
                    className="d-flex flex-column align-items-center justify-content-center bg-white p-5 w-sm-auto position-relative"
                    style={{ height: "500px", width: "450px" }}
                >
                    {activeView !== LoginViewType.INIT && renderBackButton()}
                    <img
                        src="/images/logo.png"
                        alt=""
                        className="mb-5 d-sm-block"
                        style={{ width: "100%", height: "90px" }}
                    />
                    {activeView === LoginViewType.INIT && renderInitView()}
                    {activeView === LoginViewType.LOGIN_PASSWORD &&
                        renderAuthorizationView()}
                    {activeView === LoginViewType.LOGIN_OTP && renderOtpView()}
                </div>
            </div>
        </div>
    );
}
