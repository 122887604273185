import { map } from "lodash";
import { ICategory } from "./category";
import { IUser } from "./user";

export interface IInventoryLog {
    createdAt: string;
    adjustment: number;
    newQuantity: number;
    adjustBy: IUser;
    remark: string;
}

export interface IInventoryComment {
    createdBy: IUser;
    message: string;
    createdAt: string;
}

export interface IInventory {
    id: string;
    name: string;
    SKU: string;
    productId: string;
    productGroupIds: string[];
    category: ICategory[];
    quantity: number;
    logs: IInventoryLog;
    comments: IInventoryComment;
    allAvailableCW: number;
    allOnHoldCW: number;
}

export const mapImportItemsToServer = (items: any[]) => {
    return map(items, (item) => ({
        ...item,
        stockUpdateQuantity: parseInt(item.stockUpdateQuantity, 10),
    }));
};

export const mapImportInventToServer = (input: any) => {
    const { warehouseId, itemImportList, remark, attachment } = input;
    return {
        warehouseId,
        stocks: mapImportItemsToServer(itemImportList),
        remark,
        attach: map(attachment, (item) => item.url)?.[0],
    };
};

export const mapFilterInventToServer = (filter: any) => {
    const {
        warehouseIds = [],
        productType = [],
        serviceIds = [],
        categoryIds = [],
        stockStatus,
    } = filter;
    return {
        warehouseIds: warehouseIds?.length > 0 ? warehouseIds : null,
        serviceIds: serviceIds?.length ? serviceIds : null,
        productType: productType?.length ? productType : null,
        categoryIds: categoryIds?.length ? categoryIds : null,
        stockStatus,
    };
};
