import React, { useContext } from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import { CustomerCreateContext } from "../../../context/customer";

const CustomerCreatePassword = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    return (
        <div className="card-container p-4">
            <div className="row mt-3">
                <InputTextForm
                    className="col-6"
                    keyData="password"
                    form={customerForm}
                    type="password"
                />
                <InputTextForm
                    className="col-6"
                    keyData="confirmPassword"
                    form={customerForm}
                    type="password"
                />
            </div>
        </div>
    );
};

export default CustomerCreatePassword;
