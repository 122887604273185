import React from "react";
import Messages from "../../../languages/Messages";

const WSCBookingNote = ({ note }: any) => {
    return (
        <div className="card-container p-4 mt-3 flex-column">
            <label>{Messages.bookingNotesInternalUseOnly}</label>
            <text className="mt-3">{note}</text>
        </div>
    );
};

export default WSCBookingNote;
