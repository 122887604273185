import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { forEach } from "lodash";
import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import OrderAPI from "../../../api/order/OrderAPI";
import { OrderCreateContext } from "../../../context/order";
import { OrderCreateSchema } from "../../../formschema/order";
import { useOrderPrice } from "../../../hoook/order";
import { ICustomer } from "../../../interfaces/customer";
import { IOrderCreateState, mapOrderToServer } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import OrderCreateBilling from "./OrderCreateBilling";
import OrderCreateContact from "./OrderCreateContact";
import OrderCreateLoadOrder from "./OrderCreateLoadOrder";
import OrderCreateLoadWorksheets from "./OrderCreateLoadWorksheets";
import OrderCreateNotes from "./OrderCreateNotes";
import OrderCreateProducts from "./OrderCreateProducts";
import OrderCreateSearchCustomer from "./OrderCreateSearchCustomer";
import OrderCreateServiceAdd from "./OrderCreateServiceAdd";
import OrderCreateWarehouse from "./OrderCreateWarehouse";
import OrderCreateCoSales from "./OrderCreateCoSales";

const ORDER_CREATE_INIT_VALUES = {
    isCreditNote: false,
    customProducts: [] as any[],
    productsWorksheet: [] as any[],
    products: [] as any[],
    customer: undefined as any,
    contact: undefined as any,
    addresses: [] as any[],
    billing: null as any,
    note: "" as string,
    fees: [] as any[],
    manualDiscounts: [] as any[],
    productVouchers: [] as any[],
    anonymousVouchers: [] as any[],
    worksheetIds: [] as any[],
    isInvoice: false,
    warehouseId: null as any,
    coSales: [] as any,
};

export type OrderCreateValueType = typeof ORDER_CREATE_INIT_VALUES;

const OrderCreate = () => {
    const history = useHistory();
    const [orderState, setOrderState] = useState<IOrderCreateState>({} as any);

    const orderForm = useFormik<any>({
        initialValues: {
            ...ORDER_CREATE_INIT_VALUES,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderCreateSchema,
        onSubmit: (values) => {
            const input = mapOrderToServer(values);
            onCreate(input);
        },
    });

    const orderFormValues = orderForm.values;
    const orderStatePrice = useOrderPrice(orderFormValues);

    useEffect(() => {
        forEach(Object.keys(orderForm.errors), (key) =>
            Notifications.showError(JSON.stringify(orderForm.errors[key]))
        );
    }, [orderForm.errors]);

    useEffect(() => {
        onChangeOrderState(orderStatePrice);
    }, [orderStatePrice]);

    const onChangeOrderState = (newState: any) => {
        setOrderState({ ...orderState, ...newState });
    };

    const onCreate = (input: any) => {
        Progress.show(
            { method: OrderAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createOrderSuccess);
                history.replace(
                    generatePath(Path.ORDER_DETAIL, { orderId: id })
                );
            }
        );
    };

    const onChangeCustomer = (customerValue: ICustomer) => {
        if (!customerValue) return;
        const { addressServiceDefault, firstName, lastName, email, phone } =
            customerValue;

        const contact = { firstName, lastName, email, phone };
        orderForm.setFieldValue("customer", customerValue);
        orderForm.setFieldValue("billing", { sameServiceAddress: true });
        orderForm.setFieldValue("contact", contact);
        orderForm.setFieldValue(
            "addresses",
            addressServiceDefault ? [addressServiceDefault] : []
        );
        orderForm.setFieldValue("isCreditNote", false);
    };

    return (
        <OrderCreateContext.Provider
            value={
                {
                    orderForm,
                    orderState,
                    onChangeCustomer,
                } as any
            }
        >
            <div className="h-100 overflow-auto pb-5">
                <div className="d-flex p-4 align-items-center">
                    <h4>{Messages.createOrder}</h4>
                    <OrderCreateLoadOrder />
                    <OrderCreateLoadWorksheets />
                </div>
                <div className="d-flex flex-column flex-lg-row px-3 mt-3">
                    <div className="col col-lg-8 p-0">
                        <OrderCreateProducts />
                        <OrderCreateNotes />
                        <div className="mt-3 d-flex justify-content-end">
                            <Button onClick={() => orderForm.handleSubmit()}>
                                {Messages.createOrder.toUpperCase()}
                            </Button>
                        </div>
                    </div>
                    <div className="col col-lg-4 p-0 pl-lg-3 py-0">
                        <OrderCreateWarehouse />
                        <OrderCreateCoSales />
                        <OrderCreateSearchCustomer />
                        <OrderCreateContact />
                        <OrderCreateServiceAdd />
                        <OrderCreateBilling />
                    </div>
                </div>
            </div>
        </OrderCreateContext.Provider>
    );
};

export default OrderCreate;
