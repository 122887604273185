import { AwesomeTableComponent, Button, Checkbox } from "d-react-components";
import { every, find, isEmpty, map } from "lodash";
import { useEffect, useState } from "react";
import Messages from "../../../languages/Messages";
import ObjectUtils from "../../../utils/ObjectUtils";
import { WORKSHEET_CRITERIA_CHECK_STATUS } from "../../../constant/worksheet";

export const getColumnCriteria = (criteria: any) => ({
    title: criteria.name,
    dataIndex: "criteria",
    render: (criteriaValList: any) => {
        const criteriaVal = find(
            criteriaValList,
            (item: any) => item.id === criteria.id
        );
        if (!isEmpty(criteriaVal?.value)) {
            return criteriaVal?.value;
        }
        const valueIdDisplay = find(
            criteriaVal?.values,
            (item: any) => item.id === criteriaVal.valueId
        );

        return valueIdDisplay?.value;
    },
});

export const getColumnCheck = (
    checkItem: any,
    key: string,
    onChangeCheck: any
) => {
    const onCheck = (priorCheck: any, checkItem: any) => {
        const checkItemVal = {
            ...checkItem,
            value: !checkItem.value,
        };

        const value = {
            ...priorCheck,
            [key]: ObjectUtils.updateArrayById(priorCheck[key], checkItemVal),
        };

        onChangeCheck(value);
    };
    return {
        title: checkItem.value,
        dataIndex: key,
        render: (values: any, criteriaCheck: any) => {
            const valueItem = find(values, (item) => item.id === checkItem.id);
            return (
                <Checkbox
                    disabled={criteriaCheck?.disabled}
                    checked={valueItem?.value}
                    onChange={() => onCheck(criteriaCheck, valueItem)}
                    // onChange={() =>
                    //     onChangeCheck && onChangeCheck(criteriaCheck, valueItem)
                    // }
                />
            );
        },
    };
};

interface ICriteriaCheckTable {
    criteriasCheck: any[];
    criteriaDataList: any[];
    checkDataList: any[];
    checkDataKey: string;
    isRemove?: boolean;
    onRemove?: any;
    // onChangeCheck?: any;
    onCheckAll?: any;
    onCancel?: any;
    hiddenCancel?: boolean;
}

const CriteriaCheckTable = ({
    criteriasCheck,
    criteriaDataList,
    checkDataList,
    checkDataKey,
    isRemove,
    onRemove,
    // onChangeCheck,
    onCheckAll,
    onCancel,
    hiddenCancel = false,
}: ICriteriaCheckTable) => {
    const [criteriasCheckState, setCriteriasCheck] = useState(criteriasCheck);

    useEffect(() => {
        const mappedCriteriasCheck = map(criteriasCheck, (criteria) => ({
            ...criteria,
            disabled:
                every(criteria[checkDataKey], (item) => item.value) ||
                criteria?.status === WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED,
        }));
        setCriteriasCheck(mappedCriteriasCheck);
    }, [criteriasCheck]);

    const onChangeCheck = (criteria: any) => {
        const newCriteriaCheck = ObjectUtils.updateArrayById(
            criteriasCheckState,
            criteria
        );
        setCriteriasCheck(newCriteriaCheck);
    };

    const isConfirmedAllOrigin = (criteria: any) => {
        const criteriaOrigin = find(
            criteriasCheck,
            (item) => item.id === criteria.id
        );
        const isAllChecked = every(
            criteriaOrigin?.[checkDataKey],
            (item) => item.value
        );
        return isAllChecked;
    };

    const columns = [
        {
            title: Messages.no,
            dataIndex: "",
            render: (value: any, item: any, index: number) => index + 1,
        },
        ...map(criteriaDataList, getColumnCriteria),
        ...map(checkDataList, (checkItem) =>
            getColumnCheck(checkItem, checkDataKey, onChangeCheck)
        ),

        {
            title: "",
            dataIndex: "",
            render: (value: any, criteria: any, index: number) => {
                if (
                    criteria?.status ===
                    WORKSHEET_CRITERIA_CHECK_STATUS.CANCELLED
                ) {
                    return Messages.cancelled;
                }
                const isAllCheckedOrigin = isConfirmedAllOrigin(criteria);
                const isAllCheckedState = every(
                    criteria?.[checkDataKey],
                    (item) => item.value
                );

                const isAllUnChecked = every(
                    criteria?.[checkDataKey],
                    (item) => !item.value
                );

                if (isAllUnChecked && !hiddenCancel) {
                    return (
                        <Button
                            variant="trans"
                            size="x-small"
                            onClick={() => onCancel && onCancel(criteria)}
                        >
                            {Messages.cancel}
                        </Button>
                    );
                }
                return (
                    <Button
                        variant="trans"
                        size="x-small"
                        onClick={() => onCheckAll && onCheckAll(criteria)}
                        disabled={isAllCheckedOrigin || !isAllCheckedState}
                    >
                        {Messages.confirmAll}
                    </Button>
                );
            },
        },
    ];

    if (isRemove) {
        columns.unshift({
            title: "",
            dataIndex: "",
            width: 50,
            render: (value: any, item: any) => (
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={() => onRemove && onRemove(item?.id, item)}
                />
            ),
        } as any);
    }

    return (
        <AwesomeTableComponent
            columns={columns}
            className="mt-3"
            dataSource={criteriasCheckState}
            isPagination={false}
            bordered={false}
            baseColumnProps={{ width: 100 }}
        />
    );
};

export default CriteriaCheckTable;
