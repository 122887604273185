import {
    Button,
    Checkbox,
    InputText,
    Modal,
    Notifications,
    Progress,
    UrlUtils,
} from "d-react-components";
import { filter, isEmpty, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import BookingAPI from "../../../../api/booking/BookingAPI";
import { PRODUCT_TYPE } from "../../../../constant/product";
import { WorksheetCreateContext } from "../../../../context/worksheet";
import {
    getFullAddress,
    IServiceAddress,
} from "../../../../interfaces/address";
import Messages from "../../../../languages/Messages";

interface ISelectAddressItem {
    address: IServiceAddress;
    isChecked: boolean;
    onChangeCheck: () => void;
}

const SelectAddressItem = ({
    address,
    isChecked,
    onChangeCheck,
}: ISelectAddressItem) => (
    <div className="d-flex justify-content-between border-bottom py-3">
        <div className="flex-column ">
            <div className="d-flex align-items-center">
                <text className="text-bold">{`${address.firstName} ${address.lastName}`}</text>
            </div>
            <text className="mt-1">{address.phone}</text>
            <text className="mt-1">{getFullAddress(address)}</text>
        </div>
        <Checkbox
            variant="radio"
            checked={isChecked}
            onChange={onChangeCheck}
        />
    </div>
);

const SelectAddressModal = ({ open, onClose, onSave, addressList }: any) => {
    const [addressSelect, setAddressSelect] = useState<IServiceAddress>();

    return (
        <Modal
            open={open}
            onClose={() => {}}
            title={Messages.selectServiceAddress}
            size="medium"
            onSave={() => {
                onSave(addressSelect);
                onClose();
            }}
            hasCloseIcon={false}
        >
            {map(addressList, (address: IServiceAddress) => (
                <SelectAddressItem
                    address={address}
                    onChangeCheck={() => setAddressSelect(address)}
                    isChecked={addressSelect?.id === address.id}
                />
            ))}
        </Modal>
    );
};

const WSCBookingInput = () => {
    const query = UrlUtils.getQuery();
    const { booking, setBooking, worksheetForm } = useContext(
        WorksheetCreateContext
    );
    const [bookingNo, setBookingNo] = useState<string>(
        (query?.booking as string) ?? ""
    );
    const [selectModal, setSelectModal] = useState<any>({
        open: false,
        addressList: [],
    });

    useEffect(() => {
        if (!isEmpty(bookingNo) && !booking) {
            onLoadBooking();
        }
    }, []);

    /**
     * Only load booking that have service products
     * if booking have more than 2 addresses => so modal for user to selecting
     */

    const onLoadBooking = () =>
        Progress.show(
            { method: BookingAPI.detailByBookingNo, params: [bookingNo] },
            (bookingDetail: any) => {
                const serviceProducts = filter(
                    bookingDetail?.products ?? [],
                    (item) => item?.productType === PRODUCT_TYPE.SERVICE_PRODUCT
                );
                if (serviceProducts.length === 0) {
                    Notifications.showError(
                        Messages.thisBookingNotHaveAnyService
                    );
                    return;
                }
                const formValue = {
                    ...worksheetForm.values,
                };
                if (bookingDetail?.addresses?.length > 1) {
                    setSelectModal({
                        open: true,
                        addressList: bookingDetail.addresses,
                    });
                } else {
                    formValue.address = bookingDetail?.addresses?.[0];
                }
                worksheetForm.setValues({
                    ...formValue,
                    bookingId: bookingDetail.id,
                    billingId: bookingDetail?.billing?.id,
                    PIC: bookingDetail?.PIC ?? [],
                });
                setBooking(bookingDetail as any);
                setBookingNo("");
            }
        );

    return (
        <div className="card-container p-4">
            <label>{Messages.bookingNumber}</label>
            <div className="d-flex">
                <InputText
                    className="w-100"
                    value={bookingNo}
                    onChange={(event) => setBookingNo(event.target.value)}
                />
                <Button
                    className="ml-3"
                    onClick={onLoadBooking}
                    disabled={isEmpty(bookingNo)}
                >
                    {Messages.submit}
                </Button>
                <SelectAddressModal
                    {...selectModal}
                    onClose={() =>
                        setSelectModal({ ...selectModal, open: false })
                    }
                    onSave={(address: any) =>
                        worksheetForm.setValues({
                            ...worksheetForm.values,
                            address,
                        })
                    }
                />
            </div>
        </div>
    );
};

export default WSCBookingInput;
