import { gql } from "@apollo/client";

export const F_PAYMENT_METHOD = gql`
    fragment F_PAYMENT_METHOD on PaymentMethod {
        id
        th {
            title
            description
        }
        en {
            title
            description
        }
        type
        priority
        status
        minimum
        maximum
        bankAccounts {
            id
            image
            th {
                bankName
                accountName
            }
            en {
                bankName
                accountName
            }
            accountNo
        }
        updatedAt
        merchantID
        credentialMode
    }
`;
