import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import NewsAPI from "../../../api/news/NewsAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import TableAction from "../../../common/TableActions";
import { CATEGORY_STATUSES } from "../../../constant/category";
import { NewsCategorySchema } from "../../../formschema/news";
import {
    INews,
    INewsCategory,
    mapCategoryNewsToSer,
} from "../../../interfaces/news";
import Messages from "../../../languages/Messages";

interface INewsCategoryCrudModal {
    category: INewsCategory;
    title: string;
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const NewsCategoryCrudModal = ({
    category,
    title,
    open,
    onClose,
    onSuccess,
}: INewsCategoryCrudModal) => {
    const catForm = useFormik<any>({
        initialValues: category ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: NewsCategorySchema,
        onSubmit: (values: any) => {
            const input = mapCategoryNewsToSer(values);
            if (category) {
                onUpdateNewsCategory(input);
            } else {
                onCreateNewsCategory(input);
            }
        },
    });

    const onUpdateNewsCategory = (input: any) =>
        Progress.show(
            { method: NewsAPI.categoryUpdate, params: [category?.id, input] },
            () => {
                onSuccess();
                Notifications.showSuccess(Messages.updateCategorySuccessfully);
                onClose();
            }
        );

    const onCreateNewsCategory = (input: any) =>
        Progress.show(
            { method: NewsAPI.categoryCreate, params: [input] },
            () => {
                onSuccess();
                Notifications.showSuccess(Messages.createCategorySuccessfully);
                onClose();
            }
        );

    const classNameInput = "mt-3";
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            onSave={() => catForm.handleSubmit()}
        >
            <div>
                <InputTextForm
                    keyData="name"
                    form={catForm}
                    className={classNameInput}
                />
                <InputTextForm
                    keyData="order"
                    form={catForm}
                    className={classNameInput}
                />
                <InputSelectForm
                    keyData="status"
                    dataSource={CATEGORY_STATUSES}
                    form={catForm}
                    className={classNameInput}
                />
            </div>
        </Modal>
    );
};

const NewsCategoryTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");

    const [openCrudCat, setOpenCrudCat] = useState({
        open: false,
        category: null,
    });
    const columns: IColumnsProps = [
        {
            title: Messages.title,
            dataIndex: "name",
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={CATEGORY_STATUSES}
                />
            ),
        },
        {
            title: Messages.order,
            dataIndex: "order",
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (by) => by?.firstName,
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.updateAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (category) => {
                return (
                    <TableAction
                        dataSource={[{ id: "update", label: "update" }]}
                        onClick={() => setOpenCrudCat({ open: true, category })}
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return NewsAPI.categoryList(searchRef.current, pagingData);
    };

    const onClickAddNew = () => {
        setOpenCrudCat({ open: true, category: null });
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allCategories}
                // onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.categoryNews ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 200 }}
            />
            {openCrudCat.open && (
                <NewsCategoryCrudModal
                    open={openCrudCat.open}
                    onClose={() =>
                        setOpenCrudCat({ open: false, category: null })
                    }
                    title={Messages.addCategory}
                    category={openCrudCat.category as any}
                    onSuccess={() => {
                        tableRef.current.refresh();
                    }}
                />
            )}
        </div>
    );
};

export default NewsCategoryTable;
