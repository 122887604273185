import { ObjectUtils, Select } from "d-react-components";
import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import WarehouseAPI from "../../../api/warehouse/WarehouseAPI";
import { IWarehouse } from "../../../interfaces/warehouse";
import Messages from "../../../languages/Messages";

interface IWarehouseSelect {
    className?: string;
    value: IWarehouse | IWarehouse[];
    multiple?: boolean;
    isObjectValue?: boolean;
    onChange: (value: IWarehouse | IWarehouse[]) => void;
    [key: string]: any;
}

const WarehouseSelect = ({
    value,
    multiple,
    className,
    onChange,
    isObjectValue,
    ...selectProps
}: IWarehouseSelect) => {
    const [warehouseList, setWarehouseList] = useState([]);

    useEffect(() => {
        WarehouseAPI.warehouseActiveList().then((res) => {
            setWarehouseList(res?.data?.data?.warehouse ?? []);
        });
    }, []);

    const onChangeValue = (id: any) => {
        if (!isObjectValue) {
            onChange(id);
            return;
        }
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(warehouseList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(warehouseList, id));
    };

    const warehouseValue = useMemo(() => {
        if (!isObjectValue) {
            return value;
        }
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            className={className}
            label={Messages.warehouse}
            dataSource={warehouseList}
            getLabel={(item) => item?.[Messages.getLanguage()]?.name}
            value={warehouseValue}
            onChange={onChangeValue}
            multiple={multiple}
            {...selectProps}
        />
    );
};

export default WarehouseSelect;
