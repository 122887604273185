import { PAYMENT_STATUS } from "../constant/payment";
import { IPaymentMethod } from "./paymentmethod";

export interface IPayment {
    id: string;
    paymentMethod: IPaymentMethod;
    amount: number;
    status: string;
    proofPayment: IPaymentProof[];
    confirmation: IPaymentConfirmation;
    rejection: IPaymentRejection;
    paymentRef: string;
    paymentGatewayLogs: any;
}

export interface IPaymentProof {
    id: string;
    paymentMethod: IPaymentMethod;
    nameOfPayer: string;
    bankTransferred: IBankAccount;
    dateOfPayment: string;
    slip: string;
    remark: string;
    status: string;
    transactionId: string;
}

export interface IBankAccount {
    id: string;
    image: string;
    th: IBankAccountGeneralInfo;
    en: IBankAccountGeneralInfo;
    accountNo: string;
}

export interface IBankAccountGeneralInfo {
    bankName: string;
    accountName: string;
}

export interface IPaymentConfirmation {
    id: string;
    bankAccount: IBankAccount;
    proofOfPaymentReceived: string[];
    createdAt: string;
    dateOfReceiving: string;
    remark: string;
    transactionId: string;
    confirmedBy: string;
    approvalCode: string;
}

export interface IPaymentRejection {
    remark: string;
    proofOfCancellation: string[];
    createdAt: string;
    rejectedBy: string;
}

export const mapSplitPaymentToSer = (payment: any) => {
    const { paymentMethod, amount, status, id } = payment;
    return {
        paymentId: status === PAYMENT_STATUS.CONFIRMED ? id : null,
        paymentTypeId: paymentMethod.id,
        amount,
    };
};
