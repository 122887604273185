import {
    AwesomeTableComponent,
    InputText,
    Notifications,
    ObjectUtils,
} from "d-react-components";
import { filter, find, map, reduce, uniqBy } from "lodash";
import React, { useContext, useMemo } from "react";
import { PRODUCT_TYPE } from "../../../../constant/product";
import { WorksheetCreateContext } from "../../../../context/worksheet";
import { IProduct } from "../../../../interfaces/product";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/common/ProductNameView";

const WSCBookingProducts = () => {
    const { worksheetForm, booking } = useContext(WorksheetCreateContext);
    const worksheetProducts = worksheetForm?.values?.products ?? [];
    const bookingProducts = booking?.products ?? [];
    const productsSource = useMemo(
        () =>
            filter(
                bookingProducts,
                (item: any) => item.productType === PRODUCT_TYPE.SERVICE_PRODUCT
            ),
        [bookingProducts]
    );

    const onUpdateProduct = (product: any) => {
        const pros = ObjectUtils.updateArrayById(worksheetProducts, product);
        const totalUseQuantity = reduce(
            pros,
            (sum, item) => sum + item.useQuantity,
            0
        );
        worksheetForm.setFieldValue("products", pros);
        worksheetForm.setFieldValue("totalUseQuantity", totalUseQuantity);
    };

    const columns = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (item: any) => <ProductNameView product={item} />,
            width: 200,
        },
        {
            title: Messages.totalQty,
            dataIndex: "quantity",
        },
        {
            title: Messages.useItemQty,
            dataIndex: "",
            render: (item: any) => {
                const wsProduct = find(
                    worksheetProducts,
                    (proItem: any) => proItem.id === item.id
                );
                if (!wsProduct) return "0";
                return (
                    <InputText
                        type="number"
                        value={`${wsProduct?.useQuantity}`}
                        onChange={(event) =>
                            onUpdateProduct({
                                ...wsProduct,
                                useQuantity: parseInt(event.target.value, 10),
                            })
                        }
                    />
                );
            },
        },
    ];

    const onChangeSelectProduct = (selectingProduct: IProduct[]) => {
        const uniqProductService = uniqBy(
            selectingProduct,
            (item) => item?.service?.id
        );
        if (uniqProductService.length > 1) {
            Notifications.showError(Messages.canNotProWithDiffService);
            return;
        }
        worksheetForm.setFieldValue("products", selectingProduct);
    };

    return (
        <AwesomeTableComponent
            columns={columns}
            className="mt-3"
            dataSource={productsSource}
            isPagination={false}
            bordered={false}
            baseColumnProps={{ width: 100 }}
            rowSelection={{
                selectedRowKeys: map(worksheetProducts, (item) => item.id),
                onChange: (selectedRowKeys, selectRows) => {
                    onChangeSelectProduct(selectRows);
                },
            }}
        />
    );
};

export default WSCBookingProducts;
