import {
    Button,
    Modal,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { every } from "lodash";
import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import MediaAPI from "../../api/media/MediaAPI";
import { MediaContext } from "../../context/media";
// import CustomizedUploadFileButton from "../../custom/image/CustomizedUploadFileButton";
import Messages from "../../languages/Messages";

const ModalUploadMediaFolder = ({ open, onClose, handleUpload }: any) => {
    const [mediaType, setMediaType] = useState("default");
    const [mediaUpload, setMediaUpload] = useState([]);

    const onClickSaveUpload = () => {
        // if (!mediaType) {
        //     Notifications.showError(Messages.mediaTypeIsRequire);
        //     return;
        // }

        if (mediaUpload.length === 0) {
            Notifications.showError(Messages.mediaFilesIsRequire);
            return;
        }
        const isValidName = every(mediaUpload, (mediaItem: any) =>
            StringUtils.isEnglishAlphabet(mediaItem.fileData.name)
        );
        if (!isValidName) {
            Notifications.showError(Messages.notValidNameFile);
            return;
        }
        handleUpload(mediaUpload, mediaType);
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.upload}
            onSave={onClickSaveUpload}
            className="sm-modal "
            maskClosable={false}
        >
            <div className="d-flex flex-column">
                {/* <label className="my-3">{Messages.mediaType}</label>
                <Radio.Group value={mediaType}>
                    {AppConstant.MEDIA_TYPE.map((mediaTypeParam) => (
                        <Radio
                            value={mediaTypeParam.id}
                            onClick={(e) => {
                                if (mediaTypeParam.id === mediaType) {
                                    setMediaType("");
                                } else {
                                    setMediaType(mediaTypeParam.id);
                                }
                                e.stopPropagation();
                            }}
                        >
                            {Messages[mediaTypeParam.label]}
                        </Radio>
                    ))}
                </Radio.Group> */}
                <label className="my-3">{Messages.mediaFiles}</label>
                {/* <CustomizedUploadFileButton
                    uploadImagesOnly
                    getFile={setMediaUpload}
                    isRemoveUploaded
                    onClickRemoveUploadedFile
                    isAllowNameNoExtension={false}
                /> */}
            </div>
        </Modal>
    );
};
const UploadMediaFolderButton = ({ container }: any) => {
    const { refreshMedia, folderId, loadMediaFolder } =
        useContext(MediaContext);

    const onUploadImage = (files: any) => {
        const APIUploadList = files.map((file: any) => {
            return {
                method: MediaAPI.uploadMediaToDir,
                params: [folderId, file],
            };
        });
        Progress.show(APIUploadList, () => {
            Notifications.showSuccess(Messages.uploadMediaSuccess);
            refreshMedia();
            loadMediaFolder();
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onUploadImage,
        noDragEventsBubbling: true,
    });
    const inputParam = { accept: "image/x-png,image/jpeg,image/heic" };

    return (
        <div className={container} {...getRootProps()}>
            <Button>
                {Messages.upload}
                <input {...getInputProps(inputParam)} />
            </Button>
        </div>
    );
};
export default UploadMediaFolderButton;
