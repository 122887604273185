export const ORDER_STATUS = {
    PENDING_PAYMENT: "PENDING_PAYMENT",
    PENDING_CONFIRMATION: "PENDING_CONFIRMATION",
    PARTIALLY_PAID: "PARTIALLY_PAID",
    ORDER_PROCESSING: "ORDER_PROCESSING",
    DELIVERY_PROCESSING: "DELIVERY_PROCESSING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    EXPIRED: "EXPIRED",
};

export const ORDER_STATUSES = [
    { id: "PENDING_PAYMENT", label: "pending", color: "#FAC256" },
    {
        id: "PENDING_CONFIRMATION",
        label: "pendingConfirmation",
        color: "#FAC256",
    },
    { id: "PARTIALLY_PAID", label: "partiallyPaid", color: "#FAC256" },
    { id: "ORDER_PROCESSING", label: "processing", color: "#84C4FF" },
    {
        id: "DELIVERY_PROCESSING",
        label: "deliveryProcessing",
        color: "#84C4FF",
    },
    { id: "COMPLETED", label: "completed", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
    { id: "EXPIRED", label: "expired", color: "#FF1100" },
];

export const ORDER_FEES = [
    { id: "transportation", label: "transportationFee" },
];

export const ORDER_PAYMENT_STATUS = {
    PENDING_PAYMENT: "PENDING_PAYMENT",
    FULLY_CONFIRMED: "FULL_PAYMENT_CONFIRMED",
    FULLY_REJECTED: "PAYMENT_FULLY_REJECTED",
    PARTIALLY_REJECTED: "PAYMENT_PARTIALLY_REJECTED",
    PARTIALLY_CONFIRMED: "PAYMENT_PARTIALLY_CONFIRMED",
};

export const ORDER_PAYMENT_STATUSES = [
    {
        id: ORDER_PAYMENT_STATUS.PENDING_PAYMENT,
        label: "pendingPayment",
        color: "#FAC256",
    },
    {
        id: ORDER_PAYMENT_STATUS.FULLY_CONFIRMED,
        label: "paymentFullyConfirmed",
        color: "#33B950",
    },
    {
        id: ORDER_PAYMENT_STATUS.FULLY_REJECTED,
        label: "paymentFullyRejected",
        color: "#FF1100",
    },
    {
        id: ORDER_PAYMENT_STATUS.PARTIALLY_REJECTED,
        label: "paymentPartiallyRejected",
        color: "#FAC256",
    },
    {
        id: ORDER_PAYMENT_STATUS.PARTIALLY_CONFIRMED,
        label: "paymentPartiallyConfirm",
        color: "#84C4FF",
    },
];

export const ORDER_EXPORT_OPTIONS = [
    { id: "reportByPrice", label: "reportByPrice" },
];

export const ORDER_REPORT_BY_ITEM_FIELDS = [
    { header: "Order Id", field: "orderNo" },
    { header: "Sale Person ID", field: "salePersonId" },
    { header: "Sale Person", field: "salePerson" },
    { header: "Bank Account No.", field: "bankAccountNo" },
    { header: "Channel payment", field: "paymentMethod" },
    { header: "Slip Uploaded Date", field: "slipUploadedDate" },
    { header: "Slip Uploaded Time", field: "slipUploadedTime" },
    {
        header: "Actual bank of receiving payment",
        field: "bankReceivingPayment",
    },
    {
        header: "Actual date of receiving payment",
        field: "dateReceivingPayment",
    },
    {
        header: "Actual time of receiving payment",
        field: "timeReceivingPayment",
    },
    { header: "Order status", field: "orderStatus" },
    { header: "Order note", field: "orderNote" },
    { header: "Customer name", field: "customer" },
    { header: "SKU/Barcode", field: "sku" },
    { header: "Product name", field: "productName" },
    { header: "Created date", field: "createdDate" },
    { header: "Confirmed date", field: "confirmedDate" },
    { header: "Delivery type", field: "deliveryType" },
    { header: "Quantity", field: "quantity" },
    { header: "Unit", field: "unit" },
    { header: "Unit price", field: "unitPrice" },
    { header: "Line amount", field: "lineAmount" },
    { header: "Total discount (THB)", field: "totalDiscount" },
    { header: "SKU discount", field: "totalSkuDiscount" },
    { header: "Final price", field: "finalPrice" },
];
