import {
    AwesomeTableComponent,
    IColumnsProps,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { find, map } from "lodash";
import { useContext, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import OrderAPI from "../../../api/order/OrderAPI";
import Image from "../../../common/Image";
import { ORDER_FEES } from "../../../constant/order";
import { PRODUCT_TYPE } from "../../../constant/product";
import { OrderDetailContext } from "../../../context/order";
import {
    IOrder,
    IOrderProduct,
    IOrderProductVoucher,
} from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import OnHoldAllocationModal from "../../inventory/common/OnHoldAllocationModal";
import Path from "../../Path";
import { ProductSearchModal } from "../../product/common/ProductSearchButton";

const ProductOnHold = ({ productId }: any) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { warehouse, id } = orderDetail as IOrder;
    const [open, setOpen] = useState(false);

    return (
        <>
            <small
                className="ml-1 dot-view text-white bg-primary cursor-pointer text-no-wrap"
                onClick={() => setOpen(true)}
            >
                {Messages.onHold}
            </small>
            {open && (
                <OnHoldAllocationModal
                    // warehouseId={warehouse?.id}
                    orderId={id}
                    productId={productId}
                    open={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    );
};

const MapProduct = ({ product }: { product: IOrderProduct }) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const [openSearch, setOpenSearch] = useState(false);
    const isMapped = !!product?.productId;

    const onMapProduct = ([mapProduct]: any) =>
        Progress.show(
            {
                method: OrderAPI.mapCustomProduct,
                params: [orderDetail?.id, product.id, mapProduct?.id],
            },
            (order: any) => {
                setOpenSearch(false);
                Notifications.showSuccess(Messages.mapCustomProductSuccess);
                setOrderDetail(order);
            }
        );

    return (
        <text>
            {isMapped && (
                <small className="ml-1 dot-view text-white bg-primary cursor-pointer text-no-wrap">
                    {Messages.mapped}
                </small>
            )}
            <small
                className="ml-1 dot-view text-white bg-primary cursor-pointer text-no-wrap"
                onClick={() => setOpenSearch(true)}
                hidden={product?.shippedQty > 0}
            >
                {isMapped ? Messages.reMap : Messages.map}
            </small>
            {openSearch && (
                <ProductSearchModal
                    open={openSearch}
                    onClose={() => setOpenSearch(false)}
                    filterSource={{
                        productType: [PRODUCT_TYPE.DEFAULT_PRODUCT],
                    }}
                    onSave={onMapProduct}
                    multiple={false}
                />
            )}
        </text>
    );
};

export const OrderDetailProductView = ({
    product,
}: {
    product: IOrderProduct;
}) => {
    const {
        thumbnail,
        SKU,
        name,
        id,
        onHold,
        productType,
        worksheet,
        isCustom,
        productId,
    } = product;

    const displayOnHold =
        onHold &&
        productType !== PRODUCT_TYPE.PREMIUM_SERVICE &&
        productType !== PRODUCT_TYPE.SERVICE_PRODUCT;

    return (
        <div className="d-flex">
            <Image src={thumbnail} className="image-square-small" />

            <div className="flex-column ml-2">
                <text className="d-block">
                    <a
                        className="h5"
                        href={generatePath(Path.PRODUCT_DETAIL, {
                            productId: productId ?? "productId",
                        })}
                    >
                        {name}
                    </a>
                    {displayOnHold && <ProductOnHold productId={productId} />}
                    {isCustom && <MapProduct product={product} />}
                </text>

                <small>
                    {Messages.sku}: {SKU}
                </small>
                <div className="small text-warning" hidden={!worksheet}>
                    {`${Messages.worksheet} #${worksheet?.worksheetNo}`}
                </div>
            </div>
        </div>
    );
};

const OrderDetailProducts = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { products, subTotal, total, manualCode, fees, productVouchers } =
        orderDetail as IOrder;
    const tableRef = useRef<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.no,
            dataIndex: "",
            render: (item, pro, index) => index + 1,
        },
        {
            title: Messages.itemName,
            dataIndex: "",
            width: 300,
            render: (product) => <OrderDetailProductView product={product} />,
        },
        // {
        //     title: Messages.unitPrice,
        //     dataIndex: "salePrice",
        //     render: StringUtils.moneyThaiFormat,
        // },
        {
            title: Messages.salePrice,
            dataIndex: "salePriceAtCreated",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.openingPrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.qnty,
            dataIndex: "quantity",
        },
        {
            title: Messages.price,
            dataIndex: "salePrice",
            render: (salePrice, item) =>
                StringUtils.moneyThaiFormat(salePrice * item.quantity),
            width: 150,
            align: "right",
        },
    ];

    const renderSummaryRow = (title: any, content: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center border-bottom py-3 pr-2">
                <div>{title}</div>
                <div>{content}</div>
            </div>
        );
    };

    const renderSummaryPriceRow = (title: any, price: number) => {
        return renderSummaryRow(title, StringUtils.moneyThaiFormat(price));
    };

    const renderSummaryVoucherRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            <text className="text-success flex-row">
                {`- ${StringUtils.moneyThaiFormat(price)}`}
            </text>
        );
    };

    const renderOrderSummary = () => (
        <div className=" d-flex justify-content-end">
            <div className="w-50">
                {renderSummaryPriceRow(Messages.subTotal, subTotal)}
                {map(productVouchers, (productVoucher: IOrderProductVoucher) =>
                    renderSummaryVoucherRow(
                        `Voucher (${productVoucher?.voucher?.code})`,
                        productVoucher?.voucher?.product?.salePrice
                    )
                )}
                {map(manualCode, (manualDiscount: any) =>
                    renderSummaryVoucherRow(
                        `Manual discount (${manualDiscount?.code})`,
                        manualDiscount?.discount
                    )
                )}

                {map(fees, (fee: any) => {
                    const orderFee = find(
                        ORDER_FEES,
                        (item) => item.id === fee.type
                    );
                    return renderSummaryPriceRow(
                        (Messages as any)[orderFee?.label as string],
                        fee?.value
                    );
                })}

                {renderSummaryRow(
                    <div className="text-bold">{Messages.total}</div>,
                    <div className="text-bold">
                        {StringUtils.moneyThaiFormat(total)}
                    </div>
                )}
                <div
                    className="p-3 text w-100 bg-note"
                    hidden={!orderDetail.isInvoice}
                >
                    This customer requested the tax invoice
                </div>
            </div>
        </div>
    );
    return (
        <div className="card-container p-4">
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={products}
                bordered={false}
                baseColumnProps={{ width: 100 }}
                pagination={false}
                rowKey={(item) => `${item?.id}-${item?.worksheet?.id}`}
            />
            {renderOrderSummary()}
        </div>
    );
};

export default OrderDetailProducts;
