import { Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";

interface IVoucherCancelModal {
    voucherId: string;
    open: boolean;
    onClose: () => void;
    onSuccess?: (res: any) => void;
}

function VoucherCancelModal({
    voucherId,
    open,
    onClose,
    onSuccess,
}: IVoucherCancelModal) {
    const cancelForm = useFormik<any>({
        initialValues: {
            id: voucherId,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onCancelVoucher(values);
        },
    });

    const onCancelVoucher = (params: any) => {
        Progress.show(
            { method: VoucherAPI.cancel, params: [params] },
            (res: any) => {
                onClose();
                Notifications.showSuccess(Messages.cancelVoucherSuccess);
                onSuccess && onSuccess(res);
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.cancel}
            onSave={() => cancelForm.handleSubmit()}
        >
            <div className="row">
                <div className="col-12 small">
                    You are going to cancel this voucher. Check carefully and
                    confirm one more time to make sure of your action. Please
                    note that this action cannot be undone.
                </div>
                <InputTextForm
                    form={cancelForm}
                    keyData="remark"
                    className="col-12 mt-3"
                    multiple
                />
            </div>
        </Modal>
    );
}

export default VoucherCancelModal;
