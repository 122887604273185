import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import InputTextForm from "../../../common/input/InputTextForm";
import { CustomerPasswordSchema } from "../../../formschema/customer";
import Messages from "../../../languages/Messages";

const CustomerDetailPassword = () => {
    const { customerId } = useParams<any>();

    const passForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values) => {
            const error: any = {};

            if (values.password !== values.confirmPassword) {
                error.confirmPassword = Messages.passwordNotMatch;
            }

            return error;
        },
        validationSchema: CustomerPasswordSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: CustomerAPI.updatePassword,
                    params: [customerId, values],
                },
                () => {
                    Notifications.showSuccess(Messages.updatePasswordSuccess);
                }
            );
        },
    });
    return (
        <div className="card-container p-4">
            <div className="flex-row-between-center">
                <label>{Messages.updatePassword}</label>
                <Button onClick={() => passForm.handleSubmit()} variant="trans">
                    {Messages.save}
                </Button>
            </div>
            <div className="row mt-3">
                <InputTextForm
                    className="col-6"
                    keyData="password"
                    form={passForm}
                    type="password"
                />
                <InputTextForm
                    className="col-6"
                    keyData="confirmPassword"
                    form={passForm}
                    type="password"
                />
            </div>
        </div>
    );
};

export default CustomerDetailPassword;
