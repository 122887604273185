import {
    Button,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { map, toUpper } from "lodash";
import React, { useContext, useState } from "react";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";
import UserAvatarName from "../../user/common/UserAvatarName";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import Image from "../../../common/Image";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    REFUND_STATUS,
    REFUND_STATUSES,
} from "../../../constant/refundRequest";
import { RefundDetailContext } from "../../../context/refundRequest";
import { RefundCompleteSchema } from "../../../formschema/refundRequest";
import Messages from "../../../languages/Messages";

interface IRefundConfirmModal {
    open: boolean;
    onClose: () => void;
}

const RefundCompleteModal = ({ open, onClose }: IRefundConfirmModal) => {
    const { refundRequest, setRefundRequest } = useContext(RefundDetailContext);

    const completeForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RefundCompleteSchema,
        onSubmit: (values: any) => {
            const input = {
                remark: values?.remark,
                attachments: map(values?.attachments, (item) => item.url),
            };
            Progress.show(
                {
                    method: RefundRequestAPI.completeRefund,
                    params: [refundRequest?.id, input],
                },
                (order: any) => {
                    setRefundRequest(order);
                    onClose();
                    Notifications.showSuccess(Messages.completeRefundSuccess);
                }
            );
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.completeRefund}
            onSave={() => completeForm.handleSubmit()}
        >
            <div>
                <label>{Messages.attachments}</label>
                <ButtonFileUpload
                    onChange={(value: any) =>
                        completeForm.setFieldValue("attachments", value)
                    }
                    maxFiles={5}
                    className="mt-3"
                />
                <InputTextForm
                    form={completeForm}
                    keyData="remark"
                    multiple
                    className="mt-3"
                />
            </div>
        </Modal>
    );
};

const RefundRequestDetailAccounting = () => {
    const [openComplete, setOpenComplete] = useState(false);
    const { refundRequest } = useContext(RefundDetailContext);
    const { status, completeRefund } = refundRequest;

    const renderInfoItem = (label: string, content: any) => {
        return (
            <div className="d-flex border-bottom py-2">
                <div className="text-disabled w-50">{label}</div>
                <div className="text-grey w-50">{content}</div>
            </div>
        );
    };

    if (status === REFUND_STATUS.REJECTED || status === REFUND_STATUS.PENDING) {
        return <div />;
    }
    return (
        <div className="mt-3 card-container p-3">
            <label>{Messages.directorApproval}</label>
            {renderInfoItem(
                Messages.status,
                <ViewLabelStatus
                    className="ml-3"
                    status={status}
                    listStatus={REFUND_STATUSES}
                    getLabel={(item) => toUpper((Messages as any)[item.label])}
                />
            )}

            {completeRefund &&
                renderInfoItem(
                    Messages.confirmedAt,
                    TimeUtils.toDateTime(completeRefund?.createdAt)
                )}

            {completeRefund &&
                renderInfoItem(
                    Messages.confirmBy,
                    <UserAvatarName user={completeRefund?.createBy} />
                )}

            {completeRefund &&
                renderInfoItem(Messages.remark, completeRefund?.remark)}

            {completeRefund &&
                renderInfoItem(
                    Messages.attachments,
                    <div className="d-flex">
                        {map(completeRefund?.attachments, (item) => (
                            <Image
                                src={item}
                                className="mr-3 image-reg-medium"
                            />
                        ))}
                    </div>
                )}

            {!completeRefund &&
                renderInfoItem(
                    Messages.action,
                    <Button onClick={() => setOpenComplete(true)}>
                        {Messages.confirm}
                    </Button>
                )}

            {openComplete && (
                <RefundCompleteModal
                    open={openComplete}
                    onClose={() => setOpenComplete(false)}
                />
            )}
        </div>
    );
};

export default RefundRequestDetailAccounting;
