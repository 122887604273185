/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import classNames from "classnames";
import {
    Button,
    Drawer,
    IDrawerProps,
    Icon,
    InputText,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, includes, isEmpty, map, some } from "lodash";
import React, {
    Dispatch,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import AuthCode from "react-auth-code-input";
import AuthAPI from "../../../../api/auth/AuthAPI";
import VoucherAPI from "../../../../api/voucher/VoucherAPI";
import { PRODUCT_TYPE } from "../../../../constant/product";
import {
    VOUCHER_STATUS,
    VoucherOwnershipType,
} from "../../../../constant/voucher";
import {
    VERIFY_VOUCHER_METHOD,
    VERIFY_VOUCHER_METHODS,
} from "../../../../constant/worksheet";
import { AuthOtpSchema } from "../../../../formschema/auth";
import { useCountDown } from "../../../../hoook/app";
import { useAppliedVouchersProductList } from "../../../../hoook/voucher";
import { ICustomer } from "../../../../interfaces/customer";
import {
    IProduct,
    IProductOrderCreate,
    getProductLocalizeInfo,
} from "../../../../interfaces/product";
import {
    IVoucher,
    IVoucherOrderCreate,
    IVoucherProduct,
} from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/common/ProductNameView";
import VoucherProductItem from "./VoucherProductItem";
import Image from "../../../../common/Image";
import ObjectUtils from "../../../../utils/ObjectUtils";

export interface IVoucherSelectState
    extends Partial<IOrderVoucherSelectDrawerProps> {
    activeScreen?: VoucherSelectScreenType | null;
    productVouchers?: Array<IVoucherProduct>;
    setProductVouchers: Dispatch<Array<IVoucherProduct>>;

    anonymousVouchers?: IVoucher[];
    appliedVoucherProducts?: {
        [proId: string]: { quantity?: number; usedQty?: number };
    };
    products?: IProductOrderCreate[];
    verifyMethod: string;
    setVerifyMethod: Dispatch<any>;
    setActiveScreen: Dispatch<VoucherSelectScreenType>;
    setAnonymousVouchers?: Dispatch<Array<IVoucher>>;
}

const voucherSelectState: IVoucherSelectState = {
    customer: null as any,
    activeScreen: null,
    verifyMethod: "",
    setVerifyMethod: () => {},
    setActiveScreen: () => {},
    setProductVouchers: () => {},
    setAnonymousVouchers: () => {},
};

export const VoucherSelectContext = React.createContext(voucherSelectState);

export interface IOrderVoucherSelectDrawerProps extends IDrawerProps {
    inputType: VoucherOwnershipType;
    customer: ICustomer;
    voucherSelected?: IVoucherOrderCreate[];
    products?: IProductOrderCreate[];
    onChange?: (
        productVouchers: IOrderVoucherSelectDrawerProps["voucherSelected"],
        anonymousVouchers: any[]
    ) => any;
}

export interface IProductApplyVoucher extends IProduct {
    availableVouchers?: IVoucherProduct[];
    availableVouchersQty?: number;
    quantity?: number;
}

export enum VoucherSelectScreenType {
    ANONYMOUS_VOUCHER_INPUT = "ANONYMOUS_VOUCHER_INPUT",
    SELECT_VOUCHER = "SELECT_VOUCHER",
    SELECT_VERIFY_METHOD = "SELECT_VERIFY_METHOD",
    APPLY_CODE = "APPLY_CODE",
}

const VoucherAuthorizationCodeInput = () => {
    const [timeLeft, { start }] = useCountDown(60 * 1000, 1000);

    const {
        verifyMethod,
        setActiveScreen,
        onClose,
        onChange,
        customer,
        productVouchers,
        anonymousVouchers,
        products,
    } = useContext(VoucherSelectContext);
    const verifyForm = useFormik<any>({
        initialValues: {
            code: undefined,
            otpToken: "",
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AuthOtpSchema,
        onSubmit: (values: any) => {
            const input = {
                customerId: customer?.id,
                code: values?.code,
                otpToken: values?.otpToken,
                verifyType: verifyMethod,
            };
            Progress.show(
                {
                    method: AuthAPI.verifyOtpAuthGrantVoucherOwnership,
                    params: [input],
                },
                onProcessAuthenticated,
                () => {
                    verifyForm.setFieldValue("code", "");
                }
            );
        },
    });

    useEffect(() => {
        verifyMethod === VERIFY_VOUCHER_METHOD.SMS_OTP && requestSmsOtp();
    }, [verifyMethod]);

    const requestSmsOtp = () => {
        if (timeLeft > 0) return;
        AuthAPI.requestSendOtp({
            phone: customer?.phone,
        }).then((res) => {
            const tokenRes = res?.data?.data;
            if (!isEmpty(tokenRes?.token)) {
                verifyForm.setFieldValue("otpToken", tokenRes?.token);
                start();
            }
        });
    };

    const onProcessAuthenticated = (res: any) => {
        const isValid = res?.data?.data;
        if (isValid) {
            const productVoucherValid = filter(productVouchers, (item) => {
                return item?.quantity && item?.quantity > 0;
            });
            onChange &&
                onChange(productVoucherValid as any, anonymousVouchers as any);
            onClose && onClose(res);
        } else {
            Notifications.showError(Messages.codeIsInvalid);
        }
    };

    const renderAdminTokenInput = () => {
        return (
            <div className="p-3">
                <div className="my-3">{Messages.enterDigitAdminToken}</div>
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
            </div>
        );
    };
    const renderCustomerAppOtpInput = () => {
        return (
            <div className="p-3">
                <div className="my-3">{Messages.enterDigitAdminToken}</div>
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
            </div>
        );
    };

    const renderCustomerSmsOtp = () => {
        return (
            <div className="p-3">
                <div
                    className="my-3"
                    dangerouslySetInnerHTML={{
                        __html: `<div>${Messages.inputDigitPhoneNumber?.replace(
                            "%phoneNumber",
                            `<text style='color:rgba(244,67,54,1)' >${customer?.phone}</text>`
                        )}</div>`,
                    }}
                />
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
                <div className="flex-column flex-center mt-3">
                    <div>{Messages.didNotReceiveTheCode}</div>
                    <div
                        className={classNames(
                            "text mt-1 text-primary cursor-pointer",
                            {
                                "text-disabled": timeLeft > 0,
                            }
                        )}
                        onClick={requestSmsOtp}
                    >
                        {Messages.resendNowSecond.replace(
                            "%second",
                            `${timeLeft / 1000}`
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderCodeInput = () => {
        switch (verifyMethod) {
            case VERIFY_VOUCHER_METHOD.ADMIN_TOKEN:
                return renderAdminTokenInput();
            case VERIFY_VOUCHER_METHOD.CUSTOMER_APP:
                return renderCustomerAppOtpInput();
            case VERIFY_VOUCHER_METHOD.SMS_OTP:
                return renderCustomerSmsOtp();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="flex-center-y w-100 border-bottom">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.authorizationCode}
                </div>
            </div>
            {renderCodeInput()}

            <div className="flex-center-y position-absolute w-100 p-3 border-top start-0 end-0 bottom-0 justify-content-between">
                <Button
                    content={Messages.back}
                    className=""
                    variant="outline"
                    onClick={() => {
                        //@ts-ignore
                        return setActiveScreen(
                            VoucherSelectScreenType.SELECT_VERIFY_METHOD
                        );
                    }}
                />
                <Button
                    content={Messages.apply}
                    className=""
                    onClick={() => {
                        verifyForm.handleSubmit();
                    }}
                />
            </div>
        </div>
    );
};

const VoucherSelectList = () => {
    const { productVouchers, setProductVouchers, products } =
        useContext(VoucherSelectContext);

    const onChangeItem = (itemToUpdate: IVoucherProduct) => {
        const updateList = map(productVouchers, (v) =>
            v?.product?.id === itemToUpdate?.product?.id ? itemToUpdate : v
        );
        setProductVouchers(updateList);
    };

    const renderItem = ({ item, index }: any) => {
        return (
            <VoucherProductItem
                voucherProduct={item as any}
                showQtyInput
                // inputQuantityProps={{ max: maxQty }}
                onChange={(voucher) => {
                    onChangeItem(voucher as any);
                }}
            />
        );
    };

    const renderListVoucher = () => {
        return (
            <div>
                <div className="flex-center-y w-100 border-bottom py-3">
                    <div className="h4 text-primary w-100 text-center">
                        {Messages.selectVoucher}
                    </div>
                </div>
                {map(products, (product) => (
                    <ProductNameView
                        className="flex-row mt-2"
                        classNameContent="bg-primary w-100 text-white mx-0 p-3"
                        styleImage={{ height: "auto", width: "100px" }}
                        classNameLabel="text-white"
                        product={product}
                        subContent={
                            <small>
                                {`${Messages.quantity}: ${product?.quantity}`}
                            </small>
                        }
                    />
                ))}

                <div>
                    {map(productVouchers, (item, index) =>
                        renderItem({ item, index })
                    )}
                </div>
            </div>
        );
    };

    return <React.Fragment>{renderListVoucher()}</React.Fragment>;
};

const VoucherInput = () => {
    const { anonymousVouchers, setAnonymousVouchers, products, customer } =
        useContext(VoucherSelectContext);
    const [voucherCode, setVoucherCode] = React.useState("");

    const onCheckAndAddVoucher = () => {
        const allCodes = map(anonymousVouchers, (i) => i?.code);
        if (allCodes.includes(voucherCode)) {
            return Notifications.showError(Messages.codeIsAlreadyApplied);
        }
        return Progress.show(
            {
                method: VoucherAPI.checkVouchers,
                params: [[voucherCode]],
            },
            (voucherRes: any) => {
                const voucher: IVoucher = voucherRes[0];
                const voucherCustomerId = voucher?.ownership?.customer?.id;
                if (voucherCustomerId && voucherCustomerId !== customer?.id) {
                    return Notifications.showError(Messages.voucherIsInvalid);
                }
                if (
                    voucher?.product?.productType !==
                    PRODUCT_TYPE.PREMIUM_SERVICE
                ) {
                    return Notifications.showError(
                        Messages.onlyAcceptPremiumVoucher
                    );
                }

                const premiumProductIds = map(
                    voucher?.product?.premiumService,
                    (item) => item.id
                );
                const isIncludeOrderProduct = some(products, (product) =>
                    includes(premiumProductIds, product.id)
                );
                if (!isIncludeOrderProduct) {
                    return Notifications.showError(
                        Messages.premiumVoucherNoIncludeProduct
                    );
                }
                setAnonymousVouchers &&
                    setAnonymousVouchers([
                        ...(anonymousVouchers ?? []),
                        voucher,
                    ]);
                setVoucherCode("");
            }
        );
    };

    const onRemove = (voucher: IVoucher) => {
        const voucherList = ObjectUtils.removeItemFromId(
            anonymousVouchers,
            voucher?.id
        );
        setAnonymousVouchers && setAnonymousVouchers(voucherList as any);
    };

    const renderVoucherItem = (voucher: IVoucher) => {
        const { product, code } = voucher;
        return (
            <div className="d-flex flex-row card-container mt-3 position-relative">
                <Image
                    src={product?.thumbnail?.thumbnail}
                    className="image-square-large"
                />
                <div className="p-3">
                    <div>{code}</div>
                    <div>{getProductLocalizeInfo(product, "name")}</div>
                    <div>{`${Messages.sku}: ${product.SKU}`}</div>
                </div>
                {/* <Icon
                    name="delete"
                    className="position-absolute top-3 right-3"
                    color="primary"
                /> */}
                <div
                    className={classNames(
                        "position-absolute top-0 end-0 bg-primary flex-center cursor-default"
                    )}
                    style={{ width: "35px", height: "35px" }}
                    onClick={() => onRemove && onRemove(voucher)}
                >
                    <Icon name="delete" color="white" />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="flex-center-y w-100 border-bottom py-3">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.selectAnonymousVoucher}
                </div>
            </div>
            <div className="p-3">
                <div className="mb-3">{Messages.voucherInputDescription}</div>
                <div className="d-flex flex-row">
                    <InputText
                        placeholder={Messages.enterVoucherCode}
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e?.target?.value)}
                        className="flex-grow-1"
                    />
                    <Button onClick={onCheckAndAddVoucher} className="ml-3">
                        {Messages.apply}
                    </Button>
                </div>
                <div>{map(anonymousVouchers, renderVoucherItem)}</div>
            </div>
        </div>
    );
};

const VoucherVerifyMethodSelect = () => {
    const { setActiveScreen, verifyMethod, setVerifyMethod, customer } =
        useContext(VoucherSelectContext);

    const verifyMethodObject = find(
        VERIFY_VOUCHER_METHODS,
        (item) => item.id === verifyMethod
    );

    const verifyMethodDesc = verifyMethodObject?.description;
    return (
        <div>
            <div className="flex-center-y w-100 border-bottom">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.authorization}
                </div>
            </div>
            <div className="p-3">
                <Select
                    label={Messages.verificationMethod}
                    dataSource={VERIFY_VOUCHER_METHODS}
                    onChange={setVerifyMethod}
                    value={verifyMethod}
                />
                {verifyMethodDesc && (
                    <div
                        className="mt-2 small"
                        dangerouslySetInnerHTML={{
                            __html: `<div>${(Messages as any)[
                                verifyMethodDesc
                            ]?.replace(
                                "%phoneNumber",
                                `<text style='color:rgba(244,67,54,1)' >${customer?.phone}</text>`
                            )}</div>`,
                        }}
                    />
                )}
            </div>
            <div className="flex-center-y position-absolute w-100 p-3 border-top start-0 end-0 bottom-0 justify-content-between">
                <Button
                    content={Messages.back}
                    variant="outline"
                    onClick={() => {
                        setActiveScreen(VoucherSelectScreenType.SELECT_VOUCHER);
                    }}
                />
                <Button
                    content={Messages.continue}
                    onClick={() => {
                        setActiveScreen(VoucherSelectScreenType.APPLY_CODE);
                    }}
                />
            </div>
        </div>
    );
};

const OrderVoucherSelectDrawer: React.FC<IOrderVoucherSelectDrawerProps> = ({
    open,
    inputType,
    onClose,
    customer,
    products = [],
    voucherSelected,
    onChange,
}) => {
    const [activeScreen, setActiveScreen] = useState<VoucherSelectScreenType>(
        () => {
            if (inputType === VoucherOwnershipType.OWNER_SHIP) {
                return VoucherSelectScreenType.SELECT_VOUCHER;
            }
            return VoucherSelectScreenType.ANONYMOUS_VOUCHER_INPUT;
        }
    );
    const [verifyMethod, setVerifyMethod] = useState<string>(
        VERIFY_VOUCHER_METHOD.CUSTOMER_APP
    );

    const productIds: string[] = map(products, (pro) => pro?.id);

    const [productVouchers, setProductVouchers] = useState<IVoucherProduct[]>(
        []
    );

    const [anonymousVouchers, setAnonymousVouchers] = useState<IVoucher[]>([]);

    const appliedVoucherProducts = useAppliedVouchersProductList(
        {
            products: products || [],
            vouchers: voucherSelected || [],
        },
        [productIds, voucherSelected]
    );
    const enableSaveVoucherButton = useMemo(() => {
        if (
            !isEmpty(productVouchers) &&
            some(productVouchers, (item) => {
                return item?.quantity && item?.quantity > 0;
            })
        ) {
            return true;
        }
        if (anonymousVouchers?.length) {
            return true;
        }
        return false;
    }, [productVouchers, voucherSelected, anonymousVouchers]);

    useEffect(() => {
        if (customer?.id) {
            loadVoucherList();
        }
    }, [customer?.id]);

    const loadVoucherList = () => {
        return Progress.show(
            {
                method: VoucherAPI.listProductVoucher,
                params: [
                    {},
                    {
                        customerIds: [customer?.id],
                        status: [
                            VOUCHER_STATUS.AVAILABLE,
                            VOUCHER_STATUS.EXPIRING,
                        ],
                    },
                ],
            },
            (res: any) => {
                const list: Array<IVoucherProduct> =
                    res?.data?.data?.products ?? [];

                const productIds = map(products, (item) => item.id);

                // filter products voucher that include in order's products
                const productVouchers = filter(list, (productVoucher) => {
                    // if (includes(productIds, productVoucher?.product?.id)) {
                    //     return true;
                    // }

                    const isPremiumPro =
                        productVoucher?.product?.productType ===
                        PRODUCT_TYPE.PREMIUM_SERVICE;
                    if (isPremiumPro) {
                        const isIncludePro = some(
                            productIds,
                            (productId, index) => {
                                const premiumServiceIds = map(
                                    productVoucher?.product?.premiumService,
                                    (item) => item.id
                                );
                                const isDefaultProduct =
                                    products[index].productType ===
                                    PRODUCT_TYPE.DEFAULT_PRODUCT;
                                return (
                                    isDefaultProduct &&
                                    includes(premiumServiceIds, productId)
                                );
                            }
                        );
                        return isIncludePro;
                    }

                    return false;
                });

                setProductVouchers(productVouchers);
            }
        );
    };

    const renderFooterDrawer = () => {
        if (
            activeScreen === VoucherSelectScreenType.APPLY_CODE ||
            activeScreen === VoucherSelectScreenType.SELECT_VERIFY_METHOD
        ) {
            return null;
        }
        return (
            <div className="w-100 d-flex p-3 justify-content-end">
                <Button
                    content={Messages.select}
                    className=""
                    onClick={() => {
                        setActiveScreen(
                            VoucherSelectScreenType.SELECT_VERIFY_METHOD
                        );
                    }}
                    disabled={!enableSaveVoucherButton}
                />
            </div>
        );
    };

    return (
        <VoucherSelectContext.Provider
            value={{
                anonymousVouchers,
                verifyMethod,
                setVerifyMethod,
                appliedVoucherProducts,
                activeScreen,
                onChange,
                onClose,
                setActiveScreen,
                customer,
                products,
                productVouchers,
                setProductVouchers,
                setAnonymousVouchers,
            }}
        >
            <Drawer
                size="x-large"
                open={open}
                onClose={onClose}
                footer={renderFooterDrawer()}
            >
                <div className="p-3">
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VOUCHER && (
                        <VoucherSelectList />
                    )}
                    {activeScreen ===
                        VoucherSelectScreenType.ANONYMOUS_VOUCHER_INPUT && (
                        <VoucherInput />
                    )}
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VERIFY_METHOD && (
                        <VoucherVerifyMethodSelect />
                    )}
                    {activeScreen === VoucherSelectScreenType.APPLY_CODE && (
                        <VoucherAuthorizationCodeInput />
                    )}
                </div>
            </Drawer>
        </VoucherSelectContext.Provider>
    );
};

export default OrderVoucherSelectDrawer;
