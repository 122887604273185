import { Button, Notifications } from "d-react-components";
import React, { useContext } from "react";
import { WORKSHEET_CREATE_STEPS } from "../../../constant/worksheet";
import { WorksheetCreateContext } from "../../../context/worksheet";
import {
    WSBookingSchema,
    WSCriteriaPriorCheckingSchema,
    WSCriteriaProgressSchema,
    WSCustomerConfirmSchema,
    WSDefectionSchema,
} from "../../../formschema/worksheet";
import Messages from "../../../languages/Messages";

const WorksheetCreateStepControl = () => {
    const { step, setStep, worksheetForm } = useContext(WorksheetCreateContext);
    const { totalUseQuantity, criteria } = worksheetForm.values;
    const onValidateByStep = async () => {
        const pageId = WORKSHEET_CREATE_STEPS[step]?.id;
        switch (pageId) {
            case 0:
                return WSBookingSchema.validate(worksheetForm.values);
            case 1:
                if (criteria.length < totalUseQuantity) {
                    return Promise.reject(new Error(Messages.needAddFullCriteria));
                }
                return WSCriteriaPriorCheckingSchema.validate(criteria);
            case 2:
                return WSDefectionSchema.validate(worksheetForm.values);
            case 3:
                return WSCriteriaProgressSchema.validate(criteria);
            case 5:
                return WSCustomerConfirmSchema.validate(worksheetForm.values);
            default:
                return Promise.resolve();
        }
    };

    const onClickNext = async () => {
        try {
            await onValidateByStep();
            setStep(step + 1);
        } catch (err: any) {
            Notifications.showError(err.message);
        }
    };

    const onClickSubmit = async () => {
        try {
            await onValidateByStep();
            worksheetForm.handleSubmit();
        } catch (err: any) {
            Notifications.showError(err.message);
        }
    };

    return (
        <div className="w-100 d-flex justify-content-between mt-3">
            <Button
                iconName="keyboard_arrow_left"
                disabled={step === 0}
                onClick={() => setStep(step - 1)}
            >
                {Messages.previous}
            </Button>
            <Button
                suffixIcon="keyboard_arrow_right"
                onClick={onClickNext}
                hidden={step === WORKSHEET_CREATE_STEPS.length - 1}
            >
                {Messages.next}
            </Button>
            <Button
                onClick={onClickSubmit}
                hidden={step !== WORKSHEET_CREATE_STEPS.length - 1}
            >
                {Messages.confirm}
            </Button>
        </div>
    );
};

export default WorksheetCreateStepControl;
