import { Modal } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import Messages from "../../../languages/Messages";
import CustomerFilterForm from "./CustomerFilterForm";

interface ICustomerFilterModal {
    open: boolean;
    onClose: () => void;
    onFilter: any;
}

const CustomerFilterModal = ({
    open,
    onClose,
    onFilter,
}: ICustomerFilterModal) => {
    const filterForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            onFilter && onFilter(values);
            onClose();
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.filter}
            saveText={Messages.filter}
            onSave={() => filterForm.handleSubmit()}
        >
            <CustomerFilterForm formData={filterForm} />
        </Modal>
    );
};

export default CustomerFilterModal;
