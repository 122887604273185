import {
    AwesomeTableComponent,
    Button,
    DateInput,
    DialogManager,
    IColumnsProps,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import InputTextForm from "../../../common/input/InputTextForm";
import TableAction from "../../../common/TableActions";
import { BOOKING_STATUS, BOOKING_STATUSES } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

interface IScheduleServiceAddModal {
    open: boolean;
    onClose: () => void;
    defaultValue: any;
}

function ScheduleServiceCrudModal({
    open,
    defaultValue,
    onClose,
}: IScheduleServiceAddModal) {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);
    const isEdit = !!defaultValue;
    const scheduleForm = useFormik<any>({
        initialValues: defaultValue ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            if (isEdit) {
                onUpdateScheduleService(values);
            } else {
                onAddScheduleService(values);
            }
        },
    });

    const formValues = scheduleForm?.values;
    const formErrors = scheduleForm?.errors;

    const onUpdateScheduleService = (values: any) => {
        Progress.show(
            {
                method: BookingAPI.updateScheduleService,
                params: [
                    bookingDetail?.id,
                    values?.id,
                    { date: values?.date, remark: values?.remark },
                ],
            },
            (booking) => {
                setBookingDetail(booking);
                onClose();
            }
        );
    };

    const onAddScheduleService = (values: any) => {
        Progress.show(
            {
                method: BookingAPI.createScheduleService,
                params: [bookingDetail?.id, values],
            },
            (booking) => {
                setBookingDetail(booking);
                onClose();
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.scheduleServiceDate}
            onSave={() => scheduleForm.handleSubmit()}
        >
            <div className="row">
                <DateInput
                    label={Messages.serviceDate}
                    onChange={(val) => {
                        const valueTime = (val as any).valueOf();
                        if (valueTime < new Date().getTime()) {
                            Notifications.showError(
                                Messages.estDateNeedToGreaterThan
                            );
                            return;
                        }
                        scheduleForm.setFieldValue(
                            "date",
                            moment(val).toISOString()
                        );
                    }}
                    value={
                        formValues.date
                            ? (moment(formValues.date) as any)
                            : null
                    }
                    className="col-12"
                    error={formErrors.date as any}
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    allowClear={false}
                />
                <InputTextForm
                    keyData="remark"
                    form={scheduleForm}
                    className="col-12 mt-3"
                    multiple
                />
            </div>
        </Modal>
    );
}

const BookingDetailScheduleService = () => {
    const { bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);
    const [crudSchedule, setCrudSchedule] = useState<any>({ open: false });
    const tableRef = useRef<any>();
    const { scheduleServices, status } = bookingDetail;
    const isHiddenCrud =
        status !== BOOKING_STATUS.PENDING &&
        status !== BOOKING_STATUS.SCHEDULED;

    const columns: IColumnsProps = [
        {
            title: Messages.date,
            dataIndex: "date",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.remark,
            dataIndex: "remark",
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (schedule: any) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, schedule)
                    }
                    dataSource={[
                        { id: "update", label: "update" },
                        { id: "delete", label: "delete" },
                    ]}
                />
            ),
        },
    ];

    const onClickTableAction = (actionId: string, schedule: any) => {
        if (isHiddenCrud) {
            Notifications.showError(Messages.bookingIsNotInScheduling);
            return;
        }
        switch (actionId) {
            case "delete":
                onDeleteSchedule(schedule?.id);
                break;
            default:
                setCrudSchedule({ value: schedule, open: true });
        }
    };

    const onDeleteSchedule = (scheduleId: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureToDeleteSchedule,
            () => {
                Progress.show(
                    {
                        method: BookingAPI.deleteScheduleService,
                        params: [bookingDetail?.id, scheduleId],
                    },
                    (booking) => {
                        setBookingDetail(booking);
                        Notifications.showSuccess(
                            Messages.deleteScheduleSuccess
                        );
                    }
                );
            }
        );
    };

    return (
        <div className="card-container p-4 mt-3 flex-column">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.scheduleServiceDate}</label>
                <Button
                    onClick={() => setCrudSchedule({ open: true })}
                    hidden={isHiddenCrud}
                >
                    {Messages.schedule}
                </Button>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={scheduleServices}
                className="mt-3"
            />
            {crudSchedule.open && (
                <ScheduleServiceCrudModal
                    open={crudSchedule?.open}
                    onClose={() => setCrudSchedule({ open: false })}
                    defaultValue={crudSchedule?.value}
                />
            )}
        </div>
    );
};

export default BookingDetailScheduleService;
