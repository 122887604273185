import {
    AwesomeTableComponent,
    Button,
    DateInput,
    IColumnsProps,
    InputText,
    Modal,
    Notifications,
    Progress,
    RadioGroup,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find, isEmpty, map, reduce } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import OrderAPI from "../../../api/order/OrderAPI";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { DELIVERY_STATUS, DELIVERY_STATUSES } from "../../../constant/delivery";
import { ORDER_STATUS } from "../../../constant/order";
import { OrderDetailContext } from "../../../context/order";
import {
    OrderDeliverySchema,
    OrderDeliveryStatusSchema,
} from "../../../formschema/order";
import { useOrderDeliveryShippedQty } from "../../../hoook/order";
import { getFullAddress } from "../../../interfaces/address";
import { IDelivery } from "../../../interfaces/delivery";
import { mapOrderDeliveryToServer } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

interface IOrderDeliveryCrudModal {
    open: boolean;
    delivery?: IDelivery;
    onClose: () => void;
}

interface IOrderDeliveryItem {
    delivery: IDelivery;
}

interface IDeliveryAllModal {
    open: boolean;
    onClose: () => void;
}

interface IDeliveryUpdateStatusModal {
    delivery: IDelivery;
    open: boolean;
    onClose: () => void;
}
interface IOrderDeliveryDetail {
    delivery: IDelivery;
    open: boolean;
    onClose: () => void;
}

const DeliveryShippingAddress = ({ shipping }: any) => (
    <div className="flex-column">
        <text>{`${shipping?.firstName} ${shipping?.lastName}`}</text>
        <text>{shipping?.phone}</text>
        <text>{getFullAddress(shipping)}</text>
    </div>
);

const DeliveryShippingProduct = ({ product, deliveryId }: any) => {
    const shippedQty = useOrderDeliveryShippedQty(deliveryId, product);
    const leftItem = product.quantity - shippedQty - product?.shippingQty;
    return (
        <div className="d-flex">
            <Image src={product?.thumbnail} className="image-square-small" />

            <div className="flex-column ml-2">
                <AppLink
                    to={generatePath(Path.PRODUCT_DETAIL, {
                        productId: product.productId ?? "products",
                    })}
                >
                    {product?.name}
                </AppLink>
                <small>
                    {Messages.sku}: {product?.SKU}
                </small>
                <small className="text-success">{`${leftItem} ${Messages.willBeLeft}`}</small>
            </div>
        </div>
    );
};
const InputDeliveryQuantity = ({
    value,
    onChange,
    deliveryId,
    product,
}: any) => {
    const shippedQty = useOrderDeliveryShippedQty(deliveryId, product);
    return (
        <InputTextQuantity
            value={value}
            onChange={onChange}
            min={0}
            max={product.quantity - shippedQty}
        />
    );
};

const getDefaultDeliveryProduct = (products: any[]) =>
    map(products, (pro: any) => ({ ...pro, shippingQty: 0 }));

export const OrderDeliveryCrudModal = ({
    open,
    delivery,
    onClose,
}: IOrderDeliveryCrudModal) => {
    const { orderDetail, metaData, setOrderDetail } =
        useContext(OrderDetailContext);
    const [deliveryTypes, setDeliveryTypes] = useState([]);
    const isEdit = useMemo(() => !!delivery, [delivery]);
    useEffect(() => {
        OrderAPI.deliveryTypes().then(setDeliveryTypes);
    }, []);

    const deliveryForm = useFormik<any>({
        initialValues: {
            orderId: orderDetail.id,
            products: getDefaultDeliveryProduct(orderDetail.deliveryProducts),
            ...(delivery ?? {}),
            shippingId: delivery?.shipping?.id,
            warehouseId: delivery?.warehouse?.id,
            deliveryType: delivery?.deliveryType?.id,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderDeliverySchema,
        onSubmit: (values: any) => {
            const input = mapOrderDeliveryToServer(values);
            isEdit ? onUpdateDelivery(input) : onCreateDelivery(input);
        },
    });

    const formValues = deliveryForm?.values;
    const formErrors = deliveryForm?.errors;
    const inputClassName = "col-6 mt-3";

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => (
                <DeliveryShippingProduct
                    product={product}
                    deliveryId={delivery?.id}
                />
            ),
        },
        {
            title: Messages.orderQty,
            dataIndex: "quantity",
            width: 150,
        },
        {
            title: Messages.availableQtyWH,
            dataIndex: "quantity",
            width: 150,
        },
        {
            title: Messages.shipQty,
            dataIndex: "shippingQty",
            render: (shippingQty, product) => (
                <InputDeliveryQuantity
                    value={shippingQty}
                    onChange={(qty: any) => onChangeShipQty(product, qty)}
                    product={product}
                    deliveryId={delivery?.id}
                />
            ),
        },
    ];

    const onCreateDelivery = (input: any) => {
        Progress.show(
            { method: OrderAPI.createDelivery, params: [input] },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.createDeliverySuccess);
                setOrderDetail(orderDetailRes);
                onClose();
            }
        );
    };

    const onUpdateDelivery = (input: any) => {
        Progress.show(
            { method: OrderAPI.updateDelivery, params: [delivery?.id, input] },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.updateDeliverySuccess);
                setOrderDetail(orderDetailRes);
                onClose();
            }
        );
    };

    const onChangeShipQty = (product: any, qty: number) => {
        const productResult = map(formValues.products, (pro) => {
            if (pro.id === product.id) {
                return { ...product, shippingQty: qty };
            }
            return pro;
        });
        onUpdateProducts(productResult);
    };

    const onUpdateProducts = (products: any[]) => {
        deliveryForm.setFieldValue("products", products);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.createDelivery}
            onSave={() => deliveryForm.handleSubmit()}
            size="large"
        >
            <div className="row">
                <InputSelectForm
                    required
                    label={Messages.fromWarehouse}
                    keyData="warehouseId"
                    dataSource={metaData?.warehouses}
                    className={inputClassName}
                    form={deliveryForm}
                    getLabel={(item: any) =>
                        `${item[Messages.getLanguage()].name} (${item.code})`
                    }
                />
                <InputSelectForm
                    keyData="deliveryType"
                    dataSource={deliveryTypes}
                    className={inputClassName}
                    form={deliveryForm}
                    getLabel={(item: any) => item.label}
                />
                <DateInput
                    required
                    label={Messages.estimatedDateArrival}
                    onChange={(value) =>
                        deliveryForm.setFieldValue("dateOfArrival", value)
                    }
                    value={
                        formValues.dateOfArrival
                            ? (moment(formValues.dateOfArrival) as any)
                            : null
                    }
                    error={formErrors.dateOfArrival as any}
                    className={inputClassName}
                />
                <InputTextForm
                    keyData="deliveryNo"
                    form={deliveryForm}
                    className={inputClassName}
                />
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={formValues.products}
                    className="col-12 mt-3"
                    pagination={false}
                />
                <RadioGroup
                    className="col-12 mt-3"
                    label={Messages.shippingAddress}
                    numberOfColumns="1"
                    dataSource={orderDetail.shippings}
                    value={formValues?.shippingId}
                    onChange={(value) =>
                        deliveryForm.setFieldValue("shippingId", value)
                    }
                    getLabel={(shipping) => (
                        <DeliveryShippingAddress shipping={shipping} />
                    )}
                    error={formErrors.shippingId}
                />

                <InputTextForm
                    keyData="remark"
                    form={deliveryForm}
                    className="col-12 mt-3"
                    multiple
                />
            </div>
        </Modal>
    );
};

const DeliveryDetailModal = ({
    delivery,
    open,
    onClose,
}: IOrderDeliveryDetail) => {
    const {
        warehouse = {},
        deliveryType,
        dateOfArrival,
        deliveryNo,
        products,
        shipping,
        remark,
    } = delivery;
    const inputClassName = "col-6 mt-3";

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => (
                <DeliveryShippingProduct
                    product={{ ...product, shippingQty: 0 }}
                />
            ),
        },
        {
            title: Messages.orderQty,
            dataIndex: "quantity",
            width: 150,
        },
        {
            title: Messages.availableQtyWH,
            dataIndex: "quantity",
            width: 150,
        },
        {
            title: Messages.shipQty,
            dataIndex: "shippingQty",
            width: 150,
        },
    ];

    const renderFieldValue = (label: string, value: any) => (
        <InputText
            label={label}
            className={inputClassName}
            value={value}
            disabled
        />
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.listItem}
            showFooter={false}
            size="medium"
        >
            <div className="row">
                {renderFieldValue(
                    Messages.fromWarehouse,
                    (warehouse as any)[Messages.getLanguage()]?.name
                )}
                {renderFieldValue(Messages.deliveryType, deliveryType?.label)}
                {renderFieldValue(
                    Messages.estimatedDateArrival,
                    TimeUtils.toDateTime(dateOfArrival)
                )}
                {renderFieldValue(Messages.deliveryNo, deliveryNo)}
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={products}
                    className="col-12 mt-3 overflow-auto"
                    pagination={false}
                />
                <div className="col-12 mt-3">
                    <label>{Messages.shippingAddress}</label>
                    <DeliveryShippingAddress shipping={shipping} />
                </div>
                <div
                    className="bg-note p-3 mt-3"
                    hidden={!remark}
                >{`${Messages.remark}: ${remark}`}</div>
            </div>
        </Modal>
    );
};

const DeliveryUpdateStatusModal = ({
    delivery,
    open,
    onClose,
}: IDeliveryUpdateStatusModal) => {
    const { setOrderDetail } = useContext(OrderDetailContext);
    const updateForm = useFormik<any>({
        initialValues: { status: delivery.status } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderDeliveryStatusSchema,
        onSubmit: (values: any) => {
            onUpdateDeliveryStatus(values);
        },
    });
    const formValues = updateForm?.values;
    const formErrors = updateForm?.errors;

    const onUpdateDeliveryStatus = (values: any) => {
        const { dateOfArrival, remark, status } = values;
        const body = {
            remark,
            status,
            arrived: dateOfArrival ? moment(dateOfArrival).toISOString() : null,
        };

        Progress.show(
            {
                method: OrderAPI.updateDeliveryStatus,
                params: [delivery.id, body],
            },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.updateDeliveryStatusSuccess);
                setOrderDetail(orderDetailRes);
                onClose();
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateStatus}
            onSave={() => updateForm.handleSubmit()}
        >
            <RadioGroup
                numberOfColumns="2"
                dataSource={DELIVERY_STATUSES}
                label={Messages.status}
                value={formValues.status}
                onChange={(value) => updateForm.setFieldValue("status", value)}
            />
            <DateInput
                required
                label={
                    formValues.status === DELIVERY_STATUS.COMPLETED
                        ? Messages.actualDateArrival
                        : Messages.actualDateReturn
                }
                onChange={(value) =>
                    updateForm.setFieldValue("dateOfArrival", value)
                }
                value={
                    formValues.dateOfArrival
                        ? (moment(formValues.dateOfArrival) as any)
                        : null
                }
                error={formErrors?.dateOfArrival as any}
                className="mt-3"
                hidden={
                    formValues.status !== DELIVERY_STATUS.COMPLETED &&
                    formValues.status !== DELIVERY_STATUS.RETURNED
                }
            />
            <InputTextForm
                keyData="remark"
                form={updateForm}
                className="mt-3"
                multiple
            />
        </Modal>
    );
};

const OrderDeliveryItem = ({ delivery }: IOrderDeliveryItem) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const {
        deliveryType,
        deliveryNo,
        status,
        warehouse,
        remark,
        createBy,
        createdAt,
        products,
        shipping,
        dateOfArrival,
        ref,
        arrived,
    } = delivery;

    const isDisableEdit = useMemo(() => {
        if (
            (orderDetail?.status === ORDER_STATUS.DELIVERY_PROCESSING ||
                orderDetail?.status === ORDER_STATUS.ORDER_PROCESSING) &&
            (delivery?.status === DELIVERY_STATUS.PENDING ||
                delivery?.status === DELIVERY_STATUS.BOOKED)
        ) {
            return false;
        }
        return true;
    }, [orderDetail?.status, delivery?.status]);

    const isDisableUpdateStatus = useMemo(() => {
        if (
            delivery?.status === DELIVERY_STATUS.CANCEL ||
            delivery?.status === DELIVERY_STATUS.COMPLETED ||
            delivery?.status === DELIVERY_STATUS.RETURNED
        ) {
            return true;
        }
        return false;
    }, [delivery?.status]);

    const deliveryStatus = find(
        DELIVERY_STATUSES,
        (item) => item.id === status
    );

    const totalShippingQty = reduce(
        products,
        (sum, item) => sum + item.shippingQty,
        0
    );

    return (
        <div className="flex-column border-bottom pb-3 mt-3">
            <text>{deliveryType?.label ?? ""}</text>
            <div
                className="d-flex align-center-center"
                hidden={isEmpty(deliveryNo)}
            >
                <text>{deliveryNo}</text>
                <Button
                    iconName="content_copy"
                    size="x-small"
                    variant="trans"
                    onClick={() => navigator.clipboard.writeText(deliveryNo)}
                />
            </div>
            <text>{`${Messages.ref}: ${ref}`}</text>
            <text style={{ color: deliveryStatus?.color }}>
                {deliveryStatus?.label}
            </text>
            <small className="mt-3">
                {`${(warehouse as any)?.[Messages.getLanguage()]?.name} (${
                    warehouse?.warehouseNo
                })`}
            </small>
            <small>{`${Messages.estimatedDateArrival} ${TimeUtils.toDateTime(
                dateOfArrival
            )}`}</small>

            <div
                className="small"
                hidden={status !== DELIVERY_STATUS.COMPLETED}
            >{`${Messages.arrivedOn} ${TimeUtils.toDateTime(arrived)}`}</div>

            <div
                className="small"
                hidden={status !== DELIVERY_STATUS.RETURNED}
            >{`${Messages.returnedOn} ${TimeUtils.toDateTime(arrived)}`}</div>

            <div className="bg-muted p-3 mt-3">
                <DeliveryShippingAddress shipping={shipping} />
            </div>
            <div className="mt-3 bg-note p-3 mt-3" hidden={!remark}>
                {`${Messages.remark}: ${remark}`}
            </div>
            <small className="mt-3">
                {`${Messages.createdBy} ${
                    createBy?.username
                } at ${TimeUtils.toDateTime(createdAt)}`}
            </small>
            <div className="d-flex mt-3">
                <Button
                    variant="outline"
                    onClick={() => setOpenDetail(true)}
                >{`${Messages.listItem} (${totalShippingQty})`}</Button>
                <Button
                    className="ml-2"
                    onClick={() => setOpenUpdateStatus(true)}
                    disabled={isDisableUpdateStatus}
                >
                    {Messages.updateStatus}
                </Button>
            </div>
            <div
                className="mt-3 text-error cursor-pointer"
                onClick={() => setOpenEdit(true)}
                hidden={isDisableEdit}
            >
                {Messages.edit}
            </div>
            {openUpdateStatus && (
                <DeliveryUpdateStatusModal
                    open={openUpdateStatus}
                    onClose={() => setOpenUpdateStatus(false)}
                    delivery={delivery}
                />
            )}
            {openDetail && (
                <DeliveryDetailModal
                    open={openDetail}
                    onClose={() => setOpenDetail(false)}
                    delivery={delivery}
                />
            )}
            {openEdit && (
                <OrderDeliveryCrudModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    delivery={delivery}
                />
            )}
        </div>
    );
};

const DeliveryAllModal = ({ open, onClose }: IDeliveryAllModal) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { deliveries } = orderDetail;
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.allDelivery}
            onSave={() => {}}
            showFooter={false}
        >
            {map(deliveries, (delivery) => (
                <OrderDeliveryItem delivery={delivery} />
            ))}
        </Modal>
    );
};

const OrderDetailDelivery = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const [openAll, setOpenAll] = useState(false);
    const { deliveries } = orderDetail;
    if (!deliveries || deliveries.length === 0) return <div />;
    return (
        <div className="card-container p-4 mt-3">
            <label>{Messages.deliveryAssignment}</label>
            {deliveries?.length > 0 && (
                <OrderDeliveryItem delivery={deliveries[0]} />
            )}

            <Button
                className="w-100 mt-3"
                onClick={() => setOpenAll(true)}
                hidden={deliveries?.length < 2}
            >
                {Messages.allDelivery}
                <small className="dot-view ml-2">{deliveries?.length}</small>
            </Button>
            {openAll && (
                <DeliveryAllModal
                    open={openAll}
                    onClose={() => setOpenAll(false)}
                />
            )}
        </div>
    );
};

export default OrderDetailDelivery;
