import { gql } from "@apollo/client";

export const F_STATIC_CONTENT = gql`
    fragment F_STATIC_CONTENT on StaticContent {
        id
        name
        type
        th {
            content
        }
        en {
            content
        }
    }
`;
