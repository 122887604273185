import { Dropdown, Menu } from "antd";
import classNames from "classnames";
import {
    Avatar,
    DateInput,
    Icon,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    VOUCHER_OWNERSHIP_STATUS,
    VOUCHER_STATUSES,
} from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import {
    VoucherChangeExpiryDateSchema,
    VoucherTransferOwnershipSchema,
} from "../../../formschema/voucher";
import Messages from "../../../languages/Messages";
import CustomerSelect from "../../customer/common/CustomerSelect";
import VoucherCancelModal from "../common/VoucherCancelModal";

const VoucherMoreAction = ({ onClickActions }: any) => {
    const ACTIONS_LIST = [
        { id: "changeExpiryDate", label: Messages.changeExpiringDate },
        { id: "transferOwnership", label: Messages.transferOwnership },
    ];

    const menu = (
        <Menu>
            {map(ACTIONS_LIST, (action) => (
                <Menu.Item
                    key={action.id}
                    onClick={() => onClickActions(action.id)}
                >
                    {action.label}
                </Menu.Item>
            ))}
        </Menu>
    );
    return (
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="flex-center cursor-pointer ml-3"
        >
            <div>
                <text>{Messages.moreActions}</text>
                <Icon name="expand_more" size="small" className="ml-1" />
            </div>
        </Dropdown>
    );
};

interface IChangeExpiryDateModal {
    open: boolean;
    onClose: () => void;
}

const ChangeExpiryDateModal = ({ open, onClose }: IChangeExpiryDateModal) => {
    const { voucher, setVoucher } = useContext(VoucherDetailContext);

    const expiryDateForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VoucherChangeExpiryDateSchema,
        onSubmit: (values: any) => {
            onChangeExpiryDate(values);
        },
    });

    const onChangeExpiryDate = (input: any) => {
        Progress.show(
            {
                method: VoucherAPI.changeExpiryDate,
                params: [voucher?.id, input],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.changeExpiryDateSuccess);
                setVoucher(res);
                onClose();
            }
        );
    };

    const formValues = expiryDateForm?.values;
    const formErrors = expiryDateForm?.errors;

    const classNameInput = classNames("mt-3 col-12");
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.changeExpiringDate}
            onSave={() => expiryDateForm.handleSubmit()}
        >
            <div className="row">
                <DateInput
                    value={formValues?.date}
                    label={Messages.expiryDate}
                    onChange={(value) =>
                        expiryDateForm.setFieldValue("date", value)
                    }
                    error={formErrors.date as any}
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    className={classNameInput}
                />
                <InputTextForm
                    keyData="remark"
                    form={expiryDateForm}
                    className={classNameInput}
                    multiple
                />
                <div className={classNameInput}>
                    <label>{Messages.proof}</label>
                    <ButtonFileUpload
                        onChange={(value: any) =>
                            expiryDateForm.setFieldValue(
                                "attachments",
                                map(value, (item) => item.url)
                            )
                        }
                        maxFiles={5}
                        className="mt-3"
                    />
                </div>
            </div>
        </Modal>
    );
};

const TransferOwnershipModal = ({ open, onClose }: IChangeExpiryDateModal) => {
    const { voucher, setVoucher } = useContext(VoucherDetailContext);

    const ownershipForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VoucherTransferOwnershipSchema,
        onSubmit: (values: any) => {
            const input = {
                ownershipId: values.ownership?.id,
                remark: values?.remark,
                attachments: values?.attachments,
            };
            onTransferOwnership(input);
        },
    });

    const onTransferOwnership = (input: any) => {
        Progress.show(
            {
                method: VoucherAPI.transferOwnership,
                params: [voucher?.id, input],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.transferOwnershipSuccess);
                setVoucher(res);
                onClose();
            }
        );
    };

    const formValues = ownershipForm?.values;
    const formErrors = ownershipForm?.errors;

    const classNameInput = classNames("mt-3 col-12");
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.transferOwnership}
            onSave={() => ownershipForm.handleSubmit()}
        >
            <div className="row">
                <CustomerSelect
                    onChange={(customer) =>
                        ownershipForm.setFieldValue("ownership", customer)
                    }
                    value={formValues?.ownership}
                    error={formErrors.ownership as any}
                    className={classNameInput}
                    showAddNew={false}
                />
                <InputTextForm
                    keyData="remark"
                    form={ownershipForm}
                    className={classNameInput}
                    multiple
                />
                <div className={classNameInput}>
                    <label>{Messages.proof}</label>
                    <ButtonFileUpload
                        onChange={(value: any) =>
                            ownershipForm.setFieldValue(
                                "attachments",
                                map(value, (item) => item.url)
                            )
                        }
                        maxFiles={5}
                        className="mt-3"
                    />
                </div>
            </div>
        </Modal>
    );
};

const VoucherDetailHeader = () => {
    const [openCancel, setOpenCancel] = useState(false);
    const [openExpiryDate, setOpenExpiryDate] = useState(false);
    const [openOwnership, setOpenOwnership] = useState(false);
    const { voucher, setVoucher } = useContext(VoucherDetailContext);
    const { status, code, createBy, createdAt, id, ownership } = voucher;

    const isHiddenCancel = useMemo(() => {
        if (ownership?.status !== VOUCHER_OWNERSHIP_STATUS.AVAILABLE) {
            return true;
        }
        return false;
    }, [status]);

    const onAction = (actionId: string) => {
        switch (actionId) {
            case "changeExpiryDate":
                setOpenExpiryDate(true);
                break;
            case "transferOwnership":
                setOpenOwnership(true);
                break;
            default:
        }
    };

    return (
        <div className="card-container p-4 flex-center-y justify-content-between ">
            <div className="flex-column">
                <div className="d-flex">
                    <h4>{code}</h4>
                    <ViewLabelStatus
                        className="ml-3"
                        status={status}
                        listStatus={VOUCHER_STATUSES}
                        getLabel={(item) => (item?.label ?? "")?.toUpperCase()}
                    />
                </div>
                <div className="flex-center-y mt-3">
                    <div className="cursor-pointer flex-center-y">
                        <Icon name="print" className="mr-2" />
                        {Messages.print}
                    </div>
                    <div
                        className="cursor-pointer flex-center-y ml-3"
                        onClick={() => setOpenCancel(true)}
                        hidden={isHiddenCancel}
                    >
                        <Icon name="cancel" className="mr-2" />
                        {Messages.cancel}
                    </div>
                    <VoucherMoreAction onClickActions={onAction} />
                </div>
            </div>
            <div className="flex-column">
                <div className="flex-center-y">
                    {`${Messages.by} ${createBy?.firstName} ${createBy?.lastName}`}
                    <Avatar
                        size="xx-small"
                        className="ml-2"
                        src={createBy?.avatar}
                    />
                </div>
                <small className="mt-2">
                    {`${Messages.createAt} ${TimeUtils.toDateTime(createdAt)}`}
                </small>
            </div>
            {openCancel && (
                <VoucherCancelModal
                    open={openCancel}
                    onClose={() => setOpenCancel(false)}
                    voucherId={id}
                    onSuccess={setVoucher}
                />
            )}
            {openExpiryDate && (
                <ChangeExpiryDateModal
                    open={openExpiryDate}
                    onClose={() => setOpenExpiryDate(false)}
                />
            )}
            {openOwnership && (
                <TransferOwnershipModal
                    open={openOwnership}
                    onClose={() => setOpenOwnership(false)}
                />
            )}
        </div>
    );
};

export default VoucherDetailHeader;
