import { Checkbox, Modal } from "d-react-components";
import { useFormik } from "formik";
import { BillingSchema } from "../../../formschema/customer";
import Messages from "../../../languages/Messages";
import CustomerAddressForm from "./CustomerAddressForm";

interface IBillingUpdateModal {
    open: boolean;
    hideSaveAsNew?: boolean;
    showSameAsServiceAdd?: boolean;
    billingDefault?: any;
    onClose: () => any;
    onChange: (value: any) => void;
}

const CustomerCrudBillingModal = ({
    open,
    hideSaveAsNew,
    showSameAsServiceAdd,
    billingDefault,
    onClose,
    onChange,
}: IBillingUpdateModal) => {
    const billingForm = useFormik<any>({
        initialValues: billingDefault ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BillingSchema,
        onSubmit: (values) => {
            onClose();
            onChange(values);
        },
    });

    const formValue = billingForm?.values;

    const isPersonal = billingForm.values?.typeOfCustomer === "INDIVIDUAL";

    const renderSideModal = () => {
        if (hideSaveAsNew || formValue?.sameServiceAddress) return <div />;
        return (
            <Checkbox
                label={Messages.saveAsNewAddress}
                checked={billingForm.values.saveNewAddress}
                onChange={() =>
                    billingForm.setFieldValue(
                        "saveNewAddress",
                        !billingForm.values.saveNewAddress
                    )
                }
            />
        );
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateAddress}
            size="medium"
            onSave={() => billingForm.handleSubmit()}
            customSideButton={renderSideModal}
        >
            {showSameAsServiceAdd && (
                <Checkbox
                    label={Messages.sameAsServiceAddress}
                    onChange={() =>
                        billingForm.setFieldValue(
                            "sameServiceAddress",
                            !formValue?.sameServiceAddress
                        )
                    }
                    checked={formValue?.sameServiceAddress}
                />
            )}
            {!formValue?.sameServiceAddress && (
                <CustomerAddressForm
                    formData={billingForm}
                    show={{
                        citizenId: isPersonal,
                        company: !isPersonal,
                        email: true,
                        nickname: false,
                        gender: false,
                        typeOfCustomer: true,
                        typeOfPlace: false,
                        map: false,
                    }}
                />
            )}
        </Modal>
    );
};

export default CustomerCrudBillingModal;
