import * as FileSaver from "file-saver";
import { forEach, isEmpty, map } from "lodash";
import * as XLSX from "xlsx";

const exportToCSV = (csvData: any[], fileName: string) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

/**
 *
 * @param []{sheetName: string, data: json} sheetDataList
 * @param {string} fileName
 */
const exportToCSVMultipleSheet = (sheetDataList: any[], fileName: string) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const wb = XLSX.utils.book_new();
    sheetDataList.forEach((sheetData) => {
        const ws = XLSX.utils.json_to_sheet(sheetData.data);
        XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
    });

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

const convertRawToFileData = (dataList: any[], fieldList: any[]) => {
    const fileData = map(dataList, (customer) => {
        const data: any = {};

        forEach(fieldList, (field) => {
            if (!isEmpty(field.field)) {
                data[field.header] = customer[field.field];
            }

            if (field.getValue) {
                data[field.header] = field.getValue(customer);
            }
        });

        return data;
    });

    return fileData;
};

export default { exportToCSV, exportToCSVMultipleSheet, convertRawToFileData };
