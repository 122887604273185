import classNames from "classnames";
import {
    DialogManager,
    Icon,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { toUpper } from "lodash";
import { useContext, useState } from "react";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";
import Image from "../../../common/Image";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    REFUND_STATUS,
    REFUND_STATUSES,
} from "../../../constant/refundRequest";

import { RefundDetailContext } from "../../../context/refundRequest";
import { RefundBankSchema } from "../../../formschema/refundRequest";
import Messages from "../../../languages/Messages";

interface IRefundBankAddModal {
    open: boolean;
    onClose: () => void;
}

const RefundBankAddModal = ({ open, onClose }: IRefundBankAddModal) => {
    const { refundRequest, setRefundRequest } = useContext(RefundDetailContext);

    const bankForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RefundBankSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: RefundRequestAPI.addRefundRequestBank,
                    params: [refundRequest?.id, values],
                },
                (refund: any) => {
                    Notifications.showSuccess(Messages.addBankSuccessfully);
                    setRefundRequest(refund);
                    onClose();
                }
            );
        },
    });

    const classNameInput = classNames("col-12 mt-3");
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.addBank}
            onSave={() => bankForm.handleSubmit()}
        >
            <div className="row">
                <InputTextForm
                    form={bankForm}
                    keyData="bankName"
                    className={classNameInput}
                />
                <InputTextForm
                    form={bankForm}
                    keyData="accountName"
                    className={classNameInput}
                />
                <InputTextForm
                    form={bankForm}
                    keyData="accountNo"
                    className={classNameInput}
                />
            </div>
        </Modal>
    );
};

const RefundRequestDetailHeader = () => {
    const { refundRequest, setRefundRequest } = useContext(RefundDetailContext);
    const [openAddBank, setOpenAddBank] = useState(false);

    const { requestNo, status, createBy, createdAt } = refundRequest;

    const renderActionButton = (
        iconName: string,
        content: string,
        action: any
    ) => (
        <div
            className="d-flex align-items-center mr-3 cursor-pointer"
            onClick={action}
        >
            <Icon name={iconName} className="small" />
            <small className="ml-1">{content}</small>
        </div>
    );

    const onResetRefund = () => {
        if (
            status !== REFUND_STATUS.CONFIRMED &&
            status !== REFUND_STATUS.REJECTED
        ) {
            Notifications.showError(Messages.onlyResetRefundStatus);
            return;
        }
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.sureToResetRefund,
            () => {
                Progress.show(
                    {
                        method: RefundRequestAPI.resetRefund,
                        params: [refundRequest?.id],
                    },
                    (refund) => {
                        setRefundRequest(refund);
                        Notifications.showSuccess(Messages.resetRefundSuccess);
                    }
                );
            }
        );
    };

    return (
        <div className="card-container d-flex p-4">
            <div className="flex-column flex-grow-1">
                <div className="d-flex align-items-center">
                    <h5>{`${Messages.refundNo} #${requestNo}`}</h5>
                    <ViewLabelStatus
                        className="ml-3"
                        status={status}
                        listStatus={REFUND_STATUSES}
                        getLabel={(item) =>
                            toUpper((Messages as any)[item.label])
                        }
                    />
                </div>
                <div className="d-flex align-items-center mt-3">
                    {renderActionButton("print", Messages.print, () => {})}
                    {renderActionButton("savings", Messages.addBank, () =>
                        setOpenAddBank(true)
                    )}
                    {renderActionButton(
                        "remove_circle",
                        Messages.reset,
                        onResetRefund
                    )}
                </div>
            </div>
            <div>
                <div className="d-flex align-items-center">
                    <text>{`${Messages.by} ${createBy?.username}`}</text>
                    <Image
                        className="image-square-xx-small ml-3"
                        src={createBy?.avatar}
                        style={{ borderRadius: "15px" }}
                    />
                </div>
                <small>
                    {`${Messages.createdOn} ${TimeUtils.toDateTime(createdAt)}`}
                </small>
            </div>

            {openAddBank && (
                <RefundBankAddModal
                    open={openAddBank}
                    onClose={() => setOpenAddBank(false)}
                />
            )}
        </div>
    );
};

export default RefundRequestDetailHeader;
