import {
    Button,
    Icon,
    InputTextSearch,
    Notifications,
    ObjectUtils,
} from "d-react-components";
import { findIndex, map } from "lodash";
import { useEffect, useState } from "react";
import BlockAPI from "../../../api/block/BlockAPI";
import DragContainer from "../../../common/drag/DragContainer";
import DragItem from "../../../common/drag/DragItem";
import { IBlock, IBlockDetail } from "../../../interfaces/block";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductSearchButton from "../../product/common/ProductSearchButton";

const BlockDetail = ({ type }: IBlockDetail) => {
    const [blockDetail, setBlockDetail] = useState<IBlock>();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        loadBlockDetail();
    }, []);

    const loadBlockDetail = () => {
        BlockAPI.detail(type).then(onSetData);
    };

    const onSetData = (blockDetail: any) => {
        setBlockDetail(blockDetail);
        setProducts(blockDetail?.products ?? []);
    };

    const onDropProduct = async (moveItem: any) => {
        const currentIndex = findIndex(
            blockDetail?.products,
            (item) => item.id === moveItem.id
        );
        const products = ObjectUtils.arrayMove(
            blockDetail?.products as any,
            moveItem.index,
            currentIndex
        ) as any;
        const productIds = map(products, (item) => item.id);
        const blockDetailRes = await BlockAPI.update(blockDetail?.type as any, {
            productIds,
        });
        onSetData(blockDetailRes);
    };

    const onMoveProduct = (hoverIndex: number, dragIndex: number) => {
        const results = ObjectUtils.arrayMove(products, dragIndex, hoverIndex);
        setProducts([...results]);
    };

    const onRemoveProducts = async (ids: string[]) => {
        const blockDetail = await BlockAPI.removeProduct(type, ids);
        onSetData(blockDetail);
        Notifications.showSuccess(Messages.removeProductSuccessfully);
    };

    const onAddProducts = async (products: IProduct[]) => {
        const ids = map(products, (item) => item.id);
        const blockDetail = await BlockAPI.addProduct(type, ids);
        onSetData(blockDetail);
        Notifications.showSuccess(Messages.addProductSuccessfully);
    };

    return (
        <div className="p-4  h-100 w-100">
            <div className="card-container p-4">
                <div className="d-flex">
                    <InputTextSearch className="flex-1 mr-3" />
                    <ProductSearchButton
                        onSave={onAddProducts}
                        isHideAvaQtyCurrentWH
                        isHideOnHoldQtyCurrentWH
                        isHideRegularPrice
                        isHideSalePrice
                        isHideAvaQtyDelivery
                        isHideOnHoldQtyDelivery
                        filterSource={{
                            customerVisibility: true,
                        }}
                    >
                        {Messages.add}
                    </ProductSearchButton>
                </div>
                <div className="mt-3">
                    <DragContainer>
                        {map(products, (product: any, index: number) => (
                            <DragItem
                                id={product.id}
                                onMoveItem={onMoveProduct}
                                index={index}
                                onDrop={onDropProduct}
                            >
                                <div className="row border-bottom py-3">
                                    <Icon
                                        name="reorder"
                                        className="text-primary col-1"
                                    />
                                    <div className="col-10">
                                        {product[Messages.getLanguage()].name}
                                    </div>
                                    <Icon
                                        name="delete"
                                        className="text-primary col-1"
                                        onClick={() =>
                                            onRemoveProducts([product?.id])
                                        }
                                    />
                                </div>
                            </DragItem>
                        ))}
                    </DragContainer>
                </div>
            </div>
        </div>
    );
};

export default BlockDetail;
