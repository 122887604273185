import React, { useContext } from "react";
import LanguageSelectButton from "../../../common/button/LanguageSelectButton";
import { ProductDetailContext } from "../../../context/product";

const ProductLanguageSelect = () => {
    const { productLang, setProductLang } = useContext(ProductDetailContext);
    return (
        <LanguageSelectButton lang={productLang} onChange={setProductLang} />
    );
};

export default ProductLanguageSelect;
