import { Dropdown, Menu } from "antd";
import classNames from "classnames";
import {
    AvatarName,
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, findIndex, map, reduce, toUpper } from "lodash";
import { useContext, useState } from "react";
import Carousel, { Modal as ModalImage, ModalGateway } from "react-images";
import OrderAPI from "../../../api/order/OrderAPI";
import UserAvatarName from "../../user/common/UserAvatarName";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import Image from "../../../common/Image";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadField from "../../../common/upload/UploadField";
import {
    REFUND_STATUS,
    REFUND_STATUSES,
} from "../../../constant/refundRequest";
import { OrderDetailContext } from "../../../context/order";
import {
    OrderRefundApprovalSchema,
    OrderRefundBankSchema,
    OrderRefundCompleteSchema,
} from "../../../formschema/order";
import { IOrderRefund, IOrderRefundProduct } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import { OrderDetailProductView } from "./OrderDetailProducts";

interface IRefundDetail {
    refund: IOrderRefund;
    index: number;
}

const REFUND_ACTIONS = [
    { id: "addBank", label: "addBank" },
    { id: "reset", label: "resetRefund" },
];

interface IRefundBankAddModal {
    refundId: string;
    open: boolean;
    onClose: () => void;
}

interface IInfoRow {
    title: any;
    content?: any;
}

interface IRefundConfirmModal {
    refundId: string;
    open: boolean;
    onClose: () => void;
}

const InfoRow = ({ title, content }: IInfoRow) => {
    return (
        <div className="d-flex align-items-center mt-2">
            <text className="font-weight-bold">{`${title}: `}</text>
            <div className="ml-1">{content}</div>
        </div>
    );
};

const RefundBankAddModal = ({
    refundId,
    open,
    onClose,
}: IRefundBankAddModal) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);

    const bankForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderRefundBankSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: OrderAPI.addRefundBank,
                    params: [orderDetail?.id, refundId, values],
                },
                (order: any) => {
                    Notifications.showSuccess(Messages.addBankSuccessfully);
                    setOrderDetail(order);
                    onClose();
                }
            );
        },
    });

    const classNameInput = classNames("col-12 mt-3");
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.addBank}
            onSave={() => bankForm.handleSubmit()}
        >
            <div className="row">
                <InputTextForm
                    form={bankForm}
                    keyData="bankName"
                    className={classNameInput}
                />
                <InputTextForm
                    form={bankForm}
                    keyData="accountName"
                    className={classNameInput}
                />
                <InputTextForm
                    form={bankForm}
                    keyData="accountNo"
                    className={classNameInput}
                />
            </div>
        </Modal>
    );
};

const RefundItemsButton = ({
    products,
}: {
    products: IOrderRefundProduct[];
}) => {
    const { orderDetail } = useContext(OrderDetailContext);

    const [showItems, setShowItems] = useState(false);
    const refundProducts = filter(products, (item) => !!item.quantity);
    const quantity = reduce(
        refundProducts,
        (sum, item) => sum + item.quantity,
        0
    );

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "id",
            render: (id) => {
                const orderProduct = find(
                    orderDetail?.products,
                    (item) => item.id === id
                );
                return <OrderDetailProductView product={orderProduct as any} />;
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
        },
    ];

    return (
        <div className="ml-2">
            <Button
                onClick={() => setShowItems(true)}
                className=""
                variant="outline"
            >
                {`${Messages.viewList} (${quantity})`}
            </Button>
            <Modal
                open={showItems}
                onClose={() => setShowItems(false)}
                title={Messages.items}
            >
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={refundProducts}
                    pagination={false}
                />
            </Modal>
        </div>
    );
};

const RefundAttachments = ({ refund }: { refund: IOrderRefund }) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const { attachments, status } = refund;

    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });

    const hiddenUpload =
        status === REFUND_STATUS.REFUNDED || status === REFUND_STATUS.REJECTED;

    const onClickAttachment = (slip: string) =>
        setImageToView({
            isVisible: true,
            index: findIndex(attachments, (item) => item === slip),
        });

    const onAddAttachment = (value: any) => {
        // const attachments = map(files, (item) => item.url);
        Progress.show(
            {
                method: OrderAPI.addRefundAttachment,
                params: [orderDetail?.id, refund?.id, [value?.url]],
            },
            (order) => {
                setOrderDetail(order);
                Notifications.showSuccess(Messages.addAttachmentSuccess);
            }
        );
    };

    return (
        <div className="mt-2">
            <label>{Messages.attachments}</label>
            <div className="d-flex mt-2 overflow-scroll scroll-hide-indicator">
                {map(attachments, (url) => (
                    <Image
                        src={url}
                        onClick={() => onClickAttachment(url)}
                        className="image-reg-large mr-2"
                    />
                ))}
            </div>

            <ModalGateway>
                {imageToView.isVisible ? (
                    <ModalImage
                        onClose={() => setImageToView({ isVisible: false })}
                    >
                        <Carousel
                            views={map(attachments, (url) => ({
                                source: url,
                            }))}
                            currentIndex={imageToView.index}
                        />
                    </ModalImage>
                ) : null}
            </ModalGateway>

            {!hiddenUpload && (
                <UploadField onChange={onAddAttachment} className="mt-2" />
            )}
        </div>
    );
};

const RefundBanks = ({ refund }: { refund: IOrderRefund }) => {
    const { banks } = refund;

    if (!banks?.length) return <div />;

    return (
        <div className="mt-2">
            <label>{Messages.refundToBankAccount}</label>
            {map(banks, (bank) => (
                <div className="bg-primary-trans-15 p-3 mt-2">
                    <InfoRow
                        content={bank?.bankName}
                        title={Messages.bankName}
                    />
                    <InfoRow
                        content={bank?.accountName}
                        title={Messages.accountName}
                    />
                    <InfoRow
                        content={bank?.accountNo}
                        title={Messages.accountNo}
                    />
                </div>
            ))}
        </div>
    );
};

const RefundConfirmModal = ({
    refundId,
    open,
    onClose,
}: IRefundConfirmModal) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);

    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderRefundApprovalSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: OrderAPI.confirmRefund,
                    params: [orderDetail?.id, refundId, values],
                },
                (order: any) => {
                    setOrderDetail(order);
                    onClose();
                    Notifications.showSuccess(Messages.refundConfirmed);
                }
            );
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.confirm}
            onSave={() => confirmForm.handleSubmit()}
        >
            <div>
                <div>{Messages.goingApproveRefund}</div>
                <InputTextForm form={confirmForm} keyData="remark" multiple />
            </div>
        </Modal>
    );
};

const RefundRejectModal = ({
    refundId,
    open,
    onClose,
}: IRefundConfirmModal) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);

    const rejectForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderRefundApprovalSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: OrderAPI.rejectRefund,
                    params: [orderDetail?.id, refundId, values],
                },
                (order: any) => {
                    setOrderDetail(order);
                    onClose();
                    Notifications.showSuccess(Messages.refundRejected);
                }
            );
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.reject}
            onSave={() => rejectForm.handleSubmit()}
        >
            <div>
                <div>{Messages.goingRejectRefund}</div>
                <InputTextForm form={rejectForm} keyData="remark" multiple />
            </div>
        </Modal>
    );
};

const RefundCompleteModal = ({
    refundId,
    open,
    onClose,
}: IRefundConfirmModal) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);

    const completeForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderRefundCompleteSchema,
        onSubmit: (values: any) => {
            const input = {
                remark: values?.remark,
                attachments: map(values?.attachments, (item) => item.url),
            };
            Progress.show(
                {
                    method: OrderAPI.completeRefund,
                    params: [orderDetail?.id, refundId, input],
                },
                (order: any) => {
                    setOrderDetail(order);
                    onClose();
                    Notifications.showSuccess(Messages.completeRefundSuccess);
                }
            );
        },
    });
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.completeRefund}
            onSave={() => completeForm.handleSubmit()}
        >
            <div>
                <label>{Messages.attachments}</label>
                <ButtonFileUpload
                    onChange={(value: any) =>
                        completeForm.setFieldValue("attachments", value)
                    }
                    maxFiles={5}
                    className="mt-3"
                />
                <InputTextForm
                    form={completeForm}
                    keyData="remark"
                    multiple
                    className="mt-3"
                />
            </div>
        </Modal>
    );
};

const RefundDirectorApproval = ({ refund }: { refund: IOrderRefund }) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const { status, confirmRefund, rejectRefund } = refund;
    const hiddenButtons = status !== REFUND_STATUS.PENDING;
    const showConfirm =
        status === REFUND_STATUS.CONFIRMED || status === REFUND_STATUS.REFUNDED;
    const showReject = status === REFUND_STATUS.REJECTED;
    return (
        <div className="mt-2">
            <label>{Messages.directorApproval}</label>
            <div
                className="bg-primary-trans-15 p-3 mt-2 d-flex"
                hidden={hiddenButtons}
            >
                <Button onClick={() => setOpenConfirm(true)} className="mr-3">
                    {Messages.confirm}
                </Button>
                <Button
                    onClick={() => setOpenReject(true)}
                    className="mr-3"
                    color="error"
                >
                    {Messages.reject}
                </Button>
            </div>
            <div
                className="bg-primary-trans-15 p-3 mt-2 "
                hidden={!showConfirm}
            >
                <InfoRow
                    title={Messages.confirmBy}
                    content={
                        <UserAvatarName
                            user={confirmRefund?.createBy}
                            placeholder="NAN"
                            size="xx-small"
                        />
                    }
                />

                <InfoRow
                    title={Messages.confirmedDate}
                    content={TimeUtils.toDateTime(confirmRefund?.createdAt)}
                />
                <InfoRow
                    title={Messages.remark}
                    content={confirmRefund?.remark}
                />
            </div>
            <div className="bg-primary-trans-15 p-3 mt-2 " hidden={!showReject}>
                <InfoRow
                    title={Messages.rejectBy}
                    content={
                        <UserAvatarName
                            user={rejectRefund?.createBy}
                            placeholder="NAN"
                            size="xx-small"
                        />
                    }
                />

                <InfoRow
                    title={Messages.rejectedAt}
                    content={TimeUtils.toDateTime(rejectRefund?.createdAt)}
                />
                <InfoRow
                    title={Messages.remark}
                    content={rejectRefund?.remark}
                />
            </div>

            {openConfirm && (
                <RefundConfirmModal
                    refundId={refund?.id}
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                />
            )}

            {openReject && (
                <RefundRejectModal
                    refundId={refund?.id}
                    open={openReject}
                    onClose={() => setOpenReject(false)}
                />
            )}
        </div>
    );
};

const RefundAccountingConfirm = ({ refund }: { refund: IOrderRefund }) => {
    const [openConfirm, setOpenConfirm] = useState(false);

    const { status, completeRefund } = refund;
    const hiddenButtons = status !== REFUND_STATUS.CONFIRMED;
    const showConfirm = status === REFUND_STATUS.REFUNDED;

    if (status === REFUND_STATUS.PENDING || status === REFUND_STATUS.REJECTED) {
        return <div />;
    }

    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });

    const onClickAttachment = (slip: string) =>
        setImageToView({
            isVisible: true,
            index: findIndex(
                completeRefund?.attachments,
                (item) => item === slip
            ),
        });

    return (
        <div className="mt-2">
            <label>{Messages.directorApproval}</label>
            <div
                className="bg-primary-trans-15 p-3 mt-2 d-flex"
                hidden={hiddenButtons}
            >
                <Button onClick={() => setOpenConfirm(true)} className="mr-3">
                    {Messages.completeRefund}
                </Button>
            </div>
            <div
                className="bg-primary-trans-15 p-3 mt-2 "
                hidden={!showConfirm}
            >
                <InfoRow
                    title={Messages.confirmBy}
                    content={
                        <UserAvatarName
                            user={completeRefund?.createBy}
                            placeholder="NAN"
                            size="xx-small"
                        />
                    }
                />

                <InfoRow
                    title={Messages.confirmedDate}
                    content={TimeUtils.toDateTime(completeRefund?.createdAt)}
                />

                <InfoRow title={Messages.attachments} />
                <div className="d-flex mt-2 overflow-scroll scroll-hide-indicator">
                    {map(completeRefund?.attachments, (url) => (
                        <Image
                            src={url}
                            onClick={() => onClickAttachment(url)}
                            className="image-reg-large mr-2"
                        />
                    ))}
                </div>

                <ModalGateway>
                    {imageToView.isVisible ? (
                        <ModalImage
                            onClose={() => setImageToView({ isVisible: false })}
                        >
                            <Carousel
                                views={map(
                                    completeRefund?.attachments,
                                    (url) => ({
                                        source: url,
                                    })
                                )}
                                currentIndex={imageToView.index}
                            />
                        </ModalImage>
                    ) : null}
                </ModalGateway>

                <InfoRow
                    title={Messages.remark}
                    content={completeRefund?.remark}
                />
            </div>

            {openConfirm && (
                <RefundCompleteModal
                    refundId={refund?.id}
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                />
            )}
        </div>
    );
};

const RefundDetail = ({ refund, index }: IRefundDetail) => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);

    const { createBy, createdAt, typeOfRefund, status, remark, products } =
        refund;

    const [openAddBank, setOpenAddBank] = useState(false);

    const refundStatus = find(REFUND_STATUSES, (item) => item.id === status);

    const menu = (
        <Menu>
            {map(REFUND_ACTIONS, (action) => (
                <Menu.Item
                    key={action.id}
                    onClick={() => onRefundAction(action.id)}
                >
                    {(Messages as any)[action.label]}
                </Menu.Item>
            ))}
        </Menu>
    );

    const onRefundAction = (actionId: string) => {
        switch (actionId) {
            case "addBank":
                setOpenAddBank(true);
                break;

            case "reset":
                onResetRefund();
                break;
            default:
        }
    };

    const onResetRefund = () => {
        if (
            status !== REFUND_STATUS.CONFIRMED &&
            status !== REFUND_STATUS.REJECTED
        ) {
            Notifications.showError(Messages.onlyResetRefundStatus);
            return;
        }

        Progress.show(
            {
                method: OrderAPI.resetRefund,
                params: [orderDetail?.id, refund?.id],
            },
            (order) => {
                setOrderDetail(order);
                Notifications.showSuccess(Messages.resetRefundSuccess);
            }
        );
    };

    return (
        <div className="border-dashed p-3 mb-3">
            <div className="flex-row-between-center">
                <label>{`${toUpper(Messages.refundRequest)} ${index}`}</label>
                <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    // className="flex-center w-100"
                >
                    <div className="cursor-pointer text-primary">
                        {Messages.action}
                    </div>
                </Dropdown>
            </div>
            <InfoRow
                title={Messages.requestedBy}
                content={
                    <UserAvatarName
                        user={createBy}
                        placeholder="NAN"
                        size="xx-small"
                    />
                }
            />
            <InfoRow
                title={Messages.dateOfRequest}
                content={TimeUtils.toDateTime(createdAt)}
            />
            <InfoRow title={Messages.type} content={typeOfRefund} />
            <InfoRow
                title={Messages.status}
                content={
                    <div style={{ color: refundStatus?.color }}>
                        {(Messages as any)?.[refundStatus?.label as any]}
                    </div>
                }
            />
            <InfoRow title={Messages.reason} content={remark} />
            <InfoRow
                title={Messages.itemsToRefund}
                content={<RefundItemsButton products={products} />}
            />

            <RefundAttachments refund={refund} />
            <RefundBanks refund={refund} />
            <RefundDirectorApproval refund={refund} />
            <RefundAccountingConfirm refund={refund} />

            {openAddBank && (
                <RefundBankAddModal
                    refundId={refund?.id}
                    open={openAddBank}
                    onClose={() => setOpenAddBank(false)}
                />
            )}
        </div>
    );
};

const OrderDetailRefundRequest = () => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { refunds } = orderDetail;
    return (
        <div className="card-container mt-3 p-4">
            <label>{Messages.refundRequest}</label>

            {map(refunds, (refund, index) => (
                <RefundDetail index={index + 1} refund={refund} />
            ))}
        </div>
    );
};

export default OrderDetailRefundRequest;
