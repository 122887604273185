import { ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import InputDataList from "../../../common/input/InputDataList";
import { CategoryDetailContext } from "../../../context/category";
import Messages from "../../../languages/Messages";

const CategoryDetailWorkProgress = () => {
    const { categoryForm } = useContext(CategoryDetailContext);
    const { workProgress } = categoryForm?.values ?? {};
    return (
        <ViewCollapse label={Messages.item}>
            <InputDataList
                value={workProgress}
                onChange={(process) =>
                    categoryForm.setFieldValue("workProgress", process)
                }
            />
        </ViewCollapse>
    );
};

export default CategoryDetailWorkProgress;
