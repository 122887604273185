import * as Yup from "yup";

export const BookingCreateSchema = Yup.object().shape({
    products: Yup.array().required("Items required!").min(1, "Items required!"),
    serviceDate: Yup.string().required("Service date required!"),
    sourceBookingId: Yup.string().required("Source booking required!"),
    customer: Yup.object().required("Customer required!"),
    addresses: Yup.array()
        .required("Service addresses required!")
        .min(1, "Service addresses required!"),
    billing: Yup.object().required("Billing required!"),
});

export const BookingCancelSchema = Yup.object().shape({
    remark: Yup.string().required("Remark required!"),
});

export const BookingMarkCompleteSchema = Yup.object().shape({
    remark: Yup.string().required("Remark required!"),
});
