/* eslint-disable no-case-declarations */
/* eslint-disable no-await-in-loop */
import { Progress } from "antd";
import { Modal, Select, TimeUtils } from "d-react-components";
import { useFormik } from "formik";
import { ceil, forEach, maxBy, reduce } from "lodash";
import { useState } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import {
    ORDER_EXPORT_OPTIONS,
    ORDER_REPORT_BY_ITEM_FIELDS,
} from "../../../constant/order";
import { OrderExportSchema } from "../../../formschema/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import FileUtils from "../../../utils/FileUtils";
import OrderFilterForm from "./OrderFilterForm";

interface IOrderTableExportModal {
    open: boolean;
    onClose: () => void;
}

function OrderExportModal({ open, onClose }: IOrderTableExportModal) {
    const [loadingExport, setLoadingExport] = useState(false);
    const [percentageExport, setPercentageExport] = useState(0);
    const [exportType, setExportType] = useState("reportByPrice");

    const exportForm = useFormik<any>({
        initialValues: {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderExportSchema,
        onSubmit: (values: any) => {
            onClickExport(values);
        },
    });

    const onClickExport = async (filterData: any) => {
        setLoadingExport(true);

        let remainPage = true;
        let orderListResult: any[] = [];
        let pageIndex = 1;
        const pageSize = 20;
        while (remainPage) {
            const response = await OrderAPI.ordersToExport(
                "",
                { pageIndex, pageSize },
                filterData
            );
            const orderList = response?.data?.data?.order ?? [];
            const paginationData = response?.data?.data?.pagination ?? {};
            const { currentPage, totalDocs } = paginationData;
            const totalPage = ceil(totalDocs / pageSize);
            orderListResult = orderListResult.concat(orderList);
            if (currentPage === totalPage) {
                remainPage = false;
                setPercentageExport(100);
            } else {
                pageIndex += 1;
                setPercentageExport(
                    Math.round((currentPage / totalPage) * 100)
                );
            }
        }

        const fileDataOrders = getOrderFileData(orderListResult);

        const from = TimeUtils.toDate(filterData?.from);
        const to = TimeUtils.toDate(filterData?.to);
        const fileName = `OrderExport_${filterData?.exportType}_${from}_${to}`;
        FileUtils.exportToCSV(fileDataOrders, fileName);
        setLoadingExport(false);
        onClose();
    };

    const getOrderFileData = (orderList: IOrder[]) => {
        switch (exportType) {
            case "reportByPrice":
                const dataList = getDataOrderByPrice(orderList);
                return FileUtils.convertRawToFileData(
                    dataList,
                    ORDER_REPORT_BY_ITEM_FIELDS
                );

            default:
                return [];
        }
    };

    const getDataOrderByPrice = (orderList: IOrder[]) => {
        const rowDataList: any[] = [];
        const language = Messages.getLanguage();
        forEach(orderList, (order) => {
            const orderCreatedBy = order?.createBy ?? {};
            const orderCustomer = order?.customer;
            // const orderTotalDiscount =
            //     order?.discountManual + order?.discountVoucher;

            const orderTotalDiscount = order?.discountManual;

            const lineAmountTotalOrder = reduce(
                order?.products,
                (sum, item) => sum + item?.quantity * item?.salePrice,
                0
            );

            forEach(order?.splitPayment, (payment) => {
                const bankPayment = payment?.confirmation?.bankAccount;
                const slipUploadedDate = maxBy(
                    payment.proofPayment,
                    (item) => item.dateOfPayment
                );
                forEach(order.products, (product) => {
                    const lineAmount = product?.quantity * product?.salePrice;
                    const totalSkuDiscount =
                        (lineAmount * orderTotalDiscount) /
                        lineAmountTotalOrder;

                    const rowData = {
                        orderNo: order?.orderNo,
                        salePersonId: orderCreatedBy?.companyId,
                        salePerson: `${orderCreatedBy?.firstName ?? ""} ${
                            orderCreatedBy?.lastName ?? ""
                        }`,
                        //payment
                        bankAccountNo: bankPayment?.accountNo,
                        paymentMethod: payment?.paymentMethod?.type,
                        slipUploadedDate: slipUploadedDate?.dateOfPayment
                            ? TimeUtils.toDate(slipUploadedDate?.dateOfPayment)
                            : "",
                        slipUploadedTime: "00:00",
                        bankReceivingPayment: `${bankPayment?.accountNo}(${
                            (bankPayment as any)?.[language]?.bankName
                        } - ${(bankPayment as any)?.[language]?.accountName})`,
                        dateReceivingPayment: payment?.confirmation
                            ?.dateOfReceiving
                            ? TimeUtils.toDate(
                                  payment?.confirmation?.dateOfReceiving
                              )
                            : "",
                        timeReceivingPayment: payment?.confirmation
                            ?.dateOfReceiving
                            ? TimeUtils.toTime(
                                  payment?.confirmation?.dateOfReceiving
                              )
                            : "",
                        //order
                        orderStatus: order?.status,
                        orderNote: order?.note,
                        customer: `${orderCustomer?.firstName} ${orderCustomer?.lastName}`,

                        //product
                        sku: product?.SKU,
                        productName: product?.name,

                        //order
                        createdDate: TimeUtils.toDateTime(order?.createdAt),
                        confirmedDate: payment?.confirmation?.dateOfReceiving
                            ? TimeUtils.toDateTime(
                                  payment?.confirmation?.dateOfReceiving
                              )
                            : "",

                        deliveryType:
                            order?.deliveries?.[0]?.deliveryType ?? "",
                        quantity: product?.quantity,
                        unit: "PCS",
                        unitPrice: product?.salePrice,
                        lineAmount: product?.quantity * product?.salePrice,
                        totalDiscount: orderTotalDiscount,
                        totalSkuDiscount,
                        finalPrice: lineAmount - totalSkuDiscount,
                    };
                    rowDataList.push(rowData);
                });
            });
        });

        return rowDataList;
    };

    const renderMainContent = () => {
        if (loadingExport) return <Progress percent={percentageExport} />;
        return (
            <div>
                <Select
                    label={Messages.exportType}
                    dataSource={ORDER_EXPORT_OPTIONS}
                    getLabel={(item) => (Messages as any)[item.label]}
                    onChange={(value) => {
                        setExportType(value);
                    }}
                    value={exportType}
                />
                <OrderFilterForm formData={exportForm} />
            </div>
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.export}
            onSave={() => exportForm.handleSubmit()}
            size="medium"
            sideText={Messages.clearAll}
            onSideClick={() => exportForm.setValues({})}
        >
            {renderMainContent()}
        </Modal>
    );
}

export default OrderExportModal;
