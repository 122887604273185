/* eslint-disable consistent-return */
import { Button, Dropdown, Notifications, Progress } from "d-react-components";
import { filter, forEach, includes, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import VoucherAPI from "../../../../../api/voucher/VoucherAPI";
import WorksheetAPI from "../../../../../api/worksheet/WorksheetAPI";
import { PRODUCT_TYPE } from "../../../../../constant/product";
import {
    VOUCHER_OWNERSHIP_TYPES,
    VoucherOwnershipType,
} from "../../../../../constant/voucher";
import { WorksheetStatus } from "../../../../../constant/worksheet";
import { WorksheetDetailContext } from "../../../../../context/worksheet";
import { IProduct } from "../../../../../interfaces/product";

import {
    IVoucher,
    IVoucherWorksheetDetail,
} from "../../../../../interfaces/voucher";
import Messages from "../../../../../languages/Messages";
import { WorksheetDetailVoucherContext } from "../context/WorksheetDetailVoucherContext";
import WorksheetVoucherConfirmModal from "./WorksheetVoucherConfirmModal";
import WorksheetVoucherInputDrawer from "./WorksheetVoucherInputDrawer";
import WorksheetVoucherRemoveDrawer from "./WorksheetVoucherRemoveDrawer";
import VoucherSelectDrawer from "./WorksheetVoucherSelectDrawer";

export interface IWorksheetVoucherHeaderProps {
    [key: string]: any;
}

const WorksheetVoucherHeader: React.FC<IWorksheetVoucherHeaderProps> = () => {
    const { worksheetProducts, voucherWithServiceApply, ownershipVouchers } =
        useContext(WorksheetDetailVoucherContext);
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const {
        id,
        vouchers,
        isVoucherRemovable,
        customer,
        status,
        completedAt = 0,
    } = worksheetDetail;

    const [voucherConfirm, setVoucherConfirm] = useState<{
        open: boolean;
        products?: IProduct[];
        voucher?: IVoucher;
    }>({ open: false });
    const [openVoucherDrawer, setOpenVoucherDrawer] = useState<{
        open?: boolean;
        action?: "ADD" | "REMOVE";
        voucherType?: VoucherOwnershipType;
    }>({ open: false });

    const isDisableRemove = useMemo(() => {
        if (status === WorksheetStatus.COMPLETED) {
            const isMoreThan60Mins =
                moment().valueOf() - moment(completedAt).valueOf() > 60 * 3600;
            return isMoreThan60Mins;
        }
        return false;
    }, [completedAt, status]);

    const onAddAnonymousVoucher = (code: any) => {
        const allCodes = map(vouchers, (i) => i?.code);
        if (allCodes.includes(code)) {
            return Notifications.showError(Messages.codeIsAlreadyApplied);
        }
        return Progress.show(
            {
                method: VoucherAPI.checkVouchers,
                params: [[code]],
            },
            (res: any) => {
                const voucher: IVoucher = res?.[0];
                const voucherCustomerId = voucher?.ownership?.customer?.id;
                if (voucherCustomerId && voucherCustomerId !== customer?.id) {
                    return Notifications.showError(Messages.voucherIsInvalid);
                }
                processApplyVoucher(voucher);
            }
        );
    };

    const processApplyVoucher = (voucher: IVoucher) => {
        const productVoucher = voucher?.product;
        switch (productVoucher?.productType) {
            case PRODUCT_TYPE.PREMIUM_SERVICE:
                applyPremiumVoucher(voucher);
                break;
            default:
                onApplyVoucher([{ ...voucher }]);
        }
    };

    const applyPremiumVoucher = (voucher: any) => {
        const productVoucher = voucher?.product;
        const wsProductsVoucher = filter(worksheetProducts, (item) =>
            includes(
                map(productVoucher?.premiumService ?? [], (item) => item.id),
                item.id
            )
        );
        if (wsProductsVoucher.length === 0) {
            Notifications.showError(Messages.voucherOnlyApllyWorksheet);
        } else if (wsProductsVoucher.length === 1) {
            onApplyVoucher([
                { ...voucher, appliedFor: wsProductsVoucher?.[0]?.id },
            ]);
        } else {
            setTimeout(() => {
                setVoucherConfirm({
                    open: true,
                    products: wsProductsVoucher,
                    voucher,
                });
            }, 700);
        }
    };

    const onRemoveAnonymousVoucher = (codeToRemove: string) => {
        if (
            !includes(
                map(vouchers, (i) => i?.code),
                codeToRemove
            )
        ) {
            return Notifications.showError(Messages.codeIsInvalid);
        }
        return Progress.show(
            {
                method: WorksheetAPI.resetVoucher,
                params: [id, [codeToRemove]],
            },
            onSetNewData
        );
    };

    const onRemoveOwnershipVoucher = (vouchersToRemove: IVoucher[]) => {
        console.log("vouchersToRemove", vouchersToRemove);
        if (isEmpty(vouchersToRemove)) {
            return;
        }
        const codes = map(vouchersToRemove, (i) => i?.code);
        return Progress.show(
            {
                method: WorksheetAPI.resetVoucher,
                params: [id, codes],
            },
            onSetNewData
        );
    };

    const onApplyVoucher = (applyVouchers: Array<IVoucherWorksheetDetail>) => {
        const serviceApply: any[] = [];
        forEach(applyVouchers, (voucher) => {
            const { product, appliedFor, code } = voucher || {};
            if (product?.productType === PRODUCT_TYPE.PREMIUM_SERVICE) {
                serviceApply.push({
                    voucherCode: code,
                    productServiceId: appliedFor,
                });
            }
        });
        const vouchers = map(applyVouchers, (item) => item?.code);

        if (worksheetDetail?.categoryProduct?.seatConfiguration) {
            Progress.show(
                {
                    method: WorksheetAPI.applyVoucherSofa,
                    params: [{ worksheetId: id, vouchers }],
                },
                onSetNewData
            );
        } else {
            Progress.show(
                {
                    method: WorksheetAPI.applyVoucher,
                    params: [{ worksheetId: id, vouchers, serviceApply }],
                },
                onSetNewData
            );
        }
    };

    const onSetNewData = (res: any) => {
        // const {
        //     vouchers: newVouchers,
        //     serviceApply,
        //     isVoucherRemovable,
        //     status,
        // } = res?.data?.data ?? {};
        // setWorksheetDetail((prev: any) => ({
        //     ...prev,
        //     status,
        //     vouchers: newVouchers,
        //     serviceApply,
        //     isVoucherRemovable,
        // }));

        const worksheetData = res?.data?.data ?? {};
        setWorksheetDetail(worksheetData);
    };

    const onSelectDropdown = (
        action: "ADD" | "REMOVE",
        voucherType: VoucherOwnershipType
    ) => {
        setOpenVoucherDrawer({ open: true, voucherType, action });
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center">
                <label className="d-block">{Messages.voucher}</label>
                <div className="flex-center-y">
                    <Dropdown
                        dataSource={VOUCHER_OWNERSHIP_TYPES}
                        Messages={Messages}
                        onClick={(item) => {
                            // if (!isVoucherRemovable) {
                            //     return Notifications.showError(
                            //         Messages.canNotResetVoucher
                            //     );
                            // }
                            onSelectDropdown("REMOVE", item?.id as any);
                        }}
                    >
                        <Button
                            content={Messages.remove}
                            variant="outline"
                            disabled={isDisableRemove}
                        />
                    </Dropdown>
                    <Dropdown
                        className="ml-3"
                        dataSource={VOUCHER_OWNERSHIP_TYPES}
                        Messages={Messages}
                        onClick={(item) =>
                            onSelectDropdown("ADD", item?.id as any)
                        }
                    >
                        <Button
                            style={{ minWidth: "80px" }}
                            content={Messages.add}
                            disabled={status !== WorksheetStatus.DELIVERED}
                        />
                    </Dropdown>
                </div>
            </div>
            {openVoucherDrawer.open &&
                openVoucherDrawer.action === "ADD" &&
                openVoucherDrawer.voucherType ===
                    VoucherOwnershipType.OWNER_SHIP && (
                    <VoucherSelectDrawer
                        open={openVoucherDrawer.open}
                        onClose={() => setOpenVoucherDrawer({ open: false })}
                        customerId={customer?.id as string}
                        products={worksheetProducts as any}
                        onChange={(v = []) => onApplyVoucher(v as any)}
                        value={voucherWithServiceApply as any}
                    />
                )}
            {openVoucherDrawer.open &&
                openVoucherDrawer.action === "REMOVE" &&
                openVoucherDrawer.voucherType ===
                    VoucherOwnershipType.OWNER_SHIP && (
                    <WorksheetVoucherRemoveDrawer
                        open={openVoucherDrawer.open}
                        onClose={() => setOpenVoucherDrawer({ open: false })}
                        onChange={(v = []) => onRemoveOwnershipVoucher(v)}
                        value={ownershipVouchers}
                    />
                )}
            {openVoucherDrawer.open &&
                openVoucherDrawer.action === "ADD" &&
                openVoucherDrawer.voucherType ===
                    VoucherOwnershipType.ANONYMOUS && (
                    <WorksheetVoucherInputDrawer
                        open={openVoucherDrawer.open}
                        onClose={() => setOpenVoucherDrawer({ open: false })}
                        onApply={onAddAnonymousVoucher}
                    />
                )}
            {openVoucherDrawer.open &&
                openVoucherDrawer.action === "REMOVE" &&
                openVoucherDrawer.voucherType ===
                    VoucherOwnershipType.ANONYMOUS && (
                    <WorksheetVoucherInputDrawer
                        open={openVoucherDrawer.open}
                        onClose={() => setOpenVoucherDrawer({ open: false })}
                        onApply={onRemoveAnonymousVoucher}
                    />
                )}
            {voucherConfirm?.open && (
                <WorksheetVoucherConfirmModal
                    open={voucherConfirm?.open}
                    onClose={() => setVoucherConfirm({ open: false })}
                    products={voucherConfirm?.products ?? {}}
                    onSave={(product: IProduct) => {
                        const appliedVoucherQty =
                            filter(
                                voucherWithServiceApply,
                                (item) => item?.appliedFor === product?.id
                            )?.length ?? 0;
                        const leftToApplyQty =
                            (product?.quantity || 0) - appliedVoucherQty;
                        if (!(leftToApplyQty > 0)) {
                            return Notifications.showError(Messages.noQtyLeft);
                        }
                        onApplyVoucher([
                            {
                                ...(voucherConfirm?.voucher ?? {}),
                                appliedFor: product?.id,
                            } as IVoucherWorksheetDetail,
                        ]);
                        setVoucherConfirm({ open: false });
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default WorksheetVoucherHeader;
