import React from "react";
import Messages from "../../languages/Messages";

const AddMediaView = () => {
    return (
        <div className="flex-center flex-column border-dashed image-square-x-large">
            <img src="/images/placeholder.png" className="image-square-small" />
            <small className="text-center mt-1">{Messages.browseImages}</small>
        </div>
    );
};

export default AddMediaView;
