import {
    AwesomeTableComponent,
    IColumnsProps,
    Modal,
    TimeUtils,
} from "d-react-components";
import { isEmpty } from "lodash";
import React from "react";
import { generatePath } from "react-router-dom";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import AppLink from "../../../common/AppLink";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

interface IOnHoldAllocationModal {
    productId: string;
    warehouseId?: string;
    orderId?: string;

    open: boolean;
    onClose: () => void;
}

const OnHoldAllocationModal = ({
    productId,
    orderId,
    warehouseId,
    open,
    onClose,
}: IOnHoldAllocationModal) => {
    const columns: IColumnsProps = [
        {
            title: Messages.storeWarehouse,
            dataIndex: "warehouse",
            render: (warehouse) => warehouse?.[Messages.getLanguage()]?.name,
            width: 200,
        },
        {
            title: Messages.storeCode,
            dataIndex: "warehouse",
            render: (warehouse) => warehouse?.code,
            width: 200,
        },
        {
            title: Messages.onHoldQty,
            dataIndex: "quantity",
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.ref,
            dataIndex: "onHoldRef",
            render: (ref, item) => {
                if (!isEmpty(item.orderId)) {
                    return (
                        <AppLink
                            to={generatePath(Path.ORDER_DETAIL, {
                                orderId: item.orderId,
                            })}
                        >
                            {ref}
                        </AppLink>
                    );
                }
                return ref;
            },
        },
    ];

    const source = () => {
        const param = {
            productId,
            onHoldTypes: [],
            orderId,
            warehouseId,
        };
        return InventoryAPI.onHoldAllocation(param);
    };

    const transformer = (res: any) => res?.data?.data ?? [];

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.inventoryAllocation}
            size="medium"
            showFooter={false}
        >
            <AwesomeTableComponent
                columns={columns}
                source={source}
                transformer={transformer}
                isPagination={false}
                baseColumnProps={{ width: 100 }}
            />
        </Modal>
    );
};

export default OnHoldAllocationModal;
