/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import classNames from "classnames";

import {
    Button,
    Checkbox,
    Drawer,
    IDrawerProps,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import {
    filter,
    find,
    groupBy,
    includes,
    isEmpty,
    map,
    reduce,
    some,
    uniqBy,
} from "lodash";
import React, {
    Dispatch,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import AuthCode from "react-auth-code-input";
import AuthAPI from "../../../../../api/auth/AuthAPI";
import VoucherAPI from "../../../../../api/voucher/VoucherAPI";
import { VOUCHER_STATUS } from "../../../../../constant/voucher";
import {
    VERIFY_VOUCHER_METHOD,
    VERIFY_VOUCHER_METHODS,
} from "../../../../../constant/worksheet";
import { WorksheetDetailContext } from "../../../../../context/worksheet";
import { AuthOtpSchema } from "../../../../../formschema/auth";
import { useCountDown } from "../../../../../hoook/app";
import { useAppliedVouchersProductList } from "../../../../../hoook/voucher";
import {
    IProduct,
    IProductOrderCreate,
} from "../../../../../interfaces/product";
import {
    isPremiumService,
    IVoucherOrderCreate,
    IVoucherProduct,
} from "../../../../../interfaces/voucher";
import Messages from "../../../../../languages/Messages";
import ProductNameView from "../../../../product/common/ProductNameView";
import VoucherProductItem from "./VoucherProductItem";
import ObjectUtils from "../../../../../utils/ObjectUtils";
import { PRODUCT_TYPE } from "../../../../../constant/product";

export interface IVoucherSelectState
    extends Partial<IWorksheetVoucherSelectDrawerProps> {
    activeScreen?: VoucherSelectScreenType | null;
    allVoucherList?: Array<IVoucherProduct>;
    selectingVoucherList?: Array<IVoucherProduct>;
    productToApplyVoucher?: IProductApplyVoucher[];
    appliedVoucherProducts?: {
        [proId: string]: { quantity?: number; usedQty?: number };
    };
    verifyMethod: string;
    isSeatConfiguration?: boolean;
    setActiveScreen: Dispatch<VoucherSelectScreenType>;
    setAllVoucherList: Dispatch<Array<IVoucherProduct>>;
    setProductToApplyVoucher: Dispatch<IProductApplyVoucher[]>;
    setSelectingVoucherList: Dispatch<Array<IVoucherProduct>>;
    setVerifyMethod: Dispatch<string>;
}

const voucherSelectState: IVoucherSelectState = {
    verifyMethod: "",
    customerId: null as any,
    activeScreen: null,
    isSeatConfiguration: false,
    selectingVoucherList: [],
    setActiveScreen: () => {},
    setAllVoucherList: () => {},
    setProductToApplyVoucher: () => {},
    setSelectingVoucherList: () => {},
    setVerifyMethod: () => {},
};

export const VoucherSelectContext = React.createContext(voucherSelectState);

export interface IWorksheetVoucherSelectDrawerProps extends IDrawerProps {
    onChange?: (value: IWorksheetVoucherSelectDrawerProps["value"]) => any;
    customerId: string;
    value?: Array<IVoucherOrderCreate>;
    products?: IProductOrderCreate[];
    initScreen?: VoucherSelectScreenType;
}

export interface IProductApplyVoucher extends IProduct {
    availableVouchers?: IVoucherProduct[];
    availableVouchersQty?: number;
    quantity?: number;
}

export enum VoucherSelectScreenType {
    SELECT_VOUCHER = "SELECT_VOUCHER",
    SELECT_SERVICE = "SELECT_SERVICE",
    SELECT_VERIFY_METHOD = "SELECT_VERIFY_METHOD",
    APPLY_CODE = "APPLY_CODE",
}

const VoucherAuthorizationCodeInput = () => {
    const { worksheetDetail } = useContext(WorksheetDetailContext);
    const [timeLeft, { start }] = useCountDown(60 * 1000, 1000);

    const {
        verifyMethod,
        setActiveScreen,
        onClose,
        onChange,
        customerId,
        selectingVoucherList,
        productToApplyVoucher,
    } = useContext(VoucherSelectContext);
    const verifyForm = useFormik<any>({
        initialValues: {
            code: undefined,
            otpToken: "",
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AuthOtpSchema,
        onSubmit: (values: any) => {
            const input = {
                customerId,
                code: values?.code,
                otpToken: values?.otpToken,
                verifyType: verifyMethod,
            };
            Progress.show(
                {
                    method: AuthAPI.verifyOtpAuthGrantVoucherOwnership,
                    params: [input],
                },
                onProcessAuthenticated,
                () => {
                    verifyForm.setFieldValue("code", "");
                }
            );
        },
    });

    useEffect(() => {
        verifyMethod === VERIFY_VOUCHER_METHOD.SMS_OTP && requestSmsOtp();
    }, [verifyMethod]);

    const requestSmsOtp = () => {
        if (timeLeft > 0) return;
        AuthAPI.requestSendOtp({
            phone: worksheetDetail?.customer?.phone,
        }).then((res) => {
            const tokenRes = res?.data?.data;
            if (!isEmpty(tokenRes?.token)) {
                verifyForm.setFieldValue("otpToken", tokenRes?.token);
                start();
            }
        });
    };

    const onProcessAuthenticated = (res: any) => {
        const isValid = res?.data?.data;
        if (isValid) {
            const applyingVouchers: any[] = [];
            selectingVoucherList?.forEach((voucherPro) => {
                const {
                    quantity,
                    codesLeft = [],
                    product,
                    voucherCodes,
                } = voucherPro || {};

                if (quantity && quantity > 0) {
                    // ----- NEED TO UPDATE
                    const productToApply = find(
                        productToApplyVoucher,
                        (item) => item.id === product.id
                    );
                    Array.from(Array(quantity).keys()).forEach((n, i) => {
                        applyingVouchers.push({
                            code: codesLeft?.[i],
                            appliedFor: productToApply?.id,
                            product,
                            voucherCodes,
                        });
                    });
                }
            });
            onChange && onChange(applyingVouchers);
            onClose && onClose(res);
        } else {
            Notifications.showError(Messages.codeIsInvalid);
        }
    };

    const renderAdminTokenInput = () => {
        return (
            <div className="p-3">
                <div className="my-3">{Messages.enterDigitAdminToken}</div>
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
            </div>
        );
    };
    const renderCustomerAppOtpInput = () => {
        return (
            <div className="p-3">
                <div className="my-3">{Messages.enterDigitAdminToken}</div>
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
            </div>
        );
    };

    const renderCustomerSmsOtp = () => {
        return (
            <div className="p-3">
                <div
                    className="my-3"
                    dangerouslySetInnerHTML={{
                        __html: `<div>${Messages.inputDigitPhoneNumber?.replace(
                            "%phoneNumber",
                            `<text style='color:rgba(244,67,54,1)' >${worksheetDetail?.customer?.phone}</text>`
                        )}</div>`,
                    }}
                />
                <AuthCode
                    onChange={(code) => verifyForm.setFieldValue("code", code)}
                    containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                    inputClassName="auth-code__input"
                    allowedCharacters="numeric"
                    autoFocus
                    placeholder=""
                />
                <div className="flex-column flex-center mt-3">
                    <div>{Messages.didNotReceiveTheCode}</div>
                    <div
                        className={classNames(
                            "text mt-1 text-primary cursor-pointer",
                            {
                                "text-disabled": timeLeft > 0,
                            }
                        )}
                        onClick={requestSmsOtp}
                    >
                        {Messages.resendNowSecond.replace(
                            "%second",
                            `${timeLeft / 1000}`
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderCodeInput = () => {
        switch (verifyMethod) {
            case VERIFY_VOUCHER_METHOD.ADMIN_TOKEN:
                return renderAdminTokenInput();
            case VERIFY_VOUCHER_METHOD.CUSTOMER_APP:
                return renderCustomerAppOtpInput();
            case VERIFY_VOUCHER_METHOD.SMS_OTP:
                return renderCustomerSmsOtp();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="flex-center-y w-100 border-bottom">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.authorizationCode}
                </div>
            </div>
            {renderCodeInput()}

            <div className="flex-center-y position-absolute w-100 p-3 border-top start-0 end-0 bottom-0 justify-content-between">
                <Button
                    content={Messages.back}
                    className=""
                    variant="outline"
                    onClick={() => {
                        //@ts-ignore
                        return setActiveScreen(
                            VoucherSelectScreenType.SELECT_VERIFY_METHOD
                        );
                    }}
                />
                <Button
                    content={Messages.apply}
                    className=""
                    onClick={() => {
                        verifyForm.handleSubmit();
                    }}
                />
            </div>
        </div>
    );
};

const VoucherVerifyMethodSelect = () => {
    const { worksheetDetail } = useContext(WorksheetDetailContext);
    const { setActiveScreen, verifyMethod, setVerifyMethod } =
        useContext(VoucherSelectContext);

    const verifyMethodObject = find(
        VERIFY_VOUCHER_METHODS,
        (item) => item.id === verifyMethod
    );

    const verifyMethodDesc = verifyMethodObject?.description;
    return (
        <div>
            <div className="flex-center-y w-100 border-bottom">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.authorization}
                </div>
            </div>
            <div className="p-3">
                <Select
                    label={Messages.verificationMethod}
                    dataSource={VERIFY_VOUCHER_METHODS}
                    onChange={setVerifyMethod}
                    value={verifyMethod}
                />
                {verifyMethodDesc && (
                    <div
                        className="mt-2 small"
                        dangerouslySetInnerHTML={{
                            __html: `<div>${(Messages as any)[
                                verifyMethodDesc
                            ]?.replace(
                                "%phoneNumber",
                                `<text style='color:rgba(244,67,54,1)' >${worksheetDetail?.customer?.phone}</text>`
                            )}</div>`,
                        }}
                    />
                )}
            </div>
            <div className="flex-center-y position-absolute w-100 p-3 border-top start-0 end-0 bottom-0 justify-content-between">
                <Button
                    content={Messages.back}
                    variant="outline"
                    onClick={() => {
                        setActiveScreen(VoucherSelectScreenType.SELECT_VOUCHER);
                    }}
                />
                <Button
                    content={Messages.continue}
                    onClick={() => {
                        setActiveScreen(VoucherSelectScreenType.APPLY_CODE);
                    }}
                />
            </div>
        </div>
    );
};

const VoucherSelectList = () => {
    const {
        selectingVoucherList,
        setSelectingVoucherList,
        productToApplyVoucher = [],
        isSeatConfiguration,
    } = useContext(VoucherSelectContext) || {};

    const { worksheetDetail } = useContext(WorksheetDetailContext);

    const worksheetTotalSeat = useMemo(
        () =>
            reduce(
                worksheetDetail.services,
                (sum, service) =>
                    sum +
                    (service?.quantity ?? 0) *
                        (service?.product?.numberOfSeat ?? 0),
                0
            ),
        [productToApplyVoucher]
    );

    const totalSeatVoucherApplying = useMemo(
        () =>
            reduce(
                selectingVoucherList,
                (sum, voucher) => {
                    const { quantity = 0, product } = voucher ?? {};

                    const isPremium =
                        product?.productType === PRODUCT_TYPE.PREMIUM_SERVICE;
                    if (isPremium) {
                        return sum + quantity * 2;
                    }
                    return sum + quantity * (product?.numberOfSeat ?? 0);
                },
                0
            ),
        [JSON.stringify(selectingVoucherList)]
    );

    useEffect(() => {
        // ----- NEED TO UPDATE
        const selectingVoucherList = reduce(
            productToApplyVoucher,
            (allVouchers, product) =>
                [...allVouchers, ...(product?.availableVouchers ?? [])] as any,
            []
        );

        const uniqVoucherList = uniqBy(
            selectingVoucherList,
            (item: any) => item?.product?.id
        );
        setSelectingVoucherList(uniqVoucherList ?? []);
        // setSelectingVoucherList(productToApplyVoucher?.availableVouchers ?? []);
    }, [JSON.stringify(productToApplyVoucher)]);

    /**
     * If selecting vouchers include premium voucher =>
     * total premium vouchers can not over then total quantity of remaining service - service's voucher applied
     */
    const onValidateChangeQuantity = (itemToUpdate: IVoucherProduct) => {
        const updatedList = map(selectingVoucherList, (v) =>
            v?.product?.id === itemToUpdate?.product?.id ? itemToUpdate : v
        );

        const premiumVoucher = find(
            updatedList,
            (item) =>
                item?.product?.productType === PRODUCT_TYPE.PREMIUM_SERVICE &&
                (item?.quantity ?? 0) > 0
        );
        // If include premium voucher
        if (!!premiumVoucher) {
            const serviceIdsInPremium = map(
                premiumVoucher?.product?.premiumService,
                (item) => item.id
            );

            let possibleQuantityPremiumVoucher = 0;
            let totalServiceInPremiumApplied = 0;

            for (
                let index = 0;
                index < productToApplyVoucher.length;
                index += 1
            ) {
                const productToApply = productToApplyVoucher[index];
                const isIncludeInPremium = includes(
                    serviceIdsInPremium,
                    productToApply.id
                );
                if (isIncludeInPremium) {
                    possibleQuantityPremiumVoucher +=
                        productToApply?.quantity ?? 0;

                    const voucherProduct = find(
                        updatedList,
                        (voucher) => voucher?.product?.id === productToApply.id
                    );
                    totalServiceInPremiumApplied +=
                        voucherProduct?.quantity ?? 0;
                }
            }

            const maximumPremiumVoucherQty =
                possibleQuantityPremiumVoucher - totalServiceInPremiumApplied;
            if (maximumPremiumVoucherQty < (premiumVoucher?.quantity ?? 0)) {
                Notifications.showError(
                    Messages.canNotSelectQuantityOverService
                );
                return false;
            }
        }

        return true;
    };

    /**
     * Total of voucher applying seats can not over total remaining seat of worksheet
     * Total seat of premium voucher = 2 seats * voucher's quantity
     * Total seat of normal voucher = product's no of seats * voucher's quantity
     */
    const onValidateChangeSeatConfig = (updatedList: IVoucherProduct[]) => {
        const totalSeatVoucherApplied = reduce(
            updatedList,
            (sum, voucher) => {
                const isPremium =
                    voucher?.product?.productType ===
                    PRODUCT_TYPE.PREMIUM_SERVICE;
                if (isPremium) {
                    return sum + (voucher?.quantity ?? 0) * 2;
                }
                return (
                    sum +
                    (voucher?.quantity ?? 0) *
                        (voucher?.product?.numberOfSeat ?? 0)
                );
            },
            0
        );

        if (
            totalSeatVoucherApplied >
            (worksheetDetail?.totalRemainingSeat ?? 0) + 1
        ) {
            Notifications.showError(Messages.canNotSelectQuantityOverTotalSeat);
            return false;
        }
        return true;
    };

    /**
     * Need to validate voucher's quantity every time updating
     * New quantity can not over voucher's quantity
     * If updating not premium voucher => voucher's quantity can not over number of service
     * Then need to validate specific two cases
     * 1. Service is sofa service (has seat configuration)
     * 2. Others services
     */
    const onChangeItem = (itemToUpdate: IVoucherProduct) => {
        const { quantity = 0, voucherQuantity = 0 } = itemToUpdate ?? {};

        // New quantity can not over voucher's quantity
        if (quantity > voucherQuantity) {
            Notifications.showError(Messages.canNotSelectQuantityVoucher);
            return false;
        }

        // If updating not premium voucher => voucher's quantity can not over number of service
        const isUpdatingPremiumVoucher =
            itemToUpdate?.product?.productType === PRODUCT_TYPE.PREMIUM_SERVICE;
        if (!isUpdatingPremiumVoucher) {
            const productToApply = find(
                productToApplyVoucher,
                (item) => item.id === itemToUpdate.product?.id
            );

            if (!productToApply || (productToApply.quantity ?? 0) < quantity) {
                Notifications.showError(
                    Messages.canNotSelectQuantityOverService
                );
                return false;
            }
        }

        const updatedList = map(selectingVoucherList, (v) =>
            v?.product?.id === itemToUpdate?.product?.id ? itemToUpdate : v
        );

        // Sofa services
        if (isSeatConfiguration) {
            const validOnChangeSeatConfig =
                onValidateChangeSeatConfig(updatedList);
            if (!validOnChangeSeatConfig) {
                return;
            }
            setSelectingVoucherList(updatedList);
            return;
        }

        // Others services
        const validOnChangeProduct = onValidateChangeQuantity(itemToUpdate);
        if (validOnChangeProduct) {
            setSelectingVoucherList(updatedList);
        }
    };

    const renderItem = ({ item, index }: any) => {
        // const otherList = filter(
        //     selectingVoucherList,
        //     (i) => i?.product?.id !== item?.product?.id
        // );
        // const otherQty = reduce(
        //     otherList,
        //     (res = 0, i) => (res += i?.quantity ?? 0),
        //     0
        // );
        // // ----- NEED TO UPDATE
        // const productToApply = find(
        //     productToApplyVoucher,
        //     (product) => product.id === item?.product?.id
        // );
        // const maxQty = Math.min(
        //     item?.quantityLeft ?? 0,
        //     (productToApply?.quantity || 0) - otherQty
        // );

        return (
            <VoucherProductItem
                voucherProduct={item as any}
                showQtyInput
                // inputQuantityProps={{ max: maxQty }}
                onChange={(voucher) => {
                    onChangeItem(voucher as any);
                }}
                getQty={(item) => item?.quantityLeft ?? 0}
            />
        );
    };

    const renderSummaryRow = (label: string, content: any) => {
        return (
            <div className="flex-row-between-center py-2 border-bottom border-primary">
                <div className="text-primary font-weight-bold">{label}</div>
                <div>{content}</div>
            </div>
        );
    };

    const renderTotalSeatSummary = () => {
        if (!isSeatConfiguration) {
            return <div />;
        }
        const totalSeatToBeBilled =
            (worksheetDetail?.totalRemainingSeat ?? 0) -
            totalSeatVoucherApplying;
        return (
            <div className="bg-primary-trans-15 p-3">
                {renderSummaryRow(Messages.totalSeat, worksheetTotalSeat)}
                {renderSummaryRow(
                    Messages.voucherAppliedSeat,
                    worksheetTotalSeat - totalSeatToBeBilled
                )}
                {renderSummaryRow(
                    Messages.remainingSeatBilled,
                    totalSeatToBeBilled
                )}
            </div>
        );
    };

    const renderProductToApplySubContent = (product: any) => {
        if (isSeatConfiguration) {
            return (
                <React.Fragment>
                    <div className="flex-center-y">
                        <small>{Messages.serviceQty}: </small>
                        <div className="ml-2">{product?.totalQty}</div>
                    </div>

                    <div className="flex-center-y">
                        <small>{Messages.totalSeat}:</small>
                        <div className="ml-2">
                            {product?.numberOfSeat * product?.totalQty}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className="flex-center-y">
                    <small>{Messages.serviceQty}: </small>
                    <div className="ml-2">{product?.quantity}</div>
                </div>
                <div className="flex-center-y">
                    <small>{Messages.availableVoucherQty}:</small>
                    <div className="ml-2">{product?.availableVouchersQty}</div>
                </div>
            </React.Fragment>
        );
    };

    const renderListVoucher = () => {
        return (
            <div>
                <div className="flex-center-y w-100 border-bottom py-3">
                    <div className="h4 text-primary w-100 text-center">
                        {Messages.selectVoucher}
                    </div>
                </div>
                {renderTotalSeatSummary()}
                {map(productToApplyVoucher, (product: any) => {
                    return (
                        <ProductNameView
                            className="flex-row mt-2"
                            classNameContent="bg-primary w-100 text-white mx-0 p-3"
                            classNameLabel="text-white"
                            styleImage={{ height: "auto", width: "100px" }}
                            product={product}
                            subContent={renderProductToApplySubContent(product)}
                        />
                    );
                })}
                <div>
                    {map(selectingVoucherList, (item, index) =>
                        renderItem({ item, index })
                    )}
                </div>
            </div>
        );
    };

    return <React.Fragment>{renderListVoucher()}</React.Fragment>;
};

const VoucherSelectService: React.FC<any> = () => {
    const {
        products,
        allVoucherList,
        productToApplyVoucher = [],
        appliedVoucherProducts,
        setProductToApplyVoucher,
        setActiveScreen,
    } = useContext(VoucherSelectContext);
    const groupedProds = groupBy(products, (item) => item?.id);
    console.log("appliedVoucherProducts", appliedVoucherProducts);
    const listToSelect = map(groupedProds, (items, key) => {
        const pro = items?.[0];
        const prevQty = appliedVoucherProducts?.[pro?.id];
        const leftToApplyQty =
            (prevQty?.quantity || 0) - (prevQty?.usedQty || 0);
        const availableVouchers: IVoucherProduct[] = [];

        const filteredVoucherList = filter(
            allVoucherList,
            (i) => (i?.quantityLeft ?? 0) > 0
        );
        filteredVoucherList?.forEach((item) => {
            if (isPremiumService(item)) {
                if (
                    map(item?.product?.premiumService, (i) => i?.id).includes(
                        key
                    )
                ) {
                    availableVouchers.push(item);
                }
            } else if (item?.product?.id === key) {
                availableVouchers.push(item);
            }
        });
        const availableVouchersQty = reduce(
            availableVouchers,
            (res = 0, i) => (res += i?.quantityLeft ?? 0),
            0
        );

        return {
            ...pro,
            availableVouchers,
            availableVouchersQty,
            quantity: leftToApplyQty,
            totalQty: prevQty?.quantity ?? 0,
        };
    });

    const isProductSelected = (product: any) => {
        return !!find(productToApplyVoucher, (item) => item.id === product?.id);
    };

    const onSelectProduct = (product: any) => {
        const isSelected = isProductSelected(product);
        if (isSelected) {
            const removedProducts = ObjectUtils.removeItemFromId(
                productToApplyVoucher,
                product?.id
            );
            setProductToApplyVoucher(removedProducts);
        } else {
            setProductToApplyVoucher([...productToApplyVoucher, product]);
        }
    };

    return (
        <React.Fragment>
            <div className="flex-center-y w-100 border-bottom py-3">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.selectService}
                </div>
            </div>
            <div className="px-3 mt-3">
                {Messages.voucherSelectServiceDescription}
            </div>
            <div>
                {map(listToSelect, (item, index) => (
                    <div
                        className={classNames(
                            "flex-center-y w-100 justify-content-between p-3",
                            {
                                "border-top": index !== 0,
                            }
                        )}
                    >
                        <ProductNameView
                            product={item}
                            subContent={
                                <React.Fragment>
                                    <div className="flex-center-y">
                                        <small>{Messages.serviceQty}: </small>
                                        <div className="ml-2">
                                            {item?.quantity}
                                        </div>
                                    </div>
                                    <div className="flex-center-y">
                                        <small>
                                            {Messages.availableVoucherQty}:
                                        </small>
                                        <div className="ml-2">
                                            {item?.availableVouchersQty}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        />
                        <Checkbox
                            onChange={() => onSelectProduct(item)}
                            checked={isProductSelected(item)}
                            disabled={item?.availableVouchersQty === 0}
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

const WorksheetVoucherSelectDrawer: React.FC<
    IWorksheetVoucherSelectDrawerProps
> = ({ open, onClose, customerId, products, value, onChange, initScreen }) => {
    const [activeScreen, setActiveScreen] = useState<VoucherSelectScreenType>(
        initScreen || VoucherSelectScreenType.SELECT_SERVICE
    );
    const [allVoucherList, setAllVoucherList] = useState<
        Array<IVoucherProduct>
    >([]);
    const productIds: string[] = map(products, (pro) => pro?.id);
    const usedCodes = useMemo(() => {
        return map(value, (item) => item?.code);
    }, [value]);
    const [productToApplyVoucher, setProductToApplyVoucher] =
        useState<IProductApplyVoucher[]>();
    const [selectingVoucherList, setSelectingVoucherList] =
        useState<Array<IVoucherProduct>>();
    const appliedVoucherProducts = useAppliedVouchersProductList(
        {
            products: products || [],
            vouchers: value || [],
        },
        [productIds, value]
    );

    const [verifyMethod, setVerifyMethod] = useState<string>(
        VERIFY_VOUCHER_METHOD.CUSTOMER_APP
    );

    const enableSaveVoucherButton = useMemo(() => {
        if (
            !isEmpty(selectingVoucherList) &&
            some(selectingVoucherList, (item) => {
                return item?.quantity && item?.quantity > 0;
            })
        ) {
            return true;
        }
        return false;
    }, [selectingVoucherList, value]);

    const isSeatConfiguration = useMemo(() => {
        return products?.[0]?.service?.seatConfiguration;
    }, [products]);

    useEffect(() => {
        if (customerId) {
            loadVoucherList();
        }
    }, [customerId]);

    const loadVoucherList = () => {
        return Progress.show(
            {
                method: VoucherAPI.listProductVoucher,
                params: [
                    {},
                    {
                        customerIds: [customerId],
                        status: [
                            VOUCHER_STATUS.AVAILABLE,
                            VOUCHER_STATUS.EXPIRING,
                        ],
                    },
                ],
            },
            (res: any) => {
                const list: Array<IVoucherProduct> =
                    res?.data?.data?.products ?? [];
                const transList = map(list, (itemList) => {
                    const {
                        voucherCodes,
                        voucherQuantity = 0,
                        product,
                    } = itemList || {};
                    const codesLeft = filter(
                        voucherCodes,
                        (i) => !includes(usedCodes, i)
                    );
                    const quantityLeft = codesLeft?.length ?? 0;
                    return {
                        ...itemList,
                        quantityLeft,
                        codesLeft,
                    };
                });
                setAllVoucherList(transList);
            }
        );
    };

    const renderFooterDrawer = () => {
        if (activeScreen === VoucherSelectScreenType.APPLY_CODE) {
            return null;
        }
        return (
            <div className="w-100 d-flex p-3">
                <div className="flex-fill">
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VOUCHER && (
                        <Button
                            content={Messages.back}
                            className=""
                            variant="outline"
                            onClick={() => {
                                //@ts-ignore
                                return setActiveScreen(
                                    VoucherSelectScreenType.SELECT_SERVICE
                                );
                            }}
                        />
                    )}
                </div>
                {activeScreen === VoucherSelectScreenType.SELECT_SERVICE && (
                    <Button
                        content={Messages.continue}
                        className=""
                        disabled={isEmpty(productToApplyVoucher)}
                        onClick={() => {
                            if (isEmpty(productToApplyVoucher)) {
                                return Notifications.showError(
                                    Messages.youHaveToChooseProductsFirst
                                );
                            }
                            setActiveScreen(
                                VoucherSelectScreenType.SELECT_VOUCHER
                            );
                        }}
                    />
                )}
                {activeScreen === VoucherSelectScreenType.SELECT_VOUCHER && (
                    <Button
                        content={Messages.select}
                        className=""
                        onClick={() => {
                            setActiveScreen(
                                VoucherSelectScreenType.SELECT_VERIFY_METHOD
                            );
                        }}
                        disabled={!enableSaveVoucherButton}
                    />
                )}
            </div>
        );
    };

    return (
        <VoucherSelectContext.Provider
            value={{
                verifyMethod,
                selectingVoucherList,
                appliedVoucherProducts,
                allVoucherList,
                activeScreen,
                onChange,
                onClose,
                setActiveScreen,
                setAllVoucherList,
                setProductToApplyVoucher,
                setSelectingVoucherList,
                setVerifyMethod,
                productToApplyVoucher,
                customerId,
                products,
                isSeatConfiguration,
            }}
        >
            <Drawer
                size="x-large"
                open={open}
                onClose={onClose}
                footer={renderFooterDrawer()}
            >
                <div className="p-3">
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_SERVICE && (
                        <VoucherSelectService />
                    )}
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VOUCHER && (
                        <VoucherSelectList />
                    )}

                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VERIFY_METHOD && (
                        <VoucherVerifyMethodSelect />
                    )}

                    {activeScreen === VoucherSelectScreenType.APPLY_CODE && (
                        <VoucherAuthorizationCodeInput />
                    )}
                </div>
            </Drawer>
        </VoucherSelectContext.Provider>
    );
};

export default WorksheetVoucherSelectDrawer;
