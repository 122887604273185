import { gql } from "@apollo/client";
import { F_ADDRESS, F_BILLING } from "../address/AddressFragment";
import { F_PAGINATION } from "../Fragment";

export const F_CUSTOMERS_ITEM = gql`
    ${F_ADDRESS}
    ${F_BILLING}
    fragment F_CUSTOMERS_ITEM on Customer {
        id
        avatar
        firstName
        lastName
        nickName
        company
        gender
        dateOfBirth
        email
        phone
        createBy
        province
        customerId
        createdAt
        updatedAt
        addressServiceDefault {
            ...F_ADDRESS
        }
        addressBillingDefault {
            ...F_BILLING
        }
        status
    }
`;

export const F_CUSTOMERS = gql`
    ${F_CUSTOMERS_ITEM}
    ${F_PAGINATION}
    fragment F_CUSTOMERS on Customers {
        customer {
            ...F_CUSTOMERS_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_CUSTOMER = gql`
    ${F_ADDRESS}
    ${F_BILLING}
    fragment F_CUSTOMER on Customer {
        id
        avatar
        firstName
        lastName
        nickName
        company
        gender
        dateOfBirth
        nationality
        email
        phone
        customerId
        typeOfCustomer
        addressServiceDefault {
            ...F_ADDRESS
        }
        addressBillingDefault {
            ...F_BILLING
        }
        status
        creditTerm {
            year
            month
            day
        }

        isVerified
    }
`;

export const F_CUSTOMER_EXPORT = gql`
    fragment F_CUSTOMER_EXPORT on CustomerExport {
        id
        customerId
        firstName
        lastName
        nickName
        company
        gender
        dateOfBirth
        nationality
        email
        phone
        typeOfCustomer
        province {
            id
            name
        }
        createdAt
        status

        numberOfVoucher
        numberOfBooking
        numberOfWorksheet
        numberOfOrder
        totalSpending
        lastBooking
        lastOrder
        lastWorksheet
    }
`;
