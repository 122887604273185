import { gql } from "@apollo/client";
import { TreeDataUtils } from "d-react-components";
import { map } from "lodash";
import API from "../API";
import { F_DIRECTORY, F_MEDIA, F_MEDIA_ITEM } from "./MediaFragment";

const allDirConverter = {
    fromServer: (res: any) => {
        const dirAll = res?.data?.directories;
        const dirAllMap = map(dirAll, (item) => ({
            ...item,
            key: item.id,
            children: [],
        }));
        return TreeDataUtils.mapListDataToTree(dirAllMap, "parentId");
    },
};

const dirTreeConverter = {
    fromServer: (res: any) => {
        return res?.data?.directoryTree ?? [];
    },
};

interface IUploadParam {
    file?: any;
    base64?: string;
}

export default {
    uploadMedia: ({ file, base64 }: IUploadParam) =>
        API.instanceUpload.mutate({
            mutation: gql`
                mutation ($file: Upload, $base64: String) {
                    upload(file: $file, base64: $base64) {
                        url
                    }
                }
            `,
            variables: { file, base64 },
        }),

    getDirTree: async () =>
        API.withConverter(dirTreeConverter).query({
            query: gql`
                ${F_DIRECTORY}
                query directoryTree {
                    directoryTree {
                        ...F_DIRECTORY
                    }
                }
            `,
            fetchPolicy: "no-cache",
        }),

    getDirAll: async () =>
        API.withConverter(allDirConverter).query({
            query: gql`
                ${F_DIRECTORY}
                query directories {
                    directories {
                        ...F_DIRECTORY
                    }
                }
            `,
            fetchPolicy: "no-cache",
        }),

    updateDirName: async (updateDirNameInput: any) =>
        API.withConverter(allDirConverter).mutate({
            mutation: gql`
                ${F_DIRECTORY}
                mutation updateDirName(
                    $updateDirNameInput: RenameDirectoryInput
                ) {
                    directories: renameDirectory(input: $updateDirNameInput) {
                        ...F_DIRECTORY
                    }
                }
            `,
            variables: { updateDirNameInput },
        }),

    moveDir: async (moveDirInput: any) =>
        API.withConverter(allDirConverter).mutate({
            mutation: gql`
                ${F_DIRECTORY}

                mutation moveDir($moveDirInput: MoveDirectoryInput) {
                    directories: moveDirectory(input: $moveDirInput) {
                        ...F_DIRECTORY
                    }
                }
            `,
            variables: { moveDirInput },
        }),

    createDir: async (createDirInput: any) =>
        API.withConverter(allDirConverter).mutate({
            mutation: gql`
                ${F_DIRECTORY}
                mutation createDir($createDirInput: CreateDirectoryInput) {
                    directories: createDirectory(input: $createDirInput) {
                        ...F_DIRECTORY
                    }
                }
            `,
            variables: { createDirInput },
        }),

    deleteDir: async (id: string) =>
        API.withConverter(allDirConverter).mutate({
            mutation: gql`
                ${F_DIRECTORY}
                mutation deleteDir($id: ID!) {
                    directories: destroyDirectory(id: $id) {
                        ...F_DIRECTORY
                    }
                }
            `,
            variables: { id },
        }),

    getMedias: async (dirPath: string, q = "") =>
        API.withConverter({
            fromServer: (res) => res?.data?.mediaImages ?? [],
        }).query({
            query: gql`
                ${F_MEDIA_ITEM}
                query getMedias($dirPath: String!, $q: String) {
                    mediaImages(directory: $dirPath, q: $q) {
                        ...F_MEDIA_ITEM
                    }
                }
            `,
            variables: { dirPath, q },
            fetchPolicy: "no-cache",
        }),

    uploadMediaToDir: async (dirPath: string, fileUpload: any) =>
        API.instanceUpload.mutate({
            mutation: gql`
                mutation uploadMedia($dirPath: String, $fileUpload: Upload!) {
                    uploadMedia(
                        input: {
                            directory: $dirPath
                            width: 1024
                            height: 1024
                            file: $fileUpload
                        }
                    ) {
                        id
                    }
                }
            `,
            variables: { dirPath, fileUpload },
        }),

    getMedia: async (id: string) =>
        API.withConverter({ fromServer: (res) => res?.data?.mediaImage }).query(
            {
                query: gql`
                    ${F_MEDIA}
                    query getMedia($id: ID!) {
                        mediaImage(id: $id) {
                            ...F_MEDIA
                        }
                    }
                `,
                variables: { id },
            }
        ),

    updateMedia: async (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation updateMedia($id: ID!, $input: UpdateMediaImageInput) {
                    updateMedia(imageId: $id, input: $input) {
                        id
                    }
                }
            `,
            variables: { id, input },
        }),

    deleteMedia: (ids: string[]) =>
        API.mutate({
            mutation: gql`
                mutation delete($ids: [ID]!) {
                    destroyMedia(imageId: $ids) {
                        status
                        messages
                    }
                }
            `,
            variables: { ids },
        }),

    moveMedia: (ids: string[], directoryId: string) =>
        API.mutate({
            mutation: gql`
                mutation moveMedia($ids: [ID]!, $directoryId: ID!) {
                    moveMedia(imageId: $ids, directoryId: $directoryId) {
                        id
                    }
                }
            `,
            variables: { ids, directoryId },
        }),
};
