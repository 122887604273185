import {
    Button,
    InputText,
    ObjectUtils,
    Select,
    StringUtils,
    ViewTextError,
} from "d-react-components";
import { map } from "lodash";
import React from "react";
import Messages from "../../languages/Messages";

interface IDataItem {
    id: string;
    value: string;
    isAddNew?: boolean;
}

interface IInputDataList {
    type?: "text" | "select";
    value: IDataItem[];
    defaultNewValue?: IDataItem;
    error?: any;
    dataSource?: {
        id: string;
        label: string;
    }[];
    onChange: (value: any) => void;
}

interface IInputDataItem {
    type: "text" | "select";
    dataItem: IDataItem;
    dataSource?: {
        id: string;
        label: string;
    }[];
    onChange: (value: any) => void;
    onDelete: (value: any) => void;
    onUp: (value: any) => void;
    onDown: (value: any) => void;
}

export const InputDataItem = ({
    dataItem,
    type,
    dataSource,
    onDelete,
    onUp,
    onDown,
    onChange,
}: IInputDataItem) => {
    const renderMainInput = () => {
        switch (type) {
            case "select":
                return (
                    <Select
                        dataSource={dataSource}
                        value={dataItem.value}
                        onChange={(value) => onChange({ ...dataItem, value })}
                    />
                );
            default:
                return (
                    <InputText
                        value={dataItem.value}
                        onChange={(event) =>
                            onChange({ ...dataItem, value: event.target.value })
                        }
                    />
                );
        }
    };

    return (
        <div className="w-100 row flex-center" key={dataItem.id}>
            <div className="flex-column col-2">
                <Button
                    iconName="keyboard_arrow_up"
                    variant="trans"
                    onClick={onUp}
                />
                <Button
                    iconName="keyboard_arrow_down"
                    variant="trans"
                    onClick={onDown}
                />
            </div>
            <div className="col-8">{renderMainInput()}</div>
            <div className="col-2 d-flex justify-content-end">
                <Button iconName="delete" onClick={onDelete} />
            </div>
        </div>
    );
};

const InputDataList = (props: IInputDataList) => {
    const {
        value = [],
        type = "text",
        defaultNewValue,
        error,
        dataSource = [],
        onChange,
    } = props;

    const onDelete = (dataItem: any) => {
        const newProcesses = ObjectUtils.removeArrayById(
            value as any,
            dataItem?.id
        );
        onChange(newProcesses);
    };

    const onMove = (oldIndex: number, newIndex: number) => {
        const newData = ObjectUtils.arrayMove(value as any, oldIndex, newIndex);
        onChange([...newData]);
    };

    const onCreate = () => {
        const newValue = defaultNewValue ?? {
            id: StringUtils.getUniqueID(),
            isAddNew: true,
        };
        onChange([...value, newValue]);
    };

    const onChangeItem = (dataItem: any) => {
        const newData = ObjectUtils.updateArrayById(value as any, dataItem);
        onChange(newData);
    };

    return (
        <div>
            {map(value, (dataItem, index) => (
                <InputDataItem
                    type={type}
                    dataItem={dataItem}
                    onDelete={() => onDelete(dataItem)}
                    onDown={() => onMove(index, index + 1)}
                    onUp={() => onMove(index, index - 1)}
                    onChange={onChangeItem}
                    dataSource={dataSource}
                />
            ))}
            <Button className="mt-3" onClick={onCreate}>
                {Messages.addMore}
            </Button>
            <ViewTextError error={error} />
        </div>
    );
};

export default InputDataList;
