import React, { useContext } from "react";
import LanguageSelectButton from "../../../common/button/LanguageSelectButton";
import { WarehouseCreateContext } from "../../../context/warehouse";

const WarehouseLanguageSelect = () => {
    const { warehouseLang, setWarehouseLang } = useContext(
        WarehouseCreateContext
    );
    return (
        <LanguageSelectButton
            lang={warehouseLang}
            onChange={setWarehouseLang}
        />
    );
};

export default WarehouseLanguageSelect;
