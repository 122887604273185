import {
    Button,
    DialogManager,
    Modal,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { CustomerDetailContext } from "../../../context/customer";
import { BillingSchema } from "../../../formschema/customer";
import {
    IBillingAddress,
    mapAddressToForm,
    mapAddressToSer,
} from "../../../interfaces/address";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import AddressItemHeader from "../common/AddressItemHeader";
import CustomerAddressForm from "../common/CustomerAddressForm";

interface IAddressItem {
    address: IBillingAddress;
    index: number;
}

const CustomerUpdateAddressModal = ({
    open,
    onClose,
    addressDefault,
    isEdit,
}: any) => {
    const { customerId } = useParams<any>();
    const { loadCustomerBillingAdds } = useContext(CustomerDetailContext);
    const customerForm = useFormik<ICustomer>({
        initialValues: mapAddressToForm(addressDefault),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BillingSchema,
        onSubmit: (values) => {
            const input = mapAddressToSer(values);
            if (isEdit) {
                onEditAddress(input);
            } else {
                onCreateAddress(input);
            }
        },
    });

    const isPersonal = customerForm.values?.typeOfCustomer === "INDIVIDUAL";

    const onEditAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.updateBillingAddress,
                params: [customerId, addressDefault?.id, body],
            },
            () => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                loadCustomerBillingAdds();
                onClose();
            }
        );
    };

    const onCreateAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.createBillingAddress,
                params: [customerId, body],
            },
            () => {
                Notifications.showSuccess(
                    Messages.createCustomerAddressSuccess
                );
                loadCustomerBillingAdds();
                onClose();
            }
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateAddress}
            size="medium"
            onSave={() => customerForm.handleSubmit()}
        >
            <CustomerAddressForm
                formData={customerForm}
                show={{
                    citizenId: isPersonal,
                    company: !isPersonal,
                    email: true,
                    nickname: false,
                    gender: false,
                    typeOfCustomer: true,
                    typeOfPlace: false,
                    map: false,
                }}
                title={Messages.billingAddress}
            />
        </Modal>
    );
};

const AddressItem = ({ address, index }: IAddressItem) => {
    const { customerId } = useParams<any>();
    const { loadCustomerBillingAdds } = useContext(CustomerDetailContext);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const {
        id,
        phone,
        pin,
        typeOfPlace,
        fullAddress,
        fullName,
        typeOfCustomer,
        citizenId,
        taxId,
    } = address;

    const onSetDefaultAddress = () => {
        const body = { ...mapAddressToSer(address), pin: true };
        Progress.show(
            {
                method: CustomerAPI.updateBillingAddress,
                params: [customerId, id, body],
            },
            () => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                loadCustomerBillingAdds();
            }
        );
    };

    const onClickDelete = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureDeleteAddress,
            onDeleteAddress
        );
    };

    const onDeleteAddress = () => {
        Progress.show(
            {
                method: CustomerAPI.deleteBillingAddress,
                params: [customerId, id],
            },
            () => {
                Notifications.showSuccess(Messages.deleteAddressSuccess);
                loadCustomerBillingAdds();
            }
        );
    };

    return (
        <div className="mt-3">
            <AddressItemHeader
                isDefault={pin}
                label={`${Messages.billingAddress} ${index}`}
                onClickEdit={() => setOpenEdit(true)}
                onClickDelete={onClickDelete}
                onClickSetDefault={onSetDefaultAddress}
            />
            <div className="flex-column">
                <text>{fullName}</text>
                {typeOfCustomer === "INDIVIDUAL" && (
                    <text>{`${Messages.citizenId}: ${citizenId}`}</text>
                )}
                {typeOfCustomer === "BUSINESS" && (
                    <text>{`${Messages.taxId}: ${taxId}`}</text>
                )}
                <text>{phone}</text>
                <text>{fullAddress}</text>
                <text>{typeOfPlace}</text>
            </div>
            <CustomerUpdateAddressModal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                addressDefault={address}
                isEdit
            />
        </div>
    );
};

const CustomerDetailBilling = () => {
    const { customerBillingAdds } = useContext(CustomerDetailContext);
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h4>{Messages.billingAddress}</h4>
                <Button iconName="add" onClick={() => setOpenAdd(true)}>
                    {Messages.newAddress}
                </Button>
            </div>
            <div className="bg-white p-4 mt-3">
                {map(customerBillingAdds, (address, index) => (
                    <AddressItem address={address} index={index + 1} />
                ))}
            </div>
            {openAdd && (
                <CustomerUpdateAddressModal
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}
        </div>
    );
};

export default CustomerDetailBilling;
