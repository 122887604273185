import { Avatar, AvatarProps } from "d-react-components";
import { ICustomer } from "../../../interfaces/customer";

interface ICustomerAvatarName {
    customer: ICustomer;
    placeholder?: any;
    size?: AvatarProps["size"];
}

const CustomerAvatarName = ({
    customer,
    placeholder = "N/A",
    size = "small",
}: ICustomerAvatarName) => {
    if (!customer || !customer?.id) return placeholder;
    return (
        <div className="flex-center-y">
            <Avatar
                src={customer?.avatar ?? "/images/placeholder.png"}
                size={size}
            />
            <div className="ml-2">{`${customer?.firstName} ${customer.lastName}`}</div>
        </div>
    );
};

export default CustomerAvatarName;
