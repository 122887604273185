import { useContext } from "react";
import { OrderCreateContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import CustomerInfoView from "../../customer/common/CustomerInfoView";
import CustomerSelect from "../../customer/common/CustomerSelect";

const OrderCreateSearchCustomer = () => {
    const { orderForm, onChangeCustomer } = useContext(OrderCreateContext);
    const { customer } = orderForm.values;

    return (
        <div className="mt-3">
            <div className="card-container p-4">
                <CustomerSelect
                    onChange={onChangeCustomer}
                    value={customer}
                    error={orderForm.errors.customer as any}
                />
            </div>
            <div className="card-container p-4 mt-3" hidden={!customer}>
                <label>{Messages.customer}</label>
                <CustomerInfoView customer={customer as any} />
            </div>
        </div>
    );
};

export default OrderCreateSearchCustomer;
