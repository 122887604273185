import { StringUtils, TimeUtils } from "d-react-components";
import { useContext } from "react";
import { generatePath } from "react-router-dom";
import { VoucherDetailContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const VoucherDetailInformation = () => {
    const { voucher } = useContext(VoucherDetailContext);
    const { code, product, effectiveDate, expiryDate, remark, priceFromOrder } =
        voucher;
    const renderInfoItem = (label: string, content: any) => {
        return (
            <div className="flex-center-y border-bottom py-3">
                <div className="small w-50">{label}</div>
                <div className="text w-50 ">{content ?? "N/A"}</div>
            </div>
        );
    };
    return (
        <div className="card-container mt-3 p-4">
            <label>{Messages.information}</label>
            {renderInfoItem(Messages.code, code)}
            {/* {code &&
                renderInfoItem(
                    Messages.qrCode,
                    <QRCode value={code} size={100} className="mt-3" />
                )} */}
            {product &&
                renderInfoItem(
                    Messages.product,
                    <a
                        href={generatePath(Path.PRODUCT_DETAIL, {
                            productId: product.id,
                        })}
                    >
                        {(product as any)?.[Messages.getLanguage()]?.name}
                    </a>
                )}
            {renderInfoItem(
                Messages.priceFromOrder,
                StringUtils.moneyFormat(priceFromOrder)
            )}
            {product &&
                renderInfoItem(
                    Messages.sku,
                    <a
                        href={generatePath(Path.PRODUCT_DETAIL, {
                            productId: product.id,
                        })}
                    >
                        {product?.SKU}
                    </a>
                )}

            {renderInfoItem(
                Messages.effectiveDate,
                TimeUtils.toDateTime(effectiveDate)
            )}
            {renderInfoItem(
                Messages.expiryDate,
                expiryDate
                    ? TimeUtils.toDateTime(expiryDate)
                    : Messages.noExpireDate
            )}
            {renderInfoItem(Messages.remark, remark)}
        </div>
    );
};

export default VoucherDetailInformation;
