import { gql } from "@apollo/client";
import { F_ACTIVITIES } from "../activity/ActivityFragment";
import API from "../API";
import { F_WORKSHEET, F_WORKSHEETS } from "./WorksheetFragment";

const listConvertor = {
    fromServer: (res: any) => ({
        data: {
            data: {
                ...res.data.data,
                worksheet: res?.data?.data?.worksheet ?? [],
            },
        },
    }),
};

const detailConvertor = {
    fromServer: (res: any) => res?.data?.worksheet,
};

export default {
    list: (q: string, { pageIndex = 1, pageSize = 10 }: any, filter: any) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_WORKSHEETS}
                query worksheets(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: WorksheetFilter
                ) {
                    data: worksheets(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_WORKSHEETS
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                filter,
            },
            fetchPolicy: "no-cache",
        }),

    listAssignedMe: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filter?: any
    ) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_WORKSHEETS}
                query worksheetsAssignedMe(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: WorksheetFilter
                ) {
                    data: worksheetsAssignedMe(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_WORKSHEETS
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                filter,
            },
            fetchPolicy: "no-cache",
        }),

    listToLoadOrder: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        customerId: string
    ) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_WORKSHEETS}
                query worksheetsToLoadOrder(
                    $q: String
                    $page: Int
                    $limit: Int
                    $customerIds: [ID]
                ) {
                    data: worksheetsToLoadOrder(
                        q: $q
                        page: $page
                        limit: $limit
                        customerIds: $customerIds
                    ) {
                        ...F_WORKSHEETS
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                customerIds: [customerId],
            },
            fetchPolicy: "no-cache",
        }),

    summary: () =>
        API.withConverter({
            fromServer: (res) => res?.data?.worksheetSummary ?? [],
        }).query({
            query: gql`
                query worksheetSummary {
                    worksheetSummary {
                        type
                        total
                    }
                }
            `,
            fetchPolicy: "no-cache",
        }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createWorksheet?.id,
        }).mutate({
            mutation: gql`
                mutation createWorksheet($input: WorksheetInput!) {
                    createWorksheet(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_WORKSHEET}
                query worksheet($id: ID!) {
                    worksheet(id: $id) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { id },
            fetchPolicy: "no-cache",
        }),
    detailByNo: (worksheetNo: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_WORKSHEET}
                query worksheetByNo($worksheetNo: String!) {
                    worksheet: worksheetByNo(worksheetNo: $worksheetNo) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetNo },
            fetchPolicy: "no-cache",
        }),

    assignPIC: (worksheetId: string, userId: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation assignPICWorksheet($worksheetId: ID!, $userId: [ID]!) {
                    worksheet: assignPICWorksheet(
                        worksheetId: $worksheetId
                        userId: $userId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, userId },
        }),

    cancel: (id: string, remark: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation cancelWorksheet($id: ID!, $remark: String!) {
                    worksheet: cancelWorksheet(id: $id, remark: $remark) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { id, remark },
        }),

    removePIC: (worksheetId: string, userId: string[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation removePICWorksheet($worksheetId: ID!, $userId: [ID]!) {
                    worksheet: removePICWorksheet(
                        worksheetId: $worksheetId
                        userId: $userId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, userId },
        }),

    addCriteriaCheck: (worksheetId: string, input: any[]) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation addCriteriaCheck(
                    $worksheetId: ID!
                    $input: [WorksheetCriteriaCheckItemInput]!
                ) {
                    worksheet: addCriteriaCheck(
                        worksheetId: $worksheetId
                        input: $input
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, input },
        }),

    addCriteriaCheckByQuantity: (
        worksheetId: string,
        input: any[],
        quantity: number
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation addCriteriaCheckByQuantity(
                    $worksheetId: ID!
                    $quantity: Int!
                    $input: [WorksheetCriteriaCheckItemInput]!
                ) {
                    worksheet: addCriteriaCheckByQuantity(
                        worksheetId: $worksheetId
                        input: $input
                        quantity: $quantity
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, input, quantity },
        }),

    removeCriteriaCheck: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation removeCriteriaCheck(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: removeCriteriaCheck(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    checkPriorChecklist: (
        worksheetId: string,
        criteriaCheckId: string,
        priorChecklistId: string
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkPriorChecklist(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                    $priorChecklistId: ID!
                ) {
                    worksheet: checkPriorChecklist(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                        priorChecklistId: $priorChecklistId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId, priorChecklistId },
        }),

    checkWorkProgress: (
        worksheetId: string,
        criteriaCheckId: string,
        progressId: string
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkWorkProgress(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                    $progressId: ID!
                ) {
                    worksheet: checkWorkProgress(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                        progressId: $progressId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId, progressId },
        }),

    checkDeliveryProgress: (
        worksheetId: string,
        criteriaCheckId: string,
        progressId: string
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkDeliveryProgress(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                    $progressId: ID!
                ) {
                    worksheet: checkDeliveryProgress(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                        progressId: $progressId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId, progressId },
        }),

    checkCustomerConfirm: (
        worksheetId: string,
        criteriaCheckId: string,
        checkId: string
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkCustomerConfirm(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                    $checkId: ID!
                ) {
                    worksheet: checkCustomerConfirm(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                        checkId: $checkId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId, checkId },
        }),

    addDefectionVisual: (worksheetId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation addDefectionVisual(
                    $worksheetId: ID!
                    $input: WorksheetDefectionVisualInput!
                ) {
                    worksheet: addDefectionVisual(
                        worksheetId: $worksheetId
                        input: $input
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, input },
        }),

    updateDefectionVisual: (
        worksheetId: string,
        visualizationId: string,
        input: any
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation updateDefectionVisual(
                    $worksheetId: ID!
                    $visualizationId: ID!
                    $input: WorksheetDefectionVisualInput!
                ) {
                    worksheet: updateDefectionVisual(
                        worksheetId: $worksheetId
                        visualizationId: $visualizationId
                        input: $input
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, visualizationId, input },
        }),

    removeDefectionVisual: (worksheetId: string, visualizationId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation removeDefectionVisual(
                    $worksheetId: ID!
                    $visualizationId: ID!
                ) {
                    worksheet: removeDefectionVisual(
                        worksheetId: $worksheetId
                        visualizationId: $visualizationId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, visualizationId },
        }),

    confirmDefection: (worksheetId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation confirmDefection($worksheetId: ID!) {
                    worksheet: confirmDefection(worksheetId: $worksheetId) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId },
        }),

    editRemarkDefectionConfirm: (worksheetId: string, remark: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation editRemarkDefectionConfirm(
                    $worksheetId: ID!
                    $remark: String
                ) {
                    worksheet: editRemarkDefectionConfirm(
                        worksheetId: $worksheetId
                        remark: $remark
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, remark },
        }),

    customerSignDefection: (worksheetId: string, signature: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation customerSignDefection(
                    $worksheetId: ID!
                    $signature: String
                ) {
                    worksheet: customerSignDefection(
                        worksheetId: $worksheetId
                        signature: $signature
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, signature },
        }),

    staffSignDefection: (worksheetId: string, signature: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation staffSignDefection(
                    $worksheetId: ID!
                    $signature: String
                ) {
                    worksheet: staffSignDefection(
                        worksheetId: $worksheetId
                        signature: $signature
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, signature },
        }),

    editRemarkFinalConfirm: (worksheetId: string, remark: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation editRemarkFinalConfirm(
                    $worksheetId: ID!
                    $remark: String
                ) {
                    worksheet: editRemarkFinalConfirm(
                        worksheetId: $worksheetId
                        remark: $remark
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, remark },
        }),

    staffSignFinalConfirm: (worksheetId: string, signature: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation staffSignFinalConfirm(
                    $worksheetId: ID!
                    $signature: String
                ) {
                    worksheet: staffSignFinalConfirm(
                        worksheetId: $worksheetId
                        signature: $signature
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, signature },
        }),

    customerSignFinalConfirm: (worksheetId: string, signature: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation customerSignFinalConfirm(
                    $worksheetId: ID!
                    $signature: String
                ) {
                    worksheet: customerSignFinalConfirm(
                        worksheetId: $worksheetId
                        signature: $signature
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, signature },
        }),

    activities: (worksheetId: string, { pageIndex = 1, pageSize = 10 }: any) =>
        API.query({
            query: gql`
                ${F_ACTIVITIES}
                query worksheetActivities(
                    $worksheetId: ID!
                    $page: Int
                    $limit: Int
                ) {
                    data: worksheetActivities(
                        worksheetId: $worksheetId
                        page: $page
                        limit: $limit
                    ) {
                        ...F_ACTIVITIES
                    }
                }
            `,
            variables: { worksheetId, page: pageIndex, limit: pageSize },
            fetchPolicy: "no-cache",
        }),

    addComment: (
        worksheetId: string,
        message: string,
        attachments?: string[]
    ) =>
        API.mutate({
            mutation: gql`
                mutation commentsWorksheet(
                    $worksheetId: ID!
                    $message: String
                    $attachments: [String]
                ) {
                    commentsWorksheet(
                        worksheetId: $worksheetId
                        message: $message
                        attachments: $attachments
                    )
                }
            `,
            variables: { worksheetId, message, attachments },
        }),

    checkAllPriorChecklist: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkAllPriorChecklist(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: checkAllPriorChecklist(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    cancelPriorChecklist: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation cancelPriorChecklist(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: cancelPriorChecklist(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    checkAllWorkProgress: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkAllWorkProgress(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: checkAllWorkProgress(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    cancelWorkProgress: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation cancelWorkProgress(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: cancelWorkProgress(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    checkAllDeliveryProgress: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkAllDeliveryProgress(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: checkAllDeliveryProgress(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    checkAllCustomerConfirm: (worksheetId: string, criteriaCheckId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation checkAllCustomerConfirm(
                    $worksheetId: ID!
                    $criteriaCheckId: ID!
                ) {
                    worksheet: checkAllCustomerConfirm(
                        worksheetId: $worksheetId
                        criteriaCheckId: $criteriaCheckId
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, criteriaCheckId },
        }),

    applyVoucher: ({ worksheetId, vouchers, serviceApply }: any) =>
        API.mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation applyVoucher(
                    $worksheetId: ID!
                    $vouchers: [String]
                    $serviceApply: [ServiceApplyInput]
                ) {
                    data: applyVoucher(
                        worksheetId: $worksheetId
                        vouchers: $vouchers
                        serviceApply: $serviceApply
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, vouchers, serviceApply },
        }),
    applyVoucherSofa: ({ worksheetId, vouchers, serviceApply }: any) =>
        API.mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation applyVoucherSofa(
                    $worksheetId: ID!
                    $vouchers: [String]
                ) {
                    data: applyVoucherSofa(
                        worksheetId: $worksheetId
                        vouchers: $vouchers
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, vouchers, serviceApply },
        }),

    resetVoucher: (worksheetId: string, vouchers: string[]) =>
        API.mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation resetVoucher($worksheetId: ID!, $vouchers: [String]) {
                    data: resetVoucher(
                        worksheetId: $worksheetId
                        vouchers: $vouchers
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, vouchers },
        }),

    startMileage: (worksheetId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation startMileage(
                    $worksheetId: ID!
                    $input: WorksheetMileageInput
                ) {
                    worksheet: startMileage(
                        worksheetId: $worksheetId
                        input: $input
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, input },
        }),

    endMileage: (worksheetId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_WORKSHEET}
                mutation endMileage(
                    $worksheetId: ID!
                    $input: WorksheetMileageInput
                ) {
                    worksheet: endMileage(
                        worksheetId: $worksheetId
                        input: $input
                    ) {
                        ...F_WORKSHEET
                    }
                }
            `,
            variables: { worksheetId, input },
        }),
};
