import { Progress } from "d-react-components";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";
import { AppStateContext } from "../../../context/app";
import { RefundDetailContext } from "../../../context/refundRequest";
import { IRefundRequest } from "../../../interfaces/refundRequest";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import RefundRequestDetailAccounting from "./RefundRequestDetailAccounting";
import RefundRequestDetailDirector from "./RefundRequestDetailDirector";
import RefundRequestDetailHeader from "./RefundRequestDetailHeader";
import RefundRequestDetailInfo from "./RefundRequestDetailInfo";
import RefundRequestDetailItems from "./RefundRequestDetailItems";

const RefundRequestDetail = () => {
    const { setSiteConfig } = useContext(AppStateContext);

    const [refundRequest, setRefundRequest] = useState<IRefundRequest>(
        {} as any
    );
    const { refundRequestId } = useParams<any>();

    useEffect(() => {
        loadRefundDetail();
    }, []);

    const loadRefundDetail = () => {
        Progress.show(
            {
                method: RefundRequestAPI.detail,
                params: refundRequestId,
            },
            (refundDetail: any) => {
                setRefundRequest(refundDetail);
                setSiteConfig({
                    breadcrumb: [
                        {
                            title: Messages.allRefundRequest,
                            url: Path.REFUND_REQUEST_TABLE,
                        },
                        {
                            title: refundDetail?.requestNo,
                        },
                    ],
                });
            }
        );
    };
    return (
        <RefundDetailContext.Provider
            value={{ refundRequest, setRefundRequest }}
        >
            <div className="p-4">
                <RefundRequestDetailHeader />
                <RefundRequestDetailInfo />
                <RefundRequestDetailItems />
                <RefundRequestDetailDirector />
                <RefundRequestDetailAccounting />
            </div>
        </RefundDetailContext.Provider>
    );
};

export default RefundRequestDetail;
