import { gql } from "@apollo/client";
import { map } from "lodash";
import { USER_STATUS } from "../../constant/user";
import {
    mapUserFromSer,
    mapUserRolePermissionFromSever,
} from "../../interfaces/user";
import API from "../API";
import {
    F_PERMISSION,
    F_USER,
    F_USERS,
    F_USER_ROLE,
    F_USER_ROLES,
} from "./UserFragment";

const listConvertor = {
    fromServer: (res: any) => ({
        data: {
            data: {
                ...res.data.data,
                user: map(res?.data?.data?.user ?? [], mapUserFromSer),
            },
        },
    }),
};

const detailConvertor = {
    fromServer: (res: any) => mapUserFromSer(res?.data?.user),
};

const roleDetailConvertor = {
    fromServer: (res: any) => {
        const { name, permissions, permissionType } = res?.data?.role ?? {};

        const permissionResult = mapUserRolePermissionFromSever(permissions);
        return { name, permissions: permissionResult, permissionType };
    },
};

const UserAPI = {
    list: (q: string, filter?: any, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_USERS}
                query users(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: UserFilter
                ) {
                    data: users(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_USERS
                    }
                }
            `,
            variables: {
                q,
                filter,
                page: paging.pageIndex,
                limit: paging.pageSize,
            },
            fetchPolicy: "no-cache",
        }),

    usersSearch: (
        q: string,
        paging = { pageIndex: 1, pageSize: 10 },
        filter?: any
    ) =>
        API.withConverter(listConvertor).query({
            query: gql`
                ${F_USERS}
                query usersSearch(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: UserFilter
                ) {
                    data: usersSearch(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_USERS
                    }
                }
            `,
            variables: {
                q,
                filter,
                page: paging.pageIndex,
                limit: paging.pageSize,
            },
            fetchPolicy: "no-cache",
        }),

    activeUserList: (q: string) =>
        UserAPI.list(q, { status: USER_STATUS.ACTIVE }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res: any) => res?.data?.createUser?.id,
        }).mutate({
            mutation: gql`
                mutation create($input: CreateUserInput!) {
                    createUser(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_USER}
                query detail($id: ID!) {
                    user(id: $id) {
                        ...F_USER
                    }
                }
            `,
            variables: { id },
        }),

    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_USER}
                mutation updateUser($id: ID!, $input: UpdateUserInput!) {
                    user: updateUser(id: $id, input: $input) {
                        ...F_USER
                    }
                }
            `,
            variables: { id, input },
        }),

    resetPassword: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_USER}
                mutation update($id: ID!, $input: ResetPassword) {
                    user: resetPassword(id: $id, input: $input) {
                        ...F_USER
                    }
                }
            `,
            variables: { id, input },
        }),

    roleList: (q?: string) =>
        API.query({
            query: gql`
                ${F_USER_ROLES}
                query roles($q: String) {
                    data: roles(q: $q) {
                        ...F_USER_ROLES
                    }
                }
            `,
            variables: { q },
            fetchPolicy: "no-cache",
        }),

    roleCreate: (input: any) =>
        API.mutate({
            mutation: gql`
                ${F_USER_ROLE}
                mutation roleCreate($input: RoleInput) {
                    createRole(input: $input) {
                        ...F_USER_ROLE
                    }
                }
            `,
            variables: { input },
        }),

    roleUpdate: (id: string, input: any) =>
        API.withConverter(roleDetailConvertor).mutate({
            mutation: gql`
                ${F_USER_ROLE}
                mutation roleUpdate($id: ID!, $input: RoleInput) {
                    role: updateRole(id: $id, input: $input) {
                        ...F_USER_ROLE
                    }
                }
            `,
            variables: { id, input },
        }),

    roleDetail: (id: any) =>
        API.withConverter(roleDetailConvertor).query({
            query: gql`
                ${F_USER_ROLE}
                query role($id: String) {
                    role(id: $id) {
                        ...F_USER_ROLE
                    }
                }
            `,
            variables: { id },
        }),

    permissionList: () =>
        API.query({
            query: gql`
                ${F_PERMISSION}
                query permissions {
                    permissions {
                        ...F_PERMISSION
                    }
                }
            `,
        }),

    generateOtpAuth: (accountName?: string) =>
        API.mutate({
            mutation: gql`
                mutation generateOtpAuth($accountName: String) {
                    generateOtpAuth(accountName: $accountName) {
                        otpAuthSecretCode
                        otpAuthUrl
                    }
                }
            `,
            variables: { accountName },
        }),

    removeRole: (id?: string) =>
        API.mutate({
            mutation: gql`
                mutation removeRole($id: ID!) {
                    removeRole(id: $id)
                }
            `,
            variables: { id },
        }),

    refreshOtpCode: () =>
        API.mutate({
            mutation: gql`
                mutation refreshLocalOtpAuth {
                    data: refreshLocalOtpAuth {
                        code
                        expiryAt
                    }
                }
            `,
        }),
};
export default UserAPI;
