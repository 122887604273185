import Messages from "../languages/Messages";

export enum WorksheetStatus {
    PROCESSING = "PROCESSING",
    DELIVERED = "DELIVERED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
}

export const WORKSHEET_STATUSES = [
    { id: WorksheetStatus.PROCESSING, label: "processing", color: "#84C4FF" },
    { id: WorksheetStatus.DELIVERED, label: "delivered", color: "#FAC256" },
    { id: WorksheetStatus.COMPLETED, label: "completed", color: "#33B950" },
    { id: WorksheetStatus.CANCELLED, label: "cancelled", color: "#C4C4C4" },
];

export const WORKSHEET_CREATE_STEPS = [
    { id: 0, label: "booking" },
    // { id: 1, label: "priorChecking" },
    // { id: 2, label: "defection" },
    // { id: 3, label: "progress" },
    // { id: 4, label: "voucher" },
    // { id: 5, label: "finalConfirmation" },
];

export const WORKSHEET_DETAIL_STEPS = [
    { id: 8, label: "general" },
    { id: 0, label: "booking" },
    { id: 1, label: "priorChecking" },
    { id: 2, label: "defection" },
    { id: 3, label: "progress" },
    // { id: 4, label: "voucher" },
    { id: 5, label: "finalConfirmation" },
    { id: 6, label: "activity" },
];

export const CUSTOMER_CHECKS = [
    { id: "checkService", value: "Check service" },
    { id: "checkBelonging", value: "Check belonging" },
    { id: "checkWorkingArea", value: "Check working area" },
];

export const VERIFY_VOUCHER_METHOD = {
    CUSTOMER_APP: "CUSTOMER_APP",
    ADMIN_TOKEN: "ADMIN_TOKEN",
    SMS_OTP: "SMS_OTP",
};

export const VERIFY_VOUCHER_METHODS = [
    {
        id: VERIFY_VOUCHER_METHOD.CUSTOMER_APP,
        label: "De Hygienique customer app OTP",
    },
    {
        id: VERIFY_VOUCHER_METHOD.ADMIN_TOKEN,
        label: "Authorized Admin Token",
    },
    {
        id: VERIFY_VOUCHER_METHOD.SMS_OTP,
        label: "SMS OTP",
        description: "smsOtpDesc",
    },
];

export enum WORKSHEET_CRITERIA_CHECK_STATUS {
    PENDING = "PENDING",
    PRIOR_CHECKED = "PRIOR_CHECKED",
    WORK_PROGRESS_CHECKED = "WORK_PROGRESS_CHECKED",
    CANCELLED = "CANCELLED",
    CUSTOMER_CONFIRMED = "CUSTOMER_CONFIRMED",
}
