// Import the functions you need from the SDKs you need
//@ts-ignore
import * as Firebase from "firebase/app";
//@ts-ignore
import * as FirebaseMessaging from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBNF0rsu7Ddj_hMkmO0VwWfNiTVDQX00BQ",
    authDomain: "de-hygienique.firebaseapp.com",
    projectId: "de-hygienique",
    storageBucket: "de-hygienique.appspot.com",
    messagingSenderId: "1068813284922",
    appId: "1:1068813284922:web:92e6947be2909263fba703",
    measurementId: "G-SFNH1YQP5N",
};

// Initialize Firebase
const app = Firebase.initializeApp(firebaseConfig);
FirebaseMessaging.getMessaging(app);
export default FirebaseMessaging;
