import { gql } from "@apollo/client";

export const F_SMART_SUPPORT = gql`
    fragment F_SMART_SUPPORT on SmartSupport {
        id
        status
        themeColor
        th {
            mobileHeader
        }
        en {
            mobileHeader
        }
        channels {
            id
            icon
            url
            en {
                title
                subTitle
            }
            th {
                title
                subTitle
            }
        }
    }
`;
