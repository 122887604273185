import { gql } from "@apollo/client";

export const F_ADDRESS = gql`
    fragment F_ADDRESS on Address {
        id
        firstName
        lastName
        nickName
        gender
        phone
        country
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        address
        typeOfPlace
        nameOfPlace
        pin
        latitude
        longitude
        remark
    }
`;

export const F_BILLING = gql`
    fragment F_BILLING on Billing {
        id
        firstName
        lastName
        citizenId
        taxId
        companyName
        companyPhone
        email
        phone
        country
        province {
            id
            name
        }
        district {
            id
            name
            provinceId
            province {
                id
                name
            }
        }
        subDistrict {
            id
            name
            postcode
        }
        postcode
        address
        typeOfCustomer
        pin
        sameServiceAddress
    }
`;
