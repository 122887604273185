import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useCountDown = (
    timeToCount: number = 60 * 1000,
    interval: number = 1000
): any[] => {
    const [timeLeft, setTimeLeft] = useState(0);
    const INIT_TIMER = {
        started: null,
        lastInterval: null,
        timeLeft: 0,
        requestId: 1,
        timeToCount: 1,
    };
    const timer = useRef(INIT_TIMER);

    const run = (ts: any) => {
        if (!timer.current.started) {
            timer.current.started = ts;
            timer.current.lastInterval = ts;
        }

        const localInterval = Math.min(
            interval,
            timer.current.timeLeft || Infinity
        );
        if (ts - (timer.current.lastInterval as any) >= localInterval) {
            (timer.current.lastInterval as any) += localInterval;
            setTimeLeft((timeLeft) => {
                timer.current.timeLeft = timeLeft - localInterval;
                return timer.current.timeLeft;
            });
        }

        if (
            timer.current.started &&
            ts - (timer.current.started as any) < timer.current.timeToCount
        ) {
            timer.current.requestId = window.requestAnimationFrame(run);
        } else {
            timer.current = INIT_TIMER;
            setTimeLeft(0);
        }
    };

    const start = useCallback((ttc) => {
        window.cancelAnimationFrame(timer.current.requestId);

        const newTimeToCount = ttc !== undefined ? ttc : timeToCount;
        timer.current.started = null;
        timer.current.lastInterval = null;
        timer.current.timeToCount = newTimeToCount;
        timer.current.requestId = window.requestAnimationFrame(run);

        setTimeLeft(newTimeToCount);
    }, []);

    const pause = useCallback(() => {
        window.cancelAnimationFrame(timer.current.requestId);
        timer.current.started = null;
        timer.current.lastInterval = null;
        timer.current.timeToCount = timer.current.timeLeft;
    }, []);

    const resume = useCallback(() => {
        if (!timer.current.started && timer.current.timeLeft > 0) {
            window.cancelAnimationFrame(timer.current.requestId);
            timer.current.requestId = window.requestAnimationFrame(run);
        }
    }, []);

    const reset = useCallback(() => {
        if (timer.current.timeLeft) {
            window.cancelAnimationFrame(timer.current.requestId);
            timer.current = INIT_TIMER;
            setTimeLeft(0);
        }
    }, []);

    const actions = useMemo(() => ({ start, pause, resume, reset }), []);

    useEffect(() => reset, []);

    return [timeLeft, actions];
};
