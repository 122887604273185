import { Empty } from "antd";
import { Button, ObjectUtils, StringUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import CustomerAddressForm from "../common/CustomerAddressForm";

const CustomerCreateAddress = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    const addressList = customerForm?.values?.address ?? [];

    const onClickAddNewAddress = () => {
        customerForm.setFieldValue("address", [
            ...addressList,
            { id: StringUtils.getUniqueID() },
        ]);
    };

    const onToggleDefault = (id: string) => {
        const result = map(addressList, (item) => {
            if (item.id !== id) {
                return { ...item, pin: false };
            }
            return { ...item, pin: true };
        });
        customerForm.setFieldValue("address", result);
    };

    const onDelete = (id: string) => {
        const result = ObjectUtils.removeArrayById(addressList, id);
        customerForm.setFieldValue("address", result);
    };

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button iconName="add" onClick={onClickAddNewAddress}>
                    {Messages.newAddress}
                </Button>
            </div>

            {addressList?.length === 0 && (
                <Empty
                    className="mt-3"
                    description={Messages.noServiceAddress}
                />
            )}

            {map(addressList, (addressItem, index: number) => (
                <div className="bg-white p-4 my-3">
                    <CustomerAddressForm
                        formData={customerForm}
                        prefix={`address.${index}`}
                        onToggleDefault={() => onToggleDefault(addressItem?.id)}
                        onClickDelete={() => onDelete(addressItem?.id)}
                        title={Messages.serviceAddress}
                    />
                </div>
            ))}
        </div>
    );
};

export default CustomerCreateAddress;
