import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import NewsAPI from "../../../api/news/NewsAPI";
import { NewsCrudContext } from "../../../context/news";
import { NewsSchema } from "../../../formschema/news";
import { INewsCategory, mapNewsToSer } from "../../../interfaces/news";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import NewsCrudBookService from "./NewsCrudBookService";
import NewsCrudContent from "./NewsCrudContent";
import NewsCrudStatus from "./NewsCrudStatus";
import NewsCrudPublishDate from "./NewsCrudPublishDate";
import NewsCrudCategory from "./NewsCrudCategory";
import NewsCrudFeaturedBanner from "./NewsCrudFeaturedBanner";
import NewsCrudProductToBook from "./NewsCrudProductToBook";
import { CATEGORY_STATUS } from "../../../constant/category";

const NewsCrud = () => {
    const history = useHistory();
    const { newsId } = useParams<any>();
    const isEdit = useMemo(() => !!newsId, [newsId]);
    const [newsLang, setNewsLang] = useState("en");
    const [categoryList, setCategoryList] = useState<INewsCategory[]>([]);

    const newsForm = useFormik<any>({
        initialValues: { status: CATEGORY_STATUS.INACTIVE } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: NewsSchema,
        onSubmit: (values: any) => {
            const input = mapNewsToSer(values);
            if (isEdit) {
                onUpdate(input);
            } else {
                onCreate(input);
            }
        },
    });

    useEffect(() => {
        loadMetaData();
        isEdit && loadNewsDetail();
    }, []);

    const loadMetaData = () => {
        NewsAPI.detailMetaData().then((res) => {
            const catList = res?.data?.categoryNewsList?.categoryNews ?? [];
            setCategoryList(catList);
        });
    };

    const loadNewsDetail = () =>
        Progress.show(
            { method: NewsAPI.detail, params: [newsId] },
            newsForm.setValues
        );

    const onCreate = (input: any) => {
        Progress.show(
            { method: NewsAPI.create, params: [input] },
            (res: any) => {
                Notifications.showSuccess(Messages.createNewsSuccess);
                history.replace(
                    generatePath(Path.NEWS_DETAIL, {
                        newsId: res?.data?.createNews.id,
                    })
                );
            }
        );
    };

    const onUpdate = (input: any) => {
        Progress.show(
            { method: NewsAPI.update, params: [newsId, input] },
            (newsDetail: any) => {
                Notifications.showSuccess(Messages.updateNewsSuccess);
                newsForm.setValues(newsDetail);
            }
        );
    };

    return (
        <NewsCrudContext.Provider
            value={{
                categoryList,
                newsForm,
                newsLang,
                setNewsLang,
            }}
        >
            <Header
                title={Messages.news}
                onSave={() => newsForm.handleSubmit()}
            />
            <div className="row p-4">
                <div className="col-8 mt-3">
                    <NewsCrudContent />
                </div>
                <div className="col-4 mt-3">
                    <NewsCrudStatus />
                    <NewsCrudPublishDate />
                    <NewsCrudCategory />
                    <NewsCrudFeaturedBanner />
                    <NewsCrudBookService />
                    <NewsCrudProductToBook />
                </div>
            </div>
        </NewsCrudContext.Provider>
    );
};

export default NewsCrud;
