import { find, map } from "lodash";
import { IBillingAddress, IServiceAddress } from "./address";
import { IBooking } from "./booking";
import { ICategory } from "./category";
import { ICustomer } from "./customer";
import { IProduct } from "./product";
import { IUser } from "./user";
import { IVoucher } from "./voucher";

export interface IWorksheetService {
    product: IProduct;
    openingCost: number;
    quantity: number;
    availableOrderQty: number;
}

export interface IWorksheetConfirm {
    customerSignature: string;
    customerSignDate: string;
    staffSignature: string;
    staffSignDate: string;
    remark: string;
    confirmed: boolean;
}

export interface IWorksheetVisualization {
    images: string[];
    note: string;
}

export interface IWorksheetMileage {
    mile: number;
    proves: string[];
}

export interface IWorksheet {
    id: string;
    worksheetNo: string;
    booking: IBooking;
    address: IServiceAddress;
    billing: IBillingAddress;
    status: string;
    openingCost: number;
    quantity: number;
    serviceDate: string;
    createdAt: string;
    completedAt: any;
    createBy: IUser;
    services: IWorksheetService[];
    categoryProduct: ICategory;
    serviceApply?: { productServiceId?: string; voucherCode?: string }[];
    criteriasCheck: any[];
    PIC: IUser[];
    customer: ICustomer;

    defection: {
        visualization: IWorksheetVisualization[];
        confirmation: IWorksheetConfirm;
    };
    confirmation: IWorksheetConfirm;
    vouchers: IVoucher[];
    isVoucherRemovable?: boolean;
    mileageStart: IWorksheetMileage;
    mileageEnd: IWorksheetMileage;
    totalRemainingSeat?: number;
}

const getCustomerCheckValue = (customerCheck: any[], key: string) =>
    find(customerCheck, (item) => item.id === key).value;

const mapConfirmSignatureToServer = (confirmation: any) => {
    const { confirmed, ...restDefectionConfirm } = confirmation;
    return restDefectionConfirm;
};

export const mapWorksheetCustomerCheck = (customerChecks = []) => {
    return {
        checkService: getCustomerCheckValue(customerChecks, "checkService"),
        checkBelonging: getCustomerCheckValue(customerChecks, "checkBelonging"),
        checkWorkingArea: getCustomerCheckValue(
            customerChecks,
            "checkWorkingArea"
        ),
    };
};

export const mapWorksheetToServer = (worksheet: any) => {
    const { products, address, PIC = [], bookingId, billingId } = worksheet;

    return {
        bookingId,
        addressId: address?.id,
        serviceCategory: products?.[0]?.service?.id,
        service: map(products, (item) => ({
            productId: item.id,
            quantity: item.useQuantity,
            openingCost: item.openingCost,
        })),
        billingId,
        PIC: map(PIC, (item) => item.id),

        // criteria: map(criteria, (criteriaItem) => ({
        //     criteria: map(criteriaItem.criteria, (item) => ({
        //         id: item.id,
        //         valueId: item.valueId,
        //         value: item.value,
        //     })),
        //     // customerCheck: mapWorksheetCustomerCheck(
        //     //     criteriaItem.customerCheck
        //     // ),
        //     priorChecklist: criteriaItem.checklist,
        //     // workProgress: criteriaItem.workProgress,
        //     // deliveryProgress: criteriaItem.deliveryProgress,
        // })),
        // defection: {
        //     visualization: map(defectionVisualList, (item) => ({
        //         defectionImage: item.images,
        //         defectionNote: item.note,
        //     })),
        //     confirmation:
        //         mapConfirmSignatureToServer(defectionConfirm),
        // },
        // confirmation: mapConfirmSignatureToServer(confirmation),
    };
};
