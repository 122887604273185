import _, { every, includes, orderBy, reverse } from "lodash";

const getValueFromStringKey = (object, keyString) => {
    const keyList = keyString.split(".");
    if (keyList.length === 0) {
        return object[keyString];
    }
    let objectResult = object;
    keyList.forEach((key) => {
        objectResult = objectResult?.[key];
    });

    return objectResult;
};

const setValueFromStringKey = (object, keyString, value) => {
    const keyList = keyString.split(".");
    reverse(keyList);
    let objectResult = {};

    if (keyList.length === 0) {
        objectResult[keyString] = value;
        return objectResult;
    }
    keyList.forEach((key, index) => {
        if (index === 0) {
            objectResult[key] = value;
        } else {
            objectResult = { [key]: objectResult };
        }
    });

    return { ...object, ...objectResult };
};
const mapFieldsLangsCTS = (dataClient = {}, KEYS_LANG = []) => {
    let dataResult = {};
    KEYS_LANG.forEach((fields) => {
        dataResult = setValueFromStringKey(dataResult, fields.keyServer, dataClient[fields.keyClient]);
        // dataResult[fields.keyServer] = dataClient[fields.keyClient];
    });
    return dataResult;
};

const mapFieldsLangsSTC = (dataServer, KEYS_LANG = []) => {
    const dataResult = {};
    KEYS_LANG.forEach((fields) => {
        dataResult[fields.keyClient] = getValueFromStringKey(dataServer, fields.keyServer);
    });
    return dataResult;
};

const mapObjectToArray = (object) => {
    if (!object) {
        return [];
    }
    const arrayResult = [];
    Object.keys(object).forEach((key) => arrayResult.push({ id: key, ...object[key] }));
    return arrayResult;
};

const mapArrayToObject = (array, getKey = (item) => item.id) => {
    if (!array || array.length === 0) {
        return {};
    }
    const objectResult = {};
    array.forEach((arrayItem) => {
        const key = getKey(arrayItem);
        objectResult[key] = arrayItem;
    });
    return objectResult;
};

const findItemFromId = (list, id) => {
    if (!list || list.length === 0) {
        return {};
    }

    return _.filter(list, (pro) => pro.id === id)?.[0];
};

const removeItemFromId = (list, id) => {
    if (!list || list.length === 0) {
        return [];
    }

    return _.filter(list, (pro) => pro.id !== id);
};

const sliceArrayToMui = (bigArray = [], numberOfItem = 10) => {
    const arrayOfArrays = [];
    for (let i = 0; i < bigArray.length; i += numberOfItem) {
        arrayOfArrays.push(bigArray.slice(i, i + numberOfItem));
    }

    return arrayOfArrays;
};

const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

function combineAllArray(arr) {
    if (!arr || arr.length === 0) return [];
    if (arr.length === 1) {
        return arr[0];
    }
    const result = [];
    const allCasesOfRest = combineAllArray(arr.slice(1));
    for (let i = 0; i < allCasesOfRest.length; i += 1) {
        for (let j = 0; j < arr[0].length; j += 1) {
            result.push(arr[0][j].concat(allCasesOfRest[i]));
        }
    }
    return result;
}

function findAllChildInArray(root, array) {
    return array.filter((item) => item.parent_id === root.id);
}

function compareTwoStringArray(array1, array2) {
    if (!array1 || !array2 || array1.length !== array2.length) return false;
    return every(array1, (item) => includes(array2, item));
}

/**
 *
 * @param {big array} array1
 * @param {small array} array2
 */
function arrayIsContainArray(array1, array2) {
    return every(array2, (item) => includes(array1, item));
}

const updateArrayById = (arrays = [], newItem) => {
    if (!newItem) {
        return arrays;
    }
    return arrays.map((item) => {
        if (item.id === newItem?.id) {
            return newItem;
        }
        return item;
    });
};

/* eslint-disable no-param-reassign */
export const createDataTree = (_dataset, childset) => {
    const hashTable = Object.create(null);
    const dataset = orderBy(_dataset, "order");
    dataset.forEach(
        // eslint-disable-next-line no-return-assign
        (aData) => (hashTable[aData.value] = { ...aData, children: [] })
    );
    const dataTree = [];
    dataset.forEach((aData) => {
        if (childset && childset[aData.value]) {
            hashTable[aData.value].children = [...hashTable[aData.value].children, ...childset[aData.value]];
        }
        if (aData.parentId) {
            if (!hashTable[aData.parentId]?.children) {
                hashTable[aData.parentId].children = [];
            }
            // eslint-disable-next-line nonblock-statement-body-position
            hashTable[aData.parentId].children.push(hashTable[aData.value]);
            hashTable[aData.parentId].children = orderBy(
                hashTable[aData.parentId].children,
                ["type", "order"],
                ["desc", "asc"]
            );
        } else dataTree.push(hashTable[aData.value]);
    });
    const addKey = (data, level) => {
        data.forEach((item, itemIndex) => {
            item.key = `${level}-${itemIndex}`;
            if (item.children) {
                addKey(item.children, `${level}-${itemIndex}`);
            }
        });
    };
    addKey(dataTree, "0");
    return dataTree;
};

export const flatternArray = (a) => {
    return a.reduce((flattened, { children, ...rest }) => {
        return flattened.concat([{ ...rest }]).concat(children ? flatternArray(children) : []);
    }, []);
};

/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
export function findParentInNestedArray(items, value, parent = null) {
    for (const item of items) {
        const res =
            item.value === value
                ? parent
                : item.children && findParentInNestedArray(item.children, value, item);
        if (res) return res;
    }
}

export default {
    getValueFromStringKey,
    mapObjectToArray,
    findItemFromId,
    removeItemFromId,
    sliceArrayToMui,
    arrayMove,
    mapArrayToObject,
    combineAllArray,
    compareTwoStringArray,
    setValueFromStringKey,
    mapFieldsLangsCTS,
    mapFieldsLangsSTC,
    arrayIsContainArray,
    updateArrayById,
    createDataTree,
    flatternArray,
    findParentInNestedArray,
};
