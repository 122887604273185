import { StringUtils, ViewLabelStatus } from "d-react-components";
import { find, join, map, toUpper } from "lodash";
import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import { REFUND_STATUSES, REFUND_TYPES } from "../../../constant/refundRequest";
import { RefundDetailContext } from "../../../context/refundRequest";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const RefundRequestDetailInfo = () => {
    const { refundRequest } = useContext(RefundDetailContext);

    const refundType = find(
        REFUND_TYPES,
        (type) => type.id === refundRequest?.refundType
    );

    const banksName = join(
        map(
            refundRequest?.banks,
            (item) =>
                `${item.bankName} // ${item.accountNo} // ${item.accountName}`
        ),
        "\n"
    );
    const renderInfoItem = (label: string, content: any) => {
        return (
            <div className="d-flex border-bottom py-2">
                <div className="text-disabled w-50">{label}</div>
                <div className="text-grey w-50">{content}</div>
            </div>
        );
    };
    return (
        <div className="mt-3 card-container p-3">
            <label>{Messages.information}</label>
            {renderInfoItem(Messages.requestNo, refundRequest?.requestNo)}
            {renderInfoItem(
                Messages.refundType,
                (Messages as any)?.[refundType?.label as any]
            )}
            {renderInfoItem(Messages.refundFormat, refundRequest?.refundFormat)}
            {renderInfoItem(
                Messages.refundAmount,
                StringUtils.moneyThaiFormat(refundRequest?.amount)
            )}
            {renderInfoItem(Messages.bankAccount, banksName)}
            {renderInfoItem(
                Messages.status,
                <ViewLabelStatus
                    className="ml-3"
                    status={refundRequest?.status}
                    listStatus={REFUND_STATUSES}
                    getLabel={(item) => toUpper((Messages as any)[item.label])}
                />
            )}
            {refundRequest?.order &&
                renderInfoItem(
                    Messages.orderNo,
                    <AppLink
                        to={generatePath(Path.ORDER_DETAIL, {
                            orderId: refundRequest?.order?.id,
                        })}
                    >
                        {refundRequest?.order?.orderNo}
                    </AppLink>
                )}
            {refundRequest?.worksheet &&
                renderInfoItem(
                    Messages.worksheet,
                    <AppLink
                        to={generatePath(Path.WORKSHEET_DETAIL, {
                            worksheetId: refundRequest?.worksheet?.id,
                        })}
                    >
                        {refundRequest?.worksheet?.worksheetNo}
                    </AppLink>
                )}

            {renderInfoItem(
                Messages.attachments,
                <div className="d-flex">
                    {map(refundRequest?.attachments, (item) => (
                        <Image src={item} className="mr-3 image-reg-medium" />
                    ))}
                </div>
            )}
            {renderInfoItem(Messages.reason, refundRequest?.remark)}
        </div>
    );
};

export default RefundRequestDetailInfo;
