import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";

export const F_CRITERIA_ITEM = gql`
    fragment F_CRITERIA_ITEM on Criteria {
        id
        name
        type
        values {
            id
            value
        }
        valuesQty
        createdAt
        updatedAt
        status
    }
`;

export const F_CRITERIA_LIST = gql`
    ${F_CRITERIA_ITEM}
    ${F_PAGINATION}
    fragment F_CRITERIA_LIST on Criterias {
        criteria {
            ...F_CRITERIA_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_CRITERIA = gql`
    fragment F_CRITERIA on Criteria {
        id
        name
        type
        values {
            id
            value
        }
        valuesQty
        createdAt
        updatedAt
        status
    }
`;
