/* eslint-disable no-await-in-loop */
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    Modal,
    Notifications,
    TimeUtils,
} from "d-react-components";
import {
    debounce,
    filter,
    find,
    map,
    reduce,
    replace,
    uniq,
    uniqBy,
} from "lodash";
import { useContext, useRef, useState } from "react";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import UserAvatarName from "../../user/common/UserAvatarName";
import { PRODUCT_TYPE } from "../../../constant/product";
import { OrderCreateContext } from "../../../context/order";
import { IBooking } from "../../../interfaces/booking";
import { IWorksheet } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";

interface ILoadWorksheetModal {
    open: boolean;
    defaultValue: IWorksheet[];
    onClose: () => void;
    onSave: any;
}

const LoadWorksheetModal = ({
    open,
    defaultValue,
    onClose,
    onSave,
}: ILoadWorksheetModal) => {
    const { orderForm } = useContext(OrderCreateContext);
    const [worksheetSelect, setWorksheetSelect] =
        useState<IWorksheet[]>(defaultValue);

    const searchRef = useRef("");
    const tableRef = useRef<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.worksheetNo,
            dataIndex: "worksheetNo",
        },
        {
            title: Messages.customer,
            dataIndex: "booking",
            render: (booking: IBooking) =>
                `${booking?.customer?.firstName} ${booking?.customer?.lastName}`,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            // render: (booking: IBooking) =>
            //     `${booking?.customer?.firstName} ${booking?.customer?.lastName}`,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createBy) => (
                <UserAvatarName user={createBy} placeholder="" />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) =>
        WorksheetAPI.listToLoadOrder(
            searchRef.current,
            pagingData,
            orderForm?.values?.customer?.id
        );

    const transformer = (res: any) => {
        return res?.data?.data?.worksheet ?? [];
    };

    const onChangeSelectWorksheet = (
        selectedRowKeys: string[],
        selectRows: any[]
    ) => {
        const existedSelectRow = filter(selectRows, (item) => !!item);
        const worksheets = map(selectedRowKeys, (id) => {
            let selectRow = find(existedSelectRow, (item) => item?.id === id);
            if (!selectRow) {
                selectRow = find(worksheetSelect, (item) => item.id === id);
            }
            return selectRow;
        });
        setWorksheetSelect(worksheets);
    };

    const onChangeTextSearch = debounce((event) => {
        searchRef.current = event.target.value;
        tableRef.current.refresh();
    }, 1000);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.worksheet}
            onSave={() => {
                onSave(worksheetSelect);
                onClose && onClose();
            }}
            size="large"
        >
            <InputTextSearch onChange={onChangeTextSearch} />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                source={source}
                transformer={transformer}
                className="mt-3"
                rowSelection={{
                    selectedRowKeys: map(worksheetSelect, (item) => item.id),
                    preserveSelectedRowKeys: true,
                    onChange: onChangeSelectWorksheet as any,
                }}
            />
        </Modal>
    );
};

const OrderCreateLoadWorksheets = () => {
    const { orderForm } = useContext(OrderCreateContext);
    const [openModal, setOpenModal] = useState(false);
    const { isInvoice, worksheetIds } = orderForm?.values;

    const loadWorksheetProducts = (worksheets: any, products: any[]) => {
        const currentProducts = orderForm?.values?.productsWorksheet ?? [];

        const productsResult = uniqBy(
            [...currentProducts, ...products],
            (item) => `${item.id}${item.worksheetId}`
        );
        orderForm.setFieldValue("productsWorksheet", productsResult);

        //load worksheetIds
        const newWorksheetIds = map(worksheets, (item) => item.id);
        const worksheetIdList = [...worksheetIds, ...newWorksheetIds];
        orderForm.setFieldValue("worksheetIds", uniq(worksheetIdList));
    };

    /**
     * check all items in all worksheets(added and adding)
     * if two worksheet has same product =>so these products should be same openingCost(salePrice)
     * todo that => array 1 is uniq all products by id and salePrice and array2 is uniq by id
     * if length of two above arrays is the same => PASS
     */
    const checkMultipleProductCost = (products: any[]) => {
        const currentProducts = orderForm?.values?.productsWorksheet ?? [];
        const allProducts = [...currentProducts, ...products];

        const allUniqIdPriceProduct = uniqBy(
            allProducts,
            (item) => `${item.id}_${item.salePrice}`
        );
        const allUniqIdProduct = uniqBy(allProducts, (item) => item.id);

        return allUniqIdPriceProduct?.length !== allUniqIdProduct?.length;
    };

    const getWorksheetProducts = (worksheet: any) => {
        const products = map(worksheet?.services ?? [], (item) => {
            let salePrice = item.openingCost;
            /**
             * with checked invoice, service product will be price * 1.07
             */
            if (
                isInvoice &&
                item.product?.productType === PRODUCT_TYPE.SERVICE_PRODUCT
            ) {
                salePrice *= 1.07;
            }
            return {
                ...item.product,
                salePrice,
                salePriceAtCreated: salePrice,
                quantity: item.availableOrderQty,
                maxQuantity: item.availableOrderQty,
                worksheetId: worksheet?.id,
                worksheetNo: worksheet?.worksheetNo,
                isProductWorksheet: true,
            };
        });
        // const nonZeroProducts = filter(products, (item) => item.quantity > 0);
        return products;
    };

    /**
     * A valid load worksheet must be:
     * WS's product have left item to load
     * Products opening cost must be the same between WSs.
     */
    const loadWorksheets = (worksheets: IWorksheet[]) => {
        const validWorksheets = filter(worksheets, (worksheet) => {
            // const products = getWorksheetProducts(worksheet);
            // if (!products?.length) {
            //     Notifications.showError(
            //         replace(
            //             Messages.thisWorksheetNoProductLeft,
            //             "%worksheetNo",
            //             worksheet?.worksheetNo
            //         )
            //     );
            //     return false;
            // }
            return true;
        });

        const allWsProducts = reduce(
            validWorksheets,
            (products: any[], worksheet) => {
                const productWs = getWorksheetProducts(worksheet);
                return [...products, ...productWs];
            },
            []
        );

        const isMultipleDiffCost = checkMultipleProductCost(allWsProducts);

        if (isMultipleDiffCost) {
            Notifications.showError(
                `Not allow to load these worksheets because opening cost of item should be the same in all worksheets`
            );
            return;
        }
        loadWorksheetProducts(worksheets, allWsProducts);
    };

    return (
        <div className="ml-3">
            <Button
                onClick={() => setOpenModal(true)}
                disabled={!orderForm?.values?.customer}
            >
                {Messages.loadWorksheet}
            </Button>
            {openModal && (
                <LoadWorksheetModal
                    defaultValue={[]}
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    onSave={loadWorksheets}
                />
            )}
        </div>
    );
};

export default OrderCreateLoadWorksheets;
