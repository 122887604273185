import {
    Button,
    Checkbox,
    InputText,
    Modal,
    Notifications,
    RadioGroup,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, map, uniqBy } from "lodash";
import React, { useContext, useState } from "react";
import { ORDER_FEES } from "../../../constant/order";
import { PRODUCT_TYPE } from "../../../constant/product";
import { OrderCreateContext } from "../../../context/order";
import { OrderFeeSchema } from "../../../formschema/order";
import { getCreditTermString } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import OrderCreateDiscountModal from "./OrderCreateDiscountModal";
import OrderCreateSelectVoucherButton from "./vouchers/OrderCreateSelectVoucherButton";

interface IAddTransportFeeModal {
    open: boolean;
    onClose: () => void;
    onSave: (fee: any) => void;
}

function AddFeeModal({ open, onClose, onSave }: IAddTransportFeeModal) {
    const feeForm = useFormik<any>({
        initialValues: { type: "transportation" } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderFeeSchema,
        onSubmit: (values: any) => {
            onSave(values);
            onClose();
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.addTransportationFee}
            onSave={() => {
                feeForm.handleSubmit();
            }}
            saveText={Messages.add}
        >
            <RadioGroup
                label={Messages.feeType}
                numberOfColumns="2"
                dataSource={ORDER_FEES}
                value={feeForm?.values?.type}
                onChange={(value) => feeForm.setFieldValue("type", value)}
                getLabel={(item) => (Messages as any)[item.label]}
            />
            <InputText
                label={Messages.amount}
                value={feeForm?.values?.value}
                onChange={(event) => {
                    const { value } = event.target;
                    const result = value ? parseInt(value, 10) : 0;
                    feeForm.setFieldValue("value", result);
                }}
            />
        </Modal>
    );
}

function OrderCreateDiscount() {
    const { orderForm } = useContext(OrderCreateContext);
    const [addFeeModal, setAddFeeModal] = useState(false);
    const [openCreateDiscount, setOpenCreateDiscount] = useState(false);
    const {
        isInvoice,
        isCreditNote,
        customProducts,
        productsWorksheet,
        products,
        fees,
        productVouchers,
        customer,
    } = orderForm.values;

    const onAddFee = (fee: any) => {
        const result = uniqBy([fee, ...fees], (item) => item.type);
        orderForm.setFieldValue("fees", result);
    };

    const onClickAddDiscount = () => {
        if (isEmpty([...products, ...productsWorksheet, ...customProducts])) {
            Notifications.showError(Messages.youNeedAddAtLeastOneProduct);
            return;
        }
        setOpenCreateDiscount(true);
    };

    const getProductOnCheckInvoice = (product: any, isInvoice: boolean) => {
        if (product.productType === PRODUCT_TYPE.SERVICE_PRODUCT) {
            if (isInvoice) {
                return { ...product, salePrice: product.salePrice * 1.07 };
            }
            return { ...product, salePrice: product.salePrice / 1.07 };
        }
        return product;
    };

    const onCheckTaxInvoice = () => {
        const isInvoiceResult = !isInvoice;
        const productAfterInvoice = map(products, (product) =>
            getProductOnCheckInvoice(product, isInvoiceResult)
        );
        const productWSAfterInvoice = map(productsWorksheet, (product) =>
            getProductOnCheckInvoice(product, isInvoiceResult)
        );

        const vouchersAfterInvoice = map(productVouchers, (voucher) => {
            if (isInvoiceResult) {
                return { ...voucher, amount: voucher.amount * 1.07 };
            }
            return { ...voucher, amount: voucher.amount / 1.07 };
        });

        orderForm.setValues({
            ...orderForm.values,
            isInvoice: isInvoiceResult,
            products: productAfterInvoice,
            productsWorksheet: productWSAfterInvoice,
            productVouchers: vouchersAfterInvoice,
        });
    };

    const customerCreditTerm = customer?.creditTerm;

    return (
        <div className="flex-column w-50 pr-3">
            <label>{Messages.discountAndFee}</label>
            <div className="flex-center-y">
                <Button
                    onClick={onClickAddDiscount}
                    className="mt-3"
                    variant="outline"
                >
                    {Messages.addDiscount}
                </Button>
                <Button
                    onClick={() => setAddFeeModal(true)}
                    variant="outline"
                    className="mt-3 mx-2"
                >
                    {Messages.addFee}
                </Button>
                <OrderCreateSelectVoucherButton />
            </div>
            {/* <label className="mt-3">{Messages.commercialTaxInvoice}</label> */}
            {/* <Checkbox
                checked={isInvoice}
                onChange={onCheckTaxInvoice}
                label={Messages.iWantCommercialTax}
            /> */}
            <div hidden={!customer || !customerCreditTerm}>
                <label className="mt-3">{Messages.creditNote}</label>
                <Checkbox
                    checked={isCreditNote}
                    onChange={() =>
                        orderForm.setFieldValue("isCreditNote", !isCreditNote)
                    }
                    label={Messages.iWantApplyCreditNote}
                />
                <div className="bg-primary-20 mt-3 px-3 py-2 w-fit-content">
                    <text className="text-primary small">
                        {`${Messages.creditTerm}: ${getCreditTermString(
                            customerCreditTerm
                        )}`}
                    </text>
                </div>
            </div>

            {addFeeModal && (
                <AddFeeModal
                    open={addFeeModal}
                    onClose={() => setAddFeeModal(false)}
                    onSave={onAddFee}
                />
            )}
            {openCreateDiscount && (
                <OrderCreateDiscountModal
                    open={openCreateDiscount}
                    onClose={() => setOpenCreateDiscount(false)}
                />
            )}
        </div>
    );
}

export default OrderCreateDiscount;
