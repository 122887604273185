import { gql } from "@apollo/client";
import { F_USER } from "../user/UserFragment";

export const F_DIRECTORY = gql`
    fragment F_DIRECTORY on Directory {
        id
        name
        size
        parentId
    }
`;

export const F_MEDIA_ITEM = gql`
    fragment F_MEDIA_ITEM on MediaImage {
        id
        title
        type
        url
        size
    }
`;

export const F_MEDIA = gql`
    ${F_USER}
    fragment F_MEDIA on MediaImage {
        id
        title
        description
        type
        size
        dimension
        url
        modificationDate
        createBy {
            ...F_USER
        }
    }
`;
