import { InputText, Select, ViewCollapse, ViewRow } from "d-react-components";
import React, { useContext } from "react";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { WAREHOUSE_STATUSES } from "../../../constant/warehouse";
import { CategoryDetailContext } from "../../../context/category";
import Messages from "../../../languages/Messages";
import SelectMediaManagerButton from "../../media/selectmedia/SelectMediaManagerButton";
import { YES_NO_OPTIONS } from "../../../constant/app";

const CategoryDetailGeneral = () => {
    const { categoryForm } = useContext(CategoryDetailContext);
    const {
        name,
        priority,
        description,
        thumbnail,
        status,
        mileageTrackingRequired,
    } = categoryForm?.values ?? {};
    const errors = categoryForm?.errors ?? {};

    return (
        <div>
            <ViewCollapse label={Messages.general}>
                <ViewRow label={Messages.status}>
                    <Select
                        value={status}
                        error={errors?.status}
                        dataSource={WAREHOUSE_STATUSES}
                        getLabel={(item) => (Messages as any)[item?.label]}
                        onChange={(value) =>
                            categoryForm.setFieldValue("status", value)
                        }
                    />
                </ViewRow>
                <ViewRow label={Messages.name} className="mt-3">
                    <InputText
                        placeholder={Messages.name}
                        value={name}
                        name="name"
                        onChange={categoryForm.handleChange}
                        error={errors.name}
                    />
                </ViewRow>
                <ViewRow label={Messages.mileageTracking} className="mt-3">
                    <Select
                        value={mileageTrackingRequired}
                        error={errors?.mileageTrackingRequired}
                        dataSource={YES_NO_OPTIONS}
                        onChange={(value) =>
                            categoryForm.setFieldValue(
                                "mileageTrackingRequired",
                                value
                            )
                        }
                    />
                </ViewRow>
                <ViewRow label={Messages.priority} className="mt-3">
                    <InputText
                        placeholder={Messages.priority}
                        value={priority}
                        name="priority"
                        onChange={categoryForm.handleChange}
                        error={errors.priority}
                    />
                </ViewRow>
            </ViewCollapse>

            <ViewCollapse label={Messages.description} className="mt-4">
                <RichTextTiny
                    value={description}
                    onChange={(value: string) =>
                        categoryForm.setFieldValue("description", value)
                    }
                />
            </ViewCollapse>
            <ViewCollapse label={Messages.featuredImage} className="mt-4">
                {thumbnail && (
                    <img src={thumbnail} className="image-square-x-large" />
                )}
                <SelectMediaManagerButton
                    isSingleSelect
                    onSelect={([value]: any) => {
                        categoryForm.setFieldValue("thumbnail", value?.url);
                    }}
                    className="mt-3"
                />
                <small className="font-italic mt-2">
                    Recommended size: W400 x H400
                </small>
            </ViewCollapse>
        </div>
    );
};

export default CategoryDetailGeneral;
