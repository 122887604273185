import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";
import { F_PRODUCT_CAT_ITEM } from "../product/ProductFragment";
import { F_USER } from "../user/UserFragment";

export const F_INVENTORY_ITEM = gql`
    ${F_PRODUCT_CAT_ITEM}

    fragment F_INVENTORY_ITEM on Inventory {
        id
        name
        SKU
        category {
            ...F_PRODUCT_CAT_ITEM
        }
        quantity
        allAvailableCW
        allOnHoldCW
        productGroupIds
        productId
        productType
    }
`;

export const F_INVENTORY_LOG = gql`
    ${F_USER}
    ${F_PAGINATION}
    fragment F_INVENTORY_LOG on InventoryLogs {
        logs {
            id
            stockId
            createdAt
            adjustment
            newQuantity
            adjustBy {
                ...F_USER
            }
            remark
            attach
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_INVENTORIES = gql`
    ${F_INVENTORY_ITEM}
    ${F_PAGINATION}
    fragment F_INVENTORIES on Inventories {
        inventory {
            ...F_INVENTORY_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;
