import React from "react";
import { INewsCategory } from "../interfaces/news";

interface INewsCrudState {
    newsForm: any;
    newsLang: string;
    setNewsLang: any;
    categoryList: INewsCategory[];
}

const newsCrudState: INewsCrudState = {
    newsForm: null,
    newsLang: "en",
    categoryList: [],
    setNewsLang: () => {},
};

export const NewsCrudContext = React.createContext(newsCrudState);
