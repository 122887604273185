/* eslint-disable jsx-a11y/label-has-associated-control */
// react
import { Progress, UrlUtils } from "d-react-components";
import React from "react";
import AuthAPI from "../../api/auth/AuthAPI";
import { useSignIn } from "../../store/auth/authHook";
// data stubs
// application
import Path from "../Path";
import OtpFormView from "./shared/OtpFormView";

export default function AuthOTP() {
    const signIn = useSignIn();
    const onSubmitHandler = (code: string) => {
        Progress.show(
            {
                method: AuthAPI.otpAuthenticate,
                params: [
                    { code, accessToken: UrlUtils.getQuery()?.accessToken },
                ],
            },
            onProcessAuthenticated
        );
    };

    const onProcessAuthenticated = (res: any) => {
        const { accessToken } = res?.data.authenticate;
        signIn(accessToken);
        window.open(Path.ROOT, "_self");
    };

    return (
        <React.Fragment>
            <div className="row" style={{ height: "100vh", width: "100vw" }}>
                <div className="col-1 col-sm-7 ">
                    <img
                        src="/images/auth-background.png"
                        className="auth-image__background"
                    />
                </div>

                <div className="col-11 col-sm-5 flex-column justify-content-center">
                    <div className="flex-center">
                        <img
                            src="/images/logo.png"
                            className="image-reg-medium mb-5"
                        />
                    </div>
                    <OtpFormView onSubmitHandler={onSubmitHandler} />
                </div>
            </div>
        </React.Fragment>
    );
}
