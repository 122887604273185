import { CheckboxGroup, RadioGroup } from "d-react-components";
import React, { useContext } from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import {
    CUSTOMER_STATUSES,
    CUSTOMER_TYPES,
    GENDERS,
} from "../../../constant/customer";
import { AppStateContext } from "../../../context/app";
import { IProvince } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";

interface ICustomerFilterForm {
    formData: any;
}

const CustomerFilterForm = ({ formData }: ICustomerFilterForm) => {
    const { provinceList } = useContext(AppStateContext);
    const classNameInput = "col-12";
    return (
        <div className="row mt-3">
            <CheckboxGroup
                className={classNameInput}
                label={Messages.status}
                dataSource={CUSTOMER_STATUSES}
                value={formData?.values?.status}
                onChange={(value) => formData.setFieldValue("status", value)}
                getLabel={(item) => (Messages as any)[item.label]}
            />

            <CheckboxGroup
                className={classNameInput}
                label={Messages.gender}
                dataSource={GENDERS}
                value={formData?.values?.gender}
                onChange={(value) => formData.setFieldValue("gender", value)}
                getLabel={(item) => (Messages as any)[item.label]}
            />

            <CheckboxGroup
                className={classNameInput}
                label={Messages.customerType}
                dataSource={CUSTOMER_TYPES}
                value={formData?.values?.typeOfCustomer}
                onChange={(value) =>
                    formData.setFieldValue("typeOfCustomer", value)
                }
                getLabel={(item) => (Messages as any)[item.label]}
            />
            <InputSelectForm
                form={formData}
                keyData="province"
                className={classNameInput}
                dataSource={provinceList}
                getLabel={(item: IProvince) => item.name}
                multiple
            />
        </div>
    );
};

export default CustomerFilterForm;
