import { Modal } from "d-react-components";
import { useFormik } from "formik";
import Messages from "../../../languages/Messages";
import WorksheetFilterForm from "../common/WorksheetFilterForm";

interface IWorksheetTableFilterModal {
    open: boolean;
    defaultValue: any;
    onClose: () => void;
    onFilter: (values: any) => void;
}

function WorksheetFilterModal({
    open,
    defaultValue = {},
    onClose,
    onFilter,
}: IWorksheetTableFilterModal) {
    const filterForm = useFormik<any>({
        initialValues: defaultValue as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onFilter(values);
            onClose();
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm.handleSubmit()}
            size="medium"
            sideText={Messages.clearAll}
            onSideClick={() => filterForm.setValues({})}
        >
            <WorksheetFilterForm filterForm={filterForm} />
        </Modal>
    );
}

export default WorksheetFilterModal;
