import { Modal, ObjectUtils, Select, StringUtils } from "d-react-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CategoryAPI from "../../../api/category/CategoryAPI";
import InputTextForm from "../../../common/input/InputTextForm";
import { OrderCustomItemSchema } from "../../../formschema/order";
import Messages from "../../../languages/Messages";

interface IOrderCreateCustomItemModal {
    open: boolean;
    defaultValue?: any;
    onClose: () => void;
    onSave: (customerItem: any) => void;
}

function OrderCreateCustomItemModal({
    open,
    defaultValue,
    onClose,
    onSave,
}: IOrderCreateCustomItemModal) {
    const [categoryList, setCategoryList] = useState([]);

    const customItemForm = useFormik<any>({
        initialValues:
            defaultValue ??
            ({
                id: StringUtils.getUniqueID(),
                isCustomProduct: true,
                quantity: 1,
            } as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderCustomItemSchema,
        onSubmit: (values: any) => {
            onSave(values);
            onClose();
        },
    });

    useEffect(() => {
        loadServiceCategory();
    }, []);

    const loadServiceCategory = () => {
        CategoryAPI.activeCategoryList().then((res) =>
            setCategoryList(res?.data?.data?.category ?? [])
        );
    };

    const classNameField = "mt-3 col-6";
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.addCustomItem}
            onSave={() => customItemForm.handleSubmit()}
        >
            <div className="row">
                <Select
                    dataSource={categoryList}
                    required
                    className={classNameField}
                    getLabel={(item: any) => item.name}
                    value={customItemForm.values.category?.id}
                    onChange={(value) => {
                        const category = ObjectUtils.findItemFromId(
                            categoryList,
                            value
                        );
                        customItemForm.setFieldValue("category", category);
                    }}
                    label={Messages.category}
                />
                <InputTextForm
                    keyData="name"
                    form={customItemForm}
                    required
                    className={classNameField}
                />
                <InputTextForm
                    keyData="SKU"
                    form={customItemForm}
                    required
                    className={classNameField}
                    label={Messages.sku}
                />
                <InputTextForm
                    label={Messages.salePrice}
                    keyData="salePriceAtCreated"
                    form={customItemForm}
                    required
                    className={classNameField}
                />
                <InputTextForm
                    label={Messages.openingPrice}
                    keyData="salePrice"
                    form={customItemForm}
                    required
                    className={classNameField}
                />
                <InputTextForm
                    keyData="remark"
                    form={customItemForm}
                    multiple
                    className="mt-3 col-12"
                />
            </div>
        </Modal>
    );
}

export default OrderCreateCustomItemModal;
