import { gql } from "@apollo/client";
import { map } from "lodash";
import { appStateDefault, IAppState } from "../context/app";
import API from "./API";
import { F_USER_ROLE } from "./user/UserFragment";

export default {
    loadMetaData: () =>
        API.withConverter({
            fromServer: (res: any): IAppState => ({
                ...appStateDefault,
                provinceList: res?.data?.provinces ?? [],
                countryList: map(res?.data?.nationality ?? [], (item) => ({
                    id: item,
                    name: item,
                })),
                me: res?.data?.me ?? {},
            }),
        }).query({
            query: gql`
                ${F_USER_ROLE}
                query metaData {
                    provinces {
                        id
                        name
                    }
                    nationality

                    me {
                        id
                        email
                        firstName
                        lastName
                        username
                        nickName
                        avatar
                        companyId
                        role {
                            ...F_USER_ROLE
                        }
                        createdAt
                        updatedAt
                        lastLogin
                        status
                    }
                }
            `,
        }),
};
