// react
import classNames from "classnames";
import {
    AwesomeListComponent,
    Badge,
    Breadcrumb,
    Button,
    Drawer,
    Dropdown,
    Icon,
    Notifications,
    TabBar,
    TimeUtils,
    UrlUtils,
    useFirstTime,
} from "d-react-components";
import _, { includes, isEmpty, map } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
// third-party
import { generatePath, Link, useHistory, useLocation } from "react-router-dom";
import AppAPI from "../api/AppAPI";
import AuthAPI from "../api/auth/AuthAPI";
import NotificationAPI from "../api/notification/NotificationAPI";
import Image from "../common/Image";
import {
    FIREBASE_MESSAGING_KEY,
    LANGUAGES,
    LANGUAGE_DEFAULT,
    MAIN_MENU,
    SITE_SETTINGS,
} from "../constant/app";
import { NOTIFICATION_TYPE } from "../constant/notification";
import { ROLE_ACCESS_TYPE } from "../constant/user";
import {
    AppStateContext,
    appStateDefault,
    IAppState,
    ISiteConfig,
} from "../context/app";
import FirebaseMessaging from "../firebase/firebase";
import { useUserPermission } from "../hoook/permission";
import { INotification } from "../interfaces/notification";
import Messages from "../languages/Messages";
import { useSignOut } from "../store/auth/authHook";
import Path from "./Path";
import Routes from "./Routes";
import UserAuthOtp from "./user/common/UserAuthOtp";

const usePathInfo = () => {
    const location = useLocation();
    const pathName = location.pathname;
    return useMemo(() => {
        let menuSelecting;
        MAIN_MENU.forEach((menuItem: any) => {
            if (menuItem.path === pathName) {
                menuSelecting = menuItem;
            }

            if (menuItem.subMenu?.length > 0) {
                menuItem.subMenu.forEach((subMenuItem: any) => {
                    if (subMenuItem.path === pathName) {
                        menuSelecting = subMenuItem;
                    }
                });
            }
        });
        return menuSelecting;
    }, [pathName]);
};

const CustomLink = ({ to, className, onClick, ...props }: any) => {
    if (isEmpty(to)) {
        return (
            <div className={className} onClick={onClick}>
                {props.children}
            </div>
        );
    }
    return (
        <Link to={to} className={className}>
            {props.children}
        </Link>
    );
};
const SubMenuItem = ({ menu, subMenu }: any) => {
    const location = useLocation();

    const isGrantPermission = useUserPermission(menu?.id, subMenu.accessType);
    if (!isGrantPermission) return <div />;

    const isSelected = (menuSelect: any) => {
        if (_.isEmpty(menuSelect.path)) return false;
        if (menuSelect.path !== location.pathname) return false;
        return true;
    };

    const classNameSubMenu = classNames("app-layout__sub-menu-item", {
        "app-layout__sub-menu-item-active": isSelected(subMenu),
    });

    return (
        <Link to={subMenu.path} className={classNameSubMenu} key={subMenu?.id}>
            {/* <Icon name="done" /> */}
            <text className="text-medium app-layout__menu-list-title">
                {(Messages as any)[subMenu.label]}
            </text>
        </Link>
    );
};
const MenuItem = ({ menu }: any) => {
    const location = useLocation();
    const { setOpenUserAuthOtp } = useContext(AppStateContext);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const subMenuList = menu.subMenu ?? [];
    const hasSubMenu = subMenuList.length > 0;
    const isGrantPermissionMenu = useUserPermission(
        menu?.id,
        ROLE_ACCESS_TYPE.MENU
    );

    const isSelected = (menuSelect: any) => {
        if (_.isEmpty(menuSelect.path)) return false;
        if (menuSelect.path !== location.pathname) return false;
        return true;
    };

    const onClickMenuItem = () => {
        if (menu?.id === "oauthCode") {
            setOpenUserAuthOtp(true);
            return;
        }
        setOpenSubMenu(!openSubMenu);
    };

    const hasSubMenuActive = includes(
        map(subMenuList, (item) => item.path),
        location.pathname
    );
    const classNameInner = classNames("app-layout__menu-list-item-inner", {
        "app-layout__menu-list-item-active": isSelected(menu),
        "app-layout__menu-list-item-sub-active": hasSubMenuActive,
    });

    if (!isGrantPermissionMenu) return <div />;

    return (
        <div className="app-layout__menu-list-item">
            <CustomLink
                to={menu?.path}
                className={classNameInner}
                onClick={onClickMenuItem}
            >
                <Icon name={menu.iconName} />
                <text className="text-medium app-layout__menu-list-title">
                    {(Messages as any)[menu.label]}
                </text>
                {hasSubMenu && (
                    <Icon
                        name="keyboard_arrow_down"
                        className="app-layout__menu-list-icon-arrow"
                    />
                )}
            </CustomLink>
            {openSubMenu && hasSubMenu && (
                <div className="app-layout__sub-menu-list">
                    {map(subMenuList, (subMenu: any) => (
                        <SubMenuItem menu={menu} subMenu={subMenu} />
                    ))}
                </div>
            )}
        </div>
    );
};

const DropdownLanguage = () => {
    const { locale = LANGUAGE_DEFAULT } = UrlUtils.getQuery();
    const languageSelecting = LANGUAGES.find((item) => item.id === locale);

    const onChangeLocale = ({ id }: any) => {
        if (id === locale) {
            return;
        }
        UrlUtils.replaceQuery({ locale: id });
    };

    return (
        <Dropdown withIcons dataSource={LANGUAGES} onClick={onChangeLocale}>
            {languageSelecting && (
                <div className="d-flex align-items-center cursor-pointer">
                    <img src={languageSelecting.icon} alt="" />
                    <h6 className="ml-2">{languageSelecting.label}</h6>
                </div>
            )}
        </Dropdown>
    );
};

const NOTIFICATION_TABS = [
    {
        id: "ALL",
        label: Messages.all,
    },

    {
        id: "UNREAD",
        label: Messages.unRead,
    },
];
const DropdownNotification = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [unread, setUnread] = useState(0);
    const [tabSelected, setTabSelected] = useState<any>(NOTIFICATION_TABS[0]);
    const history = useHistory();
    const notificationList = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        loadUnreadNumber();
    }, []);

    useEffect(() => {
        if (isFirstTime) return;
        notificationList.current?.refresh();
    }, [tabSelected]);

    const loadUnreadNumber = () => {
        NotificationAPI.notificationUnread().then((res) => {
            const { unread } = res?.data?.notificationUnread ?? {};
            setUnread(unread ?? 0);
        });
    };

    const markAllRead = () => {
        NotificationAPI.markAllNotificationAsRead().then(() => {
            loadUnreadNumber();
            notificationList.current.refresh();
        });
    };

    const source = (paging: any) => {
        let isRead;
        if (tabSelected?.id === "UNREAD") {
            isRead = false;
        }
        return NotificationAPI.notifications(paging, isRead);
    };

    const transformer = (res: any) => {
        return res?.data?.data?.notification ?? [];
    };

    const onClickNotification = (notification: INotification) => {
        const { data, type, isRead } = notification;
        switch (type) {
            case NOTIFICATION_TYPE.NEW_BOOKING:
            case NOTIFICATION_TYPE.NEW_COMMENT_BOOKING:
            case NOTIFICATION_TYPE.BOOKING_SCHEDULE_SERVICE:
                data?.bookingId &&
                    history.push(
                        generatePath(Path.BOOKING_DETAIL, {
                            bookingId: data.bookingId,
                        })
                    );
                break;
            case NOTIFICATION_TYPE.NEW_WORKSHEET:
            case NOTIFICATION_TYPE.NEW_COMMENT_WORKSHEET:
                data?.worksheetId &&
                    history.push(
                        generatePath(Path.WORKSHEET_DETAIL, {
                            worksheetId: data.worksheetId,
                        })
                    );
                break;

            case NOTIFICATION_TYPE.NEW_ORDER:
            case NOTIFICATION_TYPE.NEW_COMMENT_ORDER:
            case NOTIFICATION_TYPE.ORDER_CONFIRMED:
                data?.orderId &&
                    history.push(
                        generatePath(Path.ORDER_DETAIL, {
                            orderId: data.orderId,
                        })
                    );
                break;

            default:
                break;
        }
        if (!isRead) onMarkAsRead(notification);
    };

    const onMarkAsRead = (notification: INotification) => {
        NotificationAPI.markNotificationAsRead(notification?.id).then(() => {
            loadUnreadNumber();
        });
    };

    const renderItem = (notification: INotification) => {
        const { content, sender, createdAt, isRead } = notification;
        const containerClassName = classNames(
            "py-3 border-bottom cursor-pointer d-flex px-3",
            { "bg-note": !isRead }
        );
        return (
            <div
                className={containerClassName}
                onClick={() => onClickNotification(notification)}
            >
                <Image src={sender?.avatar} className="image-square-small" />
                <div className="flex-column px-3">
                    {content}
                    <text className="small">
                        {TimeUtils.toDateTime(createdAt)}
                    </text>
                </div>
            </div>
        );
    };

    const renderLabelTab = (tab: any) => {
        return (
            <div className="flex-center">
                {tab.label}
                {tab.id === "UNREAD" && (
                    <Badge
                        className="ml-3"
                        variant="index"
                        size="xx-large"
                        index={unread}
                    />
                )}
            </div>
        );
    };

    const renderNotifications = useMemo(
        () => (
            <div className="card-container py-3 flex-column border w-100 h-100">
                <div className="p-2 border-bottom h4">
                    {Messages.notification}
                </div>
                <div className="flex-row-between-center pr-3 border-bottom">
                    <TabBar
                        dataSource={NOTIFICATION_TABS}
                        onChange={setTabSelected}
                        value={tabSelected}
                        getItemProps={({ item, isActive }) => {
                            if (isActive) {
                                return {
                                    style: {
                                        borderColor: "white",
                                        borderBottom: "2px solid #587C5F",
                                        backgroundColor: "white",
                                        color: "#587C5F",
                                    },
                                };
                            }
                            return {
                                style: {
                                    borderColor: "white",
                                    backgroundColor: "white",
                                },
                            };
                        }}
                        getLabel={renderLabelTab}
                    />
                    <div
                        className="small text-primary cursor-pointer"
                        onClick={markAllRead}
                    >
                        {Messages.markAllAsRead}
                    </div>
                </div>
                <AwesomeListComponent
                    ref={(ref) => {
                        notificationList.current = ref;
                    }}
                    source={source}
                    transformer={transformer}
                    renderItem={renderItem}
                    styleContainer={{ overflowY: "auto" }}
                    isPaging
                />
            </div>
        ),
        [tabSelected]
    );

    return (
        <div>
            <Badge
                variant="index"
                size="xx-large"
                index={unread}
                color="secondary"
            >
                <Button
                    iconName="notifications"
                    color="primary"
                    variant="trans"
                    onClick={() => setShowDrawer(true)}
                />
            </Badge>
            <Drawer
                placement="right"
                onClose={() => setShowDrawer(false)}
                open={showDrawer}
            >
                {renderNotifications}
            </Drawer>
        </div>
    );
};

const DropdownSiteSetting = () => {
    const signOut = useSignOut();
    const history = useHistory();

    const onSignOut = () => {
        signOut();
        history.replace(Path.AUTH);
    };

    const onClickSetting = (settingItem: any) => {
        switch (settingItem.id) {
            default:
                onSignOut();
        }
    };
    return (
        <Dropdown
            dataSource={SITE_SETTINGS}
            onClick={onClickSetting}
            buttonProps={{
                iconName: "settings",
                classNameIcon: "text-primary",
            }}
            // className="ml-3"
        />
    );
};

const AppLayoutContext = ({ children }: any) => {
    const [metaData, setMetaData] = useState<IAppState>(appStateDefault);
    const [siteConfig, setSiteConfig] = useState<ISiteConfig>({});
    const [openUserAuthOtp, setOpenUserAuthOtp] = useState(false);

    useEffect(() => {
        loadMetaData();
    }, []);

    const loadMetaData = () => {
        AppAPI.loadMetaData().then(setMetaData);
    };

    return (
        <AppStateContext.Provider
            value={{
                ...metaData,
                siteConfig,
                setSiteConfig,
                openUserAuthOtp,
                setOpenUserAuthOtp,
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
};

const Navbar = () => {
    const menuSelecting: any = usePathInfo();
    const { siteConfig, setSiteConfig } = useContext(AppStateContext);

    useEffect(() => {
        if (!menuSelecting) return;
        setSiteConfig({
            breadcrumb: menuSelecting.breadcrumb,
            label: (Messages as any)[menuSelecting?.label as any],
        });
    }, [menuSelecting]);

    const renderContentNav = () => {
        const { breadcrumb, label } = siteConfig;
        if (breadcrumb && breadcrumb?.length > 0) {
            return <Breadcrumb breadcrumb={breadcrumb ?? []} />;
        }
        return <h4>{label}</h4>;
    };

    return (
        <nav className="app-layout__navbar" id="app-layout__navbar">
            <button
                type="button"
                className="btn app-layout__sidebar-button"
                onClick={() => {
                    (
                        document.getElementById("app-layout__sidebar") as any
                    ).classList.toggle("active");
                    (
                        document.getElementById("app-layout__content") as any
                    ).classList.toggle("active");
                }}
            >
                <i className="material-icons">notes</i>
            </button>
            <div className="container-fluid app-layout__nav-bar-content">
                {renderContentNav()}
                <div className="app-layout__nav-bar-right-content flex-center-y">
                    <DropdownLanguage />
                    <DropdownNotification />
                    <DropdownSiteSetting />
                </div>
            </div>
        </nav>
    );
};

const SideBar = () => {
    const { me } = useContext(AppStateContext);

    return (
        <nav
            id="app-layout__sidebar"
            className="active"
            onMouseEnter={() => {
                document
                    .getElementById("app-layout__content")
                    ?.classList.add("mouse-enter");
                document
                    .getElementById("app-layout__sidebar")
                    ?.classList.add("mouse-enter");
            }}
            onMouseLeave={() => {
                document
                    .getElementById("app-layout__content")
                    ?.classList.remove("mouse-enter");
                document
                    .getElementById("app-layout__sidebar")
                    ?.classList.remove("mouse-enter");
            }}
        >
            <div>
                <div className="app-layout__sidebar-header">
                    <img
                        src="/images/logo.png"
                        className="app-layout__sidebar-header-logo"
                    />
                    <img
                        src="/images/logo-icon.png"
                        className="app-layout__sidebar-header-logo-icon"
                    />
                </div>
                <div className="app-layout__sidebar-user-info">
                    <Image src={me?.avatar} className="user-info-avatar" />
                    <text className="text-large-bold text-light">
                        {`${me?.firstName} ${me?.lastName}`}
                    </text>
                    <text className="text-small text-light mt-1">
                        {me?.email ?? ""}
                    </text>
                </div>
                <div className="app-layout__menu-list">
                    {MAIN_MENU.map((menuItem, index) => {
                        return <MenuItem menu={menuItem} key={index} />;
                    })}
                </div>
            </div>
        </nav>
    );
};

function AppLayout() {
    useEffect(() => {
        const isSupported = () =>
            "Notification" in window &&
            "serviceWorker" in navigator &&
            "PushManager" in window;
        if (!isSupported()) return;
        requestNotificationPermission();
    }, []);

    const requestNotificationPermission = () => {
        Notification.requestPermission((permission) => {
            if (permission === "granted") {
                onLoadMessagingToken();
                onMessaging();
            }
        });
    };

    const onLoadMessagingToken = async () => {
        try {
            const messaging = FirebaseMessaging.getMessaging();
            const currentToken = await FirebaseMessaging.getToken(messaging, {
                vapidKey: FIREBASE_MESSAGING_KEY,
            });
            await AuthAPI.updateMessingToken(currentToken);
        } catch (err) {
            console.error("Token notification", err);
        }
    };

    const onMessaging = () => {
        const messaging = FirebaseMessaging.getMessaging();
        FirebaseMessaging.onMessage(messaging, (payload) => {
            const { content } = payload?.data ?? {};
            if (!isEmpty(content)) {
                Notifications.showInfo(content);
            }
        });
    };

    return (
        <AppLayoutContext>
            <div className="app-layout__wrapper">
                <SideBar />

                <div className="bg-gray active" id="app-layout__content">
                    <Navbar />
                    <Routes />
                    <UserAuthOtp />
                </div>
            </div>
        </AppLayoutContext>
    );
}

const mapStateToProps = (state: any) => ({
    locale: state.locale,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
