import { map, sortBy } from "lodash";
import { IUser } from "./user";

export interface IMedia {
    title?: string;
    id?: string;
    url: string;
    type?: string;
    createBy?: IUser;
    description?: string;
    size?: string;
    dimension?: string;
    modificationDate?: string;
}

export interface IDirectory {
    name?: string;
    path?: string;
    size?: number;
    type?: string;
    children?: IDirectory[];
    key?: string;
}

export const mapDirFromSer: any = (folderSer: any) => {
    const sortedList = sortBy(folderSer.children, ["name"]);
    return {
        ...folderSer,
        key: folderSer.path,
        title: folderSer.name,
        children: map(sortedList, mapDirFromSer),
    };
};

export const mapMediaToSer: any = (mediaDetail: any) => {
    const { title, description = "", directory } = mediaDetail;
    return {
        title,
        description,
        directory,
    };
};
