import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Notifications,
    StringUtils,
} from "d-react-components";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { CUSTOMER_CHECKS } from "../../../../constant/worksheet";
import { WorksheetCreateContext } from "../../../../context/worksheet";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/common/ProductNameView";
import CriteriaCheckTable from "../../common/CriteriaCheckTable";
import WSCPriorCheckingAddModal from "./WSCPriorCheckingAddModal";

const WorksheetCreatePriorChecking = () => {
    const { worksheetForm } = useContext(WorksheetCreateContext);
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    const { products, criteria = [], totalUseQuantity } = worksheetForm?.values;
    const productService = products?.[0].service ?? {};
    const {
        workProgress = [],
        priorChecking,
        deliveryProgress = [],
    } = productService ?? {};
    const { criterias = [], checkList = [] } = priorChecking;

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.quantity,
            dataIndex: "useQuantity",
        },
    ];

    const onAddPriorCheck = ({ criteriaList }: any) => {
        if (criteria?.length === totalUseQuantity) {
            Notifications.showError(Messages.criteriaLengthCantGreaterThan);
            return;
        }
        const priorChecking = {
            criteria: criteriaList,
            checkList: map(checkList, (item) => ({ ...item, value: false })),
            workProgress: map(workProgress, (item) => ({
                ...item,
                value: false,
            })),
            deliveryProgress: map(deliveryProgress, (item) => ({
                ...item,
                value: false,
            })),
            customerCheck: map(CUSTOMER_CHECKS, (item) => ({
                ...item,
                value: false,
            })),
            id: StringUtils.getUniqueID(),
        };
        worksheetForm.setFieldValue("criteria", [...criteria, priorChecking]);
    };

    return (
        <div className="mt-3">
            <div className="card-container p-4 flex-column">
                <label>{Messages.workingProtection}</label>
                <text>{Messages.protected}</text>
            </div>
            <div className="card-container p-4 mt-3">
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={products}
                    isPagination={false}
                />
            </div>
            <div className="card-container p-4 mt-3">
                <div className="flex-center justify-content-between">
                    <label>{`${Messages.priorChecking} `}</label>

                    <Button
                        onClick={() => setOpenAdd(true)}
                        disabled={criteria?.length === totalUseQuantity}
                    >
                        {Messages.addItem}
                    </Button>
                </div>
                <CriteriaCheckTable
                    criteriasCheck={criteria}
                    criteriaDataList={criterias}
                    checkDataList={checkList}
                    // onChange={(values) =>
                    //     worksheetForm.setFieldValue("criteria", values)
                    // }
                    checkDataKey="checkList"
                />
                {openAdd && (
                    <WSCPriorCheckingAddModal
                        open={openAdd}
                        onClose={() => setOpenAdd(false)}
                        defaultCriteriaList={criterias}
                        onSave={onAddPriorCheck}
                    />
                )}
            </div>
        </div>
    );
};

export default WorksheetCreatePriorChecking;
