import {
    ObjectUtils,
    Button,
    Modal,
    Checkbox,
    Progress,
    Notifications,
} from "d-react-components";
import { find, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { useDeleteCustomerAdd } from "../../../hoook/customer";
import {
    IAddress,
    IServiceAddress,
    mapAddressToSer,
} from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import CustomerCrudAddressModal from "./CustomerCrudAddressModal";

interface ISelectAddressItem {
    address: IServiceAddress;
    isChecked: boolean;
    customerId: string;
    onChangeCheck: () => void;
    onUpdateSuccess: () => void;
}

interface ICustomerSelectAddressModal {
    customerId: string;
    open: boolean;
    onClose: () => void;
    onSave: (value: any) => void;
}

const SelectAddressItem = ({
    address,
    isChecked,
    customerId,
    onChangeCheck,
    onUpdateSuccess,
}: ISelectAddressItem) => {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const { onClickDelete } = useDeleteCustomerAdd(
        customerId,
        address?.id,
        onUpdateSuccess
    );

    const onUpdateAddress = (body: any) => {
        Progress.show(
            {
                method: CustomerAPI.updateServiceAddress,
                params: [customerId, body?.id, mapAddressToSer(body)],
            },
            () => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                onUpdateSuccess();
            }
        );
    };

    return (
        <div className="d-flex justify-content-between border-bottom py-3">
            <div className="flex-column ">
                <div className="d-flex align-items-center">
                    <text className="text-bold">{address.fullName}</text>
                    <Button
                        iconName="edit"
                        variant="trans"
                        onClick={() => setOpenEdit(true)}
                    />
                    <Button
                        iconName="delete"
                        variant="trans"
                        onClick={onClickDelete}
                    />
                </div>
                <text className="mt-1">{address.phone}</text>
                <text className="mt-1">{address.fullAddress}</text>
            </div>
            <Checkbox
                variant="radio"
                checked={isChecked}
                onChange={onChangeCheck}
            />
            {openEdit && (
                <CustomerCrudAddressModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    customerId={customerId}
                    isEdit
                    addressDefault={address}
                    onCrudSuccess={onUpdateAddress}
                />
            )}
        </div>
    );
};

const CustomerSelectAddressModal = ({
    customerId,
    open,
    onClose,
    onSave,
}: ICustomerSelectAddressModal) => {
    const [addressList, setAddressList] = useState<IServiceAddress[]>([]);

    useEffect(() => {
        loadAddressList();
    }, [customerId]);

    const loadAddressList = () => {
        if (!customerId) return;
        CustomerAPI.serviceAddresses(customerId).then(setAddressList);
    };

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [addressSelect, setAddressSelect] = useState<IAddress[]>([]);

    const isCheckedAddress = (address: IAddress) =>
        !!find(addressSelect, (item: IAddress) => item.id === address.id);

    const onSelectAdd = (address: IAddress) => {
        setAddressSelect([...addressSelect, address]);
    };

    const onDeselectAdd = (addressId: string) => {
        const newAddresses = ObjectUtils.removeArrayById(
            addressSelect,
            addressId
        );
        setAddressSelect(newAddresses);
    };

    const renderAddressItem = (address: IServiceAddress) => {
        const isChecked = isCheckedAddress(address);
        return (
            <SelectAddressItem
                address={address}
                onChangeCheck={() =>
                    isChecked
                        ? onDeselectAdd(address?.id)
                        : onSelectAdd(address)
                }
                isChecked={isChecked}
                onUpdateSuccess={loadAddressList}
                customerId={customerId}
            />
        );
    };

    const renderHeaderSide = () => (
        <Button variant="trans" onClick={() => setOpenAdd(true)}>
            {Messages.addNew}
        </Button>
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.addServiceAddress}
            size="medium"
            onSave={() => {
                onSave(addressSelect);
                onClose();
            }}
            headerSide={renderHeaderSide}
        >
            {map(addressList, renderAddressItem)}
            {openAdd && (
                <CustomerCrudAddressModal
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    onCrudSuccess={loadAddressList}
                    customerId={customerId}
                />
            )}
        </Modal>
    );
};

export default CustomerSelectAddressModal;
