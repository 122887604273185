/* eslint-disable no-case-declarations */
import {
    InputText,
    Modal,
    Notifications,
    ObjectUtils,
    Select,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { every, map, replace } from "lodash";
import InputTextForm from "../../../../common/input/InputTextForm";
import { CRITERIA_TYPE } from "../../../../constant/criteria";
import { ICriteria } from "../../../../interfaces/criteria";
import Messages from "../../../../languages/Messages";

interface IProps {
    open: boolean;
    defaultCriteriaList: ICriteria[];
    maxQuantity?: number;
    onClose: () => any;
    // eslint-disable-next-line no-unused-vars
    onSave: (input: { criteriaList: any[]; quantity: number }) => any;
}

interface IPriorAddInput {
    criteria: any;
    // eslint-disable-next-line no-unused-vars
    onChangeValueId: (value: any, item: any) => void;
    // eslint-disable-next-line no-unused-vars
    onChangeValue: (value: any) => void;
}

const OTHER_SELECT = { id: "other", value: Messages.other };

const PriorAddInputSelect = ({
    onChangeValueId,
    onChangeValue,
    criteria,
}: any) => {
    const isOtherInput = criteria?.valueId === "other";

    const inputProps = {
        className: "mt-3",
        label: criteria.name,
    };
    return (
        <div>
            <Select
                {...inputProps}
                dataSource={[...(criteria?.values ?? []), OTHER_SELECT]}
                getLabel={(item) => item.value}
                onChange={onChangeValueId}
                value={criteria?.valueId}
            />
            {isOtherInput && (
                <InputText
                    {...inputProps}
                    label={`${criteria.name} (${Messages.other})`}
                    value={criteria?.value}
                    onChange={(event) => onChangeValue(event.target.value)}
                />
            )}
        </div>
    );
};

const PriorAddInput = ({
    criteria,
    onChangeValueId,
    onChangeValue,
}: IPriorAddInput) => {
    const inputProps = {
        className: "mt-3",
        label: criteria.name,
    };

    switch (criteria.type) {
        case CRITERIA_TYPE.dropdown:
            return (
                <PriorAddInputSelect
                    onChangeValueId={onChangeValueId}
                    onChangeValue={onChangeValue}
                    criteria={criteria}
                />
            );
        case CRITERIA_TYPE.textInput:
            return (
                <InputText
                    {...inputProps}
                    value={criteria.value}
                    onChange={(event) => onChangeValue(event.target.value)}
                />
            );
        default:
            return <div />;
    }
};

const WSCPriorCheckingAddModal = ({
    open,
    maxQuantity = 1,
    defaultCriteriaList,
    onSave,
    onClose,
}: IProps) => {
    const criteriaForm = useFormik<any>({
        initialValues: {
            criteriaList: defaultCriteriaList,
            quantity: 1,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,

        validate: (values) => {
            const errors: any = {};
            const hasValue = every(values?.criteriaList, (item: any) => {
                if (item.value) return true;
                if (item.valueId && item.valueId !== "other") return true;
                return false;
            });
            if (!hasValue) {
                errors.criteriaList = Messages.needAllRequiredInfo;
            }
            if (values?.quantity > maxQuantity) {
                errors.quantity = replace(
                    Messages.numberCriteriaCreateNewNotOver,
                    "%number",
                    `${maxQuantity}`
                );
            }

            return errors;
        },
        onSubmit: (values: any) => {
            const input = {
                criteriaList: values?.criteriaList,
                quantity: parseInt(values?.quantity, 10),
            };

            onSave(input);
            onClose();
        },
    });

    const formValues = criteriaForm?.values;
    const formErrors = criteriaForm?.errors;

    const onChangeVariant = (criteria: any) => {
        const value = ObjectUtils.updateArrayById(
            formValues?.criteriaList,
            criteria
        );
        criteriaForm.setFieldValue("criteriaList", value);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.addItem}
            onSave={() => criteriaForm?.handleSubmit()}
        >
            {map(formValues?.criteriaList, (criteria) => (
                <PriorAddInput
                    criteria={criteria}
                    onChangeValue={(value) =>
                        onChangeVariant({ ...criteria, value })
                    }
                    onChangeValueId={(valueId, item) =>
                        onChangeVariant({
                            ...criteria,
                            value: item.children ?? "",
                            valueId,
                        })
                    }
                />
            ))}
            <ViewTextError error={formErrors.criteriaList} />
            <InputTextForm
                form={criteriaForm}
                keyData="quantity"
                className="mt-3"
            />
        </Modal>
    );
};

export default WSCPriorCheckingAddModal;
