import { gql } from "@apollo/client";
import { map } from "lodash";
import { CATEGORY_STATUS } from "../../constant/category";
import { mapProductFromSer } from "../../interfaces/product";
import API from "../API";
import { F_PRODUCT, F_PRODUCTS, F_PRODUCT_CATS } from "./ProductFragment";

const detailMetaDataConvertor = {
    fromServer: (res: any) => ({
        categoryProducts: res?.data?.categoryProducts?.list ?? [],
        categoryServices: res?.data?.categoryServices?.list ?? [],
        brands: map(res?.data?.brands ?? [], (item) => ({
            id: item?.name,
            label: item?.name,
        })),
    }),
};
const detailConvertor = {
    fromServer: (res: any) => mapProductFromSer(res?.data?.product),
};

const ProductAPI = {
    list: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filter: any = {}
    ) =>
        API.query({
            query: gql`
                ${F_PRODUCTS}
                query products(
                    $q: String
                    $page: Int
                    $limit: Int
                    $visibility: Boolean
                    $customerVisibility: Boolean
                    $warehouseId: ID
                    $productType: [ProductType]
                ) {
                    data: products(
                        q: $q
                        page: $page
                        limit: $limit
                        visibility: $visibility
                        customerVisibility: $customerVisibility
                        warehouseId: $warehouseId
                        productType: $productType
                    ) {
                        ...F_PRODUCTS
                    }
                }
            `,
            variables: { q, page: pageIndex, limit: pageSize, ...filter },
            fetchPolicy: "no-cache",
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_PRODUCT}
                query product($id: ID!) {
                    product(id: $id) {
                        ...F_PRODUCT
                    }
                }
            `,
            variables: { id },
        }),

    detailMetaData: () =>
        API.withConverter(detailMetaDataConvertor).query({
            query: gql`
                query detailMetaData {
                    categoryProducts(
                        page: 1
                        limit: 100
                        filter: { status: ACTIVE }
                    ) {
                        list: categoryProduct {
                            id
                            label: name
                        }
                    }

                    categoryServices: categories(
                        page: 1
                        limit: 100
                        filter: { status: ACTIVE }
                    ) {
                        list: category {
                            id
                            label: name
                            seatConfiguration
                        }
                    }

                    brands {
                        name
                    }
                }
            `,
        }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createProduct?.id,
        }).mutate({
            mutation: gql`
                mutation create($input: ProductInput) {
                    createProduct(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),
    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_PRODUCT}
                mutation updateProduct($id: ID!, $input: ProductInput) {
                    product: updateProduct(id: $id, input: $input) {
                        ...F_PRODUCT
                    }
                }
            `,
            variables: { id, input },
        }),

    categoryList: (
        q: string,
        filter?: any,
        paging = { pageIndex: 1, pageSize: 10 }
    ) =>
        API.query({
            query: gql`
                ${F_PRODUCT_CATS}
                query categoryProducts(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: CategoryFilter
                ) {
                    data: categoryProducts(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_PRODUCT_CATS
                    }
                }
            `,
            variables: {
                q,
                page: paging?.pageIndex,
                limit: paging?.pageSize,
                filter,
            },
            fetchPolicy: "no-cache",
        }),

    activeCategoryList: () =>
        ProductAPI.categoryList(
            "",
            { status: CATEGORY_STATUS.ACTIVE },
            { pageIndex: 1, pageSize: 1000 }
        ),

    categoryCreate: (input: any) =>
        API.mutate({
            mutation: gql`
                mutation createCategory($input: CategoryProductInput) {
                    createCategoryProduct(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    categoryEdit: (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation updateCategoryProduct(
                    $id: ID!
                    $input: CategoryProductInput
                ) {
                    updateCategoryProduct(id: $id, input: $input) {
                        id
                    }
                }
            `,
            variables: { id, input },
        }),
};

export default ProductAPI;
