import { TreeDataUtils } from "d-react-components";
import { replace } from "lodash";
import { useMemo } from "react";
import { IDirectory } from "../interfaces/media";

export const useDirParentPath = (folder: IDirectory) =>
    useMemo(
        () => replace(folder?.path ?? "", `/${folder?.name}`, ""),
        [folder]
    );

export const useDirectory = (folderId: string, folders: IDirectory[]) => {
    const currentFolder = TreeDataUtils.searchNodeFromTreeList(
        folderId,
        folders as any,
        (node: any) => node.id
    );

    const parentPath = useDirParentPath(currentFolder);

    const parentFolder = TreeDataUtils.searchNodeFromTreeList(
        parentPath,
        folders as any,
        (node: any) => node.id
    );
    return { folder: currentFolder, parentFolder, parentPath };
};
