import { Modal } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { InventoryFilterSchema } from "../../../formschema/inventory";
import { mapFilterInventToServer } from "../../../interfaces/inventory";
import Messages from "../../../languages/Messages";
import InventoryFilterView from "../common/InventoryFilterView";

interface IInventoryFilterModal {
    open: boolean;
    defaultValue: any;
    onClose: () => void;
    onFilter: (values: any) => void;
}

function InventoryFilterModal({
    open,
    defaultValue,
    onClose,
    onFilter,
}: IInventoryFilterModal) {
    const filterForm = useFormik<any>({
        initialValues: {
            serviceCategory: [],
            category: [],
            status: null,
            ...(defaultValue ?? {}),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: InventoryFilterSchema,
        onSubmit: (values) => {
            const filter = mapFilterInventToServer(values);
            onFilter(filter);
            onClose();
        },
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm.handleSubmit()}
            sideText={Messages.clearAll}
            onSideClick={() => filterForm.setValues({})}
        >
            <InventoryFilterView form={filterForm} />
        </Modal>
    );
}

export default InventoryFilterModal;
