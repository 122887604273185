import { Select } from "d-react-components";
import React, { useContext } from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import { WAREHOUSE_STATUSES } from "../../../constant/warehouse";
import { WarehouseCreateContext } from "../../../context/warehouse";
import Messages from "../../../languages/Messages";

const WarehouseCrudInfo = () => {
    const { warehouseForm, warehouseLang } = useContext(WarehouseCreateContext);
    return (
        <div className="grid-container bg-white p-4">
            <div className="row mt-3">
                <InputTextForm
                    prefix={warehouseLang}
                    keyData="name"
                    form={warehouseForm}
                    required
                    className="col-6"
                />
                <Select
                    label={Messages.status}
                    value={warehouseForm.values?.status}
                    error={warehouseForm.errors?.status}
                    className="col-6 "
                    dataSource={WAREHOUSE_STATUSES}
                    getLabel={(item) => (Messages as any)[item?.label]}
                    onChange={(value) =>
                        warehouseForm.setFieldValue("status", value)
                    }
                    required
                />
                <InputTextForm
                    keyData="code"
                    form={warehouseForm}
                    required
                    className="col-6 mt-3"
                />
                <InputTextForm
                    keyData="phone"
                    form={warehouseForm}
                    className="col-6 mt-3"
                />
            </div>
        </div>
    );
};

export default WarehouseCrudInfo;
