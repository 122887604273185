import { map, parseInt } from "lodash";
import moment from "moment";
import { IProduct } from "./product";
import { IUser } from "./user";

interface NewsInfo {
    title: string;
    description: string;
    shortDescription: string;
}

export interface INewsCategory {
    id: string;
    featured: string;
    name: string;
    order: number;
    createBy: IUser;
    createdAt: string;
    updatedAt: string;
    status: string;
}

export interface INews {
    id: string;
    newsId: string;
    th: NewsInfo;
    en: NewsInfo;
    status: string;
    publishedDate: string;
    featuredUrl: string;
    isBookService: boolean;
    category: INewsCategory[];
    products: IProduct[];
    createBy: IUser[];
}

export interface INewsInput {
    th: NewsInfo;
    en: NewsInfo;
    status: string;
    publishedDate: string;
    featuredUrl: string;
    isBookService?: boolean;
    categoryIds: string[];
    productIds?: string[];
}

export const mapCategoryNewsToSer = (category: any) => {
    const { order, name, status } = category;

    return {
        name,
        status,
        order: parseInt(order),
    };
};

export const mapNewsToSer = (news: any): INewsInput => {
    const {
        products,
        th,
        en,
        status,
        publishedDate,
        featuredUrl,
        isBookService,
        categoryIds,
    } = news;
    return {
        th,
        en,
        status,
        featuredUrl,
        isBookService,
        categoryIds,
        publishedDate: moment(publishedDate).toISOString(),
        productIds: map(products, (item) => item.id),
    };
};

export const mapNewsFromSer = (news: any) => {
    return {
        ...news,
        publishedDate: moment(news?.publishedDate),
        categoryIds: map(news?.category, (item) => item.id),
    };
};
