import { CheckboxGroup, Select } from "d-react-components";
import { STOCK_STATUS_LIST } from "../../../constant/inventory";
import { PRODUCT_STOCK_TYPES } from "../../../constant/product";
import Messages from "../../../languages/Messages";
import ProductCategoryCheckboxGroup from "../../product/category/ProductCategoryCheckboxGroup";
import WarehouseSelect from "../../warehouse/common/WarehouseSelect";

interface IInventoryFilterView {
    form: any;
}

function InventoryFilterView({ form }: IInventoryFilterView) {
    const formValue = form?.values;
    const formError = form?.errors;
    console.log("form", form);
    return (
        <div>
            <WarehouseSelect
                value={formValue?.warehouseIds}
                onChange={(value) => {
                    form.setFieldValue("warehouseIds", value);
                }}
                multiple
                error={formError?.warehouseIds}
            />
            <CheckboxGroup
                numberOfColumns="2"
                dataSource={PRODUCT_STOCK_TYPES}
                label={Messages.productType}
                className="mt-3"
                value={formValue?.productType ?? []}
                onChange={(value) => form.setFieldValue("productType", value)}
                getLabel={(item) => (Messages as any)[item.label]}
            />
            {/* <div className="divider mt-3" /> */}
            {/* <CategoryCheckboxGroup
                className="mt-3"
                onChange={(value) => form.setFieldValue("serviceIds", value)}
                value={formValue?.serviceIds}
            /> */}
            <div className="divider mt-3" />
            <ProductCategoryCheckboxGroup
                value={formValue?.categoryIds}
                className="mt-3"
                onChange={(value) => form.setFieldValue("categoryIds", value)}
            />
            <div className="divider mt-3" />
            <Select
                label={Messages.stockStatus}
                className="mt-3"
                value={formValue?.stockStatus}
                dataSource={STOCK_STATUS_LIST}
                onChange={(valueId) => {
                    form.setFieldValue("stockStatus", valueId);
                }}
                getLabel={(item) => (Messages as any)[item.label]}
            />
        </div>
    );
}

export default InventoryFilterView;
