import { Button } from "d-react-components";
import React, { useState } from "react";
import { BookingBillingContext } from "../../../context/booking";
import { IBillingAddress } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressBillingInfoView from "../../address/AddressBillingInfoView";
import CustomerCrudBillingModal from "../../customer/common/CustomerCrudBillingModal";
import CustomerSelectBillingModal from "../../customer/common/CustomerSelectBillingModal";

interface IBookingBilling {
    customerId: string;
    billing: IBillingAddress;
    onChangeBilling?: (billing: any) => void;
    hiddenCrud?: boolean;
}

const BookingBilling = ({
    customerId,
    billing,
    onChangeBilling,
    hiddenCrud,
}: IBookingBilling) => {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    return (
        <BookingBillingContext.Provider value={{ customerId }}>
            <div className="card-container p-4 mt-3">
                <div className="flex-center justify-content-between">
                    <label>{Messages.billingAddress}</label>
                    <Button
                        variant="trans"
                        onClick={() => setOpenAdd(true)}
                        hidden={hiddenCrud}
                    >
                        {Messages.add}
                    </Button>
                </div>
                <AddressBillingInfoView billing={billing} />
                <div
                    className="cursor-pointer text-primary mt-3"
                    onClick={() => setOpenEdit(true)}
                    hidden={hiddenCrud}
                >
                    {Messages.edit}
                </div>
                {openEdit && (
                    <CustomerCrudBillingModal
                        open={openEdit}
                        onClose={() => setOpenEdit(false)}
                        billingDefault={billing}
                        onChange={onChangeBilling as any}
                        showSameAsServiceAdd
                    />
                )}
                {openAdd && (
                    <CustomerSelectBillingModal
                        open={openAdd}
                        onClose={() => setOpenAdd(false)}
                        customerId={customerId}
                        onSave={onChangeBilling}
                    />
                )}
            </div>
        </BookingBillingContext.Provider>
    );
};

export default BookingBilling;
