import { InputText, ObjectUtils } from "d-react-components";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import Messages from "../../languages/Messages";

interface IInputTextForm {
    form: any;
    keyData: string;
    label?: any;
    className?: any;
    prefix?: any;
    required?: boolean;
    [key: string]: any;
}

const InputTextForm = ({
    form,
    keyData,
    label,
    required,
    className,
    prefix,
    ...props
}: IInputTextForm) => {
    // eslint-disable-next-line no-unneeded-ternary
    const labelValue = label ? label : (Messages as any)[keyData];
    const isPrefix = useMemo(() => !isEmpty(prefix), []);

    const formDataValue = isPrefix
        ? ObjectUtils.getValueFromStringKey(form.values, prefix)
        : form.values;

    const formDataError = isPrefix
        ? ObjectUtils.getValueFromStringKey(form.errors, prefix)
        : form.errors;

    const getName = (key: string) => {
        return isPrefix ? `${prefix}.${key}` : key;
    };

    return (
        <InputText
            className={className}
            placeholder={labelValue}
            label={labelValue}
            name={getName(keyData)}
            error={formDataError?.[keyData]}
            value={formDataValue?.[keyData] ?? ""}
            onChange={form.handleChange}
            required={required}
            {...props}
        />
    );
};

export default InputTextForm;
