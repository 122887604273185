import { Button, Progress } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import OrderAPI from "../../../api/order/OrderAPI";
import { ORDER_STATUS } from "../../../constant/order";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import OrderCrudContactModal from "../common/OrderCrudContactModal";

const OrderDetailContact = () => {
    const { orderDetail, setOrderDetail } = useContext(OrderDetailContext);
    const { orderContact, id, status } = orderDetail;
    const { firstName, lastName, phone, email } = orderContact ?? {};
    const [openEdit, setOpenEdit] = useState(false);

    const isHideEdit = useMemo(
        () =>
            status !== ORDER_STATUS.PENDING_PAYMENT &&
            status !== ORDER_STATUS.PENDING_CONFIRMATION,
        [status]
    );

    const onUpdateOrderContact = (contact: any) => {
        Progress.show(
            { method: OrderAPI.updateContact, params: [id, contact] },
            setOrderDetail
        );
    };

    return (
        <div className="card-container p-4 mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.orderContact}</label>
                <Button
                    variant="trans"
                    onClick={() => setOpenEdit(true)}
                    hidden={isHideEdit}
                >
                    {Messages.edit}
                </Button>
            </div>
            <div className="flex-column">
                <text>{`${firstName} ${lastName}`}</text>
                <text>{email}</text>
                <text>{phone}</text>
            </div>
            {openEdit && (
                <OrderCrudContactModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    defaultValue={orderContact}
                    onChange={onUpdateOrderContact}
                />
            )}
        </div>
    );
};

export default OrderDetailContact;
