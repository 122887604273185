import { AwesomeTableComponent, Progress, TimeUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import AppLink from "../../../common/AppLink";
import { WorksheetStatus } from "../../../constant/worksheet";
import { WorksheetDetailContext } from "../../../context/worksheet";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductNameView from "../../product/common/ProductNameView";
import WSCBookingNote from "../common/WSCBookingNote";
import WSCBookingPIC from "../common/WSCBookingPIC";

const WorksheetDetailBooking = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const { booking, services = [], PIC = [], status } = worksheetDetail;
    const hiddenAssignPIC =
        status === WorksheetStatus.CANCELLED ||
        status === WorksheetStatus.DELIVERED ||
        status === WorksheetStatus.COMPLETED;

    const columns = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product: any) => <ProductNameView product={product} />,
        },
        {
            title: Messages.totalQty,
            dataIndex: "quantity",
        },
    ];

    const onChangeAssignPic = (userList = []) => {
        const userIds = map(userList, (item: any) => item.id);
        Progress.show(
            {
                method: WorksheetAPI.assignPIC,
                params: [worksheetDetail?.id, userIds],
            },
            setWorksheetDetail
        );
    };

    const onRemoveAssignPic = (user: any) => {
        Progress.show(
            {
                method: WorksheetAPI.removePIC,
                params: [worksheetDetail?.id, [user?.id]],
            },
            setWorksheetDetail
        );
    };

    return (
        <div className="flex-column mt-3">
            <div className="mt-3 card-container p-4 flex-column">
                <div className="label">{Messages.booking}</div>
                <text>
                    {`${Messages.no} `}
                    <AppLink
                        to={generatePath(Path.BOOKING_DETAIL, {
                            bookingId: booking.id,
                        })}
                    >
                        {booking?.bookingNo}
                    </AppLink>
                </text>
                <text>{`${Messages.createdAt} ${TimeUtils.toDateTime(
                    booking?.createdAt
                )}`}</text>
            </div>
            <div className="mt-3 card-container p-4">
                <label>{Messages.items}</label>
                <AwesomeTableComponent
                    columns={columns}
                    className="mt-3"
                    dataSource={services}
                    isPagination={false}
                    bordered={false}
                />
            </div>

            <WSCBookingNote note={booking?.notes} />
            <WSCBookingPIC
                picList={PIC}
                onAddUser={onChangeAssignPic}
                onRemoveUser={onRemoveAssignPic}
                hiddenAssignPIC={hiddenAssignPIC}
            />
        </div>
    );
};

export default WorksheetDetailBooking;
