import { gql } from "@apollo/client";
import { mapOrderFromServer } from "../../interfaces/order";
import { F_ACTIVITIES } from "../activity/ActivityFragment";
import API from "../API";
import { F_PAYMENT_METHOD } from "../paymentmethod/PaymentMethodFragment";
import { F_WAREHOUSES } from "../warehouse/WarehouseFragment";
import {
    F_ORDER,
    F_ORDERS,
    F_ORDERS_TO_EXPORT,
    F_ORDER_WORKSHEET,
} from "./OrderFragment";

const sourceOrderConvertor = {
    fromServer: (res: any) => res?.data?.sourceBookings ?? [],
};

const detailConvertor = {
    fromServer: (res: any) => mapOrderFromServer(res?.data?.order),
};

const metaDataConvertor = {
    fromServer: (res: any) => ({
        paymentMethods: res?.data?.paymentMethods ?? [],
        warehouses: res?.data?.warehouses?.warehouse ?? [],
    }),
};

export default {
    list: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filter: any = {}
    ) =>
        API.query({
            query: gql`
                ${F_ORDERS}
                query orders(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: OrderFilter
                ) {
                    data: orders(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_ORDERS
                    }
                }
            `,
            variables: { q, page: pageIndex, limit: pageSize, filter },
            fetchPolicy: "no-cache",
        }),

    ordersToExport: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filter: any = {}
    ) =>
        API.query({
            query: gql`
                ${F_ORDERS_TO_EXPORT}
                query orders(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: OrderFilter
                ) {
                    data: orders(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_ORDERS_TO_EXPORT
                    }
                }
            `,
            variables: { q, page: pageIndex, limit: pageSize, filter },
            fetchPolicy: "no-cache",
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_ORDER}
                query order($id: ID!) {
                    order(id: $id) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    detailByNo: (orderNo: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_ORDER}
                query orderByNo($orderNo: String) {
                    order: orderByNo(orderNo: $orderNo) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderNo },
            fetchPolicy: "no-cache",
        }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createOrder?.id,
        }).mutate({
            mutation: gql`
                mutation createOrder($input: CreateOrderInput) {
                    createOrder(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    updateContact: (orderId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateOrderContact(
                    $orderId: ID!
                    $input: OrderContactInput
                ) {
                    order: updateOrderContact(
                        orderId: $orderId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, input },
        }),

    updateServiceAddress: (orderId: string, addressId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateOrderShipping(
                    $orderId: ID!
                    $addressId: ID!
                    $input: ShippingInput
                ) {
                    order: updateOrderShipping(
                        orderId: $orderId
                        shippingId: $addressId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, addressId, input },
        }),

    addServiceAddress: (orderId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation createOrderShipping(
                    $orderId: ID!
                    $input: ShippingInput
                ) {
                    order: createOrderShipping(
                        orderId: $orderId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, input },
        }),

    deleteServiceAddress: (orderId: string, addressId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation destroyOrderShipping($orderId: ID!, $addressId: ID!) {
                    order: destroyOrderShipping(
                        orderId: $orderId
                        shippingId: $addressId
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, addressId },
        }),

    loadWorksheetOrder: (worksheetNo: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.loadWorksheetOrder,
        }).query({
            query: gql`
                ${F_ORDER_WORKSHEET}
                query loadWorksheetOrder($worksheetNo: String) {
                    loadWorksheetOrder(no: $worksheetNo) {
                        ...F_ORDER_WORKSHEET
                    }
                }
            `,
            variables: { worksheetNo },
            fetchPolicy: "no-cache",
        }),

    orderSource: () =>
        API.withConverter(sourceOrderConvertor).query({
            query: gql`
                query {
                    sourceBookings {
                        id
                        label: name
                    }
                }
            `,
        }),

    activityList: (orderId: string, { pageIndex = 1, pageSize = 10 }: any) =>
        API.query({
            query: gql`
                ${F_ACTIVITIES}
                query orderActivities($orderId: ID!, $page: Int, $limit: Int) {
                    data: orderActivities(
                        orderId: $orderId
                        page: $page
                        limit: $limit
                    ) {
                        ...F_ACTIVITIES
                    }
                }
            `,
            variables: { orderId, page: pageIndex, limit: pageSize },
            fetchPolicy: "no-cache",
        }),

    activityAdd: (orderId: string, message: string, attachments: string[]) =>
        API.mutate({
            mutation: gql`
                mutation commentOrder(
                    $orderId: ID!
                    $message: String
                    $attachments: [String]
                ) {
                    commentOrder(
                        orderId: $orderId
                        message: $message
                        attachments: $attachments
                    )
                }
            `,
            variables: { orderId, message, attachments },
        }),

    changeExpiryDate: (id: string, date: string, remark: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation changeOrderExpiry(
                    $id: ID!
                    $date: Date!
                    $remark: String
                ) {
                    order: changeOrderExpiry(
                        id: $id
                        expiryDate: $date
                        remark: $remark
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { id, date, remark },
        }),

    cancel: (id: string, remark: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation cancelOrder($id: ID!, $remark: String) {
                    order: cancelOrder(id: $id, remark: $remark) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { id, remark },
        }),

    sendMailOrderCreated: (orderId: string, email: string) =>
        API.mutate({
            mutation: gql`
                mutation sendMailOrderCreated($orderId: ID!, $email: String!) {
                    sendMailOrderCreated(orderId: $orderId, email: $email) {
                        status
                    }
                }
            `,
            variables: { orderId, email },
        }),

    sendMailInvoice: (orderId: string, email: string) =>
        API.mutate({
            mutation: gql`
                mutation sendMailInvoice($orderId: ID!, $email: String!) {
                    sendMailInvoice(orderId: $orderId, email: $email) {
                        status
                    }
                }
            `,
            variables: { orderId, email },
        }),

    updateNote: (orderId: string, note: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateOrderNote($orderId: ID!, $note: String) {
                    order: updateOrderNote(orderId: $orderId, note: $note) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, note },
        }),

    updateBilling: (orderId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateOrderBilling(
                    $orderId: ID!
                    $input: BillingInput
                ) {
                    order: updateOrderBilling(
                        orderId: $orderId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, input },
        }),

    splitPayment: (orderId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation createSplitPayment(
                    $orderId: ID!
                    $input: [SplitPaymentInput]
                ) {
                    order: createSplitPayment(
                        orderId: $orderId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, input },
        }),

    deliveryTypes: () =>
        API.withConverter({
            fromServer: (res: any) => res?.data?.deliveryTypes ?? [],
        }).query({
            query: gql`
                query deliveryTypes {
                    deliveryTypes {
                        id
                        label
                    }
                }
            `,
        }),

    createDelivery: (input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation createDelivery($input: DeliveryInput) {
                    order: createDelivery(input: $input) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { input },
        }),

    updateDelivery: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateDelivery($id: ID!, $input: DeliveryInput) {
                    order: updateDelivery(id: $id, input: $input) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { id, input },
        }),

    updateDeliveryStatus: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateDeliveryStatus(
                    $id: ID!
                    $input: DeliveryStatusInput
                ) {
                    order: updateDeliveryStatus(id: $id, input: $input) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { id, input },
        }),

    createProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation createProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: ProofPaymentInput
                ) {
                    order: createProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    confirmProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation confirmProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: ConfirmProofPaymentInput
                ) {
                    order: confirmProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    rejectProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation rejectPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: RejectPaymentInput
                ) {
                    order: rejectPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    resetPayment: (input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation resetPayment(
                    $orderId: ID!
                    $splitPaymentId: ID!
                    $remark: String
                ) {
                    order: resetPayment(
                        orderId: $orderId
                        splitPaymentId: $splitPaymentId
                        remark: $remark
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: input,
        }),

    mapCustomProduct: (
        orderId: string,
        customItemId: string,
        productId: string
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation mapOrderCustomItem(
                    $orderId: ID!
                    $customItemId: String
                    $productId: String
                ) {
                    order: mapOrderCustomItem(
                        orderId: $orderId
                        customItemId: $customItemId
                        productId: $productId
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, customItemId, productId },
        }),

    requestRefund: (orderId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation requestOrderRefund(
                    $orderId: ID!
                    $input: OrderRefundInput
                ) {
                    order: requestOrderRefund(
                        orderId: $orderId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, input },
        }),

    addRefundAttachment: (
        orderId: string,
        refundId: string,
        attachments: string[]
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation addOrderRefundAttachment(
                    $orderId: ID!
                    $refundId: ID!
                    $attachments: [String]
                ) {
                    order: addOrderRefundAttachment(
                        orderId: $orderId
                        refundId: $refundId
                        attachments: $attachments
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, refundId, attachments },
        }),

    addRefundBank: (orderId: string, refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation addOrderRefundBank(
                    $orderId: ID!
                    $refundId: ID!
                    $input: OrderRefundBankInput
                ) {
                    order: addOrderRefundBank(
                        orderId: $orderId
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, refundId, input },
        }),

    confirmRefund: (orderId: string, refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation confirmOrderRefund(
                    $orderId: ID!
                    $refundId: ID!
                    $input: UpdateRefundStatusInput!
                ) {
                    order: confirmOrderRefund(
                        orderId: $orderId
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, refundId, input },
        }),

    rejectRefund: (orderId: string, refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation rejectOrderRefund(
                    $orderId: ID!
                    $refundId: ID!
                    $input: UpdateRefundStatusInput!
                ) {
                    order: rejectOrderRefund(
                        orderId: $orderId
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, refundId, input },
        }),

    completeRefund: (orderId: string, refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation completeOrderRefund(
                    $orderId: ID!
                    $refundId: ID!
                    $input: UpdateRefundStatusInput!
                ) {
                    order: completeOrderRefund(
                        orderId: $orderId
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, refundId, input },
        }),

    resetRefund: (orderId: string, refundId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation resetOrderRefund($orderId: ID!, $refundId: ID!) {
                    order: resetOrderRefund(
                        orderId: $orderId
                        refundId: $refundId
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, refundId },
        }),

    detailMetaData: () =>
        API.withConverter(metaDataConvertor).query({
            query: gql`
                ${F_PAYMENT_METHOD}
                ${F_WAREHOUSES}
                query detailMetaData($paymentStatus: Boolean) {
                    paymentMethods(status: $paymentStatus) {
                        ...F_PAYMENT_METHOD
                    }
                    warehouses(q: "", page: 1, limit: 1000) {
                        ...F_WAREHOUSES
                    }
                }
            `,
        }),
};
