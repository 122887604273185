export const REFUND_STATUS = {
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED",
    REFUNDED: "REFUNDED",
};

export const REFUND_STATUSES = [
    {
        id: REFUND_STATUS.PENDING,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: REFUND_STATUS.CONFIRMED,
        label: "confirmed",
        color: "#33B950",
    },
    {
        id: REFUND_STATUS.REJECTED,
        label: "rejected",
        color: "#FF1100",
    },
    {
        id: REFUND_STATUS.REFUNDED,
        label: "refunded",
        color: "#FAC256",
    },
];

export const REFUND_TYPE = {
    ORDER: "ORDER",
    WORKSHEET: "WORKSHEET",
};

export const REFUND_FORMAT = {
    CASH: "CASH",
    VOUCHER: "VOUCHER",
};

export const REFUND_TYPES = [
    { id: REFUND_TYPE.ORDER, label: "aServiceNotUsed" },
    { id: REFUND_TYPE.WORKSHEET, label: "aServiceUsed" },
];

export const REFUND_FORMATS = [
    { id: REFUND_FORMAT.CASH, label: "cash" },
    { id: REFUND_FORMAT.VOUCHER, label: "voucher" },
];
