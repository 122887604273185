import { Button, Drawer as DDrawer, IDrawerProps } from "d-react-components";
import Messages from "../../languages/Messages";

interface DrawerProps extends IDrawerProps {
    saveText?: string;
    cancelText?: string;
    onSave?: () => void;
    disableSave?: boolean;
    hideFooter?: boolean;
    showCancel?: boolean;
}

export const Drawer = (props: DrawerProps) => {
    const {
        saveText,
        cancelText,
        onSave,
        onClose,
        disableSave = false,
        hideFooter = false,
        showCancel = true,
    } = props;

    return (
        <DDrawer {...props} onClose={onClose} destroyOnClose>
            <div className="pb-12">{props.children}</div>
            {!hideFooter && (
                <div className="position-absolute z-50 bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
                    {onClose && showCancel && (
                        <Button
                            onClick={(event) => {
                                onClose && onClose(event);
                            }}
                            variant="outline"
                        >
                            {cancelText || Messages.cancel}
                        </Button>
                    )}
                    <div className="flex flex-1 justify-content-end">
                        <Button
                            onClick={() => {
                                onSave && onSave();
                            }}
                            disabled={disableSave}
                        >
                            {saveText || Messages.save}
                        </Button>
                    </div>
                </div>
            )}
        </DDrawer>
    );
};

export default Drawer;
