import classNames from "classnames";
import { Button, Progress } from "d-react-components";
import React from "react";
import { useDropzone } from "react-dropzone";
import MediaAPI from "../../api/media/MediaAPI";
import Messages from "../../languages/Messages";

interface IUploadField {
    onChange: (value: string) => void;
    disabled?: boolean;
    className?: string;
    children?: any;
}

const UploadField = ({
    onChange,
    disabled = false,
    children = <Button>{Messages.upload}</Button>,
    className,
}: IUploadField) => {
    const onDrop = ([fileUpload]: any) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(fileUpload);
        reader.onloadend = () => {
            Progress.show(
                {
                    method: MediaAPI.uploadMedia,
                    params: { file: fileUpload },
                },
                (res: any) => {
                    onChange(res?.data?.upload);
                }
            );
        };
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const containerClass = classNames(className);

    return (
        <div {...getRootProps()} className={containerClass}>
            <input {...getInputProps()} multiple={false} disabled={disabled} />
            {children}
        </div>
    );
};

export default UploadField;
