import { Header, Notifications, Progress, TabBar } from "d-react-components";
import { useFormik } from "formik";
import { find, isEmpty } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import CategoryAPI from "../../../api/category/CategoryAPI";
import VerticalTabView from "../../../common/view/VerticalTabView";
import { CATEGORY_STATUS } from "../../../constant/category";
import { AppStateContext } from "../../../context/app";
import { CategoryDetailContext } from "../../../context/category";
import { CategorySchema } from "../../../formschema/category";
import { ICategory, mapCateToServer } from "../../../interfaces/category";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CategoryDetailDeliveryProgress from "./CategoryDetailDeliveryProgress";
import CategoryDetailGeneral from "./CategoryDetailGeneral";
import CategoryDetailPriorChecking from "./CategoryDetailPriorChecking";
import CategoryDetailWorkProgress from "./CategoryDetailWorkProgress";

const CATEGORY_DETAIL_TABS = [
    {
        id: "general",
        label: Messages.general,
        component: <CategoryDetailGeneral />,
    },

    {
        id: "priorChecking",
        label: Messages.priorChecking,
        component: <CategoryDetailPriorChecking />,
    },
    {
        id: "workProgress",
        label: Messages.workProgress,
        component: <CategoryDetailWorkProgress />,
    },
    // {
    //     id: "deliveryProgress",
    //     label: Messages.deliveryProgress,
    //     component: <CategoryDetailDeliveryProgress />,
    // },
];

const CategoryDetail = () => {
    const { catId, duplicateId } = useParams<any>();
    const history = useHistory();
    const isDuplicate = duplicateId && duplicateId?.length === 24;
    const isEdit = useMemo(() => !isEmpty(catId), [catId]);
    const [selectedTab, setSelectedTab] = useState<any>(
        CATEGORY_DETAIL_TABS[0]
    );
    const [metaData, setMetaData] = useState<any>();
    const { setSiteConfig } = useContext(AppStateContext);

    const categoryForm = useFormik<ICategory>({
        initialValues: {
            name: "",
            priority: "",
            status: CATEGORY_STATUS.ACTIVE,
            workProgress: [],
            deliveryProgress: [],
            priorChecklist: [],
            priorCriteria: [],
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CategorySchema,
        onSubmit: (values) => {
            const input = mapCateToServer(values);
            if (isEdit) {
                onUpdateCat(input);
            } else {
                onCreateCat(input);
            }
        },
    });

    useEffect(() => {
        loadCategoryDetail();
        loadCategoryMetaData();
    }, [catId, isDuplicate]);

    const loadCategoryMetaData = () => {
        CategoryAPI.detailMetaData().then(setMetaData);
    };

    const loadCategoryDetail = () => {
        if (!isEdit && !isDuplicate) return;
        Progress.show(
            {
                method: CategoryAPI.detail,
                params: isDuplicate ? duplicateId : catId,
            },
            (catDetail: any) => {
                categoryForm.setValues(catDetail);
                setSiteConfig({
                    breadcrumb: [
                        {
                            title: Messages.allCategories,
                            url: Path.CATEGORY_TABLE,
                        },
                        {
                            title: catDetail?.name,
                        },
                    ],
                });
            }
        );
    };

    const onCreateCat = (values: any) => {
        Progress.show(
            {
                method: CategoryAPI.create,
                params: values,
            },
            (res: any) => {
                Notifications.showSuccess(Messages.createCategorySuccessfully);
                history.replace(
                    generatePath(Path.CATEGORY_DETAIL, {
                        catId: res?.data?.createCategory.id,
                    })
                );
            }
        );
    };
    const onUpdateCat = (values: any) => {
        Progress.show(
            {
                method: CategoryAPI.update,
                params: [catId, values],
            },
            (cateDetail) => {
                categoryForm.setValues(cateDetail as any);
                Notifications.showSuccess(Messages.updateCategorySuccessfully);
            }
        );
    };

    const contextValue = useMemo(
        () => ({ categoryForm, ...metaData }),
        [categoryForm, metaData]
    );
    return (
        <CategoryDetailContext.Provider value={contextValue}>
            <Header
                title={Messages.category}
                onSave={() => categoryForm.handleSubmit()}
                onPrint={
                    isEdit
                        ? () => {
                              history.replace(
                                  generatePath(Path.CATEGORY_CREATE, {
                                      duplicateId: catId,
                                  })
                              );
                          }
                        : undefined
                }
                printText={Messages.duplicate}
                // printButtonProps={{ iconName: "content_copy" } as any}
            />
            <div className="row p-4">
                <div className="col">
                    <VerticalTabView
                        dataSource={CATEGORY_DETAIL_TABS}
                        value={selectedTab}
                        onChange={setSelectedTab}
                    />
                </div>
                <form className="col-sm-9  ml-2">
                    {
                        find(
                            CATEGORY_DETAIL_TABS,
                            (item) => selectedTab.id === item.id
                        )?.component
                    }
                </form>
            </div>
        </CategoryDetailContext.Provider>
    );
};

export default CategoryDetail;
