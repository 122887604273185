/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import ClassNames from "classnames";
import {
    Button,
    Checkbox,
    Drawer,
    IDrawerProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, groupBy, isEmpty, map, reduce, some } from "lodash";
import React, {
    Dispatch,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import AuthCode from "react-auth-code-input";
import AuthAPI from "../../../../api/auth/AuthAPI";
import VoucherAPI from "../../../../api/voucher/VoucherAPI";
import { VOUCHER_STATUS } from "../../../../constant/voucher";
import { VERIFY_VOUCHER_METHOD } from "../../../../constant/worksheet";
import { AuthOtpSchema } from "../../../../formschema/auth";
import { useAppliedVouchersProductList } from "../../../../hoook/voucher";
import { IProduct, IProductOrderCreate } from "../../../../interfaces/product";
import {
    IVoucherOrderCreate,
    IVoucherProduct,
    isPremiumService,
} from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/common/ProductNameView";
import VoucherProductItem from "./VoucherProductItem";

export interface IVoucherSelectState
    extends Partial<IVoucherSelectDrawerProps> {
    activeScreen?: VoucherSelectScreenType | null;
    allVoucherList?: Array<IVoucherProduct>;
    selectingVoucherList?: Array<IVoucherProduct>;
    productToApplyVoucher?: IProductApplyVoucher;
    appliedVoucherProducts?: {
        [proId: string]: { quantity?: number; usedQty?: number };
    };
    setActiveScreen: Dispatch<VoucherSelectScreenType>;
    setAllVoucherList: Dispatch<Array<IVoucherProduct>>;
    setProductToApplyVoucher: Dispatch<IProductApplyVoucher>;
    setSelectingVoucherList: Dispatch<Array<IVoucherProduct>>;
}

const voucherSelectState: IVoucherSelectState = {
    customerId: null as any,
    activeScreen: null,
    selectingVoucherList: [],
    setActiveScreen: () => {},
    setAllVoucherList: () => {},
    setProductToApplyVoucher: () => {},
    setSelectingVoucherList: () => {},
};

export const VoucherSelectContext = React.createContext(voucherSelectState);

export interface IVoucherSelectDrawerProps extends IDrawerProps {
    onChange?: (value: IVoucherSelectDrawerProps["value"]) => any;
    customerId: string;
    value?: Array<IVoucherOrderCreate>;
    products?: IProductOrderCreate[];
    productsToApplyDefault?: IProductOrderCreate[];
    initScreen?: VoucherSelectScreenType;
}

export interface IProductApplyVoucher extends IProduct {
    availableVouchers?: IVoucherProduct[];
    availableVouchersQty?: number;
    quantity?: number;
}

export enum VoucherSelectScreenType {
    SELECT_VOUCHER = "SELECT_VOUCHER",
    SELECT_SERVICE = "SELECT_SERVICE",
    APPLY_CODE = "APPLY_CODE",
}

const VoucherSelectAuthorization = () => {
    const {
        setActiveScreen,
        onClose,
        onChange,
        customerId,
        selectingVoucherList,
        productToApplyVoucher,
    } = useContext(VoucherSelectContext);
    const verifyForm = useFormik<any>({
        initialValues: {
            code: undefined,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AuthOtpSchema,
        onSubmit: (values: any) => {
            const input = {
                customerId,
                code: values?.code,
                verifyType: VERIFY_VOUCHER_METHOD.CUSTOMER_APP,
            };
            Progress.show(
                {
                    method: AuthAPI.verifyOtpAuthGrantVoucherOwnership,
                    params: [input],
                },
                onProcessAuthenticated,
                () => {
                    verifyForm.setFieldValue("code", "");
                }
            );
        },
    });

    const onProcessAuthenticated = (res: any) => {
        const isValid = res?.data?.data;
        if (isValid) {
            const applyingVouchers: IVoucherOrderCreate[] = [];
            selectingVoucherList?.forEach((voucherPro) => {
                const {
                    quantity,
                    codesLeft = [],
                    product,
                    voucherCodes,
                } = voucherPro || {};

                if (quantity && quantity > 0) {
                    Array.from(Array(quantity).keys()).forEach((n, i) => {
                        applyingVouchers.push({
                            code: null,
                            appliedFor: productToApplyVoucher?.id,
                            product,
                            voucherCodes,
                        } as any);
                    });
                }
            });
            onChange && onChange(applyingVouchers);
            onClose && onClose(res);
        } else {
            Notifications.showError(Messages.codeIsInvalid);
        }
    };

    return (
        <div className="p-3">
            <div className="flex-center-y w-100 border-bottom">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.authorizationCode}
                </div>
            </div>
            <div className="my-3">
                {Messages.applyAuthorizationCodeForVoucherDescription}
            </div>
            <AuthCode
                onChange={(code) => verifyForm.setFieldValue("code", code)}
                containerClassName="auth-code__container mt-3 d-flex justify-content-center"
                inputClassName="auth-code__input"
                allowedCharacters="numeric"
                autoFocus
                placeholder=""
            />
            <div className="flex-center-y position-absolute w-100 p-3 border-top start-0 end-0 bottom-0 justify-content-between">
                <Button
                    content={Messages.back}
                    className=""
                    variant="outline"
                    onClick={() => {
                        //@ts-ignore
                        return setActiveScreen(
                            VoucherSelectScreenType.SELECT_VOUCHER
                        );
                    }}
                />
                <Button
                    content={Messages.apply}
                    className=""
                    onClick={() => {
                        verifyForm.handleSubmit();
                    }}
                />
            </div>
        </div>
    );
};

const VoucherSelectList = () => {
    const {
        selectingVoucherList,
        setActiveScreen,
        setSelectingVoucherList,
        value,
        productToApplyVoucher,
    } = useContext(VoucherSelectContext) || {};
    useEffect(() => {
        setSelectingVoucherList(productToApplyVoucher?.availableVouchers ?? []);
    }, [productToApplyVoucher?.id]);

    const onChangeItem = (itemToUpdate: IVoucherProduct) => {
        const updateList = map(selectingVoucherList, (v) =>
            v?.product?.id === itemToUpdate?.product?.id ? itemToUpdate : v
        );
        setSelectingVoucherList(updateList);
    };

    const renderItem = ({ item, index }: any) => {
        const otherList = filter(
            selectingVoucherList,
            (i) => i?.product?.id !== item?.product?.id
        );
        const otherQty = reduce(
            otherList,
            (res = 0, i) => (res += i?.quantity ?? 0),
            0
        );

        const productToApply = find(
            productToApplyVoucher,
            (product) => product.id === item.id
        );

        const maxQty = Math.min(
            item?.quantityLeft ?? 0,
            (productToApply?.quantity || 0) - otherQty
        );

        return (
            <VoucherProductItem
                voucherProduct={item as any}
                showQtyInput
                inputQuantityProps={{ max: maxQty }}
                onChange={(voucher) => {
                    onChangeItem(voucher as any);
                }}
                getQty={(item) => item?.quantityLeft ?? 0}
            />
        );
    };

    const renderListVoucher = () => {
        return (
            <div>
                <div className="flex-center-y w-100 border-bottom py-3">
                    <div className="h4 text-primary w-100 text-center">
                        {Messages.selectVoucher}
                    </div>
                </div>
                <ProductNameView
                    className="flex-row"
                    classNameContent="bg-primary w-100 text-white mx-0 p-3"
                    styleImage={{ height: "auto", width: "100px" }}
                    product={productToApplyVoucher}
                    subContent={
                        <React.Fragment>
                            <div className="flex-center-y">
                                <small>{Messages.serviceQty}: </small>
                                <div className="ml-2">
                                    {productToApplyVoucher?.quantity}
                                </div>
                            </div>
                            <div className="flex-center-y">
                                <small>{Messages.availableVoucherQty}:</small>
                                <div className="ml-2">
                                    {
                                        productToApplyVoucher?.availableVouchersQty
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    }
                />
                <div>
                    {map(selectingVoucherList, (item, index) =>
                        renderItem({ item, index })
                    )}
                </div>
            </div>
        );
    };

    return <React.Fragment>{renderListVoucher()}</React.Fragment>;
};

const VoucherSelectService: React.FC<any> = () => {
    const {
        products,
        allVoucherList,
        productToApplyVoucher,
        appliedVoucherProducts,
        setProductToApplyVoucher,
        setActiveScreen,
    } = useContext(VoucherSelectContext);
    const groupedProds = groupBy(products, (item) => item?.id);
    const listToSelect = map(groupedProds, (items, key) => {
        const pro = items?.[0];
        const prevQty = appliedVoucherProducts?.[pro?.id];
        const leftToApplyQty =
            (prevQty?.quantity || 0) - (prevQty?.usedQty || 0);
        const availableVouchers: IVoucherProduct[] = [];

        const filteredVoucherList = filter(
            allVoucherList,
            (i) => (i?.quantityLeft ?? 0) > 0
        );
        filteredVoucherList?.forEach((item) => {
            if (isPremiumService(item)) {
                if (
                    map(item?.product?.premiumService, (i) => i?.id).includes(
                        key
                    )
                ) {
                    availableVouchers.push(item);
                }
            } else if (item?.product?.id === key) {
                availableVouchers.push(item);
            }
        });
        const availableVouchersQty = reduce(
            availableVouchers,
            (res = 0, i) => (res += i?.quantityLeft ?? 0),
            0
        );

        return {
            ...pro,
            availableVouchers,
            availableVouchersQty,
            quantity: leftToApplyQty,
            totalQty: prevQty?.quantity ?? 0,
        };
    });

    return (
        <React.Fragment>
            <div className="flex-center-y w-100 border-bottom py-3">
                <div className="h4 text-primary w-100 text-center">
                    {Messages.selectService}
                </div>
            </div>
            <div className="px-3 mt-3">
                {Messages.voucherSelectServiceDescription}
            </div>
            <div>
                {map(listToSelect, (item, index) => (
                    <div
                        className={ClassNames(
                            "flex-center-y w-100 justify-content-between p-3",
                            {
                                "border-top": index !== 0,
                            }
                        )}
                    >
                        <ProductNameView
                            product={item}
                            subContent={
                                <React.Fragment>
                                    <div className="flex-center-y">
                                        <small>{Messages.serviceQty}: </small>
                                        <div className="ml-2">
                                            {item?.quantity}
                                        </div>
                                    </div>
                                    <div className="flex-center-y">
                                        <small>
                                            {Messages.availableVoucherQty}:
                                        </small>
                                        <div className="ml-2">
                                            {item?.availableVouchersQty}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        />
                        <Checkbox
                            onChange={() => setProductToApplyVoucher(item)}
                            checked={productToApplyVoucher?.id === item?.id}
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

const VoucherSelectDrawer: React.FC<IVoucherSelectDrawerProps> = ({
    open,
    onClose,
    customerId,
    products,
    productsToApplyDefault,
    value,
    onChange,
    initScreen,
}) => {
    const [activeScreen, setActiveScreen] = useState<VoucherSelectScreenType>(
        initScreen || VoucherSelectScreenType.SELECT_VOUCHER
    );
    const [allVoucherList, setAllVoucherList] = useState<
        Array<IVoucherProduct>
    >([]);
    const productIds: string[] = map(products, (pro) => pro?.id);

    const [productToApplyVoucher, setProductToApplyVoucher] =
        useState<IProductApplyVoucher>(productsToApplyDefault as any);

    const [selectingVoucherList, setSelectingVoucherList] =
        useState<Array<IVoucherProduct>>();
    const appliedVoucherProducts = useAppliedVouchersProductList(
        {
            products: products || [],
            vouchers: value || [],
        },
        [productIds, value]
    );
    const enableSaveVoucherButton = useMemo(() => {
        if (
            !isEmpty(selectingVoucherList) &&
            some(selectingVoucherList, (item) => {
                return item?.quantity && item?.quantity > 0;
            })
        ) {
            return true;
        }
        return false;
    }, [selectingVoucherList, value]);

    useEffect(() => {
        if (customerId) {
            loadVoucherList();
        }
    }, [customerId]);

    const loadVoucherList = () => {
        return Progress.show(
            {
                method: VoucherAPI.listProductVoucher,
                params: [
                    {},
                    {
                        customerIds: [customerId],
                        status: [
                            VOUCHER_STATUS.AVAILABLE,
                            VOUCHER_STATUS.EXPIRING,
                        ],
                    },
                ],
            },
            (res: any) => {
                const list: Array<IVoucherProduct> =
                    res?.data?.data?.products ?? [];
                const transList = map(list, (itemList) => {
                    const {
                        voucherCodes,
                        voucherQuantity = 0,
                        product,
                    } = itemList || {};
                    const usedQty =
                        filter(value, (i) => i?.product?.id === product?.id)
                            ?.length ?? 0;
                    return {
                        ...itemList,
                        quantityLeft: voucherQuantity - usedQty,
                    };
                });
                setAllVoucherList(transList);
            }
        );
    };

    const renderFooterDrawer = () => {
        if (activeScreen === VoucherSelectScreenType.APPLY_CODE) {
            return null;
        }
        return (
            <div className="w-100 d-flex p-3">
                <div className="flex-fill">
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VOUCHER && (
                        <Button
                            content={Messages.back}
                            className=""
                            variant="outline"
                            onClick={() => {
                                //@ts-ignore
                                return setActiveScreen(
                                    VoucherSelectScreenType.SELECT_SERVICE
                                );
                            }}
                        />
                    )}
                </div>
                {activeScreen === VoucherSelectScreenType.SELECT_SERVICE && (
                    <Button
                        content={Messages.continue}
                        className=""
                        onClick={() => {
                            if (isEmpty(productToApplyVoucher)) {
                                return Notifications.showError(
                                    Messages.youHaveToChooseProductsFirst
                                );
                            }
                            setActiveScreen(
                                VoucherSelectScreenType.SELECT_VOUCHER
                            );
                        }}
                    />
                )}
                {activeScreen === VoucherSelectScreenType.SELECT_VOUCHER && (
                    <Button
                        content={Messages.select}
                        className=""
                        onClick={() => {
                            setActiveScreen(VoucherSelectScreenType.APPLY_CODE);
                        }}
                        disabled={!enableSaveVoucherButton}
                    />
                )}
            </div>
        );
    };

    return (
        <VoucherSelectContext.Provider
            value={{
                selectingVoucherList,
                appliedVoucherProducts,
                allVoucherList,
                activeScreen,
                onChange,
                onClose,
                setActiveScreen,
                setAllVoucherList,
                setProductToApplyVoucher,
                setSelectingVoucherList,
                productToApplyVoucher,
                customerId,
                products,
            }}
        >
            <Drawer
                size="x-large"
                open={open}
                onClose={onClose}
                footer={renderFooterDrawer()}
            >
                <div className="p-3">
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_SERVICE && (
                        <VoucherSelectService />
                    )}
                    {activeScreen ===
                        VoucherSelectScreenType.SELECT_VOUCHER && (
                        <VoucherSelectList />
                    )}
                    {activeScreen === VoucherSelectScreenType.APPLY_CODE && (
                        <VoucherSelectAuthorization />
                    )}
                </div>
            </Drawer>
        </VoucherSelectContext.Provider>
    );
};

export default VoucherSelectDrawer;
