import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import UserAPI from "../../../api/user/UserAPI";
import { IFieldSelect } from "../../../interfaces/select";
import { IUser } from "../../../interfaces/user";

export interface IUserSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const UserSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    filterSource = {},
    ...selectProps
}: IUserSelect<IUser>) => {
    const [userList, setUserList] = useState<IUser[]>(
        isArray(value) ? value : [value]
    );

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setUserList([]);
        } else {
            UserAPI.usersSearch(
                textSearch,
                {
                    pageIndex: 1,
                    pageSize: 50,
                },
                filterSource
            ).then((res: any) => {
                const userRes = res?.data?.data?.user ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterUser = map(
                    [...selectedValue, ...userRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqUser = uniqBy(filterUser, (item) => item.id);
                setUserList(uniqUser);
            });
        }
    }, 500);

    const onChangeValue = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    ObjectUtils.findItemFromId(userList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(userList, values));
    };

    const userValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id ?? null;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            dataSource={userList}
            getLabel={(item) => `${item.firstName} ${item.lastName}`}
            onChange={onChangeValue}
            showSearch
            className={className}
            multiple={multiple}
            value={userValue}
            filterOption={false}
            {...selectProps}
        />
    );
};

export default UserSelect;
