import {
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
    ViewCollapse,
    ViewRow,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, map } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import CriteriaAPI from "../../../api/criteria/CriteriaAPI";
import InputDataList from "../../../common/input/InputDataList";
import { CRITERIA_STATUSES, CRITERIA_TYPES } from "../../../constant/criteria";
import { AppStateContext } from "../../../context/app";
import { mapCriteriaToServer } from "../../../interfaces/criteria";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CriteriaSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
});

const CriteriaCrud = () => {
    const { criteriaId } = useParams<any>();
    const history = useHistory();
    const isEdit = useMemo(() => !isEmpty(criteriaId), [criteriaId]);
    const { setSiteConfig } = useContext(AppStateContext);

    const criteriaForm = useFormik<any>({
        initialValues: {
            name: null,
            type: null,
            status: null,
            values: [],
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CriteriaSchema,
        validate: (values) => {
            const error: any = {};
            if (values.type === "dropdown" && values.values.length === 0) {
                error.values = "At least one value";
            }
            return error;
        },
        onSubmit: (values) => {
            const input = mapCriteriaToServer(values);
            if (isEdit) {
                onUpdateCriteria(input);
            } else {
                onCreateCriteria(input);
            }
        },
    });
    useEffect(() => {
        onLoadCriteriaDetail();
    }, []);

    const onLoadCriteriaDetail = async () => {
        if (!isEdit) return;
        const criteriaRes = await CriteriaAPI.detail(criteriaId);
        criteriaForm.setValues(criteriaRes);

        setSiteConfig({
            breadcrumb: [
                {
                    title: Messages.allCriteria,
                    url: Path.CRITERIA_TABLE,
                },
                {
                    title: criteriaRes?.name,
                },
            ],
        });
    };

    const onUpdateCriteria = (input: any) => {
        Progress.show(
            { method: CriteriaAPI.update, params: [criteriaId, input] },
            (updatedCriteria: any) => {
                Notifications.showSuccess(Messages.updateCriteriaSuccess);
                criteriaForm.setValues(updatedCriteria);
            }
        );
    };

    const onCreateCriteria = (input: any) => {
        Progress.show(
            { method: CriteriaAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createCriteriaSuccess);
                history.replace(
                    generatePath(Path.CRITERIA_DETAIL, { criteriaId: id })
                );
            }
        );
    };

    return (
        <div className="p-4">
            <Header
                title={Messages.criteria}
                onSave={() => criteriaForm.handleSubmit()}
            />
            <ViewCollapse label={Messages.general} className="mt-3">
                <ViewRow label={Messages.name}>
                    <InputText
                        name="name"
                        value={criteriaForm.values.name}
                        error={criteriaForm.errors.name as string}
                        onChange={criteriaForm.handleChange}
                        placeholder={Messages.pleaseInput}
                    />
                </ViewRow>
                <ViewRow label={Messages.type} className="mt-3">
                    <Select
                        dataSource={CRITERIA_TYPES}
                        value={criteriaForm.values.type}
                        error={criteriaForm.errors.type as string}
                        onChange={(type) =>
                            criteriaForm.setFieldValue("type", type)
                        }
                    />
                </ViewRow>
                <ViewRow label={Messages.status} className="mt-3">
                    <Select
                        dataSource={CRITERIA_STATUSES}
                        value={criteriaForm.values.status}
                        error={criteriaForm.errors.status as string}
                        onChange={(status) =>
                            criteriaForm.setFieldValue("status", status)
                        }
                        getLabel={(item) => (Messages as any)[item.label]}
                    />
                </ViewRow>
            </ViewCollapse>

            {criteriaForm.values.type === "dropdown" && (
                <ViewCollapse label={Messages.criteriaValue} className="mt-3">
                    <InputDataList
                        value={criteriaForm.values.values}
                        onChange={(values) =>
                            criteriaForm.setFieldValue("values", values)
                        }
                        error={criteriaForm.errors.values}
                    />
                </ViewCollapse>
            )}
        </div>
    );
};

export default CriteriaCrud;
