import {
    Card,
    DialogManager,
    Header,
    InputText,
    Notifications,
    Progress,
    TreeSelect,
} from "d-react-components";
import { FormikProps, useFormik } from "formik";
import { pick } from "lodash";
import * as React from "react";
import { usePrevious } from "react-use";
import * as Yup from "yup";
import FaqAPI from "../../../api/faq/FaqAPI";
import Messages from "../../../languages/Messages";
import { IFaqManagerContext, FaqManagerContext } from "../FaqManager";

interface FaqCategoryDetailProps {
    onUpdated?: any;
}

interface FaqCategory {
    id?: string;
    name?: string;
    parentId?: string;
    tree?: string[];
}

export interface FaqCategoryFormProps {
    form: FormikProps<FaqCategory>;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required("isRequired"),
    parentId: Yup.mixed().required("isRequired"),
});

const FaqCategoryDetail: React.FC<FaqCategoryDetailProps> = ({ onUpdated }) => {
    const { selectedCategory, setSelectedCategory } =
        React.useContext<IFaqManagerContext>(FaqManagerContext);
    const form = useFormik<FaqCategory>({
        initialValues: {},
        onSubmit: () => {
            if (selectedCategory) {
                FaqAPI.faqCategoriesUpdate({
                    payload: [
                        {
                            id: selectedCategory.value,
                            ...values,
                        },
                    ],
                }).then(() => {
                    onUpdated && onUpdated();
                    Notifications.showSuccess(
                        Messages.updateStSuccess.replace(
                            "@something",
                            Messages.manualCategory
                        )
                    );
                });
            } else {
                FaqAPI.faqCategoryCreate({
                    payload: { ...pick(values, ["parentId", "name"]) },
                }).then(() => {
                    onUpdated && onUpdated();
                });
                Notifications.showSuccess(
                    Messages.createStSuccess.replace(
                        "@something",
                        Messages.manualCategory
                    )
                );
                setSelectedCategory(undefined);
            }
        },
        validationSchema,
        validateOnChange: false,
    });
    const { handleSubmit, values } = form;

    const deleteCategory = () => {
        DialogManager.showConfirm(
            Messages.warning,
            Messages.confirmDeleteSetting.replace(
                "$setting",
                Messages.manualCategory
            ),
            () => {
                Progress.show(
                    {
                        method: FaqAPI.manualCategoryDelete,
                        params: {
                            id: selectedCategory?.value,
                        },
                    },
                    (res: any) => {
                        Notifications.showSuccess(
                            Messages.deleteStSuccess.replace(
                                "@something",
                                Messages.manualArticle
                            )
                        );
                        onUpdated && onUpdated();
                    },
                    (res: any) => {
                        Notifications.showError(Messages.serverError);
                    }
                );
            }
        );
    };

    return (
        <>
            <Header
                title={values?.name || `New Category`}
                onSave={() => {
                    handleSubmit();
                }}
                cancelText={Messages.delete}
                onCancel={deleteCategory}
                showCancel={!!selectedCategory?.parentId}
            />
            <Card className="my-3">
                <FaqCategoryForm form={form} />
            </Card>
        </>
    );
};

const FaqCategoryForm: React.FC<FaqCategoryFormProps> = ({ form }) => {
    const { categoryList, selectedCategory, treeData } =
        React.useContext<IFaqManagerContext>(FaqManagerContext);
    const { values, setFieldValue, errors, setValues } = form;
    const getTree = (v: string) => {
        const tree =
            categoryList.find((item: any) => item?.value === v)?.tree ?? [];
        setFieldValue("tree", [...tree, v]);
    };
    const prevCategory = usePrevious(selectedCategory);

    React.useEffect(() => {
        if (selectedCategory) {
            setValues({
                name: selectedCategory.title,
                tree: selectedCategory.tree,
                parentId: selectedCategory.parentId,
            });
        } else {
            setValues({
                name: "",
                tree: [],
                parentId: prevCategory
                    ? prevCategory.value
                    : treeData?.[0]?.value,
            });
        }
    }, [selectedCategory]);

    return (
        <>
            <div className="mb-3">
                <InputText
                    label={Messages.name}
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e?.target?.value)}
                    error={errors.name}
                />
            </div>
            <div className="mb-3">
                <TreeSelect
                    label={Messages.parentCategory}
                    value={values?.parentId as any}
                    dataSource={treeData}
                    allowClear={false}
                    error={errors.parentId}
                    onChange={(v: any) => {
                        if (v === selectedCategory?.value) {
                            return;
                        }
                        getTree(v);
                        setFieldValue("parentId", v);
                    }}
                />
            </div>
        </>
    );
};

export default FaqCategoryDetail;
