import { Modal, Button, Progress } from "d-react-components";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import MediaAPI from "../../api/media/MediaAPI";
import Messages from "../../languages/Messages";

interface ISignatureModal {
    open: boolean;
    description?: string;
    canvasProps?: any;
    onSave?: (value: any) => void;
    onClose: () => void;
}

const SignatureModal = ({
    open,
    onClose,
    canvasProps = {},
    onSave,
    description,
}: ISignatureModal) => {
    const signaturePadRef = useRef<any>();
    const onClear = () => {
        signaturePadRef.current.clear();
    };

    const onSaveSignature = () =>
        Progress.show(
            {
                method: MediaAPI.uploadMedia,
                params: [{ base64: signaturePadRef.current.toDataURL() }],
            },
            (res: any) => {
                onSave && onSave(res?.data?.upload?.url);
                onClose();
            }
        );

    return (
        <Modal
            open={open}
            onClose={onClose}
            onSave={onSaveSignature}
            title={Messages.signature}
        >
            <div>
                <div>
                    <Button onClick={onClear} variant="trans">
                        {Messages.clear}
                    </Button>
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                            width: 600,
                            height: 300,
                            className: "border w-100",
                            ...canvasProps,
                        }}
                        ref={(ref: any) => {
                            signaturePadRef.current = ref;
                        }}
                    />
                </div>
                {description && <small className=" mt-3">{description}</small>}
            </div>
        </Modal>
    );
};
export default SignatureModal;
