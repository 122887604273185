/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import { Tree, TreeNodeProps } from "antd";
import { Button, Notifications } from "d-react-components";
import { filter, forEach } from "lodash";
import * as React from "react";
import FaqAPI from "../../api/faq/FaqAPI";
import { FaqStatus, Faq } from "../../interfaces/faq";
import Messages from "../../languages/Messages";
import {
    createDataTree,
    findParentInNestedArray,
    flatternArray,
} from "../../utils/ObjectUtils";
import FaqArticleDetail from "./content/FaqArticleDetail";
import FaqCategoryDetail from "./content/FaqCategoryDetail";

export type ViewMode = "category" | "article";

export interface ManualCategory {
    id: string;
    name: string;
    title?: string;
    parentId?: string;
    tree?: string[];
    value: string;
}

export interface IFaqManagerContext {
    selectedCategory?: ManualCategory;
    categoryList: any;
    onUpdated?: any;
    treeData: any;
    selectedArticle?: Faq;
    setSelectedArticle?: any;
    setSelectedCategory?: any;
    folders: any;
    folderId: any;
    onChangeState: any;
}

const faqManagerContextState: IFaqManagerContext = {} as any;

export const FaqManagerContext = React.createContext(faqManagerContextState);

const FaqManager = () => {
    const [categoryList, setCategoryList] = React.useState([]);
    const [articleList, setArticleList] = React.useState<any>({});
    const [selectedCategory, setSelectedCategory] = React.useState();
    const [selectedArticle, setSelectedArticle] = React.useState();

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>();
    const [treeData, setTreeData] = React.useState<any>([]);
    const [treeDataWithArticle, setTreeDataWithArticle] = React.useState<any>(
        []
    );
    const [viewMode, setViewMode] = React.useState<ViewMode>("category");
    const [expandedKeys, setExpandedKeys] = React.useState<any[]>();
    React.useEffect(() => {
        const data = createDataTree(categoryList, []);
        setTreeData(data);
        const dataArticle = createDataTree(categoryList, articleList);
        setTreeDataWithArticle(dataArticle);
    }, [categoryList, articleList]);

    React.useEffect(() => {
        getListCategory();
    }, []);

    const onDropItem = (info: any) => {
        if (
            info.dragNode.type === "article" &&
            info.node.type === "article" &&
            !info.dropToGap
        ) {
            return;
        }
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split("-");
        const dropPosition =
            info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data: any, key: any, callback: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...treeDataWithArticle];

        let dragObj: any;
        loop(data, dragKey, (item: any, index: any, arr: any) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item: any) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.children || []).length > 0 &&
            info.node.expanded &&
            dropPosition === 1
        ) {
            loop(data, dropKey, (item: any) => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else {
            let ar: any[] = [];
            let i = 0;
            loop(data, dropKey, (item: any, index: any, arr: any) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1 && ar) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }
        // setTreeDataWithArticle(data);
        if (info.dragNode.type === "category") {
            updateCategory(data, info.dragNode);
        } else {
            updateFAQ(data, info.dragNode, info?.dragNode?.parentId);
        }
    };

    const onSelectCategory = (info: TreeNodeProps) => {
        const { selected, expanded, node } = info;
        const isSelected = selected || expanded;
        if (node.type === "category") {
            setViewMode("category");
            if (isSelected) {
                setSelectedCategory(node);
                updateArticleOnNode(node?.value);
            } else {
                setSelectedCategory(undefined);
            }
        } else {
            setViewMode("article");
            if (isSelected) {
                setSelectedArticle(node);
            } else {
                setSelectedArticle(undefined);
            }
        }
    };

    const updateFAQ = (newTree: any, article: any, oldParent?: any) => {
        try {
            const newParent = findParentInNestedArray(newTree, article.value);
            const faqsToUpdate: any[] = [];
            if (newParent.children) {
                const articles = filter(
                    newParent.children,
                    (cat) => cat.isLeaf
                );
                forEach(articles, (cat, index) => {
                    faqsToUpdate.push({
                        id: cat.value,
                        order: index + 1,
                        ...(cat.value === article.value
                            ? { categoryId: newParent.value }
                            : {}),
                    });
                });
            }
            FaqAPI.updateMultipleFaq({ payload: faqsToUpdate }).then(() => {
                updateArticleOnNode(newParent.value);
                if (oldParent) {
                    updateArticleOnNode(oldParent);
                }
                setSelectedArticle(article);
            });
        } catch (error) {
            Notifications.showError(error);
        }
    };

    const updateCategory = (newTree: any, category: any) => {
        try {
            const newParent = findParentInNestedArray(newTree, category.value);
            const categoriesToUpdate: any[] = [];
            if (newParent.children) {
                const categories = filter(
                    newParent.children,
                    (cat) => !cat.isLeaf
                );
                forEach(categories, (cat, index) => {
                    categoriesToUpdate.push({
                        id: cat.value,
                        order: index + 1,
                        ...(cat.value === category.value
                            ? { parentId: newParent.value }
                            : {}),
                    });
                });
            }
            FaqAPI.faqCategoriesUpdate({
                payload: categoriesToUpdate,
            }).then(() => {
                getListCategory();
            });
        } catch (error) {
            Notifications.showError(error);
        }
    };

    const onUpdatedCategory = () => {
        getListCategory();
    };

    const onUpdatedArticle = (categories: string[]) => {
        categories.forEach((category: string) => {
            updateArticleOnNode(category);
        });
    };

    const getListCategory = () => {
        FaqAPI.faqCategoryList().then((res) => {
            setCategoryList(
                (res?.data?.data ?? []).map((item: any) => ({
                    ...item,
                    type: "category",
                }))
            );
        });
    };

    const onClickExpandAll = () => {
        const categories = flatternArray(treeData).filter(
            (item: any) => item.type === "category"
        );
        setExpandedKeys(categories.map((i: any) => i.key));
        categories.forEach((cat: any) => {
            updateArticleOnNode(cat?.value);
        });
    };

    const onClickCollapseAll = () => {
        setExpandedKeys([]);
    };

    const updateArticleOnNode = (nodeId: string) => {
        return new Promise<void>((resolve) => {
            FaqAPI.faqListAll({
                categoryId: nodeId,
            }).then((resp) => {
                const faqs = resp?.data?.data ?? [];
                setArticleList((prevList: any) => ({
                    ...prevList,
                    [nodeId]: faqs.map((faq: any) => ({
                        ...faq,
                        isLeaf: true,
                        value: faq.id,
                        parentId: nodeId,
                        type: "article",
                        status: faq.status,
                    })),
                }));
                resolve();
            });
        });
    };

    const onLoadData = async (node: any) => {
        // setSelectedKeys([node.key]);
        // setSelectedCategory(node);
        // return updateArticleOnNode(node.value);
    };

    const titleRender = (nodeData: any) => {
        return nodeData?.status === FaqStatus.INACTIVE ? (
            <>
                <span className="badge badge-secondary mr-1">
                    {Messages.draft}
                </span>
                {nodeData?.title}
            </>
        ) : (
            nodeData?.title
        );
    };

    const renderSide = () => {
        return (
            <div className="col-md-4 bg-white p-4">
                <div className="d-flex">
                    <Button
                        className="mb-3 mr-3"
                        onClick={() => {
                            setSelectedCategory(undefined);
                            setSelectedKeys(undefined);
                            setViewMode("category");
                        }}
                    >
                        {Messages.newCategory}
                    </Button>
                    <Button
                        className="mb-3"
                        onClick={() => {
                            setViewMode("article");
                            setSelectedArticle(undefined);
                            setSelectedKeys(undefined);
                        }}
                    >
                        {Messages.newFAQ}
                    </Button>
                </div>
                <div className="d-flex align-items-center">
                    <Button variant="trans" onClick={onClickExpandAll}>
                        {Messages.expandAll}
                    </Button>
                    <h5>|</h5>
                    <Button variant="trans" onClick={onClickCollapseAll}>
                        {Messages.collapseAll}
                    </Button>
                </div>
                {treeData && treeData.length > 0 && (
                    <Tree.DirectoryTree
                        treeData={treeDataWithArticle}
                        draggable
                        onDrop={(event) => {
                            onDropItem(event);
                        }}
                        onSelect={(selectedKeys: any, info: TreeNodeProps) => {
                            onSelectCategory(info);
                            setSelectedKeys(selectedKeys);
                        }}
                        expandedKeys={expandedKeys}
                        onExpand={(keys: any, info: TreeNodeProps) => {
                            setSelectedKeys([info?.node?.key]);
                            onSelectCategory(info);
                            setExpandedKeys(keys);
                        }}
                        selectedKeys={selectedKeys}
                        loadData={onLoadData}
                        multiple={false}
                        titleRender={titleRender}
                    />
                )}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className="col-md-8">
                {viewMode === "category" && (
                    <FaqCategoryDetail onUpdated={onUpdatedCategory} />
                )}
                {viewMode === "article" && (
                    <FaqArticleDetail onUpdatedArticle={onUpdatedArticle} />
                )}
            </div>
        );
    };

    return (
        <FaqManagerContext.Provider
            value={
                {
                    categoryList,
                    treeData,
                    selectedCategory,
                    selectedArticle,
                    setSelectedArticle,
                    setSelectedCategory,
                } as any
            }
        >
            <div className="row p-4 min-vh-50">
                {renderSide()}
                {renderContent()}
            </div>
        </FaqManagerContext.Provider>
    );
};

export default FaqManager;
