import React from "react";
import { IBillingAddress, IServiceAddress } from "../interfaces/address";
import { IBooking } from "../interfaces/booking";

interface IBookingCreateState {
    bookingForm: any;
    sourceBookings: any[];
}

const bookingCreateState: IBookingCreateState = {
    bookingForm: null,
    sourceBookings: [],
};

export const BookingCreateContext = React.createContext(bookingCreateState);

interface IBookingDetailState {
    sourceBookings: any[];
    bookingDetail: IBooking;
    setBookingDetail: (values: any) => void;
}

const bookingDetailState: IBookingDetailState = {
    sourceBookings: [],
    bookingDetail: {} as any,
    setBookingDetail: () => {},
};

export const BookingDetailContext = React.createContext(bookingDetailState);

interface IBookingAddressesState {
    customerId: string;
}

const bookingAddressesState: IBookingAddressesState = {
    customerId: "",
};

export const BookingAddressesContext = React.createContext(
    bookingAddressesState
);

interface IBookingBillingState {
    customerId: string;
}

const bookingBillingState: IBookingBillingState = {
    customerId: "",
};

export const BookingBillingContext = React.createContext(bookingBillingState);
