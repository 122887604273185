import { Switch } from "antd";
import {
    Button,
    Header,
    Icon,
    InputColor,
    InputText,
    Modal,
    Notifications,
    Progress,
    StringUtils,
    ViewCollapse,
} from "d-react-components";
import { FormikProps, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import SettingAPI from "../../api/setting/SettingAPI";
import DraggableList from "../../common/drag/DraggableList";
import Image from "../../common/Image";
import Messages from "../../languages/Messages";
import SelectMediaManagerButton from "../media/selectmedia/SelectMediaManagerButton";
import SmartSupportLanguageButton from "./SmartSupportLanguageButton";

interface ISmartSupportState {
    smartSupportForm: FormikProps<any>;
    smartSupportLang: string;
    setSmartSupportLang: any;
}

const smartSupportState: ISmartSupportState = {
    smartSupportForm: {} as any,
    smartSupportLang: "en",
    setSmartSupportLang: () => {},
};

export const SmartSupportContext = React.createContext(smartSupportState);

const ROTATING_SPEED = [
    {
        id: "slow",
        label: Messages.slow,
    },
    {
        id: "medium",
        label: Messages.medium,
    },
    {
        id: "fast",
        label: Messages.fast,
    },
];

const SettingSupportContent = () => {
    const {
        smartSupportLang: lang,
        setSmartSupportLang,
        smartSupportForm,
    } = useContext(SmartSupportContext);

    const { values, setFieldValue, setValues } = smartSupportForm || {};

    const { status, themeColor, rotatingSpeed, channels } =
        smartSupportForm?.values ?? {};
    const [showModal, setShowModal] = useState(false);

    const onChangeData = (
        key: string,
        value: any,
        isLocalization?: boolean
    ) => {
        if (!isLocalization) {
            setFieldValue(key, value);
        } else {
            setValues({
                ...(values || {}),
                [lang]: { ...(values?.[lang] ?? {}), [key]: value },
            });
        }
    };

    const onChangeItem = (item: any) => {
        if (item.id) {
            const newChannels = channels.map((channel: any) =>
                item.id === channel.id ? item : channel
            );
            onChangeData("channels", newChannels, false);
        } else {
            onChangeData(
                "channels",
                [
                    ...(channels || []),
                    {
                        ...item,
                        id: StringUtils.generateCode(8),
                    },
                ],
                false
            );
        }
    };

    const onChangeListValue = (key: string, value: any) => {
        onChangeData(key, value, false);
    };

    return (
        <div>
            <SmartSupportLanguageButton />
            <ViewCollapse label={Messages.general} className="mt-3 mb-3">
                <div className="mb-3">
                    <div className="label mb-2">{Messages.status}</div>
                    <Switch
                        checked={status}
                        onChange={() => onChangeData("status", !status, false)}
                    />
                </div>
                <div className="mb-3">
                    <InputColor
                        label={Messages.themeColor}
                        value={themeColor}
                        onChange={(e) =>
                            onChangeData("themeColor", e?.target?.value, false)
                        }
                    />
                </div>
                {/* <div className="mb-3">
                    <Select
                        value={rotatingSpeed}
                        dataSource={ROTATING_SPEED}
                        getLabel={(item) => item?.label}
                        getValue={(item) => item?.id}
                        onChange={(value) =>
                            onChangeData("rotatingSpeed", value, false)
                        }
                        allowClear={false}
                        label={Messages.rotatingSpeed}
                    />
                </div> */}
                <div className="mb-3">
                    <InputText
                        label={Messages.mobileHeader}
                        placeholder={Messages.mobileHeader}
                        value={
                            smartSupportForm?.values?.[lang]?.mobileHeader ?? ""
                        }
                        onChange={(e) => {
                            onChangeData(
                                "mobileHeader",
                                e?.target?.value,
                                true
                            );
                        }}
                    />
                </div>
            </ViewCollapse>

            <ViewCollapse label={Messages.channels} className="mt-3 mb-3">
                <DraggableList
                    listValue={channels}
                    onChange={onChangeListValue}
                    listName="channels"
                    onChangeItem={onChangeItem}
                    onAddNew={() => setShowModal(true)}
                >
                    <TabsList />
                </DraggableList>
            </ViewCollapse>
            {showModal && (
                <ModalChannel
                    item={{}}
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={onChangeItem}
                />
            )}
        </div>
    );
};

const TabsListItem = SortableElement(
    ({ item, onChangeItem, onRemove, itemIndex }: any) => {
        const [showModal, setShowModal] = useState(false);
        const { smartSupportLang: lang } = useContext(SmartSupportContext);
        return (
            <>
                <div
                    className="p-3 d-flex align-items-center border-bottom"
                    style={{ pointerEvents: "painted" }}
                >
                    <div className="col-1 d-flex flex-column">
                        <Icon name="keyboard_arrow_up" size="small" />
                        <Icon name="keyboard_arrow_down" size="small" />
                    </div>
                    <div className="col-2">{item?.[lang]?.title}</div>
                    <div className="col-2">{item?.[lang]?.subTitle}</div>
                    <div className="col-2">
                        {item?.icon ? (
                            <Image
                                src={item?.icon}
                                className="image-square-small"
                            />
                        ) : null}
                    </div>
                    <div className="col-3">{item?.url}</div>
                    <div className="col-2 d-flex">
                        <Button
                            className="px-1 mr-1"
                            onClick={() => setShowModal(true)}
                            size="x-small"
                            variant="trans"
                            style={{ height: 40, width: 40 }}
                        >
                            <div style={{ pointerEvents: "none" }}>
                                <Icon name="edit" />
                            </div>
                        </Button>
                        <Button
                            className="px-1 mr-1"
                            onClick={(e) => {
                                onRemove(itemIndex);
                            }}
                            size="x-small"
                            variant="trans"
                            style={{ height: 40, width: 40 }}
                        >
                            <div style={{ pointerEvents: "none" }}>
                                <Icon name="delete" />
                            </div>
                        </Button>
                    </div>
                </div>
                {showModal && (
                    <ModalChannel
                        item={item}
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        onSave={onChangeItem}
                    />
                )}
            </>
        );
    }
);

const TabsList = SortableContainer(
    ({ listValue = [], onChangeValue, onChangeItem, onRemove }: any) => {
        return (
            <div className="w-100">
                <div className="p-3 d-flex align-items-center border-bottom">
                    <div className="col-1">&nbsp;</div>
                    <div className="col-2">{Messages.title}</div>
                    <div className="col-2">{Messages.subTitle}</div>
                    <div className="col-2">{Messages.icon}</div>
                    <div className="col-3">{Messages.url}</div>
                    <div className="col-2">{Messages.action}</div>
                </div>
                {listValue?.map((item: any, index: any) => {
                    return (
                        <TabsListItem
                            item={item}
                            itemIndex={index}
                            index={index}
                            key={`${item.id}-index`}
                            onChangeValue={(key: string, value: any) =>
                                onChangeValue(key, value, item)
                            }
                            onRemove={onRemove}
                            onChangeItem={onChangeItem}
                        />
                    );
                })}
            </div>
        );
    }
);

const ModalChannel = ({ open, onClose, onSave, item }: any) => {
    const { smartSupportLang: blockLang } = useContext(SmartSupportContext);
    const [media, setMedia] = useState(item);
    const { title = "", subTitle = "" } = media?.[blockLang] ?? {};
    const { icon, url = "" } = media;

    const onChange = (key: string, value: any, isLocalization: boolean) => {
        const newItem = { ...media };
        if (isLocalization) {
            if (!newItem[blockLang]) {
                newItem[blockLang] = {};
            }
            newItem[blockLang][key] = value;
        } else {
            newItem[key] = value;
        }
        setMedia(newItem);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            size="medium"
            hasCloseIcon={false}
            title={Messages.channel}
            hasCancelButton
            saveText={Messages.save}
            onSave={() => {
                onSave && onSave(media);
                onClose();
            }}
        >
            <SmartSupportLanguageButton />
            <div className="mb-3">
                <InputText
                    value={title}
                    onChange={(e) => onChange("title", e.target.value, true)}
                    label={Messages.title}
                    className="mt-2"
                />
            </div>
            <div className="mb-3">
                <InputText
                    value={subTitle}
                    onChange={(e) => onChange("subTitle", e.target.value, true)}
                    label={Messages.subTitle}
                    className="mt-2"
                />
            </div>
            <div className="mb-3">
                <div className="label mb-3">{Messages.icon}</div>
                {icon && <Image src={icon} className="image-reg-medium" />}
                <SelectMediaManagerButton
                    isSingleSelect
                    onSelect={([media]: any) =>
                        onChange("icon", media.url, false)
                    }
                    renderButton={() => (
                        <Button className="mt-3">{Messages.chooseImage}</Button>
                    )}
                />
            </div>
            <div className="mb-3">
                <InputText
                    value={url}
                    onChange={(e) => onChange("url", e.target.value, false)}
                    label={Messages.url}
                    className="mt-2"
                />
            </div>
        </Modal>
    );
};

const SmartSupport = () => {
    const [smartSupportLang, setSmartSupportLang] = useState("en");

    const smartSupportForm = useFormik<any>({
        initialValues: { status: true },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        // validationSchema: NewsSchema,
        onSubmit: (values: any) => {
            onUpdateSmartSupport(values);
        },
    });

    useEffect(() => {
        loadSmartSupportDetail();
    }, []);

    const loadSmartSupportDetail = () => {
        return Progress.show(
            { method: SettingAPI.smartSupport, params: [] },
            (res: any) => {
                smartSupportForm.setValues(res || { status: true });
            }
        );
    };

    const onUpdateSmartSupport = (input: any) => {
        Progress.show(
            { method: SettingAPI.updateSmartSupport, params: [input] },
            (res: any) => {
                Notifications.showSuccess(Messages.updateSmartSupportSuccess);
                smartSupportForm.setValues(res || {});
            }
        );
    };

    return (
        <SmartSupportContext.Provider
            value={{
                smartSupportForm,
                smartSupportLang,
                setSmartSupportLang,
            }}
        >
            <Header
                title={Messages.smartSupport}
                onSave={() => smartSupportForm.handleSubmit()}
            />

            <div className="d-flex flex-column p-4">
                <SettingSupportContent />
            </div>
        </SmartSupportContext.Provider>
    );
};

export default SmartSupport;
