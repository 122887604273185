import { Progress } from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import { AppStateContext } from "../../../context/app";
import { VoucherDetailContext } from "../../../context/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import VoucherDetailActivity from "./VoucherDetailActivity";
import VoucherDetailHeader from "./VoucherDetailHeader";
import VoucherDetailInformation from "./VoucherDetailInformation";
import VoucherDetailOwnerShip from "./VoucherDetailOwnerShip";
import VoucherDetailUsageStatus from "./VoucherDetailUsageStatus";

const VoucherDetail = () => {
    const [voucher, setVoucher] = useState<IVoucher>({} as any);
    const { setSiteConfig } = useContext(AppStateContext);

    const { voucherId } = useParams<any>();

    useEffect(() => {
        loadVoucherDetail();
    }, []);

    const loadVoucherDetail = () => {
        Progress.show(
            {
                method: VoucherAPI.detail,
                params: voucherId,
            },
            (voucherDetail: any) => {
                setVoucher(voucherDetail);
                setSiteConfig({
                    breadcrumb: [
                        {
                            title: Messages.allVoucher,
                            url: Path.VOUCHER_TABLE,
                        },
                        {
                            title: Messages.voucherDetail,
                        },
                    ],
                });
            }
        );
    };

    return (
        <VoucherDetailContext.Provider value={{ voucher, setVoucher }}>
            <div className="h-100 overflow-auto pb-5">
                <div className="p-4">
                    <VoucherDetailHeader />
                    <VoucherDetailInformation />
                    <VoucherDetailOwnerShip />
                    <VoucherDetailUsageStatus />
                    <VoucherDetailActivity />
                </div>
            </div>
        </VoucherDetailContext.Provider>
    );
};

export default VoucherDetail;
