import { Button, Drawer, IDrawerProps } from "d-react-components";
import { forEach, map, slice } from "lodash";
import React, { useState } from "react";
import { getProductLocalizeInfo } from "../../../../../interfaces/product";
import { IVoucher } from "../../../../../interfaces/voucher";
import Messages from "../../../../../languages/Messages";
import { IVoucherWorksheetOwnership } from "../WorksheetDetailVouchers";
import VoucherProductItem from "./VoucherProductItem";

export interface IWorksheetVoucherRemoveDrawerProps extends IDrawerProps {
    value?: IVoucherWorksheetOwnership[];
    onChange?: (props: IVoucher[]) => any;
}

const WorksheetVoucherRemoveDrawer: React.FC<IWorksheetVoucherRemoveDrawerProps> =
    ({ open, onClose, value, onChange }) => {
        const [listToRemove, setListToRemove] = useState<
            IVoucherWorksheetOwnership[]
        >(map(value, (i) => ({ ...i, quantity: i?.appliedQty ?? 0 })));
        const onChangeQuantity = (itemToChange: IVoucherWorksheetOwnership) => {
            const clone = map(listToRemove, (i) =>
                i?.id === itemToChange?.id ? itemToChange : i
            );
            setListToRemove(clone);
        };

        const onApplyHandle = () => {
            const listToChange: IVoucher[] = [];
            forEach(listToRemove, (item) => {
                const { quantity = 0, appliedQty = 0, appliedList = [] } = item;
                if (quantity < appliedQty) {
                    const qtyToRemove = appliedQty - quantity;
                    const itemsToRemove = slice(appliedList, 0, qtyToRemove);
                    if (itemsToRemove?.length > 0) {
                        forEach(itemsToRemove, (i) => {
                            listToChange.push(i);
                        });
                    }
                }
            });
            if (listToChange && listToChange?.length > 0) {
                onChange && onChange(listToChange);
                onClose && onClose(0 as any);
            }
        };

        const renderFooterDrawer = () => {
            return (
                <div className="w-100 d-flex p-3 justify-content-end">
                    <Button onClick={onApplyHandle}>{Messages.apply}</Button>
                </div>
            );
        };

        return (
            <Drawer
                size="x-large"
                open={open}
                onClose={onClose}
                footer={renderFooterDrawer()}
            >
                <div className="flex-center-y w-100 border-bottom py-3">
                    <div className="h4 text-primary w-100 text-center">
                        {Messages.remove}
                    </div>
                </div>
                <div className="p-3">
                    {map(listToRemove, (item, index) => {
                        return (
                            <VoucherProductItem
                                key={`${item?.id}_${index}_${item?.quantity}`}
                                showQtyInput
                                voucherProduct={item?.voucher}
                                getQty={(i) => item?.appliedQty}
                                show={{}}
                                inputQuantityProps={{
                                    value: item?.quantity,
                                    max: item?.appliedQty,
                                    onChange: (value) =>
                                        onChangeQuantity({
                                            ...item,
                                            quantity: value,
                                        }),
                                }}
                                renderAdditionalInfo={() => {
                                    if (!item?.appliedProduct) {
                                        return null;
                                    }
                                    return (
                                        <div className="text-primary">
                                            {`( ${getProductLocalizeInfo(
                                                item?.appliedProduct,
                                                "name"
                                            )} )`}
                                        </div>
                                    );
                                }}
                            />
                        );
                    })}
                </div>
            </Drawer>
        );
    };

export default WorksheetVoucherRemoveDrawer;
