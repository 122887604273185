/* eslint-disable no-plusplus */
import { Button, TimeUtils } from "d-react-components";
import { useState } from "react";
import SignatureModal from "../../../common/signature/SignatureModal";
import Messages from "../../../languages/Messages";

interface ISignatureField {
    name: string;
    date?: any;
    signature?: string;
    disabled?: boolean;
    // eslint-disable-next-line no-unused-vars
    onChangeSign?: (url: string) => void;
}
const SIGN_PLACEHOLDER = "...............................";

const SignatureField = ({
    name,
    date,
    signature,
    disabled,
    onChangeSign,
}: ISignatureField) => {
    const [openSignature, setOpenSignature] = useState<boolean>(false);

    const renderSignatureField = () => {
        if (signature) {
            return <img src={signature} className="image-reg-small ml-1" />;
        }
        return <small className="ml-1">{SIGN_PLACEHOLDER}</small>;
    };

    return (
        <div className="flex-column">
            <div className="d-flex align-items-center">
                <small>{`${name}: `}</small>
                {renderSignatureField()}
                {!disabled && (
                    <Button
                        size="small"
                        iconName="edit"
                        variant="trans"
                        onClick={() => setOpenSignature(true)}
                    />
                )}
            </div>
            <small>{`${Messages.date}: ${
                date ? TimeUtils.toDateTime(date) : SIGN_PLACEHOLDER
            }`}</small>
            <SignatureModal
                open={openSignature}
                onClose={() => setOpenSignature(false)}
                onSave={onChangeSign}
            />
        </div>
    );
};

export default SignatureField;
