export const VOUCHER_STATUSES = [
    { id: "AVAILABLE", label: "available", color: "#33B950" },
    { id: "USED", label: "used", color: "#2D9CDB" },
    { id: "EXPIRING", label: "expiring", color: "#FAC256" },
    { id: "EXPIRED", label: "expired", color: "#D30F0F" },
    { id: "CANCELLED", label: "cancelled", color: "#BDBDBD" },
];

export const VOUCHER_STATUS = {
    AVAILABLE: "AVAILABLE",
    USED: "USED",
    EXPIRED: "EXPIRED",
    EXPIRING: "EXPIRING",
    CANCELLED: "CANCELLED",
};

export const VOUCHER_OWNERSHIP_STATUSES = [
    { id: "AVAILABLE", label: "available", color: "#27AE60" },
    { id: "OWNED", label: "owned", color: "#2D9CDB" },
    { id: "CANCELLED", label: "cancelled", color: "#BDBDBD" },
];

export const VOUCHER_OWNERSHIP_STATUS = {
    AVAILABLE: "AVAILABLE",
    OWNED: "OWNED",
    CANCELLED: "CANCELLED",
};

export enum VOUCHER_CREATE_TYPE {
    MANUAL = "MANUAL",
    GENERATE_BY_BULK = "GENERATE_BY_BULK",
}

export const VOUCHER_CREATE_TYPES = [
    {
        id: VOUCHER_CREATE_TYPE.MANUAL,
        label: "manual",
    },
    {
        id: VOUCHER_CREATE_TYPE.GENERATE_BY_BULK,
        label: "autoGenerated",
    },
];

export enum VoucherOwnershipType {
    OWNER_SHIP = "OWNER_SHIP",
    ANONYMOUS = "ANONYMOUS",
}

export const VOUCHER_OWNERSHIP_TYPES = [
    {
        id: VoucherOwnershipType.OWNER_SHIP,
        label: "ownershipVoucher",
    },
    {
        id: VoucherOwnershipType.ANONYMOUS,
        label: "anonymousVoucher",
    },
];
