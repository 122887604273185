import { Button, Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find, forEach, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import UserAPI from "../../../api/user/UserAPI";
import VerticalTabView from "../../../common/view/VerticalTabView";
import { UserCrudContext } from "../../../context/user";
import {
    UserCreateSchema,
    UserPasswordSchema,
    UserUpdateInfoSchema,
} from "../../../formschema/user";
import {
    IUserRole,
    mapUserGeneralInfoToSer,
    mapUserPasswordToSer,
    mapUserResetPasswordToSer,
} from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserCrudInfo from "./UserCrudInfo";
import UserCrudOtpAuth from "./UserCrudOtpAuth";
import UserCrudPassword from "./UserCrudPassword";
import UserCrudRoles from "./UserCrudRoles";

const TABS = [
    {
        id: "general",
        label: Messages.personalInfo,
        component: <UserCrudInfo />,
    },

    {
        id: "password",
        label: Messages.password,
        component: <UserCrudPassword />,
    },
    {
        id: "role",
        label: Messages.role,
        component: <UserCrudRoles />,
    },
    {
        id: "otpAuth",
        label: Messages.authentication,
        component: <UserCrudOtpAuth />,
    },
];

const UserCrud = () => {
    const { userId } = useParams<any>();
    const isEdit = useMemo(() => !!userId, [userId]);
    const history = useHistory();
    const [roleList, setRoleList] = useState<IUserRole[]>([]);

    const userForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        validate: (values) => {
            const error: any = {};
            if (!isEdit && values.password !== values.confirmPassword) {
                error.confirmPassword = "Confirm password not match!";
            }

            if (values.isOtpAuth && isEmpty(values.otpAuthSecretCode)) {
                error.otpAuthSecretCode = "OTP Authenticator Code require!";
            }
            return error;
        },
        onSubmit: (values) => {
            const bodyInfo = mapUserGeneralInfoToSer(values);
            const bodyPass = mapUserPasswordToSer(values);
            if (isEdit) {
                onUpdateUser(bodyInfo);
            } else {
                onCreateUser(bodyInfo, bodyPass);
            }
        },
    });

    const userUpdatePassForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UserPasswordSchema,
        validate: (values) => {
            const error: any = {};
            if (values.password !== values.confirmPassword) {
                error.confirmPassword = "Confirm password not match!";
            }
            return error;
        },
        onSubmit: (values) => {
            const bodyPass = mapUserResetPasswordToSer(values);
            onResetPassUser(bodyPass);
        },
    });

    const [selectedTab, setSelectedTab] = useState<any>(TABS[0]);

    useEffect(() => {
        loadRoleList();
        isEdit && loadCustomerDetail();
    }, []);

    useEffect(() => {
        forEach(Object.keys(userForm.errors), (key) =>
            Notifications.showError(JSON.stringify(userForm.errors[key]))
        );
    }, [userForm.errors]);

    const loadCustomerDetail = () => {
        Progress.show(
            { method: UserAPI.detail, params: [userId] },
            userForm.setValues as any
        );
    };

    const loadRoleList = () => {
        UserAPI.roleList().then((res) =>
            setRoleList(res?.data?.data?.role ?? [])
        );
    };

    const onUpdateUser = (input: any) => {
        Progress.show(
            { method: UserAPI.update, params: [userId, input] },
            (userDetail: any) => {
                userForm.setValues(userDetail);
                Notifications.showSuccess(Messages.updateCustomerSuccessfully);
            }
        );
    };

    const onResetPassUser = (input: any) => {
        Progress.show(
            { method: UserAPI.resetPassword, params: [userId, input] },
            (userDetail: any) => {
                userForm.setValues(userDetail);
                Notifications.showSuccess(
                    Messages.resetUserPasswordSuccessfully
                );
            }
        );
    };

    const onCreateUser = (input: any, inputPass: any) => {
        const body = { ...input, ...inputPass };
        Progress.show({ method: UserAPI.create, params: [body] }, (id: any) => {
            history.replace(generatePath(Path.USER_DETAIL, { userId: id }));
            Notifications.showSuccess(Messages.createUserSuccessfully);
        });
    };

    const renderHeaderButtons = () => (
        <div className="flex-center" hidden={!isEdit}>
            {/* <Button variant="outline" className="mr-3">
                    {Messages.back}
                </Button> */}
            <Button
                className="mr-3"
                onClick={() => userUpdatePassForm.handleSubmit()}
                hidden={isEmpty(userUpdatePassForm?.values?.password)}
            >
                {Messages.sendResetPassword}
            </Button>
        </div>
    );

    return (
        <UserCrudContext.Provider
            value={{
                userForm,
                userUpdatePassForm,
                roleList,
            }}
        >
            <Header
                showCancel={false}
                title={Messages.userDetail}
                // customRight={renderHeaderButtons}
                onSave={() => {
                    if (selectedTab?.id === "password") {
                        userUpdatePassForm.handleSubmit();
                        return;
                    }
                    userForm.handleSubmit();
                }}
            />
            <div className="h-100 overflow-auto">
                <div className="row p-4">
                    <div className="col">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-9  ml-2">
                        {
                            find(TABS, (item) => selectedTab.id === item.id)
                                ?.component
                        }
                    </form>
                </div>
            </div>
        </UserCrudContext.Provider>
    );
};

export default UserCrud;
