import { Modal } from "d-react-components";
import { useFormik } from "formik";
import { join, map } from "lodash";
import React from "react";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import { InventoryFilterSchema } from "../../../formschema/inventory";
import {
    IInventory,
    mapFilterInventToServer,
} from "../../../interfaces/inventory";
import Messages from "../../../languages/Messages";
import FileUtils from "../../../utils/FileUtils";
import InventoryFilterView from "../common/InventoryFilterView";

interface IInventoryExportModal {
    open: boolean;
    onClose: () => any;
}

const InventoryExportModal = ({ open, onClose }: IInventoryExportModal) => {
    const exportForm = useFormik<any>({
        initialValues: {
            serviceIds: [],
            categoryIds: [],
            stockStatus: null,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: InventoryFilterSchema,
        onSubmit: (values) => {
            const exportParam = mapFilterInventToServer(values);

            InventoryAPI.export(exportParam).then((res) => {
                const list = res?.data?.data ?? [];
                const dataExport = convertToExportData(list);
                FileUtils.exportToCSV(dataExport, "InventoryExport");
            });
        },
    });

    const convertToExportData = (inventoryList: IInventory[]) => {
        return map(inventoryList, (inventory) => {
            return {
                [Messages.itemName]: inventory?.name ?? "",
                [Messages.sku]: inventory?.SKU ?? "",
                [Messages.category]: join(
                    map(inventory?.category, (item) => item?.name ?? ""),
                    ", "
                ),
                [Messages.totalStock]: inventory?.quantity ?? "",
            };
        });
    };

    return (
        <Modal
            title={Messages.export}
            open={open}
            onClose={onClose}
            saveText={Messages.export}
            sideText={Messages.clearAll}
            onSideClick={() => exportForm.resetForm()}
            onSave={() => exportForm.handleSubmit()}
        >
            <InventoryFilterView form={exportForm} />
        </Modal>
    );
};

export default InventoryExportModal;
