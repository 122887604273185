import React, { Dispatch } from "react";
import { IProduct } from "../../../../../interfaces/product";
import { IVoucherWorksheetDetail } from "../../../../../interfaces/voucher";
import { IVoucherWorksheetOwnership } from "../WorksheetDetailVouchers";

export interface IWorksheetDetailVoucherState {
    ownershipVouchers: Array<IVoucherWorksheetOwnership>;
    anonymousVouchers: Array<IVoucherWorksheetOwnership>;
    // setOwnershipVouchers: Dispatch<Array<IVoucherWorksheetOwnership>>;
    // setAnonymousVouchers: Dispatch<Array<IVoucherWorksheetOwnership>>;
    worksheetProducts?: IProduct[];
    voucherWithServiceApply?: IVoucherWorksheetDetail[];
}

const worksheetDetailVoucherState: IWorksheetDetailVoucherState = {} as any;

export const WorksheetDetailVoucherContext = React.createContext(
    worksheetDetailVoucherState
);
