import { gql } from "@apollo/client";
import { WAREHOUSE_STATUS } from "../../constant/warehouse";
import API from "../API";
import { F_WAREHOUSE, F_WAREHOUSES } from "./WarehouseFragment";

const WarehouseAPI = {
    list: (q?: string, filter?: any, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.query({
            query: gql`
                ${F_WAREHOUSES}
                query warehouses(
                    $q: String
                    $page: Int
                    $limit: Int
                    $filter: WarehouseFilter
                ) {
                    data: warehouses(
                        q: $q
                        page: $page
                        limit: $limit
                        filter: $filter
                    ) {
                        ...F_WAREHOUSES
                    }
                }
            `,
            variables: {
                q,
                filter,
                page: paging?.pageIndex,
                limit: paging?.pageSize,
            },
            fetchPolicy: "no-cache",
        }),

    warehouseActiveList: (q?: string) =>
        WarehouseAPI.list(
            q,
            { status: WAREHOUSE_STATUS.ACTIVE },
            { pageSize: 1000, pageIndex: 1 }
        ),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createWarehouse?.id,
        }).mutate({
            mutation: gql`
                mutation createWarehouse($input: WarehouseInput!) {
                    createWarehouse(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    detail: (id: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.warehouse,
        }).query({
            query: gql`
                ${F_WAREHOUSE}
                query warehouse($id: ID!) {
                    warehouse(id: $id) {
                        ...F_WAREHOUSE
                    }
                }
            `,
            variables: { id },
        }),
    update: (id: string, input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.updateWarehouse,
        }).mutate({
            mutation: gql`
                ${F_WAREHOUSE}
                mutation updateWarehouse($id: ID!, $input: WarehouseInput) {
                    updateWarehouse(id: $id, input: $input) {
                        ...F_WAREHOUSE
                    }
                }
            `,
            variables: { id, input },
        }),
};

export default WarehouseAPI;
