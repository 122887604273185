import * as Yup from "yup";
import { PRODUCT_TYPE } from "../constant/product";

export const ProductGeneralInfoSchema = Yup.object().shape({
    name: Yup.string().required("Required!"),
    description: Yup.string().required("Required!"),
    shortDescription: Yup.string().max(250, "Too Long!").required("Required!"),
});

export const ProductCRUDSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        th: ProductGeneralInfoSchema,
        en: ProductGeneralInfoSchema,
        SKU: Yup.string().required("Required!"),

        brand: Yup.string().required("Required!"),
    });

    if (value.productType === PRODUCT_TYPE.DEFAULT_PRODUCT) {
        return schema.concat(
            Yup.object().shape({
                stock: Yup.string().required("Required!"),
            }) as any
        );
    }

    if (
        value.productType === PRODUCT_TYPE.DEFAULT_PRODUCT ||
        value.productType === PRODUCT_TYPE.PREMIUM_SERVICE
    ) {
        return schema.concat(
            Yup.object().shape({
                categoryId: Yup.array().of(Yup.string()).required("Required!"),
                regularPrice: Yup.number().required("Required!"),
            }) as any
        );
    }

    if (value.productType === PRODUCT_TYPE.SERVICE_PRODUCT) {
        return schema.concat(
            Yup.object().shape({
                serviceCategoryId: Yup.string().required("Required!"),
                regularPrice: Yup.number().required("Required!"),
            }) as any
        );
    }

    return schema;
});
