import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { forEach } from "lodash";
import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import PaymentMethodAPI from "../../../api/paymentmethod/PaymentMethodAPI";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";

import { RefundCreateContext } from "../../../context/refundRequest";
import { RefundRequestCreateSchema } from "../../../formschema/refundRequest";
import { mapRefundRequestToServer } from "../../../interfaces/refundRequest";

import Messages from "../../../languages/Messages";
import Path from "../../Path";
import RefundRequestCreateFormat from "./RefundRequestCreateFormat";
import RefundRequestCreateItems from "./RefundRequestCreateItems";
import RefundRequestCreateSummary from "./RefundRequestCreateSummary";
import RefundRequestCreateType from "./RefundRequestCreateType";

const RefundRequestCreate = () => {
    const history = useHistory();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const refundForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RefundRequestCreateSchema,
        onSubmit: (values) => {
            const input = mapRefundRequestToServer(values);
            onCreate(input);
        },
    });

    const formValues = refundForm?.values;

    useEffect(() => {
        loadPaymentMethod();
    }, []);

    const loadPaymentMethod = () => {
        PaymentMethodAPI.list().then((res) => {
            setPaymentMethods(res?.data?.data ?? []);
        });
    };

    useEffect(() => {
        forEach(Object.keys(refundForm.errors), (key) =>
            Notifications.showError(JSON.stringify(refundForm.errors[key]))
        );
    }, [refundForm.errors]);

    const onCreate = (input: any) => {
        Progress.show(
            { method: RefundRequestAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createRefundSuccess);
                history.replace(
                    generatePath(Path.REFUND_REQUEST_DETAIL, {
                        refundRequestId: id,
                    })
                );
            }
        );
    };

    return (
        <RefundCreateContext.Provider
            value={
                {
                    refundForm,
                    paymentMethods,
                } as any
            }
        >
            <div className="h-100 overflow-auto pb-5 p-4 ">
                <div className="flex-row-between-center card-container p-3">
                    <h4>{Messages.newRefundRequest}</h4>
                    <Button onClick={() => refundForm.handleSubmit()}>
                        {Messages.submit}
                    </Button>
                </div>
                <RefundRequestCreateType />
                <RefundRequestCreateSummary />
                <RefundRequestCreateItems />
                <RefundRequestCreateFormat />
            </div>
        </RefundCreateContext.Provider>
    );
};

export default RefundRequestCreate;
