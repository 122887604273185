import { Checkbox, Menu, Dropdown } from "antd";
import classNames from "classnames";
import {
    AwesomeListComponent,
    Button,
    DialogManager,
    Icon,
    ImageUtils,
    InputTextSearch,
    Notifications,
    Progress,
    useFirstTime,
} from "d-react-components";
import { debounce, includes, map } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import MediaAPI from "../../api/media/MediaAPI";
import { MediaContext } from "../../context/media";
import Messages from "../../languages/Messages";
import Path from "../Path";
import MediaManagerMediaDetail from "./MediaManagerMediaDetail";
import MediaManagerTreeFolderModal from "./MediaManagerTreeFolderModal";
import UploadMediaFolderButton from "./UploadMediaFolderButton";

const MODE_VIEW = {
    LIST: "list",
    GRID: "grid",
};

const colClassBase = (col: any, additional = "") =>
    `d-flex align-items-center col-${col} ${additional}`;

const ACTIONS_LIST = [
    {
        id: "view",
        label: "view",
    },
    {
        id: "moveFolder",
        label: "move",
    },
    {
        id: "delete",
        label: "delete",
    },
];

const ORDER_BY = {
    ASC: "asc",
    DESC: "desc",
};

const IconSort = ({ ascending, onClick }: any) => {
    let iconName = "north";
    if (ascending) iconName = "south";
    return (
        <Button
            variant="trans"
            onClick={onClick}
            iconName={iconName}
            size="small"
        />
    );
};

const MediaMoreActions = ({ media }: any) => {
    const {
        refreshMedia,
        folderId,
        isSelectMode,
        onChangeState,
        onReloadMediaList,
        loadMediaFolder,
    } = useContext(MediaContext);
    const [openFolderModal, setOpenFolderModal] = useState(false);
    const history = useHistory();
    const onClickActions = (id: string) => {
        switch (id) {
            case "view":
                onViewMedia();
                break;
            case "moveFolder":
                setOpenFolderModal(true);
                break;
            default:
                onClickDeleteMedia();
        }
    };

    const onViewMedia = () => {
        !isSelectMode &&
            history.push(
                generatePath(Path.MEDIA_DETAIL, { folderId, mediaId: media.id })
            );
        onChangeState({ mediaId: media.id });
    };

    const onClickDeleteMedia = () => {
        DialogManager.showConfirm(
            Messages.deleteMedia,
            Messages.areYouSureDeleteMedia,
            onDeleteMedia
        );
    };

    const onDeleteMedia = () => {
        MediaAPI.deleteMedia([media.id]).then(() => {
            refreshMedia();
            Notifications.showSuccess(Messages.deleteMediaSuccess);
        });
    };

    const onMoveFolder = (destinationDir: any) => {
        Progress.show(
            {
                method: MediaAPI.moveMedia,
                params: [[media.id], destinationDir],
            },
            () => {
                Notifications.showSuccess(Messages.moveMediaSuccess);
                setOpenFolderModal(false);
                onReloadMediaList && onReloadMediaList();
                loadMediaFolder && loadMediaFolder();
            }
        );
    };

    const menu = (
        <Menu>
            {map(ACTIONS_LIST, (action) => (
                <Menu.Item
                    key={action.id}
                    onClick={() => onClickActions(action.id)}
                >
                    {(Messages as any)[action.label]}
                </Menu.Item>
            ))}
        </Menu>
    );
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="flex-center w-100 ml-1 cursor-pointer"
            >
                <Icon name="more_horiz" size="small" />
            </Dropdown>
            <MediaManagerTreeFolderModal
                open={openFolderModal}
                onClose={() => setOpenFolderModal(false)}
                onSave={onMoveFolder}
            />
        </div>
    );
};

const MediaListItem = ({ mediaItem, isSelected, onClick, onSelect }: any) => {
    const { url, title, type, size } = mediaItem;

    return (
        <div
            className="container-fluid row border-bottom py-2"
            onClick={onClick}
        >
            <div className={colClassBase(1)}>
                <Checkbox
                    checked={isSelected}
                    onClick={onSelect}
                    className="text-primary"
                />
            </div>
            <div className={colClassBase(7)}>
                <img
                    className="media-manager__image-item-view-list mr-2"
                    src={url}
                />
                <small className="media-manager__image-title-grid">
                    {title}
                </small>
            </div>
            <div className={colClassBase(1)}>
                <text className="text-uppercase">{type}</text>
            </div>
            <div className={colClassBase(2)}>
                <text>{size}</text>
            </div>
            <div className={colClassBase(1, "justify-content-center")}>
                <MediaMoreActions media={mediaItem} />
            </div>
        </div>
    );
};

const MediaGridItem = ({ mediaItem, onClick, isSelected }: any) => {
    const { url, title } = mediaItem;
    const imageClass = classNames("media-manager__image-item-view-grid mr-2", {
        "border border-primary": isSelected,
    });
    return (
        <div className="media-manager__media-item-grid" onClick={onClick}>
            <img className={imageClass} src={url} />
            <div className="d-flex p-1">
                {isSelected && <Checkbox checked={isSelected} />}
                <small className="media-manager__image-title-grid ml-1">
                    {title}
                </small>
                <MediaMoreActions media={mediaItem} />
            </div>
        </div>
    );
};

const MediaManagerMediaList = () => {
    const mediaContext = useContext(MediaContext);
    const history = useHistory();
    const {
        folderId,
        mediaId,
        isSelectMode,
        onClickAddSelected,
        isSingleSelect,
        onChangeState,
        onReloadMediaList,
        loadMediaFolder,
    } = mediaContext as any;
    const mediaListRef = useRef();
    const [modeView, setModeView] = useState(MODE_VIEW.GRID);
    const [selectedMedia, setSelectedMedia] = useState<any>([]);
    const textSearch = useRef("");
    const [sortBy, setSortBy] = useState();
    const [orderBy, setOrderBy] = useState(ORDER_BY.ASC);
    const [openFolderModal, setOpenFolderModal] = useState(false);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (folderId && !isFirstTime) {
            refreshMedia();
        }
    }, [folderId, orderBy, sortBy]);

    const source = (paging: any) => {
        return MediaAPI.getMedias(folderId, textSearch.current);
    };

    const refreshMedia = () =>
        mediaListRef.current && (mediaListRef.current as any).refresh();

    const onChangeOrderBy = () => {
        setOrderBy(orderBy === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC);
    };

    const isSelectMedia = (mediaId: any) => {
        return includes(
            selectedMedia.map((item: any) => item.id),
            mediaId
        );
    };

    const onClickMediaItem = (mediaItem: any) => {
        if (isSingleSelect) {
            setSelectedMedia([mediaItem]);
            return;
        }
        if (isSelectMedia(mediaItem.id)) {
            const result = selectedMedia.filter(
                (item: any) => item.id !== mediaItem.id
            );
            setSelectedMedia(result);
        } else {
            setSelectedMedia([...selectedMedia, mediaItem]);
        }
    };

    const onChangeTextSearch = debounce((event) => {
        textSearch.current = event.target.value;
        refreshMedia();
    }, 1000);

    const onClickDeleteAllMedia = () => {
        DialogManager.showConfirm(
            Messages.deleteMedia,
            Messages.areYouSureToDeleteMedia,
            onDeleteMedia
        );
    };

    const onMoveAllMedia = (destinationDir: any) => {
        const ids = selectedMedia.map((item: any) => item.id);
        Progress.show(
            {
                method: MediaAPI.moveMedia,
                params: [ids, destinationDir],
            },
            () => {
                Notifications.showSuccess(Messages.moveMediaSuccess);
                setOpenFolderModal(false);
                onReloadMediaList && onReloadMediaList();
                loadMediaFolder && loadMediaFolder();
            }
        );
    };

    const onClickDownloadAll = () => {
        const imageUrl = selectedMedia.map((item: any) => item.urls.base);
        ImageUtils.downloadImages(imageUrl);
    };

    const onDeleteMedia = () => {
        const APIDelete = {
            method: MediaAPI.deleteMedia,
            params: [selectedMedia.map((item: any) => item.id)],
        };
        Progress.show(APIDelete, () => {
            Notifications.showSuccess(Messages.deleteMediaSuccess);
            setSelectedMedia([]);
            refreshMedia();
        });
    };

    const onAddSelectFromDetail = (media: any) => {
        setSelectedMedia([...selectedMedia, media]);
    };

    /* ----------------------------------------
    // Render media list
    // ---------------------------------------- */

    const renderMediaItem = (mediaItem: any) => {
        if (modeView === MODE_VIEW.LIST) {
            return (
                <MediaListItem
                    mediaItem={mediaItem}
                    isSelected={isSelectMedia(mediaItem?.id)}
                    onSelect={() => onClickMediaItem(mediaItem)}
                />
            );
        }
        return (
            <MediaGridItem
                mediaItem={mediaItem}
                onClick={() => onClickMediaItem(mediaItem)}
                isSelected={isSelectMedia(mediaItem?.id)}
            />
        );
    };

    const renderMediaList = () => {
        return (
            <>
                {renderMediaHeader()}
                <AwesomeListComponent
                    ref={(ref: any) => {
                        mediaListRef.current = ref;
                    }}
                    source={source}
                    renderItem={renderMediaItem}
                    className="d-flex flex-column overflow-scroll media-manager__media-list"
                    classNameInfinityScroll="d-flex flex-wrap"
                    isPaging={false}
                />
            </>
        );
    };

    /* ----------------------------------------
    // Render header media list
    // ---------------------------------------- */
    const renderMediaHeaderListMode = () => {
        return (
            <div className="container-fluid row border-bottom py-2">
                <div className={colClassBase(1)} />
                <div className={colClassBase(7)}>
                    {renderTitleSort(Messages.name, "name")}
                </div>
                <div className={colClassBase(1)}>
                    <h6>{Messages.type}</h6>
                </div>
                <div className={colClassBase(2)}>
                    {renderTitleSort(Messages.size, "size")}
                </div>
                <div className={colClassBase(1, "justify-content-center")}>
                    {renderModeOption()}
                </div>
            </div>
        );
    };

    const renderTitleSort = (title: any, key: any) => {
        return (
            <Button variant="trans" onClick={() => setSortBy(key)}>
                <h6>{title}</h6>
                {sortBy === key && (
                    <IconSort
                        onClick={onChangeOrderBy}
                        ascending={orderBy === ORDER_BY.ASC}
                    />
                )}
            </Button>
        );
    };

    const renderMediaHeaderGridMode = () => {
        return (
            <div className="d-flex justify-content-between py-2 align-items-center">
                <div className="d-flex align-items-center">
                    {renderTitleSort(Messages.name, "name")}
                </div>
                {renderModeOption()}
            </div>
        );
    };

    const renderModeOption = () => (
        <div className="d-flex">
            <Button
                className="media-manager__button-mode-view"
                onClick={() => setModeView(MODE_VIEW.GRID)}
                variant={modeView === MODE_VIEW.GRID ? "standard" : "outline"}
            >
                <Icon name="apps" size="small" />
            </Button>
            <Button
                className="media-manager__button-mode-view"
                onClick={() => setModeView(MODE_VIEW.LIST)}
                variant={modeView === MODE_VIEW.LIST ? "standard" : "outline"}
            >
                <Icon name="format_list_bulleted" size="small" />
            </Button>
        </div>
    );

    const renderMediaHeader = () => {
        if (modeView === MODE_VIEW.LIST) return renderMediaHeaderListMode();
        return renderMediaHeaderGridMode();
    };

    /* ----------------------------------------
    // Render header media
    // ---------------------------------------- */
    const renderHeader = () => {
        if (selectedMedia.length > 0) {
            return renderHeaderControlSelected();
        }
        return renderHeaderSearch();
    };

    const renderHeaderControlSelected = () => {
        return (
            <div className="d-flex justify-content-between">
                <h6>{`${selectedMedia.length} ${Messages.selected}`}</h6>
                <div className="d-flex">
                    <Button
                        className="ml-2"
                        variant="trans"
                        onClick={() => setSelectedMedia([])}
                    >
                        {Messages.unSelectAll}
                    </Button>
                    <Button
                        className="ml-2"
                        color="error"
                        onClick={onClickDeleteAllMedia}
                    >
                        {Messages.deleteAll}
                    </Button>
                    <Button
                        className="ml-2"
                        onClick={() => setOpenFolderModal(true)}
                    >
                        {Messages.moveAll}
                    </Button>
                    {/* <button className="btn btn-primary ml-2" type="button" onClick={onClickDownloadAll}>
                        {Messages.downloadAll}
                    </button> */}
                    {isSelectMode && (
                        <Button
                            className="ml-2"
                            onClick={() => onClickAddSelected(selectedMedia)}
                        >
                            {Messages.addSelected}
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    const renderHeaderSearch = () => {
        return (
            <div className="d-flex justify-content-between">
                <h5 className="font-weight-bold">{Messages.selectMedias}</h5>
                <div className="d-flex align-items-center">
                    <InputTextSearch onChange={onChangeTextSearch} />
                    <UploadMediaFolderButton container="ml-3" />
                </div>
            </div>
        );
    };
    const onClickBack = () => {
        !isSelectMode &&
            history.replace(generatePath(Path.MEDIA_FOLDER, { folderId }));
        onChangeState({ mediaId: null });
    };

    return (
        <MediaContext.Provider
            value={{ ...(mediaContext as any), refreshMedia }}
        >
            <div className="container-fluid p-3">
                {renderHeader()}
                {renderMediaList()}
            </div>
            {!!mediaId && (
                <MediaManagerMediaDetail
                    open={!!mediaId}
                    onUpdateMedia={refreshMedia}
                    onSelect={onAddSelectFromDetail}
                    onClose={() => onChangeState({ mediaId: null })}
                    onClickBack={onClickBack}
                    mediaId={mediaId}
                />
            )}
            <MediaManagerTreeFolderModal
                open={openFolderModal}
                onClose={() => setOpenFolderModal(false)}
                onSave={onMoveAllMedia}
            />
        </MediaContext.Provider>
    );
};

export default MediaManagerMediaList;
