/* eslint-disable import/prefer-default-export */

import Messages from "../languages/Messages";
import Path from "../screen/Path";
import { STATIC_CONTENT_TYPE } from "./staticContent";
import { ROLE_ACCESS_TYPE } from "./user";

export const API_GOOGLE_KEY = "AIzaSyCo-pN_alJHEpOXRIerbH9SZ88urh9Ag-w";
export const FIREBASE_MESSAGING_KEY =
    "BIp4RcTf2S77-YcnPjs7H0FbSdKmmREJyYrUa8ALdNiCxShtQfog8vtF67DHJKsHDcDxOi3mm4kW8lL0VMVp2-I";

export const MAIN_MENU = [
    {
        id: "oauthCode",
        label: "authOTP",
        iconName: "password",
    },
    {
        id: "booking",
        label: "bookings",
        iconName: "storefront",
        subMenu: [
            {
                id: "bookings",
                label: "bookingTable",
                path: Path.BOOKING_TABLE,
                selected: true,
                accessType: ROLE_ACCESS_TYPE.LIST,
            },
            {
                id: "bookingCreate",
                label: "bookingCreate",
                path: Path.BOOKING_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    { title: Messages.allBooking, url: Path.BOOKING_TABLE },
                    { title: Messages.bookingCreate },
                ],
            },
        ],
    },
    {
        id: "bookingAssigned",
        label: "bookingAssigned",
        iconName: "storefront",
        path: Path.BOOKING_ASSIGNED_TABLE,
    },
    {
        id: "worksheet",
        label: "worksheet",
        iconName: "home_repair_service",
        subMenu: [
            {
                id: "worksheet",
                label: "worksheetTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.WORKSHEET_TABLE,
            },
            {
                id: "worksheetCreate",
                label: "worksheetCreate",
                path: Path.WORKSHEET_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,

                breadcrumb: [
                    { title: Messages.allWorksheet, url: Path.WORKSHEET_TABLE },
                    { title: Messages.worksheetCreate },
                ],
            },
        ],
    },
    {
        id: "worksheetAssigned",
        label: "worksheetAssigned",
        iconName: "home_repair_service",
        path: Path.WORKSHEET_ASSIGNED_TABLE,
    },
    {
        id: "order",
        label: "order",
        iconName: "shopping_cart",
        subMenu: [
            {
                id: "orders",
                label: "orderTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.ORDER_TABLE,
            },
            {
                id: "orderCreate",
                label: "orderCreate",
                path: Path.ORDER_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,

                breadcrumb: [
                    { title: Messages.orderTable, url: Path.ORDER_TABLE },
                    { title: Messages.orderCreate },
                ],
            },
        ],
    },
    {
        id: "refundRequest",
        label: "refundRequest",
        iconName: "request_quote",
        subMenu: [
            {
                id: "refundRequests",
                label: "refundRequestTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.REFUND_REQUEST_TABLE,
            },
            {
                id: "refundRequestCreate",
                label: "refundRequestCreate",
                path: Path.REFUND_REQUEST_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allRefundRequest,
                        url: Path.REFUND_REQUEST_TABLE,
                    },
                    { title: Messages.refundRequestCreate },
                ],
            },
        ],
    },
    {
        id: "product",
        label: "products",
        iconName: "shopping_basket",
        subMenu: [
            {
                id: "products",
                label: "productTable",
                accessType: ROLE_ACCESS_TYPE.LIST,

                path: Path.PRODUCT_TABLE,
            },
            {
                id: "productCreate",
                label: "productCreate",
                path: Path.PRODUCT_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,

                breadcrumb: [
                    { title: Messages.allProduct, url: Path.PRODUCT_TABLE },
                    { title: Messages.productCreate },
                ],
            },
            {
                id: "productCat",
                label: "productCategory",
                path: Path.PRODUCT_CAT_TABLE,
                breadcrumb: [
                    {
                        title: Messages.allProductCategory,
                        url: Path.PRODUCT_CAT_TABLE,
                    },
                    {
                        title: Messages.productCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "inventory",
        label: "inventory",
        iconName: "inventory",
        path: Path.INVENTORY_TABLE,
    },

    {
        id: "customer",
        label: "customer",
        iconName: "face",
        subMenu: [
            {
                id: "customers",
                label: "customerTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.CUSTOMER_TABLE,
            },
            {
                id: "customerCreate",
                label: "customerCreate",
                accessType: ROLE_ACCESS_TYPE.CREATE,

                path: Path.CUSTOMER_CREATE,
                breadcrumb: [
                    {
                        title: Messages.allCustomers,
                        url: Path.CUSTOMER_TABLE,
                    },
                    {
                        title: Messages.customerCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "categoryService",
        label: "serviceCategory",
        iconName: "category",
        subMenu: [
            {
                id: "categories",
                label: "categoryTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.CATEGORY_TABLE,
            },
            {
                id: "createCat",
                label: "categoryCreate",
                path: Path.CATEGORY_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allCategories,
                        url: Path.CATEGORY_TABLE,
                    },
                    {
                        title: Messages.categoryCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "criteria",
        label: "criteria",
        iconName: "tune",
        subMenu: [
            {
                id: "criteriaTable",
                label: "criteriaTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.CRITERIA_TABLE,
            },
            {
                id: "criteriaCreate",
                label: "criteriaCreate",
                path: Path.CRITERIA_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allCriteria,
                        url: Path.CATEGORY_TABLE,
                    },
                    {
                        title: Messages.criteriaCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "warehouse",
        label: "warehouse",
        iconName: "precision_manufacturing",
        subMenu: [
            {
                id: "warehouseTable",
                label: "warehouseTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.WAREHOUSE_TABLE,
            },
            {
                id: "warehouseCreate",
                label: "warehouseCreate",
                path: Path.WAREHOUSE_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allWarehouse,
                        url: Path.WAREHOUSE_TABLE,
                    },
                    {
                        title: Messages.warehouseCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "user",
        label: "user",
        iconName: "person",
        subMenu: [
            {
                id: "users",
                label: "userTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.USER_TABLE,
            },
            {
                id: "userCreate",
                label: "userCreate",
                path: Path.USER_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allUsers,
                        url: Path.USER_TABLE,
                    },
                    {
                        title: Messages.userCreate,
                    },
                ],
            },
            {
                id: "roles",
                label: "roles",
                path: Path.USER_ROLES,
                breadcrumb: [
                    {
                        title: Messages.allUsers,
                        accessType: ROLE_ACCESS_TYPE.LIST,
                        url: Path.USER_TABLE,
                    },
                    {
                        title: Messages.userRoles,
                    },
                ],
            },
        ],
    },
    {
        id: "news",
        label: "news",
        iconName: "newspaper",
        subMenu: [
            {
                id: "newsList",
                label: "newsTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.NEWS_TABLE,
            },
            {
                id: "newsCreate",
                label: "newsCreate",
                path: Path.NEWS_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    { title: Messages.allNews, url: Path.NEWS_TABLE },
                    { title: Messages.newsCreate },
                ],
            },
            {
                id: "newsCat",
                label: "newsCategory",
                path: Path.NEWS_CAT_TABLE,
            },
        ],
    },
    {
        id: "blocks",
        label: "blocks",
        iconName: "grid_view",
        subMenu: [
            {
                id: "blockFeature",
                label: "blockFeature",
                path: Path.BLOCK_FEATURE,
            },
            {
                id: "blockBestSelling",
                label: "blockBestSelling",
                path: Path.BLOCK_BEST_SELLING,
            },
        ],
    },
    {
        id: "banner",
        label: "banners",
        iconName: "ad_units",
        subMenu: [
            {
                id: "banners",
                label: "bannerTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.BANNER_TABLE,
            },
            {
                id: "bannerCreate",
                label: "bannerCreate",
                accessType: ROLE_ACCESS_TYPE.CREATE,
                path: Path.BANNER_CREATE,
            },
        ],
    },
    {
        id: "paymentMethod",
        label: "paymentMethod",
        iconName: "payments",
        path: Path.PAYMENT_METHOD_TABLE,
    },
    {
        id: "voucher",
        label: "vouchers",
        iconName: "card_giftcard",
        subMenu: [
            {
                id: "voucherTable",
                label: "voucherTable",
                accessType: ROLE_ACCESS_TYPE.LIST,
                path: Path.VOUCHER_TABLE,
            },
            {
                id: "voucherCreate",
                label: "voucherCreate",
                path: Path.VOUCHER_CREATE,
                accessType: ROLE_ACCESS_TYPE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allVoucher,
                        url: Path.VOUCHER_TABLE,
                    },
                    {
                        title: Messages.voucherCreate,
                    },
                ],
            },
        ],
    },

    {
        id: "media",
        public: true,
        label: "mediaManager",
        iconName: "perm_media",
        path: Path.MEDIA,
        title: "mediaManager",
    },
    {
        id: "smartSupport",
        public: true,
        label: "smartSupport",
        iconName: "support",
        path: Path.SMART_SUPPORT,
        title: "smartSupport",
    },
    {
        id: "faq",
        public: true,
        label: "FAQ",
        iconName: "quiz",
        path: Path.FAQ_MANAGER,
        title: "FAQ",
    },

    {
        id: "staticContent",
        label: "staticContent",
        iconName: "content_paste",
        subMenu: [
            {
                id: STATIC_CONTENT_TYPE.TERM_OF_USE,
                label: "termOfUse",
                path: Path.TERM_OF_USE,
            },
            {
                id: STATIC_CONTENT_TYPE.PRIVACY_POLICY,
                label: "privacyPolicy",
                path: Path.PRIVACY_POLICY,
            },
        ],
    },
];

export const LANGUAGE_DEFAULT = "en";

export const LANGUAGES = [
    {
        label: "Thai",
        id: "th",
        locale: "th",
        code: "TH",
        icon: "/images/languages/language-3.png",
        default: true,
    },
    {
        label: "English",
        id: "en",
        locale: "en",
        code: "EN",
        icon: "/images/languages/language-1.png",
    },
];

export const SITE_SETTINGS = [{ id: "logout", label: "Logout" }];
export const TABLE_ACTIONS = [{ id: "detail", label: "detail" }];

export const YES_NO_OPTIONS = [
    { id: true, label: "yes" },
    { id: false, label: "no" },
];
