import { gql } from "@apollo/client";
import { F_PAGINATION } from "../Fragment";
import { F_NEWS } from "../news/NewsFragment";
import { F_USER } from "../user/UserFragment";

export const F_BANNER_ITEM = gql`
    ${F_USER}
    fragment F_BANNER_ITEM on Banner {
        id
        bannerId
        title
        position
        status
        effectiveDate
        expiryDate
        createBy {
            ...F_USER
        }
        isExpired
    }
`;

export const F_BANNERS = gql`
    ${F_BANNER_ITEM}
    ${F_PAGINATION}
    fragment F_BANNERS on Banners {
        banner {
            ...F_BANNER_ITEM
        }
        pagination {
            ...F_PAGINATION
        }
    }
`;

export const F_BANNER_CONTENT = gql`
    ${F_NEWS}
    fragment F_BANNER_CONTENT on BannerContentTranslator {
        image
        destinationType
        destinationUrl
        news {
            ...F_NEWS
        }
    }
`;

export const F_BANNER = gql`
    ${F_USER}
    ${F_BANNER_CONTENT}
    fragment F_BANNER on Banner {
        id
        title
        position
        status
        effectiveDate
        expiryDate
        remark
        th {
            ...F_BANNER_CONTENT
        }
        en {
            ...F_BANNER_CONTENT
        }
        createBy {
            ...F_USER
        }
        isExpired
    }
`;
