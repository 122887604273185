import { Notifications, Progress } from "d-react-components";
import { find, isEmpty, map, reduce } from "lodash";
import { useContext } from "react";
import RefundRequestAPI from "../../../api/refundrequest/RefundRequestAPI";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { ORDER_STATUS } from "../../../constant/order";
import { REFUND_TYPE, REFUND_TYPES } from "../../../constant/refundRequest";
import { WorksheetStatus } from "../../../constant/worksheet";
import { RefundCreateContext } from "../../../context/refundRequest";
import { IOrder } from "../../../interfaces/order";
import { IWorksheet } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";

const RefundRequestCreateType = () => {
    const { refundForm } = useContext(RefundCreateContext);

    const formValues = refundForm?.values;
    const inputClassName = "mt-3";

    const onLoadOrder = () => {
        if (isEmpty(formValues?.orderNo)) return;
        Progress.show(
            {
                method: RefundRequestAPI.loadOrderRefund,
                params: [formValues?.orderNo],
            },
            (orderDetail: IOrder | any) => {
                if (orderDetail?.status !== ORDER_STATUS.CANCELLED) {
                    Notifications.showError(Messages.onlyRefundCancelledOrder);
                    return;
                }

                if (!orderDetail?.totalPaidAmount) {
                    Notifications.showError(
                        Messages.orderHasNoPaymentConfirmed
                    );
                    return;
                }
                refundForm.setValues({
                    ...formValues,
                    totalPaidAmount: orderDetail?.totalPaidAmount,
                    requestedRefundAmount: orderDetail?.requestedRefundAmount,
                    orderDetail,
                    products: map(orderDetail?.products, (item) => ({
                        id: item.id,
                        productId: item.productId,
                        quantity: 0,
                        maxQuantity: item.quantity,
                        product: item,
                    })),
                });
            }
        );
    };

    const onLoadWorksheet = () => {
        if (isEmpty(formValues?.worksheetNo)) return;
        Progress.show(
            {
                method: RefundRequestAPI.loadRefundWorksheet,
                params: [formValues?.worksheetNo],
            },
            (worksheet: IWorksheet | any) => {
                if (worksheet?.status !== WorksheetStatus.COMPLETED) {
                    Notifications.showError(
                        Messages.onlyRefundCompletedWorksheet
                    );
                    return;
                }

                const totalVoucherAmount = reduce(
                    worksheet.vouchers,
                    (sum, voucher) => {
                        const service = find(
                            worksheet?.services,
                            (item) => item.product.id === voucher.product?.id
                        );

                        return sum + (service?.openingCost ?? 0);
                    },
                    0
                );

                const totalPremiumAmount = reduce(
                    worksheet.serviceApply,
                    (sum, voucher) => {
                        const service = find(
                            worksheet?.services,
                            (item) =>
                                item.product.id === voucher.productServiceId
                        );

                        return sum + (service?.openingCost ?? 0);
                    },
                    0
                );

                refundForm.setValues({
                    ...formValues,
                    worksheet,
                    totalPaidAmount: totalVoucherAmount + totalPremiumAmount,
                    requestedRefundAmount: worksheet?.requestedRefundAmount,
                    products: map(worksheet?.services, (item) => ({
                        id: item.product?.id,
                        productId: item.product?.id,
                        quantity: 0,
                        maxQuantity: item.quantity,
                        product: {
                            ...item.product,
                            name: item?.product?.[Messages.getLanguage()]?.name,
                            thumbnail: item?.product?.thumbnail?.thumbnail,
                        },
                    })),
                });
            }
        );
    };

    return (
        <div className="d-flex flex-column p-3 mt-3 card-container">
            <InputSelectForm
                form={refundForm}
                keyData="refundType"
                dataSource={REFUND_TYPES}
            />
            <InputTextForm
                keyData="worksheetNo"
                form={refundForm}
                hidden={formValues?.refundType !== REFUND_TYPE.WORKSHEET}
                className={inputClassName}
                suffix={Messages.load}
                onClickSuffix={onLoadWorksheet}
            />
            <InputTextForm
                keyData="orderNo"
                form={refundForm}
                hidden={formValues?.refundType !== REFUND_TYPE.ORDER}
                className={inputClassName}
                suffix={Messages.load}
                onClickSuffix={onLoadOrder}
            />
        </div>
    );
};

export default RefundRequestCreateType;
