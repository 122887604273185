import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import UserAPI from "../../../api/user/UserAPI";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import TableAction from "../../../common/TableActions";
import { USER_STATUSES } from "../../../constant/user";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const UserTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const history = useHistory();
    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "companyId",
            render: (companyId, item) => (
                <AppLink
                    to={generatePath(Path.USER_DETAIL, {
                        userId: item.id,
                    })}
                >
                    {companyId}
                </AppLink>
            ),
        },
        {
            title: Messages.avatar,
            dataIndex: "avatar",
            render: (avatar) => (
                <Image src={avatar} className="image-square-small" />
            ),
        },

        {
            title: Messages.name,
            dataIndex: "fullName",
        },
        {
            title: Messages.email,
            dataIndex: "email",
        },

        {
            title: Messages.companyId,
            dataIndex: "companyId",
        },
        {
            title: Messages.role,
            dataIndex: "role",
            render: (role) => role?.name,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={USER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.updatedDate,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (user: IUser) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, user)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return UserAPI.list(searchRef.current, {}, pagingData);
    };

    const onClickTableAction = (actionId: string, user: IUser) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.USER_DETAIL, {
                        userId: user.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        history.push(Path.USER_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allUsers}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.user ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default UserTable;
