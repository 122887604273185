import { map } from "lodash";
import { ICustomer } from "./customer";
import { IOrder } from "./order";
import { IPaymentMethod } from "./paymentmethod";
import { IProduct } from "./product";
import { IUser } from "./user";
import { IWorksheet } from "./worksheet";

export interface IRefundRequestProduct {
    id: string;
    quantity: number;
    product: IProduct;
}

export interface IRefundRequestBank {
    bankName: string;
    accountName: string;
    accountNo: string;
}

export interface IRefundRequestProof {
    remark: string;
    attachments: [string];
    createBy: IUser;
    createdAt: string;
}

export interface IRefundRequest {
    id: string;
    requestNo: string;
    products: [IRefundRequestProduct];
    refundType: string;
    refundFormat: string;
    paymentMethod: IPaymentMethod;
    amount: number;
    remark: string;
    attachments: [string];
    status: string;
    banks: [IRefundRequestBank];
    confirmRefund: IRefundRequestProof;
    rejectRefund: IRefundRequestProof;
    completeRefund: IRefundRequestProof;
    createBy: IUser;
    createdAt: string;
    customer: ICustomer;
    order?: IOrder;
    worksheet?: IWorksheet;
}

export const mapRefundRequestToServer = (request: any) => {
    const {
        orderDetail,
        worksheet,
        products,
        refundType,
        refundFormat,
        paymentMethodId,
        amount,
        remark,
        attachments,
    } = request;
    return {
        orderId: orderDetail?.id,
        worksheetId: worksheet?.id,
        products: map(products, (item) => ({
            id: item.id,
            productId: item.productId,
            quantity: item.quantity,
        })),
        refundType,
        refundFormat,
        paymentMethodId,
        amount,
        remark,
        attachments: map(attachments, (item) => item.url),
    };
};
