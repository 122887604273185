/* eslint-disable radix */
import { Switch } from "antd";
import { InputText, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudInventory = () => {
    const { productForm } = useContext(ProductDetailContext);
    const { SKU, stock, sellingOutOfStock, productType } = productForm.values;

    const hiddenStock =
        productType === PRODUCT_TYPE.SERVICE_PRODUCT ||
        productType === PRODUCT_TYPE.PREMIUM_SERVICE ||
        productType === PRODUCT_TYPE.GROUP_PRODUCT;

    return (
        <ViewCollapse className="mt-3" label={Messages.inventory}>
            <div>
                <div className="row">
                    <InputText
                        className="col-6"
                        placeholder={Messages.sku}
                        value={SKU ?? ""}
                        onChange={(event) =>
                            productForm.setFieldValue("SKU", event.target.value)
                        }
                        label={Messages.sku}
                        required
                        error={productForm.errors.SKU}
                    />
                    <InputText
                        className="col-6"
                        placeholder={Messages.stock}
                        value={`${stock ?? ""}`}
                        // onChange={(event) =>
                        //     productForm.setFieldValue(
                        //         "stock",
                        //         parseInt(event.target.value)
                        //     )
                        // }
                        label={Messages.totalStock}
                        // required
                        error={productForm.errors.stock}
                        hidden={hiddenStock}
                        disabled
                    />
                </div>

                <div className="d-flex mt-3" hidden={hiddenStock}>
                    <Switch
                        checked={sellingOutOfStock}
                        onChange={() =>
                            productForm.setFieldValue(
                                "sellingOutOfStock",
                                !sellingOutOfStock
                            )
                        }
                    />
                    <text className="ml-3">{Messages.continueSelling}</text>
                </div>
            </div>
        </ViewCollapse>
    );
};

export default ProductCrudInventory;
