import {
    AwesomeListComponent,
    Icon,
    Select,
    ViewCollapse,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useContext, useRef } from "react";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductCrudTags = () => {
    const { productForm } = useContext(ProductDetailContext);
    const { tags } = productForm.values;
    const textSearch = useRef();
    const tagListRef = useRef<any>();

    const source = (paging: any) => {
        // const params = {
        //     search: textSearch.current,
        //     page: paging.pageIndex,
        //     per_page: paging.pageSize,
        // };
        // return API.getTagList(params);
        return Promise.resolve([]);
    };

    const isSelectTag = (tag: any) => {
        return !!tags.find((item: any) => item.id === tag.id);
    };

    const transformer = (res: any) => {
        return res?.data?.data?.tags ?? [];
    };

    const onChangeTextSearch = debounce((text) => {
        textSearch.current = text;
        tagListRef.current.refresh();
    }, 1000);

    const onSelectTag = (tag: any) => {
        let results = [];
        if (isSelectTag(tag)) {
            results = tags.filter((item: any) => item.id !== tag.id);
        } else {
            results = [...tags, tag];
        }
        productForm.setFieldValue("tags", results);
    };

    const onDeselectTag = (tagName: any) => {
        const results = tags.filter((item: any) => item.name !== tagName);
        productForm.setFieldValue("tags", results);
    };

    const renderTagItemSelect = (item: any) => {
        return (
            <div
                className="d-flex pt-3 px-3 justify-content-between align-items-center"
                onClick={() => onSelectTag(item)}
            >
                <body>{item?.name}</body>
                {isSelectTag(item) && (
                    <Icon name="done" className="text-primary" size="small" />
                )}
            </div>
        );
    };

    const renderDropDown = () => {
        return (
            <div style={{ height: "10rem" }}>
                <AwesomeListComponent
                    ref={(ref) => {
                        tagListRef.current = ref;
                    }}
                    renderItem={renderTagItemSelect}
                    isPaging
                    transformer={transformer}
                    source={source}
                />
            </div>
        );
    };
    return (
        <ViewCollapse className=" mt-3" label={Messages.tags}>
            <Select
                // onSearch={onChangeTextSearch}
                mode="tags"
                className="w-100"
                placeholder={Messages.tags}
                onChange={(value) => productForm.setFieldValue("tags", value)}
                value={tags}
                // value={map(tags, (item) => item.name)}
                // dropdownRender={renderDropDown}
                onDeselect={onDeselectTag}
            />
        </ViewCollapse>
    );
};

export default ProductCrudTags;
