import React from "react";

interface IStaticContentCrudState {
    contentForm: any;
    contentLang: string;
    setContentLang: any;
}

const staticContentState: IStaticContentCrudState = {
    contentForm: {},
    contentLang: "en",
    setContentLang: () => {},
};

export const StaticContentCrudContext = React.createContext(staticContentState);
