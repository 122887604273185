import {
    AwesomeTableComponent,
    Button,
    Modal,
    TimeUtils,
} from "d-react-components";
import { includes, map } from "lodash";
import React, { useRef, useState } from "react";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import ActivityView from "../../../common/activity/ActivityView";
import Messages from "../../../languages/Messages";
import WarehouseSelect from "../../warehouse/common/WarehouseSelect";

interface IInventoryHistoryModal {
    inventoryId: string;
    open: boolean;
    onClose: () => void;
}

const InventoryHistoryLog = ({ inventoryId, history }: any) => {
    const sourceActivity = (paging: any) =>
        InventoryAPI.activities(inventoryId, history?.id, paging);

    const transformerActivity = (res: any) => {
        const dataList = res?.data?.data?.activities ?? [];
        return dataList;
    };

    const onSendComment = (comment: any, attachments: any[]) =>
        InventoryAPI.addComment(
            inventoryId,
            history?.id,
            history?.stockId,
            comment,
            map(attachments, (item) => item.url)
        );

    return (
        <ActivityView
            className="mt-3"
            onClickSendComment={onSendComment}
            source={sourceActivity}
            transformer={transformerActivity}
        />
    );
};

function InventoryHistoryModal({
    inventoryId,
    open,
    onClose,
}: IInventoryHistoryModal) {
    const activityList = useRef<any>(null);
    const [warehouseId, setWarehouseId] = useState<any>(null);
    const [listExpandedKey, setListExpandedKey] = useState<string[]>([]);

    const isExpanded = (history: any) => {
        return includes(listExpandedKey, history?.id as string);
    };

    const columns = [
        {
            title: Messages.date,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.adjustment,
            dataIndex: "adjustment",
        },
        {
            title: Messages.newQuantity,
            dataIndex: "newQuantity",
        },
        {
            title: Messages.adjustBy,
            dataIndex: "adjustBy",
            render: (adjustBy: any) =>
                `${adjustBy?.firstName} ${adjustBy?.lastName}`,
        },
        {
            title: Messages.remark,
            dataIndex: "remark",
            render: (remark: any, item: any) => (
                <Button variant="outline" onClick={() => onClickShowHide(item)}>
                    {Messages.show}
                </Button>
            ),
        },
    ];

    function onClickShowHide(item: any) {
        if (isExpanded(item)) {
            setListExpandedKey(
                listExpandedKey.filter((key) => key !== item.id)
            );
        } else {
            setListExpandedKey([...listExpandedKey, item.id]);
        }
    }

    const source = (paging: any) =>
        InventoryAPI.historyList(inventoryId, paging, warehouseId);

    const transformer = (res: any) => res?.data?.data?.logs ?? [];

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.history}
            saveText={Messages.ok}
            onSave={onClose}
            size="large"
        >
            <div>
                <WarehouseSelect
                    value={warehouseId}
                    onChange={(id) => {
                        setWarehouseId(id);
                        activityList.current.refresh();
                    }}
                    className="mt-3"
                />
                <div className="divider mt-3" />
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        activityList.current = ref;
                    }}
                    source={source}
                    transformer={transformer}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (history, index, indent, expanded) =>
                            expanded && (
                                <InventoryHistoryLog
                                    history={history}
                                    inventoryId={inventoryId}
                                />
                            ),
                        rowExpandable: () => true,
                        expandIcon: () => false,
                        expandedRowKeys: listExpandedKey,
                    }}
                    expandIconColumnIndex={-1}
                />
            </div>
        </Modal>
    );
}

export default InventoryHistoryModal;
