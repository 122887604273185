import { StringUtils } from "d-react-components";
import { filter, find, forEach, groupBy, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { WorksheetDetailContext } from "../../../../context/worksheet";
import { IProduct } from "../../../../interfaces/product";
import {
    IVoucher,
    IVoucherWorksheetDetail,
} from "../../../../interfaces/voucher";
import WorksheetVoucherHeader from "./content/WorksheetVoucherHeader";
import WorksheetVoucherTable from "./content/WorksheetVoucherTable";
import { WorksheetDetailVoucherContext } from "./context/WorksheetDetailVoucherContext";

export interface IVoucherWorksheetOwnership {
    id: string;
    voucher: IVoucher;
    appliedProduct?: IProduct;
    appliedList?: IVoucher[];
    appliedQty?: number;
    quantity?: number;
    isAnonymous?: boolean;
}

export const getInitOwnershipVouchers = (
    voucherList: IVoucherWorksheetDetail[]
) => {
    const filterList: IVoucherWorksheetDetail[] = filter(
        voucherList,
        (i: any) => !!i?.ownership?.customer?.id
    );
    const res: Array<IVoucherWorksheetOwnership> = [];
    if (filterList && filterList?.length > 0) {
        const grouped = groupBy(filterList, (i) => i?.product?.id);
        forEach(grouped, (items, key) => {
            const groupedItems = groupBy(
                items,
                (i: IVoucherWorksheetDetail) => {
                    return i?.appliedFor;
                }
            );
            forEach(groupedItems, (iItemsGroup, key) => {
                res.push({
                    id: StringUtils.getUniqueID(),
                    appliedList: iItemsGroup,
                    appliedQty: iItemsGroup?.length ?? 0,
                    voucher: iItemsGroup?.[0],
                    appliedProduct: iItemsGroup?.[0]?.appliedProduct,
                });
            });
        });
    }
    return res;
};

export const getInitAnonymousVouchers = (
    voucherList: IVoucherWorksheetDetail[]
) => {
    const filterList = filter(voucherList, (i) => !i?.ownership?.customer?.id);
    const res: Array<IVoucherWorksheetOwnership> = [];
    if (filterList && filterList?.length > 0) {
        forEach(filterList, (item, index) => {
            res.push({
                id: StringUtils.getUniqueID(),
                appliedQty: 1,
                appliedList: [item],
                voucher: item,
                appliedProduct: item?.appliedProduct,
                isAnonymous: true,
            });
        });
    }
    return res;
};

const WorksheetDetailVouchers = () => {
    const { worksheetDetail, setWorksheetDetail } = useContext(
        WorksheetDetailContext
    );
    const {
        booking,
        services = [],
        PIC = [],
        vouchers,
        serviceApply,
    } = worksheetDetail;
    const worksheetProducts = useMemo(() => {
        return map(services, (item) => ({
            ...(item?.product ?? {}),
            quantity: item?.quantity ?? 1,
        }));
    }, [services]);
    const voucherWithServiceApply: IVoucherWorksheetDetail[] = map(
        vouchers,
        (item) => {
            const appliedSer = find(
                serviceApply,
                (i) => i?.voucherCode === item?.code
            );
            let appliedProduct: any = item?.product;
            let appliedFor: any = item?.product?.id;
            if (appliedSer) {
                appliedProduct = find(
                    worksheetProducts,
                    (i) => i?.id === appliedSer?.productServiceId
                );
                appliedFor = appliedSer?.productServiceId;
            }
            return {
                ...item,
                appliedFor,
                appliedProduct,
            };
        }
    );

    const initOwnershipVoucher = getInitOwnershipVouchers(
        voucherWithServiceApply
    );

    const initAnonymousVoucher = getInitAnonymousVouchers(
        voucherWithServiceApply
    );
    const [ownershipVouchers, setOwnershipVouchers] =
        useState<IVoucherWorksheetOwnership[]>(initOwnershipVoucher);
    const [anonymousVouchers, setAnonymousVouchers] =
        useState<IVoucherWorksheetOwnership[]>(initAnonymousVoucher);

    useEffect(() => {
        setOwnershipVouchers(initOwnershipVoucher);
    }, [vouchers]);

    useEffect(() => {
        setAnonymousVouchers(initAnonymousVoucher);
    }, [vouchers]);

    return (
        <WorksheetDetailVoucherContext.Provider
            value={{
                worksheetProducts,
                voucherWithServiceApply,
                ownershipVouchers,
                anonymousVouchers,
            }}
        >
            <div className="flex-column mt-3 card-container p-4">
                <WorksheetVoucherHeader />
                <WorksheetVoucherTable />
            </div>
        </WorksheetDetailVoucherContext.Provider>
    );
};

export default WorksheetDetailVouchers;
