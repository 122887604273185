import React, { useContext } from "react";
import { BookingCreateContext } from "../../../context/booking";
import BookingBilling from "../common/BookingBilling";

const BookingCreateBilling = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const { billing, customer } = bookingForm.values;
    const customerId = customer?.id;

    const onChangeBilling = (value: any) => {
        bookingForm.setFieldValue("billing", value);
    };

    return (
        <BookingBilling
            customerId={customerId}
            billing={billing}
            onChangeBilling={onChangeBilling}
            hiddenCrud={!customerId}
        />
    );
};

export default BookingCreateBilling;
