import { Button, Modal, Progress, Select } from "d-react-components";
import React, { useContext, useState } from "react";
import BookingAPI from "../../../api/booking/BookingAPI";
import { BOOKING_STATUS } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const SourceUpdateModal = ({ open, onClose, defaultValue }: any) => {
    const { sourceBookings, bookingDetail, setBookingDetail } =
        useContext(BookingDetailContext);
    const [sourceId, setSourceId] = useState<boolean>(defaultValue);

    const onClickSave = () =>
        Progress.show(
            {
                method: BookingAPI.updateSource,
                params: [bookingDetail?.id, sourceId],
            },
            (bookingResult) => {
                setBookingDetail(bookingResult);
                onClose();
            }
        );

    return (
        <Modal
            size="small"
            title={Messages.update}
            open={open}
            onClose={onClose}
            onSave={onClickSave}
        >
            <Select
                label={Messages.sourceOfBooking}
                dataSource={sourceBookings}
                onChange={(value) => setSourceId(value)}
                value={sourceId}
            />
        </Modal>
    );
};

const BookingDetailSource = () => {
    const { bookingDetail } = useContext(BookingDetailContext);
    const { source, status } = bookingDetail;
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    return (
        <div className="card-container p-4 ">
            <div className="flex-center justify-content-between">
                <label>{Messages.sourceOfBooking}</label>
                <Button
                    variant="trans"
                    onClick={() => setOpenEdit(true)}
                    hidden={status === BOOKING_STATUS.CANCELLED}
                >
                    {Messages.edit}
                </Button>
            </div>
            <text>{source?.name}</text>
            {openEdit && (
                <SourceUpdateModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    defaultValue={bookingDetail?.source?.id}
                />
            )}
        </div>
    );
};

export default BookingDetailSource;
