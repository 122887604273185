import { Notifications } from "d-react-components";
import { filter, isNaN, parseInt } from "lodash";
import React, { useContext, useMemo } from "react";
import ButtonFileUpload from "../../../common/button/ButtonFileUpload";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { DEFAULT } from "../../../constant/payment";
import {
    REFUND_FORMAT,
    REFUND_FORMATS,
    REFUND_TYPE,
} from "../../../constant/refundRequest";
import { RefundCreateContext } from "../../../context/refundRequest";
import Messages from "../../../languages/Messages";

const RefundRequestCreateFormat = () => {
    const { refundForm, paymentMethods } = useContext(RefundCreateContext);

    const formValues = refundForm?.values;
    const classNameInput = "mt-3";

    return (
        <div className="d-flex flex-column p-3 mt-3 card-container">
            <InputSelectForm
                form={refundForm}
                keyData="refundFormat"
                dataSource={REFUND_FORMATS}
                className={classNameInput}
                onChange={(value: any) => {
                    if (
                        value === REFUND_FORMAT.VOUCHER &&
                        formValues?.refundType === REFUND_TYPE.ORDER
                    ) {
                        Notifications.showError(
                            Messages.serviceYetUsedCanNotRefundVoucher
                        );
                        return;
                    }
                    refundForm.setFieldValue("refundFormat", value);
                }}
            />

            <InputSelectForm
                hidden={formValues?.refundFormat === REFUND_FORMAT.VOUCHER}
                label={Messages.paymentMethod}
                form={refundForm}
                keyData="paymentMethodId"
                dataSource={filter(
                    paymentMethods,
                    (item) => item.type !== DEFAULT
                )}
                getLabel={(item: any) => item?.[Messages.getLanguage()]?.title}
                className={classNameInput}
            />
            <InputTextForm
                hidden={formValues?.refundFormat === REFUND_FORMAT.VOUCHER}
                form={refundForm}
                keyData="amount"
                className={classNameInput}
                value={(formValues.amount ?? 0).toLocaleString()}
                onChange={(event: any) => {
                    const result = event.target.value.replace(/\D/g, "");
                    const text = parseInt(result ?? "0");
                    const value = isNaN(text) ? 0 : text;
                    refundForm.setFieldValue("amount", value);
                }}
            />
            <div className={classNameInput}>
                <label>{Messages.attachments}</label>
                <ButtonFileUpload
                    onChange={(value: any) =>
                        refundForm.setFieldValue("attachments", value)
                    }
                    maxFiles={5}
                    className="mt-3"
                />
            </div>

            <InputTextForm
                form={refundForm}
                keyData="remark"
                className={classNameInput}
                multiple
            />
        </div>
    );
};

export default RefundRequestCreateFormat;
