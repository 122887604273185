import { gql } from "@apollo/client";
import { mapNewsFromSer } from "../../interfaces/news";
import API from "../API";
import {
    F_CATEGORY_NEWS_ITEM,
    F_CATEGORY_NEWS_LIST,
    F_NEWS,
    F_NEWS_LIST,
} from "./NewsFragment";

const detailConvertor = {
    fromServer: (res: any) => mapNewsFromSer(res?.data?.news),
};

export default {
    list: (q: string, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.query({
            query: gql`
                ${F_NEWS_LIST}
                query newsList($q: String, $page: Int, $limit: Int) {
                    data: newsList(q: $q, page: $page, limit: $limit) {
                        ...F_NEWS_LIST
                    }
                }
            `,
            variables: { q, page: paging.pageIndex, limit: paging.pageSize },
            fetchPolicy: "no-cache",
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_NEWS}
                query news($id: ID!) {
                    news(id: $id) {
                        ...F_NEWS
                    }
                }
            `,
            variables: { id },
        }),

    detailMetaData: () =>
        API.query({
            query: gql`
                ${F_CATEGORY_NEWS_LIST}
                query detailMetaData {
                    categoryNewsList(
                        page: 1
                        limit: 100
                        filter: { status: ACTIVE }
                    ) {
                        ...F_CATEGORY_NEWS_LIST
                    }
                }
            `,
        }),

    create: (input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_NEWS}
                mutation createNews($input: NewsInput) {
                    news: createNews(input: $input) {
                        ...F_NEWS
                    }
                }
            `,
            variables: { input },
        }),

    update: (id: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_NEWS}
                mutation updateNews($id: ID!, $input: NewsInput) {
                    news: updateNews(id: $id, input: $input) {
                        ...F_NEWS
                    }
                }
            `,
            variables: { input, id },
        }),

    categoryList: (q: string, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.query({
            query: gql`
                ${F_CATEGORY_NEWS_LIST}
                query categoryNewsList($q: String, $page: Int, $limit: Int) {
                    data: categoryNewsList(q: $q, page: $page, limit: $limit) {
                        ...F_CATEGORY_NEWS_LIST
                    }
                }
            `,
            variables: { q, page: paging.pageIndex, limit: paging.pageSize },
            fetchPolicy: "no-cache",
        }),

    categoryCreate: (input: any) =>
        API.mutate({
            mutation: gql`
                ${F_CATEGORY_NEWS_ITEM}
                mutation createCategoryNews($input: CategoryNewsInput) {
                    createCategoryNews(input: $input) {
                        ...F_CATEGORY_NEWS_ITEM
                    }
                }
            `,
            variables: { input },
        }),

    categoryUpdate: (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                ${F_CATEGORY_NEWS_ITEM}
                mutation updateCategoryNews(
                    $id: ID!
                    $input: CategoryNewsInput
                ) {
                    updateCategoryNews(input: $input, id: $id) {
                        ...F_CATEGORY_NEWS_ITEM
                    }
                }
            `,
            variables: { id, input },
        }),
};
