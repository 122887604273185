import { gql } from "@apollo/client";
import { mapOrderFromServer } from "../../interfaces/order";
import API from "../API";
import {
    F_REFUND_ORDER,
    F_REFUND_REQUEST,
    F_REFUND_REQUESTS,
    F_REFUND_WORKSHEET,
} from "./RefundRequestFragment";

const detailConvertor = {
    fromServer: (res: any) => res?.data?.refundRequest,
};

export default {
    list: (q: string, paging = { pageIndex: 1, pageSize: 10 }) =>
        API.query({
            query: gql`
                ${F_REFUND_REQUESTS}
                query refundRequests($q: String, $page: Int, $limit: Int) {
                    data: refundRequests(q: $q, page: $page, limit: $limit) {
                        ...F_REFUND_REQUESTS
                    }
                }
            `,
            variables: { q, page: paging.pageIndex, limit: paging.pageSize },
            fetchPolicy: "no-cache",
        }),

    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_REFUND_REQUEST}
                query refundRequest($id: ID!) {
                    refundRequest(id: $id) {
                        ...F_REFUND_REQUEST
                    }
                }
            `,
            variables: { id },
        }),

    loadOrderRefund: (orderNo: string) =>
        API.withConverter({
            fromServer: (res: any) => mapOrderFromServer(res?.data?.order),
        }).query({
            query: gql`
                ${F_REFUND_ORDER}
                query orderByNo($orderNo: String) {
                    order: orderByNo(orderNo: $orderNo) {
                        ...F_REFUND_ORDER
                    }
                }
            `,
            variables: { orderNo },
            fetchPolicy: "no-cache",
        }),

    loadRefundWorksheet: (worksheetNo: string) =>
        API.withConverter({
            fromServer: (res: any) => res?.data?.worksheet,
        }).query({
            query: gql`
                ${F_REFUND_WORKSHEET}
                query worksheetByNo($worksheetNo: String!) {
                    worksheet: worksheetByNo(worksheetNo: $worksheetNo) {
                        ...F_REFUND_WORKSHEET
                    }
                }
            `,
            variables: { worksheetNo },
            fetchPolicy: "no-cache",
        }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createRefundRequest?.id,
        }).mutate({
            mutation: gql`
                mutation createRefundRequest($input: RefundRequestInput) {
                    createRefundRequest(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    addRefundRequestBank: (refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_REFUND_REQUEST}
                mutation addRefundRequestBank(
                    $refundId: ID!
                    $input: RefundRequestBankInput
                ) {
                    refundRequest: addRefundRequestBank(
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_REFUND_REQUEST
                    }
                }
            `,
            variables: { refundId, input },
        }),

    confirmRefund: (refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_REFUND_REQUEST}
                mutation confirmRefundRequest(
                    $refundId: ID!
                    $input: UpdateRefundStatusInput!
                ) {
                    refundRequest: confirmRefundRequest(
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_REFUND_REQUEST
                    }
                }
            `,
            variables: { refundId, input },
        }),

    rejectRefund: (refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_REFUND_REQUEST}
                mutation rejectRefundRequest(
                    $refundId: ID!
                    $input: UpdateRefundStatusInput!
                ) {
                    refundRequest: rejectRefundRequest(
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_REFUND_REQUEST
                    }
                }
            `,
            variables: { refundId, input },
        }),

    completeRefund: (refundId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_REFUND_REQUEST}
                mutation completeRefundRequest(
                    $refundId: ID!
                    $input: UpdateRefundStatusInput!
                ) {
                    refundRequest: completeRefundRequest(
                        refundId: $refundId
                        input: $input
                    ) {
                        ...F_REFUND_REQUEST
                    }
                }
            `,
            variables: { refundId, input },
        }),

    resetRefund: (refundId: string) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_REFUND_REQUEST}
                mutation resetRefundRequest($refundId: ID!) {
                    refundRequest: resetRefundRequest(refundId: $refundId) {
                        ...F_REFUND_REQUEST
                    }
                }
            `,
            variables: { refundId },
        }),
};
