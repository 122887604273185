import { CheckboxGroup } from "d-react-components";
import { filter, includes } from "lodash";
import React, { useEffect, useState } from "react";
import PaymentMethodAPI from "../../../api/paymentmethod/PaymentMethodAPI";
import { IPaymentMethod } from "../../../interfaces/paymentmethod";
import Messages from "../../../languages/Messages";

interface IPaymentMethodsCheckboxGroup {
    value: any[];
    onChange: (value: any) => void;
}

const PaymentMethodsCheckboxGroup = ({
    value,
    onChange,
    ...props
}: IPaymentMethodsCheckboxGroup) => {
    const [paymentMethodList, setPaymentMethodList] = useState<
        IPaymentMethod[]
    >([]);

    useEffect(() => {
        loadPaymentMethods();
    }, []);

    const loadPaymentMethods = () => {
        PaymentMethodAPI.list(true).then((res) => {
            const paymentMethods = res?.data?.data ?? [];
            setPaymentMethodList(paymentMethods);
        });
    };

    const onChangeCheckbox = (values: string[]) => {
        const paymentSelected = filter(paymentMethodList, (item) =>
            includes(values, item?.id)
        );
        onChange(paymentSelected);
    };

    return (
        <CheckboxGroup
            numberOfColumns="2"
            dataSource={paymentMethodList}
            label={Messages.paymentMethod}
            className="mt-3 col-12"
            value={value}
            getLabel={(item) => item[Messages.getLanguage()]?.title}
            onChange={onChangeCheckbox}
            {...props}
        />
    );
};

export default PaymentMethodsCheckboxGroup;
