import classNames from "classnames";
import { Select, InputText as Input, ObjectUtils } from "d-react-components";
import { debounce, isEmpty, join, map } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import AddressAPI from "../../api/address/AddressAPI";
import API from "../../api/API";
import { TYPE_OF_PLACES } from "../../constant/customer";
import { AppStateContext } from "../../context/app";
import {
    IAddressFormShow,
    ICountry,
    IDistrict,
    IProvince,
    ISubdistrict,
} from "../../interfaces/address";
import Messages from "../../languages/Messages";
import AddressMapSelect from "./AddressMapSelect";

interface IAddressFormInput {
    formDataValue: any;
    formDataError: any;
    show?: IAddressFormShow;
    onChange: any;
}

const DEFAULT_SHOW = {
    typeOfPlace: true,
    remark: false,
    map: true,
    country: true,
    address: true,
};

const AddressFormInput = ({
    formDataValue,
    formDataError,
    show = {},
    onChange,
}: IAddressFormInput) => {
    const showField = { ...DEFAULT_SHOW, ...show };
    const { provinceList, countryList } = useContext(AppStateContext);
    const [listCity, setListCity] = useState([]);
    const [listSubdistrict, setListSubdistrict] = useState([]);
    const [listPostcode, setListPostcode] = useState<string[]>([]);

    const [location, setLocation] = useState<any>({
        latitude: formDataValue.latitude,
        longitude: formDataValue.longitude,
    });

    useEffect(() => {
        if (showField.map && location) {
            onChangeValue({ ...location });
        }
    }, [location]);

    useEffect(() => {
        if (!formDataValue?.province) {
            return;
        }
        AddressAPI.districtList(formDataValue?.province?.id).then(setListCity);
    }, [formDataValue?.province]);

    useEffect(() => {
        if (!formDataValue?.district) {
            return;
        }
        AddressAPI.subDistrictList(formDataValue?.district?.id).then(
            setListSubdistrict
        );
    }, [formDataValue?.district]);

    useEffect(() => {
        if (!formDataValue?.subDistrict || listSubdistrict.length === 0) {
            return;
        }
        const subDistrict: any = listSubdistrict.find(
            (item: ISubdistrict) => item.id === formDataValue?.subDistrict?.id
        );
        setListPostcode(subDistrict?.postcode ?? []);
    }, [formDataValue?.subDistrict, listSubdistrict]);

    const isFirstTime = useRef(true);
    useEffect(() => {
        if (
            (isFirstTime.current &&
                formDataValue?.latitude &&
                formDataValue?.longitude) ||
            !showField.map
        ) {
            isFirstTime.current = false;
            return;
        }
        loadLocation();
    }, [
        formDataValue?.province?.id,
        formDataValue?.district?.id,
        formDataValue?.subDistrict?.id,
    ]);

    const loadLocation = debounce(() => {
        const addressList = [
            formDataValue?.subDistrict?.name,
            formDataValue?.district?.name,
            formDataValue?.province?.name,
        ].filter((item) => !!item);
        const fulAddress = join(addressList, ",");
        if (isEmpty(fulAddress)) {
            return;
        }
        API.getLocationFromAddress(fulAddress).then((res) => {
            const locationResult = res?.data?.results?.[0]?.geometry?.location;
            if (locationResult) {
                setLocation({
                    latitude: locationResult.lat,
                    longitude: locationResult.lng,
                });
            }
        });
    }, 1000);

    const onChangeValue = (values = {}) => {
        onChange({ ...formDataValue, ...values });
    };

    const onChangeProvince = (id: string) => {
        const provinceValue = ObjectUtils.findItemFromId(provinceList, id);
        onChangeValue({
            province: provinceValue,
            district: null,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeDistrict = (id: string) => {
        const districtValue = ObjectUtils.findItemFromId(listCity, id);
        onChangeValue({
            ...formDataValue,
            district: districtValue,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeSubDistrict = (id: string) => {
        const subDistrictValue = ObjectUtils.findItemFromId(
            listSubdistrict,
            id
        );
        onChangeValue({
            ...formDataValue,
            subDistrict: subDistrictValue,
            postcode: null,
        });
    };

    const renderMapSelect = () => {
        if (!location?.latitude || !location?.longitude) {
            return <div />;
        }
        return (
            <div className="col-12 mt-3">
                <AddressMapSelect
                    location={location}
                    onChange={(location) => {
                        setLocation(location);
                    }}
                />
            </div>
        );
    };

    const classNameInputField = classNames("col-6 mt-3");

    return (
        <>
            <div className={classNameInputField} hidden={!showField.country}>
                <Select
                    label={Messages.country}
                    placeholder={Messages.country}
                    dataSource={countryList}
                    onChange={(value: any) => onChangeValue({ country: value })}
                    value={formDataValue?.country ?? "thai"}
                    error={formDataError?.country}
                    getLabel={(item: ICountry) => item.name}
                    disabled
                    required
                />
            </div>
            <div className={classNameInputField}>
                <Select
                    label={Messages.province}
                    placeholder={Messages.province}
                    dataSource={provinceList}
                    onChange={onChangeProvince}
                    value={formDataValue?.province?.id}
                    error={formDataError?.province?.id}
                    getLabel={(item: IProvince) => item.name}
                    required
                />
            </div>

            <div className={classNameInputField}>
                <Select
                    label={Messages.district}
                    placeholder={Messages.district}
                    dataSource={listCity}
                    onChange={onChangeDistrict}
                    value={formDataValue?.district?.id}
                    error={formDataError?.district?.id}
                    disabled={listCity.length === 0}
                    getLabel={(item: IDistrict) => item.name}
                    required
                />
            </div>
            <div className={classNameInputField}>
                <Select
                    label={Messages.subDistrict}
                    placeholder={Messages.subDistrict}
                    dataSource={listSubdistrict}
                    onChange={onChangeSubDistrict}
                    value={formDataValue?.subDistrict?.id}
                    error={formDataError?.subDistrict?.id}
                    disabled={listSubdistrict.length === 0}
                    getLabel={(item: ISubdistrict) => item.name}
                    required
                />
            </div>
            <div className={classNameInputField}>
                <Select
                    label={Messages.postcode}
                    placeholder={Messages.postcode}
                    dataSource={listPostcode}
                    onChange={(value: any) =>
                        onChangeValue({ postcode: value })
                    }
                    value={formDataValue?.postcode}
                    error={formDataError?.postcode}
                    disabled={listPostcode.length === 0}
                    getLabel={(item: string) => item}
                    getValue={(item: string) => item}
                    required
                />
            </div>

            <div className="col-12 mt-3" hidden={!showField.address}>
                <Input
                    label={Messages.address}
                    placeholder={Messages.address}
                    onChange={(event: any) =>
                        onChangeValue({ address: event?.target.value })
                    }
                    value={formDataValue?.address}
                    error={formDataError?.address}
                    required
                />
            </div>
            <div
                className={classNameInputField}
                hidden={!showField.typeOfPlace}
            >
                <Select
                    label={Messages.typeOfPlace}
                    placeholder={Messages.typeOfPlace}
                    dataSource={map(TYPE_OF_PLACES, (item) => ({
                        ...item,
                        label: (Messages as any)[item.label],
                    }))}
                    onChange={(value: any) =>
                        onChangeValue({ typeOfPlace: value })
                    }
                    value={formDataValue?.typeOfPlace}
                    error={formDataError?.typeOfPlace}
                    required
                />
            </div>
            <div
                className={classNameInputField}
                hidden={!showField.typeOfPlace}
            >
                <Input
                    label={Messages.nameOfPlace}
                    placeholder={Messages.nameOfPlace}
                    onChange={(event: any) =>
                        onChangeValue({ nameOfPlace: event?.target.value })
                    }
                    value={formDataValue?.nameOfPlace}
                    error={formDataError?.nameOfPlace}
                />
            </div>
            {showField.map && renderMapSelect()}
            <div className="col-12" hidden={!showField.remark}>
                <Input
                    label={Messages.remark}
                    onChange={(event: any) =>
                        onChangeValue({ remark: event?.target.value })
                    }
                    value={formDataValue?.remark}
                    error={formDataError?.remark}
                    multiple
                />
            </div>
        </>
    );
};

export default AddressFormInput;
