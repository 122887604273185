/* eslint-disable no-await-in-loop */
import { Progress } from "antd";
import { Modal, TimeUtils } from "d-react-components";
import { useFormik } from "formik";
import { ceil, map } from "lodash";
import { useState } from "react";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import { VoucherExportSchema } from "../../../formschema/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import FileUtils from "../../../utils/FileUtils";
import VoucherFilterForm from "./VoucherFilterForm";

interface IVoucherTableExportModal {
    open: boolean;
    onClose: () => void;
}

function VoucherExportModal({ open, onClose }: IVoucherTableExportModal) {
    const [loadingExport, setLoadingExport] = useState(false);
    const [percentageExport, setPercentageExport] = useState(0);

    const exportForm = useFormik<any>({
        initialValues: {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VoucherExportSchema,

        onSubmit: (values: any) => {
            onClickExport(values);
        },
    });

    const onClickExport = async (filterData: any) => {
        setLoadingExport(true);

        let remainPage = true;
        let voucherListResult: any[] = [];
        let pageIndex = 1;
        const pageSize = 20;
        while (remainPage) {
            const response = await VoucherAPI.export(
                "",
                { pageIndex, pageSize },
                filterData
            );
            const voucherList = response?.data?.data?.voucher ?? [];
            const paginationData = response?.data?.data?.pagination ?? {};
            const { currentPage, totalDocs } = paginationData;
            const totalPage = ceil(totalDocs / pageSize);
            voucherListResult = voucherListResult.concat(voucherList);
            if (currentPage === totalPage) {
                remainPage = false;
                setPercentageExport(100);
            } else {
                pageIndex += 1;
                setPercentageExport(
                    Math.round((currentPage / totalPage) * 100)
                );
            }
        }

        const fileDataVoucher = getVoucherFileData(voucherListResult);

        const from = TimeUtils.toDate(filterData?.from);
        const to = TimeUtils.toDate(filterData?.to);
        const fileName = `VoucherExport_${filterData?.exportType}_${from}_${to}`;
        FileUtils.exportToCSV(fileDataVoucher, fileName);
        setLoadingExport(false);
        onClose();
    };

    const getVoucherFileData = (voucherList: IVoucher[]) =>
        map(voucherList, (voucher) => ({
            code: voucher.code,
            Product: (voucher.product as any)?.[Messages.getLanguage()]?.name,
            SKU: voucher?.product?.SKU,
            "Effective date": TimeUtils.toDateTime(voucher?.effectiveDate),
            "Expiry date": voucher?.expiryDate
                ? TimeUtils.toDateTime(voucher?.expiryDate)
                : Messages.noExpireDate,
            "Created at": TimeUtils.toDateTime(voucher?.createdAt),
            "Created by": voucher?.createBy?.companyId ?? "",
            "Ownership status": voucher?.ownership?.status,
            Customer: voucher?.ownership?.customer?.nickName ?? "N/A",
            "Usage status": voucher?.usageState?.status,
            "Used at": voucher?.usageState?.usedAt
                ? TimeUtils.toDateTime(voucher?.usageState?.usedAt)
                : "Not yet used",
            "Worksheet Ref.":
                voucher?.usageState?.worksheet?.worksheetNo ?? "N/A",
        }));

    const renderMainContent = () => {
        if (loadingExport) return <Progress percent={percentageExport} />;
        return <VoucherFilterForm filterForm={exportForm} />;
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.export}
            onSave={() => exportForm.handleSubmit()}
            saveText={Messages.export}
            size="medium"
            sideText={Messages.clearAll}
            onSideClick={() => exportForm.setValues({})}
        >
            {renderMainContent()}
        </Modal>
    );
}

export default VoucherExportModal;
