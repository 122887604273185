import { gql } from "@apollo/client";
import API from "../API";
import { F_PAYMENT_METHOD } from "./PaymentMethodFragment";

export default {
    list: (status?: boolean) =>
        API.query({
            query: gql`
                ${F_PAYMENT_METHOD}
                query paymentMethods($status: Boolean) {
                    data: paymentMethods(status: $status) {
                        ...F_PAYMENT_METHOD
                    }
                }
            `,
            variables: {
                status,
            },
            fetchPolicy: "no-cache",
        }),

    create: (input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.createPaymentMethod?.id,
        }).mutate({
            mutation: gql`
                mutation createPaymentMethod($input: PaymentMethodInput!) {
                    createPaymentMethod(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    detail: (id: string) =>
        API.withConverter({
            fromServer: (res) => res?.data?.paymentMethod,
        }).query({
            query: gql`
                ${F_PAYMENT_METHOD}
                query paymentMethod($id: ID!) {
                    paymentMethod(id: $id) {
                        ...F_PAYMENT_METHOD
                    }
                }
            `,
            variables: { id },
        }),

    update: (id: string, input: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.updatePaymentMethod,
        }).mutate({
            mutation: gql`
                ${F_PAYMENT_METHOD}
                mutation updatePaymentMethod(
                    $id: ID!
                    $input: PaymentMethodInput
                ) {
                    updatePaymentMethod(id: $id, input: $input) {
                        ...F_PAYMENT_METHOD
                    }
                }
            `,
            variables: { id, input },
        }),
    quickUpdate: (id: string, input: any) =>
        API.mutate({
            mutation: gql`
                mutation quickUpdatePaymentMethod(
                    $id: ID!
                    $status: Boolean
                    $priority: Int
                ) {
                    quickUpdatePaymentMethod(
                        id: $id
                        status: $status
                        priority: $priority
                    ) {
                        id
                    }
                }
            `,
            variables: { id, status: input.status, priority: input.priority },
        }),
};
