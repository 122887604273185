import { IUser } from "./user";

export enum FaqStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export interface ICategoryFaq {
    id: string;
    name?: string;
    tree?: string[];
    order?: number;
    createBy?: IUser;
    parentId?: string;
}

export interface Faq {
    value?: string;
    id?: string;
    articleId?: string;
    description?: string;
    status?: FaqStatus;
    category?: ICategoryFaq;
    title?: string;
    createBy?: IUser;
}

export const FAQ_STATUS = [
    {
        id: FaqStatus.ACTIVE,
        label: "active",
        color: "#33963D",
        backgroundColor: "rgba(71, 223, 68, 0.15)",
    },
    {
        id: FaqStatus.INACTIVE,
        label: "inActive",
        color: "#FAC256",
        backgroundColor: "rgba(255, 244, 227, 1)",
    },
];
