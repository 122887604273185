import { StringUtils } from "d-react-components";
import { isEmpty, map } from "lodash";
import Messages from "../languages/Messages";
import { ICategory } from "./category";
import { IUser } from "./user";

export interface IProductGeneralInfo {
    name: string;
    description: string;
    shortDescription: string;
}

export interface IProductActivity {
    createBy: IUser;
    message: string;
    createdAt: string;
}

export interface IProduct {
    id: string;
    th: IProductGeneralInfo;
    en: IProductGeneralInfo;
    productId: string;
    gallery: any;
    regularPrice: number;
    salePrice: number;
    SKU: string;
    stock: number;
    activity: IProductActivity;
    visibility: boolean;
    customerVisibility: boolean;
    service: ICategory;
    categories: any[];
    brand: string;
    weight: number;
    tags: string[];
    thumbnail: {
        thumbnail: string;
    };
    quantity?: number;
    allAvailableCW: number;
    allOnHoldCW: number;
    allOnHoldDW: number;
    allAvailableDW: number;
    groupProducts: IProduct[];
    premiumService: IProduct[];
    productType: string;
    numberOfSeat?: number;
}

export interface IProductOrderCreate extends IProduct {
    quantity?: number;
    maxQuantity: number;
    worksheetId?: string;
    worksheetNo?: any;
    isProductWorksheet?: boolean;
    isCustomProduct?: boolean;
}

export const mapProductFromSer = (productSer: any) => {
    const { gallery, category, service, ...rest } = productSer;

    const galleryResult = map(gallery, (item) => {
        const mapItem: any = {
            id: StringUtils.getUniqueID(),
            roles: item.type,
        };
        if (item.video) {
            mapItem.videoUrl = item?.video;
            mapItem.thumbnail = item?.thumbnail;
            mapItem.isVideo = true;
        } else {
            mapItem.url = item?.thumbnail;
        }
        return mapItem;
    });

    return {
        ...rest,
        gallery: galleryResult,
        categoryId: map(category, (item) => item?.id),
        serviceCategoryId: service?.id,
    };
};

export const mapProductToSer = (product: any) => {
    const {
        id,
        productId,
        activity,
        thumbnail,
        gallery,
        weight,
        premiumService,
        groupProducts,
        numberOfSeat,
        ...rest
    } = product;

    const galleryResult = map(gallery, (item) => {
        const mapItem: any = {
            type: item.roles,
        };
        if (item.isVideo) {
            mapItem.video = item?.videoUrl;
            mapItem.thumbnail = item?.thumbnail;
        } else {
            mapItem.thumbnail = item?.url;
        }
        return mapItem;
    });

    return {
        ...rest,
        weight: parseInt(weight, 10),
        numberOfSeat: parseInt(numberOfSeat, 10),
        gallery: galleryResult,
        premiumService: map(premiumService, (item) => ({
            productId: item.id,
            // voucherConversion: item.voucherConversion ?? 1,
        })),
        productGroup: map(groupProducts, (item) => ({ productId: item.id })),
    };
};

export const getProductLocalizeInfo = (
    product: IProduct,
    key: keyof IProductGeneralInfo
) => {
    const lang = Messages.getLanguage();
    return product?.[lang as keyof IProduct]?.[key] ?? "N/A";
};
