import React from "react";
import { ICountry, IProvince } from "../interfaces/address";

export interface ISiteConfig {
    breadcrumb?: any[];
    label?: string;
}

export interface IAppState {
    me: any;
    provinceList: IProvince[];
    countryList: ICountry[];
    siteConfig: ISiteConfig;
    openUserAuthOtp: boolean;
    setOpenUserAuthOtp: (values: any) => any;
    setSiteConfig: (values: any) => any;
}

export const appStateDefault: IAppState = {
    me: {},
    provinceList: [],
    countryList: [],
    siteConfig: {} as any,
    setSiteConfig: () => {},
    openUserAuthOtp: false,
    setOpenUserAuthOtp: () => {},
};

export const AppStateContext = React.createContext(appStateDefault);
