import { InputText, ViewCollapse } from "d-react-components";
import React, { useContext } from "react";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";
import ProductLanguageSelect from "./ProductLanguageSelect";
// third-party

// application

const ProductCrudNameDesc = () => {
    const { productForm, productLang } = useContext(ProductDetailContext);
    const { name, description, shortDescription } =
        productForm.values[productLang];
    const { errors } = productForm;

    return (
        <div className="d-flex flex-column">
            <ProductLanguageSelect />
            <div className="card-container p-3 mt-3">
                <InputText
                    label={Messages.productName}
                    onChange={(event) =>
                        productForm.setFieldValue(
                            `${productLang}.name`,
                            event.target.value
                        )
                    }
                    value={name ?? ""}
                    required
                    error={errors?.th?.name || errors?.en?.name}
                />
            </div>

            <div className="mt-3">
                <ProductLanguageSelect />
                <ViewCollapse
                    label={Messages.description}
                    className="card-container mt-3"
                    required
                >
                    <RichTextTiny
                        value={description ?? ""}
                        onChange={(value: any) =>
                            productForm.setFieldValue(
                                `${productLang}.description`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.productFullDescription}
                        size="large"
                        error={
                            errors?.th?.description || errors?.en?.description
                        }
                    />
                </ViewCollapse>
            </div>
            <div className="mt-3">
                <ProductLanguageSelect />
                <ViewCollapse
                    label={Messages.shortDescription}
                    className="card-container mt-3"
                    required
                >
                    <RichTextTiny
                        value={shortDescription ?? ""}
                        onChange={(value: any) =>
                            productForm.setFieldValue(
                                `${productLang}.shortDescription`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.productShortDes}
                        size="large"
                        error={
                            errors?.th?.shortDescription ||
                            errors?.en?.shortDescription
                        }
                    />
                </ViewCollapse>
            </div>
        </div>
    );
};

export default ProductCrudNameDesc;
