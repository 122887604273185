export const PERMISSION_TYPE = {
    ALL: "ALL",
    CUSTOM: "CUSTOM",
};

export const PERMISSION_TYPES = [
    {
        id: PERMISSION_TYPE.ALL,
        label: "All",
    },
    {
        id: PERMISSION_TYPE.CUSTOM,
        label: "Custom",
    },
];

export const ROLE_ACCESS_TYPE = {
    LIST: "list",
    EXPORT: "export",
    DETAIL: "detail",
    CREATE: "create",
    EDIT: "edit",
    DELETE: "delete",
    MENU: "menu",
};

export const ROLE_ACCESS_TYPES = [
    { id: "list", label: "list" },
    { id: "export", label: "export" },
    { id: "detail", label: "detail" },
    { id: "create", label: "create" },
    { id: "edit", label: "edit" },
    { id: "delete", label: "delete" },
    { id: "menu", label: "menu" },
];

export const ORDER_ACCESS_TYPE = {
    ...ROLE_ACCESS_TYPE,
    RESET_PAYMENT: "resetPayment",
    CANCEL_ORDER: "cancelOrder",
};

export const ORDER_ACCESS_TYPES = [
    ...ROLE_ACCESS_TYPES,
    { id: "resetPayment", label: "resetPayment" },
    { id: "cancelOrder", label: "cancelOrder" },
];

export const WORKSHEET_ASSIGNED_ACCESS_TYPES = [
    { id: "list", label: "list" },
    { id: "menu", label: "menu" },
];

export const BOOKING_ASSIGNED_ACCESS_TYPES = [
    { id: "list", label: "list" },
    { id: "menu", label: "menu" },
];

export const OAUTH_CODE_ACCESS_TYPES = [
    { id: "grantVoucherOwnership", label: "approveAddingVoucherOwnership" },
    { id: "menu", label: "menu" },
];

export const PERMISSION_MODULE = {
    BOOKING_ASSIGNED: "bookingAssigned",
    WORKSHEET_ASSIGNED: "worksheetAssigned",
    BOOKING: "booking",
    WORKSHEET: "worksheet",
    VOUCHER: "voucher",
    CUSTOMER: "customer",
    PRODUCT: "product",
    INVENTORY: "inventory",
    ORDER: "order",
    CATEGORY_SERVICE: "categoryService",
    CRITERIA: "criteria",
    WAREHOUSE: "warehouse",
    USER: "user",
    NEWS: "news",
    BLOCK: "block",
    BANNER: "banner",
    SMART_SUPPORT: "smartSupport",
    FAQ: "faq",
    PAYMENT_METHOD: "paymentMethod",
    MEDIA: "media",
    ROLE: "role",
    STATIC_CONTENT: "staticContent",
    REFUND_REQUEST: "refundRequest",
    OAUTH_CODE: "oauthCode",
};

export const PERMISSION_MODULES: {
    key: string;
    label: string;
    accessTypes?: any[];
}[] = [
    {
        key: PERMISSION_MODULE.BOOKING,
        label: "bookings",
    },
    {
        key: PERMISSION_MODULE.BOOKING_ASSIGNED,
        label: "bookingAssigned",
        accessTypes: BOOKING_ASSIGNED_ACCESS_TYPES,
    },
    {
        key: PERMISSION_MODULE.WORKSHEET,
        label: "worksheet",
    },
    {
        key: PERMISSION_MODULE.WORKSHEET_ASSIGNED,
        label: "worksheetAssigned",
        accessTypes: WORKSHEET_ASSIGNED_ACCESS_TYPES,
    },
    {
        key: PERMISSION_MODULE.ORDER,
        label: "order",
        accessTypes: ORDER_ACCESS_TYPES,
    },
    {
        key: PERMISSION_MODULE.PRODUCT,
        label: "products",
    },
    {
        key: PERMISSION_MODULE.INVENTORY,
        label: "inventory",
    },
    {
        key: PERMISSION_MODULE.CUSTOMER,
        label: "customer",
    },
    {
        key: PERMISSION_MODULE.CATEGORY_SERVICE,
        label: "serviceCategory",
    },
    {
        key: PERMISSION_MODULE.CRITERIA,
        label: "criteria",
    },
    {
        key: PERMISSION_MODULE.WAREHOUSE,
        label: "warehouse",
    },
    {
        key: PERMISSION_MODULE.USER,
        label: "user",
    },
    {
        key: PERMISSION_MODULE.ROLE,
        label: "roles",
    },
    {
        key: PERMISSION_MODULE.NEWS,
        label: "news",
    },
    {
        key: PERMISSION_MODULE.BLOCK,
        label: "block",
    },
    {
        key: PERMISSION_MODULE.BANNER,
        label: "banner",
    },
    {
        key: PERMISSION_MODULE.SMART_SUPPORT,
        label: "smartSupport",
    },
    {
        key: PERMISSION_MODULE.FAQ,
        label: "faq",
    },
    {
        key: PERMISSION_MODULE.PAYMENT_METHOD,
        label: "paymentMethod",
    },
    {
        key: PERMISSION_MODULE.VOUCHER,
        label: "vouchers",
    },
    {
        key: PERMISSION_MODULE.MEDIA,
        label: "mediaManager",
    },
    {
        key: PERMISSION_MODULE.STATIC_CONTENT,
        label: "staticContent",
    },
    {
        key: PERMISSION_MODULE.REFUND_REQUEST,
        label: "refundRequest",
    },
    {
        key: PERMISSION_MODULE.OAUTH_CODE,
        label: "otpAuthorizeCode",
        accessTypes: OAUTH_CODE_ACCESS_TYPES,
    },
];
export const USER_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};
export const USER_STATUSES = [
    { id: USER_STATUS.ACTIVE, label: "active", color: "#33B950" },
    { id: USER_STATUS.INACTIVE, label: "inactive", color: "#C4C4C4" },
];
