import React, { useContext } from "react";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";
import CustomerSelect from "../../customer/common/CustomerSelect";
import CustomerInfoView from "../../customer/common/CustomerInfoView";

const BookingCreateCustomer = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const { customer } = bookingForm.values;

    const onSelectCustomer = (customerValue: any) => {
        const { addressServiceDefault, firstName, lastName, email, phone } =
            customerValue;

        const contact = { firstName, lastName, email, phone };
        bookingForm.setValues({
            ...bookingForm.values,
            customer: customerValue,
            addresses: addressServiceDefault ? [addressServiceDefault] : [],
            billing: { sameServiceAddress: true },
            contact,
        });
    };

    return (
        <div>
            <div className="card-container p-4 mt-3">
                <CustomerSelect
                    onChange={onSelectCustomer}
                    value={customer}
                    error={bookingForm.errors.customer}
                />
            </div>
            <div className="card-container p-4 mt-3" hidden={!customer}>
                <label>{Messages.customer}</label>
                <CustomerInfoView customer={customer as any} />
            </div>
        </div>
    );
};

export default BookingCreateCustomer;
