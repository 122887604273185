import { Button, Drawer, IDrawerProps, InputText } from "d-react-components";
import { isEmpty } from "lodash";
import React from "react";
import Messages from "../../../../../languages/Messages";

export interface IWorksheetVoucherInputDrawerProps extends IDrawerProps {
    onApply?: (code: string) => any;
}

const WorksheetVoucherInputDrawer: React.FC<IWorksheetVoucherInputDrawerProps> =
    ({ onApply, onClose, open }) => {
        const [voucherCode, setVoucherCode] = React.useState("");

        const onApplyHandle = () => {
            onApply && onApply(voucherCode);
            onClose && onClose(0 as any);
        };

        const renderFooterDrawer = () => {
            return (
                <div className="w-100 d-flex p-3 justify-content-end">
                    <Button
                        disabled={isEmpty(voucherCode)}
                        onClick={onApplyHandle}
                    >
                        {Messages.apply}
                    </Button>
                </div>
            );
        };

        return (
            <Drawer
                size="x-large"
                open={open}
                onClose={onClose}
                footer={renderFooterDrawer()}
            >
                <div className="flex-center-y w-100 border-bottom py-3">
                    <div className="h4 text-primary w-100 text-center">
                        {Messages.selectAnonymousVoucher}
                    </div>
                </div>
                <div className="p-3">
                    <div className="mb-3">
                        {Messages.voucherInputDescription}
                    </div>
                    <InputText
                        placeholder={Messages.enterVoucherCode}
                        value={voucherCode}
                        onChange={(e) => setVoucherCode(e?.target?.value)}
                    />
                </div>
            </Drawer>
        );
    };

export default WorksheetVoucherInputDrawer;
