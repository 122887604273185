import { Progress, TabBar } from "d-react-components";
import { uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorksheetAPI from "../../../api/worksheet/WorksheetAPI";
import {
    WorksheetStatus,
    WORKSHEET_DETAIL_STEPS,
} from "../../../constant/worksheet";
import { AppStateContext } from "../../../context/app";
import { WorksheetDetailContext } from "../../../context/worksheet";
import { IWorksheet } from "../../../interfaces/worksheet";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import WSCustomerInformation from "../common/WSCustomerInformation";
import WorksheetDetailVouchers from "./worksheet-voucher/WorksheetDetailVouchers";
import WorksheetDetailActivity from "./WorksheetDetailActivity";
import WorksheetDetailBooking from "./WorksheetDetailBooking";
import WorksheetDetailCustomerConfirm from "./WorksheetDetailCustomerConfirm";
import WorksheetDetailDefection from "./WorksheetDetailDefection";
import WorksheetDetailGeneral from "./WorksheetDetailGeneral";
import WorksheetDetailHeader from "./WorksheetDetailHeader";
import WorksheetDetailPriorChecking from "./WorksheetDetailPriorChecking";
import WorksheetDetailProgress from "./WorksheetDetailProgress";

function later(delay: number, value: any) {
    return new Promise((resolve) => setTimeout(resolve, delay, value));
}

const WorksheetDetail = () => {
    const { setSiteConfig } = useContext(AppStateContext);
    const [worksheetDetail, setWorksheetDetail] = useState<IWorksheet>();
    let cloneTabs = [...WORKSHEET_DETAIL_STEPS];
    const { worksheetId } = useParams<any>();

    if (
        worksheetDetail?.status === WorksheetStatus.DELIVERED ||
        worksheetDetail?.status === WorksheetStatus.COMPLETED
    ) {
        cloneTabs.splice(5, 0, {
            id: 7,
            label: "voucher",
        });
        cloneTabs = uniqBy(cloneTabs, (item) => item?.id);
    }

    const [worksheetTab, setWorksheetTab] = useState<any>(cloneTabs[0]);
    useEffect(() => {
        loadWorksheetDetail();
    }, []);

    const loadWorksheetDetail = () => {
        Progress.show(
            {
                method: WorksheetAPI.detail,
                // method: () => later(10000000, true),
                params: worksheetId,
            },
            (worksheetDetail: any) => {
                setWorksheetDetail(worksheetDetail);
                setSiteConfig({
                    breadcrumb: [
                        {
                            title: Messages.allWorksheet,
                            url: Path.WORKSHEET_TABLE,
                        },
                        {
                            title: worksheetDetail?.worksheetNo,
                        },
                    ],
                });
            }
        );
    };

    const renderContent = () => {
        switch (worksheetTab.id) {
            case 0:
                return <WorksheetDetailBooking />;
            case 1:
                return <WorksheetDetailPriorChecking />;
            case 2:
                return <WorksheetDetailDefection />;
            case 3:
                return <WorksheetDetailProgress />;
            case 5:
                return <WorksheetDetailCustomerConfirm />;
            case 6:
                return <WorksheetDetailActivity />;
            case 7:
                return <WorksheetDetailVouchers />;
            case 8:
                return <WorksheetDetailGeneral />;
            default:
                return <div />;
        }
    };
    if (!worksheetDetail) return <div />;
    return (
        <WorksheetDetailContext.Provider
            value={{
                worksheetDetail,
                setWorksheetDetail,
            }}
        >
            <div className="h-100 overflow-auto pb-5 p-4">
                <WorksheetDetailHeader />
                <div className="row py-4">
                    <div className="col-8">
                        <TabBar
                            className="bg-transparent"
                            classNameItem="border-0"
                            dataSource={cloneTabs}
                            onChange={setWorksheetTab}
                            value={worksheetTab}
                            getLabel={(item: any) =>
                                (Messages as any)[item.label]
                            }
                        />
                        {renderContent()}
                    </div>
                    <div className="col-4">
                        <div className="card-container p-4 flex-column">
                            <label>{Messages.serviceCategory}</label>
                            <text>
                                {worksheetDetail?.categoryProduct?.name ??
                                    "N/A"}
                            </text>
                        </div>
                        <WSCustomerInformation
                            className="mt-3"
                            {...worksheetDetail}
                            customer={worksheetDetail?.customer}
                            source={worksheetDetail?.booking?.source}
                        />
                    </div>
                </div>
            </div>
        </WorksheetDetailContext.Provider>
    );
};

export default WorksheetDetail;
