import { DateInput } from "d-react-components";
import { isNaN } from "lodash";
import { useContext } from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    VOUCHER_CREATE_TYPE,
    VOUCHER_CREATE_TYPES,
} from "../../../constant/voucher";
import { VoucherCreateContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";

const VoucherCreateGeneral = () => {
    const { voucherForm } = useContext(VoucherCreateContext);
    const formValues = voucherForm.values;
    const inputClassName = "col-6 mt-3";
    return (
        <div className="card-container">
            <div className="row p-4">
                <InputSelectForm
                    required
                    label={Messages.type}
                    className="col-12 mt-3"
                    dataSource={VOUCHER_CREATE_TYPES}
                    form={voucherForm}
                    keyData="createType"
                    getLabel={(item: any) =>
                        Messages[item?.label as keyof typeof Messages]
                    }
                />
                <InputTextForm
                    hidden={
                        formValues?.createType !== VOUCHER_CREATE_TYPE.MANUAL
                    }
                    className="col-12 mt-3"
                    require
                    keyData="code"
                    label={Messages.voucherCode}
                    form={voucherForm}
                    onChange={(event: any) => {
                        const text = event.target.value ?? "";
                        voucherForm.setFieldValue("code", text.toUpperCase());
                    }}
                />

                <InputTextForm
                    hidden={
                        formValues?.createType !==
                        VOUCHER_CREATE_TYPE.GENERATE_BY_BULK
                    }
                    className="col-12 mt-3"
                    required
                    keyData="quantity"
                    label={Messages.quantity}
                    form={voucherForm}
                    onChange={(event: any) => {
                        // eslint-disable-next-line radix
                        const text = parseInt(event.target.value ?? "0");
                        const quantityValue = isNaN(text) ? 0 : text;
                        voucherForm.setFieldValue("quantity", quantityValue);
                    }}
                />
                <div
                    className="flex-center-y col-12 mt-3"
                    hidden={
                        formValues?.createType !==
                        VOUCHER_CREATE_TYPE.GENERATE_BY_BULK
                    }
                >
                    <InputTextForm
                        className="mr-2 w-100"
                        keyData="prefix"
                        label={Messages.prefix}
                        form={voucherForm}
                        onChange={(event: any) => {
                            const text = event.target.value ?? "";
                            voucherForm.setFieldValue(
                                "prefix",
                                text.toUpperCase()
                            );
                        }}
                    />
                    <InputTextForm
                        className="ml-2 w-100"
                        keyData="suffix"
                        label={Messages.suffix}
                        form={voucherForm}
                        onChange={(event: any) => {
                            const text = event.target.value ?? "";
                            voucherForm.setFieldValue(
                                "suffix",
                                text.toUpperCase()
                            );
                        }}
                    />
                </div>
                <DateInput
                    className={inputClassName}
                    value={voucherForm?.values?.effectiveDate}
                    onChange={(value) =>
                        voucherForm.setFieldValue("effectiveDate", value)
                    }
                    label={Messages.effectiveDate}
                    required
                    error={voucherForm.errors.effectiveDate as any}
                />
                <DateInput
                    className={inputClassName}
                    value={voucherForm?.values?.expiryDate}
                    onChange={(value) =>
                        voucherForm.setFieldValue("expiryDate", value)
                    }
                    label={`${Messages.expiryDate} (${Messages.leaveEmptyNoExpiry})`}
                    error={voucherForm.errors.expiryDate as any}
                />
                <InputTextForm
                    className="col-12 mt-3"
                    multiple
                    keyData="remark"
                    form={voucherForm}
                />
            </div>
        </div>
    );
};

export default VoucherCreateGeneral;
