import { StringUtils } from "d-react-components";
import { useContext } from "react";
import { RefundCreateContext } from "../../../context/refundRequest";
import Messages from "../../../languages/Messages";

const RefundRequestCreateSummary = () => {
    const { refundForm } = useContext(RefundCreateContext);

    const formValues = refundForm?.values;

    const renderSummaryItem = (label: any, content: any) => {
        return (
            <div className="border-bottom-dashed flex-row-between-center py-2 mt-1">
                <label className="text-primary">{label}</label>
                <div>{content}</div>
            </div>
        );
    };

    if (!formValues.orderDetail && !formValues.worksheet) {
        return <div />;
    }
    return (
        <div className="p-3 bg-primary-trans-15 mt-3">
            {renderSummaryItem(
                Messages.totalPaidAmount,
                StringUtils.moneyThaiFormat(formValues?.totalPaidAmount)
            )}
            {renderSummaryItem(
                Messages.requestedRefundAmount,
                StringUtils.moneyThaiFormat(formValues?.requestedRefundAmount)
            )}
            {renderSummaryItem(
                Messages.remainingAmount,
                StringUtils.moneyThaiFormat(
                    formValues?.totalPaidAmount -
                        formValues?.requestedRefundAmount
                )
            )}
        </div>
    );
};

export default RefundRequestCreateSummary;
