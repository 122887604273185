import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { forEach } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import ProductAPI from "../../../api/product/ProductAPI";
import { PRODUCT_TYPE } from "../../../constant/product";
import { AppStateContext } from "../../../context/app";
import { ProductDetailContext } from "../../../context/product";
import { ProductCRUDSchema } from "../../../formschema/product";
import { mapProductToSer } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductCrudBrand from "./ProductCrudBrand";
import ProductCrudCategory from "./ProductCrudCategory";
import ProductCrudGallery from "./ProductCrudGallery";
import ProductCrudInventory from "./ProductCrudInventory";
import ProductCrudNameDesc from "./ProductCrudNameDesc";
import ProductCrudPricing from "./ProductCrudPricing";
import ProductCrudTags from "./ProductCrudTags";
import ProductCrudType from "./ProductCrudType";
import ProductCrudVisibility from "./ProductCrudVisibility";
import ProductCrudWeight from "./ProductCrudWeight";

const ProductCrud = () => {
    const history = useHistory();
    const { productId, duplicateId } = useParams<any>();
    const isEdit = !!productId;
    const isDuplicate = duplicateId && duplicateId?.length === 24;
    const [metaData, setMetaData] = useState<any>();
    const [productLang, setProductLang] = useState<string>(
        Messages.getLanguage()
    );
    const { setSiteConfig } = useContext(AppStateContext);

    const productForm = useFormik<any>({
        initialValues: {
            th: { name: "", description: "", shortDescription: "" },
            en: { name: "", description: "", shortDescription: "" },
            gallery: [],
            weight: "",
            productType: PRODUCT_TYPE.DEFAULT_PRODUCT,
            sellingOutOfStock: false,
            premiumService: [],
            groupProducts: [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductCRUDSchema,
        onSubmit: (values) => {
            const body = mapProductToSer(values);
            if (isEdit) {
                onEdit(body);
            } else {
                onCreate(body);
            }
        },
    });

    useEffect(() => {
        loadProductDetail();
        loadProductMetaData();
    }, [productId, isDuplicate]);

    useEffect(() => {
        forEach(Object.keys(productForm.errors), (key) =>
            Notifications.showError(`${key} ${productForm.errors[key]}`)
        );
    }, [productForm.errors]);

    const loadProductDetail = async () => {
        if (!productId && !isDuplicate) return;
        const productDetail = await ProductAPI.detail(
            isDuplicate ? duplicateId : productId
        );
        productForm.setValues(productDetail as any);
        setSiteConfig({
            breadcrumb: [
                { title: Messages.allProduct, url: Path.PRODUCT_TABLE },
                {
                    title: `${Messages.product} #${productDetail?.productId}`,
                },
            ],
        });
    };

    const onEdit = (input: any) =>
        Progress.show(
            { method: ProductAPI.update, params: [productId, input] },
            (updateValue: any) => {
                Notifications.showSuccess(Messages.updateProductSuccess);
                productForm.setValues(updateValue);
            }
        );

    const onCreate = (input: any) =>
        Progress.show(
            { method: ProductAPI.create, params: [input] },
            (id: any) => {
                Notifications.showSuccess(Messages.createProductSuccess);
                history.replace(
                    generatePath(Path.PRODUCT_DETAIL, { productId: id })
                );
            }
        );

    const loadProductMetaData = () =>
        Progress.show(
            { method: ProductAPI.detailMetaData, params: [] },
            setMetaData
        );

    return (
        <ProductDetailContext.Provider
            value={{ productForm, ...metaData, productLang, setProductLang }}
        >
            <Header
                title={isEdit ? Messages.editProduct : Messages.createProduct}
                onSave={() => productForm.handleSubmit()}
                onPrint={
                    isEdit
                        ? () => {
                              history.replace(
                                  generatePath(Path.PRODUCT_CREATE, {
                                      duplicateId: productId,
                                  })
                              );
                          }
                        : undefined
                }
                printText={Messages.duplicate}
                printButtonProps={{ iconName: "content_copy" }}
            />
            <div className="h-100 overflow-auto pb-5">
                <div className="row p-4">
                    <div className="col-8 pb-5 mb-5">
                        <ProductCrudNameDesc />
                        <ProductCrudGallery />
                        <ProductCrudType />
                        <ProductCrudPricing />
                        <ProductCrudInventory />
                    </div>
                    <div className="col-4  ">
                        <ProductCrudVisibility />
                        <ProductCrudCategory />
                        <ProductCrudBrand />
                        <ProductCrudWeight />
                        <ProductCrudTags />
                    </div>
                </div>
            </div>
        </ProductDetailContext.Provider>
    );
};

export default ProductCrud;
