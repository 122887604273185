import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    StringUtils,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import OrderAPI from "../../../api/order/OrderAPI";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { ORDER_STATUSES } from "../../../constant/order";
import { PERMISSION_MODULE, ROLE_ACCESS_TYPE } from "../../../constant/user";
import { useUserPermission } from "../../../hoook/permission";
import { IOrder, mapOrderFilterBodyToServer } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import { isFilteredBody } from "../../../utils/Utils";
import Path from "../../Path";
import UserAvatarName from "../../user/common/UserAvatarName";
import OrderExportModal from "./OrderExportModal";
import OrderFilterModal from "./OrderFilterModal";

const OrderTable = () => {
    const history = useHistory();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [filterBody, setFilterBody] = useQueryParam("filterBody", JsonParam);

    const isGrantDetail = useUserPermission(
        PERMISSION_MODULE.ORDER,
        ROLE_ACCESS_TYPE.DETAIL
    );

    const isGrantCreate = useUserPermission(
        PERMISSION_MODULE.ORDER,
        ROLE_ACCESS_TYPE.CREATE
    );

    const isGrantExport = useUserPermission(
        PERMISSION_MODULE.ORDER,
        ROLE_ACCESS_TYPE.EXPORT
    );

    const columns: IColumnsProps = [
        {
            title: Messages.orderNo,
            dataIndex: "orderNo",
            render: (orderNo: string, order: any) => (
                <AppLink
                    disabled={!isGrantDetail}
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: order.id,
                    })}
                >
                    {orderNo}
                </AppLink>
            ),
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    className="ml-3"
                    status={status}
                    listStatus={ORDER_STATUSES}
                    getLabel={(item) => item.label.toUpperCase()}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer) => `${customer?.firstName} ${customer.lastName}`,
        },
        {
            title: Messages.total,
            dataIndex: "total",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createBy",
            render: (createdBy) => (
                <UserAvatarName
                    user={createdBy}
                    placeholder={Messages.customerCreated}
                />
            ),
        },

        {
            title: Messages.action,
            dataIndex: "source",
            render: (source: any, order) => (
                <TableAction
                    onClick={(actionId: string) =>
                        onClickTableAction(actionId, order)
                    }
                />
            ),
            align: "center",
        },
    ];

    const onClickTableAction = (actionId: string, order: IOrder) => {
        switch (actionId) {
            default:
                history.push(
                    generatePath(Path.ORDER_DETAIL, {
                        orderId: order.id,
                    })
                );
        }
    };

    const source = (pagingData: any) => {
        const filterInput = mapOrderFilterBodyToServer(filterBody);
        return OrderAPI.list(searchRef.current, pagingData, filterInput);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const onFilter = (values: any) => {
        setFilterBody(values);
        tableRef.current.refresh();
    };

    return (
        <div className="p-4">
            <HeaderTable
                onClickNew={() => history.push(Path.ORDER_CREATE)}
                label={Messages.orders}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="mb-3"
                isFiltered={isFilteredBody(filterBody)}
                onClickExport={() => setOpenExport(true)}
                showAddNew={isGrantCreate}
                showExport={isGrantExport}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                transformer={(res) => {
                    return res?.data?.data?.order ?? [];
                }}
                baseColumnProps={{ width: 100 }}
                bordered={false}
                className="bg-white"
            />
            {openFilter && (
                <OrderFilterModal
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    onFilter={onFilter}
                    defaultValue={filterBody}
                />
            )}
            {openExport && (
                <OrderExportModal
                    open={openExport}
                    onClose={() => setOpenExport(false)}
                />
            )}
        </div>
    );
};

export default OrderTable;
