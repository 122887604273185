import { filter, forEach, map, reduce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { DELIVERY_STATUS } from "../constant/delivery";
import { DISCOUNT_AMOUNT_TYPE } from "../constant/discount";
import { OrderDetailContext } from "../context/order";
import { IOrderDiscountDisplay } from "../interfaces/order";
import Messages from "../languages/Messages";

export interface IOrderPrice {
    subtotal: number;
    total: number;
    discountList: IOrderDiscountDisplay[];
}

export const useOrderPrice = (order: any): IOrderPrice => {
    const [orderPrice, setOrderPrice] = useState<IOrderPrice>({
        subtotal: 0,
        total: 0,
        discountList: [],
    });
    const { customProducts, productsWorksheet, products, manualDiscounts } =
        order;
    const allProductList = [
        ...customProducts,
        ...productsWorksheet,
        ...products,
    ];

    const allDiscountList = [...manualDiscounts];

    const getSubtotal = () =>
        reduce(
            allProductList,
            (sum, product) => sum + product.salePrice * product.quantity,
            0
        );

    useEffect(() => {
        const subtotal = getSubtotal();
        onChangeOrderPrice({ subtotal });
    }, [customProducts, productsWorksheet, products]);

    useEffect(() => {
        let afterDiscount = getSubtotal();
        const discountList: IOrderDiscountDisplay[] = map(
            allDiscountList,
            (discount) => {
                const { amountType, amount } = discount;
                let discountMoney = parseFloat(amount);
                let subTitle;
                const title = Messages.manualDiscount;
                if (amountType === DISCOUNT_AMOUNT_TYPE.PERCENTAGE) {
                    discountMoney = (afterDiscount * parseFloat(amount)) / 100;
                    subTitle = `${amount}%`;
                }
                afterDiscount -= discountMoney;
                return { ...discount, total: discountMoney, subTitle, title };
            }
        );
        onChangeOrderPrice({ discountList });
    }, [JSON.stringify(manualDiscounts), orderPrice.subtotal]);

    useEffect(() => {
        const totalDiscount = reduce(
            orderPrice.discountList,
            (sum, discount) => sum + discount.total,
            0
        );
        const totalProductVouchers = reduce(
            order.productVouchers,
            (sum, voucher) => sum + voucher.amount,
            0
        );
        const total = getSubtotal() - totalDiscount - totalProductVouchers;
        onChangeOrderPrice({ total });
    }, [orderPrice.subtotal, orderPrice.discountList, order.productVouchers]);

    const onChangeOrderPrice = (newPrice = {}) => {
        setOrderPrice({ ...orderPrice, ...newPrice });
    };

    return orderPrice;
};

export const useOrderDeliveryShippedQty = (
    deliveryId: string,
    product: any
) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { deliveries = [] } = orderDetail;

    const otherDeliveries = useMemo(
        () =>
            filter(
                deliveries,
                (item) =>
                    item.id !== deliveryId &&
                    item.status !== DELIVERY_STATUS.CANCEL
            ),
        [deliveryId]
    );

    const shippedQty = useMemo(() => {
        let quantity = 0;
        forEach(otherDeliveries, (delivery) => {
            forEach(delivery?.products ?? [], (pro) => {
                if (pro.id === product.id) {
                    quantity += pro?.shippingQty;
                }
            });
        });
        return quantity;
    }, [otherDeliveries]);
    return shippedQty;
};
