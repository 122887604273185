export const DEFAULT = "DEFAULT";
export const BANK_TRANSFER = "bank-transfer";
export const CREDIT_CARD_EDC = "credit-card-edc";
export const C2P = "2C2P";
export const CASH = "cash";

export const PAYMENTS_METHODS = [
    {
        id: DEFAULT,
        label: "default",
    },
    {
        id: BANK_TRANSFER,
        label: "bankTransfer",
    },
    {
        id: C2P,
        label: "c2pName",
    },
    {
        id: CASH,
        label: "cash",
    },
    {
        id: CREDIT_CARD_EDC,
        label: "creditCardEdc",
    },
];

export const PAYMENTS_CREDENTIAL_MODES = [
    {
        id: "TEST",
        label: "Test",
    },
    {
        id: "LIVE",
        label: "Live",
    },
];

export const PAYMENT_STATUS = {
    PENDING: "PENDING_PAYMENT",
    PENDING_CONFIRM: "PENDING_CONFIRMATION",
    REJECTED: "REJECTED",
    CONFIRMED: "CONFIRMED",
};

export const PAYMENT_STATUSES = [
    {
        id: PAYMENT_STATUS.PENDING,
        label: "paymentPending",
        color: "#FAC256",
    },
    {
        id: PAYMENT_STATUS.PENDING_CONFIRM,
        label: "paymentPendingConfirm",
        color: "#84C4FF",
    },
    {
        id: PAYMENT_STATUS.REJECTED,
        label: "rejected",
        color: "#FF1100",
    },
    {
        id: PAYMENT_STATUS.CONFIRMED,
        label: "confirmed",
        color: "#33B950",
    },
];
