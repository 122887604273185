import React from "react";

interface IPaymentMethodCrudState {
    paymentMethodForm: any;
    methodLang: "th" | "en";
    setMethodLang: (value: any) => any;
}
const paymentMethodCrudState: IPaymentMethodCrudState = {
    paymentMethodForm: null,
    methodLang: "en",
    setMethodLang: () => {},
};
export const PaymentMethodCrudContext = React.createContext(
    paymentMethodCrudState
);
