import React, { useContext } from "react";
import AddressFormInput from "../../address/AddressFormInput";
import InputTextForm from "../../../common/input/InputTextForm";
import { WarehouseCreateContext } from "../../../context/warehouse";

const WarehouseCrudAddress = () => {
    const { warehouseForm, warehouseLang } = useContext(WarehouseCreateContext);
    return (
        <div className="grid-container bg-white p-4 row">
            <AddressFormInput
                formDataError={warehouseForm?.errors}
                formDataValue={warehouseForm.values}
                onChange={(values: any) => {
                    warehouseForm.setValues({
                        ...warehouseForm.values,
                        ...values,
                    });
                }}
                show={{
                    typeOfPlace: false,
                    country: false,
                    map: false,
                    address: false,
                }}
            />
            <InputTextForm
                prefix={warehouseLang}
                keyData="address"
                form={warehouseForm}
                required
                className="col-12 mt-3"
            />
        </div>
    );
};

export default WarehouseCrudAddress;
