export const BOOKING_STATUSES = [
    { id: "PENDING", label: "pending", color: "#FAC256" },
    { id: "SCHEDULED", label: "scheduled", color: "#29B3BC" },
    { id: "PROCESSING", label: "processing", color: "#84C4FF" },
    { id: "COMPLETED", label: "completed", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
];

export const BOOKING_STATUS = {
    PENDING: "PENDING",
    SCHEDULED: "SCHEDULED",
    PROCESSING: "PROCESSING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
};
