import {
    AwesomeTableComponent,
    IColumnsProps,
    Modal,
} from "d-react-components";
import React from "react";
import InventoryAPI from "../../../api/inventory/InventoryAPI";
import Messages from "../../../languages/Messages";

interface IInventoryOnHoldAllocationModal {
    productId: string;
    open: boolean;
    onClose: () => void;
}

const InventoryAllocationModal = ({
    productId,
    open,
    onClose,
}: IInventoryOnHoldAllocationModal) => {
    const columns: IColumnsProps = [
        {
            title: Messages.storeWarehouse,
            dataIndex: "warehouse",
            render: (warehouse) => warehouse?.[Messages.getLanguage()]?.name,
        },
        {
            title: Messages.storeCode,
            dataIndex: "warehouse",
            render: (warehouse) => warehouse?.code,
        },
        {
            title: Messages.currentQty,
            dataIndex: "quantity",
        },
    ];

    const source = () => {
        const param = { productId };
        return InventoryAPI.inventoryAllocation(param);
    };

    const transformer = (res: any) => res?.data?.data ?? [];

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.inventoryAllocation}
            showFooter={false}
        >
            <AwesomeTableComponent
                columns={columns}
                source={source}
                transformer={transformer}
                isPagination={false}
            />
        </Modal>
    );
};

export default InventoryAllocationModal;
