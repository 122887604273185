import { gql } from "@apollo/client";
import { mapImportItemsToServer } from "../../interfaces/inventory";
import { F_ACTIVITIES } from "../activity/ActivityFragment";
import API from "../API";
import { F_WAREHOUSE } from "../warehouse/WarehouseFragment";
import {
    F_INVENTORIES,
    F_INVENTORY_ITEM,
    F_INVENTORY_LOG,
} from "./InventoryFragment";

const validateImportConvertor = {
    toServer: (variables: any) => ({
        input: mapImportItemsToServer(variables?.input),
    }),
};

export default {
    list: (
        q: string,
        { pageIndex = 1, pageSize = 10 }: any,
        filterState: any = {}
    ) =>
        API.query({
            query: gql`
                ${F_INVENTORIES}
                query inventories(
                    $q: String
                    $page: Int
                    $limit: Int
                    $categoryIds: [ID]
                    $stockStatus: StockStatus
                    $productType: [ProductType]
                    $warehouseIds: [ID]
                ) {
                    data: inventories(
                        q: $q
                        page: $page
                        limit: $limit
                        categoryIds: $categoryIds
                        stockStatus: $stockStatus
                        productType: $productType
                        warehouseIds: $warehouseIds
                    ) {
                        ...F_INVENTORIES
                    }
                }
            `,
            variables: {
                q,
                page: pageIndex,
                limit: pageSize,
                ...filterState,
            },
            fetchPolicy: "no-cache",
        }),

    validateImport: (input: any) =>
        API.withConverter(validateImportConvertor).mutate({
            mutation: gql`
                mutation validateImport($input: [InventoryStockInput]) {
                    validateInventory(input: $input)
                }
            `,
            variables: {
                input,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        }),

    import: (input: any) =>
        API.mutate({
            mutation: gql`
                mutation createInventory($input: InventoryInput) {
                    createInventory(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        }),

    historyList: (
        id: any,
        { pageIndex = 1, pageSize = 10 }: any,
        warehouseId?: string
    ) =>
        API.query({
            query: gql`
                ${F_INVENTORY_LOG}
                query inventoryHistory(
                    $id: ID!
                    $warehouseId: ID
                    $page: Int
                    $limit: Int
                ) {
                    data: inventoryHistory(
                        id: $id
                        warehouseId: $warehouseId
                        page: $page
                        limit: $limit
                    ) {
                        ...F_INVENTORY_LOG
                    }
                }
            `,
            variables: { id, warehouseId, page: pageIndex, limit: pageSize },
            fetchPolicy: "no-cache",
        }),

    activities: (
        inventoryId: any,
        logId: any,
        { pageIndex = 1, pageSize = 10 }: any
    ) =>
        API.query({
            query: gql`
                ${F_ACTIVITIES}
                query inventoryActivities(
                    $inventoryId: ID!
                    $logId: ID!
                    $page: Int
                    $limit: Int
                ) {
                    data: inventoryActivities(
                        inventoryId: $inventoryId
                        logId: $logId
                        page: $page
                        limit: $limit
                    ) {
                        ...F_ACTIVITIES
                    }
                }
            `,
            variables: {
                inventoryId,
                logId,
                page: pageIndex,
                limit: pageSize,
            },
            fetchPolicy: "no-cache",
        }),

    addComment: (
        inventoryId: string,
        logId: string,
        stockId: string,
        message: string,
        attachments?: string[]
    ) =>
        API.mutate({
            mutation: gql`
                mutation commentInventory(
                    $inventoryId: ID!
                    $logId: ID!
                    $stockId: ID!
                    $message: String
                    $attachments: [String]
                ) {
                    commentInventory(
                        inventoryId: $inventoryId
                        logId: $logId
                        stockId: $stockId
                        message: $message
                        attachments: $attachments
                    )
                }
            `,
            variables: {
                logId,
                inventoryId,
                message,
                attachments,
                stockId,
            },
        }),

    export: (filterState: any = {}) =>
        API.query({
            query: gql`
                ${F_INVENTORY_ITEM}
                query inventoryExport(
                    $categoryIds: [ID]
                    $serviceIds: [ID]
                    $stockStatus: StockStatus
                    $productType: [ProductType]
                    $warehouseIds: [ID]
                ) {
                    data: inventoryExport(
                        categoryIds: $categoryIds
                        serviceIds: $serviceIds
                        stockStatus: $stockStatus
                        productType: $productType
                        warehouseIds: $warehouseIds
                    ) {
                        ...F_INVENTORY_ITEM
                    }
                }
            `,
            variables: { ...filterState },
        }),

    inventoryAllocation: (params: any) =>
        API.query({
            query: gql`
                ${F_WAREHOUSE}
                query inventoryAllocation($productId: String!) {
                    data: inventoryAllocation(productId: $productId) {
                        warehouse {
                            ...F_WAREHOUSE
                        }
                        quantity
                        id
                    }
                }
            `,
            variables: { ...params },
            fetchPolicy: "no-cache",
        }),

    onHoldAllocation: (params: any) =>
        API.query({
            query: gql`
                ${F_WAREHOUSE}
                query onHoldAllocation(
                    $productId: String!
                    $onHoldTypes: [OnHoldType]
                    $warehouseId: ID
                    $orderId: ID
                ) {
                    data: onHoldAllocation(
                        productId: $productId
                        onHoldTypes: $onHoldTypes
                        warehouseId: $warehouseId
                        orderId: $orderId
                    ) {
                        warehouse {
                            ...F_WAREHOUSE
                        }
                        quantity
                        onHoldRef
                        createdAt
                        orderId
                        deliveryId
                    }
                }
            `,
            variables: { ...params },
            fetchPolicy: "no-cache",
        }),
};
