import { Select, ViewCollapse } from "d-react-components";
import { debounce, filter, includes, map, uniqBy } from "lodash";
import { useContext, useState } from "react";
import ProductAPI from "../../../api/product/ProductAPI";
import { NewsCrudContext } from "../../../context/news";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";

const NewsCrudProductToBook = () => {
    const { newsForm } = useContext(NewsCrudContext);

    const { values, errors } = newsForm;
    const [productList, setProductList] = useState<IProduct[]>([]);

    const onLoadProducts = debounce((search: string) => {
        ProductAPI.list(
            search,
            { pageIndex: 1, pageSize: 20 },
            { visibility: true }
        ).then((res) => {
            const productRes = res?.data?.data?.product ?? [];
            const products = uniqBy(
                [...(values.products ?? []), ...productRes],
                (item) => item.id
            );
            setProductList(products);
        });
    }, 300);

    if (!values.isBookService) {
        return <div />;
    }

    const dataSource = uniqBy(
        [...(values.products ?? []), ...productList],
        (item) => item.id
    );

    return (
        <ViewCollapse className="mt-3" label={Messages.serviceToBook}>
            <Select
                value={map(values.products, (item) => item.id)}
                error={errors.products}
                dataSource={dataSource}
                onChange={(valueIds) => {
                    const values = filter(dataSource, (item) =>
                        includes(valueIds, item.id)
                    );
                    newsForm.setFieldValue("products", values);
                }}
                getLabel={(item) => item[Messages.getLanguage()].name}
                multiple
                onSearch={onLoadProducts}
                // onClear={(items) => {}}
            />
        </ViewCollapse>
    );
};

export default NewsCrudProductToBook;
