import { ObjectUtils, StringUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext } from "react";
import { BookingCreateContext } from "../../../context/booking";
import BookingServiceAddress from "../common/BookingServiceAddress";

const BookingCreateServiceAdd = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const { addresses = [], customer } = bookingForm.values;
    const customerId = customer?.id;

    const onUpdateAddress = (addressParam: any) => {
        const value = ObjectUtils.updateArrayById(
            addresses as any,
            addressParam
        );
        bookingForm.setFieldValue("addresses", value);
    };

    const onAddAddress = (addressParam: any) => {
        const value = [
            ...addresses,
            ...map(addressParam, (item) => ({
                ...item,
                id: StringUtils.getUniqueID(),
            })),
        ];
        bookingForm.setFieldValue("addresses", value);
    };

    const onDeleteAddress = (addressId: string) => {
        const value = ObjectUtils.removeArrayById(addresses as any, addressId);
        bookingForm.setFieldValue("addresses", value);
    };

    return (
        <BookingServiceAddress
            onChangeAddress={onUpdateAddress}
            onAddAddress={onAddAddress}
            onDeleteAddress={onDeleteAddress}
            addresses={addresses}
            customerId={customerId}
            hiddenCrud={!customerId}
        />
    );
};

export default BookingCreateServiceAdd;
