import { ObjectUtils, StringUtils } from "d-react-components";
import React, { useContext } from "react";
import { WorksheetCreateContext } from "../../../../context/worksheet";
import WSDefectionConfirm from "../../common/WSDefectionConfirm";

import WSCDefectionVisual from "../../common/WSCDefectionVisual";

const WorksheetCreateDefection = () => {
    const { worksheetForm } = useContext(WorksheetCreateContext);
    const { defectionVisualList, defectionConfirm } = worksheetForm?.values;

    const onAddVisualization = () => {
        const values = [
            ...defectionVisualList,
            { id: StringUtils.getUniqueID() },
        ];

        worksheetForm.setFieldValue("defectionVisualList", values);
    };

    const onChangeVisualization = (visual: any) => {
        const value = ObjectUtils.updateArrayById(defectionVisualList, visual);
        worksheetForm.setFieldValue("defectionVisualList", value);
    };

    const onRemoveVisualization = (visualId: string) => {
        const value = ObjectUtils.removeArrayById(
            defectionVisualList,
            visualId
        );
        worksheetForm.setFieldValue("defectionVisualList", value);
    };

    const onChangeConfirm = (confirm: any) => {
        worksheetForm.setFieldValue("defectionConfirm", confirm);
    };

    return (
        <div className="flex-column mt-3">
            <WSCDefectionVisual
                visualizationList={defectionVisualList}
                onAdd={onAddVisualization}
                // onChange={onChangeVisualization}
                onRemove={onRemoveVisualization}
            />
            <WSDefectionConfirm defectionConfirm={defectionConfirm} />
        </div>
    );
};

export default WorksheetCreateDefection;
