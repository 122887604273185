import React, { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import CustomerPersonalInfoForm from "../common/CustomerPersonalInfoForm";

const CustomerCreatePersonInfo = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    return (
        <div className="bg-white p-4 ">
            <label>{Messages.updatePersonalInfo}</label>
            <CustomerPersonalInfoForm customerForm={customerForm} />
            {/* <div className="d-flex justify-content-end mt-3">
                <Button variant="outline">{Messages.cancel}</Button>
                <Button className="ml-3">{Messages.save}</Button>
            </div> */}
        </div>
    );
};

export default CustomerCreatePersonInfo;
