import { ObjectUtils } from "d-react-components";
import React, { useContext } from "react";
import {
    WorksheetCreateContext,
    WSCBookingContext,
} from "../../../../context/worksheet";
import WSCBookingNote from "../../common/WSCBookingNote";
import WSCBookingPIC from "../../common/WSCBookingPIC";
import WSCBookingInput from "./WSCBookingInput";
import WSCBookingProducts from "./WSCBookingProducts";

const WorksheetCreateBooking = () => {
    const { booking, worksheetForm } = useContext(WorksheetCreateContext);

    const onChangeAssignPic = (userList = []) => {
        worksheetForm.setFieldValue("PIC", userList);
    };

    const onRemoveAssignPic = (user: any) => {
        const userList = worksheetForm?.values?.PIC ?? [];
        const result = ObjectUtils.removeArrayById(userList, user?.id);
        worksheetForm.setFieldValue("PIC", result);
    };

    const renderContent = () => (
        <div className="flex-column">
            <WSCBookingInput />
            {booking && <WSCBookingProducts />}
            {booking && <WSCBookingNote note={booking?.notes} />}
            {booking && (
                <WSCBookingPIC
                    picList={worksheetForm?.values?.PIC ?? []}
                    onAddUser={onChangeAssignPic}
                    onRemoveUser={onRemoveAssignPic}
                />
            )}
        </div>
    );
    return (
        <WSCBookingContext.Provider value={{} as any}>
            <div className="flex-column mt-3">{renderContent()}</div>
        </WSCBookingContext.Provider>
    );
};

export default WorksheetCreateBooking;
