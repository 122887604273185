import { TimeUtils, ViewLabelStatus } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import { VOUCHER_STATUSES } from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const VoucherDetailUsageStatus = () => {
    const { voucher } = useContext(VoucherDetailContext);
    const { usageState } = voucher;
    const renderInfoItem = (label: string, content: any) => {
        return (
            <div className="flex-center-y border-bottom py-3">
                <div className="small w-50">{label}</div>
                <div className="text w-50 ">{content}</div>
            </div>
        );
    };
    console.log("usageState?.worksheet", usageState?.worksheet);
    return (
        <div className="card-container mt-3 p-4">
            <label>{Messages.usageStatus}</label>
            {renderInfoItem(
                Messages.status,
                <ViewLabelStatus
                    listStatus={VOUCHER_STATUSES}
                    status={usageState?.status}
                    getLabel={(item) => (item?.label ?? "")?.toUpperCase()}
                />
            )}
            {usageState?.usedAt &&
                renderInfoItem(
                    Messages.usedAt,
                    TimeUtils.toDateTime(usageState?.usedAt)
                )}
            {usageState?.worksheet?.id &&
                renderInfoItem(
                    Messages.worksheetRef,
                    <AppLink
                        to={generatePath(Path.WORKSHEET_DETAIL, {
                            worksheetId: usageState?.worksheet?.id,
                        })}
                        className="p-3"
                    >
                        {usageState?.worksheet?.worksheetNo}
                    </AppLink>
                )}
        </div>
    );
};

export default VoucherDetailUsageStatus;
