// react
import { TabBar } from "d-react-components";
import React from "react";

interface IVerticalTabView {
    dataSource: any[];
    value: any;
    onChange: (tabSelect: any) => any;
}

const VerticalTabView = ({
    dataSource = [],
    value,
    onChange,
}: IVerticalTabView) => {
    return (
        <TabBar
            dataSource={dataSource}
            variant="vertical"
            onChange={onChange}
            value={value}
            getItemProps={({ item, isActive }) => {
                if (isActive) {
                    return {
                        style: {
                            borderLeft: "5px solid #587C5F ",
                            borderBottom: "1px solid rgba(0,0,0,0.2)",
                            backgroundColor: "white",
                            color: "rgba(0, 0, 0)",
                        },
                    };
                }
                return {
                    style: {
                        backgroundColor: "white",
                    },
                };
            }}
        />
    );
};

export default VerticalTabView;
