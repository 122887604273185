export const DISCOUNT_AMOUNT_TYPES = [
    { id: "percentage", label: "%" },
    { id: "amount", label: "THB" },
];

export const DISCOUNT_AMOUNT_TYPE = {
    PERCENTAGE: "percentage",
    AMOUNT: "amount",
};

export const DISCOUNT_TYPES = [
    { id: "voucher", label: "Voucher" },
    { id: "manual", label: "Manual" },
];

export const DISCOUNT_TYPE = {
    VOUCHER: "voucher",
    MANUAL: "manual",
};
