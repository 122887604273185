import { Button, ObjectUtils, StringUtils } from "d-react-components";
import { filter } from "lodash";
import React from "react";
import Messages from "../../languages/Messages";

const DraggableList: React.FC<any> = ({
    listValue = [],
    onAddNew,
    onChange,
    children,
    listName = "tabs",
    ...rest
}) => {
    const addRow = () => {
        if (onAddNew) {
            onAddNew();
        } else {
            onChange &&
                onChange(listName, [
                    ...listValue,
                    { id: StringUtils.generateCode(8) },
                ]);
        }
    };
    const onRemove = (index: any) => {
        onChange &&
            onChange(
                listName,
                filter(listValue, (item, i) => i !== index)
            );
    };

    const handleOnDragEnd = ({ oldIndex, newIndex }: any) => {
        const clone = ObjectUtils.arrayMove(listValue, oldIndex, newIndex);
        onChange && onChange(listName, clone);
    };

    const onChangeItem = (key: any, value: any, item: any) => {
        onChange &&
            onChange(
                listName,
                listValue?.map((currentItem: any) =>
                    currentItem.id === item.id
                        ? { ...currentItem, [key]: value }
                        : currentItem
                )
            );
    };

    return (
        <div>
            {React.cloneElement(children, {
                listValue,
                onChangeValue: onChangeItem,
                onRemove,
                onSortEnd: handleOnDragEnd,
                ...rest,
            })}
            <Button onClick={addRow} className="mt-2">
                {Messages.add}
            </Button>
        </div>
    );
};

export default DraggableList;
